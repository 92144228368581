import {
  ReportCategoryType,
  ReportColumn,
  ReportColumnInput,
  ReportColumnType,
  ReportCombinatorType,
  ReportOperatorType,
  ReportParameter,
  ReportParameterInput,
  ReportParameterType,
  ReportQuery,
  ReportQueryInput
} from '../../../types/Report'
import _sortBy from 'lodash/sortBy'

export const REPORT_COLUMN_GROUPS: { label: string, columns: ReportColumnType[] }[] = [
  {
    label: "Appointment",
    columns: [
      ReportColumnType.DATE,
      ReportColumnType.NOTES,
      ReportColumnType.STATE,
      ReportColumnType.TYPE,
      ReportColumnType.PRACTITIONER
    ]
  },
  {
    label: "Billing",
    columns: [
      ReportColumnType.CALLS,
      ReportColumnType.CLAIMED,
      ReportColumnType.DIAGNOSES,
      ReportColumnType.FACILITY,
      ReportColumnType.OUTCOME,
      ReportColumnType.PAID,
      ReportColumnType.SERVICE_CODE,
      ReportColumnType.SERVICE_DATE,
      ReportColumnType.PRACTITIONER_BILLING_PROFILE,
    ]
  },
  {
    label: "Lab results",
    columns: [
      ReportColumnType.LAB_RESULT_DATE,
      ReportColumnType.LAB_RESULT_DESCRIPTION
    ]
  },
  {
    label: "Linked documents",
    columns: [
      ReportColumnType.LINKED_DOCUMENT_CATEGORY,
      ReportColumnType.LINKED_DOCUMENT_DESCRIPTION
    ]
  },
  {
    label: "Prescriptions",
    columns: [
      ReportColumnType.PRESCRIPTION_DATE,
      ReportColumnType.PRESCRIPTION,
      ReportColumnType.PRESCRIPTION_STATUS
    ]
  },
  {
    label: "Patient",
    columns: [
      ReportColumnType.PATIENT_AGE,
      ReportColumnType.PATIENT_DATE_OF_BIRTH,
      ReportColumnType.PATIENT_GENDER,
      ReportColumnType.PATIENT_IDENTIFIER,
      ReportColumnType.PATIENT_IDENTIFIER_TYPE,
      ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE,
      ReportColumnType.PATIENT,
      ReportColumnType.PATIENT_POSTAL_CODE
    ]
  }
]

export const REPORT_PARAMETER_GROUPS: { category: ReportCategoryType, label: string, parameters: ReportParameterType[] }[] = [
  {
    category: ReportCategoryType.APPOINTMENT,
    label: "Appointment",
    parameters: [
      ReportParameterType.APPOINTMENT_DATE_RANGE,
      ReportParameterType.APPOINTMENT_PRACTITIONERS
    ]
  },
  {
    category: ReportCategoryType.BILLING,
    label: "Billing",
    parameters: [
      ReportParameterType.SERVICE_DATE_RANGE,
      ReportParameterType.PAYMENT_DATE_RANGE,
      ReportParameterType.CLAIM_PRACTITIONERS
    ]
  },
  {
    category: ReportCategoryType.PATIENT,
    label: "Patient",
    parameters: [
      ReportParameterType.PRIMARY_PRACTITIONER
    ]
  }
]

export const convertColumnInputsToColumns = (columns: ReportColumnInput[] = []): ReportColumn[] => {
  return columns.map((column, index) => (
    {
      id: column.id,
      column: column.name,
      order: index,
      version: column.version || "0"
    } as ReportColumn
  ))
}

export const convertParameterInputsToParameters = (parameters: ReportParameterInput[] = []): ReportParameter[] => {
  return parameters.map((parameter) => (
    {
      id: parameter.id,
      parameter: parameter.name,
      version: parameter.version || "0"
    } as ReportParameter
  ))
}

const removeQueryGroupsWithNoSubqueries = (queries: ReportQuery[]): ReportQuery[] => {
  return queries.filter(query => !query.subqueries || query.subqueries.length > 0)
}

export const convertQueryInputsToQueries = (queries: ReportQueryInput[] = [], combinator: ReportCombinatorType = ReportCombinatorType.AND): ReportQuery[] => {
  const reportQueries = queries
    .filter(query => !!query.column || !!query.rules)
    .map((query, index) => ({
        id: query.id,
        column: query.column,
        operator: query.rules ? ReportOperatorType.GROUP : query.operator,
        combinator: combinator,
        value1: query.value1,
        value2: query.value2,
        subqueries: query.rules ? convertQueryInputsToQueries(query.rules, query.combinator) : undefined,
        order: index,
        version: query.version || "0"
      } as ReportQuery)
    )
  return removeQueryGroupsWithNoSubqueries(reportQueries)
}

export const convertQueriesToQueryInputs = (queries: ReportQuery[], groupId?: string): ReportQueryInput[] => {
  let queryInputs: ReportQueryInput[] = []
  const currentQueries: ReportQuery[] = queries.filter(query => !query.groupId || query.groupId === groupId)
  const remainingQueries: ReportQuery[] = queries.filter(query => !!query.groupId && query.groupId !== groupId)

  currentQueries.forEach(query => {
    let queryInput = convertQueryToQueryInput(query)
    if (query.operator === ReportOperatorType.GROUP) {
      const subqueries = convertQueriesToQueryInputs(remainingQueries, query.id)
      queryInputs.push({
        ...queryInput,
        combinator: subqueries.length > 0 ? subqueries[0].combinator : ReportCombinatorType.AND,
        rules: subqueries
      })
    } else {
      queryInputs.push(convertQueryToQueryInput(query))
    }
  })

  return _sortBy(queryInputs, 'order')
}

const convertQueryToQueryInput = (query: ReportQuery): ReportQueryInput => {
  return {
    id: query.id,
    column: query.column,
    operator: query.operator,
    combinator: query.combinator,
    value1: query.value1,
    value2: query.value2,
    rules: query.operator === ReportOperatorType.GROUP ? [] : undefined,
    order: query.order,
    version: query.version
  }
}

export const operatorIsNullable = (operator: string): boolean => {
  return (operator === ReportOperatorType.IS_EMPTY ||
    operator === ReportOperatorType.IS_NOT_EMPTY ||
    operator === ReportOperatorType.IS_NULL ||
    operator === ReportOperatorType.IS_NOT_NULL
  )
}

export const operatorIsBetween = (operator: string): boolean => {
  return (operator === ReportOperatorType.BETWEEN ||
    operator === ReportOperatorType.NOT_BETWEEN
  )
}

export const operatorIsIn = (operator: string): boolean => {
  return (operator === ReportOperatorType.IN ||
    operator === ReportOperatorType.NOT_IN)
}