import React from "react";
import { TableListRowConfig, Typography } from "saga-library/src";
import { Box } from "@mui/material"
import { LinkedDocumentType } from "../../../../../types/Document";
import { displayCategory } from "../../../util/displayEnum";
import { useFormattedDate } from '../../../../../hooks/FormattedDate'

export const PatientOtherDocumentsRow = (document: LinkedDocumentType, index: number, onRowClicked): TableListRowConfig => {
  const formattedDate = useFormattedDate(document.documentDate)

  if(document) {
    const { description, category } = document
    return (
      {
        onClick: ()=>onRowClicked(document.id),
        key: document.id!,
        rowData:[
          {
            data: formattedDate,
            children:
              <Box sx={{ width: '232px'}}>
                <Typography lineclamp={1} variant={"body2"} dataTestId={`otherDocumentRow-date-${index}`}>
                  {formattedDate}
                </Typography>
              </Box>
          },
          {
            data: description || displayCategory(category),
            children:
              <Box display={'flex'}>
                <Typography lineclamp={1} variant={"body2"} dataTestId={`otherDocumentRow-label-${index}`}>
                  { description || displayCategory(category) }
                </Typography>
              </Box>
          }
        ]
      }
    )
  }

  return {rowData: [], key: ""}
}