import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PermissionButton from "../../../../../components/PermissionButton";
import React, { useEffect, useRef, useState } from "react";
import { PrescriptionStatus } from "../../../../../types/Prescription";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import { Banner } from "saga-library/src";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";

export const RenewBanner = ({ prescription, onRenew }) => {
  const displayBanner = prescription && (prescription?.status === PrescriptionStatus.ACTIVE || prescription?.status === PrescriptionStatus.INACTIVE)

  const message = `${prescription?.status === PrescriptionStatus.ACTIVE ? "Active" : "Inactive"} prescriptions can't be
        modified`

  const renewButtonRef = useRef<HTMLButtonElement>(null)
  const [hasFocus, setHasFocus] = useState(false);
  const setFocus = (focus: boolean) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(renewButtonRef, hasFocus, setFocus)

  useEffect(() => {
    if (renewButtonRef) {
      setHasFocus(false)
    }
  }, [renewButtonRef]);

  const renewButton = <PermissionButton
    name={"renew_prescription_button"}
    variant={'text'}
    requiredType={PermissionType.Chart}
    requiredPermission={Permission.READWRITE}
    onClick={onRenew}
    sx={{color: 'tertiary.info', height: '28px', padding: 0, pl: 1, pr: 1}}
    dataTestId={"renewPrescriptionBanner-renew"}
    buttonRef={renewButtonRef}
  >
    RENEW
  </PermissionButton>

  if(displayBanner) {
    return <Banner
      Icon={InfoOutlinedIcon}
      messageText={message}
      actionButton={prescription.renewedOn ? null : renewButton}
      backgroundColor={'backgrounds.info'}
      textColor={'tertiary.info'}
      sx={{ height: '36px', padding: 1 }}
      dataTestId={"renewPrescriptionBanner"}
    />
  }

  return null
}