import { useAppSettingsContext } from '../providers/AppSettingsContext'
import { useApolloClient } from '@apollo/client'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { GET_RECENT_GLOBAL } from "../graphql-definitions";
import { useTenantContext } from "../providers/TenantContextProvider";

const usePatientCache = () => {
  const { tenant_id } = useParams()
  const apolloClient = useApolloClient()
  const { maxRecentResults } = useAppSettingsContext()
  const { orderIdentifiers, formatIdentifier } = useTenantContext()

  const updateCache = (
    patient
  ) => {
    apolloClient.cache.updateQuery(
      {
        query: GET_RECENT_GLOBAL,
        variables: {
          tenantId: tenant_id,
        },
      },
      (cacheData) => {
        let recents = _get(
          cacheData,
          'tenant.search.patients.recent',
          []
        ).filter((r) => r.id !== patient.id)

        if (patient.firstName) {
          const identifiers = orderIdentifiers(patient.identifiers, patient.province)
          let primaryIdentifier = {label: '', formatted: ''}
          if (identifiers.length > 0) {
            primaryIdentifier = {
              label: identifiers[0].label,
              formatted: formatIdentifier(identifiers[0].value, identifiers[0].typeId),
            }
          }

          recents.unshift({
            id: patient.id,
            firstName: patient.firstName,
            lastName: patient.lastName,
            dob: patient.dob,
            primaryIdentifier: primaryIdentifier.formatted,
            primaryIdentifierLabel: primaryIdentifier.label,
            identifiers: identifiers,
            province: patient.province,
            inactiveDate: patient.inactiveDate
          })
        } else {
          const recentPatient = _get(cacheData, 'tenant.search.patients.recent', [])
            .filter((r) => r.id === patient.id)[0]
          recents.unshift(recentPatient)
        }

        if (recents.length > maxRecentResults) {
          recents.length = maxRecentResults
        }

        return {
          tenant: {
            search: {
              patients: {
                recent: recents,
              },
            },
          },
        }
      }
    )
  }

  return updateCache
}

export default usePatientCache
