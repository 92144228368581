import { gql } from "@apollo/client";

export const FILE_FRAGMENT = gql`
  fragment FileDetails on File {
    id
    fileUri
    containerName
    blobName
    contentType
    status
    originalFileName
    version
    audit {
      crePit
    }
  }
`

export const GET_UNLINKED_FILES = gql`
  ${FILE_FRAGMENT}
  query UnlinkedFiles ($tenantId: ID!) {
    tenant(id: $tenantId) {
      file {
        unlinkedFiles {
          ...FileDetails
        }              
      }
    }
  }
`

export const GET_READ_SAS_TOKEN = gql`
  query GetReadSasToken ($tenantId: ID!, $blobName: String!) {
    tenant(id: $tenantId) {
      file {
        readSasToken (blobName: $blobName)           
      }
    }
  }
`

export const GET_WRITE_SAS_TOKEN = gql`
  query GetWriteSasToken ($tenantId: ID!, $blobName: String!) {
    tenant(id: $tenantId) {
      file {
        writeSasToken (blobName: $blobName)           
      }
    }
  }
`

export const CREATE_FILE = gql`
  ${FILE_FRAGMENT}
  mutation CreateFile ($tenantId: ID!, $file: FileCreateInput!) {
    tenant(id: $tenantId) {
      file {
        createFile (file: $file) {
          ...FileDetails
        }
      }
    }
  }
`

export const GET_FILE_DETAILS = gql`
  ${FILE_FRAGMENT}
  query GetFileDetails ($tenantId: ID!, $fileId: ID!) {
    tenant(id: $tenantId) {
      file {
        get (id: $fileId) {
          ...FileDetails
        }
      }
    }
  }
`

export const GET_FILE_DETAILS_FROM_URL = gql`
    ${FILE_FRAGMENT}
    query GetFileDetailsFromUrl ($tenantId: ID!, $url: String!) {
        tenant(id: $tenantId) {
            file {
                fileDetailsFromUrl (url: $url) {
                    ...FileDetails
                }
            }
        }
    }
`

export const FILE_UPLOADED = gql`
  ${FILE_FRAGMENT}
  mutation FileUploaded ($tenantId: ID!, $id: ID!, $version: String!, $isDocumentImported: Boolean!) {
    tenant(id: $tenantId) {
      file {
        fileUploaded (id: $id, version: $version, isDocumentImported: $isDocumentImported) {
          ...FileDetails
        }
      }
    }
  }
`

export const DELETE_FILE = gql`
  mutation DeleteFile($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      file {
        deleteFile(id: $id, version: $version) 
      }
    }
  }
`

export const UPDATE_FILE = gql`
  ${FILE_FRAGMENT}
  mutation UpdateFile($tenantId: ID!, $file: FileUpdateInput!) {
    tenant(id: $tenantId) {
      file {
        updateFile(file: $file) {
          ...FileDetails
        }
      }
    }
  }
`

export const LINK_DOCUMENT = gql`
  mutation LinkDocument($tenantId: ID!, $linkDocumentInput: LinkDocumentInput!) {
    tenant(id: $tenantId) {
      file {
        linkDocument(linkDocumentInput: $linkDocumentInput) {
          id          
        }
      }
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($tenantId: ID!, $documentId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      file {
        deleteFile(id: $documentId, version: $version)
      }
    }
  }
 `

export const FILE_UPLOADED_AND_LINK_DOCUMENT = gql`
  ${FILE_FRAGMENT}
  mutation ($tenantId: ID!, $id: ID!, $linkDocumentInput: LinkDocumentInput!) {
    tenant(id: $tenantId) {
      file {
        fileUploadedAndLinkDocument(id: $id, linkDocumentInput: $linkDocumentInput) {
          ...FileDetails
        }
      }
    }
  }
`

