import { gql } from "@apollo/client";


export const GENERATE_INVITES = gql`
  mutation GenerateInvites(
    $tenantId: ID!
    $emailAddresses: [String!]!
    $passcode: String!
    $question: String!
    $roleIds: [ID!]!
  ) {
    tenant(id: $tenantId) {
      invite {
        generateInvite(
          emailAddresses: $emailAddresses
          passcode: $passcode
          question: $question
          roleIds: $roleIds
        )
        {
          sent
          emailAddress
          errorMessage
        }
      }
    }
  }
`


export const RESEND_INVITE_MUTATION = gql`
  mutation ResendInvite($tenantId: ID!, $inviteId: ID!) {
    tenant(id: $tenantId) {
      invite {
        resendInvite(id: $inviteId)
      }
    }
  }
`

export const CANCEL_INVITE_MUTATION = gql`
  mutation CancelInvite($tenantId: ID!, $inviteId: ID!) {
    tenant(id: $tenantId) {
      invite {
        cancelInvite(id: $inviteId)
      }
    }
  }
`
