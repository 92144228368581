import React from 'react'
import Box from "@mui/material/Box";
import { Checkbox, Section, Select, TextField } from "saga-library/src";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { AssessedMultipleValuesField } from "./BatchUpdateAssessedClaimBaseDetails";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import { BatchControlledNewbornCodeSelect } from "../components/BatchControlledNewbornCodeSelect";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { BatchControlledTextFieldMask } from "../components/BatchControlledTextFieldMask";
import { batchUpdateStyleSearch } from "../components/BatchUpdateStyle";
import { BatchControlledSkillSelect } from "../components/BatchUpdateSkillSelect";
import { PayToOptions } from "../../../../../utils/ABClaimConstants";
import { InterceptReasonSelect } from "../../../../../components/InterceptReasonSelect";
import { MenuItem } from "@mui/material";

const Form = ({
  batchUpdateMultiple,
}) => {
  const { control, formState: {dirtyFields}, resetField, register } = useFormContext();
  const newBornCode = useWatch({
    control,
    name: 'newBornCode',
  })

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          m: 1,
          p: 2,
          width: '100%',
        }}
      >
        <Section.Header>Options</Section.Header>
        <AssessedMultipleValuesField
          label={'Paper documentation to follow'}
          multipleValuesState={batchUpdateMultiple.paperDocumentation}
        >
          <Checkbox
            name={'paperDocumentation'}
            label={'Paper documentation to follow'}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Confidential'}
          multipleValuesState={batchUpdateMultiple.confidential}
        >
          <Checkbox
            name={'confidential'}
            label={'Confidential'}
          />
        </AssessedMultipleValuesField>
        <Section.Header>Original encounter information</Section.Header>
        <AssessedMultipleValuesField
          label={'Original encounter date'}
          multipleValuesState={batchUpdateMultiple.originalEncounterDate}
        >
          <BatchControlledDatePicker
            label={'Original encounter date'}
            name={'originalEncounterDate'}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.originalFacilityId}
        >
          <BatchControlledFacilityInput
            name={'originalFacility'}
            label={'Facility'}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Original location'}
          multipleValuesState={batchUpdateMultiple.originalLocationCode}
        >
          <BatchControlledLocationCodeSelect
            label={'Original location'}
            name={'originalLocationCode'}
          />
        </AssessedMultipleValuesField>
        <Section.Header>Patient</Section.Header>
        <AssessedMultipleValuesField
          label={'Newborn'}
          multipleValuesState={batchUpdateMultiple.newBornCode}
        >
          <BatchControlledNewbornCodeSelect
            label={'Newborn'}
            name={'newBornCode'}
          />
        </AssessedMultipleValuesField>
        {
          newBornCode !== null && (
            <>
              <AssessedMultipleValuesField
                label={'Guardian/parent ULI'}
                multipleValuesState={batchUpdateMultiple.guardianUli}
              >
                <BatchControlledTextFieldMask
                  label={'Guardian/parent ULI'}
                  name={'guardianUli'}
                />
              </AssessedMultipleValuesField>
              <AssessedMultipleValuesField
                label={'Guardian/parent registration number'}
                multipleValuesState={batchUpdateMultiple.guardianRegNumber}
              >
                <TextField
                  name={'guardianRegNumber'}
                  label={'Guardian/parent registration number'}
                  type={"number"}
                  sx={batchUpdateStyleSearch(dirtyFields.guardianRegNumber)}
                  autoFocus={true}
                />
              </AssessedMultipleValuesField>
            </>
          )
        }
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          m: 1,
          p: 2,
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <Section.Header>Practitioner</Section.Header>
        <AssessedMultipleValuesField
          label={'Skill'}
          multipleValuesState={batchUpdateMultiple.skillId}
        >
          <BatchControlledSkillSelect />
        </AssessedMultipleValuesField>
        <Section.Header>Payment</Section.Header>
        <AssessedMultipleValuesField
          label={'Pay to'}
          multipleValuesState={batchUpdateMultiple.payToCode}
        >
          <Select
            label={'Pay to'}
            name={'payToCode'}
            sx={batchUpdateStyleSearch(dirtyFields.payToCode)}
          >
            {PayToOptions.map(({label, value}) =>
              <MenuItem value={value}>{label}</MenuItem>
            )}
          </Select>
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Pay to ULI'}
          multipleValuesState={batchUpdateMultiple.payToUli}
        >
          <TextField
            name={'payToUli'}
            label={'Pay to ULI'}
            type={'number'}
            sx={batchUpdateStyleSearch(dirtyFields.payToUli)}
            InputProps={register('payToUli', { valueAsNumber: true })}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Intercept reason'}
          multipleValuesState={batchUpdateMultiple.interceptReason}
        >
          <InterceptReasonSelect
            name={'interceptReason'}
            label={'Intercept reason'}
            sx={batchUpdateStyleSearch(dirtyFields.interceptReason)}
          />
        </AssessedMultipleValuesField>
      </Box>
    </Box>
  )
}

export const BatchUpdateAssessedClaimOptions = {
  Form: Form,
}