import { DATE_FORMAT, MARKETING_EMAILS } from "../../utils/SettingsConstants";
import ProfileForm, { ProfileFormValues } from "./Components/ProfileForm";
import NotificationsForm, { NotificationFormValues } from "./Components/NotificationsForm";
import SettingsForm, { SettingsFormValues } from "./Components/SettingsForm";
import { usePrompt } from "../../providers/NavigationPrompt";
import React, { useEffect, useState } from "react";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { DialogV2, Form, Tabs, Typography, useForm } from "saga-library/src";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_PROFILE, UPDATE_USER_PROFILE } from "../../graphql-definitions";
import _get from "lodash/get";
import { FormProvider } from "react-hook-form";
import { useAccountContext } from "../../providers/AccountContext";
import ChangeEmail from './ChangeEmail'

const FORM_NAME = 'account_settings_form'

const AccountSettingsKeys = [DATE_FORMAT, MARKETING_EMAILS]

let userSettings

type FormValues = ProfileFormValues & NotificationFormValues & SettingsFormValues

export const AccountModal = ({ title, open, onClose, dataTestId }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { userId } = useAccountContext()
  const form = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      contactPhone: '',
      marketingEmails: true,
      shortDateFormat: 'MM/DD/YYYY',
      version: "0"
    }
  })
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const [showEmail, setShowEmail] = useState<boolean>(true)

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting }
  } = form

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      if (discard) {
        reset()
        onClose()
      }
      setOpenNavigationPrompt(false)
    }
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  useEffect(() => {
    if(open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  useQuery(GET_USER_PROFILE, {
    variables: {
      keys: AccountSettingsKeys
    },
    onCompleted: (data) => {
      const user = _get(data, 'user', null)
      userSettings = user
      const accountSettings ={
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        contactPhone: user.profile.contactPhone,
        marketingEmails: user.settings.find(s => s.name === MARKETING_EMAILS).bool,
        shortDateFormat: user.settings.find(s => s.name === DATE_FORMAT).string,
        version: user.profile.version
      }
      reset(accountSettings)
    },
    onError: (error) => {
      showErrorAlert('Your account information couldn\'t be retrieved.')
    },
  })

  const [updateProfile] = useMutation(UPDATE_USER_PROFILE, {
    onError: (error) => {
      showErrorAlert('Settings could not be updated.')
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      showSuccessAlert('Changes have been saved.')
      reset({}, { keepValues: true })
      onClose()
    },
  })

  const onSave = handleSubmit( async (data) => {
    await updateProfile({
      variables: {
        profile: {
          firstName: data.firstName,
          lastName: data.lastName,
          contactPhone: data.contactPhone,
          version: data.version
        },
        settings: {
          shortDateFormat: data.shortDateFormat,
          marketingEmails: data.marketingEmails
        }
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify({
            id: userId,
            __typename: 'Profile'
          }),
          fields: {
            firstName() {
              return data.firstName!
            },
            lastName() {
              return data.lastName!
            },
            contactPhone() {
              return data.contactPhone!
            }
          }
        })
        cache.modify({
          id: cache.identify(userSettings.settings),
          fields: {
            shortDateFormat(cachedName) {
              return data.shortDateFormat!
            },
            marketingEmails(cachedName) {
              return data.marketingEmails!
            }
          },
        })
      }
    })
  })

  const options = [
    {
      label: "PROFILE",
      key: "PROFILE",
      content: <ProfileForm dataTestId={`${dataTestId}-profile`}/>
    },
    {
      label: "SETTINGS",
      key: "SETTINGS",
      content: <SettingsForm dataTestId={`${dataTestId}-settings`}/>
    },
    {
      label: "NOTIFICATIONS",
      key: "NOTIFICATIONS",
      content: <NotificationsForm dataTestId={`${dataTestId}-notifications`}/>
    }
  ]

  const onTabClicked = (index: number) => {
    if(index < 0) return
    const tab = options[index]
    if (tab.key === 'PROFILE') {
      setShowEmail(true)
      return
    }

    setShowEmail(false)
  }

  return <DialogV2
    dataTestId={dataTestId}
    title={title}
    onClose={onCancel}
    primaryAction={()=>null}
    formName={FORM_NAME}
    submitting={isSubmitting}
    open={open}
    PaperProps={{
      sx:{
        width:"594px",
        minHeight: "556px"
      }
    }}
  >
    <FormProvider {...form}>
      <Form
        autoComplete={'false'}
        name={'account_form'}
        id={FORM_NAME}
        onSubmit={onSave}
      >
        <Tabs
          options={options}
          onClick={onTabClicked}
          dataTestId={`${dataTestId}-tabs`}
        />
      </Form>
      {
        showEmail && (
          <>
            <SettingsSubHeader>Email</SettingsSubHeader>
            <ChangeEmail dataTestId={`${dataTestId}-changeEmail`}/>
          </>
        )
      }
    </FormProvider>
  </DialogV2>
}

export const SettingsSubHeader = ({ children }) => {
  return <Typography variant={'h5'}>{children}</Typography>
}