import {
  EmptyList,
  SimpleDataGrid,
  Typography
} from 'saga-library/src'
import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import {
  REPORT_COLUMNS,
  ReportCategoryType,
  ReportColumnDisplayFormatter,
  ReportResults as ReportResultsType,
  ReportType
} from '../../../types/Report'
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined'
import { useParams } from 'react-router-dom'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import {
  GET_APPOINTMENT_REPORT_RESULT,
  GET_BILLING_REPORT_RESULT,
  GET_PATIENT_REPORT_RESULT
} from '../../../graphql-definitions'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import { HasPermissionTemplate } from 'components/HasPermissionTemplate'
import { LoadingSpinner } from 'components/LoadingScreen'
import moment from 'moment-timezone'
import { GridColDef } from '@mui/x-data-grid'
import { useAccountContext } from '../../../providers/AccountContext'
import { DATE_FORMAT } from '../../../utils/SettingsConstants'
import { ReportResultsForm } from './ReportResultsForm'

export interface ReportResultsProps {
  report: ReportType
}

export const ReportResults = ({report}:ReportResultsProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const { getUserSetting } = useAccountContext()
  const apolloClient = useApolloClient()
  let dateFormat = getUserSetting(DATE_FORMAT) as string
  const [ reportResults, setReportResults ] = useState<ReportResultsType | null>(null)

  const [ getAppointmentReportResults, { loading: loadingAppointmentReport } ] = useLazyQuery(GET_APPOINTMENT_REPORT_RESULT)
  const [ getBillingReportResults, { loading: loadingBillingReport } ] = useLazyQuery(GET_BILLING_REPORT_RESULT)
  const [ getPatientReportResults, { loading: loadingPatientReport } ] = useLazyQuery(GET_PATIENT_REPORT_RESULT)

  useEffect(() => {
    setReportResults(null)
  }, [report.id])

  const runReport = async (data) => {
    setReportResults(null)

    let inputData = { ...data }
    inputData.parameters = inputData?.parameters?.map((parameter) => {
      if (Object.hasOwn(parameter, 'objectIdListReportRunParameter')) {
        const objectIds = parameter.objectIdListReportRunParameter
        return {
          ...parameter,
          objectIdListReportRunParameter: {
            ...objectIds,
            all: !!objectIds?.value?.includes('allPractitioners')
          }
        }
      }
      return parameter
    })

    const reportCategories = {
      [ReportCategoryType.APPOINTMENT]: {
        query: getAppointmentReportResults,
        path: 'tenant.report.runAppointment'
      },
      [ReportCategoryType.BILLING]: {
        query: getBillingReportResults,
        path: 'tenant.report.runBilling'
      },
      [ReportCategoryType.PATIENT]: {
        query: getPatientReportResults,
        path: 'tenant.report.runPatient'
      }
    }

    await reportCategories[report.category].query({
        variables: {
          tenantId: tenant_id,
          input: inputData,
        },
        fetchPolicy: 'no-cache',
        onCompleted: async (data) => {
          const reportResults = _get(data, reportCategories[report.category].path) as ReportResultsType
          setReportResults(reportResults)
          apolloClient.cache.modify({
            id: apolloClient.cache.identify({ id: report.id, isReference: report.isReference, __typename: 'Report' }),
            fields: {
              lastRun(lastRun) {
                return reportResults.runDate
              },
            },
          })
        },
        onError: error => {
          console.error(JSON.stringify(error, null, 2))
          showErrorAlert("Report couldn't run")
        }
      }
    )
  }

  return (
    <HasPermissionTemplate
      requiredType={report.category === ReportCategoryType.BILLING
        ? PermissionType.ReportingBilling
        : PermissionType.ReportingPatientAndAppointment
      }
      requiredPermission={Permission.READONLY}
    >
      <Box display={"flex"} flexDirection={"column"} flex={"1 0 auto"}>
        <ReportResultsForm report={report} onSubmit={runReport} />
        {loadingAppointmentReport || loadingBillingReport || loadingPatientReport
          ? <LoadingSpinner label={"Generating report..."} sx={{height: "100%"}}/>
          : <ReportResultList reportResults={reportResults} dateFormat={dateFormat}/>
        }
      </Box>
    </HasPermissionTemplate>
  )
}

interface ReportResultListProps {
  reportResults: ReportResultsType | null
  dateFormat: string
}

const ReportResultList = ({reportResults, dateFormat}:ReportResultListProps) => {
  if (reportResults === null) {
    return (
      <EmptyList
        icon={AnalyticsIcon}
        message={
          <Typography>
            <Box component={"span"} sx={{ fontWeight: "400" }}>Configure report parameters and click </Box>
            <Box component={"span"} sx={{ fontWeight: "700" }}>Run Report</Box>
            <Box component={"span"} sx={{ fontWeight: "400" }}>.</Box>
          </Typography>
        }
      />
    )
  }

  const initialColumns: GridColDef[] = reportResults.columns.map(column => ({
    field: column.id.toString(),
    headerName: (REPORT_COLUMNS[column.name]?.selectedLabel || REPORT_COLUMNS[column.name]?.label) ?? column.name,
    valueFormatter: value => FormatReportResult(column.format, value, dateFormat),
  }))
  const initialRows = reportResults.results.map((reportResult, index) => {
    const row: { [key: string]: string | number | null } = { id: index }
    reportResult.columns.forEach(column => {
      row[column.columnId.toString()] = column.result
    })
    return row
  })

  return (
    <SimpleDataGrid
      initialColumns={initialColumns}
      initialRows={initialRows}
      dataTestId={'report-results'}
    />
  )
}

const FormatReportResult = (format: ReportColumnDisplayFormatter, value: string | null, dateFormat: string) => {
  let formattedResult: string
  switch (format)
  {
    case ReportColumnDisplayFormatter.DATE:
      formattedResult = value ? moment(value).format(dateFormat) : ''
      break
    default:
      formattedResult = value ?? ''
  }
  return formattedResult
}