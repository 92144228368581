import React from "react";
import { useParams } from 'react-router-dom'
import { Permission, PermissionType } from "../types/settings/Permission";
import { useAccountContext } from "../providers/AccountContext";
import { usePageTitleContext } from "../providers/PageTitleContextProvider";
import { Typography } from "@mui/material";
import moment from "moment-timezone";
import {
  LabsAndInvestigationsDashboardException
} from "../apps/dashboard/components/LabsAndInvestigationsDashboardException";

export const Dashboard = React.memo(() => {
  const { tenant_id } = useParams()

  const { getTenantName } = useAccountContext()
  const { setAreaTitle } = usePageTitleContext()
  const tenantName = getTenantName(tenant_id)

  const { userHasPermission } = useAccountContext()
  const hasChartPermissions = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  React.useEffect(() => {
    setAreaTitle('Dashboard')
  }, [setAreaTitle])

    if (hasChartPermissions)
      return (
        <div>
          <LabsAndInvestigationsDashboardException />
        </div>
      )
    else
      return (
        <div>
          <Typography variant={"h2"}>{generateGreeting()}</Typography>
          <div style={{ marginTop: 16, textAlign: "left" }}>{tenantName}</div>
        </div>
      )
})

const generateGreeting = () => {
  const hour = moment().hour()

  if (hour > 16) {
    return 'Good evening'
  }

  if (hour > 11) {
    return 'Good afternoon'
  }

  return 'Good morning'
}
