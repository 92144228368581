import { useLocationContext } from "../providers/LocationContextProvider";
import React from "react";
import { FilterSelect } from "saga-library/src";
import { Box, useTheme } from "@mui/material";

type LocationDropdownProps = {
  onChange?: (any) => void
  dataTestId?: string
}

const allLocationsOption = {
  label: "All locations",
  value: null
}
export const LocationDropdown = ({onChange:parentOnChange, dataTestId}:LocationDropdownProps) => {
  const theme = useTheme()
  const { locations, selectedLocationId, handleLocationChange } = useLocationContext()

  const onChange = (e) => {
    if(parentOnChange) {
      parentOnChange(e.value)
    }
    handleLocationChange(e.value)
  }

  return (
    <Box sx={{ pl: 1 }}>
      <FilterSelect
        dataTestId={dataTestId}
        options={[allLocationsOption, ...(locations.map(location => {return {label: location.name, value: location.id}}))]}
        onSelect={onChange}
        defaultSelectedValue={selectedLocationId}
        size={"large"}
        color={theme.palette.greys.dark}
        sx={{justifyContent: 'left'}}
      />
    </Box>
  )
}
