import { NewEventDialog } from "./Event/NewEventDialog";
import { ScheduleDialogActions } from "../../../utils/ScheduleProviderUtils";
import { EditEventDialog } from "./Event/EditEventDialog";
import React from "react";
import { useScheduleContext } from "../../../providers/ScheduleContextProvider";

export interface EventDialogOpenProps {
  action: ScheduleDialogActions,
  eventData: any,
}

interface EventDialogHandlerProps {
  open: EventDialogOpenProps | null,
  onClose: () => void
}
export const EventDialogHandler = ({
  open,
  onClose,
  }:EventDialogHandlerProps) => {
  const { getScheduleData, selectedScheduleIds } = useScheduleContext()

  if(!open){
    return null
  }
  const scheduleId =  open.eventData?.resource || selectedScheduleIds[0]
  const practitionerName = getScheduleData(scheduleId)?.practitionerName || ''

  if(open.action === ScheduleDialogActions.CREATE) {
    return <NewEventDialog
      open={true}
      onClose={onClose}
      scheduleId={scheduleId}
      practitionerName={practitionerName}
      eventData={open.eventData}
    />
  }

  if(open.action === ScheduleDialogActions.EDIT) {
    return <EditEventDialog
      open={true}
      onClose={onClose}
      scheduleId={scheduleId}
      practitionerName={practitionerName}
      eventData={open.eventData}
    />
  }

  return null
}