import moment from 'moment-timezone'
import { useAccountContext } from "../providers/AccountContext"
import { DATE_FORMAT } from "../utils/SettingsConstants"

export const useFormattedDate = (date, includeTimestamp = false, includeRelativeText = false) => {
  const { getUserSetting } = useAccountContext()
  const timeFormat = ' • h:mm A'

  if(!date){
    return ""
  }

  const momentDate = moment(date)
  let dateFormat = getUserSetting(DATE_FORMAT) as string

  if(includeTimestamp) {
    dateFormat += timeFormat
  }

  if (includeRelativeText) {
    return momentDate.calendar(null,{
      sameDay: '[Today]' + timeFormat,
      lastDay: '[Yesterday]' + timeFormat,
      nextDay: dateFormat,
      nextWeek: dateFormat,
      lastWeek: dateFormat,
      sameElse: dateFormat
    })
  }

  return momentDate.format(dateFormat)
}