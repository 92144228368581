import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ServiceFeeModifierSelect } from "../../../../../components/ServiceFeeModifierSelect";
import { AbClaimMultiple, ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface BatchControlledServiceFeeModifierSelectProps {
  serviceCodeId: string | null | undefined
  inputProps?: object
  autoFocus?: boolean
  sx?: SxProps<Theme>
  batchUpdateMultiple: AbClaimMultiple
}

export const BatchControlledServiceFeeModifierSelect = ({
  serviceCodeId,
  inputProps = {},
  autoFocus,
  sx,
  batchUpdateMultiple
}: BatchControlledServiceFeeModifierSelectProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <ServiceFeeModifierSelect
      serviceCodeId={serviceCodeId}
      inputProps={inputProps}
      sx={sx}
      batchSx={batchUpdateStyleSearch(dirtyFields.serviceCodeFeeModifiers)}
      batchUpdateMultiple={batchUpdateMultiple}
      autoFocus={autoFocus}
    />
  )
}
