import { Box, SxProps } from "@mui/material";
import { BackButton, SaveButton, Section } from "saga-library/src";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface PatientSectionHeaderProps {
  sectionLabel: string | React.ReactNode
  showBackButton?: boolean
  onBack?: () => void
  showSave?: boolean
  disableSave?: boolean
  actions?: React.ReactNode
  dataTestId: string
  lineclamp?: number
  sx?: SxProps
  formName?: string
  submitting?: boolean
}

export const PatientSectionHeader = ({
  sectionLabel,
  showBackButton=false,
  onBack,
  showSave=true,
  disableSave=false,
  actions,
  dataTestId,
  lineclamp,
  sx,
  formName,
  submitting
}:PatientSectionHeaderProps) => {
  const navigate = useNavigate()

  if (showSave && !formName) {
    console.error("Save button was enabled but no form name was provided.")
  }

  return (
    <Box display={"flex"} alignItems={'center'} gap={1} mb={1} sx={sx}>
      {showBackButton && <BackButton dataTestId={`${dataTestId}-backbutton`} onClick={onBack ? onBack : () => navigate(-1)} sx={{mx:-1}}/>}
      <Section.Header
        dataTestId={`${dataTestId}-sectionLabel`}
        lineclamp={lineclamp}
        sx={{mb: 0, flex: "1 1 100%"}}
      >
        {sectionLabel}
      </Section.Header>
      {actions}
      {showSave &&
        <SaveButton
          dataTestId={dataTestId}
          form={ formName || "default" }
          submitting={!!submitting && !disableSave}
          disabled={disableSave}
        />
      }
    </Box>
  )
}