import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { schema } from "../util/appointmentStateValidation";
import { useMutation } from "@apollo/client";
import { DELETE_APPOINTMENT_STATE, UPDATE_APPOINTMENT_STATE } from "../../../../graphql-definitions";
import { AppointmentStateForm } from "./AppointmentStateForm";
import { ConfirmationDialog, DialogV2, RemoveButton } from "saga-library/src";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AppointmentState, AppointmentStateInput } from "../../../../types/schedule";

const FORM_NAME = "edit_appointment_state_form"

interface EditAppointmentStateModalProps {
  open: boolean
  onClose: () => void
  appointmentState: AppointmentState
}

export const EditAppointmentStateModal = ({
  open,
  onClose,
  appointmentState,
}: EditAppointmentStateModalProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [updateAppointmentState] =
    useMutation(UPDATE_APPOINTMENT_STATE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment state has been saved.')
        reset(originalDefault)
        onClose()
      },
      onError: (error) => {
        showErrorAlert("Appointment state couldn't be saved.")
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const originalDefault = {
    name: appointmentState.name,
    color: appointmentState.color,
    category: appointmentState.category,
    canEnterExamRoom: appointmentState.canEnterExamRoom,
    blocksSchedule: appointmentState.blocksSchedule,
    version: appointmentState.version
  }

  const formMethods = useForm<AppointmentStateInput>({
    defaultValues: originalDefault,
    schema: schema,
  })
  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
      reset(originalDefault)
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset(originalDefault)
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    await updateAppointmentState({
      variables: {
        tenantId: tenant_id,
        appointmentStateId: appointmentState.id,
        appointmentStateInput: data
      }
    })
  })

  const [deleteCustomAppointmentState] =
    useMutation(DELETE_APPOINTMENT_STATE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment state has been deleted.')
        reset(originalDefault)
        onClose()
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const deleteAppointmentState = async() => {
    await deleteCustomAppointmentState({
      variables: {
        tenantId: tenant_id,
        appointmentStateId: appointmentState.id,
        version: appointmentState.version
      },
      update(cache) {
        const normalizedId = cache.identify({ id: appointmentState.id, __typename: 'AppointmentState' })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
    })
    setOpenDeleteDialog(false)
  }

  return (
    <>
      <DialogV2
        title={`Edit appointment state (${appointmentState.name})`}
        open={open}
        onClose={handleClose}
        primaryAction={()=>null}
        overridePrimaryComponent={
          <PermissionButton
            name={'saveCustomAppointmentState'}
            onClick={onSubmit}
            requiredType={PermissionType.Schedule}
            requiredPermission={Permission.READWRITE}
            dataTestId={'editAppointmentStateModal-save-button'}
            loading={isSubmitting}
            type={'submit'}
          >
            Save
          </PermissionButton>
        }
        headerActions={!appointmentState.isDefault ? [
          <RemoveButton
            key={'editAppointmentStateModal-delete'}
            onClick={() => setOpenDeleteDialog(true)}
            dataTestId={'editAppointmentStateModal'}
          />
        ] : []}
        size={'sm'}
        data-testid={'editAppointmentStateModal-dialog'}
      >
        <FormProvider {...formMethods}>
          <AppointmentStateForm
            isDefault={appointmentState.isDefault}
            onSubmit={onSubmit}
          />
        </FormProvider>
      </DialogV2>
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={'Delete appointment state?'}
        message={'Once deleted, this appointment state cannot be used in future appointments. Existing appointments in this appointment state will not be affected. This action can’t be undone.'}
        primaryAction={deleteAppointmentState}
        primaryLabel={'Delete'}
        dataTestId={'editAppointmentStateModal-dialog-delete'}
      />
    </>
  )
}


