import { gql } from "@apollo/client";

export const GET_SHARED_FILE_READ_SAS_TOKEN = gql`
  query GetReadSasToken ($tenantId: ID!, $blobName: String!) {
    tenant(id: $tenantId) {
      sharedFile {
        readSasToken (blobName: $blobName)           
      }
    }
  }
`