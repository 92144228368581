import { Box } from "@mui/material";
import React from "react";

export const FormRow = ({ children, sx={} }) => {
  return <Box
    display={'flex'}
    alignItems={'start'}
    gap={2}
    sx={{
      ...sx
    }}>
    {children}
  </Box>
}