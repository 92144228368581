import React from "react";
import { useParams } from "react-router-dom";
import { QueryReference, useBackgroundQuery, useMutation, useSuspenseQuery } from '@apollo/client'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import usePatientCache from "../../../utils/usePatientCache";
import _get from "lodash/get";
import {
  GET_PATIENT_CURRENT_VITALS,
  GET_PATIENT_PROFILE_ONLY,
  GET_PATIENT_RELATIONSHIPS,
  UPDATE_PATIENT_BILLING,
  UPDATE_PATIENT_NOTES,
  UPDATE_PATIENT_PROFILE,
  UPDATE_PATIENT_RELATIONSHIPS
} from '../../../graphql-definitions'
import { PatientProfileType } from "../PatientTypes";
import { PatientNotesType } from "../components/profile/PatientNotes";
import { PatientBillingType } from "../components/profile/PatientBilling";
import { PatientRelationshipsType } from "../components/profile/PatientRelationships";
import { GET_PATIENT_ALL_VITALS_DATA } from '../../../graphql-definitions/tenant/patient/PatientProfileQueries'
import { ClinicPractitioner, ReferralPractitioner } from "../../../types/settings";
import { omit } from "lodash";
import { Pharmacy } from "../../../types";
import { filterIdentifiers } from "../util/filterIdentifiers";

type PatientProfileDataType = PatientProfileType & PatientBillingType & PatientNotesType

interface PatientProfileContextInterface {
  profileData: PatientProfileDataType
  patientRelationshipRef: QueryReference
  patientCurrentVitalsRef: QueryReference
  patientAllVitalsDataRef: QueryReference
  updatePatientProfile: (patientInput: PatientProfileType, onSuccess: () => void ) => void
  updatePatientNotes: (patientInput: PatientNotesType, onSuccess: (updated) => void) => void
  updatePatientBilling: (patientInput: PatientBillingType) => void
  updatePatientRelationships: (patientInput: PatientRelationshipsType) => void
}

const defaultPatientProfileContext: PatientProfileContextInterface = {
  profileData: {} as PatientProfileDataType,
  patientRelationshipRef: {} as QueryReference,
  patientCurrentVitalsRef: {} as QueryReference,
  patientAllVitalsDataRef: {} as QueryReference,
  updatePatientProfile: (patientInput: PatientProfileType, onSuccess: () => void) => null,
  updatePatientNotes: (patientInput: PatientNotesType, onSuccess: (updated) => void) => null,
  updatePatientBilling: (patientInput: PatientBillingType) => null,
  updatePatientRelationships: (patientInput: PatientRelationshipsType) => null,
}

const PatientProfileContext = React.createContext(defaultPatientProfileContext)

export const PatientProfileProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const updateCache = usePatientCache()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenantIdentifiers } = useTenantContext()
  const [updatePatientProfileGQL, { error: updateProfileError, loading: updateProfileLoading }] = useMutation(UPDATE_PATIENT_PROFILE)
  const [updatePatientNotesGQL, { error: updateNotesError, loading: updateNotesLoading }] = useMutation(UPDATE_PATIENT_NOTES)
  const [updatePatientBillingGQL, { error: updateBillingError, loading: updateBillingLoading }] = useMutation(UPDATE_PATIENT_BILLING)
  const [updatePatientRelationshipsGQL, { error: updateRelationshipsError, loading: updateRelationshipsLoading }] = useMutation(UPDATE_PATIENT_RELATIONSHIPS)

  const { data } = useSuspenseQuery(GET_PATIENT_PROFILE_ONLY, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const profileData = _get(data, 'tenant.patient.profile', {}) as PatientProfileDataType

  const [ patientRelationshipRef ] = useBackgroundQuery(GET_PATIENT_RELATIONSHIPS, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const [ patientCurrentVitalsRef ] = useBackgroundQuery(GET_PATIENT_CURRENT_VITALS, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const [ patientAllVitalsDataRef ] = useBackgroundQuery(GET_PATIENT_ALL_VITALS_DATA, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id
    }
  })

  const patientProfileError = (error) => {
    console.error(JSON.stringify(error, null, 2))
    if (error.networkError?.result?.errors?.length > 0 && error.networkError?.result?.errors[0].extensions?.userError === true){
      showErrorAlert(error.networkError.result.errors[0].message)
    } else {
      showErrorAlert('Patient couldn\'t be saved.')
    }
  }

  const updatePatientProfile = async(patientInput, onSuccess) => {
    const cleanedPatientData = processPatientData({...patientInput}, tenantIdentifiers)

    await updatePatientProfileGQL({
      variables: {
        tenantId: tenant_id,
        patientId: patient_id,
        patientData: cleanedPatientData,
      },
      onCompleted: (data) => {
        const updatedPatient = _get(data,'tenant.patient.updatePatientProfile',null)
        updateCache(updatedPatient)
        onSuccess()
        showSuccessAlert(`Patient has been saved.`)
      },
      onError: (error:any) => {
        patientProfileError(error)
      }
    })
  }

  const updatePatientNotes = async(patientInput, onSuccess) => {
    patientInput.notes = patientInput.notes.filter((note) => note.noteText !== "")

    await updatePatientNotesGQL({
      variables: {
        tenantId: tenant_id,
        patientId: patient_id,
        patientData: patientInput,
      },
      onCompleted: (data) => {
        const updatedPatient = _get(data,'tenant.patient.updatePatientNotes',null)
        onSuccess(updatedPatient)
        updateCache(updatedPatient)
        showSuccessAlert(`Patient has been saved.`)
      },
      onError: (error:any) => {
        patientProfileError(error)
      }
    })
  }

  const updatePatientBilling = async(patientInput) => {
    const cleanedPatientData = processPatientData(patientInput, tenantIdentifiers)

    await updatePatientBillingGQL({
      variables: {
        tenantId: tenant_id,
        patientId: patient_id,
        patientData: cleanedPatientData,
      },
      onCompleted: (data) => {
        const updatedPatient = _get(data,'tenant.patient.updatePatientBilling',null)
        updateCache(updatedPatient)
        showSuccessAlert(`Patient has been saved.`)
      },
      onError: (error:any) => {
        patientProfileError(error)
      }
    })
  }

  const updatePatientRelationships = async(patientInput) => {
    const cleanedPatientData = processPatientRelationships(patientInput)

    await updatePatientRelationshipsGQL({
      variables: {
        tenantId: tenant_id,
        patientId: patient_id,
        patientData: cleanedPatientData,
      },
      onCompleted: (data) => {
        showSuccessAlert(`Patient has been saved.`)
      },
      onError: (error:any) => {
        patientProfileError(error)
      }
    })
  }


  const providerValues = {
    profileData,
    patientRelationshipRef,
    patientCurrentVitalsRef,
    patientAllVitalsDataRef,
    updatePatientProfile,
    updatePatientNotes,
    updatePatientBilling,
    updatePatientRelationships,
  }

  return (
    <PatientProfileContext.Provider value={providerValues}>
      { children }
    </PatientProfileContext.Provider>
  )
}

export const processPatientRelationships = (data) => {
  if (data.referralPractitioners) {
    data.referralPractitionerLocationIds = data.referralPractitioners.filter((rp: ReferralPractitioner) => rp).map(
      (rp: ReferralPractitioner) => rp.locationId
    )
  }
  data = omit(data, 'referralPractitioners')

  if (data.clinicPractitioners) {
    data.clinicPractitionerIds = data.clinicPractitioners.filter((p: ClinicPractitioner) => p).map((p: ClinicPractitioner) => p.id || p.practitionerId)
    let primaryPractitioner = data.clinicPractitioners.find((p: ClinicPractitioner) => p.isPrimaryPractitioner)
    data.primaryClinicPractitionerId = primaryPractitioner?.id || primaryPractitioner?.practitionerId
  }
  data = omit(data, 'clinicPractitioners')

  if (data.familyPractitioners) {
    data.familyPractitionerIds = data.familyPractitioners.filter((rp: ReferralPractitioner) => rp).map(
      (rp: ReferralPractitioner) => rp.locationId
    )
  }
  data = omit(data, 'familyPractitioners')

  if (data.pharmacies) {
    data.pharmacyIds = data.pharmacies.filter((p: Pharmacy) => p).map(
      (p: Pharmacy) => p.id
    )
  }
  data = omit(data, 'pharmacies')

  if (data.familyRelationships) {
    data = omit(data, 'patientSelection')
    data = omit(data, 'typeSelection')
    data.familyRelationships = data.familyRelationships.filter((fr) => fr !== "")
  }

  return data
}

export const processPatientData = (data, tenantIdentifiers) => {
  delete data.gender

  if (data.identifiers) {
    data.identifiers = filterIdentifiers(data.identifiers, tenantIdentifiers)
  }

  if (data.serviceCode) {
    data.serviceCodeId = data.serviceCode.id
  }
  delete data.serviceCode

  if (data.diagnosticCodes) {
    data.diagnosticCodeIds = data.diagnosticCodes.map((dc) => {
      if (dc?.id) {
        return dc.id
      }
    })
  }
  delete data.diagnosticCodes

  data = processPatientRelationships(data)

  return data
}

export const usePatientProfileContext = () => {
  return React.useContext(PatientProfileContext)
}