import React, { useState } from 'react'
import { Box } from '@mui/material'
import { rules } from '../../../util/baseClaimValidation'
import { useWatch } from "saga-library/src/components/Form"
import { serviceCodeHospitalAdmission } from '../../../../../utils/ABClaimConstants'
import BatchControlledTextField from "../components/BatchControlledTextField";
import { MultipleValuesField } from '../components/MultipleValuesField';
import { BatchControlledBillingProfileSelect } from "../components/BatchControlledBillingProfileSelect";
import { BatchControlledPHNSearch } from "../components/BatchControlledPHNSearch";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledServiceCodeSearch } from "../components/BatchControlledServiceCodeSearch";
import { BatchControlledDiagnosticCodeInput } from "../components/BatchControlledDiagnosticCodeInput";
import { BatchControlledServiceFeeModifierSelect } from "../components/BatchControlledServiceFeeModifierSelect";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledFunctionalCenter } from "../components/BatchControlledFunctionalCenterInput";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import { BatchControlledReferralPractitionerSearch } from "../components/BatchControlledReferralPractitionerSearch";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { DiagnosticCodesInfoTooltip, FeeModifierInfoTooltip, ServiceCodeInfoTooltip } from "../../ClaimInfoTooltips";

const Form = ({ control, register, resetField, getValues, setValue, batchUpdateMultiple }) => {

  const [practitionerId, setPractitionerId] = useState(
    getValues('practitionerId')
  )

  const serviceCode = useWatch({
    control,
    name: 'serviceCode',
  })

  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  const diagnosticCodes = useWatch({
    control,
    name: 'diagnosticCodes'
  })

  const serviceCodeFeeModifiers = useWatch({
    control,
    name: 'serviceCodeFeeModifiers'
  })

  const handleBillingProfileChange = (billingProfile) => {
    setPractitionerId(billingProfile.practitionerId)
  }

  const multipleValuesWithTooltipStyle = {
    width: '80%',
    mr: '7px'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '48%',
        }}
      >
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-billingProfile'}
          label={'Practitioner'}
          multipleValuesState={batchUpdateMultiple.billingProfile}
          setUpdatedFields={() => setValue('billingProfile', '', { shouldDirty: true })}
        >
          <BatchControlledBillingProfileSelect
            label={'Practitioner'}
            name={'billingProfile'}
            onSelect={handleBillingProfileChange}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-patient'}
          label={'Patient'}
          multipleValuesState={batchUpdateMultiple.patient}
          setUpdatedFields={() => setValue('patient', '', { shouldDirty: true })}
        >
          <BatchControlledPHNSearch
            name={'patient'}
            label={"Patient"}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-serviceDate'}
          label={'Service date'}
          multipleValuesState={batchUpdateMultiple.serviceDate}
          setUpdatedFields={() => setValue('serviceDate', '', { shouldDirty: true })}
        >
          <BatchControlledDatePicker
            label={'Service date'}
            name={'serviceDate'}
          />
        </MultipleValuesField>
        <Box
          sx={{
            border: 1,
            borderRadius: '8px',
            borderColor: 'greys.light',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              color: 'greys.light',
              width: '100%',
              paddingLeft: '16px',
            }}
          >
            <MultipleValuesField
              dataTestId={'BatchUpdateClaimBase-serviceCode'}
              label={'Service code'}
              multipleValuesState={batchUpdateMultiple.serviceCode}
              setUpdatedFields={() => setValue('serviceCode', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledServiceCodeSearch
                label={'Service code'}
                name={'serviceCode'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={multipleValuesWithTooltipStyle}
              />
            </MultipleValuesField>
            <ServiceCodeInfoTooltip serviceCode={serviceCode}/>
          </Box>
          {serviceCode &&
            serviceCodeHospitalAdmission.includes(
              serviceCode['serviceCode']
            ) && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}
                justifyContent={'space-evenly'}
                sx={{
                  width: '80%',
                  paddingLeft: '16px',
                }}
              >
                <FormattedDatePicker
                  label={'Hospital admission date'}
                  name={'hospitalAdmissionDate'}
                  dataTestId={'BatchUpdateClaimBase-hospitalAdmissionDate'}
                />
              </Box>
            )}
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            alignContent={'center'}
            sx={{
              color: 'greys.light',
              width: '100%',
              paddingLeft: '16px',
            }}
          >
            <MultipleValuesField
              dataTestId={'BatchUpdateClaimBase-diagnosticCodes'}
              label={'Diagnostic code'}
              multipleValuesState={batchUpdateMultiple.diagnosticCodes}
              setUpdatedFields={() => setValue('diagnosticCodes', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledDiagnosticCodeInput
                label={'Diagnostic code'}
                name={'diagnosticCodes'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={multipleValuesWithTooltipStyle}
              />
            </MultipleValuesField>
            <DiagnosticCodesInfoTooltip diagnosticCodes={diagnosticCodes}/>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            sx={{
              color: 'greys.light',
              width: '100%',
              pl: 2,
            }}
          >
            <MultipleValuesField
              dataTestId={'BatchUpdateClaimBase-feeModifiers'}
              label={'Fee modifiers'}
              multipleValuesState={batchUpdateMultiple.serviceCodeFeeModifiers}
              setUpdatedFields={() => setValue('serviceCodeFeeModifiers', '', { shouldDirty: true })}
              sx={multipleValuesWithTooltipStyle}
            >
              <BatchControlledServiceFeeModifierSelect
                serviceCodeId={serviceCode?.id}
                sx={{
                  m: '8px 0',
                  ...multipleValuesWithTooltipStyle
                }}
                inputProps={{ maxLength: rules.feeModifiers.max }}
                batchUpdateMultiple={batchUpdateMultiple}
              />
            </MultipleValuesField>
            <FeeModifierInfoTooltip feeModifiers={serviceCodeFeeModifiers} />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
            sx={{
              pl: 2
            }}
          >
            <BatchControlledTextField
              label={'Calls'}
              type={'number'}
              name={'calls'}
              dataTestId={'BatchUpdateClaimBase-calls'}
              field={'calls'}
              inputProps={{ maxLength: rules.calls.max }}
              sx={{
                width: '30%',
              }}
              multipleValuesState={batchUpdateMultiple.calls}
              setUpdatedFields={() => setValue('calls', '', { shouldDirty: true })}
              InputProps={register('calls', { valueAsNumber: true })}
            />
            <BatchControlledTextField
              label={'Encounter'}
              type={'number'}
              name={'encounter'}
              dataTestId={'BatchUpdateClaimBase-encounter'}
              field={'encounter'}
              inputProps={{ maxLength: rules.encounter.max }}
              sx={{
                width: '30%',
                ml: 2
              }}
              multipleValuesState={batchUpdateMultiple.encounter}
              setUpdatedFields={() => setValue('encounter', '', { shouldDirty: true })}
              InputProps={register('encounter', { valueAsNumber: true })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '48%',
        }}
      >
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-facility'}
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.facility}
          setUpdatedFields={() => {
            setValue('facility', '', { shouldDirty: true })
          }}
        >
          <BatchControlledFacilityInput
            label={"Facility"}
            name={'facility'}
            practitionerId={practitionerId}
            serviceDate={serviceDate}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-functionalCenter'}
          label={'Functional centre'}
          multipleValuesState={batchUpdateMultiple.functionalCenter}
          setUpdatedFields={() => {
            setValue('functionalCenter', '', { shouldDirty: true })
          }}
        >
          <BatchControlledFunctionalCenter
            name={'functionalCenter'}
            label={'Functional centre'}
            onClear={() => resetField('functionalCenter')}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-LocationCode'}
          label={'Location'}
          multipleValuesState={batchUpdateMultiple.locationCode}
          setUpdatedFields={() => {
            setValue('locationCode', '', { shouldDirty: true })
          }}
        >
          <BatchControlledLocationCodeSelect
            label={'Location'}
            name={'locationCode'}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimBase-referralPractitioner'}
          label={'Referral practitioner'}
          multipleValuesState={batchUpdateMultiple.referralPractitioner}
          setUpdatedFields={() => {
            setValue('referralPractitioner', '', { shouldDirty: true })
          }}
        >
          <BatchControlledReferralPractitionerSearch
            label={'Referral practitioner'}
            name={'referralPractitioner'}
          />
        </MultipleValuesField>
        <BatchControlledTextField
          label={'Text'}
          name={'text'}
          dataTestId={'BatchUpdateClaimBase-text'}
          field={'text'}
          inputProps={{ maxLength: rules.text.max }}
          multiline={true}
          rows={6}
          multipleValuesState={batchUpdateMultiple.text}
          setUpdatedFields={() => setValue('text', '', { shouldDirty: true })}
          fullWidth={true}
        />
      </Box>
    </Box>
  )
}

export const BatchUpdateClaimBaseDetails = {
  Form: Form,
}
