import { Select } from "saga-library/src";
import { MenuItem } from "@mui/material";
import React from "react";
import { ScheduleType } from "../types/schedule/Schedule";
import { practitionerDisplayName, practitionerLocationDisplayName } from "saga-library/src/util/formatting";
import { useLocationContext } from "../providers/LocationContextProvider";

interface PractitionerSelectProps {
  name: string
  label: string
  schedules?: Array<ScheduleType>
  allowNullOption?: boolean
  disabled?: boolean,
  showLocation?: boolean
  onChange?: (any)=>void
  dataTestId?: string
}

export const PractitionerByScheduleIdsSelect = ({
  name,
  label,
  schedules,
  allowNullOption = false,
  disabled = false,
  showLocation = false,
  onChange,
  dataTestId
}: PractitionerSelectProps) => {
  const { getLocationName } = useLocationContext()

  return (
    <Select
      dataTestId={dataTestId}
      label={label}
      name={name}
      disabled={disabled}
      allowNullOption={allowNullOption}
      onChange={onChange}
    >
      {schedules && schedules.map(({ practitionerFirstName, practitionerLastName, practitionerId, locationId}, index) =>
        <MenuItem
          data-testid={`${dataTestId}-menuItem-${index}`}
          key={`schedule_item_${locationId}_${practitionerId}`}
          value={practitionerId}
        >
          {showLocation && locationId ? practitionerLocationDisplayName(practitionerFirstName, practitionerLastName, getLocationName(locationId)) : practitionerDisplayName(practitionerFirstName, practitionerLastName)}
        </MenuItem>
      )}
    </Select>
  )
}