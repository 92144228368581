import React, { useEffect } from "react";
import { Form, Section, useForm } from "saga-library/src";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { FormProvider } from "saga-library/src/components/Form";
import { PatientDetailsContainer } from "./components/PatientDetailsContainer";
import { PatientDetailsColumn } from "./components/PatientDetailsColumn";
import { usePatientProfileContext } from "../../providers/PatientProfileProvider";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { PatientPractitionerDetails, PatientPractitionerDetailsType } from "../PractitionerDetails";
import { PatientPharmacyDetails, PatientPharmacyDetailsType } from "../PharmacyDetails";
import { PersonalRelationshipDetails, PersonalRelationshipDetailsType } from "../PersonalRelationshipDetails";
import { useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { omit } from 'lodash'
import * as yup from 'yup'

export type PatientRelationshipsType =
  PatientPractitionerDetailsType &
  PatientPharmacyDetailsType &
  PersonalRelationshipDetailsType

export const PatientRelationshipDefaults = {
  version: "0",
  ...PatientPractitionerDetails.defaults,
  ...PatientPharmacyDetails.defaults,
  ...PersonalRelationshipDetails.defaults,
}

const schema = yup.object().shape({
  patientSelection: yup.object().nullable(),
  typeSelection: yup.string()
    .when('patientSelection', {
      is: (patientSelection) => patientSelection === undefined || patientSelection === null,
      then: (schema) => schema
        .nullable(),
      otherwise: (schema) => schema
        .required('Required'),
    })
});

const requiredSchema = yup.object().shape({
  typeSelection: yup.string().required("Required")
});

const FormName = "patient-relationships-form"
const SectionTitle = "Relationships"

export const PatientRelationships = ChartPanelHOC(SectionTitle, (props) => {
  const { patientRelationshipRef } = usePatientProfileContext()
  const { data } = useReadQuery(patientRelationshipRef)
  const patientRelationships: PatientRelationshipsType = omit(_get(data, 'tenant.patient.relationships', null), 'id')
  return patientRelationships && <PatientRelationshipsPanel profileData={patientRelationships} {...props} />
})

const PatientRelationshipsPanel = ({ profileData, ...props }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { updatePatientRelationships } = usePatientProfileContext()

  const formMethods = useForm<PatientRelationshipsType>({
    values: profileData,
    schema: schema,
    requiredSchema: requiredSchema,
  })

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FormName)
    return () => enableNavigationPrompt(false, FormName)
  }, [Object.keys(dirtyFields).length])

  const onSubmit = handleSubmit( async(data, event) => {
    if (event && event.target.name !== FormName) {
      return
    }
    await updatePatientRelationships(data)
    reset({}, { keepValues: true })
  })

  return (
    <FormProvider {...formMethods}>
      <Form
        autoComplete={'false'}
        name={FormName}
        id={FormName}
        onSubmit={onSubmit}
        style={{
          height:'100%',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}
      >
        <Section.Column
          sx={{ flex: "1 1 100%" }}
          header={<PatientSectionHeader formName={FormName} dataTestId={"patient-relationships-header"} sectionLabel={SectionTitle} submitting={isSubmitting} />}
        >
          <PatientDetailsContainer sx={{ pr: 1 }}>
            <PatientDetailsColumn>
              <PatientPractitionerDetails.Form dataTestId={"patient-practitionerDetails"}/>
            </PatientDetailsColumn>
            <PatientDetailsColumn>
              <PatientPharmacyDetails.Form dataTestId={"patient-pharmacyDetails"}/>
            </PatientDetailsColumn>
            <PatientDetailsColumn>
              <PersonalRelationshipDetails.Form dataTestId={"patient-personalRelationshipDetails"}/>
            </PatientDetailsColumn>
          </PatientDetailsContainer>
        </Section.Column>
      </Form>
    </FormProvider>
  )
}