import { Menu, MenuItem } from "@mui/material";
import React from "react";

export const EditBookingPreferenceMenu = ({bookingPreferences, anchorEl, onItemClick, onClose }) => {
  if (!bookingPreferences || bookingPreferences.length < 1 || !anchorEl) {
    return <></>
  }

  const getMenuOption = (bp, index) => {
    let id = bp.id || bp.templateBookingPreferenceId
    return (
      <MenuItem
        data-testid={`editBookingPreference-menuItem-${index}`}
        onClick={() => {
          onItemClick(bp)

        }}
        key={id}
      >
        Edit {bookingPreferences.length === 1 ? "booking preference" : bp.title}
      </MenuItem>
    )
  }

  return (
    <Menu
      data-testid={'editBookingPreference-menu'}
      anchorEl={anchorEl}
      open={!!bookingPreferences}
      onClose={onClose} //}
    >
      { bookingPreferences.map(getMenuOption) }
    </Menu>
  )
}
