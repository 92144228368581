import React, { useEffect } from "react"
import { Route, Routes } from 'react-router-dom'
import { Schedule as ScheduleSection } from '../apps/schedule/Schedule'
import { TemplatesDashboard } from "../apps/schedule/Templates/TemplatesDashboard";
import { EditTemplate } from "../apps/schedule/Templates/EditTemplate";
import { NewTemplate } from "../apps/schedule/Templates/NewTemplate";
import { ScheduleContextProvider } from "../providers/ScheduleContextProvider";
import { usePageTitleContext } from "../providers/PageTitleContextProvider";

export const Schedule = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Schedule')
  }, [setAreaTitle])

  return (
    <Routes>
      <Route element={<ScheduleContextProvider />}>
        <Route index element={<ScheduleSection />} />
      </Route>
      <Route path={'templates'} element={<TemplatesDashboard />}>
        <Route path={'t/:template_id'} element={<EditTemplate />}/>
        <Route path={'new'} element={<NewTemplate />}/>
      </Route>
    </Routes>
  )
}