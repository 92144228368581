import React, { useEffect, useState } from "react";
import SettingsHeader from "../../components/SettingsHeader";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";
import { TableList, type TableListHeaderConfig, TableListRowConfig } from 'saga-library/src'
import { useQuery } from '@apollo/client'
import { LIST_TASK_TYPES } from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import { TASK_TYPE_SCROLL_KEY } from '../../../../providers/UserInteractionContext'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import { TaskType } from '../../../../types/tasks/TaskType'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import PermissionButton from '../../../../components/PermissionButton'
import { Typography } from '@mui/material'
import { NewTaskTypeDialog } from './NewTaskTypeDialog'
import { EditTaskTypeDialog } from './EditTaskTypeDialog'

const columnsHeaders: TableListHeaderConfig[] = [
  {
    name: 'Name',
    sortable: false,
  },
]

const TaskTypeRow = (taskType: TaskType, onRowClicked: (taskType: TaskType) => void): TableListRowConfig => {
  return {
    onClick: () => onRowClicked(taskType),
    rowData: [
      {
        children: [
          <Typography
            key={`${taskType.id}-type`}
            variant={'body2'}
            sx={theme => ({color: theme.palette.greys.dark})}
          >
            {taskType.type}
          </Typography>
        ]
      },
    ],
    key: taskType.id
  }
}

export const TaskTypes = () => {
  const { tenant_id } = useParams()
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([])
  const [taskType, setTaskType] = useState<TaskType | null>(null)
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const { loading, error, data } = useQuery(LIST_TASK_TYPES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const taskTypeList = _get(data, 'tenant.task.type.list', [])
    setTaskTypes(sortTaskTypeList(taskTypeList))
  }, [data])

  const onRowClicked = (type: TaskType) => {
    setTaskType(type)
  }

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Task types'}
        dataTestId={'task-types'}
        actions={
          <PermissionButton
            dataTestId={'task-new-type-button'}
            name={'new-type'}
            loading={loading}
            onClick={() => {
              setOpenDialog(true)
            }}
            requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}
          >
            NEW TYPE
          </PermissionButton>
        }
      />
    }>
      <TableList
        scrollKey={TASK_TYPE_SCROLL_KEY}
        loading={loading}
        columns={columnsHeaders}
        rows={[...taskTypes.filter(t => t.active).map((task) => TaskTypeRow(task, onRowClicked))]}
        emptyListComponent={ { message: (error && "Unable to retrieve task types") || 'There are currently no task types'} }
        dataTestId={'tasks-taskTypeList'}
        leftAlignColumns={true}
      />
      <NewTaskTypeDialog open={openDialog} setOpen={setOpenDialog} />
      <EditTaskTypeDialog taskType={taskType} setTaskType={setTaskType} />
    </SettingsSectionColumn>
  )
}

export const sortTaskTypeList = (list: TaskType[]): TaskType[] => {
  return _sortBy(list, [taskType => taskType.type.toLowerCase()])
}