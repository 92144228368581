import moment from 'moment-timezone'
import { TimelineDocumentCategoryMappings, TimelineEventGroupType, TimelineEventType, TimelineType } from '../../../types/patients/Timeline'
import { practitionerDisplayName, recipientListDisplayName } from 'saga-library/src/util/formatting'
import { usePrescriptionUtils } from '../components/prescriptions/components/PrescriptionUtil'
import { DosageType, PrescriptionType } from '../../../types/Prescription'
import { AllergySeverityMap, AllergyType, getOnsetType } from '../../../types/Allergy'
import { ConditionAndProcedureType, ConditionSeverityMap } from '../../../types/ConditionAndProcedure'
import _groupBy from 'lodash/groupBy'
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import { AbLabResult, EncounterNote, FormType, LetterType, PatientAppointment } from '../../../types/patients'
import { useAccountContext } from '../../../providers/AccountContext'
import { DATE_FORMAT } from '../../../utils/SettingsConstants'
import { LinkedDocumentType } from '../../../types/Document'
import { getEncounterNoteSummary } from '../components/encounterNotes/EncounterNoteUtil'

const parseDetails = (...details: (string | null | undefined)[]): string[] => {
  return details.filter(d => !!d) as string[]
}

const getNameForLinkedDocument = (document: LinkedDocumentType): string => {
  return document.description || TimelineDocumentCategoryMappings.get(document.category)!.description
}

const convertAllergyToTimelineEvent = (allergy: AllergyType): TimelineEventType => {
  const severity = allergy.severity && AllergySeverityMap.get(allergy.severity)!.description
  return {
    id: allergy.id!,
    type: "ALLERGY",
    date: allergy.audit?.crePit,
    name: allergy.allergen!,
    details: parseDetails(
      severity,
      getOnsetType(allergy),
      allergy.reactionDescription
    ),
    crePit: allergy.audit?.crePit
  }
}

const convertAppointmentToTimelineEvent = (appointment: PatientAppointment): TimelineEventType => {
  const practitioner = appointment.practitioner ? practitionerDisplayName(appointment.practitioner.firstName, appointment.practitioner.lastName) : ""
  return {
    id: appointment.id,
    type: "APPOINTMENT",
    date: appointment.itemDate,
    name: appointment.type?.name || "Appointment",
    details: parseDetails(appointment.reasonForVisit, appointment.notes),
    practitionerName: practitioner,
    crePit: appointment.audit?.crePit
  }
}

const convertConditionAndProcedureToTimelineEvent = (conditionAndProcedure: ConditionAndProcedureType): TimelineEventType => {
  const severity = conditionAndProcedure.severity && ConditionSeverityMap.get(conditionAndProcedure.severity)!.description
  return {
    id: conditionAndProcedure.id!,
    type: "CONDITION_AND_PROCEDURE",
    date: conditionAndProcedure.startDate || conditionAndProcedure.audit?.crePit,
    name: conditionAndProcedure.description || "Condition or procedure",
    details: parseDetails(severity),
    crePit: conditionAndProcedure.audit?.crePit
  }
}

const convertEncounterNoteToTimelineEvent = (encounterNote: EncounterNote): TimelineEventType => {
  if (encounterNote.isLinkedDocument) {
    return convertLinkedDocumentToTimelineEvent(encounterNote.linkedDocument!)
  }

  const practitioner = encounterNote.practitioner ? practitionerDisplayName(encounterNote.practitioner.firstName, encounterNote.practitioner.lastName) : ""
  const summary = getEncounterNoteSummary(encounterNote)

  return {
    id: encounterNote.id!,
    type: "ENCOUNTER_NOTE",
    date: encounterNote.encounterNoteDisplayDate,
    name: encounterNote.template?.name || encounterNote.description || "Encounter note",
    details: parseDetails(practitioner, summary),
    crePit: encounterNote.audit?.crePit
  }
}

const convertFormToTimelineEvent = (form: FormType): TimelineEventType => {
  if (form.isLinkedDocument) {
    return convertLinkedDocumentToTimelineEvent(form.linkedDocument!)
  }

  return {
    id: form.id!,
    type: "FORM",
    date: form.formDate,
    name: form.name,
    details: parseDetails(form.description),
    crePit: form.audit?.crePit
  }
}

const convertLabAndInvestigationToTimelineEvent = (labAndInvestigation: AbLabResult): TimelineEventType => {
  if (labAndInvestigation.isLinkedDocument) {
    return convertLinkedDocumentToTimelineEvent(labAndInvestigation.linkedDocument!)
  }

  const { abLabResultObservationResults } = labAndInvestigation
  let details: string[] = []
  if (!!abLabResultObservationResults && abLabResultObservationResults.length > 0) {
    details = abLabResultObservationResults.length > 1 ? ["Multiple values"] : [abLabResultObservationResults[0]?.observationValue || ""]
  }

  return {
    id: labAndInvestigation.id,
    type: "LAB_AND_INVESTIGATION",
    date: labAndInvestigation.date,
    name: labAndInvestigation.description || (labAndInvestigation.isLab ? "Lab result" : "Investigation"),
    details: parseDetails(...details),
    isLab: labAndInvestigation.isLab,
    abnormal: labAndInvestigation.isAbnormal,
    crePit: labAndInvestigation.audit.crePit
  }
}

const convertLetterToTimelineEvent = (letter: LetterType): TimelineEventType => {
  if (letter.isLinkedDocument) {
    return convertLinkedDocumentToTimelineEvent(letter.linkedDocument!)
  }

  const practitioner = letter.practitioner ? practitionerDisplayName(letter.practitioner.firstName, letter.practitioner.lastName) : ""
  const recipients = !!letter.recipients && letter.recipients.length > 0
    ? `Recipient(s): ${recipientListDisplayName(letter.recipients[0].firstName, letter.recipients[0].lastName, letter.recipients.length)}`
    : ""

  return {
    id: letter.id!,
    type: "LETTER",
    date: letter.letterDate,
    name: letter.title,
    details: parseDetails(practitioner, recipients),
    crePit: letter.audit?.crePit
  }
}

const convertLinkedDocumentToTimelineEvent = (linkedDocument: LinkedDocumentType): TimelineEventType => {
  return {
    id: linkedDocument.id,
    type: TimelineDocumentCategoryMappings.get(linkedDocument.category)!.category,
    date: moment(linkedDocument.documentDate).toDate(),
    name: getNameForLinkedDocument(linkedDocument),
    details: parseDetails(TimelineDocumentCategoryMappings.get(linkedDocument.category)!.description),
    isLinkedDocument: true,
    crePit: linkedDocument.audit?.crePit
  }
}

const convertOtherDocumentToTimelineEvent = (otherDocument: LinkedDocumentType): TimelineEventType => {
  return convertLinkedDocumentToTimelineEvent(otherDocument)
}

const convertPrescriptionToTimelineEvent = (prescription: PrescriptionType, buildSummaryFromDosages: (dosages: DosageType[]) => string): TimelineEventType => {
  const { dosages } = prescription
  const dosageSummary: string = !!dosages && dosages.length > 0 ? buildSummaryFromDosages(dosages) : ""
  return {
    id: prescription.id!,
    type: "PRESCRIPTION",
    date: prescription.startDate,
    name: prescription.drug.name,
    details: parseDetails(dosageSummary),
    crePit: prescription.audit?.crePit
  }
}

const groupTimelineEvents = (events: TimelineEventType[], dateFormat: string): TimelineType => {
  const today = moment().startOf("day")
  const tomorrow = today.clone().add(1, "day")
  const lastWeek = today.clone().add(-1, "week")
  const lastMonth = today.clone().add(-1, "month")
  const lastYear = today.clone().add(-1, "year")
  const lastDecade = today.clone().add(-10, "year")

  let upcoming: TimelineEventGroupType[] = []
  let thisWeek: TimelineEventGroupType[] = []
  let thisMonth: TimelineEventGroupType[] = []
  let thisYear: TimelineEventGroupType[] = []
  let thisDecade: TimelineEventGroupType[] = []
  let lifetime: TimelineEventGroupType[] = []

  const groups = _groupBy(events, event => moment(event.date).format(dateFormat))

  Object.keys(groups).forEach((d) => {
    const date = moment(d, dateFormat).startOf('day')
    let dateFromNow = date.from(today)
    if (date.isSame(today, "day")) {
      dateFromNow = "today"
    } else if (date.isSame(tomorrow, "day")) {
      dateFromNow = "tomorrow"
    }
    const group = {
      date: d,
      dateFromNow: dateFromNow,
      events: groups[d].sort(TimelineEventSortPredicate)
    }

    if (date > today) {
      upcoming = [...upcoming, group]
    } else if (date > lastWeek) {
      thisWeek = [...thisWeek, group]
    } else if (date > lastMonth) {
      thisMonth = [...thisMonth, group]
    } else if (date > lastYear) {
      thisYear = [...thisYear, group]
    } else if (date > lastDecade) {
      thisDecade = [...thisDecade, group]
    } else {
      lifetime = [...lifetime, group]
    }
  })

  return {
    upcoming: upcoming.sort(TimelineSortPredicate),
    thisWeek: thisWeek.sort(TimelineSortPredicate),
    thisMonth: thisMonth.sort(TimelineSortPredicate),
    thisYear: thisYear.sort(TimelineSortPredicate),
    thisDecade: thisDecade.sort(TimelineSortPredicate),
    lifetime: lifetime.sort(TimelineSortPredicate)
  };
}

const appointmentTypeComparison = (a: TimelineEventType, b: TimelineEventType): number => {
  if (a.type === "APPOINTMENT" && b.type !== "APPOINTMENT") {
    return -1
  } else if (a.type !== "APPOINTMENT" && b.type === "APPOINTMENT") {
    return 1
  }

  return 0
}

const TimelineEventSortPredicate = (a: TimelineEventType, b: TimelineEventType): number => {
  return appointmentTypeComparison(a, b)
    || (nullOrUndefinedComparison(a.date, b.date) ?? moment(b.date).unix() - moment(a.date).unix())
    || (nullOrUndefinedComparison(a.crePit, b.crePit) ?? moment(b.crePit).unix() - moment(a.crePit).unix())
    || 0
}

const TimelineSortPredicate = (a: TimelineEventGroupType, b: TimelineEventGroupType): number => {
  return (nullOrUndefinedComparison(a.date, b.date) ?? moment(b.date).unix() - moment(a.date).unix())
    || 0
}

export const useTimelineUtils = () => {
  const { getUserSetting } = useAccountContext()
  const { buildSummaryFromDosages } = usePrescriptionUtils()

  const dateFormat = getUserSetting(DATE_FORMAT) as string

  return {
    convertAllergyToTimelineEvent,
    convertAppointmentToTimelineEvent,
    convertConditionAndProcedureToTimelineEvent,
    convertEncounterNoteToTimelineEvent,
    convertFormToTimelineEvent,
    convertLabAndInvestigationToTimelineEvent,
    convertLetterToTimelineEvent,
    convertOtherDocumentToTimelineEvent,
    convertPrescriptionToTimelineEvent: (prescription: PrescriptionType) => convertPrescriptionToTimelineEvent(prescription, buildSummaryFromDosages),
    groupTimelineEvents: (events: TimelineEventType[]) => groupTimelineEvents(events, dateFormat)
  }
}