import React from 'react'
import { ButtonGroup, Grow, Paper, Popper, ClickAwayListener, Box} from '@mui/material'
import { Button } from "./../Button";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface SplitButtonProps {
  primaryOption: SplitButtonOptionProps,
  options: SplitButtonOptionProps[]
  dataTestId?: string
}

export interface SplitButtonOptionProps {
  label: string,
  fx?: (v?:any) => void
  type?: 'button' | 'submit' | 'reset'
  formName?: string
}

export const SplitButton = ({dataTestId, primaryOption, options =[]}:SplitButtonProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return <>
    <ButtonGroup
      data-testid={dataTestId}
      variant="contained"
      ref={anchorRef}
      sx={{
        boxShadow:"none",
        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
          borderColor: "white",
          borderRight: "2px solid",
          width: "90px"
        },
        '& .MuiButtonGroup-grouped': {
          minWidth: "24px"
        }
    }}
    >
      <Button
        data-testid={`${dataTestId}-splitButton-${primaryOption.label}`}
        name={'splitButtonPrimary'}
        onClick={primaryOption.fx}
        type={primaryOption.type || 'button'}
        form={primaryOption.formName}
      >
        {primaryOption.label}
      </Button>

      <Button
        data-testid={`${dataTestId}-splitButton-DropdownButton`}
        name={'splitButtonDropdownButton'}
        sx={{ px:'0' }}
        onClick={handleToggle}
      >
        <ExpandMoreIcon />
      </Button>
    </ButtonGroup>
    <Popper
      sx={{
        zIndex: 1
      }}
      open={open}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Box display={'flex'} flexDirection={'column'}>
                {options.map((option, index) => (
                  <Button
                    data-testid={`${dataTestId}-splitButtonItem-${index}`}
                    name={`splitButton_${option.label}`}
                    key={`splitButton_${option.label}`}
                    disabled={index === 2}
                    variant={'text'}
                    onClick={()=> {
                      option.fx && option.fx()
                      setOpen(false)
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}

export default SplitButton