import React from 'react'
import { Box } from '@mui/material'
import { MainAppBar } from './MainAppBar'
import { Outlet, useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import { TenantSettingsMenu } from './TenantSettingsMenu'
import { AccountSettingsMenu } from './AccountSettingsMenu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Logo from "./Logo";
import { Section, Typography } from "saga-library/src";
import ChartSettingsMenu from "./ChartSettingsMenu";
import TaskSettingsMenu from "./TaskSettingsMenu";

export const RootLayout = ({ children }) => {
  return (
    <Box
      sx={ theme => ({
        m: theme.spacing(8, 0, 0, 0),
        width: '100%',
        textAlign: 'start',
        display: 'flex',
      })}
    >
      {children}
    </Box>
  )
}

export const BasicLayout = () => {
  return (
    <RootLayout>
      <MainAppBar />
      <Outlet />
    </RootLayout>
  )
}

export const UserLayout = () => {
  return (
    <RootLayout>
      <MainAppBar search rightMenu centerMenu displayName clinicName />
      <Box
        data-testid={"tenantRootLayout"}
        sx={ theme => ({
          m: theme.spacing(0, 0, 1, 1),
          width: '100%',
          overflow: 'auto'
        })}
      >
        <Outlet />
      </Box>
    </RootLayout>
  )
}

export const SettingsLayout = () => {
  return (
    <RootLayout>
      <MainAppBar rightMenu displayName />
      <Outlet />
    </RootLayout>
  )
}

export const LayoutWrapper = ({ dataTestId="", children }) => {
  return (
    <Box
      data-testid={dataTestId}
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        p: 0,
        m: 0
      }}
    >
      {children}
    </Box>
  )
}

export const MenuAndOutletLayout = ({ menu, dataTestId }: { menu: React.ReactNode, dataTestId?: string }) => {
  return (
   <LayoutWrapper dataTestId={dataTestId}>
      {menu}
      <Outlet />
    </LayoutWrapper>
  )
}

export const TenantSettingsLayout = () => {
  return (
    <MenuAndOutletLayout menu={<TenantSettingsMenu />} dataTestId={"tenantSettingsLayout"} />
  )
}

export const ChartSettingsLayout = () => {
  return (
    <MenuAndOutletLayout menu={<ChartSettingsMenu />} dataTestId={"chartSettingsLayout"} />
  )
}

export const TaskSettingsLayout = () => {
  return (
    <MenuAndOutletLayout menu={<TaskSettingsMenu />} dataTestId={"taskSettingsLayout"} />
  )
}

export const AccountSettingsLayout = () => {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        height: 'calc(100% - 8px)',
        width: '100%',
        maxWidth: '70rem',
        ml: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant={'h6'}
        sx={{ mt: '0.5rem', mb: '0.5rem' }}
      >
        <Link onClick={() => navigate(-1)} underline={'none'} sx={{cursor: 'pointer'}}>
          <ArrowBackIcon
            sx={{
              verticalAlign: 'middle',
              pr: '0.5rem',
            }}
            data-testid={"accounts-settings-layouts-go-back"}
          />
          <span>{'Go Back'}</span>
        </Link>
      </Typography>

      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
        }}
      >
        <AccountSettingsMenu />
        <Section.Column sx={{width: "825px"}} rightPadding={1}>
          <Outlet />
        </Section.Column>
      </Box>
    </Box>
  )
}

export const StaticPage = ({ children }) => {
  const navigate = useNavigate()

  return <Box>
    <Typography
      variant={'h6'}
      sx={{ my: 1, mr: 20 }}
      dataTestId={"layouts-staticPage-back"}
    >
      <Link onClick={() => navigate(-1)} underline={'none'}>
        <ArrowBackIcon
          sx={{
            verticalAlign: 'middle',
          }}
          data-testid={"layouts-staticPage-back-icon"}
        />
        <span>{'Go Back'}</span>
      </Link>
    </Typography>
    <Box margin={10} display={'flex'} justifyContent={'center'} alignItems={'center'}>{children}</Box>
  </Box>
}

export const FooterLayout = () => {
  const currentYear = new Date().getFullYear()
  return (
    <Box
      display='flex'
      flexDirection={'column'}
      sx={{
        position: 'fixed',
        bottom: 0,
        mb: 3,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Logo sx={{ width: 100 }} />
      <Typography variant={'body1'}>© {currentYear} Microquest</Typography>
    </Box>
  )
}

export const TosPrivacyPolicyLayout = ({ children }) => {
  return (
    <Box
      sx={{
        textAlign: 'left',
        wordWrap: 'break-word',
        fontFamily: 'Calibri, sans-serif',
        '& .MsoListParagraphCxSpFirst': {
          textIndent: '-.25in',
          mt:'0in',
          mr:'0in',
          mb:'0in',
          ml:'.5in',
          lineHeight:'110%',
          fontSize:'10.0pt',
          fontFamily:'Calibri,sans-serif',
        },
        '& .MsoListParagraphCxSpMiddle': {
          textIndent: '-.25in',
          mt:'0in',
          mr:'0in',
          mb:'0in',
          ml:'.5in',
          lineHeight:'110%',
          fontSize:'10.0pt',
          fontFamily:'Calibri,sans-serif',
        },
        '& .MsoListParagraphCxSpLast': {
          textIndent: '-.25in',
          mt:'0in',
          mr:'0in',
          mb:'6.0pt',
          ml:'.5in',
          lineHeight:'110%',
          fontSize:'10.0pt',
          fontFamily:'Calibri,sans-serif',
        },
        "& .MsoTitle":{
          margin:0,
          fontSize:"28.0pt",
          color:"#4472C4",
          letterSpacing:"-.5pt",
        },
        "& .MsoNormal": {
          mt:0,
          mr:0,
          mb:"6.0pt",
          ml:0,
          lineHeight:"110%",
          fontSize:"10.0pt"
        },
        "& span":{
          font:"7.0pt Times New Roman",
          fontFamily: 'Symbol',
          fontSize: '11.0pt',
          lineHeight: '110%',
        },
        "& .indent":{
          ml:".5in",
          textIndent:"-.25in"
        },
        "h1":{
          mt:"16.0pt",
          mr:0,
          mb:0,
          ml:".3in",
          textIndent:"-.3in",
          fontSize:"16.0pt",
          color:"#2F5496",
          fontWeight:"normal"
        },
        "h2":{
          mt:"4.0pt",
          mr:0,
          mb:0,
          ml:".4in",
          textIndent:"-.4in",
          fontSize:"14.0pt",
          color:"#404040",
          fontWeight:"normal"
        },
        "h3":{
          mt:0,
          mr:0,
          mb:"6.0pt",
          ml:".5in",
          textIndent:"-.5in",
          fontSize:"10.0pt",
          color:"#404040",
          lineHeight:"110%",
          fontWeight:"normal"
        }
      }}
    >
      {children}
    </Box>
  )

}