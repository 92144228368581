import React from "react";
import { Section } from "saga-library/src";
import { Switch, Typography } from "saga-library/src";
import { FormFragment } from "../../../../components/PermissionForm";
import UserSearch from "../../../../components/UserSearch";
import UserRoleSelectItem from "../../../../components/UserSelectRoleListItem";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { useQuery } from "@apollo/client";
import { LIST_PRACTITIONERS } from "../../../../graphql-definitions";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { User } from "../../../../types/account";

const defaults = {
  active: true,
  user: null,
}

export const LinkedUserAccount = ({disabled = false}) => {
  const { control } = useFormContext();
  const { tenant_id } = useParams();

  const { data } = useQuery(LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-first',
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const practitionerList = _get(data, 'tenant.practitioner.list', null)

  return (
    <Controller
      name={'user'}
      control={control}
      render={({ field: { value, onChange } }) => (
        <UserSearch
          name={'user_search'}
          searchLabel={'Linked user account'}
          onChange={onChange}
          value={value}
          userSelectItem={UserRoleSelectItem}
          getOptionDisabled={(option) =>
            (practitionerList.some(p => p.userId === option.id) || option.active === false)
          }
          dataTestId={"administrationDetails-userSearch"}
          disabled={disabled}
        />
      )}
    />
  )
}

const Form = ({requiredPermissionType}) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Administration</Section.Header>
        <Switch
          name={'active'}
          label={'Active'}
          dataTestId={"administrationDetails-active-switch"}
        />
        <Typography variant={'body2'} dataTestId={"administrationDetails-inactive"} sx={{pt: 1}}>
          Inactive practitioners and their billing profiles won’t appear when searching for practitioners or billing profiles.
        </Typography>
        <Typography variant={'body1'} sx={{pt: 2}} dataTestId={"administrationDetails-linked"}>
          <b>Linked user account</b>
        </Typography>
        <Typography variant={'body2'} dataTestId={"administrationDetails-grant"}>
          Grant a user account access to act as this practitioner.
        </Typography>
        <LinkedUserAccount />
      </Section.FormSection>
    </FormFragment>
  )
}

export type AdministrationDetailsType = {
  active: boolean,
  user?: User | null,
}


export const AdministrationDetails = {
  Form: Form,
  defaults: defaults,
}
