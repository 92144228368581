import { gql } from "@apollo/client";

export const REF_PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT = gql`
  fragment RefPractitionerIdentifierDetails on ReferralPractitioner {
    practitionerId
  }
`

export const REF_PRACTITIONER_ADMINISTRATION_DETAILS_FRAGMENT = gql`
  fragment RefPractitionerAdministrationDetails on ReferralPractitioner {
    notes
    disciplineId
    skillIds
    discipline {
      id
      description
    }
    skills {
      id
      description
      skillCode
    }
    active
  }
`

export const REF_PRACTITIONER_ADDRESS_DETAILS_FRAGMENT = gql`
  fragment RefPractitionerAddressDetails on ReferralPractitioner {
    label
    street
    street2
    street3
    city
    province
    postalCode
    country
  }
`

export const REF_PRACTITIONER_CONTACT_DETAILS_FRAGMENT = gql`
  fragment RefPractitionerContactDetails on ReferralPractitioner {
    email
    phones {
      id
      number
      extension
      type
      isPrimary
      notes
    }
    communicationMethod
  }
`


export const REF_PRACTITIONER_PERSONAL_DETAILS_FRAGMENT = gql`
  fragment RefPractitionerPersonalDetails on ReferralPractitioner {
    lastName
    firstName
  }
`


export const GET_REFERRAL_PRACTITIONER_PROFILE = gql`
    ${REF_PRACTITIONER_PERSONAL_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_ADDRESS_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_ADMINISTRATION_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
    query GetReferralPractitionerDetails($tenantId: ID!, $referralPracId: ID!) {
        tenant(id: $tenantId) {
            referralPractitioner {
                get(id: $referralPracId) {
                    id
                    version
                    ...RefPractitionerPersonalDetails
                    ...RefPractitionerContactDetails
                    ...RefPractitionerAddressDetails
                    ...RefPractitionerAdministrationDetails
                    ...RefPractitionerIdentifierDetails
                }
            }
        }
    }
`

export const UPDATE_REFERRAL_PRACTITIONER_PROFILE = gql`
    ${REF_PRACTITIONER_PERSONAL_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_ADDRESS_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_ADMINISTRATION_DETAILS_FRAGMENT}
    ${REF_PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
    mutation UpdateReferralPractitionerDetails(
        $tenantId: ID!
        $referralPracId: ID!
        $input: ReferralPractitionerInput!
    ) {
        tenant(id: $tenantId) {
            referralPractitioner {
                update(id: $referralPracId, input: $input) {
                    id
                    version
                    ...RefPractitionerPersonalDetails
                    ...RefPractitionerContactDetails
                    ...RefPractitionerAddressDetails
                    ...RefPractitionerAdministrationDetails
                    ...RefPractitionerIdentifierDetails
                }
            }
        }
    }
`


export const ADD_NEW_REFERRAL_PRACTITIONER = gql`
  mutation AddNewReferralPractitioner(
    $tenantId: ID!
    $input: ReferralPractitionerInput!
  ) {
    tenant(id: $tenantId) {
      referralPractitioner {
        create(input: $input)
      }
    }
  }
`
