import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LoadingOverlay } from "../components/LoadingScreen";
import {
  GET_USER_TENANT_SETTINGS,
  GET_TENANT_SETTINGS,
  GET_TENANT_IDENTIFIERS,
  LIST_PRACTITIONERS,
  LIST_TENANT_LETTER_TEMPLATES,
  UPDATE_TENANT_USER_SETTING
} from "../graphql-definitions";
import _get from "lodash/get";
import moment from "moment-timezone"
import { SettingInput, SettingsDictEntryType, SettingsType } from "../types/settings/Setting";
import { UPDATE_TENANT_SETTING } from "../graphql-definitions/tenant/SettingsQueries";
import { tenantUserSettings as userTenantSettingsKeys, TenantUserSettingDictionaryType } from "../utils/TenantUserSettings"
import { tenantSettings as tenantSettingsKeys, TenantSettingDictionaryType } from "../utils/TenantSettings"
import { PractitionerListType } from "../types/settings/Practitioner";
import formatStringByPattern from "format-string-by-pattern";
import { LetterTemplateType } from "../types/patients/Letters";
import { PatientIdentifier } from "../types/patients/Patient";

const ALL_USER_TENANT_SETTINGS = Object.values(userTenantSettingsKeys).map(value => value.name)
const ALL_TENANT_SETTINGS = Object.values(tenantSettingsKeys).map(value => value.name)

interface TenantContextInterface {
  getTenantSettings: (settings: SettingsDictEntryType[]) => {[key:string]: (string | boolean | number | null)}
  getUserTenantSettings: (settings: SettingsDictEntryType[]) => {[key:string]: (string | boolean | number | null)}
  updateTenantSetting: (
    settingType: SettingsDictEntryType,
    value: string | boolean | number | null,
    showSuccess?: boolean
  ) => Promise<void>
  updateUserTenantSetting: (
    setting: SettingsDictEntryType,
    value: string | boolean | number | null,
    showSuccess?: boolean
  ) => Promise<void>,
  tenantSettingsKeys: TenantSettingDictionaryType
  userTenantSettingsKeys: TenantUserSettingDictionaryType
  tenantIdentifiers: any[]
  formatIdentifier: (identifierValue: string, patientId: string) => string
  patientPrimaryIdentifier: (patient) => {label: string, formatted: string} | null
  orderIdentifiers: (identifiers, province) => PatientIdentifier[]
  practitioners: PractitionerListType[]
  letterTemplates: LetterTemplateType[]
}


const defaultTenantContext : TenantContextInterface = {
  getTenantSettings: (settings):{[key:string]: (string | boolean | number | null)} => { return {}},
  getUserTenantSettings: (settings):{[key:string]: (string | boolean | number | null)} => { return {}},
  updateTenantSetting: (setting, value, showSuccess) => Promise.resolve(),
  updateUserTenantSetting: (setting, value, showSuccess) => Promise.resolve(),
  tenantSettingsKeys: {} as TenantSettingDictionaryType,
  userTenantSettingsKeys: {} as TenantUserSettingDictionaryType,
  tenantIdentifiers: [] as any[],
  formatIdentifier: (identifierValue, patientId) => '',
  patientPrimaryIdentifier: (patient) => null,
  orderIdentifiers: (patient, province) => [],
  practitioners: [],
  letterTemplates: []
}

const TenantContext = React.createContext(defaultTenantContext)

export const TenantContextProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [ tenantSettings, setTenantSettings ] = useState<SettingsType>({})
  const [ userTenantSettings, setUserTenantSettings ] = useState<SettingsType>({})
  const [ tenantIdentifiers, setTenantIdentifiers] = useState<any[]>([])
  const [ practitioners, setPractitioners ] = useState<PractitionerListType[]>([])
  const [ letterTemplates, setLetterTemplates] = useState<LetterTemplateType[]>([]);

  const { loading: loadingTenantSettings, data: tenantData } = useQuery(
    GET_TENANT_SETTINGS, {
      variables: {
        tenantId: tenant_id,
        keys: ALL_TENANT_SETTINGS
      },
      onCompleted: data => {
        const tempSettings = _get(data, 'tenant.settings')
        const values = getSettingValues(tempSettings, ALL_TENANT_SETTINGS)
        if (values?.timezone != null) {
          moment.tz.setDefault(values.timezone)
        }
        setTenantSettings({
          ...tenantSettings,
          ...values
        })
      },
      onError: (error) => {
        console.error('Error retrieving setting: ' + JSON.stringify(error, null, 2))
      },
      fetchPolicy: 'cache-first'
  })

  const { loading: identifierLoading, error: identifierError, data: identifierData, } = useQuery(
    GET_TENANT_IDENTIFIERS, {
      variables: { tenantId: tenant_id },
      fetchPolicy: 'cache-first',
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      },
    })

  useEffect(() => {
    const tenantIdentifiers = _get(identifierData, 'tenant.identifier.list', null)
    if (tenantIdentifiers) {
      setTenantIdentifiers(tenantIdentifiers)
    }
  }, [identifierData])

  const { loading: loadingUserTenantSettings, data: userTenantData } = useQuery(
    GET_USER_TENANT_SETTINGS, {
      variables: {
        tenantId: tenant_id,
        keys: ALL_USER_TENANT_SETTINGS
      },
      onCompleted: data => {
        const tempSettings = _get(data, 'tenant.user.settings')
        setUserTenantSettings({
          ...getSettingValues(tempSettings, ALL_USER_TENANT_SETTINGS)
        })
      },
      onError: (error) => {
        console.error('Error retrieving setting: ' + JSON.stringify(error, null, 2))
      },
      fetchPolicy: 'cache-first'
    })

  const { loading: loadingPractitioners } = useQuery( LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setPractitioners(_get(data, 'tenant.practitioner.list', []))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Practitioner list couldn\'t be retrieved.')
    }
  })

  const { loading: loadingTemplates, error, data: templatesData } = useQuery(LIST_TENANT_LETTER_TEMPLATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2));
    }
  });

  useEffect(() => {
    setLetterTemplates(_get(templatesData, "tenant.letterTemplate.list", []))
  }, [templatesData])

  const [ runUpdateTenantSetting ] = useMutation(
    UPDATE_TENANT_SETTING, {
      onError: (error) => {
        console.error('Error updating setting: ' + JSON.stringify(error, null, 2))
      }
    }
  )

  const [ runUpdateUserTenantSetting ] = useMutation(
    UPDATE_TENANT_USER_SETTING, {
      onError: (error) => {
        console.error('Error updating setting: ' + JSON.stringify(error, null, 2))
      }
    }
  )


  const getSettingValues = (settings, names) => {
    let results : any = {}
    names.forEach((name) => {
      results[name] = null
      const namedSetting = settings.find((setting => setting.name === name))
      if (namedSetting != null){
        if(namedSetting.__typename === "StringSetting" && namedSetting.stringValue){
          results[name] = namedSetting.stringValue
        } else  if(namedSetting.__typename === "IntSetting" && namedSetting.intValue){
          results[name] = namedSetting.intValue
        } else  if(namedSetting.__typename === "BoolSetting" && namedSetting.boolValue !== undefined){
          results[name] = namedSetting.boolValue
        }  else  if(namedSetting.__typename === "IdSetting" && namedSetting.idValue){
          results[name] = namedSetting.idValue
        }
      }
    })
    return results
  }


  const getSettingsFromState = (settingNames, settingsState) => {
    const settingsList: {[key:string]: (string | boolean | number | null)} = {}
    settingNames.forEach(settingName => {
      if(settingsState[settingName] !== undefined){
        settingsList[settingName] = settingsState[settingName]
      }
    })
    return settingsList
  }

  const formatIdentifier = (identifierValue, identifierTypeId) => {
    const format = tenantIdentifiers.find((identifier) => identifier.id === identifierTypeId).formatMask
    return format === "*********" ? identifierValue : formatStringByPattern(format, identifierValue)
  }

  const patientPrimaryIdentifier = (patient) => {
    let sortededIdentifiers = orderIdentifiers(patient.identifiers, patient.province)
    if (sortededIdentifiers.length > 0) {
      return {
        label: sortededIdentifiers[0].label,
        formatted: formatIdentifier(sortededIdentifiers[0].value, sortededIdentifiers[0].typeId)
      }
    }
    return null
  }

  const orderIdentifiers = (identifiers, province) => {
    let sortedIdentifiers: PatientIdentifier[] = []

    let identifierLabel = "DND"
    const identifierDND = tenantIdentifiers.find((identifier) => identifier.label === identifierLabel)
    let patientIdentifier = identifierDND && identifiers.find((identifier) => identifier.typeId === identifierDND.id)
    if (patientIdentifier) {
      sortedIdentifiers.push({
        label: identifierLabel,
        value: patientIdentifier.value,
        typeId: patientIdentifier.typeId
      })
    }

    if (province) {
      const identifierProvince = tenantIdentifiers.find((identifier) => identifier.province === province)
      patientIdentifier = identifiers.find((identifier) => identifier.typeId === identifierProvince.id)
      if (patientIdentifier) {
        sortedIdentifiers.push({
          label: identifierProvince.label,
          value: patientIdentifier.value,
          typeId: patientIdentifier.typeId
        })
      }
    }

    identifiers.forEach((identifier) => {
      const existingIdentifier = sortedIdentifiers.find((sortedIdentifier) => sortedIdentifier.typeId === identifier.typeId)
      if (!existingIdentifier) {
        sortedIdentifiers.push({
          label: tenantIdentifiers.find((tenantIdentifier) => tenantIdentifier.id === identifier.typeId).label,
          value: identifier.value || 'None',
          typeId: identifier.typeId || ''
        })
      }
    })

    return sortedIdentifiers
  }

  const getUserTenantSettings = useCallback((settings) => {
    const tempSettings = {}
    settings.forEach(setting => { tempSettings[setting?.name] = setting?.defaultValue})
    const settingNames = settings.map(setting => setting?.name)
    const existingSettings = getSettingsFromState(settingNames, userTenantSettings)
    if(Object.keys(existingSettings).length !== settingNames.length && !loadingUserTenantSettings){
      let missing = settingNames.filter(x => !Object.keys(existingSettings).includes(x))
      console.error("User Tenant settings not loaded: " + missing)
    }
    return {...tempSettings, ...existingSettings}
  }, [userTenantSettings])


  const getTenantSettings = useCallback((settings) => {
    const tempSettings = {}
    settings.forEach(setting => { tempSettings[setting?.name] = setting?.defaultValue})
    const settingNames = settings.map(setting => setting.name)
    const existingTenantSettings = getSettingsFromState(settingNames, tenantSettings)
    if(Object.keys(existingTenantSettings).length !== settingNames.length && !loadingTenantSettings){
      let missing = settingNames.filter(x => !Object.keys(existingTenantSettings).includes(x))
      console.error("Tenant settings not loaded: " + missing)
    }
    return {...tempSettings, ...existingTenantSettings}
  }, [tenantSettings])


  const updateTenantSetting = async(setting: SettingsDictEntryType, value, showSuccess=true): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (value === undefined) {
        console.error("updateTenantSetting: Setting value is required for " + setting.name)
        return
      }
      const settingInput: SettingInput = {}
      const type: string = setting.type
      settingInput[type] = {
        id: setting.name,
        name: setting.name,
        value: value
      }
      runUpdateTenantSetting({
        variables: {
          tenantId: tenant_id,
          setting: settingInput
        },
        optimisticResponse: () => {
          const existingSettings = tenantSettings
          existingSettings[setting.name] = value
          setTenantSettings(existingSettings)
          if (setting.name === "timezone") {
            moment.tz.setDefault(value.toString())
          }
          return buildOptimisticResponse("tenant", setting, value)
        },
        onCompleted: (data) => {
          // Always inform on errors, but allow success to be hidden where appropriate.
          if (data.tenant.settings.updateTenantSetting.length > 0) {
            if (showSuccess) {
              showSuccessAlert('Settings have been saved.')
            }
          } else {
            showErrorAlert("Settings couldn't be saved.")
          }

          resolve()
        },
        onError: (error) => {
          reject(error)
        }
      })
    })
  }

  const updateUserTenantSetting = async (setting: SettingsDictEntryType, value, showSuccess=true): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (value === undefined) {
        console.error("updateUserTenantSetting: Setting value is required for " + setting.name)
        return
      }
      const settingInput: SettingInput = {}
      const type: string = setting.type
      settingInput[type] = {
        id: setting.name,
        name: setting.name,
        value: value
      }
      runUpdateUserTenantSetting({
        variables: {
          tenantId: tenant_id,
          setting: settingInput
        },
        optimisticResponse: () => {
          const existingSettings = userTenantSettings
          existingSettings[setting.name] = value
          setUserTenantSettings(existingSettings)
          return buildOptimisticResponse("userTenant", setting, value)
        },
        onCompleted: (data) => {
          // Always inform on errors, but allow success to be hidden where appropriate.
          if (data.tenant.user.updateTenantUserSetting.length > 0) {
            if (showSuccess) {
              showSuccessAlert('Settings have been saved.')
            }
          } else {
            showErrorAlert("Settings couldn't be saved.")
          }
          resolve()
        },
        onError: (error) => {
          reject(error)
        }
      })
    })
  }


  const buildOptimisticResponse = (scope: "tenant" | "userTenant", setting: SettingsDictEntryType, value) => {
    let settingData = {}

    if (setting.type === "stringSetting"){
      settingData = {
        id: setting.name,
        name: setting.name,
        stringValue: value,
        __typename: "StringSetting"
      }
    }
    else if (setting.type === "intSetting") {
      settingData = {
        id: setting.name,
        name: setting.name,
        intValue: value,
        __typename: "IntSetting"
      }
    }
    else if (setting.type === "boolSetting") {
      settingData = {
        id: setting.name,
        name: setting.name,
        boolValue: value,
        __typename: "BoolSetting"
      }
    }
    else if (setting.type === "idSetting") {
      settingData = {
        id: setting.name,
        name: setting.name,
        idValue: value,
        __typename: "IdSetting"
      }
    }

    if (scope === "tenant") {
      return {
        tenant: {
          settings: {
            updateTenantSetting: [
              settingData
            ]
          }
        }
      }
    } else {
      return {
        tenant:{
          user: {
            updateTenantUserSetting: [
              settingData
            ]
          }
        }
      }
    }
  }

  let providerValues = {
    getTenantSettings,
    getUserTenantSettings,
    updateTenantSetting,
    updateUserTenantSetting,
    tenantSettingsKeys,
    userTenantSettingsKeys,
    tenantIdentifiers,
    formatIdentifier,
    patientPrimaryIdentifier,
    orderIdentifiers,
    practitioners,
    letterTemplates
  }

  const loading = identifierLoading || loadingTenantSettings || loadingUserTenantSettings

  if(loading){
    return <LoadingOverlay
      loading={loading}
      message={'Loading...'}
    />
  }

  if(tenantData && userTenantData && identifierData) {
    return (
      <TenantContext.Provider value={providerValues}>
        { children }
      </TenantContext.Provider>
    )
  }

  return <></>
}

export const useTenantContext = () => {
  return React.useContext(TenantContext)
}
