import React from 'react'
import { TextField, Section, Switch, Typography } from "saga-library/src";
import { FormFragment } from "../../../../../components/PermissionForm";

export type AdministrationDetailsType = {
  name?: string
}


const defaults = {
  name: '',
  active: true,
}

const Form = ({ profileIndex, requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>Administration</Section.Header>
        <TextField
          label={'Billing profile name '}
          name={`billingProfileInputs.${profileIndex}.name`}
          dataTestId={"administrationDetails-billingProfileName"}
        />
        <Switch
          name={`billingProfileInputs.${profileIndex}.active`}
          label={'Active'}
          dataTestId={"administrationDetails-billingProfileActive"}
        />
        <Typography variant={'body2'} dataTestId={"administrationDetails-inactiveProfile"}>
          Inactive billing profiles won't appear when searching for billing profiles.
        </Typography>
      </Section.FormSection>
    </FormFragment>
  )
}

export const AdministrationDetails = {
  Form: Form,
  defaults: defaults,
}
