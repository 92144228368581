import React from "react";
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from "@apollo/client";
import {
  CREATE_CONDITION_AND_PROCEDURE, DELETE_CONDITION_AND_PROCEDURE,
  GET_PATIENT_CONDITION_AND_PROCEDURES, UPDATE_CONDITION_AND_PROCEDURE
} from "../../../graphql-definitions/tenant/patient/ConditionAndProceduresQueries";
import { ConditionAndProcedureType } from '../../../types/ConditionAndProcedure'
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import {
  ConditionAndProcedureSortPredicate
} from '../components/conditionAndProcedures/components/ConditionAndProceduresUtil'

const getConditionAndProcedureQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.conditionAndProcedure.list', []) as Array<ConditionAndProcedureType>]
}

const parseConditionAndProcedureQueryResults = (data) => {
  return getConditionAndProcedureQueryResults(data).sort(ConditionAndProcedureSortPredicate)
}

interface ConditionAndProcedureContextInterface {
  conditionAndProcedureQueryRef: QueryReference | null
  createConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete?: () => void) => Promise<void>
  updateConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete: () => void) => Promise<void>
  deleteConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete: () => void) => Promise<void>
  getConditionAndProcedureQueryResults: (any) => ConditionAndProcedureType[]
  parseConditionAndProcedureQueryResults: (any) => ConditionAndProcedureType[]
}

const defaultConditionAndProcedureContext: ConditionAndProcedureContextInterface = {
  conditionAndProcedureQueryRef: null,
  createConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete?: () => void) => Promise.resolve(),
  updateConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete: () => void) => Promise.resolve(),
  deleteConditionAndProcedure: (conditionAndProcedure: ConditionAndProcedureType, onComplete: () => void) => Promise.resolve(),
  getConditionAndProcedureQueryResults: getConditionAndProcedureQueryResults,
  parseConditionAndProcedureQueryResults: parseConditionAndProcedureQueryResults
}


const ConditionAndProcedureContext = React.createContext(defaultConditionAndProcedureContext)

export const ConditionAndProcedureProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [conditionAndProcedureQueryRef] = useBackgroundQuery(GET_PATIENT_CONDITION_AND_PROCEDURES, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const [createConditionAndProcedureGQL] = useMutation(CREATE_CONDITION_AND_PROCEDURE)
  const [updateConditionAndProcedureGQL] = useMutation(UPDATE_CONDITION_AND_PROCEDURE)
  const [deleteConditionAndProcedureGQL] = useMutation(DELETE_CONDITION_AND_PROCEDURE)

  const createConditionAndProcedure = async (conditionAndProcedure, onComplete) => {
    await createConditionAndProcedureGQL({
      variables: {
        input: {
          ...conditionAndProcedure,
          patientId: patient_id,
          version: "0",
        },
        tenantId: tenant_id,
      },
      update: (cache, data) => {
        const newCondition = _get(data, 'data.tenant.patient.conditionAndProcedure.create')
        cache.updateQuery({
          query: GET_PATIENT_CONDITION_AND_PROCEDURES,
          variables: { tenantId: tenant_id, patientId: patient_id }
        }, (data) => {
          return {
            tenant: {
              patient: {
                conditionAndProcedure: {
                  list: [...data.tenant.patient.conditionAndProcedure.list, newCondition]
                }
              }
            }
          }
        })
      },
      onCompleted: (data) => {
        if (onComplete) {
          onComplete()
        }
        showSuccessAlert('Condition or procedure has been added.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Condition or procedure couldn\'t be created.')
      }
    })
  }

  const updateConditionAndProcedure = async (conditionAndProcedure, onComplete) => {
    const conditionAndProcedureId = conditionAndProcedure.id
    delete conditionAndProcedure.id
    await updateConditionAndProcedureGQL({
      variables: {
        tenantId: tenant_id,
        id: conditionAndProcedureId,
        input: {
          ...conditionAndProcedure,
          patientId: patient_id,
        },
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Changes have been saved.`)
      },
      onError:(error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Changes couldn\'t be saved.')
      }
    })
  }

  const deleteConditionAndProcedure = async(conditionAndProcedure, onComplete) => {
    await deleteConditionAndProcedureGQL({
      variables: {
        tenantId: tenant_id,
        conditionAndProcedureId: conditionAndProcedure.id,
        version: conditionAndProcedure.version
      },
      update: (cache, data) => {
        const normalizedId = cache.identify({ id: conditionAndProcedure.id, __typename: 'ConditionAndProcedure' })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onCompleted: (data) => {
        onComplete()
        showSuccessAlert(`Condition or procedure has been deleted.`)
      },
      onError:(error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Condition or procedure couldn\'t be deleted.')
      }
    })
  }

  const providerValues = {
    conditionAndProcedureQueryRef,
    createConditionAndProcedure,
    updateConditionAndProcedure,
    deleteConditionAndProcedure,
    getConditionAndProcedureQueryResults,
    parseConditionAndProcedureQueryResults
  }

  return (
    <ConditionAndProcedureContext.Provider value={providerValues}>
      { children }
    </ConditionAndProcedureContext.Provider>
  )
}

export const useConditionAndProcedureContext = () => {
  return React.useContext(ConditionAndProcedureContext)
}