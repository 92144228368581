import React from 'react'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import { SxProps, useTheme, Theme } from "@mui/material";

interface AppBarMenuProps {
  open: boolean
  anchorEl?: HTMLAnchorElement | null
  children?: React.ReactNode
  handleClose?: () => void
}

export const AppBarMenu = ({
  anchorEl,
  handleClose,
  open,
  children,
}: AppBarMenuProps) => {
  return (
    <Menu
      data-testid={"appBarMenu"}
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={handleClose}
      sx={{
        textAlign:"center",
        fontWeight: 700
      }}
      MenuListProps={{
        sx: {
          p: 1
        }
      }}
    >
      {children}
    </Menu>
  )
}

export const MenuHeader = ({ children }) => {
  const theme = useTheme()
  return (
    <Box margin={theme.spacing(1, 2, 3,2)}>
      {children}
    </Box>
  )
}

export const MenuSection = ({sx= {}, children }) => {
  return (
    <Box margin={2} sx={{...sx}}>
      {children}
    </Box>
  )
}

export const MenuFooter = ({ children }) => {
  const theme = useTheme()
  return (
    <Box margin={theme.spacing(2, 2, 0, 2)}>
      {children}
    </Box>
  )
}

type MenuRowProps = {
  sx?: SxProps<Theme>,
  children: React.ReactNode
}
export const MenuRow = ({ sx = {}, children }: MenuRowProps) => {
  return <Box p={0.5} sx={{ ...sx }}>{children}</Box>
}

export const MainAppBarMenuRow = ({sx = {}, children}: MenuRowProps) => {
  return <MenuRow sx={{mb: 1, ...sx}} children={children}/>
}

export const PrivacyAndTerms = ({ children }) => {
  return (
    <Box
      sx={ theme => ({
        padding: theme.spacing(0.5, 0.5, 0),
        color: theme.palette.greys.medium,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
      })}
    >
      {children}
    </Box>
  )
}

export default AppBarMenu
