import React from "react";
import { Box, styled } from "@mui/material";
import { Typography } from "saga-library/src";
import TextSeparator from "./TextSeparator";

const DateFromNow = styled(({ ...props }: { children: React.ReactNode }) => (
  <Box component={"span"} display={"inline-block"} {...props} />
))({
  "&::first-letter": {
    textTransform: "capitalize"
  }
})

const TimelineEventDate = ({ date, dateFromNow }: { date: string, dateFromNow: string }) => {
  const dateDisplay = () => {
    if (dateFromNow === "today") {
      return <DateFromNow>{dateFromNow}</DateFromNow>
    }
    return (
      <>
        <Box component={"span"}>{date}</Box>
        <TextSeparator />
        <DateFromNow>{dateFromNow}</DateFromNow>
      </>
    )
  }

  return (
    <Box display={"flex"} alignItems={"flex-end"} sx={{ height: "29px", pl: "52px" }}>
      <Typography variant={"body1"} color={(theme) => theme.palette.primary.light}>
        {dateDisplay()}
      </Typography>
    </Box>
  )
}

export default TimelineEventDate