import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReferralPractitionerDetails, ReferralPractitionerDetailsType } from "./components/ReferralPractitionerDetails";
import { useQuery, useMutation } from '@apollo/client'
import { schema } from './util/referralPractitionerValidation'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { referralPractitionerDisplayName } from 'saga-library/src/util/formatting'
import omitDeep from 'omit-deep-lodash'
import { omit } from 'lodash'
import ReferralPractitionerForm from './components/ReferralPractitionerForm'
import ReferralPractitionerHeader from "./components/ReferralPractitionerHeader";
import _get from 'lodash/get'
import { LoadingSpinner } from "components/LoadingScreen";
import { StaticPage } from 'components/Layouts'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useAccountContext } from "../../../../providers/AccountContext";
import { GET_REFERRAL_PRACTITIONER_PROFILE, UPDATE_REFERRAL_PRACTITIONER_PROFILE } from "../../../../graphql-definitions";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";
import { flushSync } from "react-dom";

const FORM_NAME = 'referral_practitioner_form'

export const ReferralPractitionerProfile = () => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id, referral_practitioner_id } = useParams()
  const [title, setTitle] = useState('')
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [prevProvince, setPrevProvince] = useState('')
  const formMethods = useForm<ReferralPractitionerDetailsType>({
    defaultValues: ReferralPractitionerDetails.defaults,
    schema: schema,
  })

  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitSuccessful },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  const {
    loading: initialLoading,
    error: initialError,
    data: initialData,
  } = useQuery(GET_REFERRAL_PRACTITIONER_PROFILE, {
    variables: {
      referralPracId: referral_practitioner_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      const referralPractitioner = _get(
        data,
        'tenant.referralPractitioner.get',
        null
      )
      if (data) {
        setPrevProvince(referralPractitioner['province'])
        setValues(referralPractitioner)
      }
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Referral practitioner couldn\'t be retrieved.')
    },
    fetchPolicy: 'cache-and-network',
  })

  const [updateReferralPractitioner, { error: updateError, data: updateData }] =
    useMutation(UPDATE_REFERRAL_PRACTITIONER_PROFILE, {
      onCompleted: (updateData) => {
        const data = _get(
          updateData,
          'tenant.referralPractitioner.update',
          null
        )
        if (updateError) {
          console.error(JSON.stringify(updateError, null, 2))
          showErrorAlert('Failed to save changes.')
        } else if (data) {
          flushSync(() => {
            clearNavigationPrompt(FORM_NAME)
          })
          showSuccessAlert(`Changes have been saved.`)
          if(data.id === referral_practitioner_id) {
            setValues(data)
          } else {
            navigate(buildTenantRoute(`settings/practitioners/referral/r/${data.id}`, tenant_id))
          }
        }
      },
    })

  const setValues = (data) => {
    setTitle(referralPractitionerDisplayName(data.firstName, data.lastName))
    const cleanedData = omitDeep(omit(data, 'id'), '__typename')
    reset(cleanedData)
  }

  const updateReferralPractitionerProfile = handleSubmit(async (data) => {
    await updateReferralPractitioner({
      variables: {
        tenantId: tenant_id,
        referralPracId: referral_practitioner_id,
        input: data,
      },
    })
  })

  if(initialLoading) {
    return <LoadingSpinner />
  }

  if(initialError) {
    return (
      <StaticPage>
        Sorry, we could not find the requested referral practitioner.
      </StaticPage>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn header={<ReferralPractitionerHeader title={title} />}>
        <ReferralPractitionerForm
          onSubmit={updateReferralPractitionerProfile}
          prevProvince={prevProvince}
        />
      </SettingsSectionColumn>
    </FormProvider>
  )
}

export default ReferralPractitionerProfile
