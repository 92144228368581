import React from "react";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { PatientLabel } from "saga-client/src/components/SearchControls/Patient/PatientLabel";
import { PatientSearchResult } from "saga-client/src/types/patients";

export interface PatientRefreshableProps {
  name: string
  patient: PatientSearchResult
  onRefreshDisplaySnackbar: (v) => void
}

export const PatientRefreshable = ({
  name,
  patient,
  onRefreshDisplaySnackbar,
} : PatientRefreshableProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value} }) => (
        <PatientLabel
          patient={patient}
          name={name}
          disabled={true}
          variant={'sm'}
          refresh={() => {
            onRefreshDisplaySnackbar(value)
            value = !value
            onChange()
          }}
        />
      )}
    />
  )
}

export default PatientRefreshable