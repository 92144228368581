import { TableListRowConfig, Typography } from "saga-library/src";
import React from "react";
import SettingsRow from "../../components/SettingsRow";

export const AppointmentRoomRow = (appointmentRoom, setEditAppointmentRoom, hasReadWrite): TableListRowConfig => {

  const practitionerName = appointmentRoom.practitioner != null ?
    `${appointmentRoom.practitioner.lastName}, ${appointmentRoom.practitioner.firstName}` : null

  return ({
    onClick: hasReadWrite ? () => setEditAppointmentRoom(appointmentRoom) : null,
    key: appointmentRoom.id,
    rowData: [
      {
        children: [
          <SettingsRow sx={{ p: '8px 0px' }} key={'name'+appointmentRoom.id}>
            <Typography variant={'body1'}>{appointmentRoom.name}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow sx={{ p: '8px 0px' }} key={'name'+appointmentRoom.id}>
            <Typography variant={'body1'}>{appointmentRoom.location.name}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow sx={{ p: '8px 0px' }} key={'name'+appointmentRoom.id}>
            <Typography variant={'body1'}>{ practitionerName }</Typography>
          </SettingsRow>
        ]
      }
    ]
  })
}
