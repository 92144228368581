import React, { useCallback, useEffect, useState } from 'react'
import { AddButton, RemoveButton, Select, TextField, Typography } from 'saga-library/src'
import { Box, ListSubheader, MenuItem, MenuItemProps } from '@mui/material'
import FormattedDatePicker from '../FormattedDatePicker'
import { LinkSelect } from '../LinkSelect'
import {
  CombinatorSelectorProps,
  FieldSelectorProps,
  OperatorSelectorProps,
  QueryBuilder,
  RuleGroupType,
  RuleType,
  transformQuery,
  ValueEditorProps
} from 'react-querybuilder'
import './ReportQueryBuilder.css'
import {
  combinators,
  defaultReportGroupQueryInput,
  defaultReportQueryInput,
  fields,
  getOperators,
  operators
} from './ReportQueryBuilderFields'
import {
  REPORT_COLUMN_GROUPS,
  operatorIsBetween,
  operatorIsNullable
} from '../../apps/reports/components/ReportUtil'
import {
  REPORT_COLUMNS,
  ReportColumnType,
  ReportCombinatorType,
  ReportOperatorType,
  ReportQueryInput
} from '../../types/Report'
import { useFormContext } from 'saga-library/src/components/Form'
import { useParams } from 'react-router-dom'

const MULTIVALUE_SEPARATOR = ","

const CombinatorSelector = (props: CombinatorSelectorProps) => {
  const name = props.level === 0 ? "combinator" : `${props.context.name}.${formatPath([...props.path.slice(0, -1)])}.combinator`
  return (
    <LinkSelect
      name={name}
      defaultValue={"AND"}
      options={props.options as { value: string, label: string }[]}
      onChange={(e) => {
        props.handleOnChange(e.target.value)
        props.context.onChange()
      }}
      disabled={props.disabled}
      dataTestId={`${props.context.name}-${formatPath(props.path, "-")}-${props.testID}`}
    />
  )
}

const FieldGroupHeader = ({ label }) => (
  <ListSubheader
    component={({ children }) => (
      <Typography
        variant={"h5"}
        sx={{
          p: 1,
          pb: "4px",
          "&:first-of-type": { pt: 0 }
        }}
      >
        {children}
      </Typography>
    )}
    inset={true}
  >
    {label}
  </ListSubheader>
)

const FieldMenuItem = ({ column, dataTestId, ...props }: MenuItemProps & { column: ReportColumnType, dataTestId?: string }) => (
  <MenuItem {...props} data-testid={`${dataTestId}-${column}`}>
    {REPORT_COLUMNS[column]?.label}
  </MenuItem>
)

const FieldSelector = (props: FieldSelectorProps) => {
  const namePrefix = `${props.context.name}.${formatPath(props.path)}`
  const dataTestId = `${props.context.name}-${formatPath(props.path, "-")}-${props.testID}`
  return (
    <Select
      name={`${namePrefix}.column`}
      label={"Column"}
      renderValue={(value) => {
        if (!value) {
          return <>No selection</>
        }
        return <>{REPORT_COLUMNS[value]?.selectedLabel || REPORT_COLUMNS[value]?.label}</>
      }}
      onChange={(column) => {
        props.handleOnChange(column)
        props.context.onChange()
      }}
      disabled={props.disabled}
      dataTestId={dataTestId}
    >
      <MenuItem value={""} sx={{ display: "none" }}></MenuItem>
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[0].label} />
      {REPORT_COLUMN_GROUPS[0].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[1].label} />
      {REPORT_COLUMN_GROUPS[1].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[2].label} />
      {REPORT_COLUMN_GROUPS[2].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[3].label} />
      {REPORT_COLUMN_GROUPS[3].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[4].label} />
      {REPORT_COLUMN_GROUPS[4].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
      <FieldGroupHeader label={REPORT_COLUMN_GROUPS[5].label} />
      {REPORT_COLUMN_GROUPS[5].columns.map(column => (
        <FieldMenuItem key={column} value={column} column={column} dataTestId={dataTestId} />
      ))}
    </Select>
  )
}

const OperatorSelector = (props: OperatorSelectorProps) => {
  return (
    <Select
      name={`${props.context.name}.${formatPath(props.path)}.operator`}
      label={"Operator"}
      onChange={(operator) => {
        props.handleOnChange(operator)
        props.context.onChange()
      }}
      disabled={props.disabled}
      dataTestId={`${props.context.name}-${formatPath(props.path, "-")}-${props.testID}`}
    >
      {props.options.map(option => (
        <MenuItem key={option.name} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

const ValueEditor = (props: ValueEditorProps) => {
  const disabled = operatorIsNullable(props.operator)
  const Component = props.fieldData["Component"] as (props) => JSX.Element
  const onChange = (value) => {
    props.handleOnChange(value)
    props.context.onChange()
  }
  const defaultProps = {
    name: `${props.context.name}.${formatPath(props.path)}.value1`,
    label: "Value",
    onChange: onChange,
    disabled: props.disabled || disabled,
    dataTestId: `${props.context.name}-${formatPath(props.path, "-")}-${props.testID}`
  }

  if (Component) {
    return <Component {...defaultProps} />
  }

  if (props.fieldData.inputType === "date") {
    if (operatorIsBetween(props.operator)) {
      const currentValueArray = props.value?.split(MULTIVALUE_SEPARATOR)
      const valueArray = [currentValueArray?.[0] || "", currentValueArray?.[1] || ""]
      return (
        <Box display={"inline-flex"} gap={2} sx={{ width: "100%" }}>
          <FormattedDatePicker {...defaultProps} onChange={(date) => onChange([date.format(), valueArray[1]].join(MULTIVALUE_SEPARATOR))} sx={{ minWidth: "130px" }} />
          <FormattedDatePicker {...defaultProps} name={`${props.context.name}.${formatPath(props.path)}.value2`} onChange={(date) => onChange([valueArray[0], date.format()].join(MULTIVALUE_SEPARATOR))} sx={{ minWidth: "130px" }} />
        </Box>
      )
    }
    return <FormattedDatePicker {...defaultProps} onChange={(date) => onChange(date.format())} sx={{ width: "100%" }} />
  }

  if (props.fieldData.inputType === "number") {
    const numberProps: any = {
      ...defaultProps,
      type: "number",
      InputProps: { inputProps: { min: 0 } },
      fullWidth: true
    }
    if (operatorIsBetween(props.operator)) {
      const currentValueArray = props.value?.split(MULTIVALUE_SEPARATOR)
      const valueArray = [currentValueArray?.[0] || "", currentValueArray?.[1] || ""]
      return (
        <Box display={"inline-flex"} gap={2} sx={{ width: "100%" }}>
          <TextField {...numberProps} onChange={(e) => onChange([e.target.value, valueArray[1]].join(MULTIVALUE_SEPARATOR))} />
          <TextField {...numberProps} name={`${props.context.name}.${formatPath(props.path)}.value2`} onChange={(e) => onChange([valueArray[0], e.target.value].join(MULTIVALUE_SEPARATOR))} />
        </Box>
      )
    }
    return <TextField {...numberProps} onChange={(e) => onChange(e.target.value)} />
  }

  return (
    <TextField {...defaultProps} onChange={(e) => onChange(e.target.value)} fullWidth={true} />
  )
}

export const ReportQueryBuilder = ({ name, disabled }: { name: string, disabled?: boolean }) => {
  const { category_type, report_id } = useParams()
  const { getValues, setValue, resetField } = useFormContext()
  const [categoryType, setCategoryType] = useState<string | undefined>(category_type)

  const [query, setQuery] = useState(getQuery(getValues(name), getValues('combinator')))
  const [isInitialSetup, setIsInitialSetup] = useState<boolean>(true)

  const onChange = () => {
    setIsInitialSetup(false)
  }

  const onQueryChange = useCallback((changedQuery) => {
    const transformedQuery = transformQuery(changedQuery, {
      omitPath: true,
      ruleProcessor: (r) => {
        return ({
          ...r,
          value: getRuleValue(r)
        })
      }
    })
    const updatedRules = removeGroupsWithNoRules(transformedQuery.rules)
    setQuery({ ...transformedQuery, rules: updatedRules })
  }, [])

  const setQueries = useCallback((queries) => {
    setValue(name, queries, { shouldDirty: !isInitialSetup })
  }, [isInitialSetup])

  useEffect(() => {
    setQueries(convertRulesToQueries(query.rules))
  }, [query.rules])

  useEffect(() => {
    if (!report_id && categoryType !== category_type) {
      const updatedQuery = getQuery([defaultReportQueryInput])
      setQuery(updatedQuery)
      setIsInitialSetup(true)
      resetField(name, { defaultValue: convertRulesToQueries(updatedQuery.rules) })
      setCategoryType(category_type)
    } else if (!!report_id) {
      setCategoryType(undefined)
    }
  }, [category_type, report_id])

  const AddGroupButton = useCallback((props) => (
    <AddButton
      label={"Add group"}
      disabled={disabled}
      onClick={(e) => {
        props.handleOnClick(e)
        onChange()
      }}
      dataTestId={"group"}
    />
  ), [disabled])

  const AddRuleButton = useCallback((props) => (
    <AddButton
      label={"Add criterion"}
      disabled={disabled}
      onClick={(e) => {
        props.handleOnClick(e)
        onChange()
      }}
      dataTestId={"criterion"}
    />
  ), [disabled])

  const RemoveRuleButton = useCallback((props) => (
    <RemoveButton
      onClick={(e) => {
        props.handleOnClick(e)
        onChange()
      }}
      sx={{ mt: 1 }}
      disabled={disabled}
      dataTestId={"rule"}
    />
  ), [disabled])

  const onAddRule = useCallback((rule) => {
    return { ...rule, field: "" }
  }, [])

  const onAddGroup = useCallback((ruleGroup) => {
    return { ...ruleGroup, rules: [ { field: "", operator: ReportOperatorType.EQUAL, value: "" }] }
  }, [])

  return (
    <QueryBuilder
      context={{ name, onChange }}
      fields={fields}
      operators={operators}
      combinators={combinators}
      query={query}
      onQueryChange={onQueryChange}
      disabled={disabled}
      onAddRule={onAddRule}
      onAddGroup={onAddGroup}
      getOperators={getOperators}
      showCombinatorsBetweenRules={true}
      controlElements={{
        addGroupAction: AddGroupButton,
        addRuleAction: AddRuleButton,
        combinatorSelector: CombinatorSelector,
        fieldSelector: FieldSelector,
        operatorSelector: OperatorSelector,
        removeGroupAction: () => null,
        removeRuleAction: RemoveRuleButton,
        valueEditor: ValueEditor
      }}
      accessibleDescriptionGenerator={() => ""}
    />
  )
}

const getQuery = (criteria: ReportQueryInput[] = [], combinator: ReportCombinatorType = ReportCombinatorType.AND) => {
  const rules = convertQueriesToRules(criteria, combinator)
  return ({
    combinator: combinator,
    rules: rules.length <= 1 ? rules : cleanRules(rules)
  })
}

const convertQueryToRule = (query: ReportQueryInput, combinator: ReportCombinatorType): RuleType | RuleGroupType => {
  if (query.operator === ReportOperatorType.GROUP) {
    return { id: query.id, combinator: combinator, rules: [] } as RuleGroupType
  }
  return {
    id: query.id,
    field: query.column,
    operator: query.operator,
    value: !operatorIsBetween(query.operator!) ? query.value1 : query.value1 + MULTIVALUE_SEPARATOR + query.value2
  } as RuleType
}

const convertQueriesToRules = (queries: ReportQueryInput[] = [], combinator: ReportCombinatorType = ReportCombinatorType.AND) => {
  let updatedRules: (RuleType | RuleGroupType)[] = []
  queries.forEach(query => {
    if (query.operator !== ReportOperatorType.GROUP) {
      updatedRules.push(convertQueryToRule(query, combinator))
    } else {
      updatedRules.push({
        id: query.id,
        combinator: combinator,
        rules: convertQueriesToRules(query.rules, combinator)
      })
    }
  })
  return updatedRules
}

const convertRuleToQuery = (rule) => {
  if (!!rule.rules && rule.rules.length > 0) {
    return {
      id: rule.id,
      column: null,
      operator: ReportOperatorType.GROUP,
      rules: rule.rules.map((r) => convertRuleToQuery(r))
    }
  }
  const values = parseBetweenValues(rule)
  return {
    id: rule.id,
    column: rule.field,
    operator: rule.operator,
    value1: values ? values[0] : rule.value,
    value2: values ? values[1] : ""
  }
}

const convertRulesToQueries = (rules: (RuleType | RuleGroupType)[]) => {
  return rules.map((rule) => convertRuleToQuery(rule))
}

const parseBetweenValues = (rule): string[] | null => {
  if (operatorIsBetween(rule.operator) && !!rule.value && rule.value.includes(MULTIVALUE_SEPARATOR)) {
    return rule.value.split(MULTIVALUE_SEPARATOR)
  }
  return null
}

const addRuleByPath = (rules: ReportQueryInput[] = [], path: number[], isGroup?: boolean): ReportQueryInput[] => {
  if (path.length === 0) {
    if (!isGroup) {
      return [...rules, defaultReportQueryInput]
    }
    return [...rules, defaultReportGroupQueryInput]
  }

  const currentPathIndex = path[0]
  return [
    ...rules.slice(0, currentPathIndex),
    { ...rules[currentPathIndex], rules: addRuleByPath(rules[currentPathIndex].rules, [...path].slice(1), isGroup) },
    ...rules.slice(currentPathIndex + 1)
  ]
}

const getRuleValue = (rule: RuleType): RuleType | null => {
  if (operatorIsNullable(rule.operator)) {
    return null
  }
  return rule.value
}

const cleanRules = (rules: any[] = []) => {
  return rules
    .map(rule => {
      if (rule.hasOwnProperty('rules')) {
        return ({
          ...rule,
          rules: cleanRules(rule.rules)
        })
      }
      return rule
    })
    .filter(rule => !!rule.field || (!!rule.rules && (rule.rules || []).length > 0))
}

const removeGroupsWithNoRules = (rules) => {
  return rules
    .map(rule => {
      if (rule.hasOwnProperty('rules')) {
        return ({
          ...rule,
          rules: removeGroupsWithNoRules(rule.rules)
        })
      }
      return rule
    })
    .filter(rule => !rule.hasOwnProperty('rules') || rule['rules'].length > 0)
}

const removeRuleByPath = (rules: ReportQueryInput[] = [], path: number[]): ReportQueryInput[] => {
  if (path.length === 0) {
    return rules
  }

  const currentPathIndex = path[0]

  if (path.length === 1) {
    let updatedRules = [...rules]
    updatedRules.splice(currentPathIndex, 1)
    return updatedRules
  }

  return [
    ...rules.slice(0, currentPathIndex),
    { ...rules[currentPathIndex], rules: removeRuleByPath(rules[currentPathIndex].rules, [...path.slice(1)]) },
    ...rules.slice(currentPathIndex + 1)
  ]
}

const removeFormGroupsWithNoRules = (rules: ReportQueryInput[] = []): ReportQueryInput[] => {
  return rules
    .filter(rule => rule.operator !== ReportOperatorType.GROUP || (!!rule.rules && rule.rules.length > 0))
    .map(rule => {
      if (rule.operator === ReportOperatorType.GROUP) {
        return ({
          ...rule,
          rules: removeFormGroupsWithNoRules(rule.rules)
        })
      }
      return rule
    })
}

const formatPath = (path: number[], separator: string = ".") => {
  let pathString: string = path[0].toString()
  path.slice(1).forEach(p => {
    pathString += `${separator}rules${separator}${p}`
  })
  return pathString
}