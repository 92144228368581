import React from 'react'
import * as yup from 'yup'
import _get from 'lodash/get'

export const rules = {
  question: { max: 100 },
  passcode: { max: 64 },
  email: { max: 320 },
}

const maxLengthMessage = (value) => `Maximum ${value} characters`

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    question: yup
      .string()
      .max(rules.question.max, maxLengthMessage(rules.question.max))
      .required('Required'),
    passcode: yup
      .string()
      .max(rules.passcode.max, maxLengthMessage(rules.passcode.max))
      .required('Required'),
    confirmAnswer: yup
      .string()
      .nullable()
      .oneOf([yup.ref('passcode'), null], "Answers don't match.")
      .required('Required'),
    emailAddresses: yup
      .array()
      .of(
        yup
          .string()
          .max(rules.email.max, maxLengthMessage(rules.email.max))
          .email('Invalid email')
      )
      .min(1, 'At least one recipient is required'),
    roleIds: yup
      .array()
      .of(yup.string().required())
      .min(1, 'At least one role is required'),
  })
  .required()
