import React, { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { LoadingButton, Section, TextField, Typography, LinkButtonHref, PasswordField, Form } from 'saga-library/src'
import { Logo } from '../../../components/Logo'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from "yup"
import { useSagaAuthentication } from '../../../providers/Auth'
import { useNavigate } from 'react-router-dom'

const CAPS_LOCK_MESSAGE = 'Caps Lock is on.'

function SignInForm() {
  const [capsLockOn, setCapsLockOn] = useState<boolean>(false)
  const { performSignIn, friendlyErrorMessage, friendlyErrorDetails, clearErrors } = useSagaAuthentication()
  const navigate = useNavigate()

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  }).required();

  const formMethods = useForm<FormValues>({
    schema: schema
  })
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods

  const onSubmit = (data) => {
    return performSignIn(data)
  }

  const checkCapsLock = (event) => {
    setCapsLockOn(event.getModifierState && event.getModifierState('CapsLock'))
  }

  return (
    <FormProvider {...formMethods}>
      <Box marginTop={10} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Form id={'signin-form'} onSubmit={handleSubmit(onSubmit)} onKeyUp={checkCapsLock}>
          <Section.Column>
            <Box display={'flex'} flexDirection={'column'} marginTop={2} justifyContent={'center'} alignItems={'center'}>
              <Logo sx={{ width: 380, height: 70 }} />
              <Typography variant='h1' sx={{ pb:1, pt:1 }} >
                  Sign in
              </Typography>
              <TextField
                dataTestId={'signin-email'}
                label={'Email address'}
                sx={{ width: 280 }}
                name={'email'}
              />
              <PasswordField
                dataTestId={'signin-password'}
                label={'Password'}
                sx={{ width: 280 }}
                autoComplete={'password'}
                name={'password'}
              />
              <Collapse data-testid={'caps-lock-message'} in={capsLockOn}>
                {CAPS_LOCK_MESSAGE}
              </Collapse>
              <Collapse data-testid={'friendly-error-message'} in={!!friendlyErrorMessage} sx={{ color: "#C41923" }}>
                {friendlyErrorMessage}
              </Collapse>
              <Collapse data-testid={'friendly-error-details'} in={!!friendlyErrorDetails} sx={{ color: "#C41923" }}>
                {friendlyErrorDetails}
              </Collapse>
              <Box marginTop={2}>
                <LoadingButton
                  dataTestId={'signin-button'}
                  name={'SignInButton'}
                  type={'submit'}
                  loading={isSubmitting}
                  variant={'contained'}
                >
                  Sign in
                </LoadingButton>
              </Box>
              <Box marginTop={3}>
                <LinkButtonHref
                  dataTestId={'forgot-password-button'}
                  text="Forgot password?"
                  onClick={() => { clearErrors(); navigate('/forgot-password'); }}
                />
              </Box>
              <Box display={'inline'} fontSize={'0.7em'} marginTop={3} marginBottom={2} maxWidth={220}>
                By using Saga Health, you agree to our&nbsp;
                <LinkButtonHref
                  dataTestId={'tos-button'}
                  text="terms of service"
                  onClick={() => { clearErrors(); navigate('/terms-of-service'); }}
                />
                &nbsp; and &nbsp;
                <LinkButtonHref
                  dataTestId={'privacy-policy-button'}
                  text="privacy policy"
                  onClick={() => { clearErrors(); navigate('/privacy-policy'); }}
                />
              </Box>
            </Box>
          </Section.Column>
        </Form>
      </Box>
    </FormProvider>
  )
}

type FormValues = {
  email?: string
  password?: string
}

export default SignInForm