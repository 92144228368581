import { Box, Divider, useTheme } from '@mui/material'
import MuiSelect from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import { Controller, useFormContext } from 'saga-library/src/components/Form'

type LinkSelectProps<T> = {
  name: string
  defaultValue: T
  options: { value: T, label: string }[]
  onChange?: (value) => void
  disabled?: boolean
  dataTestId?: string
}

export function LinkSelect<T>({
  name,
  defaultValue,
  options,
  onChange,
  disabled,
  dataTestId
}: LinkSelectProps<T>) {
  const { control } = useFormContext()
  const theme = useTheme()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: formOnChange, value = defaultValue } }) => (
        <Divider>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
            }}
          >
            <MuiSelect
              data-testid={`${dataTestId}-select`}
              onChange={(newValue) => {
                formOnChange(newValue)
                if (onChange) {
                  onChange(newValue)
                }
              }}
              variant={'standard'}
              disabled={disabled}
              disableUnderline={true}
              MenuProps={{
                sx: {
                  "& .Mui-selected": {
                    backgroundColor: `${theme.palette.backgrounds.selected} !important`
                  },
                  "& .Mui-selected:hover": {
                    backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
                  },
                  "& .MuiMenuItem-root[aria-selected='false']:hover": {
                    backgroundColor: `${theme.palette.backgrounds.hover} !important`,
                  }
                }
              }}
              sx={{
                ml: 1,
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                fontSize: '14px'
              }}
              value={value}
            >
              {options.map(({ label, value }, index) => (
                <MenuItem data-testid={`${dataTestId}-menu-item-${index}`} key={index} value={value as any}>
                  {label}
                </MenuItem>
              ))}
            </MuiSelect>
          </Box>
        </Divider>
      )}
    />
  )
}