import { useParams } from "react-router-dom";
import { useAccountContext } from "../providers/AccountContext";
import React, { ReactElement } from "react";
import { Permission, PermissionType } from "../types/settings/Permission";
import LockIcon from "@mui/icons-material/Lock"
import { SimpleTooltip } from "saga-library/src"

interface ReadOnlyButtonMaskProps {
  requiredType: PermissionType
  requiredPermission: Permission
  children: ReactElement | ReactElement[]
}
export const ReadOnlyFormActionsMask = ({children, requiredType, requiredPermission}: ReadOnlyButtonMaskProps) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()

  const shouldRender = (tenant_id && userHasPermission(tenant_id, requiredType, requiredPermission))

  if(shouldRender) {
    return <> {children} </>
  }

  return <SimpleTooltip title={"You don't have permission to edit this page."}>
    <LockIcon sx={theme=>({
    color:theme.palette.error.main
  })} />
  </SimpleTooltip>
}