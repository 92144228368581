import React from 'react'
import { useTheme } from "@mui/material";
import { Typography } from "../Typography";
import { SagaTypographyVariants } from "../Typography/Typography";

export interface TextHightlightingProps {
  text: string
  highlight: string
  variant?: SagaTypographyVariants,
  dataTestId?: string
  maxLines?: number
  exactMatchOnly?: RegExp
}
export const TextHighlighting = ({ text, highlight, variant='body1', dataTestId, maxLines, exactMatchOnly}:TextHightlightingProps) => {
  const theme = useTheme()

  if (!text) {
    return null
  }
  if (!highlight?.trim()) {
    return <Typography dataTestId={dataTestId} variant={variant} lineclamp={maxLines}>{text}</Typography>
  }
  if (exactMatchOnly){
    const textStripped = text.replace(exactMatchOnly, '').toLowerCase()
    const highlightStripped = highlight.replace(exactMatchOnly, '').toLowerCase()
    return <Typography dataTestId={dataTestId} variant={variant} lineclamp={maxLines} sx={textStripped === highlightStripped ? {fontWeight: 700, color: theme.palette.greys.dark } : {}}>
      {text}
    </Typography>
  }
  const [regex, parts] = SearchTermMatcher(text, highlight)
  return (
    <Typography dataTestId={dataTestId} variant={variant} lineclamp={maxLines}>
      {parts.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <span key={i} style={{ fontWeight: 700, color: theme.palette.greys.dark }}>
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        )
      })}
    </Typography>
  )
}

export const SearchTermMatcher = (text, highlight): [RegExp, string[]] => {
  const terms = highlight
    .split(/\s/)
    .filter(String)
    .map((term) => term.replace(/([^a-z0-9 '@._-]+)/gi, '').split('').join('(?:-s*)?'))

  const regex = new RegExp(`(${terms.join('|')})`, 'gi')
  const parts = text.split(regex)
  return [regex, parts]
}
