import { RefObject, useRef } from 'react'

export const useFocus = (): [
  RefObject<HTMLInputElement>,
  (x?: boolean) => void
] => {
  const htmlElRef = useRef<HTMLInputElement>(null)
  const setFocus = (focused: boolean = true) => {
    if (focused) {
      htmlElRef.current && htmlElRef.current.focus()
    } else {
      htmlElRef.current && htmlElRef.current.blur()
    }
  }

  return [htmlElRef, setFocus]
}
