import * as yup from 'yup'

export const PLACEHOLDER_SCHEDULE_ID = "placeholder"

const nullIfEmpty = (value) => (!value ? null : value)

export const schema = yup
  .object({
    startDate: yup
      .date()
      .required('Required'),
    appointmentTypeId: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    scheduleId: yup
      .string()
      .required('Required'),
    appointmentLength: yup
      .number()
      .max(999, 'Duration must be less than 999')
      .min(1, 'Duration must be greater than 0')
      .required('Required'),
  })
  .required()
