import { Permission } from "../../../../types/settings/Permission";
import { Role, User } from "../../../../types/account";

export type TenantRoleFormType = {
  version: string,
  name?: string
  description?: string
  isAdmin?: boolean
  billing?: {
    readonly: boolean,
    readwrite: boolean,
  },
  schedule?: {
    readwrite: boolean,
  },
  practitioner?: {
    readwrite: boolean,
  },
  chart?: {
    readwrite: boolean,
  },
  reporting?: {
    readwrite: boolean,
    billing: boolean,
    patientAndAppointment: boolean,
  },
  labs?:{
    review: boolean
  },
  roleUsers?: User[],
  userChangeList?: User[]
}

export const RoleDefaults = {
  version: "0",
  name: '',
  description: '',
  isAdmin: false,
  billing: {
    readonly: false,
    readwrite: false,
  },
  schedule: {
    readwrite: false,
  },
  practitioner: {
    readwrite: false,
  },
  chart: {
    readwrite: false,
  },
  reporting: {
    readwrite: false,
    billing: false,
    patientAndAppointment: false,
  },
  labs:{
    review: false
  },
  roleUsers: [],
}

export const getTenantRoleFormDefaultValues = (role?: Role): TenantRoleFormType => {
  const permBilling = role ? Permission[role.permBilling] : Permission.NONE
  const permSchedule = role ? Permission[role.permSchedule] : Permission.NONE
  const permPractitioner = role ? Permission[role.permPractitioner] : Permission.NONE
  const permChart = role ? Permission[role.permChart] : Permission.NONE
  const permReportingBilling = role ? Permission[role.permReportingBilling] : Permission.NONE
  const permReportingPatientAndAppointment = role ? Permission[role.permReportingPatientAndAppointment] : Permission.NONE
  const permLabsReview = role ? Permission[role.permLabsReview] : Permission.NONE

  return {
    version: role?.version || RoleDefaults.version,
    name: role?.name || RoleDefaults.name,
    description: role?.description || RoleDefaults.description,
    isAdmin: role?.isAdmin || RoleDefaults.isAdmin,
    billing: {
      readonly: (permBilling >= Permission.READONLY) || RoleDefaults.billing.readonly,
      readwrite: (permBilling >= Permission.READWRITE) || RoleDefaults.billing.readwrite,
    },
    schedule: {
      readwrite: (permSchedule >= Permission.READWRITE) || RoleDefaults.schedule.readwrite,
    },
    practitioner: {
      readwrite: (permPractitioner >= Permission.READWRITE) || RoleDefaults.practitioner.readwrite,
    },
    chart: {
      readwrite: (permChart >= Permission.READWRITE) || RoleDefaults.chart.readwrite,
    },
    reporting: {
      billing: (permReportingBilling >= Permission.READONLY) || RoleDefaults.reporting.billing,
      patientAndAppointment: (permReportingPatientAndAppointment >= Permission.READONLY) || RoleDefaults.reporting.patientAndAppointment,
      readwrite: (permReportingBilling >= Permission.READWRITE || permReportingPatientAndAppointment >= Permission.READWRITE) || RoleDefaults.reporting.readwrite,
    },
    labs:{
      review: (permLabsReview >= Permission.READWRITE) || RoleDefaults.labs.review
    },
    roleUsers: role?.roleUsers || RoleDefaults.roleUsers,
  }
}