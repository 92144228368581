import React, { useEffect } from "react";
import { SaveButton } from "saga-library/src";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { schema } from "./util/validation";
import { PharmacyForm } from "./PharmacyForm";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { useAccountContext } from "../../../providers/AccountContext";
import { PharmacyInput } from "../../../types";
import { CREATE_PHARMACY, LIST_PHARMACIES } from "../../../graphql-definitions";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

const FORM_NAME = 'new_pharmacy_form'

export type PharmacyDetailsType = {
  name: string
  street: string
  street2: string
  street3: string
  city: string
  province: string
  postalCode: string
  phoneNumber: string
  faxNumber: string
  version: string
}

export const pharmacyDetailsDefaults = {
  name: '',
  street: '',
  street2: '',
  street3: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: '',
  faxNumber: '',
  version: "0"
}

export const PharmacyNew = () => {
  const navigate = useNavigate()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()

  const [createPharmacy] = useMutation(
    CREATE_PHARMACY,
    {
      onCompleted: (data) => {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        const newPharmacy = _get(data, 'tenant.pharmacy.create')
        const newPharmacyId = newPharmacy?.id
        showSuccessAlert(`Pharmacy has been added.`)
        navigate(buildTenantRoute(`settings/pharmacies/p/${newPharmacyId}`,tenant_id))
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Pharmacy couldn\'t be created.')
      },
    }
  )

  const formMethods = useForm<PharmacyDetailsType>({
    defaultValues: pharmacyDetailsDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit(async (data) => {
    const {street, ...rest} = data
    const newPharmacy: PharmacyInput = {
      ...rest,
      street1: street
    }

    await createPharmacy(({
      variables: {
        tenantId: tenant_id,
        input: newPharmacy
      },
      update(cache, returnedData) {
        cache.modify({
          fields: {
            list(existingPharmacies = []) {
              const newPharmacy = _get(returnedData, 'tenant.pharmacy.create')
              cache.writeQuery({
                query: LIST_PHARMACIES,
                data: {
                  tenant: {
                    pharmacy: {
                      list: [ ...existingPharmacies, newPharmacy ]
                    }
                  }
                }
              })
            }
          }
        })
      }
    }))
  })

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn
        header={
        <SettingsHeader
          title={'New pharmacy'}
          onBack={() => navigate(-1)}
          actions={<SaveButton
              form={FORM_NAME}
              dataTestId={"pharmacyNew"}
              submitting={isSubmitting}
            />
          }
          dataTestId={"pharmacyNew"}
        />
        }>
        <PharmacyForm formName={FORM_NAME} onSubmit={onSubmit} width={'30%'}/>
      </SettingsSectionColumn>
    </FormProvider>
  )
}
