import React from 'react'
import * as yup from 'yup'
import { PostalCodeValidation } from 'saga-library/src/components/PostalCodeField'
import { PhoneValidation } from 'saga-library/src/components/PhoneField'
import _get from 'lodash/get'

export const rules = {
  firstName: { max: 100 },
  lastName: { max: 100 },
  email: { max: 320 },
  phones: {
    number: { max: 15 },
    notes: { max: 48 },
    ext: { max: 15 },
  },
  address: {
    street: { max: 100 },
    city: { max: 100 },
    province: { max: 2 },
    postalCode: { max: 10 },
    country: { max: 100 }
  },
  practitionerId: { max: 100 },
  notes: { max: 320 },
}

const maxLengthMessage = (value) => `Max length of ${value}`

const nullIfEmpty = (value) => (!value ? null : value)

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup.object({
  lastName: yup
    .string()
    .max(rules.lastName.max, maxLengthMessage(rules.lastName.max))
    .required('Required'),
  firstName: yup
    .string()
    .max(rules.firstName.max, maxLengthMessage(rules.firstName.max))
    .required('Required'),
  email: yup
    .string()
    .max(rules.email.max, maxLengthMessage(rules.email.max))
    .email('Invalid email')
    .nullable(),
  phones: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.string().nullable(),
        type: yup.string().required('Required'),
        number: yup
          .string()
          .max(
            rules.phones.number.max,
            maxLengthMessage(rules.phones.number.max)
          )
          .matches(PhoneValidation.regex, 'Invalid phone number')
          .required('Required'),
        notes: yup
          .string()
          .max(
            rules.phones.notes.max,
            maxLengthMessage(rules.phones.notes.max)
          )
          .nullable(),
        extension: yup
          .string()
          .max(rules.phones.ext.max, maxLengthMessage(rules.phones.ext.max))
          .nullable(),
      })
      .nullable()
  ),
  street: yup
    .string()
    .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
    .nullable(),
  street2: yup
    .string()
    .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
    .nullable(),
  street3: yup
    .string()
    .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
    .nullable(),
  city: yup
    .string()
    .max(rules.address.city.max, maxLengthMessage(rules.address.city.max))
    .nullable(),
  province: yup
    .string()
    .transform((val) => nullIfEmpty(val))
    .nullable()
    .length(rules.address.province.max, 'Invalid province code'),
  postalCode: yup
    .string()
    .transform((val) => nullIfEmpty(val))
    .nullable()
    .matches(PostalCodeValidation.regex, 'Invalid postal code'),
  country: yup
    .string()
    .max(rules.address.country.max, maxLengthMessage(rules.address.country.max))
    .nullable(),
  practitionerId: yup
    .string()
    .max(rules.practitionerId.max, maxLengthMessage(rules.practitionerId.max))
    .nullable(),
  skills: yup
    .array()
    .nullable(),
  disciplineId: yup
    .string()
    .nullable(),
  notes: yup
    .string()
    .max(rules.notes.max, maxLengthMessage(rules.notes.max))
    .nullable(),
})
