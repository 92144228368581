import React from 'react'
import { Section, TextField, TextFieldMask } from "saga-library/src";
import { rules } from '../util/referralPractitionerValidation'
import { FormFragment } from "../../../../../components/PermissionForm";
import { PractitionerMaskedTextField } from "../../../../../components/PractitionerMaskedTextField";

export type IdentifierDetailsType = {
  practitionerId: string
}

const defaults = {
  practitionerId: '',
}

const Form = ({ requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Identifiers</Section.Header>
        <PractitionerMaskedTextField
          dataTestId={"identifierDetails-practitionerId"}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const IdentifierDetails = {
  Form: Form,
  defaults: defaults,
}
