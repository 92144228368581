import React from 'react'
import { EncounterNoteTemplate, EncounterNoteTemplateFieldType } from '../../../../types/patients'
import { Box, SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { EmptyList } from 'saga-library/src'
import { buildNewEncounterNote } from './EncounterNoteUtil'
import { EncounterNoteContainer } from './components/EncounterNoteContainer'
import { EncounterNoteTextField } from './components/EncounterNoteTextField'

interface EncounterNoteTemplateFieldsProps {
  template?: EncounterNoteTemplate | undefined,
  isSigned?: boolean,
  disabled?: boolean,
  sx?: SxProps<Theme>
}

export const EncounterNoteTemplateFields = ({
  template,
  isSigned,
  disabled,
  sx
}: EncounterNoteTemplateFieldsProps) => {
  if (!template) {
    return <EmptyList message={"Select an encounter note template"} />
  }

  const renderField = (templateField, dataTestId) => {
    switch (templateField.type) {
      case EncounterNoteTemplateFieldType.TEXTBOX:
        return (
          <EncounterNoteTextField
            name={`textbox-${templateField.id}`}
            isSigned={isSigned}
            disabled={disabled}
            dataTestId={dataTestId}
          />
        )
      default:
        console.warn("Unknown field type: ", templateField.type)
    }
  }

  return (
    <Box
      key={'encounter-note-template-fields'}
      display="flex"
      flexDirection="column"
      flex={'1 1 100%'}
      gap={1}
      mb={1}
      sx={{...sx}}
    >
      {
        buildNewEncounterNote(template)?.template?.fieldTree?.map((templateField, index) => {
          const dataTestId = `encounter-note-field-${index}`
          return (
            <EncounterNoteContainer key={templateField.id} templateField={templateField} dataTestId={dataTestId}>
              {renderField(templateField, dataTestId)}
            </EncounterNoteContainer>
          )
        })
      }
    </Box>
  )
}