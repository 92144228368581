import { Section, useForm } from 'saga-library/src'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { usePrompt } from 'providers/NavigationPrompt'
import { useLazyQuery, useMutation, useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { DocumentResultHeader } from './DocumentsHeader'
import { flushSync } from 'react-dom'
import { DocumentResultForm } from './DocumentResultForm'
import * as yup from "yup";
import { useReviewContext } from '../../providers/ReviewProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { GET_REVIEW_DOCUMENT, MARK_DOCUMENT_REVIEWED } from '../../../../graphql-definitions'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { useReviewDocumentStateContext } from '../../providers/ReviewDocumentStateProvider'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { ReviewDocument } from '../../../../types/inbox/ReviewDocument'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { useAccountContext } from '../../../../providers/AccountContext'

const schema = yup.object().shape({
  id: yup.string()
})

const FORM_NAME = 'document_inbox_form'

export const DocumentResult = () => {
  const { userDocumentsRef } = useReviewContext()

  if (!userDocumentsRef) return null
  return <ViewDocumentResult />
}

const ViewDocumentResult = () => {
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { tenant_id, document_id, user_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { saveDocumentEdits, userDocumentsRef, parseUserDocumentQueryResults, refetchUserDocuments } = useReviewContext()
  const { documentModified } = useReviewDocumentStateContext()
  const { updateDocumentCount } = useUpdateCount()
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)
  const [ reviewDocument, setReviewDocument ] = useState<ReviewDocument>()
  const [ loading, setLoading ] = useState<boolean>(false)

  const { data } = useReadQuery(userDocumentsRef)
  const reviewDocumentList = useMemo(() => parseUserDocumentQueryResults(data), [data, parseUserDocumentQueryResults])

  const [getReviewDocument, { data: reviewDocumentData }] = useLazyQuery(GET_REVIEW_DOCUMENT, {
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error(error)
    }
  })

  useEffect(() => {
    if (document_id && tenant_id) {
      setLoading(true)
      getReviewDocument({ variables: { id: document_id, tenantId: tenant_id } })
    }
  }, [document_id, tenant_id])

  useEffect(() => {
    if (reviewDocumentData) {
      setReviewDocument(_get(reviewDocumentData, 'tenant.review.document.get', null))
      setLoading(false)
    }
  }, [reviewDocumentData])

  const formMethods = useForm({
    schema: schema
  })

  const {
    handleSubmit,
    formState: { isSubmitting},
  } = formMethods

  const handleNavigationPromptDiscard = () => {
    setOpenNavigationPrompt(false)
  }

  const navToNextDocument = useCallback((currentDocumentId) => {
    const currentIndex = reviewDocumentList.findIndex(doc => doc.id === currentDocumentId)
    if (currentIndex !== -1 && reviewDocumentList.length > 1) {
      let nextDocument = reviewDocumentList[0]
      if (currentIndex < reviewDocumentList.length - 1) {
        nextDocument = reviewDocumentList[currentIndex + 1]
      }
      navigate(buildTenantRoute(`inbox/u/${user_id}/documents/d/${nextDocument.id}`, tenant_id))
    } else {
      navigate(buildTenantRoute(`inbox/u/${user_id}/documents`, tenant_id))
    }
    refetchUserDocuments()
  }, [reviewDocumentList])

  const onSubmit = handleSubmit( async (data) => {
    await saveDocumentEdits(() => {
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
    })
  })

  const [markAsReviewedMutation, {loading: loadingMarkReviewed}] = useMutation(MARK_DOCUMENT_REVIEWED)

  const markAsReviewed = useCallback(() => {
    saveDocumentEdits(async () => {
      await markAsReviewedMutation({
        variables: {
          tenantId: tenant_id,
          id: document_id,
          version: reviewDocument?.version || "0"
        },
        onCompleted: (data) => {
          showSuccessAlert('Document has been marked as reviewed.')
          flushSync(() => {
            clearNavigationPrompt(FORM_NAME)
          })
          updateDocumentCount(-1)
          navToNextDocument(document_id)
        },
        onError: () => {
          showErrorAlert('Document couldn\'t be marked as reviewed.')
        },
      })
    })
  }, [document_id, tenant_id, reviewDocument])

  useEffect(() => {
    enableNavigationPrompt(documentModified, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [documentModified, openNavigationPrompt])

  return (
    <Section.Column
      sx={{ pr: 1 }}
      width={'100%'}
      header={
        <DocumentResultHeader
          reviewDocument={reviewDocument}
          formName={FORM_NAME}
          formSubmitting={isSubmitting}
          loading={loading}
          onMarkAsReviewedClicked={markAsReviewed}
          loadingMarkReviewed={loadingMarkReviewed}
        />
      }
    >
      <FormProvider {...formMethods}>
        { loading ?
          <LoadingSpinner />
          :
          <DocumentResultForm
            onSubmit={onSubmit}
            formName={FORM_NAME}
            document={reviewDocument?.linkedDocument}
          />
        }
      </FormProvider>
    </Section.Column>
  )
}