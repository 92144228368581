import React from 'react'
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { NoMaxWidthTooltip } from "saga-library/src/components/Tooltip/Tooltip";
import { LIST_SCHEDULES } from "../graphql-definitions";
import { PractitionerByScheduleIdsSelect } from "./PractitionerByScheduleIdsSelect";

interface PractitionerSelectProps {
  name: string
  label: string
  locationId: string
  disabled?: boolean
}

export const PractitionersForLocationSelect = ({
  name,
  label,
  locationId,
  disabled = false
}: PractitionerSelectProps) => {
  const { tenant_id } = useParams()
  const { data } = useQuery(LIST_SCHEDULES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })
  const schedules = _get(data, 'tenant.schedule.listSchedules', [])
  const practitioners = schedules.filter(s => s.locationId === locationId)

  if (disabled) {
    return (
      <NoMaxWidthTooltip
        title={'Select a location to see the practitioners with schedules there.'}
        placement={'bottom'}
        sx={{ maxWidth: 'none' }}
      >
        <span>
          <PractitionerByScheduleIdsSelect
            name={name}
            label={label}
            disabled={disabled}
            schedules={practitioners}
            allowNullOption={true}
            dataTestId={"practitionersForLocationSelect-tooltip"}
          />
        </span>
      </NoMaxWidthTooltip>
    )
  }

  return (
    <PractitionerByScheduleIdsSelect
      name={name}
      label={label}
      disabled={disabled}
      schedules={practitioners}
      allowNullOption={true}
      dataTestId={"practitionersForLocationSelect"}
    />
  )
}