import React from "react";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Section, Typography } from "saga-library/src";

interface SettingsFormSectionProps {
  label?: string,
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  refElement?: React.Ref<HTMLDivElement>
  dataTestId?: string
}

export const SettingsFormSection = ({
  label,
  sx = {},
  children,
  ...props
}: SettingsFormSectionProps) => {
  return (
    <Section.FormSection {...props} sx={{ mb: 3, ...sx }}>
      {label && <Typography variant={'body1'} fontWeight={'bold'}>{label}</Typography>}
      {children}
    </Section.FormSection>
  )
}
