import React, { useCallback, useEffect, useRef, useState } from "react";
import { PermissionForm } from "../../../../../components/PermissionForm";
import { PermissionType } from "../../../../../types/settings/Permission";
import { PractitionerSelect } from "../../../../../components/PractitionersSelect";
import { ConfirmationDialog, TextField } from "saga-library/src";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { useParams } from "react-router-dom";
import { FormType } from "../../../../../types/patients";
import { SharedFormsSearch } from "../../SharedFormsSearch";
import { useFormContext } from "react-hook-form";
import { PatientFormViewer } from "./PatientFormViewer";
import { usePatientFormStateContext } from "./FormsStateProvider";
import { SharedForm } from "../../../../../types/patients/Forms";
import { FormRow } from "../../../../../components/FormRow";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";
import { LocationSelect } from "../../../../../components/LocationSelect";
import { useTenantContext } from "../../../../../providers/TenantContextProvider";
import { useAccountContext } from "../../../../../providers/AccountContext";
import { useLocationContext } from "../../../../../providers/LocationContextProvider";

const FoxitFileTypes = {
  "XFDF": 1,
  "XML": 2,
  "CSV": 3
};

interface PatientFormsFormProps {
  onSubmit: (data: any) => void;
  formName: string;
  formDisabled?: boolean;
  form?: FormType | null;
}

export const FormsForm = ({
  onSubmit: onSubmitParent,
  formName,
  formDisabled = false,
  form
}: PatientFormsFormProps) => {
  const { patient_id } = useParams();
  const { selectedFile, setSelectedFile, pdfDocRef } = usePatientFormStateContext();
  const { practitioners } = useTenantContext();
  const { userId } = useAccountContext();
  const { locations } = useLocationContext()

  const [sharedForm, setSharedForm] = useState<SharedForm>();
  const [ previousCsvData, setPreviousCsvData] = useState<string>('')

  const [ sharedFormChanged, setSharedFormChanged ] = useState<{
    message: string | null,
    sharedForm?: SharedForm | null,
  }>({ message: null, sharedForm: null })

  const { reset, handleSubmit, setValue } = useFormContext()

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(true);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !hasFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  useEffect(() => {
    if (form) {
      reset(form)
    }
  }, [form])

  useEffect(() => {
    async function sharedFormChanged() {
      if (pdfDocRef && pdfDocRef.current) {
        const currentForm = await ReadPDFData(selectedFile)
        setPreviousCsvData(currentForm?.csvData || '')
      }
    }

    sharedFormChanged()
  }, [pdfDocRef?.current])
  
  useEffect(() => {
    if(!form?.id) {
      const practitionerId = practitioners.find(p => p.userId === userId)?.id
      if (!!practitionerId) {
        setValue("practitionerId", practitionerId || "", { shouldDirty: false });

        const practitionerLocations = locations.filter(l => l.practitionerIds?.includes(practitionerId))
        const locationId = practitionerLocations.length === 1 ? practitionerLocations[0].id : ""
        setValue("locationId", locationId, { shouldDirty: false })
      }
    }
  }, [form, locations, practitioners, setValue, userId]);

  useEffect(() => {
    async function populateFileFields() {
      if (form && form.csvData && pdfDocRef && pdfDocRef.current) {
        await pdfDocRef.current?.importFormFromFile(
          new Blob([form.csvData], { type: "text/csv;charset=utf-8;" }),
          FoxitFileTypes.CSV,
          'UTF-8'
        );
      }
    }

    populateFileFields()
  }, [pdfDocRef?.current, form])


  const clearSharedFormChanged = () => {
    setSharedFormChanged({ message: null, sharedForm: null })
  }

  const ReadPDFData = async (data): Promise<FormType | null> => {
    return new Promise(async(resolve, reject) => {
      if (sharedForm && pdfDocRef && pdfDocRef.current) {
        try {
          let blobResult = await pdfDocRef.current.exportFormToFile(FoxitFileTypes.CSV)

          const fileReader = new FileReader()
          fileReader.onload = async (event) => {
            data.csvData = event.target?.result as string;
            data.sharedFormId = sharedForm.id;
            resolve(data);
          };

          fileReader.onerror = reject
          // Making this call will trigger the onload event defined above
          fileReader.readAsText(blobResult, 'UTF-8')
        } catch (e) {
          reject(data)
          console.error(e)
        }
      } else {
        resolve(data)
      }
    })
  }

  const onSubmit = handleSubmit(async (data) => {
    data.patientId = patient_id!;
    ReadPDFData(data)
      .then(async(newData) => {
        await onSubmitParent(newData)
      })
      .catch()
  })

  const onSharedFormSelected = useCallback (async (sharedForm) => {
    if (selectedFile && !form){
      let currentForm = await ReadPDFData(selectedFile)
      if (currentForm?.csvData !== "\r\nundefined" && previousCsvData !== currentForm?.csvData) {
        setSharedFormChanged({ message: "This form has been modified.", sharedForm: sharedForm })
        return
      }
    }

    await onSharedFormChanged(sharedForm)
  }, [selectedFile, previousCsvData, form])

  const onSharedFormChanged = async (sharedForm) => {
    setSharedForm(sharedForm);
    setSelectedFile(sharedForm?.sharedFile ?? null);
  }

  return <PermissionForm
    name={`${formName}_forms_form`}
    onSubmit={onSubmit}
    id={formName}
    requiredPermissionType={PermissionType.Chart}
    readOnlyOverride={true}
    formDisabled={formDisabled}
    sx={{
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      paddingRight: "8px",
      height: "100%"
    }}
  >
    <FormRow>
      <SharedFormsSearch
        name={"name"}
        label={"Form"}
        onChange={onSharedFormSelected}
        selectedOptionId={form?.sharedFormId}
        disabled={formDisabled}
        hidden={!!form}
        sx={{width: '100%'}}
        inputRef={firstFieldRef}
      />
    </FormRow>
    <FormRow>
      <TextField
        label={"Description"}
        name={"description"}
        dataTestId={"forms-form-description"}
        sx={{
          flex: '1 1 100%'
        }}
      />
      <PractitionerSelect
        label={"Practitioner"}
        name={"practitionerId"}
        dataTestId={"forms-form-practitioner"}
        sx={{
          flex: '0 0 auto',
          width: '200px'
        }}
      />
      <LocationSelect
        label={"Location"}
        name={"locationId"}
        dataTestId={"forms-form-location"}
        allowNullOption={true}
        containerSx={{
          flex: '0 0 auto',
          width: '200px'
        }}
      />
      <FormattedDatePicker
        label={"Date"}
        name={"formDate"}
        dataTestId={"forms-form-datePicker"}
        sx={{
          flex: '0 0 auto',
          width: '200px'
        }}
      />
    </FormRow>
    <PatientFormViewer
      prefillFields={!form}
      sx={{
        overflow: "hidden",
        flex: "1 1 auto",
      }}
    />
    <ConfirmationDialog
      open={!!sharedFormChanged.message}
      title={'Change shared form?'}
      message={sharedFormChanged.message}
      primaryAction={async () => {
        await onSharedFormChanged(sharedFormChanged.sharedForm)
        clearSharedFormChanged()
      }}
      primaryLabel={'discard'}
      onClose={clearSharedFormChanged}
      dataTestId={'changedSharedForm-dialog'}
    />
  </PermissionForm>
};