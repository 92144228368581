import { gql } from "@apollo/client";

export const GET_SHARED_FORM = gql`
    query GetSharedForm($tenantId: ID!, $sharedFormId: ID!) {
        tenant(id: $tenantId){
            sharedForm {
                get(sharedFormId: $sharedFormId) {
                    id
                    name
                    sharedFile {
                        id
                        fileUri
                        containerName
                        blobName
                    }
                }
            }
        }
    }
`

export const LIST_SHARED_FORMS = gql`
    query ListSharedForms($tenantId: ID!) {
        tenant(id: $tenantId){
            sharedForm {
                list {
                    id
                    name
                    sharedFile {
                        id
                        fileUri
                        containerName
                        blobName
                    }
                }
            }
        }
    }
`