import React from "react";
import { SimpleColorPicker, TableListRowConfig, Typography } from "saga-library/src";
import { Chip } from "saga-library/src/components/Chip/Chip";
import ChipBag from "saga-library/src/components/Chip/ChipBag";
import SettingsRow from "../../components/SettingsRow";

export const AppointmentTypeRow = (appointmentType, setEditAppointmentType, hasReadWrite): TableListRowConfig => {

  return ({
    onClick: hasReadWrite ? () => setEditAppointmentType(appointmentType) : null,
    key: appointmentType.id,
    rowData: [
      {
        children: [
          <SettingsRow sx={{ p: "8px 0px" }} key={"name" + appointmentType.id}>
            <Typography variant={"body1"}>{appointmentType.name}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow key={"color" + appointmentType.id}>
            <SimpleColorPicker
              color={appointmentType.color}
              disabled={true}
            />
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow sx={{ p: "8px 0px" }} key={"name" + appointmentType.id}>
            <Typography
              variant={"body1"}>{appointmentType.duration ? appointmentType.duration + " minutes" : ""}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: <AppointmentTypePlaces appointmentType={appointmentType} />
      },
      {
        children: <AppointmentTypeSchedules appointmentType={appointmentType} />
      }
    ]
  });
};

const AppointmentTypePlaces = ({ appointmentType }) => {
  return (
    <>
      {appointmentType.inPerson &&
        <Chip key={"inPerson" + appointmentType.id} label={"In person"} variant={"outlined"} />}
      {appointmentType.byPhone &&
        <Chip key={"byPhone" + appointmentType.id} label={"By phone"} variant={"outlined"} />}
      {appointmentType.videoCall &&
        <Chip key={"byVideoCall" + appointmentType.id} label={"By video call"} variant={"outlined"} />}
    </>
  );
};

const AppointmentTypeSchedules = ({ appointmentType }) => {
  return <ChipBag
    allChips={appointmentType.allSchedules}
    allChipsText={"All schedules"}
    chips={appointmentType.schedules.map(schedule => ({
      id: schedule.id,
      label: `${schedule.practitionerLastName}, ${schedule.practitionerFirstName} (${schedule.location.name})`
    }))}
  />;
};