import React from 'react'
import { Select } from 'saga-library/src'
import { CircularProgress, MenuItem } from "@mui/material";

export type Province = {
  code: string | null
  name: string | null
}

interface ProvinceSelectProps {
  disabled?: boolean
  dataTestId?: string,
  data: Province[],
  loading?: boolean
}

export const ProvinceSelect = ({
  disabled = false,
  dataTestId,
  data,
  loading = false
}: ProvinceSelectProps) => {
  return (
    <Select
      dataTestId={dataTestId}
      label={'Province'}
      name={'province'}
      disabled={disabled}
    >
      {loading && (
        <MenuItem>
          <CircularProgress />
        </MenuItem>
      )}
      {data && data.map((province, index) =>
        <MenuItem
          data-testid={`${dataTestId}-menuItem-${index}`}
          value={province.code ?? undefined}
          key={province.code}>{province.name}
        </MenuItem>
      )}
    </Select>
  )
}

export default ProvinceSelect
