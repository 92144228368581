


export const setProblems = (problems, setError) => {
  problems.forEach((problem, index) => {
    if (problem.severity === 'ERROR') {
      setError(problem.field, { type: 'error', message: problem.message})
    }
    else {
      setError(problem.field, { type: 'warning', message: problem.message})
    }
  })
}