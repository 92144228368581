import React, { useEffect, useRef, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { Tabs, Form } from "saga-library/src";
import { ClaimInputType } from "../../../../types/billing";
import { ABClaimActionCode, ABClaimAssessmentOutcome } from "../../../../utils/ABClaimConstants";
import { useReadQuery } from '@apollo/client'
import { ClaimOptions } from "../ClaimOptions";
import { AssessedClaimBase } from "./AssessedClaimBase";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { schema } from "../../util/baseClaimValidation";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import { AssessedClaimHeader } from "./AssessedClaimHeader";
import { setAbClaimValues } from "../../util/setAbClaimValues";
import { setProblems } from "../../util/setProblems";
import { createClaimInputFromFormData } from "../../util/createClaimInputFromFormData";
import { ClaimHistory } from "../ClaimHistory";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AssessedClaimsInfo } from "./AssessedClaimsInfo";
import claimDefaults from "../../util/ClaimDefaults"
import { withMultipleSelectedHOC } from './AssessedMultipleSelected'
import { useAssessedClaimsContext } from '../../providers/AssessedClaimsProvider'


const FORM_NAME = "assessed_claim_update_details_form"

export const EditAssessedClaim = withMultipleSelectedHOC(() => {
  const { tenant_id, claim_id } = useParams()
  const { getClaim, getClaimQueryRef } = useAssessedClaimsContext()

  useEffect(() => {
    getClaim({
      claimId: claim_id,
      tenantId: tenant_id,
    })
  }, [claim_id, tenant_id])

  return getClaimQueryRef == null ? <></> : <EditAssessedClaimView getClaimQueryRef={getClaimQueryRef} />
})


const EditAssessedClaimView = ({getClaimQueryRef}) => {
  const [claimHistory, setClaimHistory] = useState([])
  const { resubmitClaim } = useAssessedClaimsContext()
  const { claim_id } = useParams()

  const { enableNavigationPrompt } = usePrompt()
  const formRef = useRef<HTMLFormElement>(null)


  const formMethods = useForm<ClaimInputType>({
    defaultValues: claimDefaults,
    schema: schema
  });

  const {
    setValue,
    getValues,
    formState: { dirtyFields, isSubmitSuccessful },
    reset,
    setError,
    handleSubmit,
  } = formMethods

  const data = useReadQuery(getClaimQueryRef!)
  const claim = _get(data, 'data.tenant.abClaim.abClaim', null) as any
  const problems = _get(data, 'data.tenant.abClaim.abClaim.problems', []) as any
  const version = claim.version

  setProblems(problems, setError)

  useEffect(() => {
    setAbClaimValues(claim, reset)
    setClaimHistory(claim.history)
  }, [claim, version, reset])


  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])


  const readOnlyOptions = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <AssessedClaimBase />
        </fieldset>
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <ClaimOptions.Form />
        </fieldset>
      ),
    },
    {
      label: 'INFO',
      key: 'INFO',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <AssessedClaimsInfo.Form />
        </fieldset>
      )
    },
    {
      label: 'HISTORY',
      key: 'HISTORY',
      content: (
        <fieldset disabled style={{margin: '0px', padding: '0px', border: '0px'}} >
          <ClaimHistory.HistoryTable history={claimHistory} />
        </fieldset>
      )
    }
  ]

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <AssessedClaimBase />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <ClaimOptions.Form />
      ),
    },
    {
      label: 'INFO',
      key: 'INFO',
      content: (
        <AssessedClaimsInfo.Form />
      )
    },
    {
      label: 'HISTORY',
      key: 'HISTORY',
      content: (
        <ClaimHistory.HistoryTable
          history={claimHistory}
        />
      )
    }
  ]

  const onFormSubmit = handleSubmit(async(data) => {
    const cleanedData = createClaimInputFromFormData(data)
    await resubmitClaim(claim_id, cleanedData, getValues('actionCode'))
  })

  const resubmitWithCode = (actionCode: ABClaimActionCode) => {
    setValue('actionCode', actionCode)
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  return (
    <FormProvider {...formMethods}>
      <AssessedClaimHeader
        getValues={getValues}
        resubmitWithCode={resubmitWithCode}
      />

      <Form onSubmit={onFormSubmit} ref={formRef}>
        {problems?.some(p => p.field === 'claim' && p.severity === 'WARNING') &&
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            {problems.filter(p => p.field === 'claim' && p.severity === 'WARNING').map( p => <>{p.message}<br/></> )}
          </Alert>
        }
        {problems?.some(p => p.field === 'claim' && p.severity === 'ERROR') &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {problems.filter(p => p.field === 'claim' && p.severity === 'ERROR').map( p => <>{p.message}<br/></> )}
          </Alert>
        }
        {claim.assessmentOutcome === ABClaimAssessmentOutcome.HELD && <Tabs options={readOnlyOptions}/>}
        {claim.assessmentOutcome !== ABClaimAssessmentOutcome.HELD && <Tabs options={options}/>}
      </Form>
    </FormProvider>
  )
}

