import React from 'react'
import { NavLink } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'

export interface NavigationTabRouteProps {
  route: string
  label: string
  applyToChildren?: boolean
  dataTestId?: string
}

export const NavigationTabRoute = ({
  route,
  label,
  applyToChildren = false,
  dataTestId
}: NavigationTabRouteProps) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        color: theme.palette.greys.medium,
        '& a:not(.active):hover': {
          backgroundColor: theme.palette.backgrounds.hover,
        },
        '& a::after': {
          content: `"${label}"`,
          fontWeight: 'bold',
          height: 0,
          overflow: 'hidden',
          pointerEvents: 'none',
          userSelect: 'none',
          visibility: 'hidden',
        },
    }}>
      <NavLink
        to={route}
        end={!applyToChildren}
        style={(isActive) => ({
          textDecoration: isActive.isActive ? 'underline' : '',
          textDecorationColor: isActive.isActive ? 'inherit' : 'transparent',
          textDecorationSkipInk: 'none',
          textDecorationThickness: isActive.isActive ? '2px' : '',
          textUnderlineOffset: '2px',
          alignItems: 'center',
          borderRadius: '8px',
          color: isActive.isActive
            ? theme.palette.primary.main
            : 'inherit',
          display: 'inline-flex',
          flexDirection: 'column',
          fontWeight: isActive.isActive ? 'bold' : '',
          padding: '4px 8px',
        })}
        data-testid={`${dataTestId}-navigationTabRoute`}
      >
        {label}
      </NavLink>
    </Box>
  )
}
