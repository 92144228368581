import { PrescriptionIcon } from "../ChartIcons";
import {
  TableList,
  TableListHeaderConfig,
  Typography,
} from "saga-library/src";
import React, { useMemo, useState } from 'react'
import Widget from "../Widget";
import { Box, useTheme } from "@mui/material";
import { PrescriptionStatus, PrescriptionType } from "../../../../types/Prescription";
import moment from "moment-timezone";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { usePrescriptionUtils } from "../prescriptions/components/PrescriptionUtil";
import { Tag } from "../Tag";
import NewPrescriptionDialog from "./components/NewPrescriptionDialog";
import EditPrescriptionDialog from "./components/EditPrescriptionDialog";
import { usePrescriptionContext } from "../../providers/PrescriptionProvider";
import { useReadQuery } from "@apollo/client"

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' }
]


export const PrescriptionWidget = () => {
  const [ openDialog, setOpenDialog]  = useState(false)
  const [ openEditDialog, setOpenEditDialog]  = useState<any>(null)


  const onRowClick = (prescription) => {
    setOpenEditDialog(prescription)
  }

  const onNewDuringEdit = () => {
    setOpenEditDialog(null)
    setOpenDialog(true)
  }

  const onSaveAndNew = () => {
    setOpenDialog(true)
  }

  return (
    <Widget route={'prescriptions'} title={'Prescriptions'} WidgetIcon={PrescriptionIcon} onAddItem={() => setOpenDialog(true)}>
      <PrescriptionWidgetBody onRowClick={onRowClick} />
      <NewPrescriptionDialog open={openDialog} setOpen={setOpenDialog} onSaveAndNew={onSaveAndNew} />
      <EditPrescriptionDialog prescription={openEditDialog} setPrescription={setOpenEditDialog} onNew={onNewDuringEdit}/>
    </Widget>
  )
}

interface PrescriptionWidgetBodyProps {
  onRowClick: (prescription: PrescriptionType) => void
}

const PrescriptionWidgetBody = ({ onRowClick }:PrescriptionWidgetBodyProps) => {
  const { prescriptionsQueryRef, parsePrescriptionQueryResults } = usePrescriptionContext()
  const { data } = useReadQuery(prescriptionsQueryRef!)
  const patientPrescriptions = useMemo(()=> parsePrescriptionQueryResults(data), [data, parsePrescriptionQueryResults])

  const prescriptions = patientPrescriptions.slice()
    .filter(x => x.status !== PrescriptionStatus.INACTIVE)

  return (
    <TableList
      dataTestId={'prescription-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        ...prescriptions.map((prescription) => {
          return {
            onClick: () => onRowClick(prescription),
            rowData: [
              {
                children: <PrescriptionRowCell1 prescription={prescription}/>
              },
              {
                children: <PrescriptionRowCell2 prescription={prescription}/>
              }
            ],
            key: prescription.id!
          }
        })
      ]}
      sx={{height: '100%', "&.MuiTableCell-root": { borderBottom: null }}}
      emptyListComponent={{ message: "Click the add button to add a prescription.", size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      isWidget={true}
    />
  )
}

interface PrescriptionRowCellProps {
  prescription: PrescriptionType
}

const PrescriptionRowCell1 = ({prescription}:PrescriptionRowCellProps) => {
  const theme = useTheme()

  const today = moment().startOf('day')
  const startDate = moment(prescription.startDate)
  const formattedDate = useFormattedDate(startDate)
  const displayDate = startDate.clone().startOf('day').isSame((today)) ? "Today" : formattedDate

  return (
    <Box display={'flex'}
         gap={1}
         sx={{
           textAlign: 'left',
           pr: 1,
           justifyContent: 'left'
         }}>
      {prescription.status === PrescriptionStatus.DRAFT &&
        <Tag label={'Draft'} color={theme.palette.chart.yellowTag} />
      }

      {prescription.status === PrescriptionStatus.ACTIVE && (
        <Box
          sx={{
            borderRadius: 4,
            width: 'fit-content',
            pr: 1,
            display:'flex',
            flexDirection:'column',
          }}
        >
          <Typography variant={'body2'}>{displayDate}</Typography>
        </Box>
      )}
    </Box>
  )
}

const PrescriptionRowCell2 = ({prescription}:PrescriptionRowCellProps) => {
  const { buildSummary } = usePrescriptionUtils()
  const dosageSummary = buildSummary(prescription)

  return (
    <Box display={'flex'} sx={{ flexDirection:"column", lineHeight: "16px" }} gap={0}>
      <Typography lineclamp={1} variant={'body2'} >
        {prescription.drug.name}
      </Typography>
      <Typography lineclamp={1} variant={'p3'} >
        {dosageSummary}
      </Typography>
    </Box>
  )
}