import { TableListCellConfig, TableListRowConfig, Typography } from 'saga-library/src'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import React from 'react'
import { patientDisplayName } from 'saga-library/src/util'
import { ReviewDocument } from '../../../../types/inbox/ReviewDocument'
import moment from 'moment-timezone'
import { GetDocumentCategory } from '../../../../types/Document'

function FormatRowData(result: ReviewDocument, dataTestId: string, formattedDate: string): TableListCellConfig[] {
  const patientName = patientDisplayName(result.patient?.firstName, result.patient?.lastName)
  const description = result.linkedDocument?.description
  const category = GetDocumentCategory(result.linkedDocument.category)

  return [
    {
      data: moment(formattedDate).unix().toString(),
      children: (
        <Typography
          variant={'body2'}
          dataTestId={`${dataTestId}-date`}
        >
          {formattedDate}
        </Typography>
      )
    },
    {
      data: patientName,
      children: (
        <Typography
          variant={'body2'}
          dataTestId={`${dataTestId}-patient`}
        >
          {patientName}
        </Typography>
      )
    },
    {
      children: (
        <Typography
          variant={'body2'}
          dataTestId={`${dataTestId}-category`}
        >
          {category}
        </Typography>
      )
    },
    {
      children: (
        <Typography
          variant={'body2'}
          dataTestId={`${dataTestId}-description`}
        >
          {description}
        </Typography>
      )
    },
  ]
}

export const DocumentsRow = (
  result: ReviewDocument,
  onClick,
  dataTestId,
  isSelected
): TableListRowConfig => {
  const formattedDate = useFormattedDate(result.date)

  return ({
    key: result.id!,
    onClick: onClick,
    isRowClicked: isSelected,
    rowData: FormatRowData(result, dataTestId, formattedDate)
  })
}