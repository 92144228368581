import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { LoadingScreen } from '../components/LoadingScreen'
import { Tenant } from 'types'
import { Setting } from "../types/settings/Setting";
import { DATE_FORMAT, MARKETING_EMAILS } from "../utils/SettingsConstants"
import { Permission, PermissionType } from "../types/settings/Permission"
import { useSagaAuthentication } from "./Auth"
import { GenericError } from "../routes/static/ErrorPages"
import { GET_ACCOUNT_QUERY } from "../graphql-definitions";
import { Province } from 'saga-library/src'
import {
  defaultDocumentViewerSetting,
  DocumentViewerSettingsInterface,
  useDocumentViewerSetting
} from "./useDocumentViewerSetting";

const AccountSettingsKeys = [DATE_FORMAT, MARKETING_EMAILS]

interface AccountContextInterface {
  tenants: Tenant[]
  userId: string
  userFirstName: string
  userLastName: string
  userEmailAddress: string
  userHasTenant: (tenant_id?: string | null) => boolean
  buildTenantRoute: (route: string | null, tenant_id?: string | null) => string
  getTenantProvince: (tenant_id?: string | null) => Province
  getTenantName: (tenant_id?: string | null) => string
  getUserSetting: (key: string) => string | boolean | number
  userHasPermission: (tenant_id: string, type: PermissionType, permission: Permission) => boolean,
  isSupportUser: boolean,
  documentViewerSettings: DocumentViewerSettingsInterface
}

const defaultAccountContext: AccountContextInterface = {
  userId: '',
  userFirstName: '',
  userLastName: '',
  tenants: [],
  userEmailAddress: '',
  userHasTenant: () => false,
  buildTenantRoute: (route, tenant_id) => '',
  getTenantProvince: (tenant_id) => ({} as Province),
  getTenantName: (tenant_id) => '',
  getUserSetting: (key) => '',
  userHasPermission: (tenant_id: string, type, permission) => false,
  isSupportUser: false,
  documentViewerSettings: defaultDocumentViewerSetting,
}

const AccountContext = React.createContext(defaultAccountContext)

export function AccountContextProvider({ children }) {
  const { isAuthenticated } = useSagaAuthentication()
  const documentViewerSettings = useDocumentViewerSetting()
  const { error, data, loading } = useQuery(GET_ACCOUNT_QUERY, {
    variables: {
      keys: AccountSettingsKeys
    },
    fetchPolicy: 'cache-first',
  })
  const [userId, setUserId] = useState<string>('')
  const [userFirstName, setUserFirstName] = useState<string>('')
  const [userLastName, setUserLastName] = useState<string>('')
  const [userEmailAddress, setUserEmailAddress] = useState<string>('')
  const [tenants, setTenants] = useState<Tenant[]>([])
  const [settings, setSettings] = useState<Setting[]>([])
  const [isSupportUser, setIsSupportUser] = useState<boolean>(false)

  if (!isAuthenticated) {
    return <></>
  }

  const buildTenantRoute = (route, tenant_id) => {
    return tenant_id ? `/t/${tenant_id}/${route ? route : ''}` : ''
  }

  const userHasTenant = (tenant_id) => {
    return tenants.some(({ id }) => id === tenant_id)
  }

  const getTenantProvince = (tenant_id) => {
    if (userHasTenant(tenant_id)) {
      const { province }: Tenant = tenants.find(({ id }) => id === tenant_id)!
      return province ? province : ({} as Province)
    }

    return {} as Province
  }

  const getTenantName = (tenant_id) => {
    if (userHasTenant(tenant_id)) {
      const { name }: Tenant = tenants.find(({ id }) => id === tenant_id)!
      return name ? name : ''
    }

    return ''
  }

  const getUserSetting = (key: string): string | boolean | number => {

    const setting = settings.find(s => s.name === key)

    if(!setting) {
      throw Error("Attempt to retrieve setting value not loaded by account context.")
    }

    return setting.value
  }

  const userHasPermission = (tenant_id: string, type: PermissionType, permission: Permission): boolean => {
    const tenant = tenants.find(t => t.id === tenant_id)

    if(tenant) {
      const authorized = tenant.permissions[type]
      return Permission[authorized] >= permission
    }

    return false
  }

  let providerValues = {
    userId,
    userFirstName,
    userLastName,
    tenants,
    userEmailAddress,
    userHasTenant: userHasTenant,
    buildTenantRoute: buildTenantRoute,
    getTenantProvince: getTenantProvince,
    getTenantName: getTenantName,
    getUserSetting: getUserSetting,
    userHasPermission: userHasPermission,
    isSupportUser: isSupportUser,
    documentViewerSettings: documentViewerSettings,
  }

  if (data) {
    const {
      user: {
        tenants: accountTenants,
        profile: { id, firstName, lastName, emailAddress },
        settings: userSettings
      },
    } = data

    if (tenants !== accountTenants) {
      setTenants(accountTenants)
    }

    if (id !== userId || firstName !== userFirstName || lastName !== userLastName) {
      setUserId(id)
      setUserFirstName(firstName)
      setUserLastName(lastName)
      setUserEmailAddress(emailAddress)
      setIsSupportUser(id[0] === '-')

      if(settings) {
        const mappedSettings = userSettings.map(s => ({
          name: s.name,
          value: s.string || s.bool || s.int
        }))
        setSettings(mappedSettings)
      }
    }
  }

  if (loading) {
    return <LoadingScreen message={'Retrieving Account Settings'} />
  }

  if (error) {
    if (isAuthenticated) {
      console.error("AccountContext", error)
      return GenericError()
    }
    else {
      return <></>
    }
   
  }

  return (
    <AccountContext.Provider value={providerValues}>
      {children}
    </AccountContext.Provider>
  )
}

export const useAccountContext = () => {
  return React.useContext(AccountContext)
}
