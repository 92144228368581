import React from 'react'
import ControlledDatePicker, { ControlledDatePickerInterface } from "saga-library/src/components/DatePicker/DatePicker";
import { useAccountContext } from "../providers/AccountContext";
import { DATE_FORMAT } from "../utils/SettingsConstants";

const FormattedDatePicker = ({
  dataTestId,
  label,
  name,
  sx,
  onChange,
  disabled = false
} : ControlledDatePickerInterface) => {

  const { getUserSetting } = useAccountContext()

  return (
    <ControlledDatePicker
      dataTestId={dataTestId}
      label={label}
      name={name}
      sx={sx}
      onChange={onChange}
      format={getUserSetting(DATE_FORMAT) as string}
      disabled={disabled}
    />
  )
}

export default FormattedDatePicker