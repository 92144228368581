import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import React from 'react'

const UserSelectItem = ({ data, props }) => {
  const { lastName, firstName, emailAddress } = data

  return (
    <Box
      component="li"
      sx={{ flex: '1 1 auto' }}
      display={'flex'}
      padding={1}
      alignItems={'center'}
      {...props}
      key={data.key}
      name={`user_${data.value}`}
      role={'option'}
    >
      <Avatar sx={{ marginRight: 1 }}>
        {' '}
        {lastName ? lastName.trim().charAt(0) : 'N/A'}{' '}
      </Avatar>
      <Box>
        <Box> {`${lastName}, ${firstName}`} </Box>
        <Box> {emailAddress || 'n/a'}</Box>
      </Box>
    </Box>
  )
}

export default UserSelectItem
