export function getVersionedEntity(theObject) {
  let result = null;
  if(theObject instanceof Array) {
    for(let i = 0; i < theObject.length; i++) {
      result = getVersionedEntity(theObject[i]);
      if (result) {
        break;
      }
    }
  }
  else
  {
    if (Object.hasOwn(theObject, 'version')) {
      return theObject
    }
    for(let prop in theObject) {
      if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
        result = getVersionedEntity(theObject[prop]);
        if (result) {
          break;
        }
      }
    }
  }
  return result;
}