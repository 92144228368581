import { Popup } from "@mobiscroll/react";
import { Box } from "@mui/material";
import React from "react";
import { Typography } from "saga-library/src";
import { patientDisplayName } from "saga-library/src/util";
import { formatTime } from "../util/scheduleFunctions";


export const EventTooltip = ({
  anchorEl,
  hoverEventData,
  onMouseEnter,
  onMouseLeave
}) => {
  const start = formatTime(hoverEventData?.start)
  const end = formatTime(hoverEventData?.end)

  if (hoverEventData?.__typename === 'Appointment') {
    return (
      <Popup
        display={'anchored'}
        isOpen={!!anchorEl && !!hoverEventData}
        anchor={anchorEl}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        touchUi={false}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={0.5}
          sx={{
            p: 1,
            backgroundColor: '#FFFFFF',
            color: '#000000',
            borderRadius: 1,
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Typography
            variant={'body2'}>{patientDisplayName(hoverEventData?.patient?.firstName, hoverEventData?.patient?.lastName)}</Typography>
          <Typography variant={'body2'}>{start} - {end}</Typography>
          <Typography variant={'body2'}>{hoverEventData?.type?.name}</Typography>
          <Typography variant={'body2'}>{hoverEventData?.state?.name}</Typography>
        </Box>
      </Popup>
    )
  }
  else if (hoverEventData?.__typename === 'ScheduleEvent') {
    return (
      <Popup
        display={'anchored'}
        isOpen={!!anchorEl && !!hoverEventData}
        anchor={anchorEl}
        showOverlay={false}
        contentPadding={false}
        closeOnOverlayClick={false}
        touchUi={false}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={0.5}
          sx={{
            p: 1,
            backgroundColor: '#FFFFFF',
            color: '#000000',
            borderRadius: 1,
          }}
        >
          <Typography variant={'body2'}>{hoverEventData?.title}</Typography>
          <Typography variant={'body2'}>{start} - {end}</Typography>
        </Box>
      </Popup>
    )
  }
  return <></>
}
