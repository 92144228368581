import { Box, useTheme } from "@mui/material";
import React, { useState, useCallback, useEffect } from "react";
import { Section, Typography } from "saga-library/src";
import { droppableBorder } from "./RoomList";
import { Dropcontainer } from "@mobiscroll/react";
import EventUpcoming from "../../../../assets/EventUpcoming.svg";
import { PANEL_CLOSED_WIDTH } from "./SidePanel";
import { RebookAppointmentPanel } from "../RebookAppointmentPanel";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";

interface ShuttleBayProps{
  isMinimized?: boolean | null
}
const ShuttleBay = ({isMinimized=true, }: ShuttleBayProps) => {
  const theme = useTheme()
  const { tempEventHandler, shuttleBay, schedulesData:{rooms} } = useScheduleContext()

  const [ refElem, setRefElem ] = useState<HTMLDivElement | null>(null)
  const { tempEvent } = tempEventHandler

  const setDropContainer = (container) => {
    setRefElem(container)
  }
  const onItemDrop = useCallback((args: any) => {
    shuttleBay.setOccupied(args.data)
  },[])

  const onClose = () => {
    shuttleBay.setUnoccupied()
  }

  useEffect(() => {
    if(!shuttleBay.appointment) {
      onClose()
    }
  }, [shuttleBay.appointment]);

  if(shuttleBay.appointment) {
    return <RebookAppointmentPanel
      isOpen={true}
      onClose={onClose}
      eventData={shuttleBay.appointment}
      room={rooms?.find((room) => room.appointment?.id === shuttleBay.appointment?.id)}
      isMinimized={isMinimized}
    />
  }
  return <Section.Column
    preventScroll={true}
    sx={{
      mr: 0,
      p: isMinimized ? 1 : 0,
      pt: 1,
      height: isMinimized ? '42px' : '100%',
      minWidth: PANEL_CLOSED_WIDTH,
      ...droppableBorder(tempEvent?.event, null, null)
    }}
    refElement={setDropContainer}
  >
    <Dropcontainer onItemDrop={onItemDrop} element={refElem}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          flexDirection: isMinimized ? 'row' : 'column',
        }}
        data-testid={"scheduler-shuttleBay"}
      >
        <img src={EventUpcoming}  alt={'Upcoming event'} color={theme.palette.greys.light}/>
        {isMinimized && <Typography variant={'p2'} sx={{opacity: '64%'}}>Drag and drop to move appointment</Typography>}
      </Box>
    </Dropcontainer>
  </Section.Column>
}

export default ShuttleBay