import React from 'react'
import SettingsHeader from "./components/SettingsHeader";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";

export const AppSetup = () => {
  return (
    <SettingsSectionColumn>
      <SettingsHeader title={'App Setup'} dataTestId={'appSetup'} />
      App Setup Settings
    </SettingsSectionColumn>
  )
}
