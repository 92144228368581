import React, { useEffect } from "react";
import { useFormContext } from "saga-library/src/components/Form";
import { PrescriptionForm } from "./PrescriptionForm";
import { useTenantContext } from "../../../../../providers/TenantContextProvider";
import { useAccountContext } from "../../../../../providers/AccountContext";
import { usePrescriptionUtils } from "../components/PrescriptionUtil";

const FORM_NAME = "new_prescription_form"

export const NewPrescriptionForm = ({formName= FORM_NAME, onSubmit: onSubmitIn = (any) => {}}) => {
  const { practitioners } = useTenantContext()
  const { userId } = useAccountContext()
  const formMethods = useFormContext()
  const { saveAsActive } = usePrescriptionUtils()

  const {
    handleSubmit,
    setValue
  } = formMethods

  useEffect(()=>{
    setValue('practitionerId', practitioners.find(p => p.userId === userId)?.id || '', { shouldDirty: false })
  },[userId])

  const onSubmit = (e) => {
    e.preventDefault()
    saveAsActive(
      setValue,
      handleSubmit,
      (prescription) => {
        onSubmitIn(prescription)
      }
    )
  }

  return(
    <PrescriptionForm
      formName={formName}
      onSubmit={onSubmit}
      autoFocus={true}
    />
  )
}