import React from 'react'
import { batchUpdateStyleSelect, batchUpdateStyleSelectLabel } from "./BatchUpdateStyle";
import { LocationCodeSelect } from "../../../../../components/LocationCodeSelect";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { useFormContext } from "saga-library/src/components/Form";

interface BatchControlledLocationCodeSelectProps {
  name: string
  label?: string
  batchUpdateMode?: boolean
  autoFocus?: boolean
  dataTestId?: string
}

export const BatchControlledLocationCodeSelect = ({
  label,
  name,
  autoFocus,
  dataTestId
}: BatchControlledLocationCodeSelectProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <LocationCodeSelect
      dataTestId={dataTestId}
      label={label}
      name={name}
      autoFocus={autoFocus}
      sx={batchUpdateStyleSelect(dirtyFields[name])}
      inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields[name])}
    />
  )
}
