import React, { useEffect, useRef, useState } from 'react'
import { DialogV2, Typography, useForm } from 'saga-library/src'
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client'
import { LIST_AB_LAB_RESULT_EXCEPTIONS } from '../../../graphql-definitions'
import _get from "lodash/get";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { Box, useTheme } from '@mui/material'
import { SectionColumnNoElevation, SectionContainer } from "saga-library/src/components/Section/Section";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AbLabResultException, AbLabResultLink } from '../../../types/patients'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import PermissionButton from '../../../components/PermissionButton'
import { schema } from '../util/validation'
import { patientDisplayName } from 'saga-library/src/util/formatting'
import { LabsAndInvestigationsDashboardExceptionModalForm } from './LabsAndInvestigationsDashboardExceptionModalForm'
import { FormProvider } from 'react-hook-form'

interface LabsAndInvestigationsDashboardExceptionModalProps {
  open: boolean
  setOpen: (v: boolean) => void
  onClose?: () => void
}

const EXCEPTION_TEXT_NO_PATIENT = "No linked patient"
const EXCEPTION_TEXT_NO_PRACTITIONER = "No linked practitioner"
const EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER = "No linked patient or practitioner"
const dataTestId = "LabsAndInvestigationsDashboardExceptionModal"

const LabsAndInvestigationsDashboardExceptionModalColumn = ({ sx = {}, children }) => {
  return (
    <SectionColumnNoElevation
      sx={{
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        ...sx
      }}
    >
      {children}
    </SectionColumnNoElevation>
  )
}

const LabAndInvestigationsDashboardExceptionRowTitle = (result: AbLabResultException, isSelected: boolean) => {
  return ( <Typography
    variant={'body1'}
    color={(theme) => isSelected ? theme.palette.primary.main : theme.palette.text.primary}
    dataTestId={`${dataTestId}-patientName`}
  >
    {patientDisplayName(result.abLabResult?.patientName?.firstName, result.abLabResult?.patientName?.lastName)}
  </Typography>)
}

const LabAndInvestigationsDashboardExceptionRowSubTitle = (label, isSelected: boolean) => {
  return (<Typography
    variant={'body2'}
    color={(theme) => isSelected ? theme.palette.primary.main : theme.palette.text.secondary}
    dataTestId={`${dataTestId}-subtitle`}
  >
    {label}
  </Typography>)
}

const getPatientInfo = (abLabResult) => {
  const info = [abLabResult?.dob, abLabResult?.gender?.display, abLabResult?.province?.display]
  return info.filter(i => i).join(" · ")
}

export const PatientDetails = ({
  result,
  highlightText
}: {result, highlightText: boolean}) => {
  const patientInfo = getPatientInfo(result?.abLabResult)
  const patientIdentifier = result?.abLabResult.patientIdentifiers?.[0]?.label || "No identifier";

  return (
    <>
      {LabAndInvestigationsDashboardExceptionRowTitle(result, highlightText)}
      {LabAndInvestigationsDashboardExceptionRowSubTitle(patientInfo, highlightText)}
      {LabAndInvestigationsDashboardExceptionRowSubTitle(patientIdentifier, highlightText)}
    </>
  );
}

export const LabsAndInvestigationsDashboardExceptionModal = ({
  open,
  setOpen,
  onClose,
}: LabsAndInvestigationsDashboardExceptionModalProps) => {
  const exceptionRefs = useRef<HTMLDivElement[]>([])
  const FORM_NAME = 'labsAndInvestigationsDashboardExceptionForm'
  const theme = useTheme();
  const { tenant_id } = useParams()
  const [results, setResults] = useState<AbLabResultException[]>([])
  const [selectedResult, setSelectedResult] = useState<AbLabResultException>()

  const errorTextColor = theme.palette.error.main

  useEffect(() => {
    requestAnimationFrame(() => {
      if (exceptionRefs?.current && selectedResult !== undefined) {
        const selectedResultIndex = results.findIndex(r => r.abLabResult.id === selectedResult.abLabResult.id)
        if (selectedResultIndex !== -1) {
          exceptionRefs.current[selectedResultIndex].scrollIntoView({ block: "nearest" })
        }
      }
    })
  }, [results, selectedResult])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const { loading: isLoadingExceptions } = useQuery(LIST_AB_LAB_RESULT_EXCEPTIONS, {
    variables: {
      tenantId: tenant_id,
    },
    skip: !open,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      populateList(_get(data, 'tenant.dashboard.listAbLabResultExceptions', []))
    },
  })

  const populateList = (listData: AbLabResultException[] = [], nextSelectedResultId: string = "") => {
    setResults(listData)
    if (listData.length > 0) {
      if (!!nextSelectedResultId) {
        const nextSelectedResult = listData.find(ld => ld.abLabResult.id === nextSelectedResultId)
        setSelectedResult(nextSelectedResult || listData[0])
      } else {
        setSelectedResult(listData[0]);
      }
    }
    else{
      setSelectedResult(undefined)
      handleClose()
    }
  }

  const formMethods = useForm<AbLabResultLink>({
    schema: schema,
    defaultValues: {
      patientId: '',
      practitionerIdentifiers: []
    }
  });

  const {
    formState: { isSubmitting},
  } = formMethods

  const rowClicked = async (abLabResult: AbLabResultException) => {
    setSelectedResult(abLabResult)
  }

  const getAbLabResultExceptionText = (result: AbLabResultException) => {
    const hasPractitionerException = result.practitionerException;
    const hasPatient = Boolean(result.abLabResult.patientId);

    let label;
    if (hasPractitionerException && hasPatient) {
      label = EXCEPTION_TEXT_NO_PRACTITIONER;
    } else if (hasPractitionerException && !hasPatient) {
      label = EXCEPTION_TEXT_NO_PATIENT_NO_PRACTITIONER;
    } else {
      label = EXCEPTION_TEXT_NO_PATIENT;
    }

    return label;
  }

  const labAndInvestigationsDashboardExceptionRow = (result: AbLabResultException, index: number) => {
    let isSelected = false
    if(selectedResult?.abLabResult?.id === result?.abLabResult?.id){
      isSelected = true
    }

    const currentExceptionText = () => {
      let label = getAbLabResultExceptionText(result)
      return (
        <Box
          gap={ '4px' }
          display = { 'flex' }
          alignItems={'center'}
        >
          <ErrorOutlineIcon
            sx={{ color: errorTextColor }}
            data-testid={`${dataTestId}-icon`}
          />
          <Typography
            color={errorTextColor}
            sx={{ flex: '1 1 auto', fontWeight: 700 }}
            variant={'body2'}
            dataTestId={`${dataTestId}-message`}
          >
            {label}
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        key = {`labRow-${result?.abLabResult?.id}-${index}`}
        ref={(el) => exceptionRefs.current[index] = el as HTMLDivElement}
        onClick={() => rowClicked(result)}
        display = { 'flex' }
        sx = {{
          gap: '8px',
          flex: '1 1 auto',
          maxHeight: '65px',
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: theme.palette.greys.light,
          p: 1,
          backgroundColor: isSelected ? 'backgrounds.selected' : 'backgrounds.none',
          '&:hover': {
            backgroundColor: isSelected ? 'backgrounds.selectedHover' : 'backgrounds.hover',
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx = {{
            width: '184px',
          }}
        >
          <PatientDetails result={result} highlightText={isSelected}/>
        </Box>
        {currentExceptionText()}
      </Box>
    )
  }

  return (
    <DialogV2
      open={open}
      title={"Lab and investigation result exceptions"}
      primaryAction={()=> null}
      overridePrimaryComponent={
        <PermissionButton
          name={"linkExceptions"}
          type={"submit"}
          form={FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
        >
          Link
        </PermissionButton>
      }
      showCancelButton={false}
      onClose={handleClose}
      size={'lg'}
      data-testid={`${dataTestId}-dialog`}
      preventScroll={true}
      PaperProps={{sx:{height: '100%', maxHeight: '78vh', width: '1160px'}}}
    >
      {isLoadingExceptions && <LoadingSpinner />}
      {results.length > 0 && (
        <SectionContainer sx={{gap: '32px'}}>
          <LabsAndInvestigationsDashboardExceptionModalColumn
            sx={{
              maxWidth: '480px',
            }}
          >
            {
              results.map((result, index) => (
                labAndInvestigationsDashboardExceptionRow(result, index)
              ))
            }
          </LabsAndInvestigationsDashboardExceptionModalColumn>
          <LabsAndInvestigationsDashboardExceptionModalColumn>
            <FormProvider {...formMethods}>
              <LabsAndInvestigationsDashboardExceptionModalForm
                formName={FORM_NAME}
                labResult={selectedResult}
                dataTestId={dataTestId}
                onLinked={(linkedAbLabResult) => populateList(linkedAbLabResult?.abLabResultDto, linkedAbLabResult?.nextAbLabResultId)}
              />
            </FormProvider>
          </LabsAndInvestigationsDashboardExceptionModalColumn>
        </SectionContainer>
      )}
    </DialogV2>
  )
}
