import { Box } from "@mui/material";
import PagedTableList from "saga-library/src/components/TableList/PagedTableList";
import { FilterSelect, Radio, SearchField, TableListHeaderConfig, SimpleRadioGroup } from "saga-library/src";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import React, { useEffect, useTransition } from "react";
import { AbClaim } from "../../../../types/billing";
import { ABClaimAssessmentOutcome } from "../../../../utils/ABClaimConstants";
import { AssessedClaimRow } from "./AssessedClaimRow";
import { useQuery, useReadQuery } from '@apollo/client'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useNavigate, useParams } from 'react-router-dom'
import _get from "lodash/get";
import { LIST_PRACTITIONERS } from "../../../../graphql-definitions";
import { useAssessedClaimsContext } from '../../providers/AssessedClaimsProvider'
import { useAccountContext } from '../../../../providers/AccountContext'

const columns: TableListHeaderConfig[] = [
  { name: 'Patient' },
  { name: 'Service date', sortable: true },
  { name: 'Service' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Claimed' },
  { name: 'Paid' },
  { name: 'Claim', sortable: true },
  { name: 'Outcome' },
  { name: 'Expl' },

]

export interface AssessedClaimsListProps {
  selectedClaims: AbClaim[]
  setSelectedClaims: (v) => void
}

const defaultOption = {
  label: "All practitioners",
  value: "*"
}
const AssessedClaimsList = ({
  selectedClaims,
  setSelectedClaims
}: AssessedClaimsListProps) => {
  const [outcome, setOutcome] = React.useState<string | null>(null)
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const [isPending, startTransition] = useTransition()
  const { assessedClaimsListQueryRef, changeFilters, selectedClaimIds, setSelectedClaimIds } = useAssessedClaimsContext()

  const data = useReadQuery(assessedClaimsListQueryRef!)
  let claimsList = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.edges', [] as any).map(e => e.node)
  let pageInfo = _get(data, 'data.tenant.assessedAbClaim.assessedClaims.pageInfo')

  const onRequestSort = (key, order) => {
    // TODO: Fix sorting
    // assessedClaimsParams.sorter = {
    //   sortOrder: order === "asc" ? SortOrder.ASCENDING : SortOrder.DESCENDING,
    //   sortColumn: key === "Claim" ? AssessedAbClaimSortColumn.CLAIM_IDENTIFIER : AssessedAbClaimSortColumn.SERVICE_DATE
    // }
  }

  const onRequestNext = (cursor) => {
    // TODO: Fix 'load more'
  }

  const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>, claims: AbClaim[]) => {
    if (event.target.checked) {
      setSelectedClaims(claims)
    } else {
      setSelectedClaims([])
    }
  }

  const onFiltersChanged = (filters) => {
    startTransition(() => {
      changeFilters(filters)
    })
  }

  const onCheckboxClick = (claim_id) => {
    let newClaimList: AbClaim[] = [...selectedClaims]
    if (!selectedClaims.some((c) => c.id === claim_id)) {
      const claim = claimsList.find(ac => ac.id === claim_id)
      if (claim) newClaimList.push(claim)
    } else {
      newClaimList = newClaimList.filter((c) => c.id !== claim_id)
    }
    setSelectedClaims(newClaimList)
  }

  const onRowClick = (claim: AbClaim) => {
    navigate(buildTenantRoute(`billing/assessed/c/${claim.id}`, tenant_id))
  }


  let rows = []
  if (!isPending) {
    rows = claimsList?.map(node => AssessedClaimRow(node, onCheckboxClick, !!selectedClaims.find(sc => sc.id === node.id), onRowClick)) || []
  }

  return (
    <Box height={'100%'} display={'flex'} flexDirection={'column'} paddingRight={1}>
      <AssessedClaimListHeader
        onFiltersChanged={onFiltersChanged}
        outcome={outcome}
        setOutcome={setOutcome}
      />
      <PagedTableList
        loading={isPending}
        showCheckbox={!!outcome && outcome !== ABClaimAssessmentOutcome.HELD}
        checkedRows={selectedClaimIds}
        setCheckedRows={setSelectedClaimIds}
        size={'small'}
        columns={columns}
        rows={rows}
        onRequestSort={onRequestSort}
        defaultSortColumn={columns.findIndex(x => x.name === 'Claim')}
        pagingMode={'continuous'}
        pageInfo={pageInfo}
        onRequestNext={onRequestNext}
        onSelectAllClick={(e) => onSelectAll(e, claimsList)}
        emptyListComponent={ { message: "Claims received from AHCIP appear here." } }
      />
    </Box>
  )
}

const AssessedClaimListHeader = ({onFiltersChanged, outcome, setOutcome}) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()

  const [practitioner, setPractitioner] = React.useState<string | null>(null)
  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const [options, setOptions] = React.useState<{label: string | null, value: string | null}[]>([])

  useEffect( () => {
    let outcomeFilter: string | null = outcome
    if (outcome === 'ADJUSTED') {
      outcomeFilter = ABClaimAssessmentOutcome.APPLIED
    }
    else if (outcome === 'RESUBMITTED' || outcome == null) {
      outcomeFilter = null
    }
    onFiltersChanged (
      {
        assessmentOutcomeFilter: outcomeFilter,
        practitionerId: practitioner,
        searchTerm: search,
        adjusted: outcome === 'ADJUSTED',
        resubmittedOnly: outcome === 'RESUBMITTED',
      }
    )
  }, [ outcome, practitioner, search ])

  const { data } = useQuery( LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data ) => {
      setOptions([defaultOption, ...(data ? getPractitionerOptions(data) : [])])
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Practitioner list couldn\'t be retrieved.')
      setOptions([defaultOption])
    }
  })

  const getPractitionerOptions = (practitionersData) => {
    return _get(practitionersData, 'tenant.practitioner.list', []).map(practitioner => {
      return {
        value: practitioner.id,
        label: `${practitioner.lastName}, ${practitioner.firstName}`
      }
    })
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box justifyContent={'center'}>
        <SimpleRadioGroup
          label={null}
          row={true}
          name={'assessmentOutcome'}
          value={ outcome || null }
          onChange={ (e) => {
            setOutcome(e.currentTarget.value)
          }}
        >
          <Radio label={'All'} value={null} />
          <Radio label={'Held'} value={ABClaimAssessmentOutcome.HELD} />
          <Radio label={'Adjusted'} value={'ADJUSTED'} />
          <Radio label={'Refused'} value={ABClaimAssessmentOutcome.REFUSED} />
          <Radio label={'Resubmitted'} value={'RESUBMITTED'} />
        </SimpleRadioGroup>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <Box justifyContent={'center'} >
          <FilterSelect
            options={options}
            onSelect={(event) => setPractitioner(event.value)}
            defaultSelectedValue={defaultOption.value}
          />
        </Box>
        <Box justifyContent={'center'}>
          <SearchField value={search} onChange={onChange} />
        </Box>
        <Box justifyContent={'center'} ml={1}>
          <PrintOutlinedIcon />
        </Box>
      </Box>
    </Box>
  )
}

export default AssessedClaimsList
