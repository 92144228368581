import React from 'react'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { IconButton } from "../Button";

export const PrintIconButton = ({ onClick, sx = {}, disabled=false, dataTestId }) => {
  return (
    <IconButton
      dataTestId={`${dataTestId}-print-button`}
      onClick={onClick}
      sx={{
        width: '40px',
        height: '40px',
        margin: 0,
        ...sx
      }}
      disabled={disabled}
      icon={<PrintOutlinedIcon />}
    />
  )
}