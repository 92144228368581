import { Practitioner, PractitionerSearchResult } from '../settings'
import { AppointmentState } from "../schedule";
import { AppointmentType } from "../schedule";
import { Location } from "../Location";
import { Audit } from "../Audit";
import moment from 'moment-timezone'

export type PatientSearchResult = {
  id: string
  inactiveDate?: moment.Moment | null
  firstName: string
  middleName: string
  lastName: string
  dob: string
  email: string
  primaryPhoneNumber: string
  primaryIdentifier: string
  primaryIdentifierLabel: string
  resultType: string
  patientNotes: PatientNote[]
  identifiers?: PatientIdentifier[]
  province?: string
}

export type PatientIdentifier = {
  value: string
  label: string
  typeId: string
}

export type PatientNote = {
  id: string
  noteText: string
}

export type PatientAppointment = {
  itemDate: string
  length: number
  location: Location
  notes: string
  practitioner: PractitionerSearchResult
  reasonForVisit: string
  state: AppointmentState
  type: AppointmentType
  userFirstName: string
  userLastName: string
  id: string
  isDeleted?: boolean
  audit?: Audit
  start: moment.Moment
  end: moment.Moment
}

export type PatientAppointmentWithPractitionerDetailsAndClinicLocation = {
  itemDate: string
  length: number
  location: Location
  notes: string
  practitioner: Practitioner
  reasonForVisit: string
  state: AppointmentState
  type: AppointmentType
  userFirstName: string
  userLastName: string
  id: string
  isDeleted?: boolean
  audit?: Audit
  start: moment.Moment
  end: moment.Moment
}

export enum PersonalRelationship {
  PARENT = 'PARENT',
  CHILD = 'CHILD',
  SPOUSE = 'SPOUSE',
  SIBLING = 'SIBLING',
  GUARDIAN = 'GUARDIAN',
  WARD_DEPENDENT = 'WARD_DEPENDENT',
  OTHER = 'OTHER'
}

export type FamilyRelationship =  {
  relatedPatientId: string
  relationshipType: PersonalRelationship
  isEmergencyContact?: boolean
  firstName: string
  lastName: string
  email?: string
  primaryPhoneNumber?: string
}