import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "saga-library/src";
import { AssessmentStatusBadge } from "./AssessedClaimRow";
import { ClaimBase } from "../ClaimBase";
import { useTheme } from "@mui/material";
import { ExplanatoryCodeInfoTooltip } from "../ClaimInfoTooltips";
import { useFormContext } from "saga-library/src/components/Form";

export const AssessedClaimBase = () => {
  const { getValues } = useFormContext()
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <ClaimBase isAssessed={true} />
      <Box display={'flex'} flexDirection={'row'}>
        <Box sx={{mr: 5}}>
          <Typography variant={'body2'} color={theme.palette.greys.medium}>
            Outcome
          </Typography>
          <AssessmentStatusBadge
            claim={getValues()}
            sx={{m: 0}}
          />
        </Box>
        <Box sx={{mr: 5}}>
          <Typography variant={'body2'} color={theme.palette.greys.medium}>
            Explanatory codes
          </Typography>
          <Box display={'flex'} flexDirection={'row'}>
            {getValues('explanatoryCodes')?.map(code => {
              return (
                <Box display={'flex'} flexDirection={'row'} sx={{pt: '6px', pr: '28px', color: theme.palette.greys.light}}>
                  <Typography variant={'body1'} fontWeight={'bold'} color={'black'} sx={{mr: '6px'}}>
                    {code.explanatoryCode}
                  </Typography>
                  <ExplanatoryCodeInfoTooltip explanatoryCode={code} />
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box>
          <Typography variant={'body2'} color={theme.palette.greys.medium}>
            Paid
          </Typography>
          <Typography variant={'body1'} fontWeight={'bold'} color={'black'} sx={{mr: '6px'}}>
            {getValues('assessmentAmount')?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}