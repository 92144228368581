import * as yup from 'yup'
import { PhoneValidation } from 'saga-library/src/components/PhoneField'
import _get from 'lodash/get'

export const rules = {
  firstName: { max: 35 },
  lastName: { max: 35 },
  contactPhone: { max: 15 },
}

const maxLengthMessage = (value) => `Max length of ${value}`

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    firstName: yup.string(),
    lastName: yup.string(),
    contactPhone: yup
      .string()
      .max(rules.contactPhone.max, maxLengthMessage(rules.contactPhone.max))
      .matches(PhoneValidation.regex, 'Invalid phone number').nullable(),
  })
  .required()
