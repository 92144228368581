import { PatientSectionHeader } from "../PatientSectionHeader";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { TableList, Section, TableListHeaderConfig } from "saga-library/src";
import React, { useMemo } from 'react'
import PermissionButton from "../../../../components/PermissionButton";
import { FormsRow } from "./components/FormsRow";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { usePatientFormContext } from "../../providers/PatientFormProvider";
import { useReadQuery } from "@apollo/client";
import { FORMS_SCROLL_KEY } from '../../../../providers/UserInteractionContext'

const columns: TableListHeaderConfig[] = [
  { name: 'Date' },
  { name: 'Name' },
  { name: 'Practitioner' },
  { name: 'Description' },
]

const sectionTitle = "Forms"

export const FormsList = ChartPanelHOC(sectionTitle, () => {
  const { patientFormQueryRef } = usePatientFormContext()
  return patientFormQueryRef && <FormsPanel/>
})


const FormsPanel = () => {
  const { tenant_id, patient_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { patientFormQueryRef, parseFormQueryResults } = usePatientFormContext()
  const { data } = useReadQuery(patientFormQueryRef!)
  const patientForms = useMemo(()=> parseFormQueryResults(data), [data, parseFormQueryResults])

  const onNewClicked = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/forms/new`, tenant_id))
  }
  const onRowClicked = (form) => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/forms/${form.isLinkedDocument ? 'd':'f'}/${form.id}`, tenant_id))
  }

  return (
    <Section.Column
      header={
        <PatientSectionHeader
          dataTestId={'forms-list'}
          sectionLabel={'Forms'}
          showSave={false}
          actions={
            <PermissionButton
              name={"new"}
              requiredType={PermissionType.Chart}
              requiredPermission={Permission.READWRITE}
              onClick={onNewClicked}
              dataTestId={"formList-new-button"}
            >
              New
            </PermissionButton>
          }
        />
      } >
      <TableList
        scrollKey={FORMS_SCROLL_KEY}
        columns={columns}
        rows={patientForms.map(form => FormsRow(form, onRowClicked))}
        emptyListComponent={ { message: "Forms appear here once added." } }
        dataTestId={"formList"}
      />
    </Section.Column>
  )
}