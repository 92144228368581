import { Box } from "@mui/material";
import React from "react";
import { GhostInspectorNewEvent } from "./GhostInspectorNewEvent";

export const NewAppointmentEvent = ({
  eventData,
  height='100%'
}) => {
  return <Box
    className={"md-custom-event-cont"}
    sx={theme => ({
      height: height,
      overflow: "hidden",
      background: 'rgba(220, 223, 229, 0.58)',
      color: theme.palette.greys.dark,
      borderRadius: 1
    })}
  >
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        height: '100%',
        justifyContent: 'center',
        alignItems: 'start',
      }}
    >
    </Box>
    <GhostInspectorNewEvent />
  </Box>
}
