import _get from 'lodash/get'
import moment from 'moment-timezone'
import _groupBy from 'lodash/groupBy'

export const processApptResults = (data, searchData, apptResults) => {
  const edges = _get(data, "tenant.appointmentSearch.searchResults.edges", [])
  const slotNodes = edges.map(edge => {
    return {
      bookingPreferenceTypeIds: searchData.appointmentTypeId ? [searchData.appointmentTypeId] : [],
      allDay:false,
      dragInTime:false,
      ...edge.node,
    }
  })

  slotNodes.forEach(slot => {
    slot.start = moment(slot.start)
    slot.end = moment(slot.end)
  })

  const groupedAppts = _groupBy(slotNodes, appt=> moment(appt.start).format("YYYY-MM-DD"))
  const pageInfo = _get(data, "tenant.appointmentSearch.searchResults.pageInfo", {})

  const currentSlots = apptResults?.apptSlots ?? { }

  for (const [key, value] of Object.entries(groupedAppts)) {
    if (key in currentSlots){
      currentSlots[key] = [...currentSlots[key], ...value]
    } else {
      currentSlots[key] = value
    }
  }

  return {apptSlots: currentSlots, pageInfo: pageInfo}
}