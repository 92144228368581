import React, { useEffect } from "react";
import { SaveButton } from "saga-library/src";
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import TenantRoleForm, {
  getPermissionForField,
  getPermissionForReportingField
} from '../components/TenantRole/TenantRoleForm'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { CREATE_TENANT_ROLE, GET_TENANT_ROLES } from '../../../graphql-definitions'
import _get from 'lodash/get'
import { PermissionMap } from "../../../types/settings/Permission";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { useAccountContext } from "../../../providers/AccountContext";
import { schema } from "../Locations/util/validation";
import {
  getTenantRoleFormDefaultValues,
  TenantRoleFormType
} from "../components/TenantRole/TenantRoleFormDefaultValues";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

const FORM_NAME = 'new-role-form'

export const TenantRoleNew = () => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()

  const formMethods = useForm<TenantRoleFormType>({
    defaultValues: getTenantRoleFormDefaultValues(),
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  const [addNewRole] = useMutation(
    CREATE_TENANT_ROLE,
    {
      onCompleted: (data) => {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        const newRole = _get(data, 'tenant.role.createRole')
        showSuccessAlert(`${newRole.name} role has been created.`)
        navigate(buildTenantRoute(
          `settings/roles/r/${newRole.id}`,
          tenant_id
        ), { replace: true })
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Role couldn\'t be created.')
      },
    }
  )

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit(async (data) => {
    const userIds = (data.roleUsers || []).map((ru) => ru.id)
    const permBilling = getPermissionForField(!!data.billing?.readonly, !!data.billing?.readwrite)
    const permSchedule = getPermissionForField(false, !!data.schedule?.readwrite)
    const permPractitioner = getPermissionForField(false, !!data.practitioner?.readwrite)
    const permChart = getPermissionForField(false, !!data.chart?.readwrite)
    const permLabsReview = getPermissionForField(false, !!data.labs?.review)
    const permReportingBilling = getPermissionForReportingField(!!data.reporting?.billing, !!data.reporting?.readwrite)
    const permReportingPatientAndAppointment = getPermissionForReportingField(!!data.reporting?.patientAndAppointment, !!data.reporting?.readwrite)

    delete data.roleUsers
    delete data.billing
    delete data.schedule
    delete data.practitioner
    delete data.chart
    delete data.reporting
    delete data.labs

    await addNewRole({
      variables: {
        tenantId: tenant_id,
        newRole: {
          ...data,
          roleUserIds: userIds,
          permBilling: PermissionMap.get(permBilling),
          permSchedule: PermissionMap.get(permSchedule),
          permPractitioner: PermissionMap.get(permPractitioner),
          permChart: PermissionMap.get(permChart),
          permReportingBilling: PermissionMap.get(permReportingBilling),
          permReportingPatientAndAppointment: PermissionMap.get(permReportingPatientAndAppointment),
          permLabsReview: PermissionMap.get(permLabsReview),
          isOwner: false
        },
      },
      update(cache, returnedData) {
        const tenantRolesData = cache.readQuery({
          query: GET_TENANT_ROLES,
          variables: {
            tenantId: tenant_id,
          },
        })

        const newRole = _get(returnedData, 'data.tenant.role.createRole')
        const currentTenantRoles = _get(
          tenantRolesData,
          'tenant.role.tenantRoles',
          []
        )

        cache.writeQuery({
          query: GET_TENANT_ROLES,
          data: {
            tenant: {
              role: {
                tenantRoles: [...currentTenantRoles, newRole],
              },
            },
          },
          variables: {
            tenantId: tenant_id,
          },
        })
      },
    })
  })

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Create new role'}
        onBack={() => navigate(`../`)}
        actions={
          <SaveButton
            form={FORM_NAME}
            submitting={isSubmitting}
            dataTestId={'rolesAndPermissions-newRole'}
          />
        }
        dataTestId={'rolesAndPermissions-newRole'}
      />
    }>
      <FormProvider {...formMethods}>
        <TenantRoleForm onSubmit={onSubmit} name={FORM_NAME} />
      </FormProvider>
    </SettingsSectionColumn>
  )
}
