import { PatientSectionHeader } from "../PatientSectionHeader";
import { Box, useTheme } from "@mui/material";
import { Radio, Section, SimpleRadioGroup, TableList, useForm } from 'saga-library/src'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { LabAndInvestigationRow } from "./components/LabAndInvestigationRow";
import { DocumentCategory } from "../../../../types/Document";
import { useNavigate } from "react-router-dom";
import { AbLabResult, AbLabResultObservationResult, AbLabResultUpdate } from '../../../../types/patients'
import { LabValueGraph } from "./LabValueGraph";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { useLabAndInvestigationContext } from "../../providers/LabAndInvestigationProvider";
import { useLazyQuery, useReadQuery } from '@apollo/client'
import { LABS_AND_INVESTIGATIONS_SCROLL_KEY, LABS_AND_INVESTIGATIONS_FILTER_KEY, useUserInteraction } from '../../../../providers/UserInteractionContext'
import { schema } from './LabAndInvestigationFormValidationSchema'
import { FileType } from '../../../../types/tasks'
import { GET_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import _get from 'lodash/get'

type filterOptions = 'ALL' | Extract<DocumentCategory, DocumentCategory.INVESTIGATION | DocumentCategory.LAB_RESULT>

const sectionTitle = "Labs and investigations"

export const LabAndInvestigations = ChartPanelHOC(sectionTitle, () => {
  const { labAndInvestigationQueryRef } = useLabAndInvestigationContext()
  return labAndInvestigationQueryRef && <LabAndInvestigationsPanel/>
})

const LabAndInvestigationsPanel = () => {
  const { getFilter, saveFilter } = useUserInteraction()

  const { labAndInvestigationQueryRef, parseLabAndInvestigationQueryResults } = useLabAndInvestigationContext()
  const { data } = useReadQuery(labAndInvestigationQueryRef!)

  const printComponentRef = useRef<any>(null)
  const [labAndInvestigation, setLabAndInvestigation] = useState<AbLabResult | null>(null)
  const onBeforeGetContentResolve = useRef<any>()

  const [file, setFile] = useState<FileType | null>(null)
  const [files, setFiles] = useState<FileType[]>([])
  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema
  })

  const {
    reset,
  } = formMethods

  const resetFormState = (lab: AbLabResult) => {
    reset({
      id: lab.id,
      notes: lab.notes,
      version: lab.version
    })
  }

  const [getLabAndInvestigation, {loading}] = useLazyQuery(GET_LAB_AND_INVESTIGATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      const lab: AbLabResult = _get(data, 'tenant.patient.labAndInvestigation.get', null)

      if (lab) {
        const resultsWithEmbeddedFile = lab?.abLabResultObservationResults
          ?.filter(r => r.file)
          ?.map(r => r.file!)

        setFiles(resultsWithEmbeddedFile)
        resultsWithEmbeddedFile.length > 0 ? setFile(resultsWithEmbeddedFile[0]) : setFile(null)

        resetFormState(lab)
      }

      setLabAndInvestigation(lab)
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    if (!!labAndInvestigation) {
      onBeforeGetContentResolve.current()
    }
  }, [labAndInvestigation])

  //useMemo/useCallback this
  const labAndInvestigations = useMemo(()=> parseLabAndInvestigationQueryResults(data), [data, parseLabAndInvestigationQueryResults])

  const navigate = useNavigate()
  const theme = useTheme()

  const [selectedFilter, setSelectedFilter ] = useState<filterOptions>(getFilter(LABS_AND_INVESTIGATIONS_FILTER_KEY) as filterOptions || 'ALL')
  const [ observationResult, setObservationResult ] = React.useState<AbLabResultObservationResult | null>(null)

  const filteredLabAndInvestigations = labAndInvestigations
    .filter(lab => selectedFilter === 'ALL' || lab.isLab === (selectedFilter === DocumentCategory.LAB_RESULT))

  const onLabAndInvestigationRowClicked = () => {
    saveFilter(LABS_AND_INVESTIGATIONS_FILTER_KEY, selectedFilter)
  }

  const getRows = () => {
    return filteredLabAndInvestigations
      .map((lab, index) =>
        LabAndInvestigationRow(lab, index, navigate, onLabAndInvestigationRowClicked, (result) => setObservationResult(result),
          formMethods, file, files, setFile, resetFormState, printComponentRef,
          labAndInvestigation, setLabAndInvestigation, onBeforeGetContentResolve, getLabAndInvestigation, loading)
      )
  }

  return <Section.Column
    header={
      <PatientSectionHeader
        dataTestId={"labs-and-investigations-list"}
        sectionLabel={sectionTitle}
        showSave={false}
      />
    }
  >
    <Box>
      <SimpleRadioGroup
        label={null}
        row={true}
        name={'labAndInvestigationsFilter'}
        value={selectedFilter || null}
        onChange={ (e) => {
          setSelectedFilter(e.target.value)
        }}
        dataTestId={"labAndInvestigations-filterRadioGroup"}
      >
        <Radio
          label={'All'}
          value={'ALL'}
          sx={{padding: '8px'}}
          dataTestId={"labAndInvestigations-radioAll"}
        />
        <Radio
          label={'Labs'}
          value={DocumentCategory.LAB_RESULT}
          dataTestId={"labAndInvestigations-radioLabs"}
        />
        <Radio
          label={'Investigations'}
          value={DocumentCategory.INVESTIGATION}
          dataTestId={"labAndInvestigations-radioInvestigations"}
        />
      </SimpleRadioGroup>
    </Box>
    <TableList
      columns={[{name: ''}]}
      rows={getRows()}
      scrollKey={LABS_AND_INVESTIGATIONS_SCROLL_KEY}
      emptyListComponent={ { message: "Labs and investigations appear here once added." } }
      showHeader={false}
      tableSx={{borderSpacing: theme.spacing(0,1)}}
      dataTestId={"labAndInvestigationsTable"}
    />
    <LabValueGraph
      result={observationResult}
      setResult={setObservationResult}
      dataTestId={"labAndInvestigations-labValueGraph"}
    />
  </Section.Column>
}
