import * as yup from 'yup'
import { nullOrNumber } from "../../../../../utils/NumberHelpers";
import { ReviewRequired } from '../../../../../types/Document'

const getPageRanges = (pageRange?: string) : number[][] => {
  if (!pageRange) return []
  const ranges = pageRange.split(",").map((range) => {
    const intervals = range.split("-")
    if (intervals.length > 2) {
      return null // multiple -
    }
    const intervalNums = intervals.map(interval => nullOrNumber(interval.trim()))
    if (intervalNums.some(interval => interval === null)) {
      return null
    }

    if (intervalNums.length === 2) {
      if (intervalNums[0]! > intervalNums[1]!) {
        return null // 2nd value is less than 1st value
      }
    }
    return intervalNums as number[]
  })

  return ranges.some(range => range === null) ? [] : ranges as number[][]
}

export const schema = yup.object().shape({
  fileId:yup
    .string()
    .required("Required"),
  patientId: yup
    .string()
    .transform((val) => val?.id)
    .required("Required"),
  category: yup
    .string()
    .required("Required"),
  documentDate: yup
    .string()
    .required("Required"),
  pagesToInclude: yup
    .string()
    .required("Required"),
  pageRange: yup
    .array()
    .transform((val) => val ? getPageRanges(val) : null)
    .when(["pagesToInclude", "$pageCount"], ([pagesToInclude, pageCount], schema) => {
      if (pagesToInclude === "Range") {
        return schema
          .of(yup
            .array(yup
              .number()
              .integer("Invalid page range")
              .required("Invalid page range")
              .typeError("Invalid page range")
              .min(1, "Invalid page range")
              .max(pageCount, "Invalid page range")
            ).required("Invalid page range")
            .typeError("Invalid page range")
            .min(1, "Invalid page range")
          ).required("Required")
          .typeError("Invalid page range")
          .min(1, "Invalid page range")
      } else {
        return schema
          .nullable()
          .notRequired()
      }
    }),
  reviewRequired: yup
    .mixed<ReviewRequired>()
    .oneOf(Object.values(ReviewRequired)),
  assignedUserId: yup
    .string()
    .when("reviewRequired", {
      is: (reviewRequired) => reviewRequired === ReviewRequired.YES,
      then: (schema) => schema
        .required("Required")
    })
})