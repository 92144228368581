import { gql } from "@apollo/client";


export const VALIDATE_INVITATION = gql`
  query ValidateInvitation($hash: String!, $passcode: String!, $salt: String!) {
    public {
      invitation {
        validateInvitation(hash: $hash, passcode: $passcode, salt: $salt) {
          valid
          tenantName
          userId
          emailAddress
        }
      }
    }
  }
`



export const ACCEPT_INVITATION_EXISTING_USER = gql`
  mutation AcceptInvitationExistingUser($hash: String!, $passcode: String!, $salt: String!, $userId: ID!) {
    public {
      invitation {
        invitationAcceptedExistingUser(hash: $hash, passcode: $passcode, salt: $salt, userId: $userId) {
          success
        }
      }
    }
  }
`



export const ACCEPT_INVITATION_NEW_USER = gql`
  mutation AcceptInvitationNewUser($hash: String!, $passcode: String!, $salt: String!, $password: String!, $firstName: String!, $lastName: String!) {
    public {
      invitation {
        invitationAcceptedNewUser(hash: $hash, passcode: $passcode, salt: $salt, password: $password, firstName: $firstName, lastName: $lastName) {
          success
        }
      }
    }
  }
`


export const GET_APP_SETTINGS_QUERY = gql`
  query AppSettings {
    public {
      appSettings {
        appSettings {
          version
          maxRecentResults
        }
      }
    }
  }
`
