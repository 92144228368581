import React from 'react'
import { Select } from 'saga-library/src'
import { MenuItem } from '@mui/material'
import { PrintType } from '../../types/Document'

interface PrintTypeSelectProps {
  name?: string,
  label?: string,
  disabled?: boolean,
  dataTestId?: string,
  options?: { label: PrintType; value: PrintType }[]
}

export const PrintTypeSelect = ({
  name = 'locationCode',
  label = 'Location',
  disabled = false,
  dataTestId,
  options
}: PrintTypeSelectProps) => {
  return (
    <Select
      name={name}
      label={label}
      defaultValue={PrintType.DOCUMENT}
      dataTestId={dataTestId}
      disabled={disabled}
    >
      {options?.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`${dataTestId}-menuItem-${option.value}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}