import React from 'react'
import ResetPasswordForm from '../apps/account/Components/ResetPasswordForm'
import { Box } from '@mui/material'
import { useSearchParams } from 'react-router-dom'

export const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  let e = searchParams.get('e') || ""
  let t = searchParams.get('t') || ""
  let u = searchParams.get('u') || ""
  let i = searchParams.get('i') || ""

  try {
    e = decodeURI(e)
    t = decodeURI(t)
    u = decodeURI(u)
    i = decodeURI(i)
  }
  catch (ex) {
    console.warn(ex)
    e = ""
    t = ""
    u = ""
    i = ""
  }
 
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Box padding={2}>
        <ResetPasswordForm email={e} token={t} userId={u} tokenId={i}></ResetPasswordForm>
      </Box>
    </div>
  )
}
