import React, { useEffect, useState } from 'react'
import {
  TableList,
  TableListRowConfig
} from "saga-library/src";
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type {
  TableListHeaderConfig,
} from 'saga-library/src'
import _get from 'lodash/get'
import { GET_TENANT_ROLES } from '../../graphql-definitions'
import SettingsHeader from "./components/SettingsHeader";
import { Permission, PermissionType } from "../../types/settings/Permission";
import PermissionButton from "../../components/PermissionButton";
import { Role } from "../../types/account";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";
import { ROLES_AND_PERMISSIONS_SCROLL_KEY } from '../../providers/UserInteractionContext'

const columnsHeaders: TableListHeaderConfig[] = [
  {
    name: 'Role',
    sortable: false,
  },
  {
    name: 'Description',
    sortable: false,
  },
]

const RoleRow = (role, navigate): TableListRowConfig => {
  return {
    onClick: () => navigate(`r/${role.id}`),
    rowData: [
      { data: role.name },
      { data: role.description },
    ],
    key: role.id!
  }
}

export const RolesAndPermissions = () => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const [roles, setRoles] = useState([])
  const [retrievalError, setRetrievalError] = useState<undefined | string>()

  const { loading, error, data } = useQuery(GET_TENANT_ROLES, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (error) {
      console.error('Unable to retrieve roles: ' + error)
      setRetrievalError('Unable to retrieve roles')
    } else if (data) {
      const tenantRoles = _get(data, 'tenant.role.tenantRoles', null).filter((role: Role) => !role.isOwner)
      setRoles(tenantRoles)
    }
  }, [error, data])

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Roles and permissions'}
        actions={
          <PermissionButton
            dataTestId={'rolesAndPermissions-new-role-button'}
            name={'new-role'}
            onClick={() => {
              navigate('new')
            }}
            requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}
          >
            NEW ROLE
          </PermissionButton>
        }
        dataTestId={'rolesAndPermissions'}
      />
    }>

      <TableList
      scrollKey={ROLES_AND_PERMISSIONS_SCROLL_KEY}
        loading={loading}
        columns={columnsHeaders}
        rows={[...roles.map((role) => RoleRow(role, navigate))]}
        emptyListComponent={ { message: retrievalError || 'There are currently no roles'} }
        dataTestId={'rolesAndPermissions-roleList'}
        leftAlignColumns={true}
      />

    </SettingsSectionColumn>
  )
}
