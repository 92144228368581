import React from "react";
import { Box, Divider } from "@mui/material";
import { Button, Section } from "saga-library/src";
import { LetterFormReferralPractitionerSelection } from "./LetterFormReferralPractitionerSelection";
import { LetterFormFamilyPractitionerSelection } from "./LetterFormFamilyPractitionerSelection";
import { ReferralPractitioner } from "../../../../../types/settings";
import { LocationDetailsType } from "../../../../settings/Locations/LocationNew";
import { LetterFormLocationSelection } from "./LetterFormLocationSelection";
import { LetterFormAppointmentSelection } from './LetterFormAppointmentSelection'
import { PatientAppointmentWithPractitionerDetailsAndClinicLocation } from '../../../../../types/patients/Patient'
import { LetterFormTextSelection } from './LetterFormTextSelection'

export type SELECTION_PANEL_OPTION = "referring practitioner" | "family practitioner" | "appointment" | "location" | "text"
export type SelectionOptionType = ReferralPractitioner | PatientAppointmentWithPractitionerDetailsAndClinicLocation | LocationDetailsType | string

export interface TemplateChipOption {
  option: SELECTION_PANEL_OPTION
  matchIndex?: number
  pattern: string
  onReplace: (replaceMatch: string, selectedOption: any) => string
}

type LetterFormChipSelectionPanelProps = {
  chipOption?: TemplateChipOption | null
  referralPractitioners: ReferralPractitioner[]
  onSelect: (option?: SelectionOptionType) => void
  dataTestId?: string
}

export const LetterFormChipSelectionPanel = ({
  chipOption,
  referralPractitioners,
  onSelect,
  dataTestId
}: LetterFormChipSelectionPanelProps) => {
  const onSkip = () => {
    onSelect()
  }

  const options: { [o in SELECTION_PANEL_OPTION]: React.ReactNode } = {
    "referring practitioner": (
      <LetterFormReferralPractitionerSelection
        name={'templateReferralPractitioner'}
        referralPractitioners={referralPractitioners}
        onSelect={onSelect}
        dataTestId={`${dataTestId}-template-referralPractitioner`}
      />
    ),
    "family practitioner": (
      <LetterFormFamilyPractitionerSelection
        name={'templateFamilyPractitioner'}
        onSelect={onSelect}
        dataTestId={`${dataTestId}-template-familyPractitioner`}
      />
    ),
    "appointment": (
      <LetterFormAppointmentSelection
        onSelect={onSelect}
        dataTestId={`${dataTestId}-template-appointment`}
      />
    ),
    "location": (
      <LetterFormLocationSelection
        onSelect={onSelect}
        dataTestId={`${dataTestId}-template-location`}
      />
    ),
    "text": (
      <LetterFormTextSelection
        onSelect={onSelect}
        dataTestId={`${dataTestId}-template-text`}
      />
    )
  }

  if (!chipOption) {
    return <></>
  }

  return (
    <Section.Container
      sx={{
        border: "1px solid rgba(32, 41, 49, 0.32)",
        borderRadius: "8px",
        flexShrink: 0,
        height: "auto",
        width: "288px"
      }}
    >
      <Section.ColumnNoElevation>
        <Section.SubHeader variant={"h3"}>
          {getPanelTitle(chipOption.option)}
        </Section.SubHeader>
        <Box key={`selection-${chipOption.matchIndex}`} flex={"1 1 auto"} overflow={"hidden"}>
          {options[chipOption.option]}
        </Box>
        <Box mt={1}>
          <Divider sx={{ mb: 1 }} />
          <Button
            name={"skip"}
            variant={"outlined"}
            fullWidth={true}
            onClick={onSkip}
            dataTestId={`${dataTestId}-skip-button`}
          >
            Skip
          </Button>
        </Box>
      </Section.ColumnNoElevation>
    </Section.Container>
  )
}

const getPanelTitle = (option: SELECTION_PANEL_OPTION) => {
  if (option === "text") {
    return "Enter text"
  }

  if (option === "appointment") {
    return "Select an appointment"
  }

  return `Select a ${option}`
}