import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import React from "react";
import { Button } from "../Button";

export const NavigationButton = ({children, navigate, to}) => {

  return <Button
    name={'navigation-button'}
    variant={"text"}
    fullWidth={true}
    endIcon={<ArrowForwardIcon height={"24px"}/>}
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      height: '56px'
    }}
    onClick={()=>{navigate(to)}}
    data-testid={`navigationButton-${to}`}
  >
    <Box width={'100%'} textAlign={'left'}>
      { children }
    </Box>
  </Button>
}

export default NavigationButton