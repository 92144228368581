import React from 'react'
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export interface NavigationTabsProps {
  routes: React.ReactNode[]
  actions?: React.ReactNode[]
  underline?: boolean
  sx?: SxProps<Theme>
}

export const NavigationTabs = ({
  routes,
  actions,
  underline,
  sx,
}: NavigationTabsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: theme => underline
          ? '1px solid ' + theme.palette.greys.light
          : 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 0,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '40px',
        }}
      >
        {routes.map((route, index) => (
          <Box
            sx={{ pr: 3 }}
            key={'navtab ' + index}
            role={'navTab'}
          >
            {route}
          </Box>
        ))}
      </Box>
      {actions && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '40px',
          }}
        >
          {actions}
        </Box>
      )}
    </Box>
  )
}

export default NavigationTabs
