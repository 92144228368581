import React, { useState } from 'react'
import { Box, Collapse} from '@mui/material'
import { LoadingButton, Section, Typography, Button, PasswordField, Form } from 'saga-library/src'
import { Logo } from '../../../components/Logo'
import { useForm, useWatch, FormProvider } from "saga-library/src/components/Form"
import * as yup from 'yup'
import { useSagaAuthentication } from '../../../providers/Auth'
import { useNavigate } from 'react-router-dom'
import PasswordChecklist from "react-password-checklist"

type ResetPasswordFormProps = {
  email?: string | null
  token?: string | null
  userId?: string | null
  tokenId?: string | null
}

function ResetPasswordForm(this: any, { email, token, userId, tokenId }: ResetPasswordFormProps) {
  const [passwordReset, setPasswordReset] = useState<boolean>(false)
  const invalid = (!email || !token || !userId || !tokenId)
  const [checklistValid, setChecklistValid] = useState<boolean>(false)
  const { performResetPassword, friendlyErrorMessage } = useSagaAuthentication()
  const navigate = useNavigate();

  const schema = yup.object().shape({
    password: yup.string().required("New password is required"),
    confirmPassword: yup
      .string()
      .required("Please confirm the password")
      .equals([yup.ref('password'), null], 'Passwords must match'),
    email: yup.string().required(),
    token: yup.string().required()
  }).required();

  const formMethods = useForm<FormValues>({
    schema: schema
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    register
  } = formMethods

  const newPassword = useWatch({
    control,
    name: "password"
  })

  const newPasswordAgain = useWatch({
    control,
    name: "confirmPassword"
  })

  const onSubmit = (data) => {
    if (!checklistValid) return

    return performResetPassword(data)
      .then((success) => {
        if (success) setPasswordReset(true)
      })
  }

  register("email", { value: email })
  register("token", { value: token })
  register("userId", { value: userId })
  register("tokenId", { value: tokenId })

  if (invalid) {
    return (
      <>
        <Box marginTop={10} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Section.Column>
            <Box display={'flex'} flexDirection={'column'} marginTop={2} justifyContent={'center'} alignItems={'center'}>
              <Logo sx={{ width: 180, height: 70 }} />
              <Section.Header sx={{ mt: 2 }}>
                Reset Password
              </Section.Header>

              <Typography sx={{ mt: 2, mb: 1, color: "#C41923" }} variant={'h3'}>
                The reset password link is not valid.
              </Typography>

            </Box>
          </Section.Column>
        </Box>
      </>
    )
  }

  return (
    <FormProvider {...formMethods}>
      <Box marginTop={10} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Form id={'reset-password-form'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
          <Section.Column>
            <Box paddingLeft={2} paddingRight={3} display={'flex'} flexDirection={'column'} marginTop={2} justifyContent={'center'} alignItems={'center'}>
              <Logo sx={{ width: 180, height: 70 }} />
              <Section.Header sx={{ mt: 2 }}>
                Reset Password
              </Section.Header>

              {passwordReset ?
                <>
                  <Typography variant={'body1'}>
                    Your password has been reset.
                  </Typography>
                  <Box marginTop={2} marginBottom={2}>
                    <Button
                      name={'Ok'}
                      type={'button'}
                      variant={'contained'}
                      onClick={() => { navigate('/') }}
                    >
                      Ok
                    </Button>
                  </Box>
                </>
                :
                <>
                  <Typography sx={{ mt: 2, mb: 1 }} variant={'body1'}>
                    Enter, and confirm, your new password.
                  </Typography>
                  <PasswordField
                    label={'New Password'}
                    sx={{ width: 280 }}
                    autoComplete={'new-password'}
                    name={'password'}
                    dataTestId={'new-password'}
                  />
                  <PasswordField
                    label={'Confirm New Password'}
                    sx={{ width: 280 }}
                    autoComplete={'new-password'}
                    name={'confirmPassword'}
                    dataTestId={'confirm-password'}
                  />
                  <Typography sx={{ mt: 1, mb: 1 }} variant={'body1'}>
                    <PasswordChecklist
                      rules={["minLength", "specialChar", "number", "capital", "match"]}
                      minLength={8}
                      value={newPassword || ""}
                      valueAgain={newPasswordAgain || ""}
                      onChange={(isValid) => {
                        setChecklistValid(isValid)
                      }}
                      validColor={"#0F6558"}
                      invalidColor={"#C41923"}
                      iconSize={16}
                    />
                  </Typography>
                  <input {...register("email")} type="hidden" />
                  <input {...register("token")} type="hidden" />
                  <Collapse in={!!friendlyErrorMessage} sx={{ color: "#C41923" }}>
                    {friendlyErrorMessage}
                  </Collapse>
                  <Box marginTop={2} marginBottom={2}>
                    <LoadingButton
                      name={'ResetPasswordButton'}
                      type={'submit'}
                      loading={isSubmitting}
                      variant={'contained'}
                      dataTestId={'reset-password-button'}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </>
              }
            </Box>
          </Section.Column>
        </Form>
      </Box>
    </FormProvider>
  )
}

type FormValues = {
  password?: string
  confirmPassword?: string
  email?: string | null
  token?: string | null
  userId?: string | null
  tokenId?: string | null
}

export default ResetPasswordForm