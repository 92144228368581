import React from "react";
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import RepeatIcon from '@mui/icons-material/Repeat';
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface ClaimStatusIconProps {
  theme: Theme,
  sx?: SxProps<Theme>
}

const defaultIconStyle = {
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const ReadyIcon = ({theme, sx = defaultIconStyle}: ClaimStatusIconProps) => {
  return <CheckCircleOutline sx={{ color: theme.palette.primary.main, ...sx }}  />
}

export const HoldIcon = ({theme, sx = defaultIconStyle}: ClaimStatusIconProps) => {
  return <PauseCircleOutline sx={{ color: theme.palette.greys.medium, ...sx }}  />
}

export const ErrorIcon = ({theme, sx = defaultIconStyle}: ClaimStatusIconProps) => {
  return <WarningAmberIcon sx={{ color: theme.palette.warning.main, ...sx }}  />
}

export const ResubmittedIcon = ({theme, sx = defaultIconStyle}: ClaimStatusIconProps) => {
  return <RepeatIcon sx={{ color: theme.palette.greys.dark, ...sx }}  />
}