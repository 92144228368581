import Widget from "../Widget";
import { OtherDocumentIcon } from "../ChartIcons";
import { WidgetDateTableList } from "./components/WidgetDateTableList";
import React, { useMemo } from 'react'
import { OtherDocumentsModal } from "../other-documents/components/OtherDocumentsModal";
import { LinkedDocumentType } from "../../../../types/Document";
import { displayCategory } from "../../util/displayEnum";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import { useReadQuery } from "@apollo/client";

export const OtherDocumentsWidget = () => {
  const [ selectedDocument, setSelectedDocument ] = React.useState<LinkedDocumentType|null>(null)

  return <Widget
    route={'other-documents'}
    title={'Other documents'}
    WidgetIcon={OtherDocumentIcon}
  >
    <OtherDocumentsWidgetBody setSelectedDocument={setSelectedDocument} />
    <OtherDocumentsModal document={selectedDocument} onModalClose={()=>{setSelectedDocument(null)}} />
  </Widget>
}


const OtherDocumentsWidgetBody = ({setSelectedDocument}) => {
  const { documentQueryRef, parseLinkedDocumentQueryResults } = usePatientDocumentContext()
  const { data } = useReadQuery(documentQueryRef!)
  const otherDocuments = useMemo(()=> parseLinkedDocumentQueryResults(data), [data, parseLinkedDocumentQueryResults])

  return (
    <WidgetDateTableList
      dataTestId={"otherDocumentsWidget"}
      rows={otherDocuments.map( od => {
        return {
          displayDate: od.documentDate,
          label: od.description || displayCategory(od.category),
          keyName: `otherDoc_${od.id}`,
          onRowClick: () => {setSelectedDocument(od)}
        }
      })}
      emptyListMessage={"Other documents appear here once categorized."}
    />
  )
}