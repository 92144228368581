import React from 'react'
import {
  useFieldArray, useFormContext
} from "saga-library/src/components/Form";
import Box from '@mui/material/Box'
import { RemoveButton } from '../RemoveButton/'
import { AddButton } from '../AddButton'
import { Notes } from "./NoteTypes";
import { TextField } from "../TextField";

export interface NoteComponentListProps {
}

export const NoteComponentList = ({
 }: NoteComponentListProps) => {

  const { control } = useFormContext<Notes>();

  const { fields, prepend, remove } = useFieldArray<Notes>({
    name: 'notes',
    control,
  })

  const addNote = () => {
    prepend({
      noteText: '',
    })
  }

  const removeNote = (index) => {
    remove(index)
  }

  return (
    <Box>
      <AddButton label={'Add a note'} onClick={addNote} autoFocus={true} dataTestId={'note'} />

      {fields.map((item, index) => {
        return (
          <NoteComponent
            key={item.id}
            name={`notes.${index}`}
            index={index}
            removeNote={removeNote}
            dataTestId={`note-${index}`}
          />
        )
      })}
    </Box>
  )
}

export interface NoteProps {
  name: string
  index: number
  removeNote: (number) => void
  dataTestId: string
}

export const NoteComponent = ({
  name,
  index,
  removeNote,
  dataTestId
}: NoteProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        gap={0.5}
      >
        <TextField
          label={''}
          name={`${name}.noteText`}
          multiline={true}
          fullWidth={true}
          minRows={1}
          dataTestId={dataTestId}
        />
        <RemoveButton
          onClick={() => removeNote(index)}
          sx={{
            color: theme => theme.palette.primary.main,
            my: 1,
          }}
          dataTestId={dataTestId}
        />
      </Box>
    </Box>
  )
}

export default NoteComponent
