import moment from "moment-timezone";
import {
  ConditionStatusMap,
  ConditionSeverityMap,
  ConditionAndProcedureType
} from '../../../../../types/ConditionAndProcedure'
import { nullOrUndefinedComparison } from "saga-library/src/components/TableList/TableSortUtils";

export const ConditionAndProcedureSortPredicate = (a: ConditionAndProcedureType, b: ConditionAndProcedureType) : number => {
  const getStatusOrder = (condition: ConditionAndProcedureType) => {
    return condition.status ? ConditionStatusMap.get(condition.status)!.order : null
  }

  const getSeverityOrder = (condition: ConditionAndProcedureType) : number | null => {
    return condition.severity ? ConditionSeverityMap.get(condition.severity)!.order : null
  }

  const aStatusOrder = getStatusOrder(a)
  const bStatusOrder = getStatusOrder(b)
  const aSeverityOrder = getSeverityOrder(a)
  const bSeverityOrder = getSeverityOrder(b)

  return  (nullOrUndefinedComparison(aStatusOrder, bStatusOrder) ?? aStatusOrder! - bStatusOrder!)
    || (nullOrUndefinedComparison(aSeverityOrder, bSeverityOrder) ?? aSeverityOrder! - bSeverityOrder!)
    || (nullOrUndefinedComparison(a.startDate, b.startDate) ?? moment(b.startDate).unix() - moment(a.startDate).unix())
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}