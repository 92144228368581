import React from 'react'
import { MenuItem } from '@mui/material'
import { Select } from 'saga-library/src'
import { PrescriptionStatusMap } from '../types/Prescription'

const PRESCRIPTION_STATUS_OPTIONS = Array.from(PrescriptionStatusMap).map(([key, value]) => {
  return {
    value: key,
    label: value.label
  }
})

interface PrescriptionStatusSelectProps {
  label: string
  name: string
  onChange?: (value) => void
  disabled?: boolean
  dataTestId: string
}

export const PrescriptionStatusSelect = ({
  name = "Prescription status",
  label,
  onChange,
  disabled,
  dataTestId
}: PrescriptionStatusSelectProps) => {
  return (
    <Select
      dataTestId={dataTestId}
      name={name}
      label={label}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
      disabled={disabled}
    >
      {PRESCRIPTION_STATUS_OPTIONS.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`${dataTestId}-prescription-status-select-${index}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}
