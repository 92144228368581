import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ReferralPractitionerNew } from '../apps/settings/Practitioners/ReferralPractitioners/ReferralPractitionerNew'
import { ReferralPractitionerProfile } from '../apps/settings/Practitioners/ReferralPractitioners/ReferralPractitionerProfile'
import { Practitioners } from '../apps/settings/Practitioners'
import { PractitionersReferral } from '../apps/settings/Practitioners/PractitionersReferral'
import { PractitionersClinic } from '../apps/settings/Practitioners/PractitionersClinic'
import { PractitionerNew } from '../apps/settings/Practitioners/PractitionerNew'
import { PractitionerDetails } from '../apps/settings/Practitioners/components/PractitionerDetails'
import { PractitionerBillingDetailsList } from '../apps/settings/Practitioners/components/BillingProfiles/PractitionerBillingDetailsList'
import { PractitionerBillingDetails } from '../apps/settings/Practitioners/components/BillingProfiles/PractitionerBillingDetails'
import PractitionerProfile from '../apps/settings/Practitioners/PractitionerProfile'
import {
  PractitionerSchedulesDetails
} from "../apps/settings/Practitioners/components/Schedules/PractitionerSchedulesDetails";

export const PractitionersRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Practitioners />}>
        <Route path={'referral'} element={<PractitionersReferral />} />
        <Route index element={<PractitionersClinic />} />
      </Route>

      <Route path={'new'} element={<PractitionerNew />}>
        <Route
          path={'billing'}
          element={<PractitionerBillingDetailsList.Form />}
        >
          <Route
            path={'b/:billing_id'}
            element={<PractitionerBillingDetails.Form />}
          />
        </Route>

        <Route
          path={'schedules'}
          element={<PractitionerSchedulesDetails.Form />}
        />

        <Route index element={<PractitionerDetails.Form />} />
      </Route>

      <Route path={'p/:practitioner_id'} element={<PractitionerProfile />}>
        <Route
          path={'billing'}
          element={<PractitionerBillingDetailsList.Form />}
        >
          <Route
            path={'b/:billing_id'}
            element={<PractitionerBillingDetails.Form />}
          />
        </Route>

        <Route
          path={'schedules'}
          element={<PractitionerSchedulesDetails.Form />}
        />

        <Route index element={<PractitionerDetails.Form />} />
      </Route>

      <Route path={'referral/new'} element={<ReferralPractitionerNew />} />

      <Route
        path={'referral/r/:referral_practitioner_id'}
        element={<ReferralPractitionerProfile />}
      />
    </Routes>
  )
}
