import React from "react";
import { Section } from "saga-library/src";
import { Box } from "@mui/material";


interface ChartPanelErrorBoundaryProps {
  label?: string | undefined,
  children: React.ReactNode
}

export class ChartPanelErrorBoundary extends React.Component<ChartPanelErrorBoundaryProps> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error)
    console.error(info.componentStack)
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // @ts-ignore
      return (
        <Section.Column headerLabel={this.props.label} >
          <Box color={"red"}>Failed to load</Box>
        </Section.Column>
      )

    }

    // @ts-ignore
    return this.props.children
  }
}