import { Box } from "@mui/material";
import React from "react";

export const GhostInspectorNewEvent = () => {
  return <Box // the only purpose for this Box is to allow QA to click the appointment slot behind it using Ghost Inspector
    className={"newEvent"}
    sx={{
      height: "100%",
      width: "8px",
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "100%",
      pointerEvents: "none"
    }}
  />
}