import { MutableRefObject, useCallback, useEffect } from "react";

const TabFocusInterceptor = (fieldToFocus: MutableRefObject<any>, hasFocus: boolean, setHasFocus ) => {

  const handleKeyDown = useCallback((event) => {
    if (!hasFocus && event.key === 'Tab') {
      event.preventDefault();
      if (fieldToFocus.current) {
        fieldToFocus.current.focus();
        setHasFocus(true)
      }

      setHasFocus(true)
    }
  }, [hasFocus, fieldToFocus, setHasFocus])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setHasFocus, fieldToFocus]);
};

export default TabFocusInterceptor