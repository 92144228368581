import React from 'react'
import { TextField, Section } from 'saga-library/src'
import { rules } from '../util/referralPractitionerValidation'
import { FormFragment } from "../../../../../components/PermissionForm";

export type PersonalDetailsType = {
  lastName: string
  firstName: string
}

const defaults = {
  lastName: '',
  firstName: '',
}

const Form = ({ requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Personal</Section.Header>
        <TextField
          label={'Last name'}
          name={'lastName'}
          inputProps={{ maxLength: rules.lastName.max }}
        />
        <TextField
          label={'First name'}
          name={'firstName'}
          inputProps={{ maxLength: rules.firstName.max }}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const PersonalDetails = {
  Form: Form,
  defaults: defaults,
}
