import React from "react";
import { LinkedDocumentModal } from "../../LinkedDocumentForm/LinkedDocumentModal";
import { DocumentCategory } from "../../../../../types/Document";

const OTHER_DOCUMENTS_FORM_NAME = "other_documents_modal_form"
export const OtherDocumentsModal = ({
  document,
  onModalClose
}) => {
  return (
    <LinkedDocumentModal
      formName={OTHER_DOCUMENTS_FORM_NAME}
      document={document}
      category={DocumentCategory.OTHER_DOCUMENT}
      title={document?.description ? document.description : "Other document"}
      onModalClose={onModalClose}
      dataTestId={'otherDocumentsModal'}
    />
  )
}