import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSelect, batchUpdateStyleSelectLabel } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import NewbornCodeSelect from "../../../../../components/NewbornCodeSelect/NewbornCodeSelect";

interface BatchControlledNewbornCodeSelectProps {
  label: string
  name: string
  dataTestId?: string
}

export const BatchControlledNewbornCodeSelect = ({
  label,
  name,
  dataTestId
}: BatchControlledNewbornCodeSelectProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <NewbornCodeSelect
      dataTestId={dataTestId}
      label={label}
      name={name}
      sx={batchUpdateStyleSelect(dirtyFields[name])}
      inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields[name])}
    />
  )
}
