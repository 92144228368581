import React, { useEffect } from 'react'
import { ButtonDropdown, SubsectionMenu, SubsectionMenuItem } from 'saga-library/src'
import { Box } from '@mui/material'
import { useReportContext } from '../../../providers/ReportProvider'
import { useFormattedDate } from '../../../hooks/FormattedDate'
import { ReportCategorySelect } from './ReportCategorySelect'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import { HasPermissionTemplate } from '../../../components/HasPermissionTemplate'
import  _sortBy from 'lodash/sortBy'

const MIN_WIDTH = "273px"

export const ReportSelect = () => {
  const { tenant_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute, userHasPermission } = useAccountContext()

  const { reports } = useReportContext()

  const [reportCategory, setReportCategory] = React.useState<string>('all')
  const [options, setOptions] = React.useState<{ name: string, onClick: () => void }[]>([])

  const billingPermission = userHasPermission(tenant_id || "", PermissionType.ReportingBilling, Permission.READWRITE)
  const patientPermission = userHasPermission(tenant_id || "", PermissionType.ReportingPatientAndAppointment, Permission.READWRITE)

  useEffect(() => {
    let reportOptions: { name: string, onClick: () => void }[] = []

    if (patientPermission) {
      reportOptions.push({ name: "APPOINTMENT", onClick: () => navigate(buildTenantRoute('reports/new/appointment', tenant_id)) })
      reportOptions.push({ name: "PATIENT", onClick: () => navigate(buildTenantRoute('reports/new/patient', tenant_id)) })
    }

    if (billingPermission) {
      reportOptions.push({ name: "BILLING", onClick: () => navigate(buildTenantRoute('reports/new/billing', tenant_id)) })
    }

    setOptions(reportOptions)
  }, [billingPermission, patientPermission])

  return (
    <SubsectionMenu
      header={
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <ReportCategorySelect onChange={setReportCategory}/>
          <HasPermissionTemplate requiredType={[PermissionType.ReportingBilling, PermissionType.ReportingPatientAndAppointment]} requiredPermission={Permission.READWRITE}>
            <ButtonDropdown
              dataTestId={"report-actions-new"}
              title={"NEW"}
              options={_sortBy(options, "name")}
            />
          </HasPermissionTemplate>
        </Box>
      }
      routes={reports
        .filter(r=> reportCategory === 'all' || r.category === reportCategory)
        .map(report => <ReportListItem key={`report_list_item_${report.id}`} report={report}/>)
      }
      overflowY={"auto"}
      width={MIN_WIDTH}
    />
  )
}

const ReportListItem = ({report}) => {
  const { tenant_id, report_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const formattedDate = useFormattedDate(report.lastRun, true)

  const onSelect = (reportId) => {
    navigate(buildTenantRoute(`reports/r/${reportId}`, tenant_id))
  }

  return (
    <SubsectionMenuItem
      dataTestId={"report_list"}
      key={`report_li_${report.id}`}
      isActive={report_id === report.id}
      primaryText={report.name}
      secondaryText={formattedDate || "Never run"}
      onClick={()=>onSelect(report.id)}
    />
  )
}