import { BlockType, ScheduleLength } from "../../../../types/schedule/Schedule";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { Eventcalendar, MbscCalendarEventData, momentTimezone } from "@mobiscroll/react";
import { Event } from "../../components/ScheduleCalendar/ScheduleBlockEvent";
import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { CONDENSED_ROW_HEIGHT } from "../../../../utils/ScheduleConstants";
import { insertDataTestIds, processEvents } from "../../util/scheduleFunctions";

export const TemplateCalendar = ({
  scheduleType = ScheduleLength.DAY,
  bookingPreferences,
  events,
  onEventCreated,
  onEventClicked,
  onEventUpdated,
  onCellRightClicked,
  closedTimes
}) => {
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const scheduleRef = useRef(null)

  const scheduleViewType = scheduleType

  useEffect(() => {
    insertDataTestIds(scheduleRef, null, 'template', scheduleViewType, 60)
  }, [JSON.stringify(events), scheduleRef, scheduleViewType])

  const scheduleEvent = (data: MbscCalendarEventData) => {
    const eventData = data.original

    switch(eventData?.blockType) {
      case BlockType.EVENT:
        return <Event eventData={data}/>
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        '& .mbsc-schedule-header': {
          display: 'none'
        },
        '& .mbsc-calendar-controls': {
          display: 'none'
        },
        "& .mbsc-schedule-time-wrapper":{
          height: CONDENSED_ROW_HEIGHT
        },
        "& .mbsc-schedule-item":{
          height: CONDENSED_ROW_HEIGHT
        }
      }}
    >
      <Eventcalendar
        ref={scheduleRef}
        data={processEvents(events)}
        showEventTooltip={false}
        renderScheduleEvent={scheduleEvent}
        clickToCreate={'single'}
        onEventCreated={onEventCreated}
        onEventClick={onEventClicked}
        onEventUpdated={(e)=>{
          onEventUpdated(e.event)
        }}
        onCellRightClick={onCellRightClicked}
        view={{
          schedule: {
            allDay: true,
            type: scheduleViewType,
          }
        }}
        theme={"material"}
        themeVariant={"light"}
        colors={[...closedTimes, ...bookingPreferences]}
        dataTimezone={'utc'}
        displayTimezone={timezone as string}
        timezonePlugin={momentTimezone}
        dragToMove={true}
      />
    </Box>
  )
}

