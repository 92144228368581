import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, ConfirmationDialog, DialogV2, PrintIconButton, RemoveButton } from "saga-library/src";
import moment from "moment-timezone";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { AppointmentInput } from "../../../../types/schedule";
import { appointmentDefaults } from "./NewAppointment";
import { AppointmentForm } from "./AppointmentForm";
import { schema } from "../../util/appointmentValidation";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useParams } from "react-router-dom";
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { useAccountContext } from "../../../../providers/AccountContext";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { DELETE_APPOINTMENT, UPDATE_APPOINTMENT } from "../../../../graphql-definitions";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime } from "../../util/scheduleFunctions";
import { ScheduleEventType } from "../../../../types/schedule/ScheduleEvent";
import { PrintPatientModal } from "../../../patients/components/print/PrintPatientModal";
import { getAppointmentTitle } from '../../util/appointmentDialogFunctions'

const DATA_TEST_ID = "editAppointment"
const FORM_NAME = "edit_appointment_form"

export interface EditAppointmentDialogProps {
  open: boolean
  onClose: () => void
  scheduleId: string
  practitionerName?: string
  eventData: ScheduleEventType
}

export const EditAppointmentDialog = ({
  open,
  onClose,
  scheduleId,
  practitionerName,
  eventData
}: EditAppointmentDialogProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
  const [openPrintModal, setOpenPrintModal] = useState(false)
  const [ openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()
  const { buildTenantRoute } = useAccountContext()
  const { getScheduleData, displayScheduleError, shuttleBay } = useScheduleContext()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()

  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE, tenantSettingsKeys.DEFAULT_APPOINTMENT_STATE_ID])

  const formMethods = useForm<AppointmentInput>({
    defaultValues: appointmentDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.DEFAULT_APPOINTMENT_STATE_ID.name], tenantSettings[tenantSettingsKeys.TIMEZONE.name]),
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting},
    reset
  } = formMethods

  const [ updateAppointment ] = useMutation(UPDATE_APPOINTMENT,{
    onCompleted:(data) => {
      showSuccessAlert('Appointment has been updated')
    },
    onError: (error) => {
      displayScheduleError(error, "Appointment", "updated")
    }
  })

  const [ deleteAppointment ] = useMutation(DELETE_APPOINTMENT,{
    onCompleted:(data) => {
      showSuccessAlert('Appointment has been deleted.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Appointment couldn't be deleted.")
    }
  })

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data, event) => {
    if (event && event.target.name !== FORM_NAME) {
      return
    }
    data.start = moment(data.itemDate + ' ' + formatTime(data.start), 'YYYY-MM-DD h:mm A')
    data.end =  moment(data.itemDate + ' ' + formatTime(data.end), 'YYYY-MM-DD h:mm A')

    await updateAppointment(
      {
        variables: {
          tenantId: tenant_id,
          appointmentInput: data,
          appointmentId: eventData.id
        }
      }
    )
    reset()
    onClose()
  })

  const onDelete = () => {
    deleteAppointment({
      variables: {
        tenantId: tenant_id,
        appointmentId: eventData.id,
        version: eventData.version
      },
      update(cache){
        const normalizedId = cache.identify({
          id:eventData.id,
          __typename: 'Appointment'
        })
        cache.evict({id:normalizedId})
        cache.gc()
      }
    })
    reset()
    onClose()
  }

  const openNewClaimTab = () => {
    const billingProfileId = getScheduleData(eventData.resource)?.defaultBillingProfileId
    window.open(buildTenantRoute(`billing?${billingProfileId? 'bpId='+billingProfileId+"&" :''}pId=${eventData.patient.id}&serviceDate=${moment(eventData?.start).local().format('YYYY-MM-DD')}`, tenant_id))
  }

  const openRebookAppointmentPanel = () => {
    const newEvent:ScheduleEventType = {...eventData}
    newEvent.id = "new_event"
    shuttleBay.setOccupied(newEvent)
    onClose()
  }

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: eventData?.start,
        end: eventData?.end,
        practitionerName: practitionerName
      })}
      headerActions={[
        <PrintIconButton key={`${DATA_TEST_ID}-print`} onClick={() => setOpenPrintModal(true)} dataTestId={DATA_TEST_ID} />,
        <RemoveButton key={`${DATA_TEST_ID}-remove`} onClick={() => setOpenDeleteDialog(true)} dataTestId={DATA_TEST_ID} />
      ]}
      formName={FORM_NAME}
      submitting={isSubmitting}
      dataTestId={DATA_TEST_ID}
    >
      <Box>
        <FormProvider {...formMethods}>
          <AppointmentForm
            formName={FORM_NAME}
            dataTestId={DATA_TEST_ID}
            onSubmit={onSubmit}
            newAppointment={false}
          />
        </FormProvider>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={"flex-start"}
        >
          <Button
            startIcon={<EventRepeatIcon />}
            name={"rebook_patient_button"}
            dataTestId={`${DATA_TEST_ID}-rebook`}
            onClick={openRebookAppointmentPanel}
            variant={'text'}
          >
            REBOOK PATIENT
          </Button>
          <Button
            startIcon={<ReceiptLong />}
            name={"new_claim_button"}
            dataTestId={`${DATA_TEST_ID}-newClaim`}
            onClick={openNewClaimTab}
            variant={'text'}
          >
            NEW CLAIM
          </Button>
        </Box>
      </Box>
      <PrintPatientModal patientId={eventData.patient.id} open={openPrintModal} onModalClose={() => setOpenPrintModal(false)} />
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={()=> setOpenDeleteDialog(false)}
        title={"Delete appointment?"}
        message={"This action cannot be undone"}
        primaryAction={onDelete}
        primaryLabel={"Delete"}
        dataTestId={`${DATA_TEST_ID}-deleteDialog`}
      />
    </DialogV2>
  )
}