import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from "../Typography";

export const CenterMenu = ({ routes }) => {
  if (!routes) return null
  return (
      <Box sx={{overflow:"hidden"}} flexDirection={'row'} display={'flex'}>
        {routes.map((route, index) => (
          <div key={index}>{route}</div>
        ))}
      </Box>
  )
}

export const MenuItem = ({ isActive, Icon, text, dataTestId }) => {
  return (
      <Box
        data-testid={dataTestId}
        sx={{
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          backgroundColor: isActive ? 'primary.light' : 'transparent',
          p: 2
        }}
      >
        <MenuItemIcon dataTestId={`${dataTestId}-icon`} Icon={Icon} />
        <MenuText text={text} />
      </Box>
  )
}

export const MenuText = ({ text }) => (
  <Typography variant="body1">{text}</Typography>
)

export const MenuItemIcon = ({ Icon, dataTestId }) => {
  return (
    <Icon sx={{fontSize: "24px", mr: 1}} data-testid={dataTestId}/>
  )
}

export default CenterMenu