

export const getTenantStorageKey = (userId) => {
  return `${userId}:tenant_id`
}

export const getLocationStorageKey = (userId) => {
  return `${userId}:location_id`
}

export const getScheduleStorageKey = (userId, tenantId) => {
  return `${userId}:${tenantId}:lastViewedSchedule`
}

export const getDocumentViewerStorageKey = (userId) => {
  return `${userId}:documentViewerSettings`
}

