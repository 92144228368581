import React from 'react'
import * as yup from 'yup'

export const schema = yup
  .object({
    name: yup
      .string()
      .required('Required'),
    locationId: yup
      .string()
      .required('Required')
  })
