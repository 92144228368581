import * as yup from 'yup'

export const rules = {
  name: { max: 64 }
}

export const schema = yup.object({
  name: yup.string().max(rules.name.max).nullable(),
  typeId: yup.string().required("Required"),
  assignedTo: yup.object().required("Required"),
  stateId: yup.string().required("Required"),
  priority: yup.string().nullable(),
  startDate: yup.date().typeError("Invalid date").required("Required"),
  dueDate: yup.date().typeError("Invalid date").required("Required"),
  practitionerId: yup.string().nullable(),
  description: yup.string().nullable(),
  patientId: yup.string().transform((val) => val?.id).nullable(),
})