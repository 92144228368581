import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import { useSagaAuthentication } from "../../providers/Auth"
import { DestinationBeforeRedirect } from '../../types/DestinationBeforeRedirect'

export function PublicOnlyRoutes({ children }) {
  const { isAuthenticated } = useSagaAuthentication()
  const navigate = useNavigate()
  const location = useLocation()
  const publicOnlyPaths = ["/signin", "/", "/forgot-password"]
  
  useEffect(() => {
    if (isAuthenticated) {
      const savedDestination = (location.state as DestinationBeforeRedirect)?.from
      if (savedDestination && publicOnlyPaths.includes(savedDestination.pathname)) {
        navigate("/home")
      }
      else if (savedDestination) {
        navigate(savedDestination)
        location.state = null
      }
      else if (publicOnlyPaths.includes(location.pathname)) {
        navigate("/home")
      }
    }
  }, [isAuthenticated])

  return <>{children}</>;
}

export default PublicOnlyRoutes;