import { gql } from "@apollo/client";



export const GET_STATIC_DATA_QUERY = gql`
query StaticDataQuery {
  static {
    prescribedQuantityUnit {
      id
      display
    }
    prescriptionAdministrationSite {
      id
      display
    }
    prescriptionDoseQuantityUnit {
      id
      display
      pitSubsetLinks
      {
          status
      }
    }
    prescriptionDrugForm {
      id
      display
    }
    prescriptionIndicationForUse {
      id
      display
    }
    prescriptionRouteOfAdministration {
      id
      display
    }
    unitsOfTime {
      id
      display
    }
  }
}`