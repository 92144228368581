import { gql } from "@apollo/client";


export const SettingsFragment = gql`
  fragment Settings on Setting {
    ... on StringSetting {
      id
      name
      stringValue: value
    }
    ... on BoolSetting {
      id
      name
      boolValue: value
    }
    ... on IntSetting {
      id
      name
      intValue: value
    }
    ... on IdSetting {
      id
      name
      idValue: value
    }
  }
`

export const GET_TENANT_SETTINGS = gql`
  ${SettingsFragment}
  query GetTenantSetting($tenantId: ID!, $keys: [String!]!){
    tenant(id: $tenantId){
      settings(keys: $keys) {
        ...Settings
      }
    }
  }
`


export const SET_TIMEZONE = gql`
    mutation SetTimezone($tenantId: ID!, $timezone: String!) {
        tenant(id: $tenantId) {
            settings {
                setTimezone(timezone: $timezone)
            }
        }
    }
`

export const UPDATE_TENANT_SETTING = gql`
  ${SettingsFragment}
  mutation UpdateTenantSetting($tenantId: ID!, $setting:SettingInput!) {
    tenant(id: $tenantId) {
      settings {
        updateTenantSetting(setting: $setting) {
          ...Settings
        }
      }
    }
  }
`

