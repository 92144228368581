import React, { useRef, memo, useEffect, useCallback, useState } from 'react'
import { DataEvents, PDFDoc, PDFUI, PDFViewCtrl, PDFViewer, ViewerEvents } from './FoxitPDFViewer/FoxitTypes'
import { FoxitPDFViewer } from "./FoxitPDFViewer";
import { GET_READ_SAS_TOKEN } from "../graphql-definitions";
import _get from "lodash/get";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FileType } from "../types/tasks";
import { useAccountContext } from "../providers/AccountContext";
import Box from "@mui/material/Box";
import * as UIExtension from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/UIExtension.js';
import { usePatientDocumentStateContext } from '../apps/patients/providers/PatientDocumentStateProvider'
import { usePDFViewerEvent } from './FoxitPDFViewer/UsePDFViewerEvent'

interface DocumentViewerProps {
  fileRecord: FileType,
  variant?: "document" | "forms" | "labs"
  sx?: any
  documentStateContext?: ReturnType<typeof usePatientDocumentStateContext>
}

export const DocumentViewer = memo(({
  fileRecord,
  sx,
  variant = "document",
  documentStateContext
}: DocumentViewerProps) => {
  const { tenant_id } = useParams()
  const {documentViewerSettings:{ zoom, sidebarWidth }} = useAccountContext()
  const defaultDocumentStateContext = usePatientDocumentStateContext()
  const { setSelectedFile, setDocumentModified, pdfDocRef, pdfViewerRef } = documentStateContext || defaultDocumentStateContext
  const pdfuiRef = useRef<PDFUI | null>(null)
  const [ loading, setLoading ] = useState<boolean>(true)

  useEffect(() => {
    if(fileRecord){
      setSelectedFile(fileRecord)
    }
  }, [fileRecord])

  const documentOpenedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    setDocumentModified(false)
  }, [setDocumentModified])

  const documentModifiedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    setDocumentModified(true)
  }, [setDocumentModified])

  usePDFViewerEvent(DataEvents.docModified, documentModifiedCallback, pdfViewerRef)
  usePDFViewerEvent(ViewerEvents.beforeOpenFile, documentOpenedCallback, pdfViewerRef)

  useQuery(GET_READ_SAS_TOKEN, {
    variables: {
      tenantId: tenant_id,
      blobName: fileRecord.blobName,
    },
    skip: !fileRecord,
    fetchPolicy: 'no-cache',
    onCompleted: async(data) => {
      const token = _get(data, 'tenant.file.readSasToken', undefined)

      if (token) {
        const pdfui = pdfuiRef.current
        if (!pdfui) {
          setLoading(false)
          return
        }

        const uri = fileRecord.fileUri + '?' + token
        let document

        try {
          const fileType = fileRecord.originalFileName.split('.').pop()
          if(fileType === 'jpg') {
            await fetch(uri)
              .then(response => response.blob())
              .then(async blob => {
                if(!pdfViewerRef) return
                document = pdfViewerRef.current?.convertImageToPDFDoc(blob, uri, "", "", )
                  .then(async (doc) => {
                    await pdfViewerRef.current?.renderDoc(doc, '100');
                    await pdfViewerRef.current?.zoomTo(zoom)
                  })
              })
          }
          else {
            document = await pdfui.openPDFByHttpRangeRequest({
              range: {
                url: uri,
              },
            })
          }

          await pdfui.zoomTo(zoom)
          const sidebar = await pdfui.getComponentByName('sidebar') as UIExtension.components.widgets.SidebarComponent
          sidebar.expand();
          sidebar.element.setAttribute("style",`width: ${sidebarWidth}px`);
          if(variant === 'labs' && fileType !== 'jpg') {
            document.loadPDFForm().then((PDFForm) => {
              PDFForm.fields.forEach((field) => {
                const fieldFlags = field.getFlags();
                const Field_Flag = PDFViewCtrl.PDF.form.constant.Field_Flag;
                field.setFlags(fieldFlags | Field_Flag.ReadOnly);
              });
            })
          }
        }
        catch (error)
        {
          console.error('error getting file uri and token', error)
        }

        if (!document) {
          setLoading(false)
          console.error('error opening pdf')
          return
        }

        if (pdfDocRef) {
          pdfDocRef.current = document
        }
        setLoading(false)
      }
    },
    onError: (error) => {
      setLoading(false)
      console.error('error opening pdf', error)
    }
  })

  return (
    <Box
      sx={ theme =>({
        p:0,
        px: "2px",
        pb: "1px",
        mr:0,
        border:"1px solid " + theme.palette.greys.ui,
        borderRadius: 2,
        ...sx
    })}
    >
      <FoxitPDFViewer
        pdfuiRef={pdfuiRef}
        pdfViewerRef={pdfViewerRef}
        loading={loading}
        selectedFile={fileRecord}
        variant={variant}
      />
    </Box>
  )
})
