import React, { useEffect } from "react";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { LinkedDocumentEditor } from "../LinkedDocumentForm/LinkedDocumentEditor";
import { useParams } from "react-router-dom";
import { useReadQuery } from "@apollo/client";
import _get from "lodash/get";
import { FormType } from "../../../../types/patients";
import { usePatientFormContext } from "../../providers/PatientFormProvider";


const FORM_NAME = "form_document_form"

export const FormsDocumentEditor = () => {
  const { document_id } = useParams()
  const { patientFormQueryRef } = usePatientFormContext()
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)
  const { data } = useReadQuery(patientFormQueryRef!)

  useEffect(() => {
    if(document_id){
      const form = (_get(data, 'tenant.patient.form.list', []) as Array<FormType>).find(document => document.id === document_id && document.isLinkedDocument)
      if(form) {
        setDocument(form.linkedDocument)
      }
    }
  }, [document_id, data]);

  return (
    <LinkedDocumentEditor
      pathName={'forms'}
      formName={FORM_NAME}
      document={document}
      category={DocumentCategory.FORM}
      sectionLabel={document?.description || "Form"}
      dataTestId={"formDocument-editor"}
    />
  )
}