import React from "react";
import { Box } from "@mui/material";
import { GhostInspectorNewEvent } from "./GhostInspectorNewEvent";

export const AppointmentSlotEvent = ({
  eventData,
  height='100%',
}) => {
  return <Box
    sx={ theme => ({
      height: `calc(${height} - 4px)`,
      borderRadius:"4px",
      border: `2px solid ${theme.palette.primary.main}`,
      background: 'white'
    })}
  >
    <GhostInspectorNewEvent />
  </Box>
}