import React, { useEffect, useState } from 'react'
import { EmptyList, Typography } from "saga-library/src";
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import UserSearch from '../../../../components/UserSearch'
import TenantRoleUserListItem from './TenantRoleUserListItem'
import { Controller } from "saga-library/src/components/Form"
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import { User } from "../../../../types/account";

interface TenantRoleUsersProps {
  value: User[]
  onChange: (value) => void
  disabled?: boolean
}

const TenantRoleUsers = ({ value, onChange, disabled = false }: TenantRoleUsersProps) => {
  const [roleUserList, setRoleUserList] = useState<User[]>(value)
  useEffect(() => {
    onChange((value = roleUserList))
  }, [roleUserList])

  const showUsers = roleUserList.length > 0

  const addUserToRoleUsers = (value) => {
    setRoleUserList(value)
  }

  const removeUserFromRoleUsers = (user) => {
    setRoleUserList(roleUserList.filter((ru) => ru.id !== user.id))
  }

  return (
    <Box>
      <Typography variant={'body1'}>
        The following users have been assigned this role. Use the search to
        assign this role to a user.
      </Typography>
      <Paper
        sx={{
          height: '100%',
          minHeight: '500px',
          p: 1.5,
          mt: 1,
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
        }}
        variant={'outlined'}
      >
        <UserSearch
          name={'role_user_search'}
          onChange={addUserToRoleUsers}
          searchLabel={'Search to assign this role to a user'}
          multiple={true}
          hideSelected={true}
          disableChips={true}
          value={roleUserList}
          disabled={disabled}
        />
        {showUsers ? (
          <Box role={'tenantRoleUserList'} width={'100%'}>
            {roleUserList.map((ru, i) => (
              <TenantRoleUserListItem
                tenantUser={ru}
                removeUser={() => removeUserFromRoleUsers(ru)}
                key={'role_user' + i}
                disabled={disabled}
              />
            ))}
          </Box>
        ) : (
          <EmptyList
            message={"Users assigned this role appear here"}
            icon={GroupOutlinedIcon}
          />
        )}
      </Paper>
    </Box>
  )
}

export const ControlledUserSearch = ({ control, name, disabled=false }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <TenantRoleUsers value={value} onChange={onChange} disabled={disabled} />
      )}
    />
  )
}

export default TenantRoleUsers
