import React, { useMemo, useTransition } from 'react'
import { TableListHeaderConfig, Section } from "saga-library/src";
import { PatientAppointmentRow } from "../PatientAppointmentRow";
import { useAppointmentContext } from "../../providers/AppointmentProvider";
import { useLazyQuery, useReadQuery } from '@apollo/client'
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import PagedTableList from 'saga-library/src/components/TableList/PagedTableList'
import { useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { GET_APPOINTMENT_HISTORY } from '../../../../graphql-definitions'

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Date' },
  { name: 'Time' },
  { name: 'Practitioner' },
  { name: 'Type' },
  { name: 'State' },
  { name: 'Reason for visit' },
  { name: 'Notes' },
  { name: 'Last updated' },
  { name: 'Updated by' }
]

const SectionTitle = "Appointments"

export const PatientAppointments = ChartPanelHOC(SectionTitle, () => {
  const { appointmentQueryRef } = useAppointmentContext()

  return appointmentQueryRef && <PatientAppointmentsPanel/>
})

export const PatientAppointmentsPanel = () => {
  const { appointmentQueryRef, getAppointmentQueryResults, getAppointmentQueryResultsPageInfo, fetchMore } = useAppointmentContext()
  const { data } = useReadQuery(appointmentQueryRef)
  const appointments = useMemo(() => getAppointmentQueryResults(data), [data, getAppointmentQueryResults])
  const pageInfo = useMemo(() => getAppointmentQueryResultsPageInfo(data), [data, getAppointmentQueryResultsPageInfo])
  const { tenant_id } = useParams()
  const { getUserSetting } = useAccountContext()
  const { showErrorAlert } = useAlerts()
  const [ getHistoryQuery,{loading} ] = useLazyQuery(GET_APPOINTMENT_HISTORY,{
    variables: { tenantId: tenant_id }
  })
  const [isFetchMorePending, startFetchMoreTransition] = useTransition()

  const onRequestNext = () => {
    startFetchMoreTransition(() => {
      fetchMore(getAppointmentQueryResultsPageInfo(data).endCursor)
    })
  }

  return (
    <Section.Column headerLabel={SectionTitle}>
      <PagedTableList
        columns={columns}
        rows={[
          ...appointments.map((appointment) => PatientAppointmentRow(appointment, getUserSetting, showErrorAlert, getHistoryQuery, loading))
        ]}
        loading={loading}
        pageLoading={isFetchMorePending}
        pagingMode={'continuous'}
        pageInfo={pageInfo}
        defaultSortColumn={columns.findIndex(x => x.name === 'Date')}
        onRequestNext={onRequestNext}
        expandable={true}
        expandedTableName={"APPOINTMENT HISTORY"}
        dataTestId={"patient-appointments"}
      />
    </Section.Column>
  )
}
