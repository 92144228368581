import React, { useState } from 'react'
import { Box, Chip as MuiChip, Menu, MenuItem } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/system'
import { IconButton, TableListRowConfig, TextHighlighting, Typography } from "saga-library/src";
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { RESEND_INVITE_MUTATION, GET_TENANT_USERS, CANCEL_INVITE_MUTATION } from '../../../graphql-definitions'

const Chip = styled(MuiChip, { name: 'filled' })(({ theme, variant }) => ({
  backgroundColor: variant === 'filled' ? theme.palette.greys.dark : '',
  color: variant === 'filled' ? theme.palette.primary.contrastText : '',
  borderColor: variant === 'outlined' ? theme.palette.greys.dark : '',
  margin: 1,
}))

export const AccountCircleOutlinedStyledIcon = styled(AccountCircleOutlinedIcon)(({ theme }) => ({
  fontSize: 'xxx-large',
  color: theme.palette.greys.light,
}))

export const TenantInviteRow = (invite, roles, searchText, allowActions): TableListRowConfig => {
  return ({
    key: invite.id,
    rowData: [
      {
        children:
        <Box
          display={'flex'}
          alignItems={'center'}
          >
          <AccountCircleOutlinedStyledIcon />
        </Box>
      },
      {
        data: invite.emailAddress,
        children: [
          <Box key={'name-'+invite.id}>
            <Typography variant={'p4'}>
              <TextHighlighting text={invite.emailAddress} highlight={searchText} />
            </Typography>
          </Box>,
          <Box key={'email-'+invite.id} sx={theme => ({color: theme.palette.greys.light})}>
            <Typography variant={'p4'}>
              {moment(invite.expires).isAfter() ? `Invite expires ${moment(invite.expires).local().format('LLL')}` : 'Invite expired'}
            </Typography>
          </Box>,
        ],
      },
      {
        children: <TenantInviteRoles invite={invite} roles={roles} />,
      },
      {
        data: '',
      },
      {
        data: 'Never',
      },
      {
        data: moment(invite.expires).isAfter() ? 'Invited' : 'Invite expired'
      },
      {
        children: <TenantInviteRowAction invite={invite} allowActions={allowActions}/>,
      },
    ]
  })
}

const TenantInviteRoles = ({ invite, roles }) => {
  return invite.roleIds.map((roleId) => {
    let role = roles.find((r) => r.id === roleId)
    return <Chip key={invite.id+'-'+role.id} label={role.name} variant={'outlined'} />
  })
}

const TenantInviteRowAction = ({ invite, allowActions = true }) => {
  const { tenant_id } = useParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { showSuccessAlert, showErrorAlert } = useAlerts()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [cancelInvite, cancelProps] = useMutation(CANCEL_INVITE_MUTATION, {
    refetchQueries: [GET_TENANT_USERS],
  })

  const [resendInvite, resendProps] = useMutation(RESEND_INVITE_MUTATION, {
    refetchQueries: [GET_TENANT_USERS],
  })

  const cancelClicked = () => {
    cancelInvite({
      variables: {
        tenantId: tenant_id,
        inviteId: invite.id,
      },
      onCompleted: () => {
        showSuccessAlert('Invite has been cancelled.')
      },
      onError: (err) => {
        console.error(err)
        showErrorAlert('Invite couldn\'t be cancelled.')
      },
    })
    handleClose()
  }

  const resendClicked = () => {
    resendInvite({
      variables: {
        tenantId: tenant_id,
        inviteId: invite.id,
      },
      onCompleted: () => {
        showSuccessAlert('Invite re-sent.')
      },
      onError: (err) => {
        console.error(err)
        showErrorAlert("Invite couldn't be re-sent.")
      },
    })
    handleClose()
  }

  return (
    <>
      <IconButton onClick={handleClick} disabled={!allowActions} icon={<MoreHorizIcon />}/>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={resendClicked}>Resend Invite</MenuItem>
        <MenuItem onClick={cancelClicked}>Cancel Invite</MenuItem>
      </Menu>
    </>
  )
}
