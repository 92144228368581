import React from 'react'
import { TextField, Section } from 'saga-library/src'
import { SkillSelect } from '../../../../../components/SkillSelect'
import { ControlledFunctionalCenterInput } from '../../../../../components/FunctionalCenterSearch/FunctionalCenterInput'
import LocationCodeSelect from "../../../../../components/LocationCodeSelect/LocationCodeSelect";
import { LocationCode } from "../../../../../utils/ABClaimConstants";
import { SkillValue } from "../../../../../components/SkillSelect/SkillSelect";
import { useFormContext } from "saga-library/src/components/Form";
import { FormFragment } from "../../../../../components/PermissionForm";
import { FacilitySearch } from "../../../../../components/SearchControls/Claims/FacilitySearch";
import { Facility, FunctionalCenter } from "../../../../../types/billing";

export type AhcipBillingDetailsType = {
  id?: string
  baNumber: number | undefined
  abClaimFacility?: Facility
  abClaimFunctionalCenter?: FunctionalCenter
  facilityId?: string | null
  functionalCenterId?: string | null
  skillId?: string
  skill?: SkillValue
  locumBaNumber?: number
  locationCode?: LocationCode
}


const defaults = {
  baNumber: null,
  abClaimFacility: null,
  abClaimFunctionalCenter: null,
  skill: null,
  locumBaNumber: null,
  locationCode: null,
}

const Form = ({ profileIndex, requiredPermissionType }) => {
  const { getValues, setValue } = useFormContext()
  const province = getValues('province')

  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>AHCIP billing</Section.Header>
        <TextField
          label={'Business arrangement (BA) number'}
          name={`billingProfileInputs.${profileIndex}.baNumber`}
          type={'number'}
          dataTestId={"ahcipBillingDetails-baNumber"}
        />
        <FacilitySearch
          name={`billingProfileInputs.${profileIndex}.abClaimFacility`}
          label={"Facility"}
          dataTestId={"ahcipBillingDetails-facilitySearch"}
        />
        <ControlledFunctionalCenterInput
          name={`billingProfileInputs.${profileIndex}.abClaimFunctionalCenter`}
          onClear={() => {
            setValue(
              `billingProfileInputs.${profileIndex}.abClaimFunctionalCenter`,
              null
            )
          }}
          dataTestId={"ahcipBillingDetails-functionalCenterInput"}
        />
        <SkillSelect
          provinceCode={province}
          searchBySkillCode={true}
          name={`billingProfileInputs.${profileIndex}.skill`}
          label={'Skill'}
          dataTestId={"ahcipBillingDetails-skill-select"}
        />
        <TextField
          label={'Locum BA number'}
          name={`billingProfileInputs.${profileIndex}.locumBaNumber`}
          type={'number'}
          dataTestId={"ahcipBillingDetails-locum-ba-number"}
        />
        <LocationCodeSelect
          label={'Location'}
          name={`billingProfileInputs.${profileIndex}.locationCode`}
          dataTestId={"ahcipBillingDetails-location-select"}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const AhcipBillingDetails = {
  Form: Form,
  defaults: defaults,
}
