import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { TextField, Form, ToggleButtonGroup } from "saga-library/src";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import {
  ConditionSeverity,
  ConditionStatusMap
} from "../../../../../types/ConditionAndProcedure";
import { ControlledConditionSelect } from "./ControlledConditionSelect";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import {
  ConditionAndProcedureDescriptionSearch
} from "../../../../../components/SearchControls/Patient/ConditionAndProcedureDescriptionSearch";
import { DiagnosticCodeSearch } from "../../../../../components/SearchControls/Claims/DiagnosticCodeSearch";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";
import { HighIcon, MediumIcon, LowIcon } from "../../ChartIcons";

interface ConditionAndProcedureFormProps {
  onSubmit: () => void,
  autoFocus: boolean
}

export const CONDITION_FORM_ID = 'condition-form'

export const ConditionAndProcedureForm = ({
  onSubmit,
  autoFocus = false,
} : ConditionAndProcedureFormProps) => {
  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(autoFocus);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !autoFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  const { control, setValue } = useFormContext();

  const description = useWatch({
    control,
    name: "description"
  })

  useEffect(() => {
    if (description && typeof description === 'object' && description.diagnosticCode) {
      setValue('diagnosticCode', description)
    }
  }, [description]);

  return (
    <Form onSubmit={onSubmit} id={CONDITION_FORM_ID}>
      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        <ConditionAndProcedureDescriptionSearch
          dataTestId={`${CONDITION_FORM_ID}-description-search`}
          name={'description'}
          label={'Description'}
          autoFocus={autoFocus}
          inputRef={firstFieldRef}
        />
        <DiagnosticCodeSearch
          dataTestId={`${CONDITION_FORM_ID}-diagnostic-code-search`}
          name={'diagnosticCode'}
          label={'Diagnostic code'}
          allowMultiple={false}
        />
        <ControlledConditionSelect
          dataTestId={`${CONDITION_FORM_ID}-status-select`}
          name={'status'}
          label={'Status'}
          map={ConditionStatusMap}
        />
        <Box
          sx={{
            mb: 2,
            mt: 1
          }}
        >
          <ToggleButtonGroup
            dataTestId={`${CONDITION_FORM_ID}-condition-severity`}
            name={"severity"}
            buttons={[
              { value: ConditionSeverity.SEVERE, icon: <HighIcon />, children: "Severe" },
              { value: ConditionSeverity.MODERATE, icon: <MediumIcon />, children: "Moderate" },
              { value: ConditionSeverity.MILD, icon: <LowIcon />, children: "Mild" }
            ]}
            fullWidth={true}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={1}
        >
          <FormattedDatePicker
            dataTestId={`${CONDITION_FORM_ID}-start-date`}
            name={'startDate'}
            label={'Start date'}
            sx={{flexGrow: 1}}
          />
          <FormattedDatePicker
            dataTestId={`${CONDITION_FORM_ID}-end-date`}
            name={'endDate'}
            label={'End date'}
            sx={{flexGrow: 1}}
          />
        </Box>
        <TextField
          dataTestId={`${CONDITION_FORM_ID}-notes`}
          name={`notes`}
          label={'Notes'}
        />
      </Box>
    </Form>
  )
}