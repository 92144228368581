import React from "react"
import { PrescriptionResultType, PrescriptionFreeSoloType} from "./DrugSearch";
import { Drug } from "../../../../../types/Prescription";
import {  PrescriptionResult } from "../../../../../components/SearchControls/Prescription/PrescriptionResult";
import { DrugResult } from "./DrugResult";
import FavoriteIcon from '@mui/icons-material/Favorite';
import HistoryIcon from '@mui/icons-material/History';
import { IconImageTooltip } from 'saga-library/src'

interface PrescriptionResultProps {
  prescriptionResult: PrescriptionFreeSoloType
  searchText: string
  onUnfavourite?: () => void
}

export const ResultTypes = {
  drug: "DRUG",
  favourite: "FAVOURITE",
  recent: "RECENT",
  favouriteAndRecent: "FAVOURITE_AND_RECENT"
}


export const PrescriptionSearchResult = ({
  prescriptionResult,
  searchText,
  onUnfavourite
}: PrescriptionResultProps) => {
  if(typeof prescriptionResult === 'string') {
    return (
      <PrescriptionResult
        Prescription={ {name: prescriptionResult} as PrescriptionResultType}
        searchText={searchText}
      />
    )
  }

  if(prescriptionResult.resultType === ResultTypes.drug) {
    return (
      <DrugResult
        drug={ prescriptionResult as Drug }
        searchText={searchText}
      />
    )
  }

  if(prescriptionResult.resultType === ResultTypes.favourite) {
    return (
      <PrescriptionResult
        Prescription={ prescriptionResult }
        searchText={searchText}
        onUnfavourite={onUnfavourite}
        icons={searchText.length > 0 && <PrescriptionIcon type={'Favourite'} />}
      />
    )
  }

  if(prescriptionResult.resultType === ResultTypes.recent){
   return (
     <PrescriptionResult
       Prescription={ prescriptionResult }
       searchText={searchText}
       icons={searchText.length > 0 && <PrescriptionIcon type={'Frequently used'}/>}
     />
   )
  }

  if(prescriptionResult.resultType === ResultTypes.favouriteAndRecent){
    return (
      <PrescriptionResult
        Prescription={ prescriptionResult }
        searchText={searchText}
        icons={searchText.length > 0 && <><PrescriptionIcon type={'Frequently used'}/><PrescriptionIcon type={'Favourite'}/></>}
      />
    )
  }

  return <></>
}

const PrescriptionIcon = ({ type }) => {
  return <IconImageTooltip title={type}>
    {type === 'Favourite' ? <FavoriteIcon /> : <HistoryIcon />}
  </IconImageTooltip>
}