import { gql } from "@apollo/client";

export const GET_GENDERS = gql`
  query GetGenders($provinceCode: String!) {
    static {
      gendersByProvince(provinceCode: $provinceCode) {
        id
        name
      }
    }
  }
`

export const GET_PROVINCES = gql`
  query GetProvinces {
    static {
      provinces {
        name
        code
      }
    }
  }
`

export const GET_SKILLS = gql`
  query GetSkills($provinceCode: String!) {
    static {
      skillsByProvince(provinceCode: $provinceCode) {
        id
        description
        skillCode
      }
    }
  }
`

export const GET_FUNCTIONAL_CENTERS = gql`
  query GetFunctionalCenters {
    static {
      abClaimFunctionalCenters {
        id
        description
        functionalCenterCode
      }
    }
  }
`


export const TIMEZONES = gql`
    query Timezones {
        static {
            timezones {
                name
                abbreviation
                isDaylightSavingsTime
                utcOffset
            }
        }
    }
`



export const GET_DISCIPLINES = gql`
  query GetDisciplines($provinceCode: String!) {
    static {
      disciplinesByProvince(provinceCode: $provinceCode) {
        id
        description
      }
    }
  }
`
