import React, { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_RECENT_GLOBAL } from "../graphql-definitions";

const useTenantDataLoader = () => {
  const [recentsQuery, { error }] = useLazyQuery(GET_RECENT_GLOBAL, {
    fetchPolicy: 'network-only',
  })
  const { tenant_id } = useParams()

  const tenantRef = useRef<string | undefined | null>()

  useEffect(() => {
    if (tenant_id !== tenantRef.current) {
      tenantRef.current = tenant_id
      recentsQuery({
        variables: {
          tenantId: tenant_id,
        },
      })
    }
  }, [tenant_id])

  if (error) {
    console.error(error)
  }

  return
}

export default useTenantDataLoader
