import { AppointmentType } from "./AppointmentType";
import { AppointmentState } from "./AppointmentState";
import moment from "moment-timezone"

export type Appointment = {
  id: string
  billingProfileId: string
  start: string
  end: string
  appointmentType: AppointmentType
  appointmentState: AppointmentState
  patientName: string
  scheduleId: string
  version: string
}

export type AppointmentInput = {
  scheduleId: string,
  patientId: string,
  appointmentTypeId?: string | null,
  method?: AppointmentMethod | null,
  start: moment.Moment,
  end: moment.Moment,
  appointmentStateId: string,
  reasonForVisit: string,
  notes: string,
  allDay?: boolean,
  itemDate?: string
  version: string
}

export type AppointmentSlotType = {
  scheduleId: string,
  start: string,
  end: string,
}

export enum AppointmentMethod {
  INPERSON = 'INPERSON',
  PHONE = 'PHONE',
  VIDEO = 'VIDEO'
}