import { EncounterNote } from '../../../../types/patients'
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import moment from 'moment-timezone'
import {
  EncounterNoteField,
  EncounterNoteTemplate,
  EncounterNoteTemplateFieldTree,
  EncounterNoteTemplateFieldType
} from '../../../../types/patients'
import { Vital } from '../vitals/VitalTypes'

export const EncounterNoteSortPredicate = (a:EncounterNote, b:EncounterNote) : number => {
  return (nullOrUndefinedComparison(a.encounterNoteDisplayDate, b.encounterNoteDisplayDate) ?? moment(b.encounterNoteDisplayDate).unix() - moment(a.encounterNoteDisplayDate).unix())
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}

export const getEncounterNoteSummary = (encounterNote: EncounterNote) => {
  const summaryData = encounterNote.fields?.find(f => f.templateFieldId === encounterNote.template?.summaryFieldId)?.data
  if(summaryData) {
    return `${encounterNote.template?.summaryField || "Summary"}: ${summaryData}`
  }
}

export const convertEncounterNoteToInput = (encounterNote) => {
  let encounterNoteInput = {
    id: encounterNote.id,
    templateId: encounterNote.template?.id,
    appointmentId: encounterNote.appointment?.id,
    practitionerId: encounterNote.practitioner?.id,
    encounterNoteDate: encounterNote.encounterNoteDisplayDate,
    fields: encounterNote.fields,
    version: encounterNote.version
  }

  encounterNote.template?.fields?.forEach((templateField) => {
    const matchedField = encounterNote.fields?.find((field) => field.templateFieldId === templateField.id)
    if (matchedField) {
      encounterNoteInput[`${templateField.type.toLowerCase()}-${templateField.id}`] = matchedField.data
    }
  })

  return encounterNoteInput
}

export const cleanEncounterNoteInput = (encounterNote) => {
  let encounterNoteData = Object.assign({}, encounterNote)
  let fields: EncounterNoteField[] = []

  for (const [key, value] of Object.entries(encounterNoteData)) {
    const keySeparatorIndex = key.indexOf("-")
    if (keySeparatorIndex === -1) {
      continue
    }

    const keyFieldType = key.substring(0, keySeparatorIndex)
    const keyFieldId = key.substring(keySeparatorIndex + 1)
    switch (keyFieldType) {
      case "textbox":
        const field = formatTextboxField(encounterNoteData.fields || [], keyFieldId, value as string)
        fields.push(field)
        delete encounterNoteData[key]
        break
      default:
        console.warn("Unknown field type: ", keyFieldType)
    }
  }

  encounterNoteData.fields = fields

  if (!!BigInt(encounterNoteData.version)) {
    delete encounterNoteData.templateId
  }

  return encounterNoteData
}

const formatTextboxField = (currentFields: EncounterNoteField[], fieldId: string, data: string): EncounterNoteField => {
  const matchedField = currentFields.find((field) => field.templateFieldId === fieldId)
  if (matchedField) {
    return {
      ...matchedField,
      data: data
    }
  }
  return {
    templateFieldId: fieldId,
    data: data,
    version: "0"
  }
}

export const buildNewEncounterNote = (template:EncounterNoteTemplate) : EncounterNote => {
  const encounterNote:EncounterNote = {
    template: template,
    templateId: template.id,
    encounterNoteDisplayDate: new Date(),
    fields: template.fields?.filter(field => field.type !== EncounterNoteTemplateFieldType.GROUP)
      .map(field => {
        return {
          templateFieldId: field.id,
          data: field.type === EncounterNoteTemplateFieldType.VITALS ? undefined : null,
          vitals: field.type !== EncounterNoteTemplateFieldType.VITALS ? undefined : {
            bloodPressureSystolic: null,
            bloodPressureDiastolic: null,
            heartRate: null,
            weight: null,
            lengthHeight: null,
            headCircumference: null
          } as Vital
        } as EncounterNoteField
      })
  }

  return buildEncounterNote(encounterNote)
}

export const buildEncounterNote = (encounterNote:EncounterNote) : EncounterNote => {
  if (!encounterNote.template) {
    return encounterNote
  }

  // create new field objects with subfields and a link to the field on the encounter note
  const fieldTree = encounterNote.template?.fields?.map(templateField => {
    return {
      ...templateField,
      subFields: [],
      encounterNoteField: encounterNote.fields?.find(field => field.templateFieldId === templateField.id)
    } as EncounterNoteTemplateFieldTree
  })

  if (!fieldTree) {
    return encounterNote
  }

  // move each field to be contained within its parent group
  fieldTree?.forEach(templateField => {
    if (templateField.parentGroupId) {
      const parentTemplateField = fieldTree?.find(parentField => parentField.id === templateField.parentGroupId)
      if (parentTemplateField) {
        // sort by field.order
        const index = parentTemplateField.subFields.findIndex(subField => subField.order > templateField.order)
        if (index >= 0) {
          parentTemplateField.subFields.splice(index, 0, templateField)
        } else {
          parentTemplateField.subFields.splice(0, 0, templateField)
        }
      }
    }
  })

  // remove each field that is now contained within its parent group
  const newTemplate = {
    ...encounterNote.template,
    fieldTree: fieldTree
      .filter(field => !field.parentGroupId)
      .sort((a, b) => (a.order - b.order)),
  };

  encounterNote = {
    ...encounterNote,
    template: newTemplate,
  };

  return encounterNote
}