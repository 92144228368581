import React from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'saga-library/src'
import { Box, CircularProgress, MenuItem, SxProps } from "@mui/material";
import { GET_GENDERS } from "../../graphql-definitions";
import { Theme } from "@mui/system";

interface GenderSelectProps {
  provinceCode?: string | null | undefined
  label?: string
  name?: string
  onChange?: (value) => void
  dataTestId?: string
  sx?: SxProps<Theme>
}

export const GenderSelect = ({ provinceCode="AB", label="Gender", name="genderId", onChange, dataTestId, sx={} }: GenderSelectProps) => {
  const { data, loading } = useQuery(GET_GENDERS, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: provinceCode },
  })
  return (
    <Box display={"flex"} flexDirection={"row"} sx={{ width: "100%", ...sx }}>
      <Select
        label={label}
        name={name}
        onChange={(value) => {
          if (onChange) {
            onChange(value)
          }
        }}
        dataTestId={dataTestId}
      >
        {loading && (
          <MenuItem>
            <CircularProgress />
          </MenuItem>
        )}
        {data && data.static.gendersByProvince.map(({ name, id }, index) =>
          <MenuItem data-testid={`${dataTestId}-menuItem-${index}`} value={id} key={id}>{name}</MenuItem>
        )}
      </Select>
    </Box>
  )
}

export default GenderSelect
