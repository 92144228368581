import { useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'
import React, { useEffect } from 'react'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import { ReportCategoryType } from '../../../types/Report'
import { Box } from '@mui/material'
import { FilterSelect } from 'saga-library/src'
import _sortBy from 'lodash/sortBy'

export const ReportCategorySelect = ({ onChange }) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const [categories, setCategories] = React.useState<{label:string, value:string}[]>([])

  const billingPermission = userHasPermission(tenant_id || "", PermissionType.ReportingBilling, Permission.READONLY)
  const patientPermission = userHasPermission(tenant_id || "", PermissionType.ReportingPatientAndAppointment, Permission.READONLY)


  useEffect(() => {
    let reportCategories:{label:string, value:string}[] = []
    if(patientPermission) {
      reportCategories.push({label: "All", value: "all"})
      reportCategories.push({label: "Patient", value: ReportCategoryType.PATIENT})
      reportCategories.push({label: "Appointment", value: ReportCategoryType.APPOINTMENT})
    }

    if(billingPermission) {
      reportCategories.push({label: "Billing", value: ReportCategoryType.BILLING})
    }

    setCategories(reportCategories)
  }, [billingPermission, patientPermission])

  return(
    <Box sx={{ pl: 1 }}>
      <FilterSelect
        dataTestId={"reportTypeSelect"}
        options={_sortBy(categories, "label")}
        onSelect={(v)=>onChange(v.value)}
        size={"large"}
        sx={{justifyContent: 'left'}}
      />
    </Box>
  )
}