import { gql } from "@apollo/client";

const REPORT_FRAGMENT = gql`
  fragment ReportDetails on Report {
    id
    name
    description    
    category
    columns {
      id
      column
      order
      version
    }
    parameters {
      id
      parameter
      version
    }
    queries {
      id
      column
      operator
      combinator
      value1
      value2
      groupId
      order
      version
    }
    lastRun
    isReference
    version
  }
`

const REPORT_RESULT_FRAGMENT = gql`
  fragment ReportResult on ReportResults {
    results {
      columns {
        columnId
        result
      }
    }
    columns {
      id
      name
      format
    }
    runDate
  }
`

export const LIST_REPORTS = gql`
  ${REPORT_FRAGMENT}
  query ListReports($tenantId: ID!) {
    tenant(id: $tenantId) {
      report {
        list {
          ...ReportDetails
        }
      }
    }
  }
`

export const LIST_BILLING_REPORTS = gql`
  ${REPORT_FRAGMENT}
  query ListReports($tenantId: ID!) {
    tenant(id: $tenantId) {
      report {
        listBilling {
          ...ReportDetails
        }
      }
    }
  }
`

export const LIST_PATIENT_AND_APPOINTMENT_REPORTS = gql`
  ${REPORT_FRAGMENT}
  query ListReports($tenantId: ID!) {
    tenant(id: $tenantId) {
      report {
        listPatientAndAppointment {
          ...ReportDetails
        }
      }
    }
  }
`

export const CREATE_APPOINTMENT_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation CreateReport($tenantId: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        createAppointment(input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const CREATE_BILLING_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation CreateReport($tenantId: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        createBilling(input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const CREATE_PATIENT_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation CreateReport($tenantId: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        createPatient(input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const UPDATE_APPOINTMENT_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation UpdateReport($tenantId: ID!, $id: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        updateAppointment(id: $id, input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const UPDATE_BILLING_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation UpdateReport($tenantId: ID!, $id: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        updateBilling(id: $id, input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const UPDATE_PATIENT_REPORT = gql`
  ${REPORT_FRAGMENT}
  mutation UpdateReport($tenantId: ID!, $id: ID!, $input: ReportInput!) {
    tenant(id: $tenantId) {
      report {
        updatePatient(id: $id, input: $input) {
          ...ReportDetails
        }
      }
    }
  }
`

export const DELETE_APPOINTMENT_REPORT = gql`
  mutation DeleteReport($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      report {
        deleteAppointment(id: $id, version: $version)
      }
    }
  }
`

export const DELETE_BILLING_REPORT = gql`
  mutation DeleteReport($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      report {
        deleteBilling(id: $id, version: $version)
      }
    }
  }
`

export const DELETE_PATIENT_REPORT = gql`
  mutation DeleteReport($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      report {
        deletePatient(id: $id, version: $version)
      }
    }
  }
`

export const GET_APPOINTMENT_REPORT_RESULT = gql`
  ${REPORT_RESULT_FRAGMENT}
  query GetReportResults($tenantId: ID!, $input: ReportRunInput!) {
    tenant(id: $tenantId) {
      report {
        runAppointment(input: $input) {
          ...ReportResult
        }
      }
    }
  }
`

export const GET_BILLING_REPORT_RESULT = gql`
  ${REPORT_RESULT_FRAGMENT}
  query GetReportResults($tenantId: ID!, $input: ReportRunInput!) {
    tenant(id: $tenantId) {
      report {
        runBilling(input: $input) {
          ...ReportResult
        }
      }
    }
  }
`

export const GET_PATIENT_REPORT_RESULT = gql`
  ${REPORT_RESULT_FRAGMENT}
  query GetReportResults($tenantId: ID!, $input: ReportRunInput!) {
    tenant(id: $tenantId) {
      report {
        runPatient(input: $input) {
          ...ReportResult
        }
      }
    }
  }
`
