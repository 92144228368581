import React from 'react'
import { Box } from '@mui/material'
import { TextField, Section, PhoneType } from 'saga-library/src'
import { PhoneComponentList } from 'saga-library/src/components/Phone/Phone'

export type ContactDetailsType = {
  email: string
  phones: PhoneType[]
}

const defaults = {
  email: '',
  phones: [] as PhoneType[],
}

const Form = (props) => {
  const dataTestId = props?.dataTestId

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
      <Section.SubHeader>Contact</Section.SubHeader>
      <TextField
        dataTestId={`${dataTestId}-email`}
        label={'Email address'}
        name={'email'}
      />
      <PhoneComponentList dataTestId={`${dataTestId}-phoneList`} />
    </Box>
  )
}

export const ContactDetails = {
  Form: Form,
  defaults: defaults,
}
