import React, { useCallback, useMemo, useState } from 'react'
import Widget from '../Widget'
import {
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from "saga-library/src";
import { Box } from "@mui/material";
import { LabAndInvestigationsIcon } from "../ChartIcons";
import { LabAndInvestigationDocumentModal } from "../labAndInvestigations/LabAndInvestigationDocumentModal";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useLabAndInvestigationContext } from "../../providers/LabAndInvestigationProvider";
import { useReadQuery } from "@apollo/client";
import { AbLabResult } from "../../../../types/patients";
import { LabAndInvestigationModal } from '../labAndInvestigations/LabAndInvestigationModal'


export const LabsAndInvestigationsWidget = () => {
  const [ selectedLabAndInvestigation, setSelectedLabAndInvestigation ] = useState<AbLabResult|null>(null)

  const handleModalClose = useCallback(() => {
    setSelectedLabAndInvestigation(null)
  }, [setSelectedLabAndInvestigation])

  const Modal = () => {
    if (selectedLabAndInvestigation) {
      if (selectedLabAndInvestigation?.isLinkedDocument) {
        return <LabAndInvestigationDocumentModal document={selectedLabAndInvestigation.linkedDocument} onModalClose={handleModalClose} />
      }
      return <LabAndInvestigationModal labId={selectedLabAndInvestigation.id} open={!!selectedLabAndInvestigation} onModalClose={handleModalClose} />
    }
    return <></>
  }

  return <Widget
    route={'labs-investigations'}
    title={'Labs and investigations'}
    WidgetIcon={LabAndInvestigationsIcon}
  >
    <LabsAndInvestigationsWidgetBody setSelectedLabAndInvestigation={setSelectedLabAndInvestigation} />
    {Modal()}
  </Widget>
}

const LabsAndInvestigationsWidgetBody = ({ setSelectedLabAndInvestigation }) => {
  const { labAndInvestigationQueryRef, parseLabAndInvestigationQueryResults } = useLabAndInvestigationContext()
  const { data } = useReadQuery(labAndInvestigationQueryRef!)
  const labAndInvestigations = useMemo(()=> parseLabAndInvestigationQueryResults(data), [data, parseLabAndInvestigationQueryResults])

  const onRowClick = (lab:AbLabResult) => {
    setSelectedLabAndInvestigation(lab)
  }

  const columns: TableListHeaderConfig[] = [
    { name: '', sx:{width:80} },
    { name: '', sx:{width:104} },
    { name: '', sx:{width:24} },
    { name: '', sx:{width:72} }
  ]

  return (
    <TableList
      dataTestId={'labsInvestigationsWidget'}
      columns={columns}
      showHeader={false}
      rows={labAndInvestigations.map((lab) => LabAndInvestigationsRow(lab, onRowClick))}
      sx={{height: '100%', "&.MuiTableCell-root": { borderBottom: null }}}
      emptyListComponent={{ message: "Labs and investigations appear here once categorized.", size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      isWidget={true}
    />
  )
}

function LabAndInvestigationsRow(lab: AbLabResult, onRowClick): TableListRowConfig {
  const categoryLabel = lab.isLab ? "Lab result" : "Investigation"
  const observationResultCount = lab.abLabResultObservationResults?.length
  const firstObservationResult = lab.abLabResultObservationResults?.[0]

  const rowData = [
    {
      sx: { width: 80 },
      children: <LabAndInvestigationsDate lab={lab}/>
    },
    {
      sx: { width: 104 },
      children: (
        <Typography
          lineclamp={1}
          variant={"p2"}
          color={(theme) => lab.isAbnormal ? theme.palette.error.main : "inherit"}
          sx={{ wordBreak: "break-all" }}
        >
          {lab.description ? lab.description : categoryLabel}
        </Typography>
      )
    },
    {
      sx: { width: 24, px: 0 },
      children: (
        <Box display={"inline-flex"} sx={{ verticalAlign: "middle" }}>
          {lab.isLab && lab.isAbnormal &&
            <WarningAmberIcon color={"error"} />
          }
        </Box>
      )
    },
    {
      sx: { width: 72 },
      children: (
        <Box color={(theme) => lab.isAbnormal ? theme.palette.error.main : "inherit"}>
          {lab.isLab && (
            <>
              {observationResultCount > 1
                ? <Typography lineclamp={1} variant={"p2"} fontStyle={"italic"} sx={{ wordBreak: "break-all" }}>Multiple values</Typography>
                : <Typography lineclamp={1} variant={"p2"} sx={{ wordBreak: "break-all" }}>
                    {firstObservationResult?.observationValue
                      ? firstObservationResult?.observationValue + (firstObservationResult?.unitsDescription ? " " + firstObservationResult?.unitsDescription : "")
                      : ""
                    }
                  </Typography>
              }
            </>
          )}
        </Box>
      )
    }
  ] as TableListCellConfig[];

  return {
    onClick: () => onRowClick(lab),
    rowData: rowData,
    key: `${lab.id!}_${lab.isLinkedDocument?'d':'l'}`
  }
}

interface LabAndInvestigationDateProps {
  lab: AbLabResult
}

const LabAndInvestigationsDate = ({lab}:LabAndInvestigationDateProps) => {
  const formattedDate = useFormattedDate(lab.date)

  return (
    <Typography
      variant={"body2"}
      color={(theme) => lab.isAbnormal ? theme.palette.error.main : "inherit"}
    >
      {formattedDate}
    </Typography>
  )
}