import { AllergiesIcon } from "../ChartIcons";
import {
  IconImageTooltip,
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from "saga-library/src";
import React, { useMemo, useState } from 'react'
import Widget from "../Widget";
import { Box } from "@mui/material";
import {
  AllergySeverityMap, AllergyType, getOnsetType
} from "../../../../types/Allergy";
import NewAllergyDialog from "../allergies/NewAllergyDialog";
import EditAllergyDialog from "../allergies/EditAllergyDialog";
import { useAllergyContext } from "../../providers/AllergyProvider";
import { useReadQuery } from "@apollo/client";

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' },
  { name: '' }
]

export const AllergyWidget = () => {
  const [ openDialog, setOpenDialog]  = useState(false)
  const [ allergy, setAllergy ] = useState<AllergyType | null>(null)

  const onRowClick = (allergy) => {
    setAllergy(allergy)
  }

  return <Widget title={'Allergies'} WidgetIcon={AllergiesIcon} onAddItem={() => { setOpenDialog(true) }} >
    <AllergyWidgetBody onRowClick={onRowClick} />
    <NewAllergyDialog open={openDialog} setOpen={setOpenDialog} />
    <EditAllergyDialog allergy={allergy} setAllergy={setAllergy} />
  </Widget>
}


const AllergyWidgetBody = ({ onRowClick }) => {
  const { allergyQueryRef, parseAllergyQueryResults } = useAllergyContext()
  const { data } = useReadQuery(allergyQueryRef!)
  const allergies = useMemo(()=> parseAllergyQueryResults(data), [data, parseAllergyQueryResults])

  return (
    <TableList
      dataTestId={'allergy-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        ...allergies.map((allergy) => AllergyRow(allergy, onRowClick))
      ]}
      sx={{ height: '100%', "&.MuiTableCell-root": { borderBottom: null } }}
      tableSx={{ tableLayout: 'fixed' }}
      emptyListComponent={{ message: "Click the add button to add an allergy.", size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      isWidget={true}
    />
  )
}


function AllergyRow(allergy: AllergyType, onRowClick) : TableListRowConfig {
  const rowData = [
    {
      children:
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={0}
        >
          <Typography lineclamp={1} variant={'p2'}>{allergy.allergen}</Typography>
          <Typography lineclamp={1} variant={'p3'}>{getOnsetType(allergy)}</Typography>
        </Box>,
      sx: { width: 'calc(50% - 8px)' }
    },
    {
      children: allergy.severity && (
        <IconImageTooltip title={AllergySeverityMap.get(allergy.severity)!.label}>
          {AllergySeverityMap.get(allergy.severity)!.icon}
        </IconImageTooltip>
      ),
      sx: { px: 0, width: '16px' }
    },
    {
      children: <Typography lineclamp={2} variant={'p2'}>{allergy.reactionDescription}</Typography>,
      sx: { width: 'calc(50% - 8px)' }
    }
  ] as TableListCellConfig[];

  return {
    onClick: () => onRowClick(allergy),
    rowData,
    key: allergy.id!
  }
}

