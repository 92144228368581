import React from 'react'
import { Select } from "saga-library/src";
import { InterceptReason } from "../../utils/ABClaimConstants";
import { MenuItem, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface InterceptReasonSelectProps {
  name?: string
  label?: string
  sx?: SxProps<Theme>
  disabled?: boolean
  inputLabelStyle?: object
}

export const InterceptReasonSelect = ({
  name = 'interceptReason',
  label = 'Intercept Reason',
  sx={},
  inputLabelStyle={},
  disabled = false
}: InterceptReasonSelectProps) => {
  return (
    <Select
      label={label}
      name={name}
      sx={sx}
      inputLabelStyle={inputLabelStyle}
      disabled={disabled}
    >
      <MenuItem value={''}>None</MenuItem>
      <MenuItem value={InterceptReason.HOLD}>Hold for pickup</MenuItem>
    </Select>
  )
}

export default InterceptReasonSelect

