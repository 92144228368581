import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { ControlledBillingProfileSelect } from "../../../../../components/BillingProfileSelect/BillingProfileSelect";

interface BatchControlledBillingProfileSelectProps {
  name?: string
  label?: string
  onSelect?: (billingProfile) => void
  autoFocus?: boolean
}

export const BatchControlledBillingProfileSelect = ({
  name,
  label,
  onSelect,
  autoFocus,
}: BatchControlledBillingProfileSelectProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <ControlledBillingProfileSelect
      name={name}
      label={label}
      onSelect={onSelect}
      autoFocus={autoFocus}
      sx={batchUpdateStyleSearch(dirtyFields.billingProfile)}
    />
  )
}