import React from 'react'
import { Box, FormGroup, Typography } from '@mui/material'
import { FieldErrors } from "saga-library/src/components/Form"
import { Role } from '../../../types/account'
import { CheckboxArray, TextField, Spinner, Form } from 'saga-library/src'
import { InviteChipInput } from "saga-library/src/components/ChipInput/ChipInput";
import FormLabel from '@mui/material/FormLabel'
import { getErrorMessage } from '../../patients/util/validation'
import { SettingsFormSection } from "./SettingsFormSection";

const labelStyle = {
  fontWeight: 600,
  color: 'black',
  mb: 1,
}

interface InviteFormProps {
  onSubmit: () => {}
  errors: FieldErrors
  roles: Role[]
}

export const InviteForm = ({
  onSubmit,
  errors,
  roles,
}: InviteFormProps) => {
  const roleItems = roles.map((role) => ({ id: role.id!, label: role.name! }))
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 1,
      }}
    >
      <Form onSubmit={onSubmit} autoComplete={'false'}>
        <SettingsFormSection
          sx={{
            border: '1px solid lightgray',
            borderRadius: '5px',
          }}
        >
          {(roles.length == 0) && <Spinner size={'sm'} sx={{ mt: 2 }} />}
          {(roles.length > 0) && (
            <CheckboxArray
              title={'Roles'}
              name={'roleIds'}
              sx={labelStyle}
              items={roleItems}
              helperText={getErrorMessage(errors, 'roleIds')}
              row
              dataTestId={"newInviteForm-roles-checkoxArray"}
            />
          )}
          <FormGroup sx={{ m: 3 }}>
              <FormLabel sx={labelStyle}>Email Addresses</FormLabel>
              <InviteChipInput
                name={'emailAddresses'}
              />
          </FormGroup>
        </SettingsFormSection>
        <SettingsFormSection sx={{ mb: 0 }}>
          <Typography sx={labelStyle}>Security Question</Typography>
          <TextField
            name={'question'}
            label={'Enter your question'}
            dataTestId={"newInviteForm-question"}
          />
          <TextField
            name={'passcode'}
            label={'Enter your answer'}
            dataTestId={"newInviteForm-passcode"}
          />
          <TextField
            name={'confirmAnswer'}
            label={'Re-enter answer'}
            dataTestId={"newInviteForm-confirmAnswer"}
          />
        </SettingsFormSection>
      </Form>
    </Box>
  )
}

export default InviteForm
