import { SearchBar, SearchField } from "saga-library/src";
import _get from "lodash/get";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SearchControlProps } from "saga-library/src/components/SearchControl/SearchControl";
import { useAccountContext } from "../../providers/AccountContext";
import {  Box } from "@mui/material";
import { ReferralPractitioner } from "../../types/settings/ReferralPractitioner";
import { REFERRAL_PRACTITIONER_SEARCH } from "../../graphql-definitions";


export interface ReferralPractitionerSearchBarProps<
  T = ReferralPractitioner,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<SearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'error'|'onChange'|'value'|'queries'|'render'> {
  dataTestId?: string
}

export const ReferralPractitionerSearchBar = ({
  inputRef,
  label = '',
  dataTestId = 'ReferralPractitionerSearchBar',
  ...props
}: ReferralPractitionerSearchBarProps) => {

  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id,
    activeOnly: false,
  }), [tenant_id])

  const resultOnClick = (event, result: ReferralPractitioner | null) => {
    if(result) {
      navigate(buildTenantRoute(
        `settings/practitioners/referral/r/${result.id}`, tenant_id
      ))
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        mb: 2
      }}
    >
      <SearchBar<ReferralPractitioner, false, false, false>
        {...props}
        label={label}
        dataTestId={dataTestId}
        open={false}
        onChange={resultOnClick}
        queries={{
          search: {
            gql: REFERRAL_PRACTITIONER_SEARCH,
            get: (data) => _get(data, 'tenant.search.referralPractitioner.searchResults', []) as ReferralPractitioner[],
            variables: queryVariables,
            fetchPolicy: 'network-only'
          }
        }}
        render={{
          value: (referralPractitioner) => '',
          option: (referralPractitioner, state) => (
            <></>
          ),
          endAdornment: (referralPractitioner) => (
            <></>
          )
        }}
        renderInput={(params) => (
          <SearchField
            {...params}
            placeholder={label}
            dataTestId={`${dataTestId}-textfield`}
          />
        )}
        sx={{ width: '415px' }}
      />
    </Box>
  )
}
