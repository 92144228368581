import { TableListCellConfig, TableListRowConfig, Typography } from "saga-library/src";
import { useAccountContext } from "../../../../providers/AccountContext";
import { useParams } from "react-router-dom";
import ChipBag from "saga-library/src/components/Chip/ChipBag";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";
import { Box, useTheme } from "@mui/material";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { LetterType } from "../../../../types/patients";
import { practitionerDisplayName } from "saga-library/src/util/formatting";

function FormatRowData(letter: LetterType, index: number): TableListCellConfig[] {
  const { buildTenantRoute } = useAccountContext()
  const theme = useTheme()

  const { tenant_id } = useParams()

  const { letterDate, title, practitioner, notes, recipients } = letter
  const letterDateFormatted = useFormattedDate(letterDate)
  const dataTestId = `letterRow-${index}`

  const onReferralPractitionerClicked = (event: React.MouseEvent<HTMLDivElement>, id: string) =>
  {
    window.open(buildTenantRoute(
      `settings/practitioners/referral/r/${id}`, tenant_id
    ))
  }

  return [
      {
        children:
          <Typography variant={"body2"} dataTestId={`${dataTestId}-date`}>
            {letterDateFormatted ?? ''}
          </Typography>
      },
      {
        children:
          <Typography lineclamp={1} variant={"body2"} dataTestId={`${dataTestId}-title`}>
            {title ?? ''}
          </Typography>
      },
      {
        children:
          <Typography variant={"body2"} dataTestId={`${dataTestId}-practitioner`}>
            {practitioner && practitionerDisplayName(practitioner.lastName, practitioner.firstName)}
          </Typography>
      },
      {
        children:
          <Typography lineclamp={1} variant={"body2"} dataTestId={`${dataTestId}-notes`}>
            {notes ?? ''}
          </Typography>
      },
      {
        children:
          recipients &&
          <Box display={'flex'} flexWrap={'nowrap'}>
            <ChipBag
              deleteIcon={<OpenInNewIcon/>}
              sx={{
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.greys.dark
                }
              }}
              onDelete={(event, id) => onReferralPractitionerClicked(event, id)}
              chips={recipients?.map(recipient => ({
                id: recipient?.id,
                label: `${recipient?.lastName + ', ' + recipient?.firstName}`
              }))}
              dataTestId={`${dataTestId}-recipients`}
            />
          </Box>
      }
  ]
}


export const PatientLetterRow = (letter: LetterType, onRowClicked: (letter: LetterType) => void, index: number): TableListRowConfig => {
  if(letter) {
    return ({
      onClick: ()=>onRowClicked(letter),
      rowData: FormatRowData(letter, index),
      key: `${letter.id!}_${letter.isLinkedDocument?'d':'l'}`
    })
  }
  return {rowData: [], key: ""}
}