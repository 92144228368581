import React, { useState } from 'react'
import {
  TableList,
  TableListHeaderConfig,
} from 'saga-library/src'
import { Box } from '@mui/material'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import { useNavigate } from 'react-router-dom'
import { ReferralPractitionerRow } from './ReferralPractitioners/ReferralPractitionerRow'
import MuiSearchIcon from '@mui/icons-material/Search'
import { ReferralPractitionerSearchBar } from "../../../components/ReferralPractitionerSearchBar/ReferralPractitionerSearchBar";
import { ReferralPractitioner } from "../../../types/settings";

const columnsHeaders: TableListHeaderConfig[] = [
  { name: 'Name' },
  { name: 'Practitioner ID' },
  { name: 'Skills' },
]

export const PractitionersReferral = () => {
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState<
    readonly ReferralPractitioner[]
  >([])
  const [searchParam, setSearchParam] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <ReferralPractitionerSearchBar
        label={'Search by name, practitioner ID, or skill'}
        onOptionsChanged={(options) => {
          setSearchResults(options)
        }}
        onInputChange={(event, input, reason) => {
          setSearchParam(input)
        }}
        onLoading={setLoading}
      />
      <TableList
        size={'small'}
        loading={loading}
        showHeader={false}
        columns={columnsHeaders}
        rows={[
          ...searchResults?.map((practitioner) =>
            ReferralPractitionerRow(practitioner, searchParam, navigate)
          ),
        ]}
        emptyListComponent={
          searchParam ?
            {
              message: <>We couldn't find any matches for <b>{searchParam}</b>.</>,
              icon: MuiSearchIcon
            }
           :
            {
              message: "Find a referral practitioner using the above search, or add a new one.",
              icon: BadgeOutlinedIcon
            }
        }
        dataTestId={'practitionersReferral-tableList'}
        leftAlignColumns={true}
      />
    </Box>
  )
}