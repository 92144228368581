import { Box } from "@mui/material";
import { WEEKDAYSMAP } from "../../../utils/ScheduleConstants";
import React from "react";
import { useFormContext } from "saga-library/src/components/Form";
import { ScheduleTimeSelect } from "../../../components/ScheduleTimeSelect";
import { Checkbox, Typography } from "saga-library/src";
import { defaultClearDay } from "../Templates/TemplateForm";

export interface WeekAvailabilityProps {
  updateBlockedTimes: (isOpen: boolean, start: string, end: string, day: number) => void
  dataTestId?: string
}

export const WeekAvailability = ( {
  updateBlockedTimes,
  dataTestId
}: WeekAvailabilityProps ) => {
  const { getValues} = useFormContext()

  return <Box
    display={'flex'}
    sx={{
      alignItems: 'flex-start',
      mb: 2,
      gap: '16px'
    }}
  >
    {
      WEEKDAYSMAP.map((day, i) =>  {
      const isOpen: boolean = getValues(`templateDays.${i}.isOpen`)

      return <DayAvailability
        dataTestId={`${dataTestId}-day-${i}`}
        label={day[1]}
        name={`templateDays.${i}`}
        day={i}
        key={day[1] + `_availability`}
        updateBlockedTimes={updateBlockedTimes}
        isOpen={isOpen}
      />
    })
    }
  </Box>
}


interface DayAvailabilityProps {
  isOpen: boolean
  label: string
  name: string
  day: number
  updateBlockedTimes: (isOpen: boolean, start: string, end: string, day: number) => void
  dataTestId?: string
}

export const DayAvailability = ({
  isOpen,
  label,
  name,
  day,
  updateBlockedTimes,
  dataTestId
}: DayAvailabilityProps) => {
  const { getValues, setValue, resetField } = useFormContext()
  const onChangeClear = defaultClearDay(day)

  const StartEndTime = (start, end) => {
    const onChange = (e) => {
      updateBlockedTimes(true, getValues(`${name}.startTime`), getValues(`${name}.endTime`), day)
    }

    if (isOpen) {
      return(
        <>
          <ScheduleTimeSelect dataTestId={`${dataTestId}-startTime`} name={`${name}.startTime`} label={'Start time'} onChange={onChange}/>
          <ScheduleTimeSelect dataTestId={`${dataTestId}-endTime`} name={`${name}.endTime`} label={'End time'} onChange={onChange}/>
        </>
      )
    }
    return (
      <Typography dataTestId={`${dataTestId}-noAvailableHours`} sx={{mt: 1, textAlign: 'center'}} variant={'p4'}>No available hours</Typography>
    )
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: "100px"
      }}
    >
      <Checkbox
        dataTestId={`${dataTestId}-checkbox`}
        name={`${name}.isOpen`}
        label={<Typography variant={'p4'} fontWeight={'700'} sx={{ textTransform: 'capitalize' }}>{label}</Typography>}
        labelPlacement={'bottom'}
        onSelect={() => {
          const checked = getValues(`${name}.isOpen`)
          if (checked) {
            resetField(`${name}`)
          } else {
            setValue(`${name}`, onChangeClear)
          }
          updateBlockedTimes(checked, getValues(`${name}.startTime`), getValues(`${name}.endTime`), day)
        }}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ width: '100%' }}
      >
        <StartEndTime name={name}/>
      </Box>
    </Box>
  )
}