import React from "react";
import { LinkedDocumentType } from "../../../../types/Document";
import { DocumentViewer } from "../../../../components/StandaloneDocumentViewer";
import { Form } from "saga-library/src";
import { useReviewDocumentStateContext } from '../../providers/ReviewDocumentStateProvider'

interface DocumentResultFormProps {
  document?: LinkedDocumentType
  onSubmit: () => void
  formName: string
}
export const DocumentResultForm = ({
  document,
  formName,
  onSubmit,
}: DocumentResultFormProps) => {
  const documentStateContext = useReviewDocumentStateContext()

  return (
    <Form id={formName} onSubmit={onSubmit}>
      {document?.file && <DocumentViewer
        fileRecord={document.file}
        sx={{
          flex: "1 1 100%",
          overflow:"hidden"
        }}
        documentStateContext={documentStateContext}
      />}
    </Form>
  )
}