import React, { useCallback, useEffect } from "react";
import { Section } from "saga-library/src";
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useImportedDocumentsStateContext } from "./ImportedDocumentsStateProvider";
import { useDocumentContext } from "../../../../providers/DocumentProvider";
import { FoxitPDFViewer } from "../../../../components/FoxitPDFViewer";
import {
  DataEvents,
  PDFViewer,
  ViewerEvents
} from "../../../../components/FoxitPDFViewer/FoxitTypes";
import { usePDFViewerEvent } from "../../../../components/FoxitPDFViewer/UsePDFViewerEvent";


interface DocumentViewProps {
  sx?: SxProps<Theme>
}

export const DocumentView = ({sx} : DocumentViewProps) => {
  const { selectedFile, setDocumentModified, pdfDocRef, pdfViewerRef, pdfUIRef, loadingFile, setLoadingFile } = useImportedDocumentsStateContext()
  const { getFileTypeUriWithToken } = useDocumentContext()

  const documentOpenedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    setDocumentModified(false)
  }, [setDocumentModified])

  const documentModifiedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    setDocumentModified(true)
  }, [setDocumentModified])

  const openFileFailedCallback = useCallback((pdfViewer : PDFViewer, ...args : any[]) => {
    setLoadingFile(false)
  }, [setLoadingFile])

  usePDFViewerEvent(DataEvents.docModified, documentModifiedCallback, pdfViewerRef)
  usePDFViewerEvent(ViewerEvents.beforeOpenFile, documentOpenedCallback, pdfViewerRef)
  usePDFViewerEvent(ViewerEvents.openFileFailed, openFileFailedCallback, pdfViewerRef)

  useEffect(() => {
    const pdfui = pdfUIRef?.current
    if (!pdfui) return
    if (!selectedFile) return

    async function getUriAndToken() {
      return await getFileTypeUriWithToken(selectedFile)
    }

    setLoadingFile(true)

    getUriAndToken()
      .then(async (uri) => {
        if (!uri) return

        console.log('uri', uri)

        const doc = await pdfui.openPDFByHttpRangeRequest({
          range: {
            url: uri,
          },
        })
        if (!doc) {
          console.error('error opening pdf')
          return
        }

        if (pdfDocRef) pdfDocRef.current = doc

      })
      .catch((error) => {
        console.error('error getting file uri and token', error)
      })
      .finally(() => {
        setLoadingFile(false)
      })
  }, [selectedFile, getFileTypeUriWithToken, pdfDocRef, setLoadingFile])

  return (
    <Section.Column sx={sx} preventScroll={true}>
      <FoxitPDFViewer pdfuiRef={pdfUIRef} pdfViewerRef={pdfViewerRef} loading={loadingFile} selectedFile={selectedFile} variant={"document"}/>
    </Section.Column>
  )
}




