import React from 'react'
import { Outlet } from "react-router-dom";
import { NavigationTabRoute } from '../../../../components/NavigationTabRoute'
import { NavigationTabs } from 'saga-library/src'
import { PermissionType } from "../../../../types/settings/Permission";
import { PermissionForm } from "../../../../components/PermissionForm";
import { DocumentProvider } from "../../../../providers/DocumentProvider";

interface PractitionerFormProps {
  onSubmit: () => void
  formName: string
}

export const PractitionerForm = ({
  onSubmit,
  formName
}: PractitionerFormProps) => {

  const navRoutes = [
    <NavigationTabRoute route={''} label={'GENERAL'} />,
    <NavigationTabRoute
      route={'billing'}
      label={'BILLING'}
      applyToChildren={true}
    />,
    <NavigationTabRoute route={'schedules'} label={'SCHEDULES'} />
  ]

  return (
    <DocumentProvider>
      <PermissionForm
        onSubmit={onSubmit}
        sx={{
          height: '100%',
          flex: "1 1 auto",
          display:"flex",
          flexDirection: "column",
          overflow: "auto"
        }}
        requiredPermissionType={PermissionType.Practitioner}
        readOnlyOverride={true}
        name={formName}
        id={formName}
      >
        <NavigationTabs routes={navRoutes} sx={{ p: '0 0 1 2' }} />
        <Outlet />
      </PermissionForm>
    </DocumentProvider>
  )
}

export default PractitionerForm
