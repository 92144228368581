import React from 'react'
import {
  ResultOptionsWrapper,
  EntryDescription,
  EntryTitle
} from "saga-library/src";
import { AbClaimDiagnosticCode } from "../../../types/billing/AbClaim/AbClaimDiagnosticCode";

interface DiagnosticCodeResultProps {
  diagnosticCode: AbClaimDiagnosticCode
  searchText: string
}

export const DiagnosticCodeResult = ({
  diagnosticCode,
  searchText
}: DiagnosticCodeResultProps) => {
  return (
    <ResultOptionsWrapper>
      <EntryTitle
        title={diagnosticCode.diagnosticCode}
        searchText={searchText}
      />
      <EntryDescription
        text={diagnosticCode.description}
        searchText={searchText}
      />
    </ResultOptionsWrapper>
  )
}

export default DiagnosticCodeResult
