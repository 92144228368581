import React, { useEffect, useState } from "react";
import { TextField, Section, PhoneComponent, AddButton, PhoneType } from 'saga-library/src'
import { rules } from '../util/validation'
import { useFormContext } from "saga-library/src/components/Form";
import { FormFragment } from "../../../../components/PermissionForm";

export type ContactDetailsType = {
  email: string
  phone: PhoneType | null
}

const defaults = {
  email: '',
  phone: null,
}

const Form = ({requiredPermissionType}) => {
  const { getValues, setValue } = useFormContext()
  const [hasPhone, setHasPhone] = useState(getValues('phone') != null)

  useEffect(() => {
    setHasPhone(getValues('phone') != null)
  }, [getValues('phone')])

  const removeContactRow = () => {
    setValue('phone', defaults.phone)
    setHasPhone(false)
  }

  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Contact</Section.Header>
        <TextField
          label={'Email address'}
          name={'email'}
          inputProps={{ maxLength: rules.email.max }}
          dataTestId={"contactDetails-email"}
        />
        {hasPhone ? (
          <PhoneComponent
            name={'phone'}
            type={getValues('phone.type')}
            index={0}
            isPrimary={true}
            removeContactRow={removeContactRow}
            dataTestId={"contactDetails-phone"}
          />
        ) : (
          <AddButton
            label={'Add phone number'}
            onClick={() => setHasPhone(true)}
            dataTestId={"contactDetails-phone"}
          />
        )}
      </Section.FormSection>
    </FormFragment>
  )
}

export const ContactDetails = {
  Form: Form,
  defaults: defaults,
}
