import AppSetupIcon from "@mui/icons-material/AdminPanelSettings";
import GeneralIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import UsersIcon from "@mui/icons-material/Group";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import ReportsIcon from "@mui/icons-material/InsertChartOutlined";
import LocationIcon from "@mui/icons-material/StoreOutlined";
import ScheduleIcon from "@mui/icons-material/CalendarMonthOutlined"
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacyOutlined';
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { NavLink } from "react-router-dom";
import React from "react";
import { SubsectionMenu, SubsectionMenuItem } from "saga-library/src";
import { ReactComponent as ChartingIcon} from "../assets/ChartingIcon.svg";

export const TenantSettingsMenu = () => {
  return (
    <SubsectionMenu
      sx={{ marginBottom: '0px' }}
      routes={[
        <TenantSettingsRoute
          route={'general'}
          text={'General'}
          icon={<GeneralIcon />}
        />,

        <TenantSettingsRoute
          route={'locations'}
          text={'Locations'}
          icon={<LocationIcon />}
        />,

        <TenantSettingsRoute
          route={'setup'}
          text={'App setup'}
          icon={<SettingsIcon />}
        />,

        <TenantSettingsRoute
          route={'roles'}
          text={'Roles and permissions'}
          icon={<AppSetupIcon />}
        />,

        <TenantSettingsRoute
          route={'users'}
          text={'Users'}
          icon={<UsersIcon />}
        />,

        <TenantSettingsRoute
          route={'practitioners'}
          text={'Practitioners'}
          icon={<BadgeOutlinedIcon />}
        />,

        <TenantSettingsRoute
          route={'charting'}
          text={'Charting'}
          icon={<ChartingIcon />}
        />,

        <TenantSettingsRoute
          route={'schedule'}
          text={'Schedule'}
          icon={<ScheduleIcon />}
        />,

        <TenantSettingsRoute
          route={'tasks'}
          text={'Tasks'}
          icon={<AssignmentTurnedInIcon />}
        />,

        <TenantSettingsRoute
          route={'billing'}
          text={'Billing'}
          icon={<ReceiptLong />}
        />,

        <TenantSettingsRoute
          route={'reports'}
          text={'Reports'}
          icon={<ReportsIcon />}
        />,

        <TenantSettingsRoute
          route={'pharmacies'}
          text={'Pharmacies'}
          icon={<LocalPharmacyIcon />}
        />,
      ]}
    />
  )
}

const TenantSettingsRoute = ({ route, text, icon }) => {
  return (
    <NavLink to={route}>
      {({ isActive }) => (
        <SubsectionMenuItem
          dataTestId={`tenantSettingsMenu-menuItem-${text}`}
          isActive={isActive}
          startIcon={icon}
          primaryText={text}
        />
      )}
    </NavLink>
  )
}

export default TenantSettingsMenu
