import React from 'react'
import SignInForm from '../apps/account/Components/SignInForm'
import { Box } from '@mui/material'

export const SignIn = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Box padding={2}>
        <SignInForm></SignInForm>
      </Box>
    </div>
  )
}
