import { bmi2to19, headcircumferenceWeight0to2, heightWeight2to19, lengthWeight0to2 } from './growthChartUtil'

export enum WeightUnits {
  KG = "kg",
  LBS = "lbs",
}

export enum LengthHeightUnits {
  CM = "cm",
  INCH = "inch",
}

export enum LengthHeightType {
  HEIGHT = "HEIGHT",
  LENGTH = "LENGTH",
}

export type Vital = {
  id?: string
  bloodPressureSystolic?: number | null
  bloodPressureDiastolic?: number | null
  heartRate?: number | null
  weight?: number | null
  weightUnit?: WeightUnits
  lengthHeight?: number | null
  lengthHeightUnit?: LengthHeightUnits
  lengthHeightType?: LengthHeightType
  headCircumference?: number | null
  headCircumferenceUnit?: LengthHeightUnits
  crePit?: string | null
}

export type PatientVitalDetailsType = {
  vitals?: Vital[]
}


export enum VitalsType {
  BLOOD_PRESSURE = 'Blood pressure',
  HEART_RATE = 'Heart rate',
  HC_BOYS = 'Head circumference and weight for length (0-24 months)_BOYS',
  HC_GIRLS = 'Head circumference and weight for length (0-24 months)_GIRLS',
  HEIGHT = 'Height',
  WEIGHT = 'Weight',
  LENGTH_GIRLS = 'Length for age and weight for age (0-24 months)_GIRLS',
  LENGTH_BOYS = 'Length for age and weight for age (0-24 months)_BOYS',
  HW_GIRLS = 'Height for age and weight for age (2-19 years)_GIRLS',
  HW_BOYS = 'Height for age and weight for age (2-19 years)_BOYS',
  BMI = 'BMI',
  BMI_BOYS = 'BMI (2-19 years)_BOYS',
  BMI_GIRLS = 'BMI (2-19 years)_GIRLS'
}

export const VitalGraphingMap: Map<VitalsType, {fileName: string, coordinates?: any}> = new Map ([
  [VitalsType.HC_BOYS, {fileName: "boys_upto2yrhc.pdf", coordinates: headcircumferenceWeight0to2('boys')}],
  [VitalsType.HC_GIRLS, {fileName: "girls_upto2yrhc.pdf", coordinates: headcircumferenceWeight0to2('girls')}],
  [VitalsType.LENGTH_BOYS, {fileName: "boys_upto2yr.pdf", coordinates: lengthWeight0to2()}],
  [VitalsType.LENGTH_GIRLS, {fileName: "girls_upto2yr.pdf", coordinates: lengthWeight0to2()}],
  [VitalsType.HW_BOYS, {fileName: "boys_2to19.pdf", coordinates: heightWeight2to19('boys')}],
  [VitalsType.HW_GIRLS, {fileName: "girls_2to19.pdf", coordinates: heightWeight2to19('girls')}],
  [VitalsType.BMI_BOYS, {fileName: "boys_2to19bmi.pdf", coordinates: bmi2to19('boys')}],
  [VitalsType.BMI_GIRLS, {fileName: "girls_2to19bmi.pdf", coordinates: bmi2to19('girls')}],
])