import moment from "moment";

export const addDurationToDate = (duration, date, ignoreDST = false) => {
  const currentDate = moment(date)
  const updatedDate = moment(currentDate).add(moment.duration(duration).asSeconds(), 'seconds')
  if (ignoreDST) return updatedDate
  return adjustDateForDST(currentDate, updatedDate)
}

export const diffDates = (startDate, endDate, units = 'milliseconds', ignoreDST = false) => {
  const date1 = moment(startDate)
  let date2 = moment(endDate)
  if (!ignoreDST) date2 = adjustDateForDST(date1, date2)
  return date2.diff(date1, units as moment.unitOfTime.Diff)
}

const adjustDateForDST = (startDate, endDate) => {
  const date1 = moment(startDate)
  const date2 = moment(endDate)
  if (date1.isDST() && !date2.isDST()) {
    return date2.add(1, 'hours')
  }
  if (!date1.isDST() && date2.isDST()) {
    return date2.subtract(1, 'hours')
  }
  return date2
}