export type AbLabResultPersonBase<T> = {
  id: string
  type?: T
  firstName?: string
  lastName?: string
}

export type AbLabResultObservationRequestPerson = AbLabResultPersonBase<AbLabResultObservationRequestPersonType>
export type AbLabResultOrderPerson = AbLabResultPersonBase<AbLabResultOrderPersonType>
export type AbLabResultTranscriptionPerson = AbLabResultPersonBase<AbLabResultTranscriptionPersonType>

export enum AbLabResultObservationRequestPersonType {
  ORDERING_PROVIDER = "ORDERING_PROVIDER",
  RESULT_COPIES_TO = "RESULT_COPIES_TO",
  PRINCIPAL_INTERPRETER = "PRINCIPAL_INTERPRETER",
  ASSISTANT_INTERPRETER = "ASSISTANT_INTERPRETER",
  TECHNICIAN = "TECHNICIAN",
  TRANSCRIPTIONIST = "TRANSCRIPTIONIST"
}

export enum AbLabResultOrderPersonType {
  ENTERED_BY = "ENTERED_BY",
  ORDERING_PROVIDER = "ORDERING_PROVIDER"
}

export enum AbLabResultPatientVisitPersonType {
  ATTENDING_DOCTOR = "ATTENDING_DOCTOR",
  REFERRING_DOCTOR = "REFERRING_DOCTOR",
  CONSULTING_DOCTOR = "CONSULTING_DOCTOR",
  ADMITTING_DOCTOR = "ADMITTING_DOCTOR"
}

export enum AbLabResultPersonType {
  EVENT_OPERATOR = "EVENT_OPERATOR",
  PATIENT_PRIMARY_PROVIDER = "PATIENT_PRIMARY_PROVIDER"
}

export enum AbLabResultTranscriptionPersonType {
  AUTHENTICATOR = "AUTHENTICATOR",
  DISTRIBUTED_COPIES = "DISTRIBUTED_COPIES",
  PRIMARY_ACTIVITY_PROVIDER = "PRIMARY_ACTIVITY_PROVIDER",
  ORIGINATOR = "ORIGINATOR",
  ASSIGNED_DOCUMENT_AUTHENTICATOR = "ASSIGNED_DOCUMENT_AUTHENTICATOR",
  TRANSCRIPTIONIST = "TRANSCRIPTIONIST"
}