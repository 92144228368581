import React, { createRef, useCallback, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import _get from "lodash/get";
import {
  GET_UNLINKED_FILES,
} from "../../../../graphql-definitions";
import { FileType } from "../../../../types/tasks";
import { useParams } from "react-router-dom";
import { FileWithProgress } from "../../../../types/tasks";
import { PDFDoc, PDFViewer, PDFUI } from "../../../../components/FoxitPDFViewer/FoxitTypes";

interface ImportedDocumentsStateContextType {
  refreshUnlinkedDocuments: () => any,
  refreshLinkedDocuments: () => any,
  unlinkedDocuments: FileType[],
  linkedDocuments: any[],
  pendingFiles: FileWithProgress[],
  setPendingFiles: (files: FileWithProgress[]) => void,
  selectedFile: FileType | null,
  setSelectedFile: (file: FileType | null) => void,
  documentModified: boolean,
  setDocumentModified: (modified: boolean) => void,
  pdfDocRef?: React.MutableRefObject<PDFDoc | null>,
  pdfViewerRef?: React.MutableRefObject<PDFViewer | null>,
  pdfUIRef?: React.MutableRefObject<PDFUI | null>,
  loadingFile: boolean,
  setLoadingFile: (loading: boolean) => void,
}

const defaultImportedDocumentsContext: ImportedDocumentsStateContextType = {
  refreshUnlinkedDocuments: () => [],
  refreshLinkedDocuments: () => [],
  unlinkedDocuments: [],
  linkedDocuments: [],
  pendingFiles: [],
  setPendingFiles: () => {},
  selectedFile: null,
  setSelectedFile: () => {},
  documentModified: false,
  setDocumentModified: () => {},
  pdfDocRef: createRef(),
  pdfViewerRef: createRef(),
  pdfUIRef: createRef(),
  loadingFile: false,
  setLoadingFile: () => {},
}

const ImportedDocumentsStateContext = React.createContext(defaultImportedDocumentsContext)

export const ImportedDocumentsStateProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const [unlinkedDocuments, setUnlinkedDocuments] = useState<FileType[]>([])
  const [linkedDocuments, setLinkedDocuments] = useState<any[]>([])
  const [pendingFiles, setPendingFiles] = React.useState<FileWithProgress[]>([])
  const [selectedFile, setSelectedFile] = React.useState<FileType | null>(null)
  const [documentModified, setDocumentModified] = React.useState<boolean>(false);
  const pdfDocRef = useRef<PDFDoc | null>(null)
  const pdfViewerRef = useRef<PDFViewer| null>(null)
  const pdfUIRef = useRef<PDFUI| null>(null)
  const [loadingFile, setLoadingFile] = React.useState<boolean>(false)

  const [refreshUnlinkedDocuments] = useLazyQuery(GET_UNLINKED_FILES,
    {
      variables: { tenantId: tenant_id },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        setUnlinkedDocuments(_get(data, 'tenant.file.unlinkedFiles', []))
      }
    },
  )

  const refreshLinkedDocuments = useCallback(() => {
    return linkedDocuments 
  }, [linkedDocuments])

  const providerValue : ImportedDocumentsStateContextType = {
    refreshUnlinkedDocuments: refreshUnlinkedDocuments,
    refreshLinkedDocuments: refreshLinkedDocuments,
    unlinkedDocuments,
    linkedDocuments,
    pendingFiles,
    setPendingFiles,
    selectedFile,
    setSelectedFile,
    documentModified,
    setDocumentModified,
    pdfDocRef,
    pdfViewerRef,
    pdfUIRef,
    loadingFile,
    setLoadingFile,
  }

  return (
    <ImportedDocumentsStateContext.Provider value={providerValue}>
      {children}
    </ImportedDocumentsStateContext.Provider>
  )
}

export const useImportedDocumentsStateContext = () => {
  return React.useContext(ImportedDocumentsStateContext)
}
