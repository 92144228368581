import React from 'react'
import { Box, Chip as MuiChip } from '@mui/material'
import { styled } from '@mui/system'
import { TableListRowConfig, Typography } from "saga-library/src";
import { TextHighlighting } from 'saga-library/src'
import { SearchTermMatcher } from 'saga-library/src/components/SearchControl/TextHighlighting'
import { formatPractitionerId } from "../../../../components/PractitionerMaskedTextField";

const Chip = styled(MuiChip, { name: 'filled' })(({ theme, variant }) => ({
  margin: 1,
}))

export const ReferralPractitionerRow = (
  practitioner,
  searchParam,
  navigate
): TableListRowConfig => {
  return {
    onClick: () => navigate(`r/${practitioner.id}`),
    key: practitioner.id,
    rowData: [
      {
        children: <Box height={'100%'}>
          <Box key={'name-' + practitioner.id}>
            <Typography variant={'subtitle1'}>
              <TextHighlighting
                text={practitioner.lastName + ', ' + practitioner.firstName}
                highlight={searchParam}
              />
              {!practitioner.active ? ' (Inactive)' : ''}
            </Typography>
          </Box>
          <Box key={'pracid-' + practitioner.id}>
            <Typography variant={'body1'} color={'grey'}>
              <TextHighlighting
                text={formatPractitionerId(practitioner.practitionerId)}
                highlight={searchParam}
              />
            </Typography>
          </Box>
          <Box key={'discipline-' + practitioner.id}>
            <Typography variant={'body1'} color={'grey'}>
              {practitioner.disciplineDescription}
            </Typography>
          </Box>
        </Box>
      },
      {
        children: (
          <PractitionerSkills
            practitioner={practitioner}
            searchParam={searchParam}
          />
        ),
      },
      {
        children: [
          <Box key={'label-' + practitioner.id}>
            <Typography variant={'body1'}>{practitioner.label}</Typography>
          </Box>,
          <Box key={'street1-' + practitioner.id}>
            <Typography variant={'body1'}>{practitioner.street1}</Typography>
          </Box>,
          <Box key={'city-' + practitioner.id}>
            <Typography variant={'body1'}>
              {practitioner.city? practitioner.city + ', ' : null}{practitioner.province}
            </Typography>
          </Box>
        ]
      }
    ]
  }
}

const PractitionerSkills = ({ practitioner, searchParam }) => {
  return practitioner.skills.map((skill, i) => {
    const [regex] = SearchTermMatcher(skill.description, searchParam)
    return (
      <Chip
        key={practitioner.id+'-'+skill.id}
        label={skill.description}
        color={regex.test(skill.description) ? 'primary' : 'default'}
      />
    )
  })
}
