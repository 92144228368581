import React, { useEffect, useState } from 'react'
import { DialogV2, Tabs } from "saga-library/src";
import { NewAppointment } from "../Appointment/NewAppointment";
import { NewEvent } from "./NewEvent";
import { NewBookingPreference } from "../BookingPreference/NewBookingPreference";
import { getAppointmentFormattedTime, getAppointmentTitle } from '../../util/appointmentDialogFunctions'
import { usePrompt } from '../../../../providers/NavigationPrompt'

const DATA_TEST_ID = "newEvent"

export interface NewEventDialogProps {
  open: boolean
  onClose: () => void
  scheduleId: string
  practitionerName: string | undefined | null
  eventData: any
}

export const NewEventDialog = ({
  open,
  onClose,
  scheduleId,
  practitionerName,
  eventData
}: NewEventDialogProps) => {
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()
  const [formName, setFormName] = useState<string>("")
  const [dirtyFields, setDirtyFields] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, formName)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onFormChange = (name: string, submitting: boolean, changedFields: any) => {
    setFormName(name)
    setIsSubmitting(submitting)
    setDirtyFields(changedFields)
  }

  if(!open || !eventData){
    return null
  }

  const showBookingPreference = (eventData && !eventData.bookingPreferenceTypeIds)

  const optionsNoBookingPreference = [
    {
      label: 'PATIENT VISIT',
      content: (
        <NewAppointment
          scheduleId={scheduleId}
          onClose={onClose}
          onFormChange={onFormChange}
          eventData={eventData}
        />
      ),
    },
    {
      label: 'EVENT',
      content: (
        <NewEvent
          eventData={eventData}
          scheduleId={scheduleId}
          onClose={onClose}
          onFormChange={onFormChange}
        />
      ),
    },
  ]

  const allOptions = [
    ...optionsNoBookingPreference,
    {
      label: 'BOOKING PREFERENCE',
      content: (
        <NewBookingPreference
          eventData={eventData}
          scheduleId={scheduleId}
          onClose={onClose}
          onFormChange={onFormChange}
        />
      ),
    },
  ]

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: eventData?.start,
        end: eventData?.end,
        practitionerName: practitionerName
      })}
      subtitle={getAppointmentFormattedTime(eventData?.start, eventData?.end)}
      formName={formName}
      submitting={isSubmitting}
      contentSx={{ mt: 1 }}
      dataTestId={DATA_TEST_ID}
    >
      <Tabs dataTestId={`${DATA_TEST_ID}-type`} options={showBookingPreference ? allOptions : optionsNoBookingPreference} variant={'contained'} centered={true} />
    </DialogV2>
  )
}