import { Select } from "saga-library/src";
import { MenuItem } from "@mui/material";
import React from "react";
import { ScheduleType } from "../types/schedule";
import { practitionerDisplayName, practitionerLocationDisplayName } from "saga-library/src/util/formatting";
import { useLocationContext } from "../providers/LocationContextProvider";

interface ScheduleIdSelectProps {
  name: string
  label: string
  schedules?: Array<ScheduleType>
  disabled?: boolean,
  showLocation?: boolean
  onChange?: (any)=>void
  dataTestId?: string
}

export const ScheduleIdSelect = ({
  name,
  label,
  schedules,
  disabled = false,
  onChange,
  dataTestId
}: ScheduleIdSelectProps) => {
  const { getLocationName } = useLocationContext()

  return (
    <Select
      dataTestId={dataTestId}
      label={label}
      name={name}
      disabled={disabled}
      allowNullOption={false}
      onChange={onChange}
      renderValue={(v) => {
        if(v==="placeholder"){
          return <em>Select a practitioner</em>
        } else {
          const schedule = schedules?.find(s => s.id === v)
          if(schedule) {
            return practitionerLocationDisplayName(schedule.practitionerFirstName, schedule.practitionerLastName, getLocationName(schedule.locationId))
          }
        }
      }}
    >
      {schedules && schedules.length >= 0 && <MenuItem disabled value={"placeholder"} sx={{display:"none"}}>Select a practitioner</MenuItem>}
      {schedules && schedules.map(({ id, practitionerFirstName, practitionerLastName, locationId}, index) =>
        <MenuItem
          data-testid={`${dataTestId}-menuItem-${index}`}
          key={`schedule_item_${locationId}_${id}`}
          value={id}
        >
          { practitionerLocationDisplayName(practitionerFirstName, practitionerLastName, getLocationName(locationId)) }
        </MenuItem>
      )}
    </Select>
  )
}