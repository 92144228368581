import { AbLabResult, AbLabResultObservationResult, Gender } from '../../../../types/patients'
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import moment from 'moment-timezone'
import { NewPatientDefaults } from '../../../../components/SearchControls/Patient/PatientModal'
import { PhoneEnumType } from 'saga-library/src/components/Phone/PhoneType'
import { IdentifierType } from '../../PatientTypes'
import { PostalCodeValidation } from 'saga-library/src/components/PostalCodeField'
import { PhoneValidation } from 'saga-library/src/components/PhoneField'
import { capitalizedWords } from '../../../../utils/StringHelpers'

export const isCCLabResult = (lab: AbLabResult): boolean => {
  if (!lab.sourceType) {
    return false
  }
  return ["CCLAB", "CCIMG", "CCDOC", "CCENDO", "CCCARD", "CCCOMM"].includes(lab.sourceType)
}

export const getLabAndInvestigationLabel = (lab: AbLabResult): string => {
  const categoryLabel = lab?.isLab ? "Lab result" : "Investigation";
  return `${lab?.description ? `${lab?.description}` : categoryLabel}`
}

export const getLabAndInvestigationDocumentLabel = (document: LinkedDocumentType): string => {
  const categoryLabel = document.category === DocumentCategory.LAB_RESULT ?
    "Lab result" :
    "Investigation";
  return `${document.description ? `${document.description}` : categoryLabel}`
}

export const filterResultsForList = (lab: AbLabResult): AbLabResult => {
  return {
    ...lab,
    abLabResultObservationResults: lab.abLabResultObservationResults.filter((r: AbLabResultObservationResult) => {
      return (
        (!!r.observationValue
          && !!r.referenceRange
          && r.valueType?.subset === "0125"
          && ["NM", "ST", "FT"].includes(r.valueType?.code))
        || r.observationValue === "Embedded PDF"
      )
    }),
    abLabResultNoteAndComments: []
  }
}

// current known formats for referenceRanges:
// 1.0-2.0
// <1.0
// >1.0
// non-numeric text
export const parseReferenceRange = (referenceRange: string): number[] => {
  const isInvalid = (val: number) => val === null || val === undefined || isNaN(val)

  // less than
  if (referenceRange.substring(0, 1) === "<") {
    const rangeEnd = Number.parseFloat(referenceRange.substring(1))
    if (isInvalid(rangeEnd)) {
      return []
    }
    return [Number.NEGATIVE_INFINITY, rangeEnd]
  }

  // greater than
  if (referenceRange.substring(0, 1) === ">") {
    const rangeStart = Number.parseFloat(referenceRange.substring(1))
    if (isInvalid(rangeStart)) {
      return []
    }
    return [rangeStart, Number.POSITIVE_INFINITY]
  }

  // range

  // extract the first number as a string
  const firstNumber = referenceRange.match(/[-+]?[0-9]*\.?[0-9]+/)?.[0]
  if (firstNumber === null || firstNumber === undefined) {
    return []
  }

  // convert the first number to a float
  const rangeStart = Number.parseFloat(firstNumber)
  if (isInvalid(rangeStart)) {
    return []
  }

  // remove the first number from the reference range
  const remainingReferenceRange = referenceRange.replace(firstNumber, "")
  if (remainingReferenceRange === referenceRange) {
    return []
  }

  // parse the second number
  if (remainingReferenceRange.substring(0, 1) === "-") {
    const rangeEnd = Number.parseFloat(remainingReferenceRange.substring(1))
    if (isInvalid(rangeEnd)) {
      return []
    }
    return [rangeStart, rangeEnd]
  }
  return []
}

export const LabAndInvestigationSortPredicate = (a:AbLabResult, b:AbLabResult) : number => {
  return (nullOrUndefinedComparison(a.date, b.date) ?? moment(b.date).unix() - moment(a.date).unix())
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}

export const getNewPatientDefaults = (result: AbLabResult, tenantIdentifiers: any[], genders?: Gender[]) : NewPatientDefaults => {
  let postalCode = result.patientAddress?.postalCode
    ?.toUpperCase()
    .replace(/[^A-Z0-9]+/g, '') ?? ""

  if (!postalCode.match(PostalCodeValidation.regex)) {
    postalCode = ""
  }

  const phones = [ ...(result.patientHomePhoneNumbers ?? [])
    .filter(phone => !!phone)
    .map((phone, index) => {
      return {
        type: PhoneEnumType.HOME,
        number: phone.replace(/[^0-9]+/g, ''),
        extension: "",
        isPrimary: false
      }
    }), ...(result.patientWorkPhoneNumbers ?? [])
    .filter(phone => !!phone)
    .map((phone, index) => {
      return {
        type: PhoneEnumType.BUSINESS,
        number: phone.replace(/[^0-9]+/g, ''),
        extension: "",
        isPrimary: false
      }
    })
  ].filter(phone => !!phone.number.match(PhoneValidation.regex) && phone.number.length === 10)

  if (phones.length > 0) {
    phones[0].isPrimary = true
  }

  const identifiers = (result.patientIdentifiers ?? [])
    .filter(identifier => !!identifier.label)
    .map(identifier => {
      return {
        typeId: tenantIdentifiers.find(
          tenantIdentifier => tenantIdentifier.label === identifier.assigningAuthority?.code || tenantIdentifier.label === identifier.assigningAuthority?.display
        )?.id,
        value: identifier.label
      } as IdentifierType
    }).filter(identifier => !!identifier.typeId)
    .reduce((identifiers: IdentifierType[], identifier: IdentifierType) => {
      return identifiers.find(previousIdentifier => previousIdentifier.typeId === identifier.typeId) ? identifiers : [...identifiers, identifier]
    }, [])

  const gender = genders?.find(provinceGender => provinceGender.name === result?.gender?.display)

  return {
    firstName: capitalizedWords(result.patientName.firstName),
    middleName: capitalizedWords(result.patientName.middleName),
    lastName: capitalizedWords(result.patientName.lastName),
    dob: moment(result.dob),
    genderId: gender?.id,
    street: capitalizedWords(result.patientAddress?.street),
    street2: capitalizedWords(result.patientAddress?.street2),
    city: capitalizedWords(result.patientAddress?.city),
    province: result.patientAddress?.province?.code,
    postalCode: postalCode,
    country: capitalizedWords(result.patientAddress?.country?.display),
    identifiers: identifiers,
    phones: phones
  }
}