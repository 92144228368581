import React from "react";
import {
  LinePlot,
  MarkPlot,
  AreaPlot,
  ResponsiveChartContainer,
  ChartsXAxis,
  ChartsYAxis,
  AllSeriesType,
  LineChartProps as MuiLineChartProps,
  ChartsTextStyle,
  ChartsLegend,
  MarkElement as MuiMarkElement
} from '@mui/x-charts'
import { useTheme } from "@mui/material";

export interface LineChartData {
  value: number,
  date: Date
}

export interface LineChartProps extends Omit<MuiLineChartProps, 'series'> {
  data: { label: string, values: LineChartData[] }[]
  referenceMin?: number
  referenceMax?: number
  min?: Date | null
  max?: Date | null
}

const AXIS_PADDING = 0.2 // amount of extra space in the x and y axis as a percent of the range

const LineChart = ({
  data,
  referenceMin,
  referenceMax,
  width,
  height,
  min,
  max,
  slots,
  slotProps
}: LineChartProps) => {
  const theme = useTheme()
  const colorOptions = [theme.palette.primary.main, theme.palette.secondary.dark]

  if (data.length === 0 || data.some(item => item.values.length === 0)) {
    return null
  }

  const references = !!referenceMin && !!referenceMax

  const xMin = data.flatMap(v=>v.values).reduce((a, b) => { return a.date < b.date ? a : b; }).date
  const xMax = data.flatMap(v=>v.values).reduce((a, b) => { return a.date > b.date ? a : b; }).date
  const xRange = xMax.valueOf() - xMin.valueOf()
  const xPadding = (xRange === 0 ? (1000*60*60*24) : xRange) * AXIS_PADDING // if the range is 0 then assume a range of 1 day
  const xAxisMin = min ?? new Date(xMin.valueOf() - xPadding)
  const xAxisMax = max ?? new Date(xMax.valueOf() + xPadding)

  let yMin = data.flatMap(v=>v.values).reduce((a, b) => { return a.value < b.value ? a : b; }).value
  let yMax = data.flatMap(v=>v.values).reduce((a, b) => { return a.value > b.value ? a : b; }).value
  yMin = references && referenceMin !== Number.NEGATIVE_INFINITY ? Math.min(yMin, referenceMin!) : yMin
  yMax = references && referenceMax !== Number.POSITIVE_INFINITY ? Math.max(yMax, referenceMax!) : yMax
  const yRange = yMax - yMin
  const yPadding = yRange === 0 ? yMax * AXIS_PADDING : yRange * AXIS_PADDING // if the range is 0 then apply the padding to the max value
  let yAxisMin = yMin - yPadding
  if (yAxisMin < 0) {
    // only display negative values on the axis if either an observed value or the reference range is negative
    if ((referenceMin ?? 0) >= 0 && yMin >= 0) {
      yAxisMin = 0
    }
  }
  const yAxisMax = yMax + yPadding

  const dates = references ?  [xAxisMin, ...data[0].values.map(v => v.date), xAxisMax] : data[0].values.map(v => v.date)
  const series = data.map(d => ({
    data: references ? [null, ...d.values.map(v => v.value), null] : d.values.map(v => v.value),
    label: d.label
  }))

  referenceMin = referenceMin === Number.NEGATIVE_INFINITY ? yAxisMin : referenceMin
  referenceMax = referenceMax === Number.POSITIVE_INFINITY ? yAxisMax : referenceMax

  const MarkElement = (props) => {
    const shapes = ['circle', 'triangle', 'cross', 'diamond', 'square', 'star', 'wye'];
    const shape = shapes[parseInt(props.id, 10) % shapes.length];

    return <MuiMarkElement {...props} shape={shape} />;
  };

  return (
    <ResponsiveChartContainer
      colors={colorOptions}
      margin={{
        bottom: 25,
        left: 50,
        right: 16,
        top: 16
      }}
      sx={{
        '.MuiLineElement-series-referenceMin, .MuiLineElement-series-referenceMax': {
          strokeDasharray: "10",
          strokeWidth: 1
        },
        '.MuiAreaElement-series-referenceMax': {
          fill: theme.palette.tertiary.main
        },
        '.MuiChartsAxis-line, .MuiChartsAxis-tick': {
          stroke: theme.palette.greys.medium
        },
      }}
      series={[
        ...(referenceMin && referenceMax ? [
          {
            id: 'referenceMin',
            type: 'line',
            data: Array(series[0].data.length).fill(referenceMin),
            stack: 'reference',
            showMark: false,
            color: theme.palette.greys.medium,
            curve: 'linear'
          },
          {
            id: 'referenceMax',
            type: 'line',
            data: Array(series[0].data.length).fill(referenceMax - referenceMin),
            stack: 'reference',
            showMark: false,
            area: true,
            color: theme.palette.greys.medium,
            curve: 'linear'
          }
        ] as AllSeriesType[] : []),
        ...series.map((s, index) => ({
          id: `${index}`,
          type: 'line' as const,
          data: s.data,
          label: s.label,
          curve: 'linear' as const,
        }))
      ]}
      yAxis={[
        {
          id: 'yAxis',
          scaleType: 'linear',
          min: yAxisMin,
          max: yAxisMax
        }
      ]}
      xAxis={[
        {
          id: 'xAxis',
          scaleType: 'time',
          data: dates,
          min: xAxisMin,
          max: xAxisMax,
        },
      ]}
      width={width}
      height={height}
      disableAxisListener={true}
    >
      <ChartsLegend
        direction="row"
        slotProps={{
          legend: {
            itemMarkWidth: 20,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 10,
          },
          ...slotProps,
        }}
      />
      <AreaPlot slots={slots} slotProps={slotProps}/>
      <LinePlot slots={slots} slotProps={slotProps} />
      <MarkPlot
        slots={{
          mark: MarkElement,
          ...slots,
        }}
        slotProps={slotProps} />
      <ChartsXAxis position="bottom" axisId="xAxis" slots={slots} slotProps={slotProps} tickLabelStyle={theme.typography.p2 as ChartsTextStyle}/>
      <ChartsYAxis position="left" axisId="yAxis" slots={slots} slotProps={slotProps} tickLabelStyle={theme.typography.p2 as ChartsTextStyle}/>
    </ResponsiveChartContainer>
  )
}

export default LineChart