import { LengthHeightUnits, WeightUnits } from './VitalTypes'

export const convertMeasurementUnits = (value: number, fromUnit: string, toUnit: string) => {
  if (!fromUnit || !toUnit) return value;
  switch (`${fromUnit.toLowerCase()}-${toUnit.toLowerCase()}`) {
    case `${WeightUnits.KG}-${WeightUnits.LBS}`:
      return value * 2.20462;
    case `${WeightUnits.LBS}-${WeightUnits.KG}`:
      return value / 2.20462;
    case `${LengthHeightUnits.CM}-${LengthHeightUnits.INCH}`:
      return value / 2.54;
    case `${LengthHeightUnits.INCH}-${LengthHeightUnits.CM}`:
      return value * 2.54;
    default:
      return value;
  }
}

export const calculateBMI = (weight, weightUnit, lengthHeight, lengthHeightUnit) => {
  if (weight && lengthHeight) {
    const convertedHeight = convertMeasurementUnits(lengthHeight, lengthHeightUnit, LengthHeightUnits.CM)
    const convertedWeight = convertMeasurementUnits(weight, weightUnit, WeightUnits.KG)
    return (convertedWeight / ((convertedHeight / 100) ** 2)).toFixed(1)
  }
  return null
}

export const formatDecimal = (value) => {
  if (value) {
    return value.toFixed(1).replace(/\.0$/, "")
  }
  return null
}