import React from "react";
import { NavLink } from "react-router-dom";
import { SubsectionMenu, SubsectionMenuItem } from "saga-library/src";

export const ChartSettingsMenu = () => {
  return (
    <SubsectionMenu
      routes={[
        <ChartSettingsRoute
          route={'identifiers'}
          text={'Identifiers'}
        />,

        <ChartSettingsRoute
          route={'letters'}
          text={'Letter templates'}
        />
      ]}
    />
  )
}

const ChartSettingsRoute = ({ route, text }) => {
  return (
    <NavLink to={route}>
      {({ isActive }) => (
        <SubsectionMenuItem
          dataTestId={`tenantSettingsMenu-menuItem-${text}`}
          isActive={isActive}
          primaryText={text}
        />
      )}
    </NavLink>
  )
}

export default ChartSettingsMenu