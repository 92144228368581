import React from 'react'
import { StaticPage, TosPrivacyPolicyLayout } from "../../components/Layouts";

export const PrivacyPolicy = () => {
  return <StaticPage>
    <TosPrivacyPolicyLayout>
      <p className={ "MsoTitle" }>Privacy Policy</p>

      <h1>Introduction</h1>

      <p className={ "MsoNormal" }>This Privacy Policy explains what information Microquest Inc.,
        and its related entities (&quot;Microquest&quot;) collect about you and why,
        what we do with that information, how we share it, and how we handle the
        content you place in our products and services. It also explains the choices
        available to you regarding our use of your personal information and how you can
        access and update this information.</p>

      <h1>Effective Start Date</h1>

      <p className={ "MsoNormal" }>This policy is effective as of July 26, 2022.</p>

      <h1>Scope of Privacy Policy</h1>

      <p className={ "MsoNormal" }>This Privacy Policy applies to the information that we
        obtain through your use of</p>

      <p className={ "MsoNormal" }>&quot;Microquest Services&quot; via a &quot;Device&quot; or
        when you otherwise interact with Microquest. &quot;Microquest Services&quot;
        include our:</p>

      <p className={ "MsoListParagraphCxSpFirst" }><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>Websites</p>

      <p className={ "MsoListParagraphCxSpMiddle" }><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>SaaS Products</p>

      <p className={ "MsoListParagraphCxSpLast" }><span>·<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>Downloadable Products</p>

      <p className={ "MsoNormal" }>but does not include:</p>

      <p className={ "MsoNormal" }>Microquest products or services for which a separate privacy
        policy is provided.</p>

      <p className={ "MsoNormal" }>Third-Party Products. These are third-party products or
        services that you may choose to integrate with Microquest product or services,
        such as third-party Add-Ons. You should always review the policies of third-party
        products and services to make sure you are comfortable with the ways in which
        they collect and use your information.</p>

      <p className={ "MsoNormal" }>A &quot;Device&quot; is any computer used to access the
        Microquest Services, including without limitation a desktop, laptop, mobile
        phone, tablet, or other consumer electronic device.</p>

      <p className={ "MsoNormal" }>Unless otherwise stated, our SaaS Products and our
        Downloadable Products are treated the same for the purposes of this document.</p>

      <p className={ "MsoNormal" }>By registering for or using Microquest Services you consent
        to the collection, transfer, processing, storage, disclosure, and other uses
        described in this Privacy Policy.</p>

      <h1>Definitions</h1>

      <p className={ "MsoNormal" }><i>“Add-On”:</i> A bundle of code, resources and
        configuration files that can be used with a Microquest product to add new
        functionality or to change the behavior of that product's existing features.</p>

      <p className={ "MsoNormal" }><i>“Content”:</i> Any information or data that you upload,
        submit, post, create, transmit, store or display in a Microquest Service.</p>

      <p className={ "MsoNormal" }><i>“Downloadable Products”:</i> Microquest's downloadable
        software products and mobile applications, including Add-Ons created by
        Microquest, that are installed by customers on an infrastructure of their
        choice. Downloadable Products do not include Add-Ons created by third parties, even
        when they are accessed through Microquest.ca.</p>

      <p className={ "MsoNormal" }><i>“Information”:</i> All of the different forms of data,
        content, and information collected by us as described in this Privacy Policy.</p>

      <p className={ "MsoNormal" }><i>“Personal Information”:</i> Information that may be used
        to readily identify or contact you as an individual person, such as: name,
        address, email address, or phone number. Personal Information does not include
        information that has been anonymized such that it does not allow for the ready
        identification of specific individuals.</p>

      <p className={ "MsoNormal" }><i>“SaaS Products”:</i> Microquest's &quot;Cloud&quot;
        hosted solutions, as well as other Microquest hosted solutions that display a
        link to this Privacy Policy. For the avoidance of doubt, if a Microquest hosted
        solution displays a link to a different privacy policy, then that other privacy
        policy shall apply.</p>

      <p className={ "MsoNormal" }><i>“Websites”:</i> Microquest's websites, including but not
        limited to Microquest.ca, and any related websites, sub-domains and pages.</p>

      <h1>Changes to our Privacy Policy</h1>

      <p className={ "MsoNormal" }>We may change this Privacy Policy from time to time. If we
        make any changes, we will notify you by revising the &quot;Effective
        Starting&quot; date at the top of this Privacy Policy. If we make any material
        changes, we will provide you with additional notice (such as by adding a notice
        on the Microquest Services homepages, login screens, or by sending you an email
        notification). We encourage you to review our Privacy Policy whenever you use
        Microquest Services to stay informed about our information practices and the
        ways you can help protect your privacy.</p>

      <p className={ "MsoNormal" }>If you disagree with any changes to this Privacy Policy, you
        will need to stop using Microquest Services and deactivate your account(s), as
        outlined below.</p>

      <h1>Information you provide to us</h1>

      <p className={ "MsoNormal" }>We collect the following information:</p>

      <p className={ "MsoNormal" }><b>Account and Profile Information:</b> We collect
        information about you and your company as you register for an account, create
        or modify your profile, make purchases through, use, access, or interact with
        the Microquest Services (including but not limited to when you upload,
        download, collaborate on or share Content). Information we collect includes:</p>

      <p className={ "MsoListParagraphCxSpFirst" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Contact information such as name, email address, mailing address,
        and phone number;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Billing information such as credit card details and billing
        address;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Profile information such as a username, profile photo, practice
        addresses, specialties, special interests, practice interests and other
        relevant information;</p>

      <p className={ "MsoListParagraphCxSpLast" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Preferences information such as notification and marketing
        preferences.</p>

      <p className={ "MsoNormal" }>You may provide this information directly when you enter it
        in Microquest Services.</p>

      <p className={ "MsoNormal" }>In some cases another user (such as a system administrator)
        may create an account on your behalf and may provide your information,
        including Personal Information (most commonly when your company requests that
        you use our products). We collect Information under the direction of our
        customers and often have no direct relationship with the individuals whose
        personal data we process. If you are an employee of one of our customers and
        would no longer like us to process your information, please contact your
        employer. If you are providing information (including Personal Information)
        about someone else, you must have the authority to act for them and to consent
        to the collection and use of their Personal Information as described in this
        Privacy Policy.</p>

      <p className={ "MsoNormal" }><b>Content:</b> We collect and store Content that you
        create, input, submit, post, upload, transmit, store or display in the process
        of using our SaaS Products or Websites. Such Content includes any Personal
        Information or other sensitive information that you choose to include
        (&quot;incidentally-collected Personal Information&quot;).</p>

      <p className={ "MsoNormal" }><b>Other submissions:</b> We collect other data that you
        submit to our Websites or as you participate in any interactive features of the
        Microquest Services, participate in a survey, contest, promotion, sweepstakes,
        activity or event, apply for a job, request customer support, communicate with
        us via third-party social media sites or otherwise communicate with us. For
        example, information regarding a problem you are experiencing with a Microquest
        product could be submitted to our Support Services or posted in our public
        forums.</p>

      <h1>Information we collect from your use of Microquest Services</h1>

      <p className={ "MsoNormal" }><b>Web Logs:</b> As is true with most websites and services
        delivered over the Internet, we gather certain information and store it in log
        files when you interact with our Websites and SaaS Products. This information
        includes internet protocol (IP) addresses as well as browser type, internet
        service provider, URLs of referring/exit pages, operating system, date/time
        stamp, information you search for, locale and language preferences,
        identification numbers associated with your Devices, your mobile carrier, and
        system configuration information. In the case of our SaaS Product, the URLs you
        accessed (and therefore included in our log files) include userids as well as
        elements of Content (such as data element keys) as necessary for the SaaS
        Product to perform the requested operations. Occasionally, we connect Personal
        Information to information gathered in our log files as necessary to improve
        Microquest Services for individual customers. In such a case, we would treat
        the combined Information in accordance with this privacy policy.</p>

      <p className={ "MsoNormal" }><b>Analytics Information from Website and SaaS Products:</b>
        We collect analytics information when you use our Websites and SaaS Products to
        help us improve our products and services. In the SaaS Products, this analytics
        information consists of the feature and function of the Microquest Service
        being used, the associated license identifier (SEN) and domain name, the
        username and IP address of the individual who is using the feature or function (which
        will include Personal Information if the Personal Information was incorporated
        into the username), the sizes and original filenames of attachments, and
        additional information required to detail the operation of the function and
        which parts of the Microquest Services are being affected.</p>

      <p className={ "MsoNormal" }>The analytics information we collect includes elements of
        Content related to the function the user is performing. As such, the analytics
        information we collect may include Personal Information or sensitive business
        information that the user has included in Content that the user chose to
        upload, submit, post, create, transmit, store or display in a Microquest
        Service.</p>

      <p className={ "MsoNormal" }><b>Analytics Information Derived from Content:</b> Analytics
        information also consists of data we collect as a result of running queries
        against Content across our user base for the purposes of generating Usage Data.
        &quot;Usage Data&quot; is aggregated data about a group or category of
        services, features or users that does not contain Personal Information. For
        example, we may query Content to determine the most common types of files
        uploaded by searching for the most common file names used as attachments, or we
        may query Content to determine the most frequent users in order to better understand
        the composition of our user base.</p>

      <p className={ "MsoNormal" }>Though we may happen upon sensitive or Personal Information
        as we compile Usage Data from Content across user instances, this is a
        byproduct of our efforts to understand broader patterns and trends. It is not a
        concerted effort by us to examine the Content of any particular customer.</p>

      <p className={ "MsoNormal" }><b>Cookies and Other Tracking Technologies:</b> Microquest
        and our third-party partners, such as our advertising and analytics partners,
        use various technologies to collect information, such as cookies and web
        beacons. Cookies are small data files stored on your hard drive or in device
        memory. We use cookies to improve and customize Microquest Services and your
        experience; to allow you to access and use the Websites or SaaS Products
        without re-entering your username or password; and to count visits and
        understand which areas and features of the Websites and SaaS Products are most
        popular. You can instruct your browser, by changing its options, to stop
        accepting cookies or to prompt you before accepting a cookie from websites you
        visit. If you do not accept cookies, however, you may not be able to use all
        aspects of our Websites or SaaS Products. Microquest and our third-party
        partners also collect information using web beacons (also known as &quot;tracking
        pixels&quot;). Web beacons are electronic images that may be used in our
        Websites or SaaS Products or in emails that help us to deliver cookies, count
        visits, understand usage and campaign effectiveness and determine whether an
        email has been opened and acted upon.</p>

      <p className={ "MsoNormal" }>Microquest and our third-party partners also use javascript,
        e-tags, and HTML5 local storage to collect information about your online
        activities over time and across different websites or online services. Many
        browsers include their own management tools for removing HTML5 local storage
        objects.</p>

      <p className={ "MsoNormal" }>You may be able to opt out of receiving personalized
        advertisements as described below under &quot;Your Choices.&quot;</p>

      <h1>Information we collect from other sources</h1>

      <p className={ "MsoNormal" }><b>Information from third-party services:</b> We also obtain
        information from third parties and combine that with Information we collect
        through Microquest Services. For example, we may have access to certain
        information from a third-party authentication service if you log into
        Microquest Services through the service or otherwise provide us with access to
        Information from the service. Any access that we may have to such Information
        from a third-party authentication service is in accordance with the
        authorization procedures determined by that service. By authorizing us to
        connect with a third-party service, you authorize us to access and store your
        name, email address(es), current city, profile picture URL, and other
        information that the third-party service makes available to us, and to use and
        disclose it in accordance with this Privacy Policy. You should check your
        privacy settings on these third-party services to understand and change the
        information sent to us through these services.</p>

      <h1>How we use Information we collect</h1>

      <p className={ "MsoNormal" }><b>General Uses:</b> We use the Information we collect about
        you (including Personal Information to the extent applicable) for a variety of
        purposes, including to:</p>

      <p className={ "MsoListParagraphCxSpFirst" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Provide, operate, maintain, improve, and promote Microquest
        Services;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Enable you to access and use Microquest Services, including
        uploading, downloading, collaborating on and sharing Content;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Process and complete transactions, and send you related
        information, including purchase confirmations and invoices;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Send transactional messages, including responding to your
        comments, questions, and requests; providing customer service and support; and
        sending you technical notices, updates, security alerts, and support and
        administrative messages;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Send promotional communications, such as providing you with
        information about services, features, surveys, newsletters, offers, promotions,
        contests, events and sending updates about your team and chat rooms; and
        providing other news or information about us and our select partners. You have
        the ability to opt out of receiving any of these communications;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Process and deliver contest or sweepstakes entries and rewards;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Monitor and analyze trends, usage, and activities in connection
        with Microquest</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Services and for marketing or advertising purposes;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Investigate and prevent fraudulent transactions, unauthorized
        access to</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Microquest Services, and other illegal activities;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Personalize Microquest Services, including by providing content,
        features, or advertisements that match your interests and preferences;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>Enable you to communicate, collaborate, and share Content with
        users you designate; and</p>

      <p className={ "MsoListParagraphCxSpLast" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>For other purposes about which we obtain your consent.</p>

      <p className={ "MsoNormal" }>Notwithstanding the foregoing, we will not use Personal
        Information appearing in our Analytics Logs or Web Logs for any purpose. The
        use of Information collected through our Microquest Services shall be limited to
        the purposes disclosed in this policy.</p>

      <p className={ "MsoNormal" }><b>Compiling aggregate analytics information:</b> Because
        our SaaS Products and Downloadable Products are some of the most configurable
        in the market, we make extensive use of analytics information (including log
        and configuration data) to understand how our products are being configured and
        used, how they can be improved for the benefit of all of our users, and to
        develop new products and services. As such we generate Usage Data (as defined
        above) from the web logs and analytics logs described above, including the
        Content elements captured in such logs, as well as from the Content stored in
        the Websites and SaaS Products.</p>

      <h1>Information sharing and disclosure</h1>

      <p className={ "MsoNormal" }>We will not share or disclose any of your Personal
        Information or Content with third parties except as described in this policy or
        as contained, for example, in our Terms and Conditions Agreement. We do not
        sell your Personal Information or Content.</p>

      <p className={ "MsoNormal" }><b>Your Use:</b> When you use Microquest Services, Content
        you provide will be displayed back to you. Certain features of Microquest
        Services allow you or your administrator to make some of your Content public,
        in which case it will become readily accessible to anyone. We urge you to
        consider the sensitivity of any data you input into Microquest Services.</p>

      <p className={ "MsoNormal" }><b>Collaboration:</b> As a natural result of using
        Microquest Services, you may create Content and grant permission to other
        Microquest users to access it for the purposes of collaboration. Some of the
        collaboration features of Microquest Services display your profile information,
        including Personal Information included in your profile, to users with whom you
        have shared your Content.</p>

      <p className={ "MsoNormal" }><b>Access by your system administrator:</b> You should be
        aware that the administrator of your instance of Microquest Services may be
        able to:</p>

      <p className={ "MsoListParagraphCxSpFirst" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>access information in and about your Microquest Services account;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>access communications history, including file attachments, for
        your Microquest Services account;</p>

      <p className={ "MsoListParagraphCxSpMiddle" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>disclose, restrict, or access information that you have provided
        or that is made available to you when using your Microquest Services account,
        including your Content; and</p>

      <p className={ "MsoListParagraphCxSpLast" } ><span
      >·<span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span>control how your Microquest Services account may be accessed or
        deleted.</p>

      <p className={ "MsoNormal" }><b>Microquest Community:</b> Our Websites offer publicly
        accessible community services such as blogs, forums, bug trackers, and wikis.
        You should be aware that any Content you provide in these areas may be read,
        collected, and used by others who access them. Your posts may remain even after
        you cancel your account. To request removal of your Personal Information from
        the Microquest Community, please contact us using the information listed below.
        In some cases, we may not be able to remove your Personal Information, in which
        case we will let you know if we are unable to and why.</p>

      <p className={ "MsoNormal" }><b>Service Providers, Business Partners and Others:</b> We
        work with third-party service providers to provide website, application
        development, hosting, maintenance, back-up, storage, virtual infrastructure,
        payment processing, analysis and other services for us. These service providers
        may have access to or process your Information for the purpose of providing
        those services for us. Some of our pages utilize white-labeling techniques to
        serve content from our service providers while providing the look and feel of
        our site. Please be aware that you are providing your Information to these
        third parties acting on behalf of Microquest.</p>

      <p className={ "MsoNormal" }><b>Third-Party Add-Ons:</b> You may choose to make use of third-party
        Add-Ons in conjunction with Microquest Services. Third-Party Add-Ons are
        software written by third parties to which you grant access privileges to your
        Content (which may include your Personal Information). When access is granted,
        your Content is shared with the third-party. Third-Party Add-On policies and
        procedures are not controlled by Microquest even though the third-party Add-On
        may be available through Microquest Services. Third parties who have been
        granted access to your Content through Add-Ons could use this data to contact
        you and market services to you, and could share your data with other third
        parties. This Privacy Policy does not cover the collection or use of your data
        by third-party Add-Ons, and we urge you to consider the privacy policies
        governing third-party Add-Ons. If you object to your Personal Information being
        shared with these third parties, please uninstall the Add-On (in the event
        installed from the Microquest Marketplace) or terminate your agreement with the
        third-party Add-On provider (in the event you have purchased a direct
        integration).</p>

      <p className={ "MsoNormal" }><b>Links to Third-Party Sites:</b> The Microquest Services
        may include links to other websites whose privacy practices may differ from
        ours. If you submit Personal Information to any of those sites, your
        information is governed by their privacy policies. We encourage you to
        carefully read the privacy policy of any website you visit.</p>

      <p className={ "MsoNormal" }><b>Social Media Widgets:</b> The Microquest Services may
        contain social media features, such as the Twitter &quot;tweet&quot; button.
        These features may collect your IP address, which page you are visiting on the
        Microquest Services, and may set a cookie to enable the feature to function
        properly. Social media features and Widgets are either hosted by a third-party
        or hosted directly on our Microquest Services. Your interactions with these
        features are governed by the privacy policy of the company providing it.</p>

      <p className={ "MsoNormal" }><b>Testimonials:</b> We may display personal testimonials of
        satisfied customers on the Microquest Services. With your consent, we may post
        your testimonial along with your name. If you wish to update or delete your
        testimonial, you can contact us using the information below.</p>

      <p className={ "MsoNormal" }><b>Compliance with Laws and Law Enforcement Requests;
        Protection of Our Rights:</b> We may disclose your Information (including your
        Personal Information) to a third-party if (a) we believe that disclosure is
        reasonably necessary to comply with any applicable law, regulation, legal
        process or governmental request, (b) to enforce our agreements, policies and
        terms of service, (c) to protect the security or integrity of Microquest's
        products and services, (d) to protect Microquest, our customers or the public
        from harm or illegal activities, or (e) to respond to an emergency which we
        believe in the good faith requires us to disclose information to assist in
        preventing the death or serious bodily injury of any person.</p>

      <p className={ "MsoNormal" }><b>Business Transfers: </b>We may share or transfer your
        Information (including your Personal Information) in connection with, or during
        negotiations of, any merger, sale of company assets, financing, or acquisition
        of all or a portion of our business to another company. You will be notified
        via email and/or a prominent notice on the Microquest Services of any change in
        ownership or uses of your Personal Information, as well as any choices you may
        have regarding your Personal Information.</p>

      <p className={ "MsoNormal" }><b>Aggregated or Anonymized Data:</b> We may also share
        aggregated or anonymized information that does not directly identify you with
        the third parties described above.</p>

      <p className={ "MsoNormal" }><b>With Your Consent:</b> We will share your Personal
        Information with third parties when we have your consent to do so.</p>

      <h1>Information we do not share</h1>

      <p className={ "MsoNormal" }>We do not share Personal Information about you with third
        parties for their marketing purposes (including direct marketing purposes)
        without your permission.</p>

      <h1>Data storage, transfer and security</h1>

      <p className={ "MsoNormal" }>Microquest hosts data with hosting service providers in
        numerous countries including Canada. The servers on which Personal Information
        is stored are kept in a controlled environment. While we take reasonable
        efforts to guard your Personal Information, no security system is impenetrable
        and due to the inherent nature of the Internet as an open global communications
        vehicle, we cannot guarantee that information, during transmission through the
        Internet or while stored on our systems or otherwise in our care, will be absolutely
        safe from intrusion by others, such as hackers. In addition, we cannot
        guarantee that any incidentally-collected Personal Information you choose to
        store in Websites or SaaS Products are maintained at levels of protection to
        meet specific needs or obligations you may have relating to that information.</p>

      <p className={ "MsoNormal" }>Where data is transferred over the Internet as part of a
        Website or SaaS Product, the data is encrypted using industry standard SSL/TLS
        (HTTPS).</p>

      <p className={ "MsoNormal" }>Where Downloadable Products are used, responsibility of securing
        access to the data you store in the Downloadable Products rests with you and
        not Microquest. We strongly recommend that administrators of Downloadable
        Products configure SSL/TLS to prevent interception of data transmitted over networks
        and to restrict access to the databases and other storage used to hold data.</p>

      <h1>Your Choices</h1>

      <p className={ "MsoNormal" }>You may opt out of receiving promotional communications from
        Microquest by using the unsubscribe link within each email, updating your email
        preferences at microquest.ca or within your Microquest Service account settings
        menu, or emailing us to have your contact information removed from our
        promotional email list or registration database. Although opt-out requests are
        usually processed immediately, please allow ten (10) business days for a
        removal request to be processed. Even after you opt out from receiving
        promotional messages from us, you will continue to receive transactional
        messages from us regarding Microquest's Services. You can opt-out of some
        notification messages in your account settings.</p>

      <h1>Accessing and updating your information</h1>

      <p className={ "MsoNormal" }>You may often correct, update, amend, or remove your
        Personal Information in your account settings or by directing your query to
        your account administrator. You may also contact Support Services, or contact
        us by postal mail using the address listed below. We will respond to your
        request for access within thirty (30) days.</p>

      <p className={ "MsoNormal" }>You can often remove Content using editing tools associated
        with that Content. In some cases, you may need to contact your administrator to
        request they remove the Content. You can contact us to request removal of
        Personal Information from Microquest Community services.</p>

      <p className={ "MsoNormal" }>You or your administrator may be able to deactivate your
        Microquest Services account. If you can deactivate your own account, you can
        most often do so in your account settings. Otherwise, please contact your
        administrator. To deactivate an organization account, please contact Support
        Services. To deactivate an account made for you without authorization, please
        contact us at the contact information below.</p>

      <p className={ "MsoNormal" }>We will retain your account information for as long as your
        account is active, or as reasonably useful for commercial purposes or as
        necessary to comply with our legal obligations, resolve disputes, and enforce
        our agreements. If your account is managed by an administrator, that account
        administrator may have control with regards to how your account information is
        retained and deleted.</p>

      <h1>Our policy towards children</h1>

      <p className={ "MsoNormal" }>Microquest Services are not directed to individuals under
        13. We do not knowingly collect Personal Information from children under 13. If
        we become aware that a child under 13 has provided us with Personal
        Information, we will take steps to delete such information. If you become aware
        that a child has provided us with Personal Information, please contact our
        Support Services.</p>

      <h1>Contact Us</h1>

      <p className={ "MsoNormal" }>Microquest Inc.<br></br>
        #201, 4246 97 Street NW, Edmonton, Alberta T6E 5Z9<br></br>
        E-Mail: <u>info@microquest.ca</u></p>
    </TosPrivacyPolicyLayout>
  </StaticPage>
}
