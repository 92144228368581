import TableRow from "@mui/material/TableRow";
import React from "react";
import TableHead from "@mui/material/TableHead";
import { TableListHeaderConfig } from "../TableList";
import TableCell from "@mui/material/TableCell";
import { TableHeaderTypography } from "../Typography/Typography";


interface HeaderProps {
  columns: TableListHeaderConfig[]
  showHeader: boolean
}
export const DraggableTableListHeader = ({ columns, showHeader }: HeaderProps) => {
  if (!showHeader) return null
  return (
    <TableHead key={'tableListHeader'} data-testid={"draggableTableListHeader-tableHeader"}>
      <TableRow data-testid={"draggableTableListHeader-tableRow"}>
        <TableListHeaderCell
          key={'header-draggable'}
          name={'draggable'}
        >
          {" "}
        </TableListHeaderCell>
        {columns.map((column, index) => (
          <TableListHeaderCell
            key={'header-' + index}
            lastCol={index === columns.length - 1}
            data-testid={"draggableTableListHeader-tableListHeaderCell"}
            {...column}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}


export interface HeaderCellProps {
  name: string
  styles?: object
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
  children?: React.ReactNode
  lastCol?: boolean
}
export const TableListHeaderCell = (props: HeaderCellProps) => {
  const {
    name,
    styles,
    align,
    children,
    lastCol
  } = props
  return (
    <TableCell
      align={align}
      sx={{
        color: 'greys.medium',
        fontSize: '12px',
        width: lastCol ? '100%' : 'auto',
        whiteSpace: 'nowrap',
        paddingLeft: '8px',
        paddingRight: '8px',
        ...styles
    }}
      data-testid={`tableListHeaderCell-tableCell-${name}`}
    >
      {children ? children : <TableHeaderTypography>{name}</TableHeaderTypography>}
    </TableCell>
  )
}