export enum InviteValidationStep {
  Validate = 0,
  Accept = 1,
  SignUp = 2,
  Complete = 3,
}

export type ValidateInviteFormProps = {
  hash: string
  salt: string
  question: string
  onSubmit: (success: boolean, data: string) => void
  setErrorMessage: (message: string) => void
}

export type ValidateInviteFormValues = {
  hash: string
  salt: string
  passcode: string
}

export type AcceptInviteFormProps = {
  hash: string
  salt: string
  passcode: string
  userId: string
  tenantName: string
  onSubmit: (success: boolean) => void
  setErrorMessage: (message: string) => void
}

export type AcceptInviteFormValues = {
  hash: string
  salt: string
  passcode: string
  userId: string
}

export type SignUpInviteFormProps = {
  hash: string
  salt: string
  passcode: string
  tenantName: string
  emailAddress: string
  onSubmit: (success: boolean) => void
  setErrorMessage: (message: string) => void
}

export type SignUpInviteFormValues = {
  hash: string
  salt: string
  passcode: string
  emailAddress: string
  password: string
  firstName: string
  lastName: string
}