import { PatientSectionHeader } from "../PatientSectionHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ConfirmationDialog, PrintIconButton, RemoveButton, Section } from "saga-library/src";
import { Box } from "@mui/material";
import { LetterType } from "../../../../types/patients";
import { LetterForm } from "./LetterForm";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { HasPermissionTemplate } from "../../../../components/HasPermissionTemplate";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { letterDefaults } from "./LetterFormDefaultValues";
import { schema } from "./LetterFormValidationSchema";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useLetterContext } from "../../providers/LetterProvider";
import { useReadQuery } from "@apollo/client";
import _get from "lodash/get";
import { flushSync } from 'react-dom'
import { useEditorImageContext } from '../../../../providers/EditorImageContextProvider'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'

const MAIN_LETTER_FORM_NAME = "main_letter_form"

export const LetterFormMain = () => {
  const navigate = useNavigate()
  const { patient_id, tenant_id, letter_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { buildTenantRoute } = useAccountContext()
  const activityTracking = useActivityTracking()

  const { letterQueryRef, createLetter, updateLetter, deleteLetter } = useLetterContext()
  const { data } = useReadQuery(letterQueryRef!)
  const patientLetters = _get(data, 'tenant.patient.letter.list', []) as Array<LetterType>
  const letter = patientLetters.find((letter) => letter.id === letter_id && !letter.isLinkedDocument)

  const editorRef = useRef<any>(null)

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const { imageFilesInEditor, disableSave, setImageFilesInEditor } = useEditorImageContext()
  let shouldCallOnChange = imageFilesInEditor?.length > 0

  const formMethods = useForm<LetterType>({
    defaultValues: letterDefaults,
    schema: schema,
  })

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    setImageFilesInEditor(letter?.imageFileIds ?? [])
  }, [setImageFilesInEditor])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, MAIN_LETTER_FORM_NAME, `letters/`)
    return () => enableNavigationPrompt(false, MAIN_LETTER_FORM_NAME)
  }, [Object.keys(dirtyFields).length]);


  const onSuccess = useCallback((letterResult)=>{
    flushSync(() => {
      clearNavigationPrompt( MAIN_LETTER_FORM_NAME)
    })
    navigate(buildTenantRoute(`patients/p/${patient_id}/letters/l/${letterResult.id}`, tenant_id), {replace: true})
  }, [letter_id, letter, patient_id])

  const onSubmitCreate = handleSubmit(async (data) => {
    data.patientId = patient_id!
    data.imageFileIds = [...imageFilesInEditor]
    await createLetter(data, onSuccess)
  })

  const onSubmitUpdate = handleSubmit(async (data) => {
    data.patientId = patient_id!
    data.imageFileIds = [...imageFilesInEditor]
    await updateLetter(data, () => reset({},{keepValues: true}))
  })

  const onDelete = async(letter) => {
    await deleteLetter(letter, () => {
      flushSync(() => {
        clearNavigationPrompt(MAIN_LETTER_FORM_NAME)
      })
      navigate(buildTenantRoute(`patients/p/${patient_id}/letters`, tenant_id))
    })
  }

  const onBack = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/letters`, tenant_id), {replace: true})
  }

  return <Section.Column
    sx={{ flex: "1 1 100%" }}
    header={
      <PatientSectionHeader
        dataTestId={'letter-editor'}
        sectionLabel={letter ? letter.title : "New Letter"}
        showBackButton={true}
        onBack={onBack}
        formName={MAIN_LETTER_FORM_NAME}
        showSave={!!hasEditPermission as boolean}
        submitting={isSubmitting}
        disableSave={disableSave}
        actions={ letter && (
          <Box display={"inline-flex"}>
            <DeleteLetterConfirmation onDelete={() => onDelete(letter)} />
            <PrintIconButton
              onClick={() =>{
                console.log(letter)
                editorRef?.current?.editor?.print?.run()
                activityTracking.logPrintActivity(UserActivityObjectType.LETTER, letter.id!, patient_id)
              }}
              dataTestId={"letter-editor"}
            />
          </Box>
        )}
      />
    } >
    <FormProvider {...formMethods}>
      <LetterForm
        onSubmit={letter ? onSubmitUpdate : onSubmitCreate}
        formName={MAIN_LETTER_FORM_NAME}
        letter={letter}
        editorRef={editorRef}
        shouldCallOnChange={shouldCallOnChange}
        dataTestId={"letterForm"}
      />
    </FormProvider>
  </Section.Column>
}

export const DeleteLetterConfirmation = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
      <RemoveButton
        onClick={() => setOpenDeleteConfirmation(true)}
        dataTestId={'letter'}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={'Delete letter?'}
        message={"This action cannot be undone."}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete()
        }}
        primaryLabel={'delete'}
        onClose={() => setOpenDeleteConfirmation(false)}
        dataTestId={'delete-letter-confirmation'}
      />
    </HasPermissionTemplate>
  )
}