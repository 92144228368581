import React from "react";
import {
  Timeline as MuiTimeline,
  timelineItemClasses,
} from "@mui/lab";
import { EVENT_GROUP_NAMES, TimelineEventType, TimelineType } from "../../../../types/patients/Timeline";
import TimelineEventGroup from "./components/TimelineEventGroup";

type TimelineProps = {
  timeline: TimelineType
  onEventClick: (event: TimelineEventType) => void
}

const Timeline = ({ timeline, onEventClick }: TimelineProps) => {
  return (
    <MuiTimeline
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        m: 0,
        pl: 0,
        pr: 1
      }}
    >
      {Object.keys(timeline)
        .filter((group) => timeline[group].length !== 0)
        .map((group, index) => (
          <TimelineEventGroup
            key={group}
            dataTestId={`timeline-${group}`}
            name={EVENT_GROUP_NAMES[group]}
            group={timeline[group]}
            groupIndex={index}
            onEventClick={onEventClick}
          />
        ))
      }
    </MuiTimeline>
  )
}

export default Timeline