import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { schema } from "../util/appointmentStateValidation";
import { useMutation } from "@apollo/client";
import _get from "lodash/get";
import { DialogV2 } from "saga-library/src";
import React, { useState, useEffect } from "react";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AppointmentStateForm } from "./AppointmentStateForm";
import { ADD_NEW_APPOINTMENT_STATE, LIST_APPOINTMENT_STATES } from "../../../../graphql-definitions";
import { AppointmentState, AppointmentStateInput } from "../../../../types/schedule";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { AppointmentStateCategory } from '../../../../types/schedule/AppointmentState'

const FORM_NAME = 'new_appointment_state_form'

interface NewAppointmentStateModalProps {
  open: boolean
  setOpen: (a: boolean) => void
}

const NewAppointmentStateDefaults = {
  name: '',
  color: '#FFFFFF',
  category: AppointmentStateCategory.PREVISIT,
  canEnterExamRoom: false,
  blocksSchedule: false,
  version: "0"
}

export const NewAppointmentStateModal = ({ open, setOpen }: NewAppointmentStateModalProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<AppointmentStateInput>({
    defaultValues: NewAppointmentStateDefaults,
    schema: schema,
  })
  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setOpen(false)
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [addNewAppointmentState, {loading}] =
    useMutation(ADD_NEW_APPOINTMENT_STATE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment state has been added.')
        reset()
        setOpen(false)
      },
      onError: (error) => {
        showErrorAlert("Appointment state couldn't be created.")
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const onSubmit = handleSubmit(async (data) => {
    await addNewAppointmentState({
      variables: {
        tenantId: tenant_id,
        appointmentStateInput: data
      },
      update(cache, returnedData) {
        const appointmentState = _get(returnedData, 'data.tenant.schedule.state.create')
        cache.updateQuery({
          query: LIST_APPOINTMENT_STATES,
          variables: {tenantId: tenant_id}},
          (listData) => {
            const allStates = _get(listData, 'tenant.schedule.state.listStates', [])
            return {
              tenant: {
                schedule: {
                  state: {
                    listStates: [...allStates, appointmentState]
                  }
                }
              }
            }
          }
        )
      },
    })
  })

  return (
    <DialogV2
      title={"Add a new appointment state"}
      open={open}
      onClose={handleClose}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={'saveAppointmentState'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={"newAppointmentStateModal-save-button"}
          loading={isSubmitting}
          type={'submit'}
        >
          Save
        </PermissionButton>
      }
      data-testid={'newAppointmentStateModal'}
    >
      <FormProvider {...formMethods}>
        <AppointmentStateForm
          isDefault={false}
          onSubmit={onSubmit}
        />
      </FormProvider>
    </DialogV2>
  )
}
