import React from "react";
import { TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { Typography } from "saga-library/src";
import TimelineConnector from "./TimelineConnector";
import TimelineEvent from "./TimelineEvent";
import { TimelineEventGroupType, TimelineEventType } from "../../../../../types/patients/Timeline";

type TimelineEventGroupProps = {
  name: string
  group: TimelineEventGroupType[]
  groupIndex: number
  onEventClick: (event: TimelineEventType) => void
  dataTestId?: string
}

const TimelineEventGroup = ({ name, group, groupIndex, onEventClick, dataTestId }: TimelineEventGroupProps) => {
  return (
    <>
      <TimelineItem sx={{ minHeight: "30px" }}>
        <TimelineSeparator sx={{ pl: 1 }}>
          {groupIndex !== 0 && <TimelineConnector sx={{ height: "12px" }} />}
          <TimelineEventGroupDot />
        </TimelineSeparator>
        <TimelineContent display={"flex"} flexDirection={"column"} justifyContent={"space-between"} sx={{ pb: 0, pt: groupIndex === 0 ? "4px" : "20px", pl: 1 }}>
          <TimelineEventGroupName>{name}</TimelineEventGroupName>
        </TimelineContent>
      </TimelineItem>
      {group.map((events, index) => (
        <TimelineEvent
          key={events.date}
          events={events}
          onEventClick={onEventClick}
          dataTestId={`${dataTestId}-event-${index}`}
        />
      ))}
    </>
  )
}

const TimelineEventGroupDot = () => {
  return (
    <TimelineDot variant={"outlined"} sx={{ borderColor: (theme) => theme.palette.greys.medium, mx: 1, my: "4px", p: 1 }} />
  )
}

const TimelineEventGroupName = ({ children }) => {
  return (
    <Typography variant={"h5"} sx={{ color: (theme) => theme.palette.greys.medium }}>{children}</Typography>
  )
}

export default TimelineEventGroup