import { Box } from "@mui/material";
import React from "react";
import ShuttleBay from "./ShuttleBay";
import { tenantUserSettings } from "../../../../utils/TenantUserSettings"
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { RoomList } from "./RoomList";
import { Section } from "saga-library/src";
import { SearchPanel } from "./SearchPanel";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
const { SHOW_ROOM_SETTING } = tenantUserSettings

export const PANEL_OPEN_WIDTH = '280px'
export const PANEL_CLOSED_WIDTH = '32px'

export const SidePanel = ({
  searchOpen,
  onSearchClose,
  onRoomWithAppointmentClick
}) => {
  const { getUserTenantSettings } = useTenantContext()
  const isOpen = getUserTenantSettings([SHOW_ROOM_SETTING])[SHOW_ROOM_SETTING.name]
  const { shuttleBay } = useScheduleContext()

  return <Box
    width={isOpen || searchOpen || shuttleBay.appointment ? PANEL_OPEN_WIDTH : PANEL_CLOSED_WIDTH}
    display={'flex'}
    flexDirection={'column'}
    gap={2}
    mr={1}
    className={'side-panel'}
    pb={"3px"}
  >
    <ShuttleBay
      isMinimized={!!isOpen || !!searchOpen }
    />

    {(isOpen || searchOpen) &&
      <Section.Column sx={{mr:0, overflow: searchOpen ? "hidden" : "auto"}} preventScroll={searchOpen}>
        {searchOpen ?
          <SearchPanel setSearchOpen={onSearchClose} />
          :
          <RoomList
            onRoomWithAppointmentClick={onRoomWithAppointmentClick}
          />
        }
      </Section.Column>}
  </Box>
}