import React from "react";
import { Typography } from "saga-library/src";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface SettingsSubHeaderProps {
  title: string
  description: string
  sx?: SxProps<Theme>
}

const SettingsSubHeader = ({ title, description, sx = {} }: SettingsSubHeaderProps) => {
  return (
    <Box sx={{ pb: 1, ...sx }}>
      <Typography variant={'h2'} dataTestId={`${title}-settingsSubHeader-title`}>{title}</Typography>
      <Typography variant={'body1'}>{description}</Typography>
    </Box>
  )
}

export default SettingsSubHeader
