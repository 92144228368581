import { Tooltip } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import Groups2Icon from '@mui/icons-material/Groups2';
import DomainIcon from '@mui/icons-material/Domain';



const IconContainer = ({message, children}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Tooltip title={message} placement={'right'}>
        {children}
      </Tooltip>
    </Box>
  )
}

interface SettingContainerProps {
  settingIcon?: JSX.Element,
  props: any
}

const SettingContainer = ({settingIcon, props}: SettingContainerProps) => {
  return (
    <Box
      sx={Object.assign({ display: "flex" }, props.sx)}
    >
      {settingIcon}
      {props.children}
    </Box>
  )
}


export const AllUsersAtClinic = (
  <IconContainer message={"This setting applies to all users at this clinic."}>
    <Groups2Icon
      sx={{
        color: (theme) => theme.palette.greys.light,
        mr: 1,
      }}
    />
  </IconContainer>
)

export const AllUsersAtClinicSetting = (props) => <SettingContainer props={props}/>



export const AllClinics = (
  <IconContainer message={"This setting applies to all clinics to which you have access."}>
    <DomainIcon
      sx={{
        color: (theme) => theme.palette.greys.light,
        mr: 1,
      }}
    />
  </IconContainer>
)

export const AllClinicsSetting = (props) => <SettingContainer settingIcon={AllClinics} props={props}/>


