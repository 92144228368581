import React, { useState } from 'react'
import { ReactToPrint } from "react-to-print";
import { LoadingButton } from "../Button";
import { useActivityTracking, UserActivityObjectType } from 'saga-client/src/hooks/useActivityTracking'
import { PrintIconButton } from './PrintIconButton'

export interface PrintButtonProps {
  name: string
  documentTitle: string
  componentRef: React.RefObject<any> | undefined
  dataTestId: string
  printWidth?: string
  printHeight?: string
  onComplete?: () => void
  objectType?: UserActivityObjectType
  objectId?: string,
  type?: 'icon' | 'button',
  onBeforeGetContent?: () => void
}

export const PrintButton = ({
  name,
  documentTitle,
  componentRef,
  dataTestId,
  printWidth,
  printHeight,
  onComplete,
  objectId,
  objectType,
  type = 'button',
  onBeforeGetContent
}: PrintButtonProps) => {
  const [loading, setLoading] = useState(false)

  const activityTracking = useActivityTracking()

  let pageStyle = { margin: '0mm', size: 'auto'}
  if (printWidth && printHeight) {
    pageStyle.size = `${printWidth} ${printHeight}`
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef?.current;
  }, [componentRef]);

  const reactToPrintTrigger = () => {
    return (
      type === 'icon'
        ? <PrintIconButton
            disabled={loading}
            onClick={undefined}
            dataTestId={`${dataTestId}-print-icon-button`}
            sx={{
              p: 0,
            }}
          />
        : <LoadingButton
            dataTestId={`${dataTestId}-print-button`}
            name={`${name}_print_button`}
            loading={loading}
            variant={'contained'}
          >
            Print
      </LoadingButton>
    )
  }

  return <ReactToPrint
    pageStyle={`@page { ${Object.entries(pageStyle).map(([key, value]) => `${key}: ${value};`).join(' ')} }`}
    content={reactToPrintContent}
    documentTitle={documentTitle}
    trigger={reactToPrintTrigger}
    onBeforePrint={() => setLoading(true)}
    onBeforeGetContent={onBeforeGetContent}
    onAfterPrint={() => {
      setLoading(false)
      if(objectType && objectId) {
        activityTracking.logPrintActivity(objectType, objectId)
      }
      if (onComplete) {
        onComplete()
      }
    }}
  />
}
