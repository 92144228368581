import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { LoadingButton, PasswordField, TextField, Typography, Form } from 'saga-library/src'
import _get from 'lodash/get'
import { useForm, FormProvider, useWatch } from "saga-library/src/components/Form"
import * as yup from 'yup'
import { SignUpInviteFormProps, SignUpInviteFormValues } from '../../types/invitations/Invitation'
import PasswordChecklist from "react-password-checklist"
import Box from '@mui/material/Box/Box'
import { ACCEPT_INVITATION_NEW_USER } from "../../graphql-definitions";

export const SignUpInviteForm = (props: SignUpInviteFormProps) => {
  const [checklistValid, setChecklistValid] = useState<boolean>(false)

  const [acceptInvite, { loading }] = useMutation(
    ACCEPT_INVITATION_NEW_USER, {
    onCompleted: (data) => {
      const success = _get(data, 'public.invitation.invitationAcceptedNewUser.success')
      if (!success) props.setErrorMessage("Incorrect answer or invitation expired.")
      props.onSubmit(!!success)
    },
    onError: (error) => {
      props.setErrorMessage("The invitation could not be accepted.")
      props.onSubmit(false)
    },
    fetchPolicy: 'network-only'
  })

  const handleSignUpInvite = (data) => {
    if (!checklistValid) return

    props.setErrorMessage("")
    acceptInvite({
      variables: data
    })
  }

  const schema = yup.object().shape({
    hash: yup.string().required(),
    salt: yup.string().required(),
    passcode: yup.string().required(),
    password: yup.string().required(),
    firstName: yup.string().required().max(35),
    lastName: yup.string().required().max(35),
  }).required();

  const signUpInviteFormMethods = useForm<SignUpInviteFormValues>({
    schema: schema,
    defaultValues: {
      emailAddress: props.emailAddress
    }
  })

  const {
    handleSubmit,
    register,
    control
  } = signUpInviteFormMethods

  const newPassword = useWatch({
    control,
    name: "password"
  })

  register("hash", { value: props.hash })
  register("salt", { value: props.salt })
  register("passcode", { value: props.passcode })

  return (<FormProvider {...signUpInviteFormMethods}>
    <Form id={'reset-password-form'} onSubmit={handleSubmit(handleSignUpInvite)} autoComplete={'off'}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant={'body1'} sx={{ mt: 2 }}>
          You are invited to join
        </Typography>
        <Typography variant={'h3'} sx={{ mt: 1 }}>
          {props.tenantName}
        </Typography>
        <Box marginTop={2} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <TextField
            label={'Email Address'}
            sx={{ width: 280 }}
            name={'emailAddress'}
            disabled={true}
          />
          <TextField
            label={'First name'}
            sx={{ width: 280 }}
            name={'firstName'}
          />
          <TextField
            label={'Last name'}
            sx={{ width: 280 }}
            name={'lastName'}
          />
          <PasswordField
            label={'Password'}
            sx={{ width: 280 }}
            autoComplete={'new-password'}
            name={'password'}
          />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={8}
            value={newPassword || ""}
            onChange={(isValid) => {
              setChecklistValid(isValid)
            }}
            style={{
              flexDirection: 'column',
              marginTop: 1,
              marginBottom: 1,
              width: 260,
              display: 'flex',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '20px',
              alignItems: 'flex-start'
            }}
            validColor={"#0F6558"}
            invalidColor={"#C41923"}
            iconSize={16}
          />
        </Box>
        <LoadingButton sx={{ mt: 2, mr: 1 }}
          name={'SignUpInviteButton'}
          type={'submit'}
          loading={loading}
          variant={'contained'}
        >
          Accept Invite & Create Account
        </LoadingButton>
      </Box>
    </Form>
  </FormProvider >)
}
