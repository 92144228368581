import { useState, useEffect, useCallback } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'

export const useGenericDataGridSearch = <T,>(code: string, name: string, params: GridRenderEditCellParams) => {
  const [val1, setVal1] = useState<T | null>(null)
  const [val2, setVal2] = useState<string | null>(null)
  const [cleared, setCleared] = useState(false)
  const apiRef = useGridApiContext()

  useEffect(() => {
    let originalRow = apiRef.current.getRow(params.id)
    if (cleared) {
      originalRow[code] = ''
      setCleared(false)
      setVal1(null)
      setVal2(null)
    } else if (val2) {
      originalRow[code] = ''
      if (val1 && val2 === val1[name]) {
        originalRow[code] = val1['id']
        return
      }
    }
    apiRef.current.setEditCellValue({ id: params.id, field: code, value: originalRow[code] })
  }, [val1, val2, cleared])

  const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    event.stopPropagation()
    apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue })
    if (newValue) {
      if (newValue.id) {
        setVal1(newValue)
      } else {
        setVal2(newValue)
      }
    } else {
      setCleared(true)
    }
  }, [params.id, params.field])

  return {
    handleChange
  }
}