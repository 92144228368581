import { Controller, useFormContext } from "saga-library/src/components/Form";
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete";
import { NoOptions } from "saga-library/src/components/SearchControl/SearchControl";
import { SimpleTextField, useSchemaRequired } from "saga-library/src";
import React, { useEffect, useState } from "react";
import { ControlledSearchControlProps } from "saga-library/src/components/SearchControl/ControlledSearchControl";
import { useParams } from "react-router-dom";
import { AutocompleteValue, Typography } from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_SHARED_FORM, LIST_SHARED_FORMS } from "../../../graphql-definitions";
import _get from "lodash/get";
import omitDeep from "omit-deep-lodash";
import { SharedForm } from "../../../types/patients/Forms";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface FormsSearchProps<
  T = SharedForm,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = true
> extends Omit<
  ControlledSearchControlProps<T, Multiple, DisableClearable, FreeSolo>,
  "queries" | "render" | "transform" | "isOptionEqualToValue"
> {
  onChange?: (value: T) => void;
  selectedOptionId?: string;
}

export const SharedFormsSearch = ({
  name = "sharedForms",
  label = "Shared Forms",
  onChange,
  selectedOptionId,
  ...props
}: FormsSearchProps) => {
  const { tenant_id } = useParams();
  const { control } = useFormContext()
  const required = useSchemaRequired()

  const [TValue, setTValue] = useState<AutocompleteValue<SharedForm, false, false, false>>()
  const [inputValue, setInputValue] = React.useState<string>("")
  const [searchOptions, setSearchOptions] = React.useState<readonly SharedForm[]>()

  const { loading } = useQuery(LIST_SHARED_FORMS, {
    variables: {
      tenantId: tenant_id
    },
    onCompleted: (data) => {
      setSearchOptions(omitDeep(_get(data, 'tenant.sharedForm.list', []), '__typename'))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const [getSharedForm] = useLazyQuery(GET_SHARED_FORM, {
    onCompleted: (data) => {
      const option = omitDeep(_get(data, 'tenant.sharedForm.get', null), '__typename')
      if (option && !searchOptions?.find((o) => o.id === option.id)){
        setSearchOptions(prevState => [...prevState ?? [], option])
      }
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if(selectedOptionId && !loading && searchOptions) {
      const option = searchOptions?.find((option) => option.id === selectedOptionId)
      if (option) {
        // This call is setting the value in the Autocomplete select
        setTValue(option)
        // This call tells the parent to load the SharedFile
        if(onChange) {
          onChange(option)
        }
      } else {
        // grab the exact version manually and add it to the list
        getSharedForm({
          variables: {
            tenantId: tenant_id,
            sharedFormId: selectedOptionId
          }
        })
      }
    }
  }, [selectedOptionId, searchOptions, loading])

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: formOnChange, ref },
        fieldState: { error }
      }) => {
        return (
          <Autocomplete
            hidden={props.hidden}
            value={TValue || value}
            disablePortal
            disabled={props.disabled}
            loading={loading}
            options={searchOptions ?? []}
            getOptionLabel={(form) => form?.name ?? ""}
            sx={props.sx}
            data-testid={"sharedFormsSearch"}
            onChange={(event, newValue) => {
              setTValue(newValue);
              formOnChange(newValue?.name);
              if(onChange) {
                onChange(newValue)
              }
            }}
            noOptionsText={<NoOptions inputValue={inputValue} />}
            onInputChange={(event, newInputValue, reason) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <SimpleTextField
                {...params}
                fullWidth={true}
                label={label}
                error={!!error}
                helperText={error?.message}
                InputProps={{
                  ...params.InputProps
                }}
                required={required}
                disabled={props.disabled}
                autoFocus={true}
                dataTestId={"sharedFormsSearch-textfield"}
              />
            )}
            renderOption={(props, option, { index }) => {
              return (
                <li {...props} key={(option as any)?.id} data-testid={`sharedFormsSearch-options-${index}`}>
                  <Typography>{option.name}</Typography>
                </li>
              )
            }}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            componentsProps={{
              clearIndicator: {
                // @ts-ignore
                "data-testid": "sharedFormsSearch-clear-button"
              },
              popupIndicator: {
                // @ts-ignore
                "data-testid": "sharedFormsSearch-dropDown-button"
              }
            }}
            clearIcon={<ClearIcon fontSize="small" data-testid={"sharedFormsSearch-clearIcon"} />}
            popupIcon={<ArrowDropDownIcon data-testid={"sharedFormsSearch-dropDownIcon"} />}
          />
        )
      }}
    />
  );
}