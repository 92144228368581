import { Box, Menu, MenuItem } from "@mui/material";
import { IconButton, OpenInNewTab, PrimaryChip, RemoveButton, Typography } from "saga-library/src";
import React, { useState } from "react";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PractitionerSearchResult } from "../../../types/settings";
import { useAccountContext } from "../../../providers/AccountContext";
import { useParams } from "react-router-dom";

export enum PractitionerRelationshipType {
  CLINIC = 'Clinic practitioner',
  REFERRING = 'Referring practitioner',
  FAMILY = 'Family practitioner'
}

type PractitionerBlockProps = {
  blockKey: string
  practitioner: PractitionerSearchResult & {isPrimaryPractitioner?: boolean}
  onDelete?: () => void
  onMore?: {label: string, onClick: () => void}[]
  dataTestId: string
  type: PractitionerRelationshipType
}

export const PractitionerBlock = ({blockKey, practitioner, type, dataTestId, onDelete, onMore}: PractitionerBlockProps) => {
  const { firstName, lastName  } = practitioner
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const openDocInNewTab = (doc) => {
    if (type === PractitionerRelationshipType.CLINIC) {
      window.open(buildTenantRoute(`settings/practitioners/p/${doc.id || doc.practitionerId}`, tenant_id))
    } else {
      window.open(buildTenantRoute(`settings/practitioners/referral/r/${doc.id}`, tenant_id))
    }
  }

  return (
    <Box
      key={blockKey}
      data-testid={`${dataTestId}-box`}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{
        p: 1,
        borderRadius: 2,
        border: '1px solid rgba(32, 41, 49, 0.32)',
        flexShrink: 0,
        alignSelf: 'stretch',
        my: 1
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={"2px"}>
        <Typography variant={'body1'} lineclamp={1} dataTestId={`${dataTestId}-name`}>{practitionerDisplayName(firstName, lastName)}</Typography>
        <Box display={"flex"} flexDirection={"row"} gap={"4px"} alignItems={'center'}>
          <Typography variant={'p2'} lineclamp={1} sx={{ color: 'greys.medium' }} dataTestId={`${dataTestId}-type`}>{type}</Typography>
          {practitioner.isPrimaryPractitioner && <PrimaryChip label={"PRIMARY"} data-testid={`${dataTestId}-primaryChip`}/>}
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={'center'}>
        <OpenInNewTab onOpenInNewTab={() => openDocInNewTab(practitioner)} dataTestId={`${dataTestId}-openInNew`} />
        {onMore && <ViewMore menuItems={onMore} dataTestId={`${dataTestId}-more`} />}
        {onDelete && <RemoveButton onClick={onDelete} dataTestId={dataTestId}/>}
      </Box>
    </Box>
  )
}

interface ViewMoreProps {
  menuItems: {label: string, onClick: () => void}[]
  dataTestId: string
}

const ViewMore = ({menuItems, dataTestId}:ViewMoreProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return <>
    <IconButton onClick={handleClick} icon={<MoreHorizIcon />} dataTestId={`${dataTestId}-button`} />
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose} data-testid={`${dataTestId}-menu`}>
      {menuItems.map((item, index) => (
        <MenuItem
          key={`${index}-menu-item`}
          onClick={ () => {
            item.onClick()
            handleClose()
          }}
          data-testid={`${dataTestId}-${item.label}`}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  </>
}