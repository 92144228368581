import React from 'react'
import { InputType } from 'react-querybuilder'
import { PractitionerSelect } from '../components/PractitionersSelect'
import { ControlledBillingProfileSelect } from '../components/BillingProfileSelect/BillingProfileSelect'
import { AppointmentStateSelect } from '../components/AppointmentStateSelect'
import { ControlledAppointmentTypeSelect } from '../components/AppointmentTypeSelect'
import { AssessmentOutcomeSelect } from '../components/AssessmentOutcomeSelect'
import { CategorySelect } from '../apps/tasks/documents/components/LinkDocumentSection'
import { PrescriptionStatusSelect } from '../components/PrescriptionStatusSelect'
import { GenderSelect } from '../components/GenderSelect'
import { PostalCodeField } from 'saga-library/src'
import { IdentifierTypeSelect } from '../components/IdentifierTypeSelect'

export interface ReportType {
  id: string,
  name: string,
  description: string,
  category: ReportCategoryType,
  columns: ReportColumn[],
  parameters: ReportParameter[],
  queries: ReportQuery[],
  lastRun: Date | null,
  isReference: boolean,
  version: string
}

interface ReportInputBaseType {
  id?: string,
  name?: string,
  description?: string,
  category?: ReportCategoryType | null,
  queries?: ReportQueryType[],
  version?: string
}

export interface ReportInputType extends ReportInputBaseType {
  combinator?: ReportCombinatorType
  columns?: ReportColumnInput[]
  parameters?: ReportParameterInput[]
  queries?: ReportQueryInput[]
}

export interface ReportInputDataType extends ReportInputBaseType {
  columns?: ReportColumn[]
  parameters?: ReportParameter[]
  queries?: ReportQuery[]
}

export enum ReportCategoryType {
  BILLING = 'BILLING',
  APPOINTMENT = 'APPOINTMENT',
  PATIENT = 'PATIENT'
}

export const REPORT_CATEGORIES: { [category: string]: ReportCategoryType } = {
  "appointment": ReportCategoryType.APPOINTMENT,
  "billing": ReportCategoryType.BILLING,
  "patient": ReportCategoryType.PATIENT
}

export enum ReportColumnType {
  DATE = 'DATE',
  NOTES = 'NOTES',
  PRACTITIONER_BILLING_PROFILE = 'PRACTITIONER_BILLING_PROFILE',
  STATE = 'STATE',
  TYPE = 'TYPE',
  CALLS = 'CALLS',
  CLAIMED = 'CLAIMED',
  DIAGNOSES = 'DIAGNOSES',
  FACILITY = 'FACILITY',
  OUTCOME = 'OUTCOME',
  PAID = 'PAID',
  SERVICE_CODE = 'SERVICE_CODE',
  SERVICE_DATE = 'SERVICE_DATE',
  LAB_RESULT_DATE = 'LAB_RESULT_DATE',
  LAB_RESULT_DESCRIPTION = 'LAB_RESULT_DESCRIPTION',
  LINKED_DOCUMENT_CATEGORY = 'LINKED_DOCUMENT_CATEGORY',
  LINKED_DOCUMENT_DESCRIPTION = 'LINKED_DOCUMENT_DESCRIPTION',
  PRESCRIPTION_DATE = 'PRESCRIPTION_DATE',
  PRESCRIPTION = 'PRESCRIPTION',
  PRESCRIPTION_STATUS = 'PRESCRIPTION_STATUS',
  PATIENT_AGE = 'PATIENT_AGE',
  PATIENT_DATE_OF_BIRTH = 'PATIENT_DATE_OF_BIRTH',
  PATIENT_GENDER = 'PATIENT_GENDER',
  PATIENT_IDENTIFIER = 'PATIENT_IDENTIFIER',
  PATIENT_IDENTIFIER_TYPE = 'PATIENT_IDENTIFIER_TYPE',
  PATIENT_LAST_APPOINTMENT_DATE = 'PATIENT_LAST_APPOINTMENT_DATE',
  PATIENT_NAME = 'PATIENT_NAME',
  PATIENT = 'PATIENT',
  PATIENT_POSTAL_CODE = 'PATIENT_POSTAL_CODE',
  PRACTITIONER = 'PRACTITIONER'
}

export interface ReportColumnInput {
  id?: string
  name: string
  label: string
  version?: string
}

export interface ReportColumn {
  id?: string
  column: string
  order: number
  version?: string
}

export enum ReportColumnDisplayFormatter {
  NONE = "NONE",
  DATE = "DATE"
}

export const REPORT_COLUMNS: {
  [key in ReportColumnType]: {
    label: string,
    selectedLabel?: string,
    inputType?: InputType,
    Component?: (props) => JSX.Element
    formatter?: ReportColumnDisplayFormatter
  }
} = {
  [ReportColumnType.DATE]: {
    label: "Date",
    selectedLabel: "Appointment date",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.NOTES]: {
    label: "Notes",
    selectedLabel: "Appointment notes"
  },
  [ReportColumnType.PRACTITIONER_BILLING_PROFILE]: {
    label: "Practitioner billing profile",
    Component: (props) => <ControlledBillingProfileSelect {...props} onSelect={props.onChange} setValueAsProfile={false} />
  },
  [ReportColumnType.STATE]: {
    label: "State",
    selectedLabel: "Appointment state",
    Component: AppointmentStateSelect
  },
  [ReportColumnType.TYPE]: {
    label: "Type",
    selectedLabel: "Appointment type",
    Component: (props) => <ControlledAppointmentTypeSelect {...props} onSelect={props.onChange} />
  },
  [ReportColumnType.CALLS]: {
    label: "Calls",
    inputType: "number"
  },
  [ReportColumnType.CLAIMED]: {
    label: "Claimed",
    inputType: "number"
  },
  [ReportColumnType.DIAGNOSES]: {
    label: "Diagnoses"
  },
  [ReportColumnType.FACILITY]: {
    label: "Facility"
  },
  [ReportColumnType.OUTCOME]: {
    label: "Outcome",
    Component: AssessmentOutcomeSelect
  },
  [ReportColumnType.PAID]: {
    label: "Paid",
    inputType: "number"
  },
  [ReportColumnType.SERVICE_CODE]: {
    label: "Service code"
  },
  [ReportColumnType.SERVICE_DATE]: {
    label: "Service date",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.LAB_RESULT_DATE]: {
    label: "Date",
    selectedLabel: "Lab date",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.LAB_RESULT_DESCRIPTION]: {
    label: "Test description"
  },
  [ReportColumnType.LINKED_DOCUMENT_CATEGORY]: {
    label: "Category",
    selectedLabel: "Document category",
    Component: CategorySelect
  },
  [ReportColumnType.LINKED_DOCUMENT_DESCRIPTION]: {
    label: "Description",
    selectedLabel: "Document description"
  },
  [ReportColumnType.PRESCRIPTION_DATE]: {
    label: "Date",
    selectedLabel: "Prescription date",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.PRESCRIPTION]: {
    label: "Prescription"
  },
  [ReportColumnType.PRESCRIPTION_STATUS]: {
    label: "Status",
    selectedLabel: "Prescription status",
    Component: PrescriptionStatusSelect
  },
  [ReportColumnType.PATIENT_AGE]: {
    label: "Age",
    inputType: "number"
  },
  [ReportColumnType.PATIENT_DATE_OF_BIRTH]: {
    label: "Date of birth",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.PATIENT_GENDER]: {
    label: "Gender",
    Component: GenderSelect
  },
  [ReportColumnType.PATIENT_IDENTIFIER]: {
    label: "Identifier"
  },
  [ReportColumnType.PATIENT_IDENTIFIER_TYPE]: {
    label: "Identifier type",
    Component: IdentifierTypeSelect
  },
  [ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE]: {
    label: "Last appointment date",
    inputType: "date",
    formatter: ReportColumnDisplayFormatter.DATE
  },
  [ReportColumnType.PATIENT_NAME]: {
    label: "Name"
  },
  [ReportColumnType.PATIENT]: {
    label: "Patient"
  },
  [ReportColumnType.PATIENT_POSTAL_CODE]: {
    label: "Postal code",
    Component: (props) => <PostalCodeField {...props} fullWidth={true} />
  },
  [ReportColumnType.PRACTITIONER]: {
    label: "Practitioner",
    Component: PractitionerSelect
  }
}

export enum ReportParameterType {
  APPOINTMENT_DATE_RANGE = 'APPOINTMENT_DATE_RANGE',
  APPOINTMENT_PRACTITIONERS = 'APPOINTMENT_PRACTITIONERS',
  SERVICE_DATE_RANGE = 'SERVICE_DATE_RANGE',
  PAYMENT_DATE_RANGE = 'PAYMENT_DATE_RANGE',
  CLAIM_PRACTITIONERS = 'CLAIM_PRACTITIONERS',
  PRIMARY_PRACTITIONER = 'PRIMARY_PRACTITIONER'
}

export interface ReportParameterInput {
  id?: string
  name: string
  label: string
  version?: string
}

export interface ReportParameter {
  id?: string
  parameter: ReportParameterType
  version?: string
}

export const REPORT_PARAMETERS: { [key in ReportParameterType]: string } = {
  [ReportParameterType.APPOINTMENT_DATE_RANGE]: "Appointment date range",
  [ReportParameterType.APPOINTMENT_PRACTITIONERS]: "Appointment practitioner(s)",
  [ReportParameterType.SERVICE_DATE_RANGE]: "Service date range",
  [ReportParameterType.PAYMENT_DATE_RANGE]: "Payment date range",
  [ReportParameterType.CLAIM_PRACTITIONERS]: "Claim practitioner(s)",
  [ReportParameterType.PRIMARY_PRACTITIONER]: "Primary practitioner"
}

export interface ReportResults {
  columns: ReportResultsColumn[],
  results: ReportResult[],
  runDate: Date
}

export interface ReportResult {
  columns: ReportColumnResult[]
}

export interface ReportColumnResult {
  columnId: number
  result: string | null
}

export interface ReportResultsColumn {
  id: number,
  name: string,
  format: ReportColumnDisplayFormatter
}

export interface ReportRunInput {
  id: string,
  parameters: ReportRunParameter[]
}

export interface ReportRunParameter {
  objectIdReportRunParameter?: {
    type: ReportParameterType,
    value: string
  },
  objectIdListReportRunParameter?: {
    type: ReportParameterType,
    value: string[],
    all?: boolean
  }
  dateRangeReportRunParameter?: {
    type: ReportParameterType,
    valueStart: Date,
    valueEnd: Date
  }
}

export interface ReportQueryType {}

export enum ReportCombinatorType {
  AND = 'AND',
  OR = 'OR'
}

export const REPORT_COMBINATORS: { [key in ReportCombinatorType]: string } = {
  [ReportCombinatorType.AND]: "and",
  [ReportCombinatorType.OR]: "or"
}

export enum ReportOperatorType {
  GROUP = 'GROUP',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN_OR_EQUALS = 'LESS_THAN_OR_EQUALS',
  GREATER_THAN_OR_EQUALS = 'GREATER_THAN_OR_EQUALS',
  CONTAINS = 'CONTAINS',
  BEGINS_WITH = 'BEGINS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN'
}

export const REPORT_OPERATORS: { [key in ReportOperatorType]: string } = {
  [ReportOperatorType.GROUP]: "",
  [ReportOperatorType.EQUAL]: "is",
  [ReportOperatorType.NOT_EQUAL]: "is not",
  [ReportOperatorType.LESS_THAN]: "is before",
  [ReportOperatorType.GREATER_THAN]: "is after",
  [ReportOperatorType.LESS_THAN_OR_EQUALS]: "is or is before",
  [ReportOperatorType.GREATER_THAN_OR_EQUALS]: "is or is after",
  [ReportOperatorType.CONTAINS]: "contains",
  [ReportOperatorType.BEGINS_WITH]: "begins with",
  [ReportOperatorType.ENDS_WITH]: "ends with",
  [ReportOperatorType.DOES_NOT_CONTAIN]: "does not contain",
  [ReportOperatorType.IS_NULL]: "is null",
  [ReportOperatorType.IS_NOT_NULL]: "is not null",
  [ReportOperatorType.IS_EMPTY]: "is empty",
  [ReportOperatorType.IS_NOT_EMPTY]: "is not empty",
  [ReportOperatorType.IN]: "in",
  [ReportOperatorType.NOT_IN]: "not in",
  [ReportOperatorType.BETWEEN]: "between",
  [ReportOperatorType.NOT_BETWEEN]: "not between"
}

export interface ReportQueryInput {
  id?: string
  column: ReportColumnType | null
  operator: ReportOperatorType | null
  combinator?: ReportCombinatorType
  value1: string
  value2?: string
  rules?: ReportQueryInput[]
  order?: number
  version?: string
}

export interface ReportQuery {
  id?: string
  column: ReportColumnType
  operator: ReportOperatorType
  combinator: ReportCombinatorType
  value1: string
  value2?: string
  subqueries?: ReportQuery[]
  groupId?: string
  order: number
  version?: string
}