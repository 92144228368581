import React from "react";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

type SettingsRowProps = {
  children: React.ReactNode,
  sx?: SxProps<Theme>
}

const SettingsRow = ({ children, sx = {} }: SettingsRowProps) => (
  <Box display={'flex'} alignItems={'center'} sx={sx}>
    {children}
  </Box>
)

export default SettingsRow