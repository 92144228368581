import { gql } from "@apollo/client";
import { FILE_FRAGMENT } from '../file/FileQueries'

export const DOCUMENTS_FRAGMENT = gql`
  ${FILE_FRAGMENT}
  fragment DocumentsFragment on LinkedDocument {
    id
    documentDate
    description
    category
    file {
      ...FileDetails
    }
    audit{
      crePit
    }
    version
  }
`


export const GET_PATIENT_OTHER_DOCUMENTS = gql`
  ${DOCUMENTS_FRAGMENT}
  query GetPatientOtherDocuments($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        document {
          otherDocuments {
            ...DocumentsFragment
          }
        }
      }
    }
  }
`


export const UPDATE_LINKED_DOCUMENT = gql`
  ${DOCUMENTS_FRAGMENT}
  mutation EditDocument($tenantId: ID!, $linkedDocumentId: ID!, $input: LinkedDocumentInput!) {
    tenant(id: $tenantId) {
      patient {
        linkedDocument {
          update(id: $linkedDocumentId, input: $input) {
            ...DocumentsFragment
          }
        }
      }
    }
  }
`