import React from 'react'
import { useQuery } from '@apollo/client'
import { LoadingScreen } from '../components/LoadingScreen'
import { GenericError } from "../routes/static/ErrorPages"
import { useSagaAuthentication } from "./Auth"
import { GET_APP_SETTINGS_QUERY } from "../graphql-definitions";

interface DefaultAppSettings {
  version: string
  maxRecentResults: number
}

const defaultAppSettings: DefaultAppSettings = {
  version: '0.00.0',
  maxRecentResults: 10,
}

const AppSettingsContext = React.createContext(defaultAppSettings)

export function AppSettingsContextProvider({
  initial = defaultAppSettings,
  children,
}) {  
  const { isAuthenticated } = useSagaAuthentication()
  const { error, data } = useQuery(GET_APP_SETTINGS_QUERY, {
    fetchPolicy: 'cache-first',
  })

  let providerValues = defaultAppSettings

  if (!isAuthenticated) {
    return <></>
  }

  if (error) {
    if (isAuthenticated) {
      console.error("AccountContext", error)
      return GenericError()
    }
    else {
      return <></>
    }

  }

  if (data) {
    const {
      public: {
        appSettings: {
          appSettings: { version, maxRecentResults },
        },
      },
    } = data

    providerValues = {
      version: version,
      maxRecentResults: maxRecentResults,
    }

    return (
      <AppSettingsContext.Provider value={providerValues}>
        {children}
      </AppSettingsContext.Provider>
    )
  }

  return <LoadingScreen message={'Retrieving App Settings'} />
}

export const useAppSettingsContext = () => {
  return React.useContext(AppSettingsContext)
}
