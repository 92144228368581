import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { LinkedDocumentEditor } from "../LinkedDocumentForm/LinkedDocumentEditor";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import _get from "lodash/get";
import { useReadQuery } from "@apollo/client";


const FORM_NAME = "other_document_form"

export const EditOtherDocument = () => {
  const { document_id } = useParams()
  const { documentQueryRef } = usePatientDocumentContext()
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)
  const { data } = useReadQuery(documentQueryRef!)

  useEffect(() => {
    if(document_id){
      const otherDocuments = _get(data, 'tenant.patient.document.otherDocuments', []) as Array<LinkedDocumentType>
      const document = otherDocuments.find(document => document.id === document_id)
      setDocument(document)
    }
  }, [document_id, data]);

  return (
    <LinkedDocumentEditor
      pathName={'other-documents'}
      formName={FORM_NAME}
      document={document}
      category={DocumentCategory.OTHER_DOCUMENT}
      sectionLabel={document?.description || "Other document"}
      dataTestId={"editOtherDocument"}
    />
  )
}
