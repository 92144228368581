import * as yup from "yup";
import { PrescriptionStatus } from "../../../../../types/Prescription";
import { nullOrNumber } from "saga-client/src/utils/NumberHelpers"

const containsXYSubstring = (value) => {
  if (typeof value !== 'string') return true
  return !value.includes('<x>') && !value.includes('<y>')
};

export const schema = yup.object().shape({
  drug: yup.object().shape({
    name: yup.string().required('Required')
  }).required('Required'),
  practitionerId: yup.string()
    .when('status', {
      is: (status) => status === PrescriptionStatus.ACTIVE,
      then: (schema) => schema
        .required('Required'),
      otherwise: (schema) => schema
        .nullable()
    })
  ,
  historical: yup.boolean().nullable(),
  startDate: yup.date().typeError("Invalid date").nullable(),
  endDate: yup.date().typeError("Invalid date").nullable(),
  dosages: yup.array().of(
    yup.object().shape({
      dosageRange: yup.string().nullable(),
      dosageUnits: yup.number()
        .transform(nullOrNumber)
        .nullable(),
      frequency: yup.string().test('invalid value', "Invalid frequency", containsXYSubstring).nullable(),
      durationAmount: yup.number()
        .nullable()
        .min(1, "Must be greater than 1")
        .max(9999.99, "Must be less than 9999.99")
        .transform(nullOrNumber),
      durationUnits: yup.number()
        .transform(nullOrNumber)
        .nullable(),
      prn: yup.boolean().nullable()
    })
  ),
  quantityAmount: yup.number()
    .transform(value => nullOrNumber(value) === 0 ? null : nullOrNumber(value))
    .min(1, "Invalid")
    .max(100000, "Invalid")
    .when('status', {
      is: (status) => status === PrescriptionStatus.ACTIVE,
      then: (schema) => schema
        .typeError('Required')
        .required('Required'),
      otherwise: (schema) => schema
        .nullable()
    })
  ,
  quantityUnits: yup.number()
    .transform(nullOrNumber)
    .when('status', {
      is: (status) => status === PrescriptionStatus.ACTIVE,
      then: (schema) => schema
        .typeError('Required')
        .required('Required'),
      otherwise: (schema) => schema
        .transform(nullOrNumber)
        .nullable()
    })
  ,
  dosageForm: yup.number()
    .transform(nullOrNumber)
    .nullable(),
  refills: yup.number()
    .min(0, "Invalid")
    .max(10000, "Invalid")
    .transform(nullOrNumber)
    .nullable(),
  refillsExpire: yup.date().typeError("Invalid date").nullable(),
  route: yup.number()
    .transform(nullOrNumber)
    .nullable(),
  site: yup.number()
    .transform(nullOrNumber)
    .nullable(),
  indication: yup.string()
    .nullable(),
  additionalInstructions: yup.string().nullable(),
})

export const requiredSchema = schema.shape({
  practitionerId: yup.string()
    .required('Required'),
  quantityAmount: yup.number()
    .transform(value => nullOrNumber(value) === 0 ? null : nullOrNumber(value))
    .required('Required'),
  quantityUnits: yup.number()
    .transform(nullOrNumber)
    .required('Required')
})