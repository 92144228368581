import React from 'react'
import { LoadingButton, ButtonProps } from "saga-library/src";
import { Permission, PermissionType } from "../types/settings/Permission";
import { HasPermissionTemplate } from "./HasPermissionTemplate";

interface PermissionButtonProps extends ButtonProps{
  requiredType: PermissionType
  requiredPermission: Permission
  loading?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  dataTestId?: string,
  buttonRef?: React.Ref<HTMLButtonElement>,
  type?: 'button' | 'reset' | 'submit'
  display?: string
}

const PermissionButton = ({
  requiredType,
  requiredPermission,
  children,
  variant = "contained",
  loading = false,
  dataTestId,
  buttonRef,
  type,
  ...buttonProps
}: PermissionButtonProps) => {
  return (
      <HasPermissionTemplate
        requiredType={requiredType}
        requiredPermission={requiredPermission}
      >
        <LoadingButton
          buttonRef={buttonRef}
          dataTestId={dataTestId}
          loading={loading}
          variant={variant}
          type={type}
          {...buttonProps}
        >
          {children}
        </LoadingButton>
      </HasPermissionTemplate>
    )
}

export default PermissionButton