import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import React from 'react'

interface CardProps {
  label?: string
  labelUpper?: boolean
  children: React.ReactNode
}

export const Card = ({ label, labelUpper = true, children }: CardProps) => {
  return (
    <Paper
      variant={'outlined'}
      sx={{
        overflow: 'hidden',
        mb: 2
      }}
    >
      {label && (
        <Box
          sx={{
            backgroundColor: '#eeeeee',
            padding: 1.5,
            fontSize: 14,
            fontWeight: 600,
            borderBottom: 'solid thin #eeeeee',
          }}
        >
          {labelUpper ? label.toUpperCase() : label}
        </Box>
      )}

      <Box
        sx={{
          padding: 1.5,
        }}
      >
        {children}
      </Box>
    </Paper>
  )
}

export default Card
