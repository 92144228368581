import React from 'react'
import { GlobalResult } from "./GlobalSearchBar";
import PatientResult from "../SearchControls/Patient/PatientResult";
import { PatientSearchResult } from '../../types/patients';
import { PractitionerResult } from "../SearchControls/Practitioner/PractitionerResult";
import { PractitionerSearchResult } from '../../types/settings';

interface GlobalSearchResultProps {
  result: GlobalResult
  searchText: string
  dataTestId: string
}

export const ResultTypes = {
  patient: "PATIENT",
  practitioner: "PRACTITIONER",
  global: "GLOBAL"
}

export const GlobalSearchResult = ({
  result,
  searchText,
  dataTestId
}: GlobalSearchResultProps) => {
  if(result.resultType === ResultTypes.patient){
    return (
      <PatientResult
        patient={result as PatientSearchResult}
        searchText={searchText}
        dataTestId={`${dataTestId}-patient`}
      />
    )
  }

  if(result.resultType === ResultTypes.practitioner){
    return (
      <PractitionerResult
        practitioner={result as PractitionerSearchResult}
        searchText={searchText}
        dataTestId={`${dataTestId}-practitioner`}
      />
    )
  }

  return <></>
}
