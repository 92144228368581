import * as yup from 'yup'

export const schema = yup.object({
  patient: yup.object().required('Required'),
  practitionerIdentifiers: yup.array().of(
    yup.object({
      id: yup.string().notRequired(),
      identifier: yup.string().notRequired(),
      practitionerId: yup.string().notRequired(),
      savePractitionerLink: yup.boolean().when(["identifier", "practitionerId"], ([identifier, practitionerId], schema) => {
        if (!!identifier && !!practitionerId) {
          return schema.required('Required')
        }
        return schema.notRequired()
      })
    })
  ).notRequired()
})