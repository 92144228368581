import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import "./Schedule.css";
import { Button, Section } from "saga-library/src";
import { PractitionerScheduleListSelect } from "../../components/PractitionerScheduleListSelect";
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ScheduleCalendar } from "./components/ScheduleCalendar/ScheduleCalendar";
import {
  Eventcalendar,
  MbscCellClickEvent,
  MbscEventClickEvent,
  MbscEventUpdatedEvent
} from "@mobiscroll/react";
import moment from "moment-timezone";
import { useScheduleContext } from "../../providers/ScheduleContextProvider";
import { useAlerts } from "saga-library/src/providers/Alerts";
import {
  filterBookingPreferences,
  fixMobiscrollDateOutput,
  separateIntoIntervals
} from "./util/scheduleFunctions";
import { EventTooltip } from "./components/EventTooltip";
import { useTenantContext } from "../../providers/TenantContextProvider";
import { tenantUserSettings } from "../../utils/TenantUserSettings"
import { SidePanel } from "./components/SidePanel/SidePanel";
import { addDurationToDate } from "saga-library/src/util";
import { EditBookingPreferenceMenu } from "./components/EditBookingPrefenceMenu";
import {
  ScheduleDialogActions,
  TempEventActionType,
} from "../../utils/ScheduleProviderUtils";
import { MbscCalendarEvent } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types";
import { useAccountContext } from '../../providers/AccountContext'

const { SHOW_ROOM_SETTING, TIME_SCALE_SETTING } = tenantUserSettings

export const Schedule = memo(() => {
  const { getUserTenantSettings } = useTenantContext()
  const {
    scheduleView,
    setScheduleView,
    getScheduleData,
    goToScheduleOrAppointment,
    selectedScheduleIds,
    schedulesData,
    appointmentSlot,
    tempEventHandler,
    openScheduleIdSettings,
    openEventDialog,
    scheduleItemActions:{scheduleItems, refetchScheduleItems, loading: scheduleItemsLoading, createEvent, updateEvent}
  } = useScheduleContext()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { showErrorAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const userTenantSettings = getUserTenantSettings([TIME_SCALE_SETTING, SHOW_ROOM_SETTING])

  const [ anchorEl, setAnchorEl ] = useState<any | HTMLElement>(undefined)
  const [ toolTip, setToolTip ] = useState<{event:MbscCalendarEvent, anchorEl:HTMLElement} | null>(null)
  const [ editableBookingPreferences, setEditableBookingPreferences ] = useState<any[]|null>(null)
  const [ queriedApptIds, setQueriedApptIds ] = useState<any[]|undefined>()
  const [ searchOpen, setSearchOpen ] = useState<boolean>(false)
  const [ loadingScheduleOrAppointmentData, setLoadingScheduleOrAppointmentData ] = useState<boolean>(false)

  const timeScale = Number(userTenantSettings[TIME_SCALE_SETTING.name])
  const timerRef = useRef<any>(null)
  const { tempEvent } = tempEventHandler

  useEffect(() => {
    if(tempEvent?.action === TempEventActionType.QUICKBOOK){
      openEventDialog( ScheduleDialogActions.CREATE, tempEvent.event)
    }
  }, [tempEvent?.action]);

  useEffect(() => {
    if (queriedApptIds && queriedApptIds.length > 0 && scheduleItems.events.length > 0) {
      if (scheduleItems.events.some(e => e.id === queriedApptIds[0])) {
        const eventData = scheduleItems.events.find(e => e.id === queriedApptIds[0])
        openEventDialog(ScheduleDialogActions.EDIT, eventData)
        setQueriedApptIds([])
      }
    }
  }, [queriedApptIds, scheduleItems])

  useEffect(() => {
    if (location.search) {
      setLoadingScheduleOrAppointmentData(true)
      const appointmentId = queryParams.get("aId")
      const scheduleId = queryParams.get("sId")

      if (appointmentId || scheduleId) {
        goToScheduleOrAppointment(scheduleId, appointmentId)
          .then((results) => {
            setQueriedApptIds(results ? [results] : undefined)
            setLoadingScheduleOrAppointmentData(false)
          })
      }
    }
  },[location.search])

  const getCalendarEvents = () => {
    if(appointmentSlot.slotInfo){
      return [...scheduleItems.events, appointmentSlot.slotInfo]
    } else {
      return scheduleItems.events
    }
  }

  const onEventClicked = useCallback((event: MbscEventClickEvent, data: Eventcalendar) => {
    if(event.event.__typename === 'AppointmentSlot') {
      const newEvent = event.event
      openEventDialog(ScheduleDialogActions.CREATE, newEvent)
      return
    }
    openEventDialog(ScheduleDialogActions.EDIT, event.event)
  }, [openEventDialog])

  const onRoomWithAppointmentClick = (appointment) => {
    openEventDialog(ScheduleDialogActions.EDIT, appointment)
  }

  const onEventUpdated = (event: MbscEventUpdatedEvent, data: Eventcalendar) => {
    updateEvent(event.event)
  }

  const onScheduleSelect = (scheduleIds) => {
    if (scheduleIds.toString() !== scheduleView.schedules.toString()) {
      setScheduleView({ ...scheduleView, schedules: scheduleIds })
    }
  }

  const onSelectedDateChanged = (event, inst) => {
    if(appointmentSlot.slotInfo){
      appointmentSlot.clearSlot()
    }
    dateChanged(event.date)
  }

  const dateChanged = (date) => {
    const eventDate = fixMobiscrollDateOutput(date)

    if (eventDate !== scheduleView?.date) {
      setScheduleView({ ...scheduleView, date: eventDate })
    }
  }

  const viewTypeChanged = (viewType) => {
    if (viewType !== scheduleView.view) {
      setScheduleView({ ...scheduleView, view: viewType })
    }
  }

  const OpenAvailability = (scheduleId) => <Button
    sx={{
      p: 1,
      color: "inherit",
      fontWeight: 600,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }}
    size={"small"}
    variant={"text"}
    name={"availability_button"}
    onClick={() => { openScheduleIdSettings(scheduleId) }}
  >
    Open more availability
  </Button>

  const openSettingsPractitioners = () => <Button
    sx={{
      p: 1,
      color: "inherit",
      fontWeight: 600,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      }
    }}
    size={"small"}
    variant={"text"}
    name={"availability_button"}
    onClick={() => { navigate(buildTenantRoute(`settings/practitioners`,tenant_id)) }}
  >
    Go to Settings {'>'} Practitioners
  </Button>

  const onEventCreateFailed = (eventData) => {
    const scheduleId = eventData.event.resource || selectedScheduleIds[0]
    const endDate = getScheduleData(scheduleId)?.endDate

    if (!scheduleId) {
      showErrorAlert('Add a practitioner to a location to create an appointment.', openSettingsPractitioners())
      return
    }

    if (scheduleId && moment(endDate).isSameOrBefore(eventData.event.start)) {
      showErrorAlert("Appointment can't be booked on this schedule at this time.", OpenAvailability(scheduleId))
      return
    }

    showErrorAlert('Appointment cannot be added here.')
  }

  const onEventHoverIn = useCallback((hoverEvent: MbscEventClickEvent, inst: Eventcalendar) => {
    if (!!tempEvent?.event && tempEvent.action === TempEventActionType.DRAG) return

    hoverEvent.domEvent.preventDefault()
    const event = hoverEvent.event
    if (event.__typename === 'Appointment' || event.__typename === 'ScheduleEvent') {
      setToolTip({
        event: event,
        anchorEl: hoverEvent.domEvent.target
      })
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }, [setToolTip, anchorEl, clearTimeout, timerRef])

  const onEventHoverOut = useCallback(() => {
    timerRef.current = setTimeout(() => { setToolTip(null) }, 100)
  }, [anchorEl, toolTip])

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
  }, [])

  const onMouseLeave = useCallback(() => {
    timerRef.current = setTimeout(() => { setToolTip(null) }, 100)
  }, [])

  const onCellRightClicked = useCallback((clickEvent: MbscCellClickEvent, inst: Eventcalendar) => {
    clickEvent.domEvent.preventDefault()
    const blockStart = fixMobiscrollDateOutput(clickEvent.date)
    const blockEnd = addDurationToDate(moment.duration(timeScale, 'minutes'), moment(blockStart)).toISOString()
    const bookingPreferences = filterBookingPreferences(scheduleItems.bookingPreferences, blockStart, blockEnd)

    if (bookingPreferences && bookingPreferences.length > 0) {
      setAnchorEl(clickEvent.domEvent.currentTarget)
      setEditableBookingPreferences(bookingPreferences)
    }
  }, [timeScale, scheduleItems.bookingPreferences])

  const bookingPreferenceEvents = (events) => {
    return events.flatMap((bp) => {
      return separateIntoIntervals(bp, timeScale)
    })
  }

  const blockedTimes = (): MbscCalendarEvent[] => {
    if (scheduleView.schedules.length > 0) {
      return schedulesData.scheduleEndEvents
    }
    // there are no schedules, and the entire calendar should be blocked
    return [{
      allDay: true,
      recurring: {
        repeat: "daily",
        weekDays: "SU, MO, TU, WE, TH, FR, SA"
      },
    }]
  }

  if (loadingScheduleOrAppointmentData)
  {
    return <></>
  }

  return (
    <Section.Container>
      <PractitionerScheduleListSelect
        value={selectedScheduleIds}
        onChange={onScheduleSelect}
        onApplyTemplateComplete={refetchScheduleItems}
      />
      <Section.Column
        id={"schedule-area"}
        sx={{
          flex: '1 1 auto',
        }}
      >
          <ScheduleCalendar
            events={getCalendarEvents()}
            blockedTimes={blockedTimes()}
            cellStep={timeScale}
            resources={schedulesData.resources}
            forceDayView={schedulesData.resources && schedulesData.resources.length > 1}
            onEventCreate={createEvent}
            onEventCreateFailed={onEventCreateFailed}
            onEventClicked={onEventClicked}
            onEventUpdated={onEventUpdated}
            onEventHoverIn={onEventHoverIn}
            onEventHoverOut={onEventHoverOut}
            onCellRightClicked={onCellRightClicked}
            onSelectedDateChanged={onSelectedDateChanged}
            onScheduleDateChange={dateChanged}
            onScheduleTypeChange={viewTypeChanged}
            scheduleTemplate={[...bookingPreferenceEvents(scheduleItems.bookingPreferences), ...scheduleItems.closedHours]}
            date={scheduleView?.date}
            type={scheduleView?.view}
            selectedEvents={queriedApptIds}
            isLoading={scheduleItemsLoading}
            setSearchOpen={setSearchOpen}
            searchOpen={searchOpen}
            toggleSearchOpen={() => setSearchOpen(!searchOpen)}
            initialDate={moment(scheduleView.date)}
          />
      </Section.Column>
      <SidePanel
        searchOpen={searchOpen}
        onSearchClose={() => setSearchOpen(false)}
        onRoomWithAppointmentClick={onRoomWithAppointmentClick}
      />
      <EditBookingPreferenceMenu
        bookingPreferences={editableBookingPreferences}
        anchorEl={anchorEl}
        onItemClick={(bp)=>{
          setEditableBookingPreferences(null)
          openEventDialog(ScheduleDialogActions.EDIT, bp)
        }}
        onClose={() => {
          setEditableBookingPreferences(null)
        }}
      />
      <EventTooltip
        anchorEl={toolTip?.anchorEl}
        hoverEventData={toolTip?.event}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </Section.Container>
  )
})