import {TableList, TableListHeaderConfig } from 'saga-library/src'
import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsRow } from './DocumentsRow'
import { useReviewContext } from '../../providers/ReviewProvider'
import { DocumentCategory } from '../../../../types/Document'
import { useReadQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { ReviewDocument } from '../../../../types/inbox/ReviewDocument'

const columns: TableListHeaderConfig[] = [
  { name: 'Date', sortable: true },
  { name: 'Patient', sortable: true },
  { name: 'Category' },
  { name: 'Description' }
]

interface DocumentsListProps {
  filters: { reviewed: string; category: DocumentCategory | string }
  dataTestId: string,
}

export const DocumentsList = ({
  filters,
  dataTestId,
}: DocumentsListProps) => {
  const { userDocumentsRef, parseUserDocumentQueryResults } = useReviewContext()
  const { document_id, user_id, role_id, tenant_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { data } = useReadQuery(userDocumentsRef)
  const parsedData = useMemo(() => parseUserDocumentQueryResults(data),
    [userDocumentsRef, parseUserDocumentQueryResults]
  )

  const [filteredDocuments, setFilteredDocuments] = useState(parsedData)

  useEffect(() => {
    let filtered = parsedData

    switch (filters.category) {
      case "*":
        break
      default:
        filtered = filtered.filter((doc: ReviewDocument) => doc.linkedDocument.category === filters.category)
    }

    switch (filters.reviewed) {
      case 'unreviewed':
        filtered = filtered.filter((doc) => !doc.isReviewed)
        break
      case 'reviewed':
        filtered = filtered.filter((doc) => doc.isReviewed)
    }

    setFilteredDocuments(filtered)

    if(document_id){
      const tempDocument = filtered.find(doc => doc.id === document_id)
      if(!tempDocument){
        navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/document/`, tenant_id))
      }
    }
  }, [filters, parsedData, document_id])

  const onClick = (document) => {
    navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/documents/d/${document.id}`, tenant_id))
  }

  return (
    <TableList
      rows={[...filteredDocuments.map((doc, index) =>
          DocumentsRow(doc, () => onClick(doc), dataTestId, doc.id === document_id))
      ]}
      columns={columns}
      showCheckbox={true}
      enableSorting={true}
      emptyListComponent={{ message: "Documents appear here once received." }}
      dataTestId={`${dataTestId}-documents-list`}
    />
  )
}