import { SettingDictionaryType, SettingsDictEntryType } from "../types/settings/Setting";

export interface TenantSettingDictionaryType extends SettingDictionaryType {
  TIMEZONE: SettingsDictEntryType,
  DEFAULT_APPOINTMENT_STATE_ID: SettingsDictEntryType
}

export const tenantSettings:TenantSettingDictionaryType = {
  'TIMEZONE': {
    name: 'timezone',
    type: 'stringSetting',
    defaultValue: 'utc'
  },
  'DEFAULT_APPOINTMENT_STATE_ID': {
    name: 'default_appointment_state',
    type: 'idSetting',
    defaultValue: ''
  }
}
