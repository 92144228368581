import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";

interface BatchControlledDatePickerProps {
  label: string
  name: string
  dataTestId?: string
}

export const BatchControlledDatePicker = ({
  label,
  name,
  dataTestId
}: BatchControlledDatePickerProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <FormattedDatePicker
      dataTestId={dataTestId}
      label={label}
      name={name}
      sx={batchUpdateStyleSearch(dirtyFields[name])}
    />
  )
}
