import React from 'react'
import { TableListRowConfig, Typography } from "saga-library/src";
import { formatPractitionerId } from "../../../../components/PractitionerMaskedTextField";
import { practitionerDisplayName } from 'saga-library/src/util/formatting'

export const PractitionerRow = (practitioner, navigate): TableListRowConfig => {
  return {
    onClick: () => navigate(`p/${practitioner.id}`),
    key: practitioner.id,
    rowData: [
      {
        data: `${practitioner.lastName} ${practitioner.firstName}`,
        children:
          <Typography variant={'body1'} key={'practitioner-' + practitioner.id}>
            {practitioner.lastName}, {practitioner.firstName}
          </Typography>
        ,
      },
      {
        data: formatPractitionerId(practitioner.practitionerId),
      },
      {
        data: practitioner.userId ? practitionerDisplayName(practitioner.userFirstName, practitioner.userLastName) : '',
        children: practitioner.userId ?
          <Typography variant={'body1'}>{practitionerDisplayName(practitioner.userFirstName, practitioner.userLastName)}</Typography>
          :
          <Typography variant={'body1'} sx={{color: (theme) => theme.palette.greys.medium}}> No linked user account </Typography>
          ,
      },
      {
        data: practitioner.active ? 'Active' : 'Inactive',
      }
    ]
  }
}
