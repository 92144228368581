import React from 'react'
import { MenuItem } from "@mui/material";
import { Section, Select, TextField, PhoneType } from 'saga-library/src'
import { PractitionerCommunicationMethod } from '../../../../../types/settings/ReferralPractitioner'
import { PhoneComponentList } from 'saga-library/src/components/Phone/Phone'
import { FormFragment } from "../../../../../components/PermissionForm";

export type ContactDetailsType = {
  email: string
  phones: PhoneType[]
  communicationMethod: PractitionerCommunicationMethod
}

const defaults = {
  email: '',
  phones: [] as PhoneType[],
  communicationMethod: PractitionerCommunicationMethod.PHONE,
}

const Form = ({ requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>Contact</Section.Header>
        <TextField
          name={'email'}
          label={'Email address'}
        />
        <PhoneComponentList />
        <Select
          name={'communicationMethod'}
          label={'Preferred communication method'}
        >
          <MenuItem value={PractitionerCommunicationMethod.FAX}>Fax</MenuItem>
          <MenuItem value={PractitionerCommunicationMethod.PHONE}>Phone</MenuItem>
          <MenuItem value={PractitionerCommunicationMethod.EMAIL}>Email</MenuItem>
        </Select>
      </Section.FormSection>
    </FormFragment>
  )
}

export const ContactDetails = {
  Form: Form,
  defaults: defaults,
}
