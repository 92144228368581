import React, { useEffect, useState } from "react";
import { Section, Typography, IconButton } from "saga-library/src";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, Box } from "@mui/material";
import { AppointmentEvent } from "./ScheduleCalendar/AppointmentEvent";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { patientDisplayName } from "saga-library/src/util";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { AppointmentMethod } from "../../../types/schedule/Appointment";
import { DraggableEvent } from "./DraggableEvent";

const DATA_TEST_ID = "scheduler-shuttledAppointment"
const FORM_NAME = "rebook_appointment_panel"

export const RebookAppointmentPanel = ({isOpen, onClose, eventData, room, isMinimized}) => {
  const theme = useTheme()
  const { enableNavigationPrompt } = usePrompt()

  useEffect(() => {
    enableNavigationPrompt(isOpen, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [isOpen]);

  if (!isOpen || !eventData) {
    return null
  }

  if (isMinimized) {
    return (
      <DraggableEvent dragData={eventData} dataTestId={DATA_TEST_ID}>
        <Box
          title={eventData.reasonForVisit}
          display={'flex'}
          sx={theme => ({
            mt: '4px',
            height: '38px',
            borderRadius: 1,
            background: eventData.state.color,
            color: theme.palette.greys.dark,
            opacity: 1
          })}
        >
          <Box sx={theme => ({
            height: '100%',
            width: '8px',
            background: eventData?.type ? eventData?.type?.color : "rgba(255,255,255,0)",
            border: eventData?.type ? "none" : `1px solid ${theme.palette.greys.extraLight}`,
            flex: '0 0 auto',
            boxSizing: "border-box"
          })} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              width: '100%',
              px: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,0)',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Typography variant={'p3'} fontWeight={'bold'}>
                {patientDisplayName(eventData.patient.firstName, eventData.patient.lastName)}
              </Typography>
              <Typography variant={'p3'} sx={{paddingLeft: '8px', paddingRight:'2px'}} >
                {eventData.type?.name}
              </Typography>
              {eventData.method === AppointmentMethod.PHONE && <CallOutlinedIcon sx={{ verticalAlign: 'middle', fontSize: '16px', color:theme.palette.greys.dark}}/>}
              {eventData.method === AppointmentMethod.VIDEO && <VideocamOutlinedIcon sx={{ verticalAlign: 'middle', fontSize: '16px', color:theme.palette.greys.dark }}/>}
            </Box>
            <IconButton dataTestId={`${DATA_TEST_ID}-close`} sx={{ height: '100%' }} onClick={onClose} icon={<CloseIcon/>} />
          </Box>
        </Box>
      </DraggableEvent>
    )
  }

  return (
    <Section.Column sx={{width: '289px', overflow: "hidden"}}>
      <IconButton onClick={onClose} sx={{alignSelf: 'flex-end', mb: '18px'}} icon={<CloseIcon/>}/>
      <Typography variant={'body1'} sx={{mb: 3}}>
        Drag and drop to book appointment.
      </Typography>
      <DraggableEvent dragData={eventData} dataTestId={DATA_TEST_ID}>
        <AppointmentEvent eventData={eventData} height={'61px'} room={room} />
      </DraggableEvent>
    </Section.Column>
  )
}