import { Button } from '../Button'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import React from 'react'
import { SxProps, Theme } from "@mui/material";

interface DeleteButtonProps {
  onClick: () => void,
  sx?: SxProps<Theme>,
  dataTestId?: string
}
export const DeleteButton = ({ onClick, sx= {}, dataTestId }:DeleteButtonProps) => {
  return (
    <Button
      dataTestId={dataTestId}
      sx={{
        ...sx
      }}
      name={'delete-role'}
      onClick={onClick}
      variant={'text'}
      startIcon={<DeleteOutlineIcon />}
    >
      Delete
    </Button>
  )
}

export default DeleteButton
