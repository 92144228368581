import React, { useMemo, useState } from 'react'
import Widget from '../Widget'
import { ChartNotesIcon, SignedIcon } from '../ChartIcons'
import { EncounterNotesDocumentModal } from "../encounterNotes/EncounterNotesDocumentModal";
import { useEncounterNoteContext } from '../../providers/EncounterNoteProvider'
import { useReadQuery } from '@apollo/client'
import { EncounterNote, PatientAppointment } from '../../../../types/patients'
import { TableList, TableListCellConfig, TableListHeaderConfig, TableListRowConfig, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { EncounterNotesModal } from '../encounterNotes/EncounterNotesModal';
import { PatientAppointmentDialog } from '../encounterNotes/PatientAppointmentDialog'
import { useAppointmentContext } from '../../providers/AppointmentProvider'
import moment from 'moment';

export const EncounterNotesWidget = () => {
  const { appointmentQueryRef, getAppointmentQueryResults} = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef!)
  const patientAppointments = useMemo(() => getAppointmentQueryResults(appointmentData), [appointmentData, getAppointmentQueryResults])

  const [ openPatientAppointmentDialog, setOpenPatientAppointmentDialog]  = useState(false)
  const [ openDialog, setOpenDialog]  = useState(false)

  const [ selectedEncounterNote, setSelectedEncounterNote ] = useState<EncounterNote|null>(null)
  const [ appointment, setAppointment ] = useState<PatientAppointment|null>(null)

  const handleModalClose = () => {
    setSelectedEncounterNote(null)
    setOpenDialog(false)
    setOpenPatientAppointmentDialog(false)
  }

  const onPatientAppointmentSelectedCallback = (newAppointment) => {
    setAppointment(newAppointment)
    setOpenPatientAppointmentDialog(false)
    setOpenDialog(true)
  }

  const onPatientAppointmentModalClosed = () => {
    setOpenDialog(false)
    setOpenPatientAppointmentDialog(false)
  }

  const AddItem = () => {
    const dateNow = moment();
    let appointments = patientAppointments.filter(appointment => dateNow.isSame(appointment.start, 'day'));
    if (appointments.length === 1) {
      setAppointment(appointments[0])
      setOpenDialog(true)
    }
    else {
      setOpenPatientAppointmentDialog(true)
    }
  }

  return (
    <Widget
      route={'encounter-notes'}
      title={'Encounter notes'}
      WidgetIcon={ChartNotesIcon}
      onAddItem={AddItem}
    >
      <EncounterNotesWidgetBody setSelectedEncounterNote={setSelectedEncounterNote}/>
      {selectedEncounterNote?.isLinkedDocument && <EncounterNotesDocumentModal encounterNote={selectedEncounterNote} onModalClose={handleModalClose} />}
      <EncounterNotesModal
        openDialog={openDialog || (!!selectedEncounterNote && !selectedEncounterNote.isLinkedDocument)}
        selectedEncounterNote={selectedEncounterNote}
        appointment={appointment}
        onClose={handleModalClose}
      />
      <PatientAppointmentDialog
        openDialog={openPatientAppointmentDialog}
        patientAppointments={patientAppointments}
        onSelected={onPatientAppointmentSelectedCallback}
        onModalClose={onPatientAppointmentModalClosed}
      />
    </Widget>
  )

}

const EncounterNotesWidgetBody = ({ setSelectedEncounterNote }) => {
  const { encounterNoteQueryRef, parseEncounterNoteQueryResults } = useEncounterNoteContext()
  const { data } = useReadQuery(encounterNoteQueryRef!)
  const encounterNotes = useMemo(()=> parseEncounterNoteQueryResults(data), [data, parseEncounterNoteQueryResults])

  const onRowClick = (encounterNote) => {
    setSelectedEncounterNote(encounterNote)
  }

  const columns: TableListHeaderConfig[] = [
    { name: '', sx:{width:80} },
    { name: '', sx:{width:24} },
    { name: '', sx:{width:72} }
  ]

  return (
    <TableList
      dataTestId={'encounterNotesWidget'}
      columns={columns}
      showHeader={false}
      rows={encounterNotes.map((encounterNote) => EncounterNotesRow(encounterNote, onRowClick))}
      sx={{height: '100%', "&.MuiTableCell-root": { borderBottom: null }}}
      emptyListComponent={{ message: "Encounter notes appear here once categorized.", size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      isWidget={true}
    />
  )
}

const EncounterNotesRow = (encounterNote, onRowClick): TableListRowConfig => {
  const rowData = [
    {
      sx: { width: 80 },
      children: <EncounterNotesDate encounterNote={encounterNote}/>
    },
    {
      sx: { width: 16, px: 0 },
      children: (
        <Box display={"inline-flex"} sx={{ verticalAlign: "middle" }}>
          {encounterNote.signOffPit &&
            <SignedIcon sx={{ fontSize: "16px" }} />
          }
        </Box>
      )
    },
    {
      children: (
        <Typography
          lineclamp={1}
          variant={"body2"}
        >
          {encounterNote.template?.name || encounterNote.description || "Encounter Note"}
        </Typography>
      )
    }
  ] as TableListCellConfig[];

  return{
    onClick: () => onRowClick(encounterNote),
    rowData,
    key: `${encounterNote.id}_${encounterNote.isLinkedDocument?'d':'l'}`
  }
}

interface EncounterNotesDateProps {
  encounterNote: EncounterNote
}

const EncounterNotesDate = ({encounterNote}:EncounterNotesDateProps) => {
  const encounterDate =   useFormattedDate(encounterNote.encounterNoteDisplayDate)

  return <Typography variant={"body2"}>{encounterDate}</Typography>
}