import React from 'react'
import {
  ResultOptionsWrapper
} from "saga-library/src";
import {
  EntryTitle,
  IdentifierRow
} from "saga-library/src";
import { PractitionerSearchResult } from '../../../types/settings';
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'

interface PractitionerResultProps {
  practitioner: PractitionerSearchResult
  searchText: string
  dataTestId: string
}

export const PractitionerResult = ({
  practitioner,
  searchText,
  dataTestId,
}: PractitionerResultProps) => {
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const openPractitionerInNewTab = () => {
    window.open(buildTenantRoute(
      `settings/practitioners/p/${practitioner.id}`, tenant_id
    ))
  }

  return (
    <ResultOptionsWrapper
      dataTestId={dataTestId}
      options={{
        onOpenInNewTab: openPractitionerInNewTab
      }}
    >
      <EntryTitle
        title={practitionerDisplayName(practitioner.firstName, practitioner.lastName)}
        searchText={searchText}
        dataTestId={`${dataTestId}-name`}
      />
      <IdentifierRow
        label={'ID'}
        identifier={practitioner.primaryIdentifier || 'None'}
        searchText={searchText}
        dataTestId={`${dataTestId}-identifier`}
      />
    </ResultOptionsWrapper>
  )
}
