import { Box, useTheme } from "@mui/material";
import React from "react";

export const CalendarAddIcon = ({color=null}:{color: null | undefined | string}) => {
  const theme = useTheme()

  return <Box color={color || theme.palette.greys.light} height={"24px"}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_24_56773" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_24_56773)">
        <path
          d="M17 22V19H14V17H17V14H19V17H22V19H19V22H17ZM5 20C4.45 20 3.97917 19.8042 3.5875 19.4125C3.19583 19.0208 3 18.55 3 18V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H14V2H16V4H17C17.55 4 18.0208 4.19583 18.4125 4.5875C18.8042 4.97917 19 5.45 19 6V12.1C18.6667 12.05 18.3333 12.025 18 12.025C17.6667 12.025 17.3333 12.05 17 12.1V10H5V18H12C12 18.3333 12.025 18.6667 12.075 19C12.125 19.3333 12.2167 19.6667 12.35 20H5ZM5 8H17V6H5V8Z"
          fill="currentColor"/>
      </g>
    </svg>
  </Box>
}