import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { AppointmentType, AppointmentTypeInput } from "../../../../types/schedule";
import { useMutation } from "@apollo/client";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AppointmentTypeForm } from "./AppointmentTypeForm";
import { ConfirmationDialog, DialogV2, RemoveButton } from "saga-library/src";
import { schema } from "../util/appointmentTypeValidation";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { DELETE_APPOINTMENT_TYPE, LIST_APPOINTMENT_TYPES, UPDATE_APPOINTMENT_TYPE } from "../../../../graphql-definitions";

const FORM_NAME = "edit_appointment_type_form"
interface EditAppointmentTypeModalTempProps {
  open: boolean
  onClose: () => void
  appointmentType: AppointmentType
}

export const EditAppointmentTypeModal = ({
  open,
  onClose,
  appointmentType
}: EditAppointmentTypeModalTempProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [updateAppointmentType, {loading, error}] =
    useMutation(UPDATE_APPOINTMENT_TYPE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment type has been saved.')
        reset(originalDefault)
        onClose()
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const [deleteAppointmentType] =
    useMutation(DELETE_APPOINTMENT_TYPE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment type has been deleted.')
        reset(originalDefault)
        onClose()
        setOpenDeleteDialog(false)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const handleDeleteAppointmentType = () => {
    deleteAppointmentType({
      variables: {
        tenantId: tenant_id,
        appointmentTypeId: appointmentType.id,
        version: appointmentType.version
      },
      update(cache) {
        cache.updateQuery({
            query: LIST_APPOINTMENT_TYPES,
            variables: { tenantId: tenant_id }},
          (data) => {
          let list = [...data.tenant.schedule.type.listTypes.filter(at => at.id !== appointmentType.id)]
            return {
              tenant: {
                schedule: {
                  type: {
                    listTypes: list
                  }
                }
              }
            }
          }
        )
      }
    })
  }

  const initialSchedules = appointmentType.schedules.map((schedule) => {
    return ({
      key: schedule.id,
      id: schedule.id,
      name: `${schedule.practitionerLastName}, ${schedule.practitionerFirstName} (${schedule.location.name})`
    })
  })

  const originalDefault = {
    name: appointmentType.name,
    color: appointmentType.color,
    duration: appointmentType.duration,
    inPerson: appointmentType.inPerson,
    byPhone: appointmentType.byPhone,
    videoCall: appointmentType.videoCall,
    allSchedules: appointmentType.allSchedules,
    schedules: appointmentType.allSchedules ? [{key: 'allSchedules', id: 'allSchedules', name: 'All schedules'}] : initialSchedules,
    scheduleIds: appointmentType.allSchedules ? [] : appointmentType.schedules.map(schedule => schedule.id),
    isDeleted: false,
    version: appointmentType.version
  }

  const formMethods = useForm<AppointmentTypeInput>({
    defaultValues: originalDefault,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
      reset()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    const allSchedulesCheck = data.schedules && data.schedules.find(s => s.id === 'allSchedules')
    if (allSchedulesCheck) {
      data.allSchedules = true
      data.scheduleIds = []
    } else {
      data.allSchedules = false
      data.scheduleIds = data.schedules ? data.schedules.map(s => s.id) : []
    }
    delete data.schedules

    data.duration = +(data.duration ?? '0')

    await updateAppointmentType({
      variables: {
        tenantId: tenant_id,
        appointmentTypeId: appointmentType.id,
        appointmentTypeInput: data
      },
    })
  })

  return (
    <>
      <DialogV2
        title={`Edit appointment type (${appointmentType.name})`}
        open={open}
        onClose={handleClose}
        primaryAction={()=>null}
        overridePrimaryComponent={
          <PermissionButton
            name={'saveAppointmentType'}
            onClick={onSubmit}
            requiredType={PermissionType.Schedule}
            requiredPermission={Permission.READWRITE}
            dataTestId={"editAppointmentTypeModal-save-button"}
            loading={isSubmitting}
            type={'submit'}
          >
            Save
          </PermissionButton>
        }
        headerActions={[
          <RemoveButton
            onClick={() => setOpenDeleteDialog(true)}
            dataTestId={"editAppointmentTypeModal"}
          />
        ]}
        data-testid={"editAppointmentTypeModal-dialog"}
      >
        <FormProvider {...formMethods}>
          <AppointmentTypeForm
            onSubmit={onSubmit}
          />
        </FormProvider>
      </DialogV2>
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={'Delete appointment type?'}
        message={'Once deleted, this appointment type cannot be used in future appointments. Existing appointments using this appointment type will not be affected. This action can’t be undone.'}
        primaryAction={handleDeleteAppointmentType}
        primaryLabel={'Delete'}
        dataTestId={"editAppointmentTypeModal-dialog-delete"}
      />
    </>
  )
}
