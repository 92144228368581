import React from "react"
import { DocumentListSection } from "./components/DocumentListSection"
import { DocumentProvider } from "../../../providers/DocumentProvider"
import { ImportedDocumentsStateProvider } from "./components/ImportedDocumentsStateProvider";
import { LinkDocumentSection } from "./components/LinkDocumentSection";
import { DocumentView } from "./components/DocumentView";
import { ImportedDocumentsActionProvider } from "./components/ImportedDocumentsActionProvider";

const DOCUMENTS_LIST_WIDTH = '289px'

export const ImportedDocuments = () => {
  return <ImportedDocumentsStateProvider>
    <DocumentProvider>
      <ImportedDocumentsActionProvider>
        <DocumentListSection sx={{width: DOCUMENTS_LIST_WIDTH}} />
        <LinkDocumentSection />
        <DocumentView sx={{ flex: "1 1 auto"}}/>
      </ImportedDocumentsActionProvider>
    </DocumentProvider>
  </ImportedDocumentsStateProvider>
}