import * as yup from 'yup'
import moment from "moment-timezone";

export const schema = yup.object().shape({
  title: yup.string().test(
    "oneOfRequired",
    "At least one of the fields is required",
    function (value) {
      return (this.parent.title && this.parent.title !== '') || (this.parent.appointmentTypes && this.parent.appointmentTypes.length > 0);
    }),
  start: yup
    .string()
    .required('Required'),
  end: yup
    .string()
    .required('Required')
    .test('end_time_is_greater', 'End time should be greater than start time', function(value) {
      const { start } = this.parent;
      return moment(value).isSameOrAfter(moment(start));
    }),
  appointmentTypes: yup.array().test(
    "oneOfRequired",
    "At least one of the fields is required",
    function (value) {
      return (this.parent.title && this.parent.title !== '') || (this.parent.appointmentTypes && this.parent.appointmentTypes.length > 0);
    })
}, [['title', 'appointmentTypes']])
