import React, { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { QueryRef, useBackgroundQuery, useMutation, useQuery, useLoadableQuery } from '@apollo/client'
import {
  DELETE_CLAIMS,
  GET_CLAIM,
  HOLD_CLAIMS,
  LIST_UNSUBMITTEDCLAIMS,
  UNHOLD_CLAIMS, UPDATE_CLAIM
} from '../../../graphql-definitions'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import { setAbClaimValues } from '../util/setAbClaimValues'
import { setProblems } from '../util/setProblems'


interface UnsubmittedClaimsContextInterface {
  unsubmittedClaimsQueryRef: QueryRef | null,
  redirectToNewlyCreatedClaim: boolean,
  setRedirectToNewlyCreatedClaim: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentPatientId: (number) => void,
  currentPatientId: string | null,
  selectedClaimIds: string[],
  setSelectedClaimIds: ([]) => void,
  batchHoldClaims: () => void,
  batchUnholdClaims: () => void,
  batchDeleteClaims: () => void,
  getClaim: (variables) => void,
  getClaimQueryRef: QueryRef | null
  updateClaim: (claim_id, claimData) => void,
}

const defaultUnsubmittedClaimsContext: UnsubmittedClaimsContextInterface = {
  unsubmittedClaimsQueryRef: null,
  redirectToNewlyCreatedClaim: false,
  setRedirectToNewlyCreatedClaim: (arg) => null,
  setCurrentPatientId: (arg) => null,
  currentPatientId: null,
  selectedClaimIds: [],
  setSelectedClaimIds: (arg) => null,
  batchHoldClaims: () => null,
  batchUnholdClaims: () => null,
  batchDeleteClaims: () => null,
  getClaim: (variables) => null,
  getClaimQueryRef: null,
  updateClaim: (claim_id, claimData) => null
}

const UnsubmittedClaimsContext = React.createContext(defaultUnsubmittedClaimsContext)

export const UnsubmittedClaimsProvider = () => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert, showWarningAlert } = useAlerts()
  const [ currentPatientId, setCurrentPatientId ] = React.useState('')
  const [ redirectToNewlyCreatedClaim, setRedirectToNewlyCreatedClaim ] = useState(false)
  const [ selectedClaimIds, setSelectedClaimIds ] = useState<string[]>([])

  const [queryRef] = useBackgroundQuery(LIST_UNSUBMITTEDCLAIMS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
  })

  const [getClaim, getClaimQueryRef] = useLoadableQuery(GET_CLAIM, {fetchPolicy: 'cache-and-network'})

  const [holdClaims, { error: holdError }] = useMutation(HOLD_CLAIMS)
  const [unholdClaims, { error: unholdError } ] = useMutation(UNHOLD_CLAIMS)
  const [deleteClaims, { error: deleteError }] = useMutation(DELETE_CLAIMS)
  const [updateClaimMutation, { error: updateError }] = useMutation(UPDATE_CLAIM)


  const updateClaim = async(claim_id, claimData) => {
    await updateClaimMutation({
      variables: {
        tenantId: tenant_id,
        claimId: claim_id,
        claimData: claimData,
      },
      onCompleted: (data) => {
        const problems = _get(data, 'tenant.abClaim.updateAbClaim.problems', null)
        if (problems.length > 0) {
          problems.filter(problem => problem.severity === 'ERROR').length === 0 ?
            showWarningAlert("Claim has been updated but contains warnings. If submitted as is, the claim may be be rejected by AHCIP.")
            :
            showWarningAlert("Claim has been updated but contains errors. It cannot be submitted until all errors are resolved.")
        } else {
          showSuccessAlert("Claim has been updated.")
        }
      }
    })
  }


  const batchHoldClaims = async() => {
    await holdClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        setSelectedClaimIds([])
        showSuccessAlert(`Claims have been held.`)
      },
      onError: () => {
        console.error(JSON.stringify(holdError, null, 2))
      }
    })
  }

  const batchUnholdClaims = async() => {
    await unholdClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        setSelectedClaimIds([])
        showSuccessAlert(`Claims have been unheld.`)
      },
      onError: () => {
        console.error(JSON.stringify(unholdError, null, 2))
      }
    })
  }

  const batchDeleteClaims = async() => {
    await deleteClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        setSelectedClaimIds([])
        showSuccessAlert(`${selectedClaimIds.length} claims have been deleted.`)
      },
      onError: () => {
        console.error(JSON.stringify(deleteError, null, 2))
      }
    })
  }



  const providerValues = {
    unsubmittedClaimsQueryRef: queryRef,
    setRedirectToNewlyCreatedClaim,
    redirectToNewlyCreatedClaim,
    setCurrentPatientId,
    currentPatientId,
    setSelectedClaimIds,
    selectedClaimIds,
    batchHoldClaims,
    batchUnholdClaims,
    batchDeleteClaims,
    getClaim,
    getClaimQueryRef,
    updateClaim
  }

  return (
    <UnsubmittedClaimsContext.Provider value={providerValues}>
      { queryRef && <Outlet /> }
    </UnsubmittedClaimsContext.Provider>
  )
}

export const useUnsubmittedClaimsContext = () => {
  return React.useContext(UnsubmittedClaimsContext)
}