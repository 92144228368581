import * as yup from "yup";

export const schema = yup.object().shape({
  practitionerId: yup.string().required("Required"),
  title: yup.string().required("Required"),
  content: yup.string(),
  letterDate: yup.date().required("Required"),
  letterTemplateId: yup.string().nullable(),
  notes: yup.string(),
  recipientIds: yup.array().of(yup.string()),
  recipients: yup.array().of(yup.object())
})