import React, { useEffect, useState, useCallback, memo } from "react";
import {
  Eventcalendar,
  formatDate,
  MbscCalendarEventData,
  MbscCalendarColor,
  MbscEventCreateEvent,
  MbscEventClickEvent,
  MbscResource,
  MbscEventUpdatedEvent,
  MbscCellClickEvent,
  MbscCalendarEvent,
  MbscEventCreateFailedEvent
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css"
import {
  Box,
  useTheme
} from "@mui/material";
import { Typography } from 'saga-library/src'
import moment, { Moment } from "moment-timezone";
import { momentTimezone } from '@mobiscroll/react';
import {default as momentTZ} from 'moment-timezone'
import { ScheduleBlockEvent } from "./ScheduleBlockEvent";
import { AppointmentEvent } from './AppointmentEvent'
import { NoScheduleEvent } from "./NoScheduleEvent";
import { NewAppointmentEvent } from "./NewAppointmentEvent"
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { CONDENSED_ROW_HEIGHT, SCHEDULE_COLUMN_MIN_WIDTH } from '../../../../utils/ScheduleConstants'
import { ScheduleLength } from "../../../../types/schedule/Schedule";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { processEvents, insertDataTestIds } from "../../util/scheduleFunctions";
import { AppointmentSlotEvent } from "./AppoinmentSlotEvent";
import { TempEventActionType } from "../../../../utils/ScheduleProviderUtils";
import { ScheduleHeader } from "./ScheduleHeader";

interface ScheduleCalendarProps{
  toggleSearchOpen: () => void
  events?: MbscCalendarEvent[]
  resources?: any[]
  closedHours?: MbscCalendarEvent[]
  blockedTimes?: MbscCalendarEvent[]
  scheduleTemplate?: MbscCalendarColor[]
  cellStep?: number
  labelStep?: number
  forceDayView?: boolean
  onEventCreate?: (event: MbscEventCreateEvent, data: Eventcalendar) => void
  onEventClicked?: (event: MbscEventClickEvent, data: Eventcalendar) => void
  onEventUpdated?: (event: MbscEventUpdatedEvent, data: Eventcalendar) => void
  onEventHoverIn?: (event: MbscEventClickEvent, data: Eventcalendar) => void
  onEventHoverOut?: () => void
  onCellRightClicked?: (event: MbscCellClickEvent, data: Eventcalendar) => void
  onSelectedDateChanged?: (event: MbscEventClickEvent, data: Eventcalendar) => void
  onScheduleTypeChange?: (string) => void,
  onScheduleDateChange?: (date: Date) => void,
  date: string | null
  type?: ScheduleLength,
  selectedEvents?: MbscCalendarEvent[],
  onEventCreateFailed:(event: MbscEventCreateFailedEvent, data: Eventcalendar)=>void
  isLoading: boolean
  setSearchOpen: (searchOpenState: boolean) => void
  searchOpen: boolean
  initialDate?: Moment
}

export const ScheduleCalendar = memo(({
  events = [],
  resources,
  blockedTimes = [],
  scheduleTemplate = [],
  cellStep = 15,
  labelStep = 60,
  forceDayView = false,
  onEventCreate,
  onEventClicked,
  onEventUpdated,
  onEventHoverIn,
  onEventHoverOut,
  onCellRightClicked,
  onSelectedDateChanged,
  onScheduleTypeChange,
  onEventCreateFailed,
  date,
  type = ScheduleLength.DAY,
  selectedEvents=[],
  isLoading,
  onScheduleDateChange,
  toggleSearchOpen,
  initialDate
}:ScheduleCalendarProps ) => {
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const { tempEventHandler, schedulesData:{rooms}, appointmentSlot } = useScheduleContext()
  const [ initDate, setInitDate ] = useState<null|Moment>(null)
  const theme = useTheme()

  const [ scheduleType, setScheduleType ] = useState<ScheduleLength>(type);
  const [ scrollXPosition, setScrollXPosition ] = useState<number>(0)
  const [instance, setInstance] = React.useState<Eventcalendar>();
  const scheduleRef = React.useRef(null)
  const { tempEvent, setDragEvent, clearTempEvent } = tempEventHandler

  momentTimezone.moment = momentTZ

  const setDraggedEvent = event => {
    setDragEvent(event)
  }

  const clearDraggedEvent = () => {
    if (tempEvent?.action === TempEventActionType.DRAG) {
      clearTempEvent()
    }
  }

  useEffect(()=>{
    if(onScheduleTypeChange){
      onScheduleTypeChange(scheduleType)
    }
  },[scheduleType])

  useEffect(()=>{
    if(forceDayView && scheduleType !== ScheduleLength.DAY){
      setScheduleType(ScheduleLength.DAY)
    }
  }, [forceDayView])


  useEffect(()=>{
    if(!initDate && initialDate && instance){
      // Mobiscroll seems to use local time in their scroll height calculation somewhere.
      // This calculation finds the difference between the timezones and corrects for it.
      let offset = new Date().getTimezoneOffset() + initialDate.utcOffset()
      let scrollTime = moment(initialDate).add(offset, "minutes")
      instance.navigate(scrollTime, false)
      setInitDate(initialDate)
    }
  }, [initialDate, instance])

  useEffect(() => {
    insertDataTestIds(scheduleRef, date, 'scheduler', type, cellStep)
  }, [JSON.stringify(events), scheduleRef, cellStep, date, type])

  useEffect(() => {
    const scheduleScrollContainer = document.getElementsByClassName('mbsc-schedule-grid-scroll')?.[0]

    const handleScroll = () => setScrollXPosition(scheduleScrollContainer?.scrollLeft ?? 0)

    scheduleScrollContainer?.addEventListener('scroll', handleScroll)
    return () => {
      scheduleScrollContainer?.removeEventListener('scroll', handleScroll)
    }
  }, [])
  
  useEffect(() => {
    setTimeout(() => {
      if (appointmentSlot.slotInfo) instance?.navigateToEvent(appointmentSlot.slotInfo)
    }, 0)
  }, [appointmentSlot.slotInfo, instance])

   const scheduleHeader =useCallback(()=>{
    return <ScheduleHeader
      date={date}
      dateChanged={onScheduleDateChange}
      showViewSelector={resources && resources.length > 1}
      instance={instance}
      setScheduleType={setScheduleType}
      toggleSearchOpen={toggleSearchOpen}
      scheduleType={scheduleType}
    />
  }, [date, onScheduleDateChange, resources, scheduleType, toggleSearchOpen, instance])

  const scheduleEvent = (data: MbscCalendarEventData) => {
    const eventData = data.original
    switch(eventData?.__typename) {
      case"Appointment":
        return <AppointmentEvent
          eventData={eventData}
          room={rooms?.find(room => room.appointment?.id === eventData.id)}
        />
      case"ScheduleEvent":
        return <ScheduleBlockEvent eventData={eventData}/>
      case "TemplateEvent":
        return <ScheduleBlockEvent eventData={eventData}/>
      case "TemplateAppliedEvent":
        return <ScheduleBlockEvent eventData={eventData}/>
      case "AppointmentSlot":
        return <AppointmentSlotEvent eventData={eventData}/>
      case "ScheduleEnd":
        return <NoScheduleEvent eventData={eventData}/>
      default:
        if (eventData?.title === "New event" && eventData?.id?.toString().startsWith("mbsc")) {
          return <NewAppointmentEvent eventData={eventData}/>
        } else {
          return <Box className={"md-custom-event-cont"}>Unknown event</Box>
        }
    }
  }

  const resourceHeader = (resource: MbscResource) => {
    if(resource.name && resources){
      if (resources.length > 1) {
        return <Box sx={theme => ({
          borderBottom: `thin solid ${theme.palette.greys.light}`
        })}>
          <Typography variant={'h3'} sx={{fontSize: "16px"}}> {resource.name} </Typography>
          <Typography variant={'body2'}> {resource.location} </Typography>
        </Box>
      }
    }
  }

  const dayHeader = (args) => {
    const {date, selected} = args

    let bgColor = 'transparent'
    let labelColor = theme.palette.greys.medium
    let labelWeight = 'default'
    if(moment(date).isSame(moment().toDate(), "day")){
      labelWeight = "bold"
      labelColor = theme.palette.primary.main
    }
    if(selected && scheduleType === ScheduleLength.DAY){
      bgColor = theme.palette.primary.main
      labelColor = 'white'
      labelWeight = 'default'
    }

    return (
      <Box overflow={'hidden'}>
        <Box bgcolor={ bgColor } borderRadius={2} data-testid={`schedule-dayHeader-${formatDate('DDDD', date)}`}>
          <Typography
            color={ labelColor }
            fontWeight={ labelWeight }
          >
            {`${formatDate('DDDD', date)} ${formatDate('DD', date)}`}
          </Typography>
        </Box>
      </Box>
    )
  }

  return <Box sx={{
    height: "100%",
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    "& .mbsc-calendar-controls": {
      pt: 0,
    },
    "& .mbsc-schedule-time-wrapper":{
      height: CONDENSED_ROW_HEIGHT
    },
    "& .mbsc-schedule-item":{
      height: CONDENSED_ROW_HEIGHT
    },
    "& .mbsc-schedule-col-width": {
      minWidth: SCHEDULE_COLUMN_MIN_WIDTH
    },
    "& .mbsc-schedule-resource-group": {
      minWidth: 0
    },
    "& .mbsc-schedule-header-item": {
      minWidth: 0
    },
    "& .mbsc-flex:has(> .mbsc-schedule-header-item)": {
      transform: `translateX(${scrollXPosition}px)`
    }
  }}>
    <Eventcalendar
      ref={scheduleRef}
      onInit={(e, i) => {
        setInstance(i)
      }}
      data={processEvents(events)}
      showEventTooltip={false}
      invalid={blockedTimes}
      renderHeader={scheduleHeader}
      renderScheduleEvent = {scheduleEvent}
      renderDay={dayHeader}
      view={{
        schedule:{
          allDay: true,
          type: scheduleType,
          timeCellStep: cellStep,
          timeLabelStep: labelStep,
          days: true
        }
      }}
      clickToCreate={'single'}
      onEventCreated={onEventCreate}
      onEventCreate={(e, v) => !e.event.allDay}
      onEventClick={onEventClicked}
      onEventUpdated={onEventUpdated}
      onEventHoverIn={onEventHoverIn}
      onEventHoverOut={onEventHoverOut}
      onCellRightClick={onCellRightClicked}
      theme={"material"}
      themeVariant={"light"}
      colors={scheduleTemplate}
      resources={resources && resources?.length > 1 ? resources : undefined}
      renderResource={resourceHeader}
      onSelectedDateChange={onSelectedDateChanged}
      dataTimezone={'utc'}
      displayTimezone={timezone as string}
      timezonePlugin={momentTimezone}
      invalidateEvent="strict"
      onEventCreateFailed={onEventCreateFailed}
      selectedEvents={selectedEvents}
      onEventDragStart={setDraggedEvent}
      onEventDragEnd={clearDraggedEvent}
      externalDrop={true}
      externalDrag={true}
      dragToMove={true}
      dragToCreate={true}
      dragTimeStep={cellStep}
    />
    {isLoading && <LoadingSpinner overlay={true} /> }
  </Box>
})