import { DocumentCategory } from "../Document";
import { DocumentNode } from "graphql/language";
import { ALLERGY_FRAGMENT } from "../../graphql-definitions/tenant/patient/AllergyQueries";
import { APPOINTMENT_FRAGMENT } from "../../graphql-definitions/tenant/schedule/AppointmentQueries";
import {
  CONDITION_AND_PROCEDURE_FRAGMENT
} from "../../graphql-definitions/tenant/patient/ConditionAndProceduresQueries";
import { ENCOUNTER_NOTE_FRAGMENT } from "../../graphql-definitions/tenant/patient/EncounterNoteQueries";
import { FORM_FRAGMENT } from "../../graphql-definitions/tenant/patient/FormQueries";
import { LAB_AND_INVESTIGATION_FRAGMENT } from "../../graphql-definitions/tenant/patient/LabAndInvestigationQueries";
import { LETTER_FRAGMENT } from "../../graphql-definitions/tenant/patient/LetterQueries";
import { DOCUMENTS_FRAGMENT } from "../../graphql-definitions/tenant/patient/LinkedDocumentQueries";
import { PRESCRIPTION_FRAGMENT } from "../../graphql-definitions/tenant/patient/PrescriptionQueries";

export type TimelineGroupType = "upcoming" | "thisWeek" | "thisMonth" | "thisYear" | "thisDecade" | "lifetime"

export type TimelineType = {
  [group in TimelineGroupType]: TimelineEventGroupType[]
}

export type TIMELINE_EVENT_TYPES =
  "ALLERGY" |
  "APPOINTMENT" |
  "CONDITION_AND_PROCEDURE" |
  "ENCOUNTER_NOTE" |
  "FORM" |
  "LAB_AND_INVESTIGATION" |
  "LETTER" |
  "OTHER_DOCUMENT" |
  "PRESCRIPTION"

export const TimelineCacheMappings: Map<TIMELINE_EVENT_TYPES, { typename: string, fragment: DocumentNode, fragmentName: string }> = new Map([
  ["ALLERGY", { typename: "Allergy", fragment: ALLERGY_FRAGMENT, fragmentName: "AllergyFragment" }],
  ["APPOINTMENT", { typename: "Appointment", fragment: APPOINTMENT_FRAGMENT, fragmentName: "AppointmentFragment" }],
  ["CONDITION_AND_PROCEDURE", { typename: "ConditionAndProcedure", fragment: CONDITION_AND_PROCEDURE_FRAGMENT, fragmentName: "ConditionAndProcedureFragment" }],
  ["ENCOUNTER_NOTE", { typename: "EncounterNote", fragment: ENCOUNTER_NOTE_FRAGMENT, fragmentName: "EncounterNoteFragment" }],
  ["FORM", { typename: "Form", fragment: FORM_FRAGMENT, fragmentName: "FormFragment" }],
  ["LAB_AND_INVESTIGATION", { typename: "AbLabResult", fragment: LAB_AND_INVESTIGATION_FRAGMENT, fragmentName: "LabAndInvestigationFragment" }],
  ["LETTER", { typename: "Letter", fragment: LETTER_FRAGMENT, fragmentName: "LetterFragment" }],
  ["OTHER_DOCUMENT", { typename: "LinkedDocument", fragment: DOCUMENTS_FRAGMENT, fragmentName: "DocumentsFragment" }],
  ["PRESCRIPTION", { typename: "Prescription", fragment: PRESCRIPTION_FRAGMENT, fragmentName: "PrescriptionFragment" }]
])

export const TimelineDocumentCategoryMappings: Map<DocumentCategory, { category: TIMELINE_EVENT_TYPES, description: string }> = new Map ([
  [DocumentCategory.CHART_NOTE, { category: "ENCOUNTER_NOTE", description: "Encounter note" }],
  [DocumentCategory.FORM, { category: "FORM", description: "Form" }],
  [DocumentCategory.INVESTIGATION, { category: "LAB_AND_INVESTIGATION", description: "Investigation" }],
  [DocumentCategory.LAB_RESULT, { category: "LAB_AND_INVESTIGATION", description: "Lab result" }],
  [DocumentCategory.LETTER, { category: "LETTER", description: "Letter" }],
  [DocumentCategory.OTHER_DOCUMENT, { category: "OTHER_DOCUMENT", description: "Other document" }]
])

export const EVENT_GROUP_NAMES: { [group in TimelineGroupType]: string } = {
  upcoming: "Upcoming",
  thisWeek: "This week",
  thisMonth: "This month",
  thisYear: "This year",
  thisDecade: "This decade",
  lifetime: "Lifetime"
}

export type TimelineEventGroupType = {
  date: string
  dateFromNow: string
  events: TimelineEventType[]
}

export type TimelineEventType = {
  id: string
  name: string
  type: TIMELINE_EVENT_TYPES
  date?: Date | string | null
  details?: string[]
  isLab?: boolean
  isLinkedDocument?: boolean
  practitionerName?: string
  abnormal?: boolean
  crePit?: Date | null
}