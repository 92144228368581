import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import { Button, SimpleTextField } from "saga-library/src";

type LetterFormTextSelectionProps = {
  onSelect: (text: string) => void
  dataTestId: string
}

export const LetterFormTextSelection = ({
  onSelect,
  dataTestId
}: LetterFormTextSelectionProps) => {
  const ref = useRef<any>(null)
  const [value, setValue] = useState<string>("")

  return (
    <Box ref={ref} display={'flex'} flexDirection={'column'} gap={1} height={'100%'}>
      <SimpleTextField
        label={"Text"}
        value={value}
        onChange={(event) => {
          setValue(event.currentTarget.value)
        }}
        autoFocus={true}
        multiline={true}
        minRows={3}
        hideHelperText={true}
        inputProps={{ style: { 'maxHeight': '100%', 'overflow': 'auto' } }}
        InputProps={{ sx: { mt: 1 } }}
        InputLabelProps={{ sx: { mt: 1 } }}
        sx={{ mt: 0, overflow: 'hidden' }}
        dataTestId={dataTestId}
      />
      <Button
        name={"done"}
        onClick={() => onSelect(value)}
        sx={{ flexShrink: 0 }}
        dataTestId={`${dataTestId}-done-button`}
      >
        Done
      </Button>
    </Box>
  )
}