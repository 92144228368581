import React from 'react'
import { StaticPage } from '../../components/Layouts'

export const ResourceNotFound = () => (
  <StaticPage>Sorry, we could not find the requested resource.</StaticPage>
)

export const GenericError = () => (
  <StaticPage>Sorry, we've encountered a problem.</StaticPage>
)

export const PermissionDenied = () => (
  <StaticPage>
    Permission denied. You do not have access to the requested resource.
  </StaticPage>
)
