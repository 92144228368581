import React from "react";
import { Section, Typography, SimpleCheckbox } from "saga-library/src";
import { PermissionType } from "../../../../../types/settings/Permission";
import { FormFragment } from "../../../../../components/PermissionForm";
import { Box, Tooltip } from "@mui/material";
import { useLocationContext } from "../../../../../providers/LocationContextProvider";
import { useFieldArray, useFormContext } from "saga-library/src/components/Form";
import { ScheduleType } from "../../../../../types/schedule";


const defaults = {
  scheduleLocationIds: []
}

const Form = () => {
  const PERMISSION_REQUIRED = PermissionType.Practitioner

  return (
    <Section.Container>
      <Section.ColumnNoElevation>
        <FormFragment requiredPermissionType={PERMISSION_REQUIRED} readOnlyOverride={true}>
          <Section.FormSection>
            <Typography variant={'body1'} sx={{mb: 3}}>
              Select the locations at which to create a schedule for this practitioner. New schedules will be opened for 30 days from the date on which they
              are created with default open hours of 8 AM to 5 PM from Monday through Friday. Once created, they will appear on the schedule and can
              be customized as desired. Schedules that contain future appointments cannot be removed.
            </Typography>
            <Typography variant={'body1'} fontWeight={'bold'} sx={{mb: 1}}>
              This practitioner has a schedule at the following locations
            </Typography>
            <PractitionerSchedules />
          </Section.FormSection>
        </FormFragment>
      </Section.ColumnNoElevation>
    </Section.Container>
  )
}

export const PractitionerSchedules = ({disabled = false}) => {

  const locations = useLocationContext().locations
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedules", 
  })

  return (
    <Box>
      {locations.map(location => {
        const schedule = fields.find(f => (f as ScheduleType).locationId === location.id) as ScheduleType | undefined
        return (
          <Tooltip title={schedule?.hasFutureAppointments ? 'This schedule contains future appointments and cannot be removed.' : ''}>
            <span>
              <SimpleCheckbox
                size={'small'}
                label={location.name}
                value={!!schedule}
                onChange={() => {
                  if(schedule) {
                    remove(fields.findIndex(f => f.id === schedule.id))
                  } else {
                    append({
                      locationId: location.id,
                      hasFutureAppointments: false
                    })
                  }
                }}
                disabled={schedule?.hasFutureAppointments || disabled}
                dataTestId={`practitionerSchedulesDetails-location-checkBox-${location.name}`}
              />
            </span>
          </Tooltip>
        )
      })}
    </Box>
  )
}

export type PractitionerSchedulesDetailsType = {
  schedules?: ScheduleType[]
}

export const PractitionerSchedulesDetails = {
  Form: Form,
  defaults: defaults
}