import * as yup from 'yup'
import moment from "moment-timezone";

export const schema = yup
  .object({
    title: yup.string().required('Required'),
    allDay: yup.boolean().transform(val => !!val),
    start: yup
      .string()
      .required('Required'),
    end: yup
      .string()
      .required('Required')
      .test('end_time_is_greater', 'End time should be greater than start time', function(value) {
        const { start } = this.parent;
        return moment(value).isSameOrAfter(moment(start));
      })
  })
  .required()
