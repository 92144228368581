import { Box } from "@mui/material";
import { Typography } from "saga-library/src";
import React from "react";
import { GhostInspectorNewEvent } from "./GhostInspectorNewEvent";

export const NoScheduleEvent = ({eventData}) =>{
  return <Box
    sx={theme => ({
      borderRadius: 1,
      textAlign: "center",
      backgroundColor: "rgba(196, 25, 35, 0.1)",
      color: theme.palette.greys.dark
    })}
  >
    <Typography>Schedule Closed </Typography>
    <GhostInspectorNewEvent />
  </Box>
}