import React, { useState } from 'react'
import { TenantUserList } from './components/TenantUserList'
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";
import { NewInviteModal } from './components/NewInviteModal'
import SettingsHeader from "./components/SettingsHeader";
import { Permission, PermissionType } from "../../types/settings/Permission";
import PermissionButton from "../../components/PermissionButton";

export const User = () => {
  const [inviteOpen, setInviteOpen] = useState(false)

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={"Users"}
        actions={
          <PermissionButton
            name="new-invite"
            onClick={() => setInviteOpen(true)}
            requiredType={PermissionType.Admin}
            requiredPermission={Permission.READONLY}
            dataTestId={"user-new-invite-button"}
          >
            Invite
          </PermissionButton>
        }
        dataTestId={'user'}
      />
    }>
      <TenantUserList />
      <NewInviteModal open={inviteOpen} setOpen={setInviteOpen} />
    </SettingsSectionColumn>
  )
}
