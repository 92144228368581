import { Autocomplete } from 'saga-library/src'
import React, { useState, useEffect } from 'react'
import UserSelectItem from './UserSelectListItem'
import { useQuery } from '@apollo/client'
import { GET_TENANT_USERS } from "../graphql-definitions";
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { Role, User } from "../types/account";

interface UserSearchProps {
  name: string
  value: User[] | User | null
  onChange: (newValue) => void
  clearOnBlur?: boolean
  inputValue?: string
  onInputChange?: (e, value, reason?) => void
  searchLabel?: string
  hideSelected?: boolean
  multiple?: boolean
  disableChips?: boolean
  disabled?: boolean
  userSelectItem?: React.ComponentType<any>
  getOptionDisabled?: (option) => boolean,
  dataTestId?: string
}

let allUsers: User[] = []

const UserSearch = ({
  name,
  onChange,
  inputValue,
  onInputChange,
  searchLabel = 'Search Users',
  hideSelected = false,
  value,
  multiple = false,
  disableChips = false,
  disabled = false,
  userSelectItem = UserSelectItem,
  getOptionDisabled = () => false,
  dataTestId
}: UserSearchProps) => {
  const { tenant_id } = useParams()
  const [userList, setUserList] = useState<User[]>([])
  const [allRoles, setAllRoles] = useState<Role[]>([])

  const hideSelectedUsers = () => {
    if (hideSelected && value) {
      let newUserList: User[] = allUsers

      if (Array.isArray(value) && value.length > 0) {
        newUserList = newUserList.flatMap((u) => {
          // @ts-ignore
          const exists = value.some((v) => v.id === u.id)
          if (exists) {
            return []
          }
          return [u]
        })
      } else {
        // @ts-ignore
        newUserList.filter((u) => u.id !== value.id)
      }
      setUserList(newUserList)
      return newUserList
    }
    return userList
  }

  useQuery(GET_TENANT_USERS, {
    onCompleted: (data) => {
      const tempAllRoles = _get(data, 'tenant.role.tenantRoles', [])
      setAllRoles(tempAllRoles)
      allUsers = _get(data, 'tenant.user.allTenantUsers', [])
        .map((u: User) => {
          return {
            ...u,
            roles: tempAllRoles.filter((r) => u.roleIds?.includes(r.id)).map((r) => r.name)
          };
        })
      setUserList(allUsers)
    },
    onError: (error) => {
      console.error('Error occurred retrieving users: ' + error)
    },
    variables: { tenantId: tenant_id, onlyActive: true },
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (allUsers.length > 0) {
      let newUserList: User[] = hideSelectedUsers()
      if (value && !Array.isArray(value) && newUserList.length > 0 && !newUserList.some((u) => u.id === value.id)) {
        const missingUser = {...value, roles: allRoles.filter((r) => value.roleIds?.some(id => id === r.id)).map((r) => r.name)}
        if (missingUser) {
          newUserList.push(missingUser)
          setUserList(newUserList)
        }
      }
    }
  }, [value, allUsers])

  const onSelect = (e, option) => {
    onChange(option)
  }

  return (
    <Autocomplete
      name={name}
      label={searchLabel}
      options={userList}
      getOptionLabel={(option) => `${option.lastName}, ${option.firstName}`}
      OptionComponent={userSelectItem}
      onChange={onSelect}
      inputValue={inputValue}
      onInputChange={onInputChange}
      value={value}
      multiple={multiple}
      disableChips={disableChips}
      isOptionEqualToValue={(o, v) => {
        if (o.id === v) {
          onSelect(null, o)
          return true
        }
        return (o.id === v.id)
      }}
      disabled={disabled}
      getOptionDisabled={getOptionDisabled}
      sx={{width: '100%'}}
      dataTestId={`${dataTestId}-userSearch-autocomplete`}
    />
  )
}

export default UserSearch
