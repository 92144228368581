import * as yup from 'yup'
import _get from 'lodash/get'

export const rules = {
  name: { max: 100 },
  title: { max: 100 },
  practitionerId: { max: 100 }
}

const maxLengthMessage = (value) => `Max length of ${value}`

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    name: yup
      .string()
      .max(rules.name.max, maxLengthMessage(rules.name.max))
      .required('Required'),
    title: yup
      .string()
      .max(rules.title.max, maxLengthMessage(rules.title.max))
      .nullable(),
    practitionerId: yup
      .string()
      .max(rules.practitionerId.max, maxLengthMessage(rules.practitionerId.max))
      .nullable(),
    content: yup
      .string()
      .nullable(),
  })
  .required()