import { TaskTemplateInput, TaskTemplateInputData } from '../../../../types/tasks/TaskTemplate'

export const convertTemplateInputToTemplateData = (input: TaskTemplateInput): TaskTemplateInputData => {
  return {
    id: input.id,
    name: input.name,
    description: input.description,
    taskName: input.taskName,
    typeId: input.typeId,
    assignedUserId: input.assignedTo?.type === "user" ? input.assignedTo.value : null,
    assignedRoleId: input.assignedTo?.type === "role" ? input.assignedTo.value : null,
    priority: input.priority,
    practitionerId: input.practitionerId,
    startOffset: input.startOffset,
    duration: input.endOffset - input.startOffset,
    version: input.version
  }
}