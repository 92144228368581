import { gql } from "@apollo/client";


export const APPOINTMENT_FRAGMENT = gql`
  fragment AppointmentFragment on Appointment {
    id
    resource: scheduleId
    notes
    reasonForVisit
    method
    itemDate
    start
    end
    allDay
    length
    type {
      byPhone
      color
      duration
      id
      inPerson
      isDeleted
      name
      videoCall
    }
    state {
      blocksSchedule
      canEnterExamRoom
      color
      id
      isDefault
      isDeleted
      name
    }
    patient {
      id
      firstName
      lastName
      primaryIdentifierLabel
      primaryIdentifier
      dob
    }
    location {
      id
      name
    }
    practitioner {
      id
      title
      firstName
      lastName
    }
    isDeleted
    audit {
      crePit
      luPit
      luUserFirstName
      luUserLastName
    }
    version
  }
`


export const ADD_NEW_APPOINTMENT = gql`
  ${APPOINTMENT_FRAGMENT}
  mutation CreateAppointment($tenantId: ID!, $appointmentInput: AppointmentInput!) {
    tenant(id: $tenantId) {
      schedule {
        appointment {
          create(input: $appointmentInput) {
            ...AppointmentFragment
          }
        }
      }
    }
  }
`

export const UPDATE_APPOINTMENT = gql`
  ${APPOINTMENT_FRAGMENT}
  mutation UpdateAppointment($tenantId: ID!, $appointmentId: ID!, $appointmentInput: AppointmentInput!){
    tenant(id: $tenantId){
      schedule{
        appointment{
          update(id: $appointmentId, input: $appointmentInput){
            ...AppointmentFragment
          }
        }
      }
    }
  }
`

export const DELETE_APPOINTMENT = gql`
  mutation DeleteAppointment($tenantId: ID!, $appointmentId: ID!, $version: String!){
    tenant(id: $tenantId){
      schedule{
        appointment{
          delete(id: $appointmentId, version: $version)
        }
      }
    }
  }
`


export const GET_APPOINTMENT_DATA = gql`
  query GetAppointmentData($tenantId: ID!, $appointmentId: ID!) {
    tenant(id: $tenantId){
      schedule{
        appointment{
          get(id: $appointmentId){
            id
            scheduleId
            start
          }
        }
      }
    }
  }  
`


export const GET_APPOINTMENT_HISTORY = gql`
  query GetAppointmentHistory($tenantId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      schedule {
        appointment {
          appointmentHistory(id: $id) {
            id
            length
            itemDate
            notes
            reasonForVisit
            type {
              name
              byPhone
              videoCall
            }
            state {
              id
              name
              color
            }
            isDeleted
            practitionerFirstName
            practitionerLastName
            location {
              name
            }
            start
            audit {
              luUserFirstName
              luUserLastName
              luPit
              crePit
            }
          }
        }
      }
    }
  }  
`


export const GET_PATIENT_APPOINTMENT_DATES = gql`
  query GetPatientAppointmentDates($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        appointment {
          patientAppointmentDates(patientId: $patientId) {
            item1
            item2
          }
        }
      }
    }
  }
`