import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { schema } from './util/validation'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import omitDeep from 'omit-deep-lodash'
import { merge, omit } from 'lodash'
import _get from 'lodash/get'
import { LoadingSpinner } from "components/LoadingScreen";
import { StaticPage } from 'components/Layouts'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { PractitionerDetails, PractitionerDetailsType } from './components/PractitionerDetails'
import PractitionerForm from "./components/PractitionerForm";
import { PractitionerBillingDetailsList, PractitionerBillingDetailsListType } from "./components/BillingProfiles/PractitionerBillingDetailsList";
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { PractitionerInput } from "../../../types/settings";
import { PractitionerBillingDetailsType } from "./components/BillingProfiles/PractitionerBillingDetails";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { PractitionerSchedulesDetails, PractitionerSchedulesDetailsType } from "./components/Schedules/PractitionerSchedulesDetails";
import PractitionerHeader from "./components/PractitionerHeader";
import {
  GET_FILE_DETAILS,
  GET_PRACTITIONER_PROFILE, GET_TENANT_USER_BY_ID,
  UPDATE_PRACTITIONER_PROFILE
} from "../../../graphql-definitions";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { formatPractitionerId } from "../../../components/PractitionerMaskedTextField";

export type ProfileFormType = PractitionerBillingDetailsListType & PractitionerDetailsType & PractitionerSchedulesDetailsType
const FORM_NAME = 'practitioner_form'

export const PractitionerProfile = () => {
  const { tenant_id, practitioner_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [title, setTitle] = useState('Practitioner')
  const [subTitle, setSubTitle] = useState()
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<ProfileFormType>({
    defaultValues: {
      ...PractitionerDetails.defaults,
      ...PractitionerBillingDetailsList.defaults,
      ...PractitionerSchedulesDetails.defaults
    },
    schema: schema,
  })
  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting, isSubmitSuccessful},
  } = formMethods

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `settings/practitioners/p/${practitioner_id}`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const setValues = async (data) => {
    let cleanedData = omitDeep(
      omit(data, 'id'),
      '__typename',
      'street1'
    )
    const cleanedBillingProfileInputs = cleanedData.billingProfiles.map(
      (billingProfile) => {
        return omitDeep(billingProfile, '__typename')
      }
    )

    cleanedData = omitDeep(cleanedData, 'billingProfiles')
    cleanedData = merge(
      cleanedData,
      { street: data.street1 },
      { billingProfileInputs: cleanedBillingProfileInputs },
    )

    if (cleanedData.userId) {
      const { data: userData } = await verifyUser({
        variables: {
          userId: cleanedData.userId,
          tenantId: tenant_id,
        },
      })
      cleanedData = omitDeep(cleanedData, 'userId')
      cleanedData.user = _get(userData, 'tenant.user.user', null)
    }

    if (cleanedData.signatureFileId) {
      const { data: signatureData } = await getFileDetails({
        variables: {
          fileId: cleanedData.signatureFileId,
          tenantId: tenant_id,
        },
      })
      cleanedData = omitDeep(cleanedData, 'signatureFileId')
      cleanedData.signature = _get(signatureData, 'tenant.file.get', null)
    }

    reset(cleanedData)

    setTitle(practitionerDisplayName(data.firstName, data.lastName))
    setSubTitle(formatPractitionerId(data.practitionerId))
  }

  const { loading: initialLoading, error: initialError } = useQuery(
    GET_PRACTITIONER_PROFILE,
    {
      variables: {
        practitionerId: practitioner_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const practitioner = _get(data, 'tenant.practitioner.get', null)
        if(practitioner.id === practitioner_id) {
          setValues(practitioner)
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Practitioner couldn\'t be retrieved.')
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const [verifyUser] = useLazyQuery(GET_TENANT_USER_BY_ID, {
    onError: (error) => {
      console.error('Error occurred retrieving user: ' + error)
    },
    fetchPolicy: 'cache-first',
  })

  const [getFileDetails] = useLazyQuery(GET_FILE_DETAILS, {
    onError: (error) => {
      console.error('Error occurred retrieving signature file: ' + error)
    },
    fetchPolicy: 'cache-and-network',
  })

  const [ updatePractitioner ] =
    useMutation(UPDATE_PRACTITIONER_PROFILE, {
      onCompleted: (data) => {
        const practitioner = _get(data, 'tenant.practitioner.update', null)
        if(practitioner.id === practitioner_id) {
          setValues(practitioner)
        }
        showSuccessAlert(`Changes have been saved.`)
      },
      onError: (updateError) => {
        console.error(JSON.stringify(updateError, null, 2))
        showErrorAlert("Practitioner couldn't be updated.")
      },
    })

  const updatePractitionerProfile = handleSubmit(async (data) => {

    const {street, billingProfileInputs, schedules, user, signature, ...rest} = data

    const updatedDoc:PractitionerInput = {
      ...rest,
      street1: street
    }
    updatedDoc.userId = user?.id
    updatedDoc.signatureFileId = signature?.id
    updatedDoc.scheduleLocationIds = schedules?.map(s => s.locationId) || []

    if (billingProfileInputs) {
      updatedDoc.billingProfileInputs = billingProfileInputs.map(
        (billingProfile) => {
          const{id, skill, abClaimFacility, abClaimFunctionalCenter, ...rest} = billingProfile
          const updatedBillingProfile: PractitionerBillingDetailsType = {
            ...rest,
            skillId: skill?.id!,
            facilityId: abClaimFacility?.id!,
            functionalCenterId: abClaimFunctionalCenter?.id!
          }
          if(id && !id.includes('new')){
            updatedBillingProfile.id = id
          }
          return updatedBillingProfile
        }
      )
    }

    await updatePractitioner({
      variables: {
        tenantId: tenant_id,
        practitionerId: practitioner_id,
        input: updatedDoc,
      }
    })
  })

  if(initialLoading) {
    return <LoadingSpinner />
  }

  if(initialError) {
    return (
      <StaticPage>
        Sorry, we could not find the requested practitioner.
      </StaticPage>
    )
  }

  return (
    <SettingsSectionColumn header={
      <PractitionerHeader
        title={title}
        subTitle={subTitle}
        loading={isSubmitting}
        formName={FORM_NAME}
        onSubmit={updatePractitionerProfile}
      />
    }>
      <FormProvider {...formMethods}>
        <PractitionerForm
          formName={FORM_NAME}
          onSubmit={updatePractitionerProfile}
        />
      </FormProvider>
    </SettingsSectionColumn>
  )
}

export default PractitionerProfile
