import React, { useEffect, useState } from 'react'
import { AdministrationDetails, AdministrationDetailsType } from "./AdministrationDetails";
import { AhcipBillingDetails, AhcipBillingDetailsType } from "./AhcipBillingDetails";
import { ExternalIdentifiersDetails, ExternalIdentifiersDetailsType } from "./ExternalIdentifiersDetails";
import { useParams } from 'react-router-dom'
import { LoadingOverlay } from "../../../../../components/LoadingScreen";
import { useFormContext } from "saga-library/src/components/Form";
import { PermissionType } from "../../../../../types/settings/Permission";
import { Section } from "saga-library/src"
import { PractitionerSubSection } from "../PractitionerDetails";

const defaults = {
  ...AdministrationDetails.defaults,
  ...AhcipBillingDetails.defaults,
  ...ExternalIdentifiersDetails.defaults,
}

const Form = () => {
  const { billing_id } = useParams()
  const [profileIndex, setProfileIndex] = useState()

  const { getValues } = useFormContext()

  useEffect(() => {
    if (billing_id) {
      const updatedBillingProfiles = getValues(`billingProfileInputs`) || []
      const index = updatedBillingProfiles.findIndex(
        (bp) => bp?.id?.toString() === billing_id
      )
      if (index >= 0) {
        setProfileIndex(index)
      }
    }
  }, [billing_id])

  if(profileIndex || profileIndex === 0) {
    return <BillingProfileForm profileIndex={profileIndex} key={profileIndex} />
  }

  return <LoadingOverlay
    loading={true}
    message={'Loading billing profile'}
  />

}

const BillingProfileForm = ({profileIndex}) => {
  const PERMISSION_REQUIRED = PermissionType.Practitioner

  return (
    <Section.Container>
      <PractitionerSubSection>
        <AdministrationDetails.Form profileIndex={profileIndex} requiredPermissionType={PERMISSION_REQUIRED}/>
        <AhcipBillingDetails.Form profileIndex={profileIndex} requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>

      <PractitionerSubSection>
        <ExternalIdentifiersDetails.Form profileIndex={profileIndex} requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>
    </Section.Container>
  )
}

export type PractitionerBillingDetailsType = AdministrationDetailsType &
  AhcipBillingDetailsType &
  ExternalIdentifiersDetailsType

export const PractitionerBillingDetails = {
  Form: Form,
  defaults: defaults,
}
