import { PhoneEnumType } from "./PhoneType";
import { Box, SxProps, Theme } from "@mui/material";
import { Typography } from '../Typography'
import { Checkbox } from '../Checkbox'
import React, { useEffect } from 'react'
import { useFormContext } from "saga-library/src/components/Form";

export interface PhoneTypeTextProps {
  name: string
  type: string
  index: number
  isPrimary?: boolean
  setPrimary?: (number) => void
  setValue?: (string, boolean) => void
  dataTestId?: string
}

export const PhoneTypeText = ({
  type,
  index,
  isPrimary,
  setPrimary = () => {},
  name,
  dataTestId,
}: PhoneTypeTextProps) => {

  const { setValue } = useFormContext();

  let phoneTypeText
  switch (type) {
    case PhoneEnumType.HOME:
      phoneTypeText = 'Home Phone'
      break
    case PhoneEnumType.MOBILE:
      phoneTypeText = 'Mobile Phone'
      break
    case PhoneEnumType.BUSINESS:
      phoneTypeText = 'Business Phone'
      break
    case PhoneEnumType.OTHER:
      phoneTypeText = 'Other Phone'
      break
    default:
      phoneTypeText = `New Phone`
      break
  }

  useEffect(() => {
    isPrimary
      ? setValue(`${name}.isPrimary`, true)
      : setValue(`${name}.isPrimary`, false)
  }, [isPrimary, setValue, name])

  if (isPrimary) {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography data-testid={dataTestId} variant={'h3'} sx={{fontSize:"14px"}}>{phoneTypeText}</Typography>
        <PrimaryPhoneText />
      </Box>
    )
  } else {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          alignItems: 'center',
        }}
      >
        <Typography data-testid={dataTestId} variant={'h3'} sx={{fontSize:"14px"}}>{phoneTypeText}</Typography>
        <Box pl={2}>
          <Checkbox
            dataTestId={`${dataTestId}-primary-checkbox`}
            name={`${name}.isPrimary`}
            label={'Set as primary'}
            onSelect={() => setPrimary(index)}
            sx={{fontSize:"14px"}}
          />
        </Box>
      </Box>
    )
  }
}

export interface PhoneChipProps {
  text: string
  sx: SxProps<Theme>
  size?: 'medium' | 'small'
  dataTestId?: string
}

const PhoneChip = ({text, sx, size='medium', dataTestId}:PhoneChipProps) => {
  return (
    <Box
      sx={{
        height: 20,
        m: 1,
        p: theme => theme.spacing(0, 1, 0, 1),
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        ...sx
      }}
    >
      <div data-testid={dataTestId} style={{ fontSize: size === 'medium'? '14px' : '12px' }}>{text}</div>
    </Box>
  )
}

export interface PhoneTypeChipProps {
  type: string
  dataTestId?: string
}

export const PhoneTypeChip = ({type, dataTestId}: PhoneTypeChipProps) => {
  return (
    <PhoneChip
      dataTestId={`${dataTestId}-phoneTypeChip`}
      text={type}
      sx={{
        backgroundColor: 'tertiary.main'
      }}
      size={'small'}
    />
  )
}

export interface PrimaryPhoneTextProps {
  bold?: boolean,
  size?: 'medium' | 'small'
  dataTestId?: string
}
export const PrimaryPhoneText = ({bold=true, size='medium', dataTestId}:PrimaryPhoneTextProps) => {
  return (
    <PhoneChip
      dataTestId={`${dataTestId}-primaryPhoneText`}
      text={'PRIMARY'}
      sx={{
        color: 'primary.main',
        backgroundColor: 'backgrounds.selected',
        fontWeight: bold? 'bold' : 'normal'
      }}
      size={size}
    />
  )
}
