import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { ConfirmationDialog, DialogV2, LoadingButton, RemoveButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AllergyType } from "../../../../types/Allergy";
import { allergyDefaults } from "./components/AllergyFormDefaultValues";
import { schema } from "./components/AllergyFormValidationsSchema";
import { AllergyForm } from "./components/AllergyForm";
import { useAllergyContext } from "../../providers/AllergyProvider";

const FORM_NAME = "edit_allergy_form"

const EditAllergyDialog = ({ allergy, setAllergy }) => {
  const { updateAllergy, deleteAllergy } = useAllergyContext()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const formMethods = useForm<AllergyType>({
    defaultValues: allergyDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if(allergy){
      reset(allergy)
    }
  }, [allergy]);

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setAllergy(null)
      }
    }
  }

  const onSubmit = () => {
    setAllergy(null)
  }

  useEffect(() => {
    if (allergy) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [allergy, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = () => {
    handleSubmit(async(data) => {
      await updateAllergy(
        data,
        () => {
          setAllergy(null)
          enableNavigationPrompt(false, FORM_NAME)
        }
      )
    })()
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setAllergy(null)
    }
  }

  const onDelete = (allergy) => {
    deleteAllergy(allergy, ()=>null)
    setAllergy(null)
  }

  if(!allergy) return null

  return <DialogV2
    dataTestId={'edit-allergy-dialog'}
    size={'sm'}
    title={allergy.allergen}
    open={!!allergy}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveAllergy'}
        form={FORM_NAME}
        dataTestId={"edit-allergy-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
    headerActions={[
      <DeleteAllergyButton key={"delete-allergy-button"} onDelete={()=>onDelete(allergy)}/>
    ]}
  >
    <FormProvider {...formMethods}>
      <AllergyForm
        onSubmit={onSubmit}
        autoFocus={false}
      />
    </FormProvider>
  </DialogV2>
}

const DeleteAllergyButton = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return <>
    <RemoveButton
      dataTestId={'allergy'}
      onClick={() => setOpenDeleteConfirmation(true)}
    />
    <ConfirmationDialog
      dataTestId={'delete-allergy-confirmation'}
      open={openDeleteConfirmation}
      title={'Delete allergy?'}
      message={"This action cannot be undone."}
      primaryAction={() => {
        setOpenDeleteConfirmation(false)
        onDelete()
      }}
      primaryLabel={'delete'}
      onClose={() => setOpenDeleteConfirmation(false)}
    />
  </>
}

export default EditAllergyDialog