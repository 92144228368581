import { Controller, useFormContext } from "saga-library/src/components/Form";
import React, { useEffect, useState } from 'react'
import { Autocomplete } from "saga-library/src";
import { SxProps, Theme } from "@mui/material";
import { useEncounterNoteTemplateContext } from "../providers/EncounterNoteTemplateProvider";

interface EncounterNoteTemplateSelectProps {
  label: string,
  name: string,
  disabled?: boolean,
  dataTestId: string,
  sx?: SxProps<Theme>
}

export const EncounterNoteTemplateSelect = ({
  label='Template',
  name,
  disabled=false,
  dataTestId,
  sx
}: EncounterNoteTemplateSelectProps) => {
  const { control } = useFormContext()
  const { encounterNoteTemplates: templates } = useEncounterNoteTemplateContext()
  const [templateOptions, setTemplateOptions] = useState<{value: string, label: string, key: string}[]>([])

  useEffect(()=>{
    const template = templates.map(temp => {
      return {
        key: temp.id,
        value: temp.id,
        label: temp.name
      }
    })

    setTemplateOptions(template)
  }, [templates])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = null }, fieldState: { error } }) => {
        if (value === '') {
          value = null
        }
        return (
          <Autocomplete
            key={name}
            name={name}
            label={label}
            options={templateOptions}
            onChange={(e, newValue) => {
              onChange(newValue?.value)
            }}
            getOptionLabel={(option) => {
              if(typeof option === 'string') {
                return templateOptions.find(o => o.value === option)?.label || ""
              }
              return option?.label || ""
            }}
            isOptionEqualToValue={(option, value) => option?.value === value}
            value={value}
            sx={{flex: '1 1 100%', ...sx}}
            disabled={disabled}
            onInputChange={(e, value, reason) => {
              if(reason === 'clear'){
                onChange(undefined)
              }
            }}
            error={error}
            helperText={error?.message}
            dataTestId={`encounterNoteSelect-${dataTestId}`}
          />
        )
      }}
    />
  )
}
