import { range } from "lodash";

export const processPageRangesForExtractPages = (pageRanges: number[][]) : number[][] => {
  return sortAndRemoveDuplicates(flattenPageRanges(pageRanges)).map((page) => [page - 1])
}

export const processPageRangesForRemovePages = (pageRanges: number[][]) : string[][] => {
  return sortAndRemoveDuplicates(flattenPageRanges(pageRanges)).map((page) => [(page - 1).toString()])
}

export const getPageCount = (pageRanges: number[][]) : number => {
  return sortAndRemoveDuplicates(flattenPageRanges(pageRanges)).length
}

export const sortAndRemoveDuplicates = (pageRange: number[]) : number[] => {
  return Array.from(new Set(pageRange)).sort((a,b) => a - b)
}

export const flattenPageRanges = (pageRange: number[][]) : number[] => {
  return pageRange.flatMap((pageRange) =>
    pageRange.length === 2 ? range(pageRange[0], pageRange[1] + 1, 1) : pageRange
  )
}