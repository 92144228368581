export enum Permission {
  NONE = 0,
  READONLY = 1,
  READWRITE = 2,
}

export const PermissionMap: Map<Permission, string> = new Map ([
  [Permission.NONE, "NONE"],
  [Permission.READONLY, "READONLY",],
  [Permission.READWRITE, "READWRITE"],
])

export enum PermissionType {
  Admin = 'admin',
  Patient = 'patient',
  Chart = 'chart',
  Billing = 'billing',
  Schedule = 'schedule',
  Practitioner = 'practitioner',
  ReportingBilling = 'reportingBilling',
  ReportingPatientAndAppointment = 'reportingPatientAndAppointment',
  LabsReview = 'labsReview'
}

export type UserPermissions = {
  admin: string,
  patient: string,
  chart: string,
  billing: string,
  schedule: string,
  practitioner: string,
  reportingBilling: string,
  reportingPatientAndAppointment: string,
  labsReview: string,
}
