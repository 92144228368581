
export interface IdentifiedObject {
  id: string
}

export const transform = {
  input: (obj: IdentifiedObject) => {
    return obj ? obj?.id : null
  },
  output: (id: string | undefined | null, options: IdentifiedObject[]): any => {
    const option = options.find(o => o.id === id)
    return option || null
  }
}