import { Skill } from "./Skill";
import { Phones } from "saga-library/src/components/Phone/PhoneType";

export type ReferralPractitioner = {
  id: string
  practitionerId: string
  firstName: string
  lastName: string
  street1: string
  street2: string
  street3: string
  city: string
  province: string
  email: string
  locationId: string
  active: boolean
  disciplineDescription: string
  label: string
  skills: Skill[]
} & Phones

export enum PractitionerCommunicationMethod {
  FAX = 'FAX',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  NONE = '',
}
