import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import { ReferralPractitioner } from "../../../../../types/settings";
import {
  ReferralPractitionerSearch
} from "../../../../../components/SearchControls/Practitioner/ReferralPractitionerSearch";
import { GET_REFERRAL_PRACTITIONER_PROFILE } from "../../../../../graphql-definitions";
import { LoadingSpinner } from "../../../../../components/LoadingScreen";
import { useFormContext } from "react-hook-form";

type LetterFormReferralPractitionerSelectionProps = {
  name: string
  referralPractitioners: ReferralPractitioner[]
  onSelect: (referralPractitioner: ReferralPractitioner) => void
  dataTestId: string
}

export const LetterFormReferralPractitionerSelection = ({
  name,
  referralPractitioners,
  onSelect,
  dataTestId
}: LetterFormReferralPractitionerSelectionProps) => {
  const { tenant_id } = useParams()
  const client = useApolloClient()
  const { watch } = useFormContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [initial, setInitial] = useState<ReferralPractitioner[]>()

  const selectedReferralPractitioner = watch(name)

  const [getReferralPractitioner, { loading: selectLoading }] = useLazyQuery(GET_REFERRAL_PRACTITIONER_PROFILE, {
    onCompleted: (data) => {
      const referralPractitioner: ReferralPractitioner = data.tenant.referralPractitioner.get
      onSelect(referralPractitioner)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    const profiles = referralPractitioners.map((referralPractitioner) => {
      return client.query({
        query: GET_REFERRAL_PRACTITIONER_PROFILE,
        variables: {
          referralPracId: referralPractitioner.id,
          tenantId: tenant_id,
        }
      })
    })
    Promise.all(profiles).then((profile) => {
      const data: ReferralPractitioner[] = profile.map(p => p.data.tenant.referralPractitioner.get)
      setInitial(data)
      setLoading(false)
    })
  }, [client, tenant_id, referralPractitioners])

  useEffect(() => {
    if (selectedReferralPractitioner) {
      if (!!initial?.find((i) => i.id === selectedReferralPractitioner.id)) {
        onSelect(selectedReferralPractitioner)
      } else {
        getReferralPractitioner({
          variables: {
            referralPracId: selectedReferralPractitioner.id,
            tenantId: tenant_id
          }
        })
      }
    }
  }, [initial, selectedReferralPractitioner])

  if (loading || selectLoading) {
    return <LoadingSpinner size={"md"} label={`Loading referral practitioner${loading ? "s" : ""}...`} />
  }

  return (
    <ReferralPractitionerSearch
      dataTestId={dataTestId}
      label={"Find a referring practitioner"}
      name={name}
      autoFocusOnOpen
      openOnFocus
      initialOptionsArray={initial}
    />
  )
}