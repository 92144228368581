import React from "react";
import Box from "@mui/material/Box";
import { TextField, Section, Switch, Form } from "saga-library/src";
import { AppointmentDatePicker } from "../../../../components/AppointmentDatePicker";

interface EventFormProps {
  onSubmit: () => void
  dataTestId?: string
  formName: string
}

export const EventForm = ({
  onSubmit,
  dataTestId,
  formName
}: EventFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      name={formName}
      id={formName}
      autoComplete={'false'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
        <Section.FormSection sx={{ maxWidth: '50%', mt: 2, mb: 0 }}>
          <TextField
            dataTestId={`${dataTestId}-title`}
            name={'title'}
            label={'Title'}
          />

          <Box mt={2} mb={2}>
            <Switch dataTestId={`${dataTestId}-allDay`} name={'allDay'} label={'All-day'}/>
          </Box>

          <AppointmentDatePicker dataTestId={dataTestId} />
        </Section.FormSection>
      </Box>
    </Form>
  )

}