import { ClaimInputType } from "../../../types/billing/AbClaim/AbClaim";
import { pick } from "lodash";


export const createClaimInputFromFormData = (data) => {
  let claimInput : ClaimInputType = {
    ...data,
    billingProfileId: data.billingProfile?.id,
    facilityId: data.facility?.id,
    functionalCenterId: data.functionalCenter?.id,
    referralPractitionerId: data.referralPractitioner?.id,
    serviceCodeId: data.serviceCode?.id,
    diagnosticCodeIds: data.diagnosticCodes?.filter(dc => !!dc).map(dc => dc.id),
    serviceCodeFeeModifierIds: data.serviceCodeFeeModifiers?.filter(fm => !!fm).map(fm => fm.id),
    patientId: data.patient?.id,
    originalFacilityId: data.originalFacility?.id,
    skillId: data.skillId?.value,
    newBornCode: data.newBornCode || null
  }
  return pick(claimInput, [
    'version',
    'serviceDate',
    'calls',
    'encounter',
    'locationCode',
    'text',
    'paperDocumentation',
    'confidential',
    'payToUli',
    'originalEncounterDate',
    'originalLocationCode',
    'newBornCode',
    'guardianUli',
    'guardianRegNumber',
    'skillId',
    'payTo',
    'payToCode',
    'interceptReason',
    'billingProfileId',
    'facilityId',
    'functionalCenterId',
    'referralPractitionerId',
    'serviceCodeId',
    'diagnosticCodeIds',
    'serviceCodeFeeModifierIds',
    'patientId',
    'originalFacilityId',
    'patientPHN',
    'patientFirstName',
    'patientLastName',
    'patientDOB',
    'patientGenderId',
    'customClaimAmount',
    'feeMod1Units',
    'feeMod2Units',
    'feeMod3Units',
    'hospitalAdmissionDate',
    'problems',
  ])
}