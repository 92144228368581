import React, { useRef, useState } from "react";
import { Box } from '@mui/material'
import { TextField, Section } from 'saga-library/src'
import * as moment from 'moment'
import GenderSelect from 'components/GenderSelect/GenderSelect'
import { useAccountContext } from 'providers/AccountContext'
import { useParams } from 'react-router-dom'
import { rules } from '../../../util/validation'
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { Province } from 'saga-library/src'
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";

export type PersonalDetailsType = {
  lastName: string
  firstName: string
  middleName: string
  genderId: string
  dob: moment.Moment | null
  id?: string | null
}

const defaults = {
  lastName: '',
  firstName: '',
  middleName: '',
  genderId: '',
  dob: null,
}

const Form = (props) => {
  const { getTenantProvince } = useAccountContext()
  const { tenant_id } = useParams()
  const province: Province = getTenantProvince(tenant_id)

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(props.autoFocus);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  const dataTestId = props?.dataTestId

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
      <Section.SubHeader>Personal</Section.SubHeader>
      <TextField
        autoFocus={props.autoFocus}
        label={'Last name'}
        name={'lastName'}
        inputProps={{ maxLength: rules.lastName.max }}
        dataTestId={`${dataTestId}-lastName`}
        inputRef={firstFieldRef}
      />
      <TextField
        label={'First name'}
        name={'firstName'}
        inputProps={{ maxLength: rules.firstName.max }}
        dataTestId={`${dataTestId}-firstName`}
      />
      { !props?.minimal &&
        <>
          <TextField
            label={'Middle name'}
            name={'middleName'}
            inputProps={{ maxLength: rules.middleName.max }}
            dataTestId={`${dataTestId}-middleName`}
          />
          <Box display={'flex'} flexDirection={'row'} gap={2}>
            <GenderSelect
              dataTestId={`${dataTestId}-gender`}
              provinceCode={province?.code}
            />
            <FormattedDatePicker
              dataTestId={`${dataTestId}-dateOfBirth`}
              label={'Date of birth'}
              name={'dob'}
              sx={{ width: '100%' }}
            />
          </Box>
        </>
      }
    </Box>
  )
}

export const PersonalDetails = {
  Form: Form,
  defaults: defaults,
}
