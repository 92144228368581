import React from "react";
import TableContainer from "@mui/material/TableContainer";

export const StyledTableContainer = ({children, style}) => {
  return (
    <TableContainer sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflowY: 'auto',
      ...style,
    }}
    >
      {children}
    </TableContainer>
  )
}