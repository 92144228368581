import * as yup from 'yup'

export const schema = yup
  .object({
    name: yup.string().required('Required'),
    duration: yup.number().integer().min(1).max(999).required('Required').typeError('A number is required'),
    inPerson: yup.boolean(),
    byPhone: yup.boolean(),
    videoCall: yup.boolean(),
  })
  .test(
    'appointmentMethodTest',
    '',
    (obj) => {
      if (obj.inPerson || obj.byPhone || obj.videoCall) {
        return true
      }
      return new yup.ValidationError(
        'At least one appointment method is required',
        null,
        'appointmentMethod'
      )
    }
  )

