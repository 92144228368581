import React, { useMemo } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { PatientSectionHeader } from "../PatientSectionHeader";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { Section, TableList, TableListHeaderConfig } from "saga-library/src";
import { PatientLetterRow } from "./PatientLetterRow";
import { useLetterContext } from "../../providers/LetterProvider";
import { useReadQuery } from "@apollo/client";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { LETTERS_SCROLL_KEY } from '../../../../providers/UserInteractionContext'


const columnNames: TableListHeaderConfig[] = [
  { name: 'Date'},
  { name: 'Title'},
  { name: 'Practitioner'},
  { name: 'Notes'},
  { name: 'Letter recipient(s)'},
]


const sectionTitle = "Letters"


export const PatientLetters = ChartPanelHOC(sectionTitle, () => {
  const { letterQueryRef } = useLetterContext()
  return letterQueryRef && <PatientLettersPanel/>
})


export const PatientLettersPanel = () => {
  const { tenant_id, patient_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { letterQueryRef, parseLetterQueryResults } = useLetterContext()
  const { data } = useReadQuery(letterQueryRef!)
  const patientLetters = useMemo(()=> parseLetterQueryResults(data), [data, parseLetterQueryResults])


  const onNew =() => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/letters/new`, tenant_id))
  }

  const onSelect = (letter) => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/letters/${letter.isLinkedDocument?'d':'l'}/${letter.id}`, tenant_id))
  }

  return (
    <Section.Column
      header={
        <PatientSectionHeader
          dataTestId={'letter-list'}
          sectionLabel={'Letters'}
          showSave={false}
          actions={
            <PermissionButton
              name={"new"}
              requiredType={PermissionType.Chart}
              requiredPermission={Permission.READWRITE}
              onClick={onNew}
              dataTestId={"letterList-new-button"}
            >
              New
            </PermissionButton>
          }
        />
      } >
      <TableList
        scrollKey={LETTERS_SCROLL_KEY}
        columns={columnNames}
        rows={patientLetters.map((letter, index) => PatientLetterRow(letter, onSelect, index))}
        emptyListComponent={ { message: "Letters appear here once added." } }
        dataTestId={"letterList"}
      />
    </Section.Column>
  )
}