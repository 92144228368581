import Box from '@mui/material/Box'
import { SimpleTooltip, Switch, Typography } from 'saga-library/src'
import React from "react";

const PermissionSwitch = ({ name, label, description = "", disabled = false, disabledHelper = '', dataTestId }) => {
  let toggle = <Switch dataTestId={dataTestId} name={name} disabled={disabled} />
  if (disabled && disabledHelper) {
    toggle = (
      <SimpleTooltip title={disabledHelper} >
        <Box>
          {toggle}
        </Box>
      </SimpleTooltip>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        mb: 2
      }}
    >
      <Box
        sx={{
          flex: '1 1 auto',
          flexDirection: 'column'
        }}
      >
        <Typography fontWeight={"bold"}>{label}</Typography>
        <Typography variant={"body2"}>{description}</Typography>
      </Box>
      {toggle}
    </Box>
  )
}

export default PermissionSwitch
