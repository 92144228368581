import React from 'react'
import { DialogV2, Typography } from 'saga-library/src'
import { PatientAppointment } from '../../../../types/patients'
import { Box, SxProps, Theme} from '@mui/material'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import moment from 'moment-timezone'

interface PatientAppointmentDialogProps {
  openDialog: boolean,
  patientAppointments?: PatientAppointment[],
  onSelected: (appointment) => void,
  onModalClose: () => void
}

interface PatientAppointmentRowProps {
  id: string,
  appointment: PatientAppointment | null,
  children: React.ReactNode,
  sx?: SxProps<Theme>
}

export const PatientAppointmentDialog = ({
  openDialog,
  patientAppointments,
  onSelected,
  onModalClose
}: PatientAppointmentDialogProps) => {
  const PatientAppointmentRow = ({
    id,
    appointment,
    children,
    sx
  }: PatientAppointmentRowProps) => {
    return (
      <Box
        key={id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '58px',
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
          padding: '8px',
          marginBottom: '4px',
          ':hover': {
            backgroundColor: '#E0E0E0',
            cursor: 'pointer'
          },
          ...sx
        }}
        onClick={() => {
          onSelected(appointment)
        }}
      >
        {children}
      </Box>
    )
  }

  const NoAppointmentRow = () => {
    return (
      <PatientAppointmentRow id={'no-appointment-row'} appointment={null} sx={{ justifyContent: 'center' }}>
        <Typography dataTestId={'encounter-note-appointment-none'} variant={'body1'}>Continue without linking an appointment</Typography>
      </PatientAppointmentRow>
    )
  }

  const AppointmentRow = (appointment: PatientAppointment, index: number) => {
    let appointmentTimeDisplay = `${useFormattedDate(appointment.start, true)} - ${moment(appointment.end).format('h:mm A')}`

    let practitionerDisplay = ''
    if (appointment.practitioner !== undefined) {
      practitionerDisplay = practitionerDisplayName(appointment.practitioner.firstName, appointment.practitioner.lastName)
    }

    return (
      <PatientAppointmentRow key={appointment.id} id={appointment.id} appointment={appointment}>
        <Typography dataTestId={`encounter-note-appointment-time-${index}`} variant={'h5'}>{appointmentTimeDisplay}</Typography>
        <Typography dataTestId={`encounter-note-appointment-practitioner-${index}`} variant={'body1'}>{practitionerDisplay}</Typography>
        <Typography dataTestId={`encounter-note-appointment-type-${index}`} variant={'p3'}>{appointment?.type?.name}</Typography>
      </PatientAppointmentRow>
    )
  }

  const appointmentSelectionBox = ({children}) => {
    return (
      <Box key={'appointment-selection-box'}>
        <Typography
          variant={'body1'}
          sx={{ paddingBottom: '16px' }}
        >
          Select an appointment to link to this encounter note:
        </Typography>
        {children}
        <NoAppointmentRow />
      </Box>
    )
  }

  const FilteredAppointments = () => {
    const dateNow = moment()

    if(!patientAppointments || patientAppointments.length === 0) return appointmentSelectionBox({children: null})
    const pastAndTodayAppointments = patientAppointments.filter(appointment => dateNow.isSameOrAfter(appointment.start, 'day'))
    let filtered = [...pastAndTodayAppointments]

    const futureAppointments = patientAppointments.filter(appointment => dateNow.isBefore(appointment.start, 'day'))
    if(futureAppointments.length > 0) {
      filtered = [futureAppointments[0], ...filtered]
    }

    return (
      appointmentSelectionBox({children:
          filtered.slice(0, 5).map((appointment, index) => (
            AppointmentRow(appointment, index)
          ))
      })
    )
  }

  return (
    <DialogV2
      onClose={() => {
        onModalClose()
      }}
      maxWidth={'sm'}
      open={openDialog}
      title={'Link an appointment'}
      scroll={'paper'}
      dataTestId={'encounter-note-appointment-dialog'}
      showActions={false}
    >
      <FilteredAppointments />
    </DialogV2>
  )
}