import { Controller, useFormContext, useWatch } from "saga-library/src/components/Form"
import { SearchControlProps } from "./SearchControl"
import { SearchControl } from "./index"
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { ValueTransform, InputTransform } from "./types"
import { AutocompleteValue } from "@mui/material";

export default function ControlledSearchControl <
  T = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  name,
  transform,
  inputRef,
  disabled = false,
  initialOptionsArray=[],
  getOptionDisabled,
  autoFocus,
  inputTransform,
  onChange,
  dataTestId,
  defaultTValue,
  ...props
} : ControlledSearchControlProps<T, Multiple, DisableClearable, FreeSolo>) {

  //Must be used within a react-hook-form provider context
  const { control } = useFormContext()

  //Currently required for ID value transformation, not ideal
  const [TValue, setTValue] = useState<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>()

  useEffect(() => {
    if(defaultTValue){
      setTValue(defaultTValue);
    }
  }, [defaultTValue]);

  const autoC = useRef<HTMLInputElement|null>(null)

  const test = useWatch({
    control,
    name: name,
  })

  useEffect(() => {
    if (!test){
      // Commonly accepted workaround for a known issue that MUI has not addressed.
      // https://github.com/mui/material-ui/issues/4736
      const ele = autoC?.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLInputElement
      if(ele){
        ele.click()
      }
    }
  }, [test])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange: controlledOnChange, ref}, fieldState: {error} }) => {
        return (
          <SearchControl<T, Multiple, DisableClearable, FreeSolo>
            {...props}
            dataTestId={dataTestId}
            name={name}
            autocompleteRef={autoC}
            autoFocus={autoFocus}
            value={TValue || value || null}
            onChange={(event, newValue) => {
              setTValue(newValue)
              controlledOnChange(transform ? transform(newValue) : newValue)
              if (onChange) {
                onChange(transform ? transform(newValue) : newValue)
              }
            }}
            onInputChange={(event, newValue, reason) => {
              if (inputTransform && reason === "input") {
                const transformed_input = inputTransform(newValue)
                setTValue(transformed_input)
                controlledOnChange(transform ? transform(transformed_input) : transformed_input)
                if (onChange) {
                  onChange(transform ? transform(transformed_input) : transformed_input)
                }
              }
            }}
            inputRef={(e) => {
              ref(e)
              if(inputRef) {
                inputRef.current = e
              }
            }}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            initialOptionsArray={initialOptionsArray}
            getOptionDisabled={getOptionDisabled}
          />
        )
      }}
    />
  )
}

export interface ControlledSearchControlProps<
  T = any,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<SearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>, 'error'|'onChange'|'value'|'inputRef'> {
  name: string
  transform?: ValueTransform<T, Multiple, DisableClearable, FreeSolo>
  inputRef?: MutableRefObject<HTMLInputElement | null>
  disabled?: boolean
  initialOptionsArray?: T[]
  getOptionDisabled?: (option) => boolean
  autoFocus?: boolean
  inputTransform?: InputTransform<T, Multiple, DisableClearable, FreeSolo>
  dataTestId?: string,
  defaultTValue?:  AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  onChange?: (value) => void
}

export interface IdEntity {
  id: string
}

export const IdTransform : ValueTransform<IdEntity, false, false, false> = (value) => {
  return value?.id || null
}

export const IdArrayTransform : ValueTransform<IdEntity, true, false, false> = (value) => {
  return value?.map<string>(v => v.id) || []
}

export function IdEqual<T extends IdEntity>(a: T, b: T): boolean {
  return a.id === b.id
}