import React from 'react'
import SettingsHeader from "./components/SettingsHeader";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";

export const Reports = () => {
  return (
    <SettingsSectionColumn header={
      <SettingsHeader title={'Reports'} dataTestId={'reports'} />
    }>
      Reports Settings
    </SettingsSectionColumn>
  )
}
