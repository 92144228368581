import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { TaskForm } from './TaskForm'
import { taskDefaults } from './TaskDefaultValues'
import { schema } from './TaskValidationsSchema'
import { ConfirmationDialog, LoadingButton, RemoveButton, Section } from 'saga-library/src'
import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'
import { useNavigate, useParams } from 'react-router-dom'
import { useReadQuery } from '@apollo/client'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { useReviewContext } from '../../providers/ReviewProvider'
import { AssignedToOptionType } from '../../../../components/AssignedTo/AssignedTo'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { useAccountContext } from '../../../../providers/AccountContext'

export const FORM_NAME = "update_task_form"

const UpdateTaskForm = () => {
  const { enableNavigationPrompt } = usePrompt()
  const { task_id, user_id, role_id, tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { updateTask, deleteTask } = useTaskContext()
  const { pendingTaskFilters, handlePendingTaskFilters, parseTaskQueryResults, tasksRef } = useReviewContext()
  const { data } = useReadQuery(tasksRef)
  const tasks = useMemo(() => parseTaskQueryResults(data), [data, parseTaskQueryResults])
  const task = tasks.find((t) => t.id === task_id)
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)
  const { updateTaskCount } = useUpdateCount()
  const navigate = useNavigate()

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    if (!task) {
      return
    }

    let assignedTo: AssignedToOptionType | undefined = undefined
    if (task.assignedRoleId) {
      assignedTo = { type: 'role', value: task.assignedRoleId }
    } else if (task.assignedUserId) {
      assignedTo = { type: 'user', value: task.assignedUserId }
    }

    reset({
      id: task.id,
      name: task.name,
      typeId: task.type.id,
      assignedTo: assignedTo,
      stateId: task.state.id,
      priority: task.priority,
      startDate: task.startDate,
      dueDate: task.dueDate,
      practitionerId: task.practitionerId,
      patientId: task.patient?.id,
      patient: task.patient,
      description: task.description,
      version: task.version,
      comments: undefined,
      linkedItems: undefined,
    })
  }, [task, reset])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    handlePendingTaskFilters(discard)
  }

  useEffect(() => {
    if (pendingTaskFilters) {
      if (!!Object.keys(dirtyFields).length) {
        enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, true, handleNavigationPromptDiscard)
      } else {
        handlePendingTaskFilters(true)
      }
    } else {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `tasks/t/${task_id}`)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, pendingTaskFilters])

  const onUpdateComplete = (data) => {
    reset({ patientId: data.patient?.id }, { keepValues: true })
  }

  const onSave = handleSubmit(async (data) => {
    let inputData: TaskAssignmentInput = {...data}
    delete inputData.assignedTo
    delete inputData.patient
    delete inputData.state
    delete inputData.type
    inputData.assignedRoleId = data.assignedTo?.type === 'role'? data.assignedTo?.value : null
    inputData.assignedUserId = data.assignedTo?.type === 'user'? data.assignedTo?.value : null

    await updateTask(inputData, onUpdateComplete)
  })

  const onDelete = async() => {
    await deleteTask(
      task?.id!,
      task?.version!,
      ()=>{
        updateTaskCount(-1)
        navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/`, tenant_id), {replace:true})
      }
    )
  }

  return (
    <Section.Column
      rightPadding={1}
      sx={{ flex: "1 1 100%" }}
      headerLabel={task?.name || task?.type.type}
      headerProps={{
        primaryAction: (
          <LoadingButton
            name={'task-update-save'}
            dataTestId={'task-update-save'}
            variant={'contained'}
            type={"submit"}
            form={FORM_NAME}
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        ),
        iconButton2: <RemoveButton
          dataTestId={'deleteTask'}
          onClick={() =>setOpenConfirmation(true)} />
      }}
    >
      <FormProvider {...formMethods}>
        <TaskForm
          formName={FORM_NAME}
          showTemplateSelect={true}
          onSubmit={onSave}
          dataTestId={"task-editor-form"}
        />
      </FormProvider>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={()=>setOpenConfirmation(false)}
        title={"Delete task?"}
        message={"This action can't be undone."}
        primaryAction={onDelete}
        primaryLabel={"Delete"}
        dataTestId={'updateTask-dialog-delete'}
      />
    </Section.Column>
  )
}


export const UpdateTask = () => {
  const { tasksRef } = useReviewContext()

  if (!tasksRef) {
    return (
      <Section.Column
        rightPadding={1}
        sx={{ flex: "1 1 100%" }}
      >
        <LoadingSpinner />
      </Section.Column>
    )
  }

  return <UpdateTaskForm />
}