import React from 'react'

export const batchUpdateStyleSearch = (updatedFields) => {
  return (
    {
      '& .MuiInputLabel-root': {
        color: (theme) => updatedFields ? theme.palette.secondary.main : '',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: (theme) => updatedFields ? theme.palette.secondary.main : '',
        }
      },
    }
  )
}

export const batchUpdateStyleSelect = (updatedFields) => {
  return (
    {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: (theme) => updatedFields ? theme.palette.secondary.main : ''
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: (theme) => updatedFields ? theme.palette.secondary.main : ''
      },
    }
  )
}

export const batchUpdateStyleSelectLabel = (updatedFields) => {
  return (
    {
      color: (theme) => updatedFields? theme.palette.secondary.main : '',
      '&.Mui-focused ' : {
        color: (theme) => updatedFields? theme.palette.secondary.main : '',
      }
    }
  )
}