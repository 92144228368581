import React from "react";
import { SvgIcon } from "@mui/material";
import { TimelineDot } from "@mui/lab";

const TimelineEventDot = ({ Icon }: { Icon: typeof SvgIcon }) => {
  return (
    <TimelineDot
      color={"secondary"}
      sx={{
        backgroundColor: (theme) => theme.palette.tertiary.main,
        borderColor: (theme) => theme.palette.tertiary.main,
        boxShadow: "none",
        color: "inherit",
        fontSize: "20px",
        my: 0,
        p: "6px"
      }}
    >
      <Icon fontSize={"small"} />
    </TimelineDot>
  )
}

export default TimelineEventDot