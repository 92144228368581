import React, { useEffect } from "react";
import { LinkedDocumentModal } from "../LinkedDocumentForm/LinkedDocumentModal";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";

const LETTER_DOCUMENT_FORM_NAME = "letter_document_modal_form"
export const LetterDocumentModal = ({
  letter,
  onModalClose
}) => {
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)

  useEffect(() => {
    if(letter.id && letter.linkedDocument){
      setDocument(letter.linkedDocument)
    }
  }, [letter.id, letter.linkedDocument]);

  return (
    <LinkedDocumentModal
      formName={LETTER_DOCUMENT_FORM_NAME}
      document={document}
      category={DocumentCategory.LETTER}
      title={document?.description || "Letter"}
      onModalClose={onModalClose}
      dataTestId={"letterDocument-modal"}
    />
  )
}