import React from 'react'
import { Form, RadioGroup, TextField, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { HISTORY_FREQUENCIES, HistoryFrequency } from '../../../../types/SocialHistory'

interface SocialHistoryOption {
  label: string
  value: string
}

interface SocialHistoryFormProps {
  onSubmit: () => void
}

export const SocialHistoryForm = ({ onSubmit } : SocialHistoryFormProps) => {
  return (
    <Form onSubmit={onSubmit}>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <ExerciseField label={'Exercise'} name={'exercise'} />
        <SocialHistoryField label={'Alcohol'} name={'alcohol'} />
        <SocialHistoryField label={'Smoking'} name={'smoking'} />
        <SocialHistoryField label={'Vaping'} name={'vaping'} />
        <SocialHistoryField label={'Caffeine'} name={'caffeine'} />
        <SocialHistoryField label={'Cannabis'} name={'cannabis'} />
        <SocialHistoryField label={'Other drugs'} name={'otherDrugs'} />
      </Box>
    </Form>
  )
}

const getOption = (historyFrequency: HistoryFrequency): SocialHistoryOption => {
  return { label: HISTORY_FREQUENCIES[historyFrequency], value: historyFrequency }
}

const SocialHistoryFieldComponent = ({ label, name, options }: { label: string, name: string, options: SocialHistoryOption[] }) => {
  return (
    <>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Box>
          <Typography variant={"h5"}>{label}</Typography>
          <RadioGroup
            name={`${name}Frequency`}
            row={true}
            options={options}
            sx={{ flexWrap: "nowrap" }}
            dataTestId={`${name}-frequencies`}
          />
        </Box>
        <TextField
          name={`${name}Note`}
          label={'Notes'}
          hideHelperText={true}
          fullWidth={true}
          dataTestId={`${name}-notes`}
        />
      </Box>
    </>
  )
}

const ExerciseField = (props: { label: string, name: string }) => {
  return (
    <SocialHistoryFieldComponent
      {...props}
      options={[
        getOption(HistoryFrequency.UNKNOWN),
        getOption(HistoryFrequency.NO),
        getOption(HistoryFrequency.YES)
      ]}
    />
  )
}

const SocialHistoryField = (props: { label: string, name: string }) => {
  return (
    <SocialHistoryFieldComponent
      {...props}
      options={[
        getOption(HistoryFrequency.UNKNOWN),
        getOption(HistoryFrequency.NEVER),
        getOption(HistoryFrequency.PAST_USE),
        getOption(HistoryFrequency.CURRENT)
      ]}
    />
  )
}