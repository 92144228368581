import { PatientSectionHeader } from "../PatientSectionHeader";
import React, { useEffect } from "react";
import { Section } from "saga-library/src";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { useAccountContext } from "../../../../providers/AccountContext";
import { schema, requiredSchema } from "./components/PrescriptionFormValidationsSchema";
import { PrescriptionType } from "../../../../types/Prescription";
import { PrescriptionActions } from "./components/PrescriptionActions";
import { useNavigate, useParams } from "react-router-dom";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { usePrescriptionUtils } from "./components/PrescriptionUtil";
import { NewPrescriptionForm } from "./components/NewPrescriptionForm";
import { prescriptionFormDefaultValues } from "./components/PrescriptionFormDefaultValues";
import { flushSync } from "react-dom";

const FORM_NAME = "new_prescription_form"

export const NewPrescription = () => {
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const { tenant_id, patient_id } = useParams()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { setAsHistorical, saveAsActive, saveAsDraft } = usePrescriptionUtils()

  const formMethods = useForm<PrescriptionType>({
    defaultValues: prescriptionFormDefaultValues(),
    schema: schema,
    requiredSchema: requiredSchema
  })

  const {
    handleSubmit,
    formState: { dirtyFields},
    reset,
    setValue,
    getValues
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `prescriptions/new`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const navigateToNewPrescription = (prescription) => {
    flushSync(() => {
      clearNavigationPrompt(FORM_NAME)
    })
    reset({},{keepValues: true})
    navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions/p/${prescription.id}`, tenant_id), {replace: true})
  }

  const onSetAsHistorical = () => {
    setAsHistorical(
      handleSubmit,
      (prescription) => navigateToNewPrescription(prescription)
    )
  }

  const onSaveAsActive = (onComplete: (any)=>void = navigateToNewPrescription) => {
    saveAsActive(
      setValue,
      handleSubmit,
      (prescription) => {
        onComplete(prescription)
      }
    )
  }

  const onSaveAsDraft = (onComplete: (any)=>void = navigateToNewPrescription) => {
    saveAsDraft(
      handleSubmit,
      (prescription) => {
        onComplete(prescription)
      }
    )
  }

  const onBack = () => {
    navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions`, tenant_id), {replace: true})
  }

  return <Section.Column
    sx={{ flex: "1 1 100%" }}
    header={
      <PatientSectionHeader
        dataTestId={'new-prescription'}
        sectionLabel={"New prescription"}
        showBackButton={true}
        onBack={onBack}
        showSave={false}
        actions={
          <PrescriptionActions
            formName={FORM_NAME}
            onSaveAsActive={onSaveAsActive}
            onSaveAsDraft={onSaveAsDraft}
            onSetAsHistorical={onSetAsHistorical}
            getValues={getValues}
            onPrintComplete={navigateToNewPrescription}
            handleSubmit={handleSubmit}
          />
        }
      />} >
    <FormProvider {...formMethods}>
      <NewPrescriptionForm
        onSubmit={navigateToNewPrescription}
      />
    </FormProvider>
  </Section.Column>
}