import React from 'react'
import { Section } from 'saga-library/src';
import { SectionColumnProps } from "saga-library/src/components/Section/Section";

export const SettingsSectionColumn = ({ children,  sx = {}, header }: SectionColumnProps) => {
  return (
    <Section.Column
      header={header}
      sx={{
        flex: '1 1 100%',
        mb: 0,
        width: '100%',
        height: 'calc(100% - 3px)',
        pt: 2,
        ...sx
      }}
      rightPadding={1}
    >
      {children}
    </Section.Column>
  )
}