import React from 'react'
import { Select } from "saga-library/src";
import { MenuItem } from "@mui/material";
import { useLocationContext } from "../providers/LocationContextProvider";
import { SelectionProps } from "saga-library/src/components/Select/Select";

interface LocationSelectProps extends Omit<SelectionProps, "children"> { }

export const LocationSelect = ({
  dataTestId,
  ...otherProps
}: LocationSelectProps) => {
  const { locations } = useLocationContext()

  return (
    <Select
      dataTestId={dataTestId}
      {...otherProps}
      >
      {locations && locations.map(({ name, id }) =>
        <MenuItem value={id} data-testid={`${dataTestId}-menuItem-${name}`}>{name}</MenuItem>
      )}
    </Select>
  )
}
