import React, { useState } from 'react'
import Snackbar from './Snackbar'
import { Button } from '../Button'

export default {
  title: 'Components/Snackbar',
  component: Snackbar,
}

export const Primary = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(!open)}>Toggle</Button>
      <Snackbar open={open} message={'Saved changes.'} setSnackbar={setOpen} />
    </>
  )
}

export const Error = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(!open)}>Toggle</Button>
      <Snackbar
        open={open}
        message={'Failed to save changes.'}
        error={true}
        setSnackbar={setOpen}
      />
    </>
  )
}
