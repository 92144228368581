import { gql } from "@apollo/client";

export const BILLING_DETAILS_FRAGMENT = gql`
  fragment PatientBillingDetails on Patient {
    serviceCode {
      id
      serviceCode
      description
    }
    diagnosticCodes {
      id
      diagnosticCode
      description
    }
  }
`


export const AB_CLAIM_HISTORY_FRAGMENT = gql`
  fragment AbClaimHistory on AbClaim {
    history{
      claimIdentifier
      claimPatientPHN
      assessmentOutcome
      assessmentAmount
      claimAmount
      explanatoryCodes
    }
  }
`


export const AB_CLAIM_OPTIONS_DETAILS_FRAGMENT = gql`
  fragment AbClaimOptionsDetails on AbClaim {
    paperDocumentation
    confidential
    originalEncounterDate
    originalFacility {
      id
      description
    }
    originalLocationCode
    newBornCode
    guardianUli
    guardianRegNumber
    skill {
      id
      description
      skillCode
    }
    payToCode
    payToUli
    interceptReason
  }
`


export const CLAIM_BASE_DETAILS_FRAGMENT = gql`
    fragment AbClaimBaseDetails on AbClaim {
        billingProfile {
            id
            practitionerLastName
            practitionerFirstName
            name
            locationCode
            practitionerId
            facility {
                id
                description
                facilityType
            }
            functionalCenter {
                id
                description
                functionalCenterCode
            }
        }
        patient {
            id
            firstName
            lastName
            dob
            diagnosticCodes {
                id
            }
            primaryIdentifier
            patientNotes {
              noteText
            }
        }
        serviceDate
        serviceCode {
            id
            serviceCode
            description
            baseAmount
        }
        diagnosticCodes {
            id
            description
            diagnosticCode
        }
        serviceCodeFeeModifiers {
            id
            feeMod
            feeModType
            description
        }
        calls
        encounter
        claimAmount
        facility {
            id
            description
        }
        functionalCenter {
            id
            description
            functionalCenterCode
        }
        locationCode
        referralPractitioner {
            id
            firstName
            lastName
            practitionerId
            street1
            street2
            street3
            city
            province
            disciplineDescription
            skills {
                id
                description
                skillCode
            }
            locationId
        }
        text
        claimPatientFirstName
        claimPatientLastName
        customClaimAmount

        feeMod1Units
        feeMod2Units
        feeMod3Units
        hospitalAdmissionDate
    }
`

export const CLAIM_OPTIONS_FRAGMENT = gql`
    fragment AbClaimOptionsDetails on AbClaim {
        paperDocumentation
        confidential
        originalEncounterDate
        originalFacility {
            id
            description
        }
        originalLocationCode
        newBornCode
        guardianUli
        guardianRegNumber
        skill {
            id
            description
            skillCode
        }
        payToCode
        payToUli
        interceptReason
    }
`



export const ASSESSED_AB_CLAIM_INFO_DETAILS_FRAGMENT = gql`
  fragment AssessedAbClaimInfoDetails on AbClaim {
    actionCode
    assessmentPostPit
    expectedPaymentDate
  }
`


export const ASSESSED_CLAIM_DATA_FRAGMENT = gql`
  ${CLAIM_BASE_DETAILS_FRAGMENT}
  ${CLAIM_OPTIONS_FRAGMENT}
  ${ASSESSED_AB_CLAIM_INFO_DETAILS_FRAGMENT}
  ${AB_CLAIM_HISTORY_FRAGMENT}
  fragment AssessedClaimDataFragment on AbClaim {
    id
    version
    claimIdentifier
    claimStatus
    acceptCode
    assessmentOutcome
    actionCode
    ...AbClaimBaseDetails
    ...AbClaimOptionsDetails
    explanatoryCodes {
      id
      explanatoryCode
      description
    }
    assessmentAmount
    adjusted
    ...AssessedAbClaimInfoDetails
    problems {
      severity
      field
      message
    }
    audit {
      creUserFirstName
      creUserLastName
      luUserFirstName
      luUserLastName
      crePit
      luPit
    }
    ...AbClaimHistory
  }
`
