import React, { Suspense } from "react";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { Section } from "saga-library/src";
import { ChartPanelErrorBoundary } from "./ChartPanelErrorBoundary";
import { Box } from "@mui/material";



const Fallback = ({loadingLabel}) => {
  return (
    <Section.Column headerLabel={loadingLabel} >
      <LoadingSpinner label={null} />
    </Section.Column>
  )
}


export const ChartPanelHOC = (loadingLabel, Component) => {
  return ({...props}) => {
    return (
      <ChartPanelErrorBoundary label={loadingLabel}>
        <Suspense fallback={<Fallback loadingLabel={loadingLabel} />} >
          <Component {...props}/>
        </Suspense>
      </ChartPanelErrorBoundary>
    )
  }
}


