import React from 'react'
import { Box } from '@mui/material'
import { TextField, Section } from 'saga-library/src'
import * as moment from 'moment'
import { rules } from '../../../util/validation'
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";

export type AdministrationDetailsType = {
  inactiveDate: moment.Moment | null
  inactiveReason: string
}

const defaults = {
  inactiveDate: null,
  inactiveReason: '',
}
const Form = (props) => {
  const dataTestId = props?.dataTestId

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
      <Section.SubHeader>Administration</Section.SubHeader>
      <FormattedDatePicker
        data-testid={`${dataTestId}-endDate`}
        label={'End date'}
        name={'inactiveDate'}
      />
      <TextField
        data-testid={`${dataTestId}-endDateReason`}
        label={'End date reason'}
        name={'inactiveReason'}
        inputProps={{ maxLength: rules.inactiveReason.max }}
      />
    </Box>
  )
}

export const AdministrationDetails = {
  Form: Form,
  defaults: defaults,
}
