import React, { createRef, useRef } from "react";
import { FileType } from "../../../types/tasks";
import { PDFDoc, PDFViewer } from '../../../components/FoxitPDFViewer/FoxitTypes'
import { DocumentProvider } from '../../../providers/DocumentProvider'

interface ReviewDocumentStateContextType {
  selectedFile: FileType | null,
  setSelectedFile: (file: FileType | null) => void,
  documentModified: boolean,
  setDocumentModified: (modified: boolean) => void,
  pdfDocRef?: React.MutableRefObject<PDFDoc | null>,
  pdfViewerRef?: React.MutableRefObject<PDFViewer | null>,
}

const defaultReviewDocumentContext: ReviewDocumentStateContextType = {
  selectedFile: null,
  setSelectedFile: () => {},
  documentModified: false,
  setDocumentModified: () => {},
  pdfDocRef: createRef(),
  pdfViewerRef: createRef(),
}

const ReviewDocumentStateContext = React.createContext(defaultReviewDocumentContext)

export const ReviewDocumentStateProvider = ({ children }) => {
  const [selectedFile, setSelectedFile] = React.useState<FileType | null>(null)
  const [documentModified, setDocumentModified] = React.useState<boolean>(false);
  const pdfDocRef = useRef<PDFDoc | null>(null)
  const pdfViewerRef = useRef<PDFViewer| null>(null)

  const providerValue : ReviewDocumentStateContextType = {
    selectedFile,
    setSelectedFile,
    documentModified,
    setDocumentModified,
    pdfDocRef,
    pdfViewerRef,
  }

  return (
    <ReviewDocumentStateContext.Provider value={providerValue}>
      <DocumentProvider>
        {children}
      </DocumentProvider>
    </ReviewDocumentStateContext.Provider>
  )
}

export const useReviewDocumentStateContext = () => {
  return React.useContext(ReviewDocumentStateContext)
}
