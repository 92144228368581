import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { TextFieldMask } from "saga-library/src";
import { AlbertaPHNFormatMask } from "../../../../../utils/validateIdentifiers";

interface BatchControlledTextFieldMaskProps {
  label: string
  name: string
  autoFocus?: boolean
}

export const BatchControlledTextFieldMask = ({
   label,
   name,
  autoFocus
 }: BatchControlledTextFieldMaskProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <TextFieldMask
      autoFocus={autoFocus}
      label={label}
      name={name}
      sx={{
        ...batchUpdateStyleSearch(dirtyFields[name]),
        flex: '1 1 auto'
      }}
      formatMask={AlbertaPHNFormatMask}
      maskChar={''}
    />
  )
}
