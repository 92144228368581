import React from 'react'
import ForgotPasswordForm from '../apps/account/Components/ForgotPasswordForm'
import { Box } from '@mui/material'

export const ForgotPassword = () => {
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <Box padding={2}>
        <ForgotPasswordForm></ForgotPasswordForm>
      </Box>
    </div>
  )
}
