import React from "react";
import TableRow from '@mui/material/TableRow'
import { CircularProgress, TablePagination } from "@mui/material";
import { TableList, TableListProps } from './TableList'
import { Button } from "../Button";
import { TableListCell } from './TableListRow'

export interface Connection {
  edges: Edge[],
  pageInfo: PageInfo
}

export interface Edge {
  cursor: string,
  node: any
}

export interface PageInfo {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
  endCursor: string | null
}

export interface PagedTableListProps extends TableListProps {
  pagingMode: 'paged' | 'continuous'
  pageInfo?: PageInfo
  onRequestNext: (cursor: string|null|undefined) => void,
  onRequestPrevious?: (cursor: string|null|undefined) => void,
  pageLoading?: boolean
}

export const PagedTableList = ({
  columns,
  pagingMode,
  pageInfo,
  onRequestNext,
  onRequestPrevious,
  pageLoading = false,
  expandable = false,
  ...rest
}: PagedTableListProps) => {
  const [page, setPage] = React.useState<number>(0)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if(newPage > page) {
      onRequestNext(pageInfo?.endCursor)
    }
    else if(newPage < page && onRequestPrevious) {
      onRequestPrevious(pageInfo?.startCursor)
    }
    setPage(newPage)
  }

  const tableFooter = pagingMode === 'continuous'
    ? <LoadMoreButton
        onClick={() => onRequestNext(pageInfo?.endCursor)}
        columns={columns.length + (expandable ? 1 : 0)}
        pageLoading={pageLoading}
      />
    : <TableRow>
        <TablePagination
          count={-1}
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={-1}
        />
      </TableRow>

  return (
    <TableList
      columns={columns}
      tableFooter={pageInfo?.hasNextPage && tableFooter}
      expandable={expandable}
      {...rest}
    />
  )
}

const LoadMoreButton = ({onClick, columns, pageLoading}) => {
  return (
    <TableRow>
      <TableListCell colSpan={columns} align={'center'}>
        {pageLoading ?
          <CircularProgress />
          :
          <Button
            name={'loadMoreButton'}
            variant={'text'}
            onClick={onClick}
          >
            load more
          </Button>
        }
      </TableListCell>
    </TableRow>
  )
}

export default PagedTableList
