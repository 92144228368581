import { Box } from "@mui/material";
import React from "react";


export const PatientDetailsContainer = ({sx={}, children}) => {
  return (
    <Box
      display={"grid"}
      gap={2}
      overflow={"auto"}
      height={"100%"}
      sx={{
        gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
        ...sx
      }}
    >
      {children}
    </Box>
  )
}