import React, { ReactNode, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { AddButton, NavigationTabs } from "saga-library/src";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { NavigationTabRoute } from "../../../../../components/NavigationTabRoute";
import { PractitionerBillingDetails, PractitionerBillingDetailsType } from "./PractitionerBillingDetails";
import { useFormContext } from "saga-library/src/components/Form";
import { HasPermissionTemplate } from "../../../../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";

const defaults = {
  billingProfileInputs: [],
}

const Form = () => {
  const { getValues, setValue } = useFormContext()
  const navigate = useNavigate()
  const { billing_id } = useParams()

  const [routes, setRoutes] = useState<ReactNode[]>([])

  const updateRoutes = (profileIndex?: null | number) => {
    const profiles = getValues('billingProfileInputs') || []
    if (profiles.length < 1) {
      const defaultProfile = {
        ...PractitionerBillingDetails.defaults,
        name: 'Billing profile',
        id: 'new0',
      }
      setValue(`billingProfileInputs.${routes.length}`, defaultProfile)
    }

    let initialRoutes: any[] = []
    profiles.forEach((profile, index: number) => {
      initialRoutes.push(
        <NavigationTabRoute
          route={`b/${profile.id}`}
          label={profile.name || 'Billing profile'}
          dataTestId={`practitionersBillingDetailsList-billingProfile-${index}`}
        />
      )
    })

    setRoutes(initialRoutes)

    let billingId =
      profileIndex || profileIndex === 0
        ? profiles[profileIndex]?.id
        : billing_id || profiles[0].id
    navigate(`b/${billingId}`)
  }

  useEffect(() => {
    updateRoutes()
  }, [])

  const addNewBillingProfile = () => {
    const profileId = routes.length
    setValue(`billingProfileInputs.${profileId}`, {
      ...PractitionerBillingDetails.defaults,
      id: `new${profileId}`,
    })
    updateRoutes(profileId)
  }

  const addBillingProfileButton = (
    <HasPermissionTemplate requiredType={PermissionType.Practitioner} requiredPermission={Permission.READWRITE}>
      <AddButton
        label="Add a billing profile"
        onClick={() => addNewBillingProfile()}
        dataTestId={"practitionersBillingDetailsList"}
      />
    </HasPermissionTemplate>
  )

  return (
   <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: 'fit-content',
      width: '100%',
      overflow: 'visible'
    }}
   >
     <NavigationTabs
       routes={routes}
       actions={[addBillingProfileButton]}
       underline={true}
       sx={{ ml: 2 }}
     />
     <Box
       sx={{
         display: 'flex',
         flexDirection: 'row'
     }}
     >
       {routes.length > 0 && <Outlet />}
     </Box>
    </Box>
  )
}

export type PractitionerBillingDetailsListType = {
  billingProfileInputs?: PractitionerBillingDetailsType[]
}

export const PractitionerBillingDetailsList = {
  Form: Form,
  defaults: defaults,
}
