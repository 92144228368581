import { PatientSectionHeader } from "../PatientSectionHeader";
import { TableList, Section, TableListHeaderConfig } from "saga-library/src";
import React, { useMemo } from 'react'
import { PatientOtherDocumentsRow } from "./components/PatientOtherDocumentsRow";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import { useReadQuery } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { OTHER_DOCUMENTS_SCROLL_KEY } from '../../../../providers/UserInteractionContext'

const columns: TableListHeaderConfig[] = [
  {
    name: 'Date',
    sx: { width: 120 }
  },
  { name: 'Description'}
]

const sectionTitle = "Other documents"

export const PatientOtherDocuments = ChartPanelHOC(sectionTitle, () => {
  const { documentQueryRef } = usePatientDocumentContext()
  return documentQueryRef && <PatientOtherDocumentsPanel/>
})

const PatientOtherDocumentsPanel = () => {
  const { documentQueryRef, parseLinkedDocumentQueryResults } = usePatientDocumentContext()
  const { patient_id, tenant_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { data } = useReadQuery(documentQueryRef!)
  const otherDocuments = useMemo(()=> parseLinkedDocumentQueryResults(data), [data, parseLinkedDocumentQueryResults])

  const onRowClicked = (documentId) =>
  {
    navigate(buildTenantRoute(`patients/p/${patient_id}/other-documents/d/${documentId}`, tenant_id))
  }

  return (
    <Section.Column
      header={
        <PatientSectionHeader
          sectionLabel={sectionTitle}
          showSave={false}
          dataTestId={'other-documents-header'}
          // This styling is here to match the design document.
          // If an Action is added to this section, this will need to be removed.
          // The New button would be forcing this styling in other patient chart sections.
          sx={{ height:'40px'}}
        />
      } >
      <TableList
        columns={columns}
        scrollKey={OTHER_DOCUMENTS_SCROLL_KEY}
        rows={otherDocuments.map((od, index) => PatientOtherDocumentsRow(od, index, onRowClicked))}
        emptyListComponent={ { message: "Other documents appear here once added." } }
        dataTestId={'other-documents-table'}
      />
    </Section.Column>
  )
}