import React, { useEffect } from "react"
import { usePageTitleContext } from "../providers/PageTitleContextProvider"
import { Permission, PermissionType } from '../types/settings/Permission'
import { HasPermissionTemplate } from '../components/HasPermissionTemplate'
import { ReportProvider } from '../providers/ReportProvider'
import { Reports } from '../apps/reports/Reports'
import { Route, Routes } from 'react-router-dom'
import { ReportDetails } from '../apps/reports/components/ReportDetails'
import { ReportEditor } from '../apps/reports/components/ReportEditor'

export const ReportRoutes = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Reports')
  }, [setAreaTitle])

  return (
    <HasPermissionTemplate
      requiredType={[PermissionType.ReportingBilling, PermissionType.ReportingPatientAndAppointment]}
      requiredPermission={Permission.READONLY}
      showAccessDenied={true}
    >
      <ReportProvider>
        <Routes>
          <Route path={'/'} element={<Reports />}>
            <Route index element={<ReportDetails />} />
            <Route path={'new/:category_type'} element={<ReportEditor />} />
            <Route path={'r/:report_id'} element={<ReportDetails />} />
            <Route path={'r/:report_id/edit'} element={<ReportEditor />} />
          </Route>
        </Routes>
      </ReportProvider>
    </HasPermissionTemplate>
  )
}