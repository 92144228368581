import { Pharmacy } from "saga-client/src/types";
import moment from "moment-timezone";

function formatName(firstName: string | null, lastName: string | null) {
  if (!firstName && !lastName) {
    return ""
  } else if (!firstName) {
    return lastName!
  } else if (!lastName) {
    return firstName
  }
  return `${lastName}, ${firstName}`
}

export function patientDisplayName(firstName: string | null, lastName: string | null) {
  return formatName(firstName, lastName)
}

export function userDisplayName(firstName: string | null, lastName: string | null) {
  return formatName(firstName, lastName)
}

export function referralPractitionerDisplayName(firstName: string, lastName: string) {
  return formatName(firstName, lastName)
}

export function practitionerDisplayName(firstName: string, lastName: string) {
  return formatName(firstName, lastName)
}

export function practitionerLocationDisplayName(firstName: string, lastName: string, location: string | null) {
  return `${formatName(firstName, lastName)} (${location})`
}

export function recipientListDisplayName(firstName: string, lastName: string, numOfRecipients: number) {
  const recipientName = `${lastName}, ${firstName}`
  const otherRecipients = numOfRecipients === 2 ? ' and 1 other' : numOfRecipients > 2 ? ` and ${numOfRecipients - 1} others` : ''
  return `${recipientName}${otherRecipients}`
}

export function fullAddress(pharmacy: Pharmacy) {
  const { street1, province, city, postalCode } = pharmacy
  return `${street1 ? street1 + ',' : ''} ${city ? city + ',' : ''} ${province ? province : ''} ${postalCode  ? postalCode : ''}`
}

export const formatNextAppointmentDate = (date) => {
  const dateMoment = moment(date, "YYYY-MM-DD")
  return dateMoment.isValid() && date !== '0001-01-01' ? dateMoment.format("dddd, MMMM DD, YYYY") : "N/A"
}

export const stringToPascalCase = (string) => {
  if(string) {
    return `${string}`
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, 'g'), ' ')
      .replace(new RegExp(/[^\w\s]/, 'g'), '')
      .replace(
        new RegExp(/\s+(.)(\w*)/, 'g'),
        ($1, $2, $3) => `${$2.toUpperCase() + $3}`
      )
      .replace(new RegExp(/\w/), s => s.toUpperCase())
  }
}

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  const padToTwoDigits = (num) => {
    return num.toString().padStart(2, '0')
  }

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`
}
