import { Controller, useFormContext } from "saga-library/src/components/Form";
import React, { useEffect, useRef, useState } from "react";
import { useTheme, SxProps } from "@mui/material";
import { SimpleTextField } from "saga-library/src";
import { MultipleValuesBatchMessage } from "../../../../../utils/ABClaimConstants";
import { MultipleValuesTextField } from "saga-library/src/components/TextField/TextField";
import Box from "@mui/material/Box";
import { SimpleTooltip } from "saga-library/src/components/Tooltip";
import { Theme } from "@mui/system"

export interface TextFieldProps {
  name: string
  label: string
  sx?: SxProps<Theme>
  rules?: object
  inputProps?: object
  type?: 'tel' | 'email' | 'text' | 'password' | 'number'
  field?: 'calls' | 'encounter' | 'claimAmount' | 'text'
  multiline?: boolean
  rows?: number
  InputProps?: object
  fullWidth?: boolean
  multipleValuesState?: boolean
  setUpdatedFields: () => void
  dataTestId?: string
}

export default function BatchControlledTextField({
  name,
  label,
  sx,
  rules,
  inputProps,
  type = 'text',
  multiline,
  rows,
  InputProps,
  fullWidth = false,
  multipleValuesState,
  field,
  setUpdatedFields,
  dataTestId
}: TextFieldProps) {
  const { control, formState: { dirtyFields } } = useFormContext();
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [showText, setShowText] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const overflowingText = useRef<HTMLInputElement | null>(null);
  const theme = useTheme()

  const checkOverflow = (textContainer: HTMLInputElement | null): boolean => {
    if (textContainer) {
      return (textContainer.offsetWidth < textContainer.scrollWidth)
    }
    return false
  }

  useEffect(() => {
    if (checkOverflow(overflowingText.current)) {
      setOverflowActive(true)
      return
    } else {
      setOverflowActive(false)
    }
  }, [])

  const batchInputSx = {
    '& .MuiInputLabel-root': {
      color: (field === 'text' && dirtyFields.text) || (field === 'calls' && dirtyFields.calls) || (field === 'encounter' && dirtyFields.encounter) || (field === 'claimAmount' && dirtyFields.claimAmount) || (!field && dirtyFields[name]) ? theme.palette.secondary.main : '',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: (field === 'text' && dirtyFields.text) || (field === 'calls' && dirtyFields.calls) || (field === 'encounter' && dirtyFields.encounter) || (field === 'claimAmount' && dirtyFields.claimAmount) || (!field && dirtyFields[name]) ? theme.palette.secondary.main : '',
      }
    },
    ...sx
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value = '', ref } }) => {
        return (
          <>
            {
              (multipleValuesState && !showText) ?
                <SimpleTooltip title={MultipleValuesBatchMessage} open={tooltipOpen}>
                  <Box
                    display={'flex'}
                    sx={sx}
                  >
                    <MultipleValuesTextField
                      name={name}
                      dataTestId={dataTestId}
                      fullWidth={fullWidth}
                      inputRef={ref}
                      label={label}
                      overflowActive={overflowActive}
                      onKeyDown={(e) => {
                        // Backspace or delete key
                        if (e.keyCode === 8 || e.keyCode === 46) {
                          setShowText(true)
                          setUpdatedFields()
                        }
                      }}
                      rows={rows}
                      multiline={multiline}
                      onMouseOver={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                    />
                  </Box>
                </SimpleTooltip>
                :
                <SimpleTextField
                  name={name}
                  dataTestId={dataTestId}
                  onChange={(data) => {
                    onChange(data)
                  }}
                  value={value || ''}
                  label={label}
                  type={type}
                  inputProps={inputProps}
                  multiline={multiline}
                  rows={rows}
                  InputProps={InputProps}
                  fullWidth={fullWidth}
                  inputRef={ref}
                  sx={batchInputSx}
                  autoFocus={showText}
                />
            }
          </>
        )
      }}
    />
  )
}
