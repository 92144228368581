import { Audit } from './Audit'

export type SocialHistoryType = {
  exerciseFrequency: HistoryFrequency
  exerciseNote?: string
  alcoholFrequency: HistoryFrequency
  alcoholNote?: string
  smokingFrequency: HistoryFrequency
  smokingNote?: string
  vapingFrequency: HistoryFrequency
  vapingNote?: string
  caffeineFrequency: HistoryFrequency
  caffeineNote?: string
  cannabisFrequency: HistoryFrequency
  cannabisNote?: string
  otherDrugsFrequency: HistoryFrequency
  otherDrugsNote?: string
  version?: string
  audit?: Audit
}

export enum HistoryFrequency {
  NO = 'NO',
  YES = 'YES',
  UNKNOWN = 'UNKNOWN',
  NEVER = 'NEVER',
  PAST_USE = 'PAST_USE',
  CURRENT = 'CURRENT'
}

export const HISTORY_FREQUENCIES: { [key in HistoryFrequency]: string } = {
  [HistoryFrequency.NO]: "No",
  [HistoryFrequency.YES]: "Yes",
  [HistoryFrequency.UNKNOWN]: "Unknown",
  [HistoryFrequency.NEVER]: "Never",
  [HistoryFrequency.PAST_USE]: "Past use",
  [HistoryFrequency.CURRENT]: "Current"
}