import React, { useState } from 'react'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { Autocomplete } from "saga-library/src";
import { EntryDescription, EntryTitle } from "saga-library/src";
import Box from "@mui/material/Box";
import { createFilterOptions, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { GET_FUNCTIONAL_CENTERS } from "../../graphql-definitions";
import { FunctionalCenter } from "../../types/billing";
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'


interface ControlledFunctionalCenterInputProps {
  name: string
  label?: string
  onClear?: () => void
  onChange?: () => void
  autoFocus?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean,
  dataTestId?: string
}

export const ControlledFunctionalCenterInput = ({
  name,
  label = 'Functional centre',
  onClear,
  onChange,
  autoFocus,
  disabled = false,
  sx,
  dataTestId
}: ControlledFunctionalCenterInputProps) => {

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange : controlledOnChange, ref }, fieldState: {error} }) => {
        return (
          <FunctionalCenterInput
            value={value}
            label={label}
            onChange={(fc) => {
              controlledOnChange(fc)
              if (onChange) onChange()
            }}
            onClear={onClear}
            name={name}
            error={error}
            helperText={error?.message}
            autoFocus={autoFocus}
            inputRef={ref}
            disabled={disabled}
            sx={sx}
            dataTestId={dataTestId}
          />
        )
      }}
    />
  )
}

const FunctionalCenterInput = ({
  value,
  label,
  onChange,
  onClear,
  name,
  error,
  helperText,
  autoFocus,
  inputRef,
  disabled = false,
  sx,
  dataTestId
}) => {

  const [functionalCenterList, setFunctionalCenterList] = useState<FunctionalCenter[]>([])

  const { loading } = useQuery( GET_FUNCTIONAL_CENTERS,
    {
      onCompleted: (data) => {
        setFunctionalCenterList( _get(data, 'static.abClaimFunctionalCenters', null))
      },
      fetchPolicy: 'cache-first',
    }
  )

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: FunctionalCenter) => option.functionalCenterCode + option.description,
  })

  const Option = ({props, data, inputValue}) => {
    return (
      <span key={data.functionalCenterCode}>
        <Box component="li" {...props}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EntryTitle
              title={data.functionalCenterCode}
              searchText={inputValue}
              dataTestId={`functionalCenterInput-${dataTestId}-functionalCenterCode`}
            />
            <EntryDescription
              text={data.description}
              searchText={inputValue}
              dataTestId={`functionalCenterInput-${dataTestId}-description`}
            />
          </Box>
        </Box>
      </span>
    )
  }

  return (
    <Autocomplete
      name={name}
      label={label}
      loading={loading}
      onChange={(e, newValue) => {
        if (newValue == null) {
          onClear()
        }
        onChange(newValue)
      }}
      value={value}
      options={functionalCenterList}
      OptionComponent={Option}
      getOptionLabel={(option) => {
        return option.functionalCenterCode
      }}
      filterOptions={filterOptions}
      error={error}
      helperText={helperText}
      autoFocus={autoFocus}
      inputRef={inputRef}
      isOptionEqualToValue={IdEqual}
      disabled={disabled}
      sx={sx}
      dataTestId={`functionalCenterInput-${dataTestId}`}
    />
  )
}
