import { getScheduleStorageKey } from "../../../config/LocalStorageKeys";
import moment from "moment-timezone";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { ScheduleLength } from "../../../types/schedule/Schedule";

export const defaultScheduleView : {view: ScheduleLength, date: string, schedules:string[]} = {
  view: ScheduleLength.DAY,
  date: '',
  schedules: []
}
export const useLastViewed = () => {
  const { tenant_id } = useParams()
  const { userId } = useAccountContext()
  const getLastViewed = (allSchedules) => {
    let storedView = localStorage.getItem(getScheduleStorageKey(userId, tenant_id))
    if(storedView) {
      let lastView = JSON.parse(storedView)
      if (!Array.isArray(lastView.schedules)) {
        lastView.schedules = []
      }

      if(allSchedules.length === 0) {
        lastView.schedules = []
      } else if(lastView.schedules.length > 0) {
        lastView.schedules = lastView.schedules.filter(s => allSchedules.some(as => as.id === s))
      } else {
        lastView.schedules = [allSchedules[0].id]
      }

      const dateRecorded = new Date(lastView.dateRecorded).toLocaleDateString()
      if (dateRecorded !== new Date().toLocaleDateString()) {
        //no date recorded today, so default to today
        lastView.date = new Date().toISOString()
      }

      const date = new Date(lastView.date)
      if (date.getHours() === 0) {
        //set the hour to noon
        lastView.date = moment(lastView.date).set({hour: 12, minute: 0, second: 0, millisecond: 0}).toDate()
      }

      return lastView
    } else {
      defaultScheduleView.date = new Date().toISOString()
      return {
        ...defaultScheduleView,
        schedules: allSchedules.length > 0 ? [allSchedules[0].id]: []
      }
    }
  }

  const setLastViewed = (scheduleView) => {
    localStorage.setItem(getScheduleStorageKey(userId, tenant_id), JSON.stringify({
      ...scheduleView,
      dateRecorded: new Date().toISOString()
    }))
  }

  return {
    getLastViewed,
    setLastViewed
  }
}