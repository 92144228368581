import { AbClaimServiceCode } from "../../../types/billing/AbClaim/AbClaimServiceCode";
import { AbClaimDiagnosticCode } from "../../../types/billing/AbClaim/AbClaimDiagnosticCode";
import { AbClaimServiceCodeFeeModifier } from "../../../types/billing/AbClaim/AbClaimServiceCodeFeeModifier";
import { Box } from "@mui/material";
import React, { useState } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InfoFilledIcon from '@mui/icons-material/Info';
import { StyledTooltip, Typography } from 'saga-library/src'

interface ServiceCodeInfoTooltipProps {
  serviceCode: AbClaimServiceCode | undefined
}

export const ServiceCodeInfoTooltip = ({serviceCode}: ServiceCodeInfoTooltipProps) => {

  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      title={
        serviceCode ?
          <Box p={1}>
            <Typography variant={'subtitle1'} fontWeight={'bold'}>{serviceCode.serviceCode}</Typography>
            <Typography variant={'body1'} >${serviceCode.baseAmount}</Typography>
            <Typography variant={'body1'} >{serviceCode.description}</Typography>
          </Box>
        :
          <Typography variant={'body1'} >Select a service code for more information.</Typography>
      }
    >
      <Box
        onClick={() =>
          setOpen(!open)
        }
      >
        {open ?
          <InfoFilledIcon fontSize={'medium'} />
          :
          <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}

interface DiagnosticCodesInfoTooltipProps {
  diagnosticCodes: AbClaimDiagnosticCode[]
}

export const DiagnosticCodesInfoTooltip = ({diagnosticCodes}: DiagnosticCodesInfoTooltipProps) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      title={
        diagnosticCodes?.length ?
          <Box p={1}>
            {diagnosticCodes.map((diagnosticCode, index) =>
              <Box mb={1} key={'diagnosticinfo-'+index}>
                <Typography variant={'subtitle1'} fontWeight={'bold'}>{diagnosticCode.diagnosticCode}</Typography>
                <Typography variant={'body1'} >{diagnosticCode.description}</Typography>
              </Box>
            )}
          </Box>
          :
          <Typography variant={'body1'} >Select one or more diagnostic codes for more information.</Typography>
      }
    >
      <Box
        onClick={() =>
          setOpen(!open)
        }
      >
        {open ?
          <InfoFilledIcon fontSize={'medium'} />
          :
          <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}

interface FeeModifierInfoTooltipProps {
  feeModifiers: AbClaimServiceCodeFeeModifier[]
}

export const FeeModifierInfoTooltip = ({feeModifiers}: FeeModifierInfoTooltipProps) => {

  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      style={{paddingTop: '18px'}}
      title={
        feeModifiers?.length > 0 ?
          <Box p={1}>
            {feeModifiers.map((feeModifier, index) =>
              <Box key={index} mb={1}>
                <Typography variant={'subtitle1'} fontWeight={'bold'}>{feeModifier.feeMod}</Typography>
                <Typography variant={'body1'} >{feeModifier.description}</Typography>
              </Box>
            )}
          </Box>
          :
          <Typography variant={'body1'} >Select one or more fee modifiers for more information.</Typography>
      }
    >
      <Box
        onClick={() =>
          setOpen(!open)
        }
      >
        {open ?
          <InfoFilledIcon fontSize={'medium'} />
          :
          <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}

export const ExplanatoryCodeInfoTooltip = ({explanatoryCode}) => {

  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      title={
        <Box mb={1}>
          <Typography variant={'subtitle1'} fontWeight={'bold'}>{explanatoryCode.explanatoryCode}</Typography>
          <Typography variant={'body1'} >{explanatoryCode.description}</Typography>
        </Box>
      }
    >
      <Box
        onClick={() =>
          setOpen(!open)
        }
      >
        {open ?
          <InfoFilledIcon fontSize={'medium'} />
          :
          <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}