import { ConditionAndProcedureIcon } from "../ChartIcons";
import {
  IconImageTooltip,
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from "saga-library/src";
import React, { useMemo, useState } from 'react'
import Widget from "../Widget";
import {
  ConditionAndProcedureType,
  ConditionSeverityMap,
  ConditionStatusMap
} from "../../../../types/ConditionAndProcedure";
import EditConditionAndProcedureDialog from "../conditionAndProcedures/EditConditionAndProcedureDialog";
import NewConditionAndProcedureDialog from "../conditionAndProcedures/NewConditionAndProcedureDialog";
import { useReadQuery } from "@apollo/client";
import { useConditionAndProcedureContext } from "../../providers/ConditionAndProcedureProvider";

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: '' },
  { name: '' }
]

export const ConditionAndProceduresWidget = () => {
  const [ openDialog, setOpenDialog]  = useState(false)
  const [ conditionAndProcedure, setConditionAndProcedure]  = useState<any>(null)

  const onRowClick = (conditionAndProcedure) => {
    setConditionAndProcedure(conditionAndProcedure)
  }

  return <Widget title={'Conditions and Procedures'} WidgetIcon={ConditionAndProcedureIcon} onAddItem={() => setOpenDialog(true)} >
      <ConditionAndProcedureWidgetBody onRowClick={onRowClick} />
      <NewConditionAndProcedureDialog open={openDialog} setOpen={setOpenDialog} />
      <EditConditionAndProcedureDialog conditionAndProcedure={conditionAndProcedure} setConditionAndProcedure={setConditionAndProcedure} />
  </Widget>
}


const ConditionAndProcedureWidgetBody = ({ onRowClick }) => {
  const { conditionAndProcedureQueryRef, parseConditionAndProcedureQueryResults } = useConditionAndProcedureContext()
  const { data } = useReadQuery(conditionAndProcedureQueryRef!)
  const conditionAndProcedures = useMemo(()=> parseConditionAndProcedureQueryResults(data), [data, parseConditionAndProcedureQueryResults])

  return (
    <TableList
      dataTestId={'condition-and-procedure-widget-table'}
      columns={columns}
      showHeader={false}
      rows={[
        ...conditionAndProcedures.map((conditionAndProcedure) => ConditionAndProcedureRow(conditionAndProcedure, onRowClick))
      ]}
      sx={{height: '100%', "&.MuiTableCell-root": { borderBottom: null }}}
      tableSx={{ tableLayout: 'fixed' }}
      emptyListComponent={{ message: "Click the add button to add a condition or procedure.", size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      isWidget={true}
    />
  )
}



function ConditionAndProcedureRow(conditionAndProcedure: ConditionAndProcedureType, onRowClick): TableListRowConfig {
  const rowData = [
    {
      children:
        <Typography variant={'p2'} lineclamp={1}>
          {conditionAndProcedure.description}
        </Typography>,
      sx: { width: 'calc(50% - 8px)' }
    },
    {
      children: conditionAndProcedure.severity && (
        <IconImageTooltip title={ConditionSeverityMap.get(conditionAndProcedure.severity)!.label}>
          {ConditionSeverityMap.get(conditionAndProcedure.severity)!.icon}
        </IconImageTooltip>
      ),
      sx: { px: 0, width: '16px' }
    },
    {
      children:
        <Typography variant={'p2'}>
          {ConditionStatusMap.get(conditionAndProcedure?.status)!.label}
        </Typography>,
      sx: { width: 'calc(50% - 8px)' }
    },
  ] as TableListCellConfig[];

  return {
    onClick: () => onRowClick(conditionAndProcedure),
    rowData: rowData,
    key: conditionAndProcedure.id!
  }
}
