import React from 'react'
import { Box, DialogContentText } from '@mui/material'
import { DialogV2 } from 'saga-library/src'
import { TenantSelectionItem } from '../TenantSelectionItem'

interface TenantSelectionDialogProps {
  tenants: { id: string | null }[]
  activeTenant: string | null
  open: boolean
  onClose: () => void
  selectionHandler: (id) => void
  dataTestId: string
}

export const TenantSelectionDialog = ({
  tenants,
  activeTenant,
  open,
  onClose,
  selectionHandler,
  dataTestId,
}: TenantSelectionDialogProps) => {
  const onItemClick = (id) => {
    onClose()
    selectionHandler(id)
  }

  return (
    <DialogV2
      open={open}
      onClose={onClose}
      size={"xs"}
      title={"Switch clinics"}
      showActions={false}
      dataTestId={dataTestId}
    >
      <DialogContentText
        sx={{
          fontSize: '18px',
          color: 'greys.dark',
          mt: 1,
          mb: 2,
        }}
      >
        You have access to the following clinics:
      </DialogContentText>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        {tenants.map((tenant, index) => (
          <TenantSelectionItem
            key={tenant.id}
            id={tenant.id}
            tenant={tenant}
            isActive={activeTenant === tenant.id}
            onClick={() => onItemClick(tenant.id)}
            dataTestId={`${dataTestId}-item-${index}`}
          />
        ))}
      </Box>
      <DialogContentText
        sx={{
          textAlign: 'center',
          fontSize: '14px',
          color: 'greys.medium',
          mt: 2,
          mb: 4
        }}
      >
        If a clinic is missing from this list, contact that clinic.
      </DialogContentText>
    </DialogV2>
  )
}

export default TenantSelectionDialog
