import React from 'react'
import { Form, TextField } from 'saga-library/src'
import { rules } from './TaskTypeValidationsSchema'

interface TaskTypeFormProps {
  formName: string
  onSubmit: () => void
  dataTestId: string
}

export const TaskTypeForm = ({
  formName,
  onSubmit,
  dataTestId
}: TaskTypeFormProps) => {
  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TextField
        name={'type'}
        label={'Name'}
        inputProps={{ maxLength: rules.type.max }}
        fullWidth={true}
        dataTestId={`${dataTestId}-type`}
      />
    </Form>
  )
}