import { Box, Typography, useTheme } from '@mui/material'
import { Button } from 'saga-library/src'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React from 'react'
import { FileType } from '../../../../../types/tasks'

export interface LabAndInvestigationFileNavigationProps {
  files: FileType[],
  selectedFile: FileType | null,
  setSelectedFile: (file: FileType | null) => void
  dataTestId: string
}

export const LabAndInvestigationFileNavigation = ({
  files,
  selectedFile,
  setSelectedFile,
  dataTestId
}:LabAndInvestigationFileNavigationProps) => {
  const theme = useTheme()

  const previousFile = () => {
    if (files.length === 0) {
      setSelectedFile(null)
      return
    }

    const currentFileIndex = files.findIndex(f => f.id === selectedFile?.id)

    if (currentFileIndex < 0) {
      // can't find the current selected file so just select the first one
      setSelectedFile(files[0])
    } else if (currentFileIndex === 0) {
      // no more previous files
    } else {
      setSelectedFile(files[currentFileIndex - 1])
    }
  }

  const nextFile = () => {
    if (files.length === 0) {
      setSelectedFile(null)
      return
    }

    const currentFileIndex = files.findIndex(f => f.id === selectedFile?.id)

    if (currentFileIndex < 0) {
      // can't find the current selected file so just select the first one
      setSelectedFile(files[0])
    } else if (currentFileIndex >= files.length - 1) {
      // no more next files
    } else {
      setSelectedFile(files[currentFileIndex + 1])
    }
  }

  return (
    <>
      <Typography
        color={theme.palette.greys.medium}
        paddingX={3}
        flex={"0 0 auto"}
      >
        <Box component={"span"} sx={{ fontWeight: "700" }}>{files.findIndex(f => f.id === selectedFile?.id) + 1}</Box>
        <Box component={"span"} sx={{ fontWeight: "400" }}>{` OF ${files.length} FILES`}</Box>
      </Typography>
      <Button
        name={"previousFile"}
        dataTestId={`${dataTestId}-previousFile`}
        variant={'outlined'}
        sx={{width: '56px', minWidth: '56px'}}
        disabled={files.findIndex(f => f.id === selectedFile?.id) === 0}
        onClick={previousFile}
      >
        <KeyboardArrowLeftIcon />
      </Button>
      <Button
        name={"nextFile"}
        dataTestId={`${dataTestId}-nextFile`}
        variant={'outlined'}
        sx={{display: "flex", gap: "8px", flex: "0 0 auto"}}
        disabled={files.findIndex(f => f.id === selectedFile?.id) === files.length - 1}
        onClick={nextFile}
      >
        NEXT FILE
        <KeyboardArrowRightIcon />
      </Button>
    </>
  )
}