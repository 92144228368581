import { usePageTitleContext } from '../../providers/PageTitleContextProvider'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BillingTabs } from './BillingTabs'
import { UnsubmittedClaimsLayout } from './UnsubmittedClaimsLayout'

import AssessedClaimsLayout from './AssessedClaimsLayout'
import { UnsubmittedClaimsProvider } from './providers/UnsubmittedClaimsProvider'
import { AssessedClaimsProvider } from './providers/AssessedClaimsProvider'
import { NewUnsubmittedClaim } from './components/UnsubmittedClaims/NewUnsubmittedClaim'
import { EditUnsubmittedClaim } from './components/UnsubmittedClaims/EditUnsubmittedClaim'
import { BatchUpdateDetails } from './components/BatchUpdate/UnsubmittedClaims/BatchUpdateDetails'
import { EditAssessedClaim } from './components/AssessedClaims/EditAssessedClaim'
import { EmptyList } from 'saga-library/src'
import { BatchUpdateAssessedDetails } from './components/BatchUpdate/AssessedClaims/BatchUpdateAssessedDetails'
import { NoneSelected } from './components/AssessedClaims/NoneSelected'


export const BillingRoutes = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Billing')
  }, [setAreaTitle])

  return (
    <Routes>

      <Route path={'/'} element={<BillingTabs />}>

        <Route path={'assessed'} element={<AssessedClaimsProvider />}>
          <Route element={<AssessedClaimsLayout />}>
            <Route index element={<NoneSelected/>} />
            <Route path={'c/update-batch'} element={<BatchUpdateAssessedDetails/>} />
            <Route path={'c/:claim_id'} element={<EditAssessedClaim/>} />
          </Route>
        </Route>

        <Route path={'/'} element={<UnsubmittedClaimsProvider />}>
          <Route element={<UnsubmittedClaimsLayout />}>
            <Route index element={<NewUnsubmittedClaim/>} />
            <Route path={'c/update-batch'} element={<BatchUpdateDetails/>} />
            <Route path={'c/:claim_id'} element={<EditUnsubmittedClaim/>} />
          </Route>
        </Route>


      </Route>
    </Routes>
  )
}