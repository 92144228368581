import React from "react";
import { ConfirmationDialog } from "saga-library/src";
import { AbClaim } from "../../../../../types/billing/AbClaim/AbClaim";

interface BatchDeleteDialogProps {
  selectedClaimIds: string[]
  openDeleteModal: boolean
  setOpenDeleteModal: (v) => void
  onDelete: () => void
}
export const BatchDeleteDialog = ({selectedClaimIds, openDeleteModal, setOpenDeleteModal, onDelete} : BatchDeleteDialogProps) => {
  return (
    <ConfirmationDialog
      open={openDeleteModal}
      onClose={() => setOpenDeleteModal(false)}
      title={`Delete ${selectedClaimIds.length} claims?`}
      message={'This action cannot be undone'}
      primaryAction={onDelete}
      primaryLabel={'Delete'}
    />
  )
}
