import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useFormContext } from "react-hook-form";
import { TextField, ConfirmationDialog } from "saga-library/src";
import { useWatch } from "saga-library/src/components/Form"
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import FormattedDatePicker from "../../../../components/FormattedDatePicker";
import { CategorySelect } from "../../../tasks/documents/components/LinkDocumentSection";
import { PermissionForm } from "../../../../components/PermissionForm";
import { PermissionType } from "../../../../types/settings/Permission";
import { DocumentViewer } from "../../../../components/StandaloneDocumentViewer";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system"
import { PatientSearch } from "../../../../components/SearchControls/Patient/PatientSearch";
import TabFocusInterceptor from "saga-library/src/hooks/tabFocusInterceptor";

interface LinkedDocumentFormProps {
  formName: string
  document?: LinkedDocumentType
  showPatient?: boolean
  onSubmit: (data: any) => void
  sx?: SxProps<Theme>
  viewerSx?: SxProps<Theme>
  setCategoryIsChanged: (isChanged: boolean) => void
  dataTestId?: string
  variant?: "document" | "forms" | "labs"
}
export const LinkedDocumentForm = ({
  document,
  showPatient,
  onSubmit,
  formName,
  setCategoryIsChanged,
  sx,
  viewerSx,
  dataTestId,
  variant
}:LinkedDocumentFormProps) => {
  const [ openModal, setOpenModal ] = useState<boolean>(false)
  const { control, reset } = useFormContext()
  const categoryValue =  useWatch({ control, name: 'category'})

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(!document);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  useEffect(() => {
    if(document && categoryValue !== DocumentCategory[document.category]){
      setOpenModal(true)
      setCategoryIsChanged(true)
    } else {
      setOpenModal(false)
      setCategoryIsChanged(false)
    }
  },[categoryValue])

  useEffect(() => {
    if(document) {
      reset({
        category: DocumentCategory[document.category],
        description: document.description,
        documentDate: document.documentDate.toString(),
        patientId: null,
        version: document.version
      })
    }
  }, [document, reset])
  if(!document) {
    return <Box>
      Document not found
    </Box>
  }

  return <Box
    sx={{
      display:"flex",
      flexDirection:"column",
      overflow:"hidden",
      ...sx
    }}
    className={"LinkedDocumentForm"}
  >
    <PermissionForm
      name={'linked_document_form'}
      id={formName}
      sx={{flex: "0 0 auto"}}
      onSubmit={onSubmit}
      requiredPermissionType={PermissionType.Chart}
      readOnlyOverride={true}
    >
      <Box display={"flex"} gap={1} flex={"0 0 auto"}>
        {showPatient && <PatientSearch
            name={'patientId'}
            label={"Patient"}
            patientLabelVariant={"sm"}
            patientLabelSx={{ width: 296 }}
            sx={{ width: 296, flex: "0 0 auto" }}
            dataTestId={`${dataTestId}-patient`}
        />
        }
        <CategorySelect
          fullWidth={false}
          sx={{
            width: 200,
            flex: "0 0 200"
          }}
          inputRef={firstFieldRef}
          dataTestId={`${dataTestId}-category`}
        />

        <FormattedDatePicker
          label={'Date'}
          name={'documentDate'}
          dataTestId={`${dataTestId}-documentDate`}
        />

        <TextField
          label={'Description'}
          name={`description`}
          multiline={false}
          sx={{ flex: "1 1 auto" }}
          dataTestId={`${dataTestId}-description`}
        />
      </Box>
    </PermissionForm>
    {document.file && <DocumentViewer
      fileRecord={document.file}
      sx={{
        flex: "1 1 auto",
        ...viewerSx
      }}
    />}
    <ConfirmationDialog
      title={"Changing categories"}
      message={"Changing this document's category will move it to selected chart area once this document is saved."}
      open={openModal}
      primaryAction={()=>setOpenModal(false)}
    />
  </Box>
}