import PermissionSwitch from "./PermissionSwitch";
import { Card } from "saga-library/src";
import React, { useEffect } from "react";
import {
  useFormContext,
  useWatch
} from "saga-library/src/components/Form";

export interface PermissionSwitchGroupProps {
  label: string
  name: string
  readonlyName: string
  readwriteName: string
  readonlyDescription: string
  readwriteDescription: string
  disabled?: boolean
  dataTestId?: string
}

const PermissionSwitchGroup = ({
  label,
  name,
  readonlyDescription,
  readonlyName,
  readwriteDescription,
  readwriteName,
  disabled = false,
  dataTestId
}: PermissionSwitchGroupProps) => {

  const { control, setValue } = useFormContext();

  const readonly = useWatch({
    control,
    name: `${name}.readonly`
  })

  const readwrite = useWatch({
    control,
    name: `${name}.readwrite`
  })

  useEffect(() => {
    if(!readonly && readwrite) {
      setValue(`${name}.readwrite`, false, {shouldDirty: true})
    }
  }, [readonly])

  useEffect(() => {
    if(!readonly && readwrite) {
      setValue(`${name}.readonly`, true, {shouldDirty: true})
    }
  }, [readwrite])

  return (
    <Card label={label}>
      <PermissionSwitch
        dataTestId={`${dataTestId}-readonly`}
        name={`${name}.readonly`}
        label={readonlyName}
        description={readonlyDescription}
        disabled={disabled}
      />
      <PermissionSwitch
        dataTestId={`${dataTestId}-readwrite`}
        name={`${name}.readwrite`}
        label={readwriteName}
        description={readwriteDescription}
        disabled={disabled}
      />
    </Card>
  )
}

export default PermissionSwitchGroup
