import React from 'react'
import { Select } from "saga-library/src";
import { MenuItem, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { Controller, useFormContext } from "saga-library/src/components/Form";

interface ScheduleTimeSelectProps {
  name: string
  label: string
  defaultValue?: string,
  sx?: SxProps<Theme>,
  onChange?: (e) => void,
  dataTestId?: string
}

export const ScheduleTimeSelect = ({
  name,
  label,
  defaultValue,
  sx,
  onChange,
  dataTestId
}: ScheduleTimeSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange:controlledOnChange, value: fieldValue = null} }) => {
        return <Select
          dataTestId={dataTestId}
          label={label}
          name={name}
          sx={sx}
          defaultValue={defaultValue}
          onChange={(e) => {
            controlledOnChange(e)
            if (onChange) {
              onChange(e)
            }
          }}
        >
          <MenuItem data-testid={`${dataTestId}-menuitem-0 `} value={'PT0H'}>12:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-1 `} value={'PT0H30M'}>12:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-2 `} value={'PT1H'}>1:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-3 `} value={'PT1H30M'}>1:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-4 `} value={'PT2H'}>2:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-5 `} value={'PT2H30M'}>2:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-6 `} value={'PT3H'}>3:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-7 `} value={'PT3H30M'}>3:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-8 `} value={'PT4H'}>4:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-9 `} value={'PT4H30M'}>4:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-10`} value={'PT5H'}>5:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-11`} value={'PT5H30M'}>5:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-12`} value={'PT6H'}>6:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-13`} value={'PT6H30M'}>6:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-14`} value={'PT7H'}>7:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-15`} value={'PT7H30M'}>7:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-16`} value={'PT8H'}>8:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-17`} value={'PT8H30M'}>8:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-18`} value={'PT9H'}>9:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-19`} value={'PT9H30M'}>9:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-20`} value={'PT10H'}>10:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-21`} value={'PT10H30M'}>10:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-22`} value={'PT11H'}>11:00 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-23`} value={'PT11H30M'}>11:30 AM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-24`} value={'PT12H'}>12:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-25`} value={'PT12H30M'}>12:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-26`} value={'PT13H'}>1:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-27`} value={'PT13H30M'}>1:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-28`} value={'PT14H'}>2:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-29`} value={'PT14H30M'}>2:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-30`} value={'PT15H'}>3:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-31`} value={'PT15H30M'}>3:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-32`} value={'PT16H'}>4:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-33`} value={'PT16H30M'}>4:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-34`} value={'PT17H'}>5:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-35`} value={'PT17H30M'}>5:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-36`} value={'PT18H'}>6:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-37`} value={'PT18H30M'}>6:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-38`} value={'PT19H'}>7:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-39`} value={'PT19H30M'}>7:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-40`} value={'PT20H'}>8:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-41`} value={'PT20H30M'}>8:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-42`} value={'PT21H'}>9:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-43`} value={'PT21H30M'}>9:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-44`} value={'PT22H'}>10:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-45`} value={'PT22H30M'}>10:30 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-46`} value={'PT23H'}>11:00 PM</MenuItem>
          <MenuItem data-testid={`${dataTestId}-menuitem-47`} value={'PT23H30M'}>11:30 PM</MenuItem>
        </Select>
      }}
    />
  )
}

interface SimpleScheduleTimeSelectProps {
  name: string
  label: string
  defaultValue?: string,
  sx?: SxProps<Theme>,
  dataTestId?: string
}

export const SimpleScheduleTimeSelect = ({
  name,
  label,
  defaultValue,
  sx,
  dataTestId
}: SimpleScheduleTimeSelectProps) => {
  return (
    <Select
      dataTestId={dataTestId}
      label={label}
      name={name}
      sx={sx}
      defaultValue={defaultValue}
    >
      <MenuItem data-testid={`${dataTestId}-menuitem-0 `} value={'PT0H'}>12:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-1 `} value={'PT0H30M'}>12:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-2 `} value={'PT1H'}>1:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-3 `} value={'PT1H30M'}>1:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-4 `} value={'PT2H'}>2:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-5 `} value={'PT2H30M'}>2:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-6 `} value={'PT3H'}>3:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-7 `} value={'PT3H30M'}>3:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-8 `} value={'PT4H'}>4:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-9 `} value={'PT4H30M'}>4:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-10`} value={'PT5H'}>5:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-11`} value={'PT5H30M'}>5:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-12`} value={'PT6H'}>6:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-13`} value={'PT6H30M'}>6:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-14`} value={'PT7H'}>7:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-15`} value={'PT7H30M'}>7:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-16`} value={'PT8H'}>8:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-17`} value={'PT8H30M'}>8:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-18`} value={'PT9H'}>9:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-19`} value={'PT9H30M'}>9:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-20`} value={'PT10H'}>10:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-21`} value={'PT10H30M'}>10:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-22`} value={'PT11H'}>11:00 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-23`} value={'PT11H30M'}>11:30 AM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-24`} value={'PT12H'}>12:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-25`} value={'PT12H30M'}>12:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-26`} value={'PT13H'}>1:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-27`} value={'PT13H30M'}>1:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-28`} value={'PT14H'}>2:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-29`} value={'PT14H30M'}>2:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-30`} value={'PT15H'}>3:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-31`} value={'PT15H30M'}>3:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-32`} value={'PT16H'}>4:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-33`} value={'PT16H30M'}>4:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-34`} value={'PT17H'}>5:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-35`} value={'PT17H30M'}>5:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-36`} value={'PT18H'}>6:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-37`} value={'PT18H30M'}>6:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-38`} value={'PT19H'}>7:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-39`} value={'PT19H30M'}>7:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-40`} value={'PT20H'}>8:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-41`} value={'PT20H30M'}>8:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-42`} value={'PT21H'}>9:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-43`} value={'PT21H30M'}>9:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-44`} value={'PT22H'}>10:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-45`} value={'PT22H30M'}>10:30 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-46`} value={'PT23H'}>11:00 PM</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuitem-47`} value={'PT23H30M'}>11:30 PM</MenuItem>
    </Select>
  )
}
