import React from 'react'
import { DefaultIdentifierList } from '../components/DefaultIdentifierList'
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";

export const Identifiers = () => {
  return (
    <SettingsSectionColumn header={
      <SettingsHeader title={'Identifiers'} dataTestId={'identifiers'} />
    }>
      <DefaultIdentifierList />
    </SettingsSectionColumn>
  )
}
