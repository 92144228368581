import { useFormContext, useWatch } from 'saga-library/src/components/Form'
import { rules } from "./roleValidation";
import React, { useEffect, useState } from 'react'
import { Box } from "@mui/material";
import { Card, Button, Section, TextField } from 'saga-library/src'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ControlledUserSearch } from "./TenantRoleUsers";
import TenantRoleSelector from "./TenantRoleSelector";
import PermissionSwitch from "./PermissionSwitch";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import PermissionSwitchGroup from "./PermissionSwitchGroup";
import { PermissionForm } from '../../../../components/PermissionForm'
import { Role } from "../../../../types/account";
import { getTenantRoleFormDefaultValues } from "./TenantRoleFormDefaultValues";

const DATA_TEST_ID = "tenantRoleForm"

export const getPermissionForField = (readonly: boolean, readwrite: boolean): Permission => {
  if(readwrite) return Permission.READWRITE
  if(readonly) return Permission.READONLY
  return Permission.NONE
}

export const getPermissionForReportingField = (readonly: boolean, readwrite: boolean): Permission => {
  if(readwrite && readonly) return Permission.READWRITE
  if(readonly) return Permission.READONLY
  return Permission.NONE
}

interface TenantRoleFormProps {
  onSubmit: (data, dirtyFields?, getValues?) => void
  name: string
  role?: Role
}


const TenantRoleForm = ({ onSubmit, name, role }: TenantRoleFormProps) => {
  const [showCopyFrom, setCopyFrom] = useState(false)

  const {
    control,
    reset,
    setValue
  } = useFormContext()

  const reportingPatientAndAppointment = useWatch({
    control,
    name: "reporting.patientAndAppointment"
  })

  const reportingBilling = useWatch({
    control,
    name: "reporting.billing"
  })

  const reportingManagement = useWatch({
    control,
    name: "reporting.readwrite"
  })

  useEffect(() => {
    if (!reportingPatientAndAppointment && !reportingBilling && reportingManagement) {
      setValue('reporting.readwrite', false)
    }
  }, [reportingPatientAndAppointment, reportingBilling, reportingManagement])

  const copyRole = (role: Role) => {
    reset({
      ...getTenantRoleFormDefaultValues(),
      isAdmin: role.isAdmin,
      billing: {
        readonly: Permission[role.permBilling] >= Permission.READONLY,
        readwrite: Permission[role.permBilling] >= Permission.READWRITE,
      },
      schedule: {
        readwrite: Permission[role.permSchedule] >= Permission.READWRITE,
      },
      practitioner: {
        readwrite: Permission[role.permPractitioner] >= Permission.READWRITE,
      },
      chart: {
        readwrite: Permission[role.permChart] >= Permission.READWRITE,
      },
      reporting: {
        billing: Permission[role.permReportingBilling] >= Permission.READONLY,
        patientAndAppointment: Permission[role.permReportingPatientAndAppointment] >= Permission.READONLY,
        readwrite: Permission[role.permReportingBilling] >= Permission.READWRITE || Permission[role.permReportingPatientAndAppointment] >= Permission.READWRITE,
      },
      labs:{
        review: Permission[role.permLabsReview] >= Permission.READWRITE,
      }
    })
  }

  return (
    <PermissionForm
      onSubmit={onSubmit}
      id={name}
      name={name}
      requiredPermissionType={PermissionType.Admin}
      readOnlyOverride={true}
    >
      <Section.Container>
        <Section.ColumnNoElevation>
          <Section.FormSection>
            <Section.Header>Details</Section.Header>
            <TextField
              dataTestId={`${DATA_TEST_ID}-name`}
              name={'name'}
              label={'Name'}
              fullWidth={false}
              inputProps={{ maxLength: rules.name.max }}
            />
            <TextField
              dataTestId={`${DATA_TEST_ID}-description`}
              name={'description'}
              label={'Description'}
              inputProps={{ maxLength: rules.description.max }}
            />
            <CopyRoleToggle
              dataTestId={`${DATA_TEST_ID}-copyRole`}
              showCopyFrom={showCopyFrom}
              setCopyFrom={setCopyFrom}
              onChange={copyRole}
              roleId={role?.id}
            />
          </Section.FormSection>
          <Section.FormSection>
            <Section.Header>Permissions</Section.Header>
            <Card label={'General'}>
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-adminAccess`}
                name={'isAdmin'}
                label={'Administrative access'}
                description={"Manage clinic users and settings"}
              />
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-manageClinicPractitioners`}
                name={'practitioner.readwrite'}
                label={'Manage clinic practitioners'}
                description={"Create and edit practitioners"}
              />
            </Card>
            <Card label={'Patients'}>
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-manageChart`}
                name={'chart.readwrite'}
                label={'Manage chart'}
                description={"Create, edit, and delete items on a patient's chart"}
              />
            </Card>
            <PermissionSwitchGroup
              dataTestId={`${DATA_TEST_ID}-manageClaims`}
              label={"Billing"}
              name={'billing'}
              readonlyName={'View claims'}
              readwriteName={'Manage claims'}
              readonlyDescription={"View existing claims"}
              readwriteDescription={"Create, edit, submit, and resubmit claims"}
            />
            <Card label={'Schedule'}>
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-manageSchedule`}
                name={'schedule.readwrite'}
                label={'Manage schedule'}
                description={"Create and edit appointment types, appointment states, exam rooms, practitioner schedules, and day templates"}
              />
            </Card>
            <Card label={'Reporting'}>
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-reporting-billing`}
                name={'reporting.billing'}
                label={'View and run billing reports'}
                description={"View and run existing billing reports"}
              />
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-reporting-patientAndAppointment`}
                name={'reporting.patientAndAppointment'}
                label={'View and run patient and appointment reports'}
                description={"View and run existing patient and appointment reports"}
              />
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-reporting-manageReports`}
                name={'reporting.readwrite'}
                label={'Manage reports'}
                description={"Create, edit, and delete reports"}
                disabled={!reportingPatientAndAppointment && !reportingBilling}
                disabledHelper={"You must select at least one report type to view prior to managing reports"}
              />
            </Card>
            <Card label={"Labs & Investigations"}>
              <PermissionSwitch
                dataTestId={`${DATA_TEST_ID}-manageLabs`}
                name={'labs.review'}
                label={'Review labs & investigations'}
                description={"Review labs and investigations, and change who is assigned for review"}
              />
            </Card>
          </Section.FormSection>
        </Section.ColumnNoElevation>

        <Section.ColumnNoElevation>
          <Section.Header>Users</Section.Header>
          <ControlledUserSearch name={'roleUsers'} control={control} />
        </Section.ColumnNoElevation>
      </Section.Container>
    </PermissionForm>
  )
}

export const CopyRoleToggle = ({ showCopyFrom, setCopyFrom, onChange, roleId, disabled = false, dataTestId }) => {
  if (showCopyFrom) {
    return (
        <TenantRoleSelector dataTestId={`${dataTestId}-selector`} name={'role-selector'} onChange={onChange} currentRoleId={roleId} />
    )
  }

  return (
    <Box mb={2} mt={2}>
      {!disabled &&
        <Button
          name={'copy-link-button'}
          dataTestId={dataTestId}
          startIcon={<ContentCopyIcon sx={{ fontSize: 16, marginRight: 0.5 }} />}
          onClick={() => setCopyFrom(true)}
          variant={'text'}
          size={'small'}
          >
            Copy permission from another role
        </Button>
      }
    </Box>
  )
}

export default TenantRoleForm
