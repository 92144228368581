import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EncounterNoteTemplate } from '../types/patients'
import { useQuery } from '@apollo/client'
import { LIST_ENCOUNTER_NOTE_TEMPLATES } from '../graphql-definitions'
import { useAlerts } from 'saga-library/src/providers/Alerts'

interface EncounterNoteTemplateContextInterface {
  loading: boolean
  encounterNoteTemplates: EncounterNoteTemplate[]
  getEncounterNoteTemplate: (id: string) => EncounterNoteTemplate | undefined
}

const defaultEncounterNoteTemplateContext: EncounterNoteTemplateContextInterface = {
  loading: true,
  encounterNoteTemplates: [],
  getEncounterNoteTemplate: () => undefined,
}

const EncounterNoteTemplateContext = React.createContext(defaultEncounterNoteTemplateContext)

export const EncounterNoteTemplateProvider = ({ children }) => {
  const { tenant_id} = useParams()
  const { showErrorAlert } = useAlerts()
  const [encounterNoteTemplates, setEncounterNoteTemplates] = useState<EncounterNoteTemplate[]>([])

  const { loading } = useQuery(LIST_ENCOUNTER_NOTE_TEMPLATES, {
    variables: {
      tenantId: tenant_id
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Encounter note templates couldn't be retrieved.")
    },
    onCompleted: (data) => {
      const templates : EncounterNoteTemplate[] = data.tenant.encounterNoteTemplate.list
      setEncounterNoteTemplates(templates)
    },
    fetchPolicy: 'cache-first'
  })

  const getEncounterNoteTemplate = (id) => encounterNoteTemplates.find(t => t.id === id)

  const providerValues = {
    loading,
    encounterNoteTemplates,
    getEncounterNoteTemplate
  }

  return (
    <EncounterNoteTemplateContext.Provider value={providerValues}>
      { children }
    </EncounterNoteTemplateContext.Provider>
  )
}

export const useEncounterNoteTemplateContext = () => {
  return React.useContext(EncounterNoteTemplateContext)
}