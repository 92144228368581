import React from 'react'
import { MenuItem } from '@mui/material'
import { Select } from 'saga-library/src'
import { ABClaimAssessmentOutcome } from '../utils/ABClaimConstants'

const ASSESSMENT_OUTCOME_OPTIONS = [
  { value: ABClaimAssessmentOutcome.REFUSED, label: "Refused" },
  { value: ABClaimAssessmentOutcome.HELD, label: "Held" },
  { value: ABClaimAssessmentOutcome.APPLIED, label: "Applied" }
]

interface AssessmentOutcomeSelectProps {
  label: string
  name: string
  onChange?: (value) => void
  disabled?: boolean
  dataTestId: string
}

export const AssessmentOutcomeSelect = ({
  name = "Assessment outcome",
  label,
  onChange,
  disabled,
  dataTestId
}: AssessmentOutcomeSelectProps) => {
  return (
    <Select
      dataTestId={dataTestId}
      name={name}
      label={label}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
      disabled={disabled}
    >
      {ASSESSMENT_OUTCOME_OPTIONS.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`${dataTestId}-assessment-outcome-select-${index}`}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}