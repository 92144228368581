import React, { useState } from 'react'
import { Collapse } from '@mui/material'
import { LoadingButton, TextField, Typography, Form } from 'saga-library/src'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import * as yup from "yup"
import { useSagaAuthentication } from '../../providers/Auth'

export const CHANGE_EMAIL_FORM_NAME = 'change-email-form'

const ChangeEmailForm = ({dataTestId}) => {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const { beginChangeEmail, friendlyEmailErrorMessage } = useSagaAuthentication()

  const schema = yup.object().shape({
    newEmail: yup.string().email('Must be a valid email').required('New email is required.')
  }).required();

  const formMethods = useForm<FormValues>({
    schema: schema
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formMethods

  const onSubmit = async (data) => {
    beginChangeEmail(data).then((success) => {
      if (success){
        setEmailSent(true)
      }
    })
  }

  return (
    <FormProvider {...formMethods}>
      <Form
        name={CHANGE_EMAIL_FORM_NAME}
        id={`${dataTestId}-form`}
        onSubmit={() => {}}
      >
        {emailSent ?
          <Typography variant={'body1'}>
            Please check your email inbox for the change email link.
          </Typography>
          :
          <>
            <Typography variant={'body1'}>
              Enter your email address for a change email link.
            </Typography>
            <TextField
              label={'New email address'}
              sx={{ width: 280 }}
              name={'newEmail'}
              dataTestId={`${dataTestId}-change-email-newEmail`}
            />
            <Collapse in={!!friendlyEmailErrorMessage} sx={{ color: "#C41923" }}>
              {friendlyEmailErrorMessage}
            </Collapse>
            <LoadingButton
              dataTestId={`${dataTestId}-button`}
              name={'change-email'}
              type={'button'}
              onClick={handleSubmit(onSubmit)}
              sx={{ width: 200 }}
              loading={isSubmitting}
              variant={'outlined'}
            >
              Send Email
            </LoadingButton>
          </>
        }
      </Form>
    </FormProvider>
  )
}

type FormValues = {
  newEmail?: string
}

export default ChangeEmailForm