import moment from 'moment-timezone'
import React from 'react'
import { ScheduleLength } from '../../../types/schedule/Schedule'

export const getAppointmentFormattedTime = (start, end) => {
  const s = moment(start)
  const e = moment(end)
  return `${s.format('h:mm A')} - ${e.format('h:mm A')}`
}

export const getAppointmentTitle = ({ start, end, practitionerName, scheduleLength }: {
  start: Date | string
  end: Date | string
  practitionerName?: string | null
  scheduleLength?: string
}) => {
  const s = moment(start)
  let formattedDate = '';
  const formattedTime = getAppointmentFormattedTime(start, end)

  if (!scheduleLength) {
    formattedDate = s.format('dddd, MMMM D, YYYY')
  } else if (scheduleLength === ScheduleLength.WEEK) {
    formattedDate = s.format('dddd')
  }

  return practitionerName ? `${formattedDate} with ${practitionerName}` : formattedTime
}