import * as yup from "yup";
import { operatorIsBetween, operatorIsNullable } from '../ReportUtil'
import { ReportColumnType } from '../../../../types/Report'
import moment from 'moment'

const querySchema = yup.object({
  column: yup.string().nullable(),
  operator: yup
    .string()
    .when("column", {
      is: (column) => !!column,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable()
    }),
  value1: yup
    .string()
    .when("operator", {
      is: (operator) => operatorIsNullable(operator),
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema
        .test({
          name: "query_value_cannot_be_null",
          message: "Required",
          test: (value1, context) => {
            const { column } = context.parent
            if (!!column) {
              return !!value1
            }
            return true
          }
        })
    }),
  value2: yup
    .string()
    .when("operator", {
      is: (operator) => operatorIsBetween(operator),
      then: (schema) => schema
        .test({
          name: "end_number_is_greater",
          message: "End number should be greater than start number",
          test: (value2, context) => {
            const { column, value1 } = context.parent
            if (column === ReportColumnType.CALLS ||
              column === ReportColumnType.CLAIMED ||
              column === ReportColumnType.PAID ||
              column === ReportColumnType.PATIENT_AGE
            ) {
              if (!value1 || !value2) {
                return false
              }
              return +value2 >= +value1
            }
            return true
          }
        })
        .test({
          name: "end_date_is_greater",
          message: "End date should be greater than start date",
          test: (value2, context) => {
            const { column, value1 } = context.parent
            if (column === ReportColumnType.DATE ||
              column === ReportColumnType.LAB_RESULT_DATE ||
              column === ReportColumnType.PATIENT_DATE_OF_BIRTH ||
              column === ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE ||
              column === ReportColumnType.PRESCRIPTION_DATE ||
              column === ReportColumnType.SERVICE_DATE
            ) {
              if (!value1 || !value2) {
                return false
              }
              return moment(value2).isSameOrAfter(moment(value1))
            }
            return true
          }
        }),
      otherwise: (schema) => schema.nullable()
    })
    .nullable()
})

export const schema = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().notRequired(),
  category: yup.string().required("Required"),
  columns: yup.array().of(yup.object()),
  parameters: yup.array().of(yup.object()),
  queries: yup.array().of(querySchema),
  version: yup.string().required("Required")
})
