import moment from 'moment-timezone'
import React from 'react'
import { diffDates } from 'saga-library/src/util'
import Typography, { SagaTypographyVariants } from 'saga-library/src/components/Typography/Typography'

type FormattedAgeProps = {
  dateOfBirth: string | null,
  variant?: SagaTypographyVariants,
  includeSuffix?: boolean,
  dataTestId: string,
}


export const NO_DATE_OF_BIRTH = 'No date of birth'

export function FormattedAgeString(dateOfBirth: string | undefined, pluralize: boolean = true) {
  if(!dateOfBirth){
    return NO_DATE_OF_BIRTH
  }

  const now = moment().utc();
  const diffYears = diffDates(dateOfBirth, now, 'years')
  const diffMonths = diffDates(dateOfBirth, now, 'months')
  const diffWeeks = diffDates(dateOfBirth, now, 'weeks')
  const diffDays = diffDates(dateOfBirth, now, 'days')

  if(diffYears > 0 && diffMonths > 24){
    return (diffYears > 1 && pluralize) ? `${diffYears} years` : `${diffYears} year`
  }
  if (diffMonths > 0){
    return (diffMonths > 1 && pluralize) ? `${diffMonths} months` : `${diffMonths} month`
  }
  if(diffWeeks > 0){
    return (diffWeeks > 1 && pluralize) ? `${diffWeeks} weeks` : `${diffWeeks} week`
  }
  if(diffDays => 0){
    return (diffDays === 1 || !pluralize) ? `${diffDays} day` : `${diffDays} days`
  }

  return NO_DATE_OF_BIRTH
}

export const FormattedAge = ({
  dateOfBirth,
  variant = 'body1',
  includeSuffix = true,
  dataTestId = 'formattedAge',
}: FormattedAgeProps) => {
  const FormattedAgeTextDisplay = ({children}) => {
    return (
      <Typography variant={variant} dataTestId={dataTestId}>
        {children}
      </Typography>
    )
  }

  if(!dateOfBirth){
    return <FormattedAgeTextDisplay>{NO_DATE_OF_BIRTH}</FormattedAgeTextDisplay>
  }

  const ageString = FormattedAgeString(dateOfBirth)
  return <FormattedAgeTextDisplay>{includeSuffix ? `${ageString} old` : ageString}</FormattedAgeTextDisplay>
}