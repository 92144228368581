import React, { useEffect, useState } from 'react'
import { SearchField, FilterSelect, TableList } from 'saga-library/src'
import { Box } from '@mui/material'
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import { StaticPage } from '../../../components/Layouts'
import { PractitionerRow } from './components/PractitionerRow'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { LIST_PRACTITIONERS } from "../../../graphql-definitions";

const PractitionerStatusOptions = [
  { label: 'All', value: '*' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
]

export const PractitionersClinic = () => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const navigate = useNavigate()

  interface ListPractitionerQueryResultFields {
    id: string
    lastName: string
    firstName: string
    active: boolean
    practitionerId: string
  }

  const [allPractitioners, setAllPractitioners] = useState<
    ListPractitionerQueryResultFields[]
  >([])
  const [filteredPractitioners, setFilteredPractitioners] = useState<
    ListPractitionerQueryResultFields[]
  >([])
  const [status, setStatus] = useState('active')
  const [search, setSearch] = useState('')

  const { loading, error, data } = useQuery(LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Practitioner list couldn\'t be retrieved.')
    },
    onCompleted: (data) => {
      let practitioners = _get(data, 'tenant.practitioner.list', null)
      setAllPractitioners(practitioners)
    },
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    let newFilteredPractitioners: ListPractitionerQueryResultFields[] =
      allPractitioners

    if (status !== '*') {
      newFilteredPractitioners = newFilteredPractitioners.filter(
        (practitioner) => practitioner.active === (status === 'active')
      )
    }

    if (!!search) {
      newFilteredPractitioners = newFilteredPractitioners.filter(
        (practitioner) => {
          const terms = search.toLowerCase().match(/\b(\w+)\b/g)
          return (
            terms === null ||
            terms.every(
              (term) =>
                practitioner.lastName.toLowerCase().startsWith(term) ||
                practitioner.firstName.toLowerCase().startsWith(term) ||
                practitioner.practitionerId?.toLowerCase().startsWith(term)
            )
          )
        }
      )
    }

    setFilteredPractitioners(newFilteredPractitioners)
  }, [allPractitioners, status, search])

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'stretch'} alignItems={'center'}>
        <FilterSelect
          label="Practitioner status"
          options={PractitionerStatusOptions}
          onSelect={(select) => {
            setStatus(select.value)
          }}
          defaultSelectedValue="active"
          dataTestId={'practitionerClinic-status-filter'}
        />
        <Box display={'flex'} justifyContent={'flex-end'} flexGrow={1}>
          <SearchField
            name="searchField"
            sx={{ width: '344px' }}
            placeholder="Search by name or practitioner ID"
            onChange={(search) => {
              setSearch(search.target.value)
            }}
            value={search}
            dataTestId={'practitionerClinic-search-field'}
          />
        </Box>
      </Box>
      <TableList
        loading={loading}
        enableSorting={true}
        columns={[
          { name: 'Practitioner', sortable: true },
          { name: 'Practitioner ID', sortable: true },
          { name: 'Linked user account', sortable: true },
          { name: 'Status', sortable: true }
        ]}
        rows={[
          ...filteredPractitioners.map((practitioner) =>
            PractitionerRow(practitioner, navigate)
          ),
        ]}
        dataTestId={'practitionerClinic-tableList'}
        leftAlignColumns={true}
      />
      {error && (
        <StaticPage>
          Sorry, an error occurred loading the practitioner list.
        </StaticPage>
      )}
    </Box>
  )
}
