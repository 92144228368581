import React from 'react'
import formatStringByPattern from 'format-string-by-pattern'
import { TextFieldMask } from '../TextFieldMask'
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

export interface PostalCodeFieldProps {
  name: string
  label: string
  fullWidth?: boolean
  onChange?: (value) => void
  sx?: SxProps<Theme>
  disabled?: boolean
  dataTestId?: string
}

const pattern = 'A9A 9A9'
const typeLength = 6

export const postalCodeMask = (value) =>
  value ? formatStringByPattern(pattern, value.toUpperCase()) : value

export const PostalCodeField = ({
  name,
  label,
  fullWidth,
  onChange,
  sx,
  disabled=false,
  dataTestId
}: PostalCodeFieldProps) => {
  const unmask = (value) =>
    value
      ? value
          .toUpperCase()
          .replace(/[^a-z0-9]/gi, '')
          .substring(0, typeLength)
      : value

  return (
    <TextFieldMask
      name={name}
      dataTestId={dataTestId}
      formatMask={'*** ***'}
      maskChar={''}
      unmask={unmask}
      onChange={onChange}
      fullWidth={fullWidth}
      sx={sx}
      label={label}
      disabled={disabled}
    />
  )
}

export default PostalCodeField

export const PostalCodeValidation = {
  regex: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z][0-9][A-Z][0-9]/g,
}
