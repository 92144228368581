import { PermissionForm } from '../../../../components/PermissionForm'
import { PermissionType } from '../../../../types/settings/Permission'
import { TextField } from 'saga-library/src'
import { DocumentViewer } from '../../../../components/StandaloneDocumentViewer'
import React, { useState } from 'react'
import { AbLabResult, AbLabResultObservationResult, AbLabResultUpdate } from '../../../../types/patients'
import { FileType } from '../../../../types/tasks'
import { useFormContext } from 'saga-library/src/components/Form'
import { useLabAndInvestigationContext } from '../../providers/LabAndInvestigationProvider'
import { LabAndInvestigationDetails } from './components/LabAndInvestigationDetails'
import { LabValueGraph } from './LabValueGraph'
import { LabAndInvestigationFooter } from './components/LabAndInvestigationFooter'
import { usePatientDocumentStateContext } from '../../providers/PatientDocumentStateProvider'

export interface LabAndInvestigationFormProps {
  formName: string,
  labAndInvestigation: AbLabResult | null,
  file: FileType | null,
  onSuccess?: (updatedLab: AbLabResult) => void,
  dataTestId: string,
  updateLabAndInvestigationMethod?: (labAndInvestigationInput: AbLabResultUpdate, onSuccess: (result: AbLabResult) => void) => Promise<void>
  documentStateContext?: ReturnType<typeof usePatientDocumentStateContext>
}

export const LabAndInvestigationForm = ({
  formName,
  labAndInvestigation,
  file,
  onSuccess,
  dataTestId,
  updateLabAndInvestigationMethod,
  documentStateContext
}: LabAndInvestigationFormProps) => {
  const { updateLabAndInvestigation } = useLabAndInvestigationContext()

  const [observationResult, setObservationResult] = useState<AbLabResultObservationResult | null>(null)
  const { handleSubmit } = useFormContext()

  const onSubmit = handleSubmit(
    async (data) => {
      updateLabAndInvestigationMethod
        ? await updateLabAndInvestigationMethod(
          data as AbLabResultUpdate,
          (updatedLab) => {
            onSuccess?.(updatedLab)
          }
        )
        : await updateLabAndInvestigation(
          data as AbLabResultUpdate,
          (updatedLab) => {
            if (onSuccess) {
              onSuccess(updatedLab)
            }
          }
        )
    },
    (errors) => {
      console.error('errors: ', errors)
    }
  )

  const LabView = () => {
    if (file) {
      return (
        <DocumentViewer
          fileRecord={file}
          variant={'labs'}
          sx={{
            flex: '1 1 auto',
            overflow: 'hidden'
          }}
          documentStateContext={documentStateContext}
        />
      )
    }

    if (labAndInvestigation) {
      return (
        <>
          <LabAndInvestigationDetails
            lab={labAndInvestigation!}
            segments={[
              ...(labAndInvestigation?.abLabResultObservationResults ?? []),
              ...(labAndInvestigation?.abLabResultNoteAndComments ?? [])
            ]}
            onGraphClick={(result) => setObservationResult(result)}
            dataTestId={`${dataTestId}-labAndInvestigation`}
          />
          <LabAndInvestigationFooter lab={labAndInvestigation!} />
        </>
      )
    }

    return <></>
  }

  return (
    <>
      <PermissionForm
        id={formName}
        name={formName}
        onSubmit={onSubmit}
        requiredPermissionType={PermissionType.Chart}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          paddingRight: '8px',
          gap: '16px',
          overflow: 'auto'
        }}
      >
        <TextField label={'Notes'} name={'notes'} dataTestId={`${dataTestId}-notes`} sx={{ height: '48px' }} />
        {LabView()}
      </PermissionForm>
      <LabValueGraph
        result={observationResult}
        setResult={setObservationResult}
        dataTestId={`${dataTestId}-labValueGraph`}
      />
    </>
  )
}