import { ABClaimActionCode } from '../../../utils/ABClaimConstants'


export const getActionCodeDescription = (actionCode) => {
  let description = 'RESUBMITTED'
  switch (actionCode) {
    case ABClaimActionCode.REASSESS:
      description = description.concat(' FOR REASSESSMENT')
      break
    case ABClaimActionCode.CHANGE:
      description = description.concat(' AS CHANGED CLAIM')
      break
    case ABClaimActionCode.DELETE:
      description = description.concat(' FOR DELETION')
      break
    case ABClaimActionCode.ADD:
      description = description.concat(' AS NEW CLAIM')
  }
  return description
}