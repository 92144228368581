import { MbscCalendarEvent } from '@mobiscroll/react'
import { useParams } from 'react-router-dom'
import { useTenantContext } from '../../../providers/TenantContextProvider'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_SCHEDULE_ITEMS,
  SCHEDULE_ITEMS_ON_CHANGED,
  UPDATE_APPOINTMENT,
  UPDATE_EVENT
} from '../../../graphql-definitions'
import {
  checkExistingBookingPreference,
  convertTemplateItemTimes,
  getScheduleViewDates, getTemplateDayHeaderEvents,
  matchGetScheduleItemsStructure,
  updateClosedHours, validateEvent
} from './scheduleFunctions'
import moment from 'moment-timezone'
import _get from 'lodash/get'
import { Dictionary, findIndex } from 'lodash'
import _cloneDeep from 'lodash/cloneDeep'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import {
  ScheduleDialogActions,
  TempEventHandlerType
} from '../../../utils/ScheduleProviderUtils'
import {
  ScheduleDataInterface,
  ScheduleLayoutInterface,
  ScheduleViewInterface
} from '../../../types/schedule/Schedule'
import { AppointmentCheckDialogOpenProps } from '../components/AppointmentCheckDialog'
import { EventDialogOpenProps } from '../components/EventDialogHandler'

export interface scheduleItemsInterface {
  bookingPreferences: any[],
  events: MbscCalendarEvent[],
  closedHours: MbscCalendarEvent[]
}

export interface UseScheduleItemActionsProps {
  loading: boolean,
  scheduleItems: scheduleItemsInterface,
  createEvent: (eventData: MbscCalendarEvent) => Promise<void>,
  updateEvent: (eventData: MbscCalendarEvent) => Promise<void>,
  refetchScheduleItems: () => void
}

const defaultScheduleItems: scheduleItemsInterface = {
  bookingPreferences: [],
  events: [],
  closedHours: []
}

export const defaultUseScheduleItemActionsProps: UseScheduleItemActionsProps = {
  loading: false,
  scheduleItems: defaultScheduleItems,
  createEvent: (eventData: MbscCalendarEvent) => Promise.resolve(),
  updateEvent: (eventData: MbscCalendarEvent) => Promise.resolve(),
  refetchScheduleItems: () => {
  }
}

interface ScheduleItemActionsProps {
  scheduleView: ScheduleViewInterface,
  scheduleData: ScheduleLayoutInterface,
  tempEventActions: TempEventHandlerType,
  setOpenConfirmationDialog: (open: AppointmentCheckDialogOpenProps | null) => void,
  setOpenEventDialog: (open: EventDialogOpenProps | null) => void,
  getScheduleData: (scheduleIds: string) => ScheduleDataInterface | null,
  handleShuttledAppt: (event: any) => Promise<any>
}

const SCHEDULE_UNMOUNT_EVENT = 'scheduleComponentUnmount'

export const useScheduleItemActions = ({
    scheduleView,
    scheduleData,
    tempEventActions,
    setOpenConfirmationDialog,
    setOpenEventDialog,
    getScheduleData,
    handleShuttledAppt
  }: ScheduleItemActionsProps
): UseScheduleItemActionsProps => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showWarningAlert } = useAlerts()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const [subscriptionDictionary, setSubscriptionDictionary] = useState<Dictionary<Function>>({})
  const [scheduleItems, setScheduleItems] = useState<scheduleItemsInterface>(defaultScheduleItems)

  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const {
    loading: loadingSchedules,
    data: scheduleItemData,
    subscribeToMore: subscribeToMoreScheduleItems,
    refetch: refetchScheduleItems
  } = useQuery(GET_SCHEDULE_ITEMS, {
    variables: {
      tenantId: tenant_id,
      scheduleIdList: scheduleView?.schedules,
      start: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).start).utc().toISOString(),
      end: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).end).add(1, 'day').utc().toISOString()
    },
    fetchPolicy: 'cache-and-network',
    skip: !scheduleView || scheduleView?.schedules.length === 0 || !scheduleView?.date || !scheduleView?.view
  })

  const [
    runUpdateEvent
  ] = useMutation(UPDATE_EVENT)

  const [
    runUpdateAppointment
  ] = useMutation(UPDATE_APPOINTMENT)

  useEffect(() => {
    //the unmount action needs to be decoupled from the changes to the subscriptionDictionary
    return () => {
      let e = new Event(SCHEDULE_UNMOUNT_EVENT)
      document.dispatchEvent(e)
    }
  }, [])

  const updateScheduleItems = useCallback((queryData) => {
    const scheduleDateRange = getScheduleViewDates(scheduleView.date, scheduleView.view)

    const allScheduleItems = _get(queryData, 'tenant.schedule.scheduleItems', []).map(scheduleItem => scheduleItem.value).flat()
    const templateBookingPreferences = convertTemplateItemTimes(allScheduleItems.filter(si => si.__typename === 'TemplateBookingPreference'))

    const scheduleTemplateDays = _get(queryData, 'tenant.schedule.scheduleTemplateDays', [])

    const templateDayHeaderEvents = getTemplateDayHeaderEvents(scheduleTemplateDays)

    let closedHours = scheduleData.defaultClosedHours
    if (scheduleTemplateDays.length > 0) {
      closedHours = updateClosedHours(scheduleData.defaultClosedHours, scheduleDateRange.end, scheduleTemplateDays, timezone)
    }
    const bookingPreferences = allScheduleItems.filter((event) => {
      return event.__typename === 'BookingPreference'
    })

    const events = allScheduleItems.filter((event) => event.__typename === 'ScheduleEvent' || event.__typename === 'Appointment')

    setScheduleItems({
      bookingPreferences: [...bookingPreferences, ...templateBookingPreferences],
      events: [...events, ...scheduleData.scheduleEndEvents, ...templateDayHeaderEvents],
      closedHours: closedHours
    })
  }, [scheduleData?.defaultClosedHours, scheduleData?.scheduleEndEvents, scheduleView?.date, scheduleView?.view, timezone])

  useEffect(() => {
    if (scheduleItemData) {
      updateScheduleItems(scheduleItemData)
    }
  }, [scheduleItemData, updateScheduleItems])

  useEffect(() => {
    setScheduleItems(defaultScheduleItems)
  }, [scheduleView?.schedules])

  useEffect(() => {
    if (scheduleView) {
      refetchScheduleItems()
    }
  }, [scheduleView])

  useEffect(() => {
    function doOnUnmount() {
      if (subscriptionDictionary) {
        for (const [key] of Object.entries(subscriptionDictionary)) {
          subscriptionDictionary[key]()
        }
      }
    }

    document.addEventListener(SCHEDULE_UNMOUNT_EVENT, doOnUnmount)
    return () => {
      document.removeEventListener(SCHEDULE_UNMOUNT_EVENT, doOnUnmount)
    }
  }, [subscriptionDictionary])

  useEffect(() => {
    if (!scheduleView || !scheduleView.schedules) {
      return
    }
    let currentSubscriptions = { ...subscriptionDictionary }
    //console.debug('removeUnnecessaryScheduleSubscriptions subscriptionDictionary.length', Object.keys(currentSubscriptions).length)
    if (currentSubscriptions) {
      for (const [key] of Object.entries(currentSubscriptions)) {
        if (key.includes('scheduleItemsOnChanged')) {
          if (!scheduleView.schedules.includes(key.split('_')[2])) {
            //console.debug(`unsubscribing (scheduleItemsOnChanged) ${key}`)
            currentSubscriptions[key]()
            delete currentSubscriptions[key]
          }
        }
      }
    }

    scheduleView.schedules.map((id) => {
      if (currentSubscriptions && currentSubscriptions[`${tenant_id}_scheduleItemsOnChanged_${id}`]) {
        return id
      } else {
        console.debug(`subscribing ${tenant_id}_scheduleItemsOnChanged_${id}`)
        const stopSubscription = subscribeToMoreScheduleItems({
          document: SCHEDULE_ITEMS_ON_CHANGED,
          variables: { tenantId: tenant_id, scheduleId: id },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            if (!prev.tenant.schedule.scheduleItems) return prev

            prev = matchGetScheduleItemsStructure(prev)
            const updatedScheduleItem = subscriptionData.data.scheduleItemsOnChanged.scheduleItem
            console.debug(`${tenant_id}_scheduleItemsOnChanged_${id}`, subscriptionData, 'prev', prev)

            if (subscriptionData.data.scheduleItemsOnChanged.eventType === 'CREATED') {
              if (!!prev.tenant.schedule.scheduleItems && prev.tenant.schedule.scheduleItems.length > 0) {
                const updatedScheduleIndex = prev.tenant.schedule.scheduleItems.findIndex((scheduleItem) => scheduleItem.key === updatedScheduleItem.resource)
                if (findIndex(prev.tenant.schedule.scheduleItems[updatedScheduleIndex].value, {
                  id: subscriptionData.data.scheduleItemsOnChanged.scheduleItem.id,
                  __typename: subscriptionData.data.scheduleItemsOnChanged.scheduleItem.__typename
                }) >= 0) {
                  return prev
                }
              }

              var deep = _cloneDeep(prev)
              if (deep.tenant.schedule.scheduleItems.length === 0) {
                deep.tenant.schedule.scheduleItems = [Object.assign({}, {
                  __typename: 'KeyValuePairOfObjectIdAndListOfIScheduleItem',
                  key: id,
                  value: []
                })]
              }
              deep.tenant.schedule.scheduleItems[0].value = [subscriptionData.data.scheduleItemsOnChanged.scheduleItem, ...deep.tenant.schedule.scheduleItems[0].value]
              return prev

            } else if (subscriptionData.data.scheduleItemsOnChanged.eventType === 'DELETED') {
              if (!prev.tenant.schedule.scheduleItems || prev.tenant.schedule.scheduleItems.length === 0) {
                return
              }
              const updatedScheduleIndex = prev.tenant.schedule.scheduleItems.findIndex((scheduleItem) => scheduleItem.key === updatedScheduleItem.resource)
              if (updatedScheduleIndex === -1) {
                console.error('Error: schedule not found')
                return prev
              }

              var index = findIndex(prev.tenant.schedule.scheduleItems[updatedScheduleIndex].value, {
                id: subscriptionData.data.scheduleItemsOnChanged.scheduleItem.id,
                __typename: subscriptionData.data.scheduleItemsOnChanged.scheduleItem.__typename
              })
              if (index === -1) {
                console.log('Event already removed')
                return
              }


              var deepDelete = _cloneDeep(prev)
              deepDelete.tenant.schedule.scheduleItems[updatedScheduleIndex].value.splice(index, 1)
              console.log('Deleted event', deepDelete)
              return deepDelete
            }

            return prev
          }
        })
        currentSubscriptions = ({
          ...currentSubscriptions,
          [`${tenant_id}_scheduleItemsOnChanged_${id}`]: stopSubscription
        })
      }
      return id
    })

    setSubscriptionDictionary(currentSubscriptions)
  }, [scheduleView?.schedules])

  const updateEvent = async (eventData: MbscCalendarEvent) => {
    if (eventData.__typename === 'ScheduleEvent') {
      updateScheduleEvent(eventData)
    } else if (eventData.__typename === 'Appointment') {
      updateScheduleAppointment(eventData)
    }
  }

  const createEvent = useCallback(async (event) => {
    if (event.action === 'externalDrop') {
      if (event.event.shuttleBay) {
        if (scheduleView?.schedules.length === 1) {
          event.event.resource = scheduleView?.schedules[0]
        }
      } else {
        showWarningAlert('Appointments in a room can\'t be placed onto the schedule. Select leave room button instead.')
        tempEventActions.clearTempEvent()
        return
      }
    }

    const bookingPreferences = checkExistingBookingPreference(event.event, scheduleItems.bookingPreferences)

    let bookingPreferenceTypes = null
    if (bookingPreferences && bookingPreferences.length > 0) {
      bookingPreferenceTypes = bookingPreferences.map((bp) => bp.appointmentTypes.map(at => at.id)).flat()
    }

    const eventDate = moment(event.event.start, moment.ISO_8601).format('YYYY-MM-DD')
    const events: any[] = scheduleItems.events.filter(si => (si.resource && event.event.resource ? si.resource === event.event.resource : true) && si.__typename === 'ScheduleEvent' && moment(si.start).format('YYYY-MM-DD') === eventDate)
    const scheduleId: string = scheduleView?.schedules?.length === 1 ? scheduleView?.schedules[0] : event.event.resource as string
    const appointmentTypes = getScheduleData(scheduleId)?.appointmentTypes

    const checkResults = await validateEvent(event.event, events, scheduleItems.closedHours, appointmentTypes)

    if (checkResults.length) {
      setOpenConfirmationDialog({
        checks: checkResults as string[],
        eventData: event.event,
        appointmentMove: event.action !== 'click'
      })
      return
    }

    if (event.event.shuttleBay && event.event.id !== 'new_event') {
      updateEvent(event.event)
      return
    }

    setOpenEventDialog({
      action: ScheduleDialogActions.CREATE,
      eventData: {
        ...event.event,
        bookingPreferenceTypeIds: bookingPreferenceTypes
      }
    })
  }, [setOpenConfirmationDialog, setOpenEventDialog, tempEventActions, getScheduleData, scheduleItems, scheduleView?.schedules, showWarningAlert, handleShuttledAppt])

  const updateScheduleEvent = useCallback(async (eventData) => {
    const eventDate = moment(eventData?.start).format('YYYY-MM-DD')

    const eventInput = {
      allDay: eventData.allDay,
      title: eventData.title,
      version: eventData.version,
      itemDate: eventDate,
      start: eventData.start,
      end: eventData.end,
      scheduleId: eventData.resource
    }

    const optimisticResponse = {
      tenant: {
        schedule: {
          updateEvent: {
            id: eventData.id,
            title: eventData.title,
            start: eventData.start,
            end: eventData.end,
            allDay: eventData.allDay || false,
            version: eventData.version,
            itemDate: eventDate,
            scheduleId: eventData.resource,
            blockType: 'EVENT',
            resource: eventData.resource,
            __typename: 'ScheduleEvent'
          },
          __typename: 'ScheduleMutations'
        },
        __typename: 'TenantMutations'
      }
    }

    await runUpdateEvent({
      variables: {
        tenantId: tenant_id,
        id: eventData.id,
        input: eventInput
      },
      optimisticResponse: () => optimisticResponse,
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Event couldn\'t be updated.')
      },
      onCompleted: (data) => {
        tempEventActions.clearTempEvent()
      }
    })
  }, [tempEventActions.clearTempEvent, runUpdateEvent, showErrorAlert])

  const updateScheduleAppointment = useCallback(async (eventData) => {
    const appointmentData = {
      appointmentStateId: eventData.state?.id,
      appointmentTypeId: eventData.type?.id,
      end: eventData.end,
      itemDate: moment(eventData.end).format('YYYY-MM-DD'),
      method: eventData.method,
      notes: eventData.notes,
      patientId: eventData.patient?.id,
      reasonForVisit: eventData.reasonForVisit,
      scheduleId: eventData.resource,
      start: eventData.start,
      version: eventData.version
    }

    const eventDate = moment(eventData.start, moment.ISO_8601).format('YYYY-MM-DD')
    const events: any[] = scheduleItems.events.filter(si => (si.resource && eventData.resource ? si.resource === eventData.resource : true) && si.__typename === 'ScheduleEvent' && moment(si.start).format('YYYY-MM-DD') === eventDate)
    const scheduleId: string = scheduleView?.schedules?.length === 1 ? scheduleView?.schedules[0] : eventData.resource as string
    const appointmentTypes = getScheduleData(scheduleId)?.appointmentTypes

    if (!eventData.skipChecks) {
      const checkResults = await validateEvent(eventData, events, scheduleItems.closedHours, appointmentTypes)

      if (checkResults.length) {
        setOpenConfirmationDialog({
          checks: checkResults as string[],
          eventData: eventData,
          appointmentMove: eventData.action !== 'click'
        })
        return
      }
    }

    await handleShuttledAppt(eventData)

    const scheduleData = getScheduleData(appointmentData.scheduleId)

    const optimisticResponse = {
      tenant: {
        schedule: {
          appointment: {
            update: {
              id: eventData.id,
              resource: eventData.resource,
              notes: appointmentData.notes,
              reasonForVisit: appointmentData.reasonForVisit,
              method: appointmentData.method,
              itemDate: appointmentData.itemDate,
              start: appointmentData.start,
              end: appointmentData.end,
              allDay: false,
              type: eventData.type,
              state: eventData.state,
              patient: eventData.patient,
              location: {
                id: scheduleData?.locationId,
                __typename: 'Location'
              },
              length: moment(appointmentData.start).diff(moment(appointmentData.end), 'minutes'),
              practitioner: {
                id: scheduleData?.practitionerId,
                __typename: 'Practitioner'
              },
              version: '0',
              __typename: 'Appointment'
            },
            __typename: 'AppointmentMutations'
          },
          __typename: 'ScheduleMutations'
        },
        __typename: 'TenantMutations'
      }
    }

    await runUpdateAppointment({
      variables: {
        tenantId: tenant_id,
        appointmentInput: appointmentData,
        appointmentId: eventData.id
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Appointment couldn\'t be updated.')
      },
      optimisticResponse: optimisticResponse,
      onCompleted: (data) => {
        tempEventActions.clearTempEvent()
        const updatedData = _get(data, 'tenant.schedule.appointment.update', {})

        if (!scheduleItems.events.some((e) => e.id === eventData.id)) {
          setScheduleItems({
            ...scheduleItems,
            events: [...scheduleItems.events, updatedData]
          })
        }
      }
    })
  }, [tempEventActions.clearTempEvent, runUpdateAppointment, showErrorAlert, setOpenConfirmationDialog, getScheduleData])

  return {
    loading: loadingSchedules,
    scheduleItems,
    refetchScheduleItems,
    createEvent,
    updateEvent
  }
}
