import React from 'react'
import {
  NavigationTabs,
} from 'saga-library/src'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { NavigationTabRoute } from '../../components/NavigationTabRoute'
import SettingsHeader from "./components/SettingsHeader";
import { Permission, PermissionType } from "../../types/settings/Permission";
import PermissionButton from "../../components/PermissionButton";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";

export const Practitioners = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navRoutes = [
    <NavigationTabRoute
      route={''}
      label={'CLINIC'}
      dataTestId={"practitioners-clinic"} />,
    <NavigationTabRoute
      route={'referral'}
      label={'REFERRAL'}
      dataTestId={"practitioners-referral"} />,
  ]

  const NewPractitionerButton = () => {
    const currentPath = useLocation().pathname
    let route
    currentPath.includes('referral')
      ? (route = 'referral/new')
      : (route = 'new')

    return <PermissionButton
      name="newClinicPractitioner"
      onClick={() => navigate(route)}
      requiredType={PermissionType.Practitioner}
      requiredPermission={Permission.READWRITE}
      dataTestId={'practitioners-newClinicPractitioner-button'}
    >
      {location.pathname.endsWith('referral')
        ? 'new referral practitioner'
        : 'new clinic practitioner'}
    </PermissionButton>
  }

  return (
    <SettingsSectionColumn
      header={
        <SettingsHeader
          title={'Practitioners'}
          actions={<NewPractitionerButton />}
          dataTestId={'practitioners'}
        />
    }>
      <NavigationTabs routes={navRoutes}
        sx={{
          mb: 2,
        }}
      />
      <Outlet />
    </SettingsSectionColumn>
  )
}
