import React from "react";
import { Box, InputAdornment } from "@mui/material";
import { Checkbox, ColorPicker, TextField, Form } from "saga-library/src";
import { useFormContext } from "saga-library/src/components/Form";
import { ControlledScheduleAutocompleteSelect } from "../../../../components/ScheduleAutocompleteSelect";
import { ColorPickerType } from "saga-library/src/components/ColorPicker/ColorPicker";
import { SettingsFormSection } from "../../components/SettingsFormSection";


export const AppointmentTypeForm = ({
  onSubmit,
}) => {
  const { formState: {errors} } = useFormContext()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%'
      }}
    >
      <Form onSubmit={onSubmit}>
        <SettingsFormSection>
          <TextField
            name={'name'}
            label={'Name'}
            dataTestId={"appointmentTypeForm-name"}
          />
          <TextField
            name={'duration'}
            label={'Length'}
            type={'number'}
            dataTestId={"appointmentTypeForm-duration"}
            InputProps={{
              endAdornment: <InputAdornment position={'end'}>minutes</InputAdornment>
            }}
          />
        </SettingsFormSection>
        <SettingsFormSection label={'Colour'}>
          <ColorPicker name={'color'} type={ColorPickerType.APPOINTMENT_TYPE} />
        </SettingsFormSection>
        <SettingsFormSection label={'Appointments can take place...'}>
          <Checkbox
            name={'inPerson'}
            label={'In person'}
            sx={{ ml: 1 }}
            dataTestId={"appointmentTypeForm-inPerson-checkbox"}
          />
          <Checkbox
            name={'byPhone'}
            label={'By phone'}
            sx={{ ml: 1 }}
            dataTestId={"appointmentTypeForm-byPhone-checkbox"}
          />
          <Checkbox
            name={'videoCall'}
            label={'By video call'}
            sx={{ ml: 1 }}
            dataTestId={"appointmentTypeForm-videoCall-checkbox"}
            errors={errors?.appointmentMethod?.message}
          />
        </SettingsFormSection>
        <SettingsFormSection label={'Appointments on the following schedules'} sx={{ mb: 0 }}>
          <ControlledScheduleAutocompleteSelect
            name={"schedules"}
            disabled={false}
          />
        </SettingsFormSection>
      </Form>
    </Box>
  )
}