import React, { useEffect, useState } from 'react'
import { CheckboxGroup, DialogV2 } from 'saga-library/src'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { REPORT_PARAMETERS, ReportCategoryType, ReportParameterInput } from '../../../../types/Report'
import { REPORT_PARAMETER_GROUPS } from '../ReportUtil'

const FORM_NAME = "report_form_parameters"

type ReportFormParametersModalType = {
  open: boolean
  category: ReportCategoryType,
  parameters?: ReportParameterInput[]
  onChange: (items: ReportParameterInput[]) => void
  onClose: () => void
}

export const ReportFormParametersModal = ({ open, category, parameters = [], onChange, onClose }: ReportFormParametersModalType) => {
  const [ openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<{ parameters: ReportParameterInput[] }>({
    defaultValues: { parameters }
  })

  const {
    formState: { dirtyFields },
    handleSubmit,
    reset
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt])

  useEffect(() => {
    if (open) {
      reset({ parameters })
    }
  }, [open])

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  const onSubmit = handleSubmit(({ parameters: updatedParameters }) => {
    onChange(updatedParameters)
    enableNavigationPrompt(false, FORM_NAME)
    onClose()
  })

  return (
    <DialogV2
      size={"xs"}
      title={"Select report parameters"}
      open={open}
      onClose={onCancel}
      primaryAction={onSubmit}
      primaryLabel={"Ok"}
      contentSx={{ display: "flex", flexDirection: "column", gap: "8px" }}
      dataTestId={"report-parameters"}
    >
      <FormProvider {...formMethods}>
        {REPORT_PARAMETER_GROUPS
          .filter((group) => group.category === category)
          .map((group) =>
            <CheckboxGroup
              key={group.label}
              name={"parameters"}
              label={group.label}
              items={group.parameters.map((parameter) => ({ name: parameter, label: REPORT_PARAMETERS[parameter] }))}
              dataTestId={"report-parameter-groups"}
            />
          )}
      </FormProvider>
    </DialogV2>
  )
}