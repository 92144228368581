import React from "react";
import { Box } from "@mui/material";
import { Typography } from "saga-library/src";
import { TimelineEventType } from "../../../../../types/patients/Timeline";
import TextSeparator from "./TextSeparator";

const TimelineEventName = ({ event }: { event: TimelineEventType }) => {
  const { type, practitionerName } = event

  if (type !== "LETTER" && !!practitionerName) {
    return (
      <Typography color={"inherit"}>
        <Box component={"span"} sx={{ fontWeight: "700" }}>{event.name}</Box>
        <Box component={"span"}> with </Box>
        <Box component={"span"} sx={{ fontWeight: "700" }}>{practitionerName}</Box>
      </Typography>
    )
  }

  return (
    <Typography color={"inherit"} sx={{ fontWeight: "700" }}>
      {event.name}
    </Typography>
  )
}

export default TimelineEventName