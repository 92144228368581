import { getDocumentViewerStorageKey } from "../config/LocalStorageKeys";
import { useAccountContext } from "./AccountContext";
import { useEffect, useState } from "react";


const defaultSettings = {
  zoom: "fitWidth" as "fitWidth" | "fitHeight" | number,
  sidebarWidth: 240
}

export interface DocumentViewerSettingsInterface {
  zoom: number | "fitWidth" | "fitHeight",
  sidebarWidth: number,
  set: (settings: {
    zoom: number | "fitWidth" | "fitHeight"
    sidebarWidth: number
  }) => void
}

export const defaultDocumentViewerSetting = {
  ...defaultSettings,
  set: (settings) => {}
}

export const useDocumentViewerSetting = () => {
  const { userId } = useAccountContext()

  const [settings, setSettings] = useState(defaultSettings)
  useEffect(() => {
    let storedView = localStorage.getItem(getDocumentViewerStorageKey(userId))
    if(storedView) {
      setSettings(JSON.parse(storedView))
    }
}, []);

  const set = (settings) => {
    setSettings(settings)
    localStorage.setItem(getDocumentViewerStorageKey(userId), JSON.stringify(settings))
  }

  return {
    zoom: settings.zoom,
    sidebarWidth: settings.sidebarWidth,
    set
  }
}

