export const calculateCheckDigitAlbertaPHN = (sequence: string): string => {
  let total = 0
  Array.from(sequence).forEach((digit, index) => {
    let value = +digit
    if (index % 2 === 1) {
      value *= 2
    }
    total += (value % 10) + Math.floor(value / 10)
  })
  total %= 10
  if (total === 0) {
    total = 10
  }
  return String(10 - total)
}

export const validateAlbertaPHN = (phn: string): boolean => {
  if (phn.length !== 9) {
    return false
  }
  const checkDigit = phn.charAt(4)
  const sequence = phn.substring(0, 4) + phn.substring(5)
  const calculatedCheckDigit = calculateCheckDigitAlbertaPHN(sequence)
  return checkDigit === calculatedCheckDigit
}

export const AlbertaPHNFormatMask = '99999-9999'
