import React from 'react'
import Box from '@mui/material/Box'
import { Typography, TenantSelectionItem } from 'saga-library/src'
import { useAccountContext } from '../providers/AccountContext'
import { useNavigate } from 'react-router-dom'
import { LoadingScreen } from '../components/LoadingScreen'
import { getTenantStorageKey } from "../config/LocalStorageKeys";
import { Logo } from "../components/Logo";
import { Paper } from "@mui/material";

export const Home = () => {
  const { tenants, buildTenantRoute, userHasTenant, userId } = useAccountContext()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (tenants.length === 1) {
      navigate(buildTenantRoute('', tenants[0].id))
    }
    else {
      const key = getTenantStorageKey(userId)
      const storedTenantId = localStorage.getItem(key)
      if(storedTenantId) {
        if(userHasTenant(storedTenantId)) {
          navigate(buildTenantRoute('', storedTenantId))
        }
        else {
          localStorage.removeItem(key)
        }
      }
    }
  }, [tenants])

  if (tenants.length === 0) {
    return (
      <Box padding={4}>
        <Typography variant={'h2'}>Saga Health</Typography>
        <Typography variant={'body1'}>
          Your account is not associated to an active Saga Health subscription.
        </Typography>
      </Box>
    )
  }

  if (tenants.length > 1) {
    return (
      <Paper sx={{
        height: 'fit-content',
        margin: '0 auto',
        marginTop: 12,
        boxShadow: '4px 4px 25px 3px rgba(0,0,0,0.25)'
      }}>
        <Box padding={4}>
          <div>
            <Typography variant={'h2'} style={{marginBottom: '16px'}}>
              Select a clinic
            </Typography>
            <Typography variant={'body1'}>
              You have access to the following clinics:
            </Typography>
            <Box maxWidth={'400px'}>
              {tenants.map((tenant, index) => {
                return (
                  <Box key={index} margin={2}>
                    <TenantSelectionItem
                      isActive={false}
                      id={tenant.id}
                      tenant={tenant}
                      onClick={() => {
                        localStorage.setItem(getTenantStorageKey(userId), tenant.id!)
                        navigate(buildTenantRoute('', tenant.id))
                      }}
                      dataTestId={`tenantSelection-item-${index}`}
                    />
                  </Box>
                )
              })}
            </Box>
          </div>
        </Box>
      </Paper>
    )
  }

  return <LoadingScreen message={'One moment'} />
}
