import React from "react";
import { AppointmentSlotType } from "../types/schedule/Appointment";
import { ScheduleEventType } from '../types/schedule/ScheduleEvent'

export enum ScheduleDialogActions{
  CREATE="CREATE",
  EDIT="EDIT",
}
export enum ConfirmationDialogEnum{
  INVALID = "INVALID",
}

export const useAppointmentSlot = (goto:(appointmentSlot: AppointmentSlotType)=>void):
  {
    slotInfo: AppointmentSlotType | null,
    gotoSlot: (appointmentSlot: AppointmentSlotType)=>void,
    clearSlot:()=>void
  } => {
  const [appointmentSlot, setAppointmentSlot] = React.useState<AppointmentSlotType | null>(null)

  const gotoSlot = (appointmentSlot: AppointmentSlotType) => {
    setAppointmentSlot(appointmentSlot)
    goto(appointmentSlot)
  }

  const clearSlot = () => setAppointmentSlot(null)

  return {slotInfo:appointmentSlot, gotoSlot, clearSlot}
}

export interface ShuttleBayType{
  setOccupied: (any)=>void,
  setUnoccupied: ()=>void,
  appointment:ScheduleEventType | null
}

export const shuttleBayDefaults = {
  setOccupied: (event)=>null,
  setUnoccupied:()=>null,
  appointment: null
}

export const useShuttleBay = ():ShuttleBayType => {
  const  [isOccupied, setIsOccupied] = React.useState<any | null>(null)

  const clear = () => setIsOccupied(null)
  const set = (event:any) => {
    console.log(event)
    setIsOccupied({
      ...event,
      shuttleBay: true
    })
  }

  return {appointment: isOccupied, setOccupied: set, setUnoccupied: clear,}
}

export enum TempEventActionType {
  DRAG = "DRAG",
  QUICKBOOK = "QUICKBOOK",
}
export interface TempEventType{
  event: any,
  action: string|null
}

export interface TempEventHandlerType {
  tempEvent: TempEventType|null
  setDragEvent: (tempEvent: any)=>void
  setQuickBookEvent: (tempEvent: any)=>void
  clearTempEvent: ()=>void
}

export const tempEventDefaults = {
  tempEvent: null,
  setDragEvent: (tempEvent)=>null,
  setQuickBookEvent: (tempEvent)=>null,
  clearTempEvent: ()=>null
}

export const useTempEvent = () => {
  const [tempEvent, setTempEvent] = React.useState<TempEventType | null>(null)

  const clear = () => {
    setTempEvent(null)
  }

  return {
    tempEvent,
    setDragEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.DRAG }),
    setQuickBookEvent: (tempEvent: TempEventType) => setTempEvent({ event: tempEvent, action: TempEventActionType.QUICKBOOK }),
    clearTempEvent: clear
  }
}
