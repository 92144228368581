import { gql } from "@apollo/client";

export const GET_TENANT_ROLES = gql`
  query GetTenantRoles($tenantId: ID!) {
    tenant(id: $tenantId) {
      role {
        tenantRoles {
          id
          name
          description
          permBilling
          permSchedule
          permPractitioner
          permChart
          permReportingBilling
          permReportingPatientAndAppointment
          permLabsReview
          isAdmin
          isOwner
        }
      }
    }
  }
`

export const GET_TENANT_ROLE = gql`
  query GetTenantRole($tenantId: ID!, $roleId: ID!) {
    tenant(id: $tenantId) {
      role {
        tenantRoleById(id: $roleId) {
          id
          name
          description
          permBilling
          permSchedule
          permPractitioner
          permChart
          permReportingBilling
          permReportingPatientAndAppointment
          permLabsReview
          isAdmin
          roleUsers {
            id
            firstName
            lastName
            emailAddress
          }
          version
        }
      }
    }
  }
`

export const CREATE_TENANT_ROLE = gql`
  mutation CreateTenantRole($tenantId: ID!, $newRole: RoleInput!) {
    tenant(id: $tenantId) {
      role {
        createRole(newRole: $newRole) {
          id
          name
          description
          permBilling
          permSchedule
          permPractitioner
          permChart
          permReportingBilling
          permReportingPatientAndAppointment
          permLabsReview
          isAdmin
          isOwner
          roleUsers {
            id
          }
          version
        }
      }
    }
  }
`

export const UPDATE_TENANT_ROLE = gql`
  mutation UpdateTenantRole(
    $tenantId: ID!
    $roleId: ID!
    $updatedRole: RoleInput!
  ) {
    tenant(id: $tenantId) {
      role {
        updateRole(id: $roleId, updatedRole: $updatedRole) {
          id
          name
          description
          permBilling
          permSchedule
          permPractitioner
          permChart
          permReportingBilling
          permReportingPatientAndAppointment
          permLabsReview
          isAdmin
          roleUsers {
            id
          }
          version
        }
      }
    }
  }
`

export const DELETE_TENANT_ROLE = gql`
  mutation DeleteRole($tenantId: ID!, $roleId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      role {
        deleteRole(id: $roleId, version: $version)
      }
    }
  }
`
