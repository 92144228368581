import React from "react";
import { NavLink } from "react-router-dom";
import { SubsectionMenu, SubsectionMenuItem } from "saga-library/src";

export const TaskSettingsMenu = () => {
  return (
    <SubsectionMenu
      routes={[
        <TaskSettingsRoute
          route={'types'}
          text={'Task types'}
        />,

        <TaskSettingsRoute
          route={'states'}
          text={'Task states'}
        />,

        <TaskSettingsRoute
          route={'templates'}
          text={'Task templates'}
        />
      ]}
    />
  )
}

const TaskSettingsRoute = ({ route, text }) => {
  return (
    <NavLink to={route}>
      {({ isActive }) => (
        <SubsectionMenuItem
          dataTestId={`tenantSettingsMenu-menuItem-${text}`}
          isActive={isActive}
          primaryText={text}
        />
      )}
    </NavLink>
  )
}

export default TaskSettingsMenu