import React, { useEffect, useState } from "react";
import {
  Box,
} from '@mui/material'
import { Checkbox, Typography, DialogV2, Form } from "saga-library/src";
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { GET_TENANT_IDENTIFIERS, UPDATE_IDENTIFIER } from '../../../graphql-definitions'
import * as yup from 'yup'
import { usePrompt } from "../../../providers/NavigationPrompt";

const FORM_NAME = 'edit_identifier_form'

interface UpdateIdentifierModalProps {
  onClose: () => void
  identifier: any
}

export const EditIdentifierModal = ({
  onClose,
  identifier,
}: UpdateIdentifierModalProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState(false)

  const [updateIdentifier] = useMutation(UPDATE_IDENTIFIER, {
    onCompleted: (data) => {
      showSuccessAlert('Identifier has been saved.')
      onClose()
    },
    onError: (error) => {
      console.error(error)
      showErrorAlert('Identifier couldn\'t be updated.')
    },
    refetchQueries: [
      {
        query: GET_TENANT_IDENTIFIERS,
        variables: { tenantId: tenant_id },
      },
    ],
  })

  type FieldValuesType = {
    alwaysDisplayField: boolean
  }
  const formMethods = useForm<FieldValuesType>({
    schema: yup.object({alwaysDisplayField: yup.boolean()}).required()
  })
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting }
  } = formMethods

  const handleClose = () => {
    if(!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    await updateIdentifier({
      variables: {
        tenantId: tenant_id,
        identifierId: identifier.id,
        alwaysDisplayField: data.alwaysDisplayField,
        version: identifier.version
      },
    })
  })

  useEffect(() => {
    reset(identifier)
  }, [identifier])

  return (
    <DialogV2
      title={`Edit Identifier (${identifier.label})`}
      onClose={handleClose}
      size={'sm'}
      primaryAction={onSubmit}
      formName={FORM_NAME}
      submitting={isSubmitting}
      secondaryLabel={'Close'}
      open={!!identifier}
    >
      <FormProvider {...formMethods}>
        <Form id={FORM_NAME} onSubmit={onSubmit} autoComplete={'false'}>
          <Typography variant={'h3'}> { identifier.name } </Typography>
          <Box display={'flex'} flexDirection={'column'} sx={{ mt: 3 }}>
            <Checkbox
              label={'Add field to all patients'}
              name={'alwaysDisplayField'}
            />
          </Box>
        </Form>
      </FormProvider>
    </DialogV2>
  )
}
