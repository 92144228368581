import { REPORT_PARAMETERS, ReportParameter, ReportParameterType } from '../../../types/Report'
import { PractitionerSelect } from '../../../components/PractitionersSelect'
import React from 'react'
import FormattedDatePicker from '../../../components/FormattedDatePicker'
import { ControlledPractitionerAutocompleteMultiSelect } from '../../../components/PractitionerAutocompleteMultiSelect'

export interface ReportResultsParameterProps {
  parameter: ReportParameter
  index: number
}

export const ReportResultsParameter = ({parameter, index}:ReportResultsParameterProps) => {
  const properties = {
    key: parameter.id,
    label: REPORT_PARAMETERS[parameter.parameter],
    dataTestId: `report-run-parameter-${parameter.parameter}`
  }

  switch (parameter.parameter) {
    case ReportParameterType.APPOINTMENT_DATE_RANGE:
      return <ReportResultsDateRangeParameter {...properties} label={"Appointment date"} index={index} />
    case ReportParameterType.APPOINTMENT_PRACTITIONERS:
      return <ControlledPractitionerAutocompleteMultiSelect {...properties} name={`parameters.${index}.objectIdListReportRunParameter.value`} sx={{width: "288px"}} />
    case ReportParameterType.CLAIM_PRACTITIONERS:
      return <ControlledPractitionerAutocompleteMultiSelect {...properties} name={`parameters.${index}.objectIdListReportRunParameter.value`} sx={{width: "288px"}} />
    case ReportParameterType.PAYMENT_DATE_RANGE:
      return <ReportResultsDateRangeParameter {...properties} label={"Payment date"} index={index} />
    case ReportParameterType.SERVICE_DATE_RANGE:
      return <ReportResultsDateRangeParameter {...properties} label={"Service date"} index={index} />
    case ReportParameterType.PRIMARY_PRACTITIONER:
      return <PractitionerSelect {...properties} name={`parameters.${index}.objectIdReportRunParameter.value`} sx={{width: "288px", flex: "0 0 auto"}} />
  }
  return null
}

interface ReportResultsDateRangeParameterProps {
  label: string
  index: number
  dataTestId: string
}

const ReportResultsDateRangeParameter = ({label, index, dataTestId}:ReportResultsDateRangeParameterProps) => {
  return (
    <>
      <FormattedDatePicker
        label={`${label} start`}
        name={`parameters.${index}.dateRangeReportRunParameter.valueStart`}
        sx={{minWidth: "168px", flex: "0 1 auto"}}
        dataTestId={`${dataTestId}-startDate`}
      />
      <FormattedDatePicker
        label={`${label} end`}
        name={`parameters.${index}.dateRangeReportRunParameter.valueEnd`}
        sx={{minWidth: "168px", flex: "0 1 auto"}}
        dataTestId={`${dataTestId}-startDate`}
      />
    </>
  )
}