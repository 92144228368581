import { IconButton } from '../Button'
import React from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SxProps, Theme } from '@mui/material'

interface ExpandButtonProps {
  expand: boolean
  setExpand: (expand: boolean) => void
  dataTestId?: string
  sx?: SxProps<Theme>
}

const ExpandButton = ({ expand, setExpand, dataTestId, sx = {} }: ExpandButtonProps) => {
  return expand
    ? <IconButton icon={<ExpandLessIcon />} onClick={() => setExpand(false)} dataTestId={`${dataTestId}-collapse`} sx={sx} />
    : <IconButton icon={<ExpandMoreIcon />} onClick={() => setExpand(true)} dataTestId={`${dataTestId}-expand`} sx={sx} />
}

export default ExpandButton