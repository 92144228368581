import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LoadingOverlay } from "../components/LoadingScreen";
import { getLocationStorageKey } from "../config/LocalStorageKeys";
import { useAccountContext } from "./AccountContext";
import { Location } from "../types/Location";
import { LIST_LOCATIONS } from "../graphql-definitions";

interface LocationContextInterface {
  locations: Location[]
  selectedLocationId: string | null
  handleLocationChange: ( id : string) => void
  getLocationName: (id: string) => string
}

const defaultLocationContext : LocationContextInterface = {
  selectedLocationId: null,
  handleLocationChange: () => {},
  locations: [],
  getLocationName: () => '',
}

const LocationContext = React.createContext(defaultLocationContext)

export const LocationContextProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const { userId } = useAccountContext()

  const [locations, setLocations] = useState<Location[]>([])
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>( null)

  useEffect(()=>{
    const storedLocation = localStorage.getItem(getLocationStorageKey(userId))
    setSelectedLocationId(storedLocation === 'null' ? null : storedLocation)
  }, [userId])

  const { loading, data } = useQuery(LIST_LOCATIONS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Locations couldn't be retrieved.")
    },
    onCompleted: (data) => {
      const locations : Location[] = data.tenant.location.list
      setLocations(locations)
    },
    fetchPolicy: 'cache-first',
  })

  const handleLocationChange = (locationId) => {
    let destinationId: string | null = null
    if(locationId) {
      const destinationLocation = locations.find(l => l.id === locationId)
      destinationId = destinationLocation?.id || null

      if(!destinationId){
        console.error(`Location ${locationId} is not accessible`)
        showErrorAlert(
          'An error occurred accessing location'
        )
      }
    }

    localStorage.setItem(getLocationStorageKey(userId), `${destinationId}`)
    setSelectedLocationId(destinationId)
  }

  const getLocationName = (locationId) => locations.find(l => l.id === locationId)?.name || "no location"

  let providerValues = {
    locations,
    selectedLocationId,
    handleLocationChange,
    getLocationName,
  }

  if(loading){
    return <LoadingOverlay
      loading={loading}
      message={'Loading locations...'}
    />
  }

  if(data) {
    return (
      <LocationContext.Provider value={providerValues}>
        { children }
      </LocationContext.Provider>
    )
  }

  return <></>
}

export const useLocationContext = () => {
  return React.useContext(LocationContext)
}
