import React from 'react'
import { Select, Typography } from "saga-library/src";
import Box from '@mui/material/Box'
import DateFormats from "../../../config/DateFormats";
import moment from "moment-timezone";
import { MenuItem } from "@mui/material";
import { SettingsSubHeader } from "../AccountModal";
import BusinessIcon from '@mui/icons-material/Business';

const SettingsForm = ({dataTestId}) => {
  return (
    <>
      <SettingsSubHeader> Date and time </SettingsSubHeader>
      <Box display={"flex"} alignItems={"center"} gap={1}>
        <BusinessIcon sx={{color: (theme) => theme.palette.greys.medium}}/>
        <Select
          dataTestId={`${dataTestId}-dateFormat`}
          name={'shortDateFormat'}
          label={"Date format"}
          renderValue={(v) => <>{moment().format(v)}</>}
        >
          {DateFormats.map((format, index) =>
            <MenuItem
              key={`dateFormat-menuItem-${index}`}
              value={format}
              data-testid={`${dataTestId}-dateFormat-menuItem-${index}`}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  variant={'p4'}
                  sx={{
                    mb: '4px',
                  }}
                >
                  {format}
                </Typography>
                <Typography
                  variant={'body2'}
                  sx={{
                    color: (theme) => theme.palette.greys.light,
                    opacity: '64%',
                  }}
                >
                  {moment().format(format)}
                </Typography>
              </Box>
            </MenuItem>)}
        </Select>
      </Box>
    </>
  )
}

export default SettingsForm
export type SettingsFormValues = {
  shortDateFormat?: string
}
