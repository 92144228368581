import React from 'react'
import { useQuery } from '@apollo/client'
import { LoadingScreen } from '../components/LoadingScreen'
import { GET_STATIC_DATA_QUERY } from "../graphql-definitions/tenant/StaticDataQueries";
import { GenericError } from "../routes/static/ErrorPages";
import { PitTerminologyType } from "../types/PitTerminology";
import { DateDisplayMap } from "../apps/patients/components/prescriptions/components/PrescriptionUtil";



interface DefaultData {
  prescribedQuantityUnit: { [id: number] : PitTerminologyType }
  prescriptionRouteOfAdministration: { [id: number] : PitTerminologyType }
  prescriptionDrugForm: { [id: number] : PitTerminologyType }
  prescriptionAdministrationSite: { [id: number] : PitTerminologyType }
  prescriptionIndicationForUse: { [id: number] : PitTerminologyType }
  prescriptionDoseQuantityUnit: { [id: number] : PitTerminologyType }
  unitsOfTime: { [id: number] : PitTerminologyType },
  frequencyData: {code: string, description: string}[]
}


const defaultData: DefaultData = {
  prescribedQuantityUnit: {},
  prescriptionRouteOfAdministration: {},
  prescriptionDrugForm: {},
  prescriptionAdministrationSite: {},
  prescriptionIndicationForUse: {},
  prescriptionDoseQuantityUnit: {},
  unitsOfTime: {},
  frequencyData: []
}

const StaticDataContext = React.createContext(defaultData)


const convertPitArrayToDict = (arr, includeEntireObject = false) => {
  if (arr == null) return {}
  if(includeEntireObject) {
    return Object.fromEntries(arr.map(x => [x.id, x]))
  }

  return Object.fromEntries(arr.map(x => [x.id, x.display]))
}

const mapUnitsOfTime = (arr) => {
  let parsed: PitTerminologyType[] = []
  for (let item of arr) {
    if (DateDisplayMap[item.display] != null) {
      const newItem : PitTerminologyType = {
        id: item.id,
        display: DateDisplayMap[item.display].display,
        sort: DateDisplayMap[item.display].sort
      }
      parsed.push(newItem)
    } else {
      parsed.push(item)
    }
  }

  return convertPitArrayToDict(parsed)
}

export function StaticDataContextProvider({
  initial = defaultData,
  children,
}) {
  const { error, data } = useQuery(GET_STATIC_DATA_QUERY, {
    fetchPolicy: 'cache-first',
  })

  let providerValues = defaultData

  if (error) {
    console.error("StaticDataContext", error)
    return GenericError()
  }
  else if (data) {
    const {
      static: {
        prescribedQuantityUnit,
        prescriptionRouteOfAdministration,
        prescriptionDrugForm,
        prescriptionAdministrationSite,
        prescriptionIndicationForUse,
        prescriptionDoseQuantityUnit,
        unitsOfTime
      },
    } = data

    providerValues = {
      prescribedQuantityUnit: convertPitArrayToDict(prescribedQuantityUnit),
      prescriptionRouteOfAdministration: convertPitArrayToDict(prescriptionRouteOfAdministration),
      prescriptionDrugForm: convertPitArrayToDict(prescriptionDrugForm),
      prescriptionAdministrationSite: convertPitArrayToDict(prescriptionAdministrationSite),
      prescriptionIndicationForUse: convertPitArrayToDict(prescriptionIndicationForUse),
      prescriptionDoseQuantityUnit: convertPitArrayToDict(prescriptionDoseQuantityUnit, true),
      unitsOfTime: mapUnitsOfTime(unitsOfTime),
      frequencyData: frequencyData
    }
    
    return (
      <StaticDataContext.Provider value={providerValues}>
        {children}
      </StaticDataContext.Provider>
    )
  }
  else {
    return <LoadingScreen message={'Retrieving App Data'} />
  }
}



export const useStaticDataContext = () => {
  return React.useContext(StaticDataContext)
}


const frequencyData =  [{
  "code": "<x>ID",
  "description": "x times a day (x is an integer >4)"
}, {
  "code": "BID",
  "description": "Two times daily"
}, {
  "code": "ONCE",
  "description": "One time only"
}, {
  "code": "Q<x>-<y>H",
  "description": "Every <x> to <y> Hours"
}, {
  "code": "Q<x>D",
  "description": "Every <x> Days"
}, {
  "code": "Q<x>H",
  "description": "Every <x> Hours"
}, {
  "code": "Q<x>L",
  "description": "Every <x> Months"
}, {
  "code": "Q<x>M",
  "description": "Every <x> Minutes"
}, {
  "code": "Q<x>S",
  "description": "Every <s> Seconds"
}, {
  "code": "Q<x>W",
  "description": "Every <x> Weeks"
}, {
  "code": "Q1-2H",
  "description": "Every 1 to 2 hours"
}, {
  "code": "Q12H",
  "description": "Every 12 hours"
}, {
  "code": "Q1H",
  "description": "Every hour"
}, {
  "code": "Q2-3H",
  "description": "Every 2 to 3 hours"
}, {
  "code": "Q2D",
  "description": "Every other day"
}, {
  "code": "Q2H",
  "description": "Every 2 hours"
}, {
  "code": "Q3-4H",
  "description": "Every 3 to 4 hours"
}, {
  "code": "Q3H",
  "description": "Every 3 hours"
}, {
  "code": "Q4-6H",
  "description": "Every  4 to 6 hours"
}, {
  "code": "Q4H",
  "description": "Every 4 hours"
}, {
  "code": "Q6-8H",
  "description": "Every  6 to 8  hours"
}, {
  "code": "Q6H",
  "description": "Every 6 hours"
}, {
  "code": "Q8-12H",
  "description": "Every 8 to 12 hours"
}, {
  "code": "Q8H",
  "description": "Every 8 hours"
}, {
  "code": "QAM",
  "description": "Every morning"
}, {
  "code": "QAM @ nnn",
  "description": "Every morning @ nnnn hours"
}, {
  "code": "QD",
  "description": "Once daily"
}, {
  "code": "QHS",
  "description": "Every  day at bedtime"
}, {
  "code": "QID",
  "description": "Four times daily"
}, {
  "code": "QNOON",
  "description": "Every day at noon"
}, {
  "code": "QPM",
  "description": "Every evening"
}, {
  "code": "QPM @ nnn",
  "description": "Every evening @ nnnn hours"
}, {
  "code": "STAT",
  "description": "Now"
}, {
  "code": "TID",
  "description": "Three times daily"
}]