import React, { useEffect } from "react";
import { LinkedDocumentModal } from "../LinkedDocumentForm/LinkedDocumentModal";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";

const ENCOUNTER_NOTES_DOCUMENT_FORM_NAME = "encounter_notes_document_modal_form"
export const EncounterNotesDocumentModal = ({
  encounterNote,
  onModalClose
}) => {
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)

  useEffect(() => {
    if(encounterNote.id && encounterNote.linkedDocument){
      setDocument(encounterNote.linkedDocument)
    }
  }, [encounterNote.id, encounterNote.linkedDocument]);

  return (
    <LinkedDocumentModal
      formName={ENCOUNTER_NOTES_DOCUMENT_FORM_NAME}
      document={document}
      category={DocumentCategory.CHART_NOTE}
      title={document?.description || "Encounter note"}
      onModalClose={onModalClose}
    />
  )
}