import React from "react"
import PermissionButton from "../../../../components/PermissionButton";
import { PermissionType, Permission } from "../../../../types/settings/Permission";
import { ReadOnlyFormActionsMask } from "../../../../components/ReadOnlyFormActionsMask";
import { Typography } from "@mui/material";
import { useAccountContext } from "../../../../providers/AccountContext";
import SettingsHeader from "../../components/SettingsHeader";
import { useNavigate, useParams } from "react-router-dom";

const PractitionerHeader = ({onSubmit, title='New practitioner', subTitle='', formName='practitionerForm', loading}) => {
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id } = useParams()
  const navigate = useNavigate()

  return <SettingsHeader
    title={title}
    onBack={() =>
      navigate(buildTenantRoute('settings/practitioners', tenant_id))
    }
    actions={
      <ReadOnlyFormActionsMask requiredType={PermissionType.Practitioner} requiredPermission={Permission.READWRITE}>
        <PermissionButton
          requiredType={PermissionType.Practitioner}
          requiredPermission={Permission.READWRITE}
          name={'save-button'}
          onClick={onSubmit}
          dataTestId={"practitionerHeader-save-button"}
          loading={loading}
          type={"submit"}
          form={formName}
        >
          Save
        </PermissionButton>
      </ReadOnlyFormActionsMask>
    }
    children={[
      <Typography sx={{ fontWeight: 'bold' }} variant={'h3'} key={0}>
        {subTitle}
      </Typography>,
    ]}
    dataTestId={'practitioner'}
  />
}

export default PractitionerHeader