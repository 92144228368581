import { gql } from "@apollo/client";

export const ENCOUNTER_NOTE_TEMPLATE_FRAGMENT = gql`
    fragment EncounterNoteTemplateFragment on EncounterNoteTemplate {
        id
        name
        fields {
            id
            name
            type
            order
            parentGroupId
        }
    }
`

export const LIST_ENCOUNTER_NOTE_TEMPLATES = gql`
    ${ENCOUNTER_NOTE_TEMPLATE_FRAGMENT}
    query ListEncounterNoteTemplates($tenantId: ID!) {
        tenant(id: $tenantId) {
            encounterNoteTemplate {
                list {
                    ...EncounterNoteTemplateFragment
                }
            }
        }
    }
`


export const GET_ENCOUNTER_NOTE_TEMPLATE = gql`
    ${ENCOUNTER_NOTE_TEMPLATE_FRAGMENT}
    query GetEncounterNoteTemplate($tenantId: ID!, $id: ID!) {
        tenant(id: $tenantId) {
            encounterNoteTemplate {
                get(id: $id) {
                    ...EncounterNoteTemplateFragment
                }
            }
        }
    }
`