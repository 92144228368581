import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import React from "react";
import PermissionButton from "../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../types/settings/Permission";

export const ManageTemplates = ({dataTestId}) => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id } = useParams()

  return (
    <PermissionButton
      dataTestId={dataTestId}
      onClick={() => navigate(buildTenantRoute('schedule/templates', tenant_id))}
      name={'manageTemplates'}
      variant={'outlined'}
      requiredType={PermissionType.Schedule}
      requiredPermission={Permission.READWRITE}
    >
      Manage Templates
    </PermissionButton>
  )
}