import React from "react";
import { useParams } from "react-router-dom";
import { QueryReference, useBackgroundQuery } from '@apollo/client'
import { GET_PATIENT_APPOINTMENTS } from "../../../graphql-definitions/tenant/patient/AppointmentQueries";
import { PatientAppointment } from "../../../types/patients";
import { Edge, PageInfo } from 'saga-library/src/components/TableList/PagedTableList'
import _get from 'lodash/get'

const DEFAULT_PAGE_SIZE = 50

const getAppointmentQueryResults = (data) => {
  const appointments = data.tenant.patient.appointment.list.edges.map(e => e.node)

  return [...appointments as Array<PatientAppointment>]
}

const getAppointmentQueryResultsPageInfo = (data) => {
  return data.tenant.patient.appointment.list.pageInfo as PageInfo
}

interface AppointmentContextInterface {
  appointmentQueryRef: QueryReference
  getAppointmentQueryResults: (any) => PatientAppointment[]
  getAppointmentQueryResultsPageInfo: (any) => PageInfo,
  fetchMore: (string) => Promise<any>
}

const defaultAppointmentContext: AppointmentContextInterface = {
  appointmentQueryRef: {} as QueryReference,
  getAppointmentQueryResults: getAppointmentQueryResults,
  getAppointmentQueryResultsPageInfo: getAppointmentQueryResultsPageInfo,
  fetchMore: () => new Promise<void>(() => {})
}

const AppointmentContext = React.createContext(defaultAppointmentContext)

export const AppointmentProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()

  const [appointmentQueryRef, { fetchMore : fetchMoreFunction } ] = useBackgroundQuery(GET_PATIENT_APPOINTMENTS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id,
      first: DEFAULT_PAGE_SIZE
    }
  })

  const fetchMore = (cursor:string) => fetchMoreFunction({variables: {
        patientId: patient_id,
        tenantId: tenant_id,
        first: DEFAULT_PAGE_SIZE,
        after: cursor
      },
      updateQuery(previousData, { fetchMoreResult }) {
        const oldAppointments = _get(previousData, 'tenant.patient.appointment.list.edges', []) as Edge[]
        const newAppointments = _get(fetchMoreResult, 'tenant.patient.appointment.list.edges', []) as Edge[]
        return {
          tenant: {
            patient: {
              appointment: {
                list: {
                  edges: [...oldAppointments, ...newAppointments],
                  pageInfo: _get(fetchMoreResult, 'tenant.patient.appointment.list.pageInfo', {}) as PageInfo
                }
              }
            }
          }
        }
      }
    })

  const providerValues = {
    appointmentQueryRef,
    getAppointmentQueryResults,
    getAppointmentQueryResultsPageInfo,
    fetchMore
  }

  return (
    <AppointmentContext.Provider value={providerValues}>
      { children }
    </AppointmentContext.Provider>
  )
}

export const useAppointmentContext = () => {
  return React.useContext(AppointmentContext)
}