import React from "react";
import { createSvgIcon } from "@mui/material";
import UrgentIconSVG from "../../../assets/UrgentIcon.svg";
import HighIconSVG from "../../../assets/HighIcon.svg";
import MediumIconSVG from "../../../assets/MediumIcon.svg";
import LowIconSVG from "../../../assets/LowIcon.svg";
import { ReactComponent as PrescriptionsSVG } from "../../../assets/PrescriptionsIcon.svg";
import { ReactComponent as LabsImagingSVG } from "../../../assets/LabsImagingIcon.svg";
import { ReactComponent as HistorySVG } from "../../../assets/HistoryIcon.svg";
import { ReactComponent as AllergySVG } from "../../../assets/AllergyIcon.svg";
import { ReactComponent as ConditionAndProceduresSVG } from "../../../assets/ConditionAndProceduresIcon.svg";
import { ReactComponent as LetterSVG } from "../../../assets/LettersIcon.svg";
import { ReactComponent as OtherDocumentsSVG } from "../../../assets/OtherDocumentsIcon.svg";
import { ReactComponent as FormsSVG } from "../../../assets/FormsIcon.svg";
import { ReactComponent as ChartingSVG } from "../../../assets/ChartingIcon.svg";
import { ReactComponent as TimelineSVG } from "../../../assets/TimelineIcon.svg";
import { ReactComponent as PractitionerSVG } from "../../../assets/PractitionerIcon.svg";
import { ReactComponent as ChartDataSVG} from "../../../assets/ChartDataIcon.svg";
import { ReactComponent as SignedIconSVG} from "../../../assets/Sign.svg";
import { ReactComponent as PenIconSVG } from "../../../assets/PenIcon.svg";
import { ReactComponent as BirthdayIconSVG } from "../../../assets/Birthday.svg";

export const UrgentIcon = () => {
  return <img src={UrgentIconSVG} height={'16px'} width={'16px'} alt={"Urgent"} />
}

export const HighIcon = () => {
  return <img src={HighIconSVG} height={'16px'} width={'16px'} alt={"High"} />
}

export const MediumIcon = () => {
  return <img src={MediumIconSVG} height={'8px'} width={'12px'} style={{ margin: '4px 2px' }} alt={"Medium"} />
}

export const LowIcon = () => {
  return <img src={LowIconSVG} height={'16px'} width={'16px'} alt={"Low"} />
}

export const HistoryIcon = createSvgIcon(<HistorySVG />, "History")

export const AllergiesIcon = createSvgIcon(<AllergySVG />, "Allergies")

export const PrescriptionIcon = createSvgIcon(<PrescriptionsSVG />, "Prescriptions")

export const ConditionAndProcedureIcon = createSvgIcon(<ConditionAndProceduresSVG />, "Conditions and Procedures")

export const LetterIcon = createSvgIcon(<LetterSVG />, "Letters")

export const FormIcon = createSvgIcon(<FormsSVG />, "Forms")

export const OtherDocumentIcon = createSvgIcon(<OtherDocumentsSVG />, "Other Documents")

export const LabAndInvestigationsIcon = createSvgIcon(<LabsImagingSVG />, "Labs")

export const ChartNotesIcon = createSvgIcon(<ChartingSVG />, "Encounter Notes")

export const TimelineIcon = createSvgIcon(<TimelineSVG />, "Timeline")

export const PractitionerIcon = createSvgIcon(<PractitionerSVG />, "Practitioner")

export const ChartDataIcon = createSvgIcon(<ChartDataSVG height={24} width={24} />, "Chart Data")

export const SignedIcon = createSvgIcon(<SignedIconSVG />, "Signed")

export const PenIcon = createSvgIcon(<PenIconSVG height={24} width={24} />, "Pen")

export const BirthdayIcon = createSvgIcon(<BirthdayIconSVG />, "Date of Birth")