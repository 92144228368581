import React, { useMemo } from "react";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { ControlledSearch, EntryTitle, ResultOptionsWrapper } from "saga-library/src";
import { ControlledSearchControlProps } from "saga-library/src/components/SearchControl/ControlledSearchControl";
import { GET_SEARCH_DIAGNOSTICCODES, LIST_RECENT_CONDITION_AND_PROCEDURES } from "../../../graphql-definitions";
import DiagnosticCodeResult from "../Claims/DiagnosticCodeResult";
import { AbClaimDiagnosticCode } from "../../../types/billing";
import { ConditionAndProcedureType } from "../../../types/ConditionAndProcedure";
import { useFormContext, useWatch } from 'react-hook-form'

export interface ConditionAndProcedureDescriptionSearchProps<
  T = AbClaimDiagnosticCode | string,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = true
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  disabled?: boolean
  dataTestId: string
}

export const ConditionAndProcedureDescriptionSearch = ({
  name,
  label,
  disabled = false,
  dataTestId,
  ...props
} :ConditionAndProcedureDescriptionSearchProps) => {

  const { tenant_id } = useParams()
  const { control } = useFormContext()
  const serviceDate = new Date()
  const diagnosticCode = useWatch({
    control,
    name: 'diagnosticCode'
  })

  const searchQueryVariables = useMemo(() => ({
    tenantId: tenant_id,
    serviceDate: serviceDate
  }), [tenant_id])

  const initialQueryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  return (
    <ControlledSearch<AbClaimDiagnosticCode|string, false, false, true>
      {...props}
      freeSolo
      dataTestId={dataTestId}
      autoFocus={props.autoFocus}
      name={name}
      label={label}
      disabled={disabled}
      queries={{
        search: {
          gql: GET_SEARCH_DIAGNOSTICCODES,
          get: (data) => _get(data, 'tenant.search.abClaim.diagnosticCodes', [] as AbClaimDiagnosticCode[]),
          variables: searchQueryVariables,
          fetchPolicy: 'network-only'
        },
        initial: {
          gql: LIST_RECENT_CONDITION_AND_PROCEDURES,
          get: (data) => {
            let recentConditions = _get(data, "tenant.recentConditionAndProcedures", []) as ConditionAndProcedureType[];
            return recentConditions.map((condition) => {
              return {
                id: condition.id,
                description: condition.description,
                diagnosticCode: condition.diagnosticCode?.diagnosticCode
              } as AbClaimDiagnosticCode
            })
          },
          variables: initialQueryVariables,
          fetchPolicy: 'cache-and-network'
        }
      }}
      render={ConditionAndProcedureRenderer}
      isOptionEqualToValue={(option, value) => DescriptionEqual(option, value, diagnosticCode?.diagnosticCode)}
    />
  )
}

function DescriptionEqual(a, b, diagnosticCode): boolean {
  if (typeof a === 'string' && typeof b === 'string') {
    return a === b
  } else if (typeof a === 'string' && typeof b === 'object') {
    return a === b.description
  } else if (typeof a === 'object' && typeof b === 'string') {
    if (diagnosticCode) {
      return a.diagnosticCode === diagnosticCode && a.description === b
    }
    return a.description === b
  }
  return a.id === b.id
}

export const ConditionAndProcedureRenderer = {
  value: (code) => {
    if (typeof code === 'string') {
      return code
    }
    return code.description
  },
  option: (code, state) =>
  {
    if (typeof code === 'string' || !code.diagnosticCode) {
      return (
        <ResultOptionsWrapper>
          <EntryTitle
            title={typeof code === 'string' ? code : code.description}
            searchText={state.inputValue}
          />
        </ResultOptionsWrapper>
      )
    }
    return (
      <DiagnosticCodeResult
        diagnosticCode={code}
        searchText={state.inputValue}
      />
    )
  }
}