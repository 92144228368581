import React, { useState } from "react";
import { TableList, TableListHeaderConfig, TableListRowConfig } from "saga-library/src";
import SettingsHeader from "../components/SettingsHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { LIST_PHARMACIES } from "../../../graphql-definitions";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import PermissionButton from "../../../components/PermissionButton";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { PHARMACIES_SCROLL_KEY } from '../../../providers/UserInteractionContext'

const columnHeaders: TableListHeaderConfig[] = [
  { name: 'Name', sortable: true },
  { name: 'Fax number' },
  { name: 'Phone number' },
  { name: 'Address' },
]

const PharmacyRow = (pharmacy, navigate): TableListRowConfig => {
  const { name, street1, province, city, postalCode, id, faxNumber, phoneNumber } = pharmacy
  return {
    onClick: () => navigate(`p/${id}`),
    key: id,
    rowData: [
      { data: name },
      { data: phoneNumberMask(faxNumber) },
      { data: phoneNumberMask(phoneNumber) },
      { data: `${street1 ? street1 + ',' : ''} ${city ? city + ',' : ''} ${province ? province : ''} ${postalCode  ? postalCode : ''}` },
    ]
  }
}

export const Pharmacies = () => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const [pharmacies, setPharmacies] = useState([])
  const [retrievalError, setRetrievalError] = useState<undefined | string>()

  const { loading} = useQuery(LIST_PHARMACIES, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error('Unable to retrieve pharmacies: ' + error)
      setRetrievalError('Unable to retrieve pharmacies')
    },
    onCompleted: (data) => {
      setPharmacies(_get(data, 'tenant.pharmacy.list', []))
    }
  })

  const NewPharmacyButton = () => {
    return <PermissionButton
      name={"newPharmacy"}
      onClick={()=>navigate('new')}
      requiredType={PermissionType.Admin}
      requiredPermission={Permission.READONLY}
      dataTestId={"pharmacies-new-button"}
    >
      New Pharmacy
    </PermissionButton>
  }

  return (
    <SettingsSectionColumn header={<SettingsHeader title={'Pharmacies'} actions={<NewPharmacyButton />} dataTestId={'pharmacies'} />}>
      <TableList
        size={'small'}
        scrollKey={PHARMACIES_SCROLL_KEY}
        loading={loading}
        enableSorting={true}
        columns={columnHeaders}
        rows={[...pharmacies.map((pharmacy) => PharmacyRow(pharmacy, navigate))]}
        emptyListComponent={ { message: retrievalError || "Pharmacies appear here once added."} }
        dataTestId={"pharmacies"}
        leftAlignColumns={true}
      />
    </SettingsSectionColumn>
  )
}
