import React, { useEffect, useState } from "react";
import { Box, Divider, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button, IconButton, SubsectionMenu, SubsectionMenuItem } from "saga-library/src";
import { ScheduleSettingsDialog } from "../apps/schedule/components/ScheduleSettingsDialog";
import { useQuery } from "@apollo/client";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { ScheduleType } from "../types/schedule";
import { useLocationContext } from "../providers/LocationContextProvider";
import { Spinner } from "saga-library/src";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { LIST_SCHEDULES } from "../graphql-definitions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Permission, PermissionType } from "../types/settings/Permission";
import { useAccountContext } from "../providers/AccountContext";
import { LocationDropdown } from "./LocationDropdown";
import { ManageTemplates } from "../apps/schedule/components/ManageTemplates";
import { ApplyTemplateDialog } from "../apps/schedule/components/ApplyTemplateDialog";
import { useScheduleContext } from "../providers/ScheduleContextProvider";
import { PractitionerIcon } from '../apps/patients/components/ChartIcons'

export interface PractitionerScheduleType{
  practitionerFirstName: string,
  practitionerLastName: string,
  id: string,
  scheduleId: string
  locationId:string
  defaultBillingProfileId?:string
}
export interface PractitionerScheduleListSelectProps {
  value?:Array<string>
  onChange: (string) => void
  showAll?: boolean
  onApplyTemplateComplete: () => void
}

export const PractitionerScheduleListSelect = ({value=[], onChange, showAll = false, onApplyTemplateComplete}:PractitionerScheduleListSelectProps) => {
  const { tenant_id } = useParams()
  const { selectedLocationId } = useLocationContext()
  const { scheduleView, openScheduleIdSettings} = useScheduleContext()

  const [ selectedIds, setSelectedIds ] = useState<Array<string>>(value)
  const [ currentLocation, setCurrentLocation ] = useState(selectedLocationId)
  const [ practitionerSchedules, setPractitionerSchedules ] = useState<PractitionerScheduleType[]>([])
  const [ openApplyTemplate, setOpenApplyTemplate ] = useState<boolean>(false)

  useEffect(()=>{
    if(value){
      const selectedIds:Array<string> = []

      value.forEach( (scheduleId) => {
        selectedIds.push(scheduleId)
      })

      setSelectedIds(selectedIds)
    }
  }, [value])

  useEffect(()=> {
    if(selectedLocationId !== currentLocation) {
      setCurrentLocation(selectedLocationId)

      filterPractitioners().then((schedules) => {
        if (schedules && schedules.length > 0) {
          setSelectedIds([schedules[0].scheduleId])
          onChange([schedules[0].scheduleId])
        }
      })
    }
  }, [selectedLocationId])

  const { loading, data:scheduleData } = useQuery(LIST_SCHEDULES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onCompleted:(data => {
      filterPractitioners()
    })
  })

  const filterPractitioners = async() => {
    let schedules: ScheduleType[] = _get( scheduleData, 'tenant.schedule.listSchedules', [])
    if(schedules.length > 0){
      let scheduleLocations: ScheduleType[] = []
      if(selectedLocationId && !showAll){
        scheduleLocations = schedules.filter( s => s.locationId === selectedLocationId)
      } else {
        scheduleLocations = schedules
      }

      const scheduledPractitioners:PractitionerScheduleType[] = []
      scheduleLocations.forEach(s => {
        scheduledPractitioners.push({
          practitionerFirstName:s.practitionerFirstName,
          practitionerLastName:s.practitionerLastName,
          id:s.practitionerId,
          scheduleId: s.id,
          locationId: s.locationId
        })
      })

      setPractitionerSchedules(scheduledPractitioners)
      return(scheduledPractitioners)
    }
  }

  const onSelect = (scheduleId) => {
    setSelectedIds([scheduleId])
    onChange([scheduleId])
  }

  const toggleSelectId = (scheduleId) => {
    const idIndex = selectedIds.indexOf(scheduleId)
    const updatedPractitionerSchedules = selectedIds

    if(idIndex > -1){
      updatedPractitionerSchedules.splice(idIndex, 1)
    } else {
      updatedPractitionerSchedules.push(scheduleId)
    }

    setSelectedIds(updatedPractitionerSchedules)
    onChange(updatedPractitionerSchedules)
  }

  const selectAll = () => {
    const allPractitionerScheduleIds:Array<string> = []

    practitionerSchedules.forEach((practitioner) => {
        allPractitionerScheduleIds.push(practitioner.scheduleId)
    })

    setSelectedIds(allPractitionerScheduleIds)
    onChange(allPractitionerScheduleIds)
  }

  if(loading){
    return <Box sx={{mt:3}}>
      <Spinner />
    </Box>
  }

  return <>
    <SubsectionMenu
      emptyListComponent={{
        message: 'Practitioners with a schedule at this location appear here.',
        icon: PractitionerIcon,
        sx: { pr: 1 }
      }}
      header={
        <>
          <LocationDropdown dataTestId={"schedule-location"} />
          <Box>
            <Button
              dataTestId={"schedule-viewAll"}
              variant={'text'}
              name={'view_all_button'}
              onClick={selectAll}
              sx={{px:1}}
            >
              View All
            </Button>
            <Divider />
          </Box>
        </>
      }
      footer={<ManageTemplates dataTestId={"schedule-manageTemplates"} />}
      overflowY={'auto'}
      routes={
        practitionerSchedules.map( (practitioner, index) => {
          return <PractitionerScheduleListItem
            dataTestId={`schedule-practitionerMenu-menuItem-${index}`}
            key={`practitioner_schedule_${practitioner.scheduleId}`}
            practitioner={ practitioner }
            onSelect={ onSelect }
            isChecked={ selectedIds.includes(practitioner.scheduleId)}
            onToggleSelect={ toggleSelectId }
            showLocation={ !selectedLocationId || showAll }
            onSettingsSelect={() => {
              openScheduleIdSettings(practitioner.scheduleId)
            }}
            multipleSelected={selectedIds.length > 1}
            onApplyTemplateClick={() => setOpenApplyTemplate(true)}
          />
        })
      }
    />
    <ApplyTemplateDialog
      open={openApplyTemplate}
      handleClose={() => setOpenApplyTemplate(false)}
      scheduleDate={scheduleView.date}
      onComplete={onApplyTemplateComplete}
    />
  </>
}

type PractitionerScheduleListItemProps = {
  practitioner:PractitionerScheduleType,
  isChecked: boolean,
  onSelect: (string) => void,
  onToggleSelect: (string) => void
  showLocation?: boolean
  onSettingsSelect: (v: string) => void
  multipleSelected: boolean
  onApplyTemplateClick?: () => void
  dataTestId?: string
}
const PractitionerScheduleListItem = ({practitioner, isChecked = false, onSelect, onToggleSelect, showLocation = false, onSettingsSelect, multipleSelected, onApplyTemplateClick, dataTestId} : PractitionerScheduleListItemProps) => {
  const { getLocationName } = useLocationContext()
  const { userHasPermission } = useAccountContext()
  const { tenant_id } = useParams()
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Schedule, Permission.READWRITE)

  return (
    <SubsectionMenuItem
      dataTestId={dataTestId}
      key={`practitioner_li_${practitioner.scheduleId}`}
      isActive={isChecked}
      secondaryActions={<SecondaryActions
        dataTestId={`${dataTestId}-secondaryActions`}
        isSelected={isChecked}
        onToggleSelect={() => onToggleSelect(practitioner.scheduleId)}
        onSettingsSelect={onSettingsSelect}
        multipleSelected={multipleSelected}
        onApplyTemplateClick={onApplyTemplateClick}
        hasReadWrite={hasReadWrite}
      />}
      primaryText={practitionerDisplayName(practitioner.practitionerFirstName, practitioner.practitionerLastName)}
      secondaryText={getLocationName(practitioner.locationId)}
      onClick={() => onSelect(practitioner.scheduleId)}
    />
  )
}

const SecondaryActions = ({ isSelected, onToggleSelect, onSettingsSelect, multipleSelected, onApplyTemplateClick, hasReadWrite, dataTestId = '' }) => {
  if (!multipleSelected && isSelected) {
    return (
      <ScheduleSettingsButton
        dataTestId={`${dataTestId}-scheduleSettings`}
        onSettingsSelect={onSettingsSelect}
        onApplyTemplateClick={onApplyTemplateClick}
        hasReadWrite={hasReadWrite}
      />
    )
  }
  return <ToggleSelectButton dataTestId={`${dataTestId}-toggle`} isSelected={isSelected} onToggleSelect={onToggleSelect}/>
}

const ScheduleSettingsButton = ({onSettingsSelect, onApplyTemplateClick, hasReadWrite = false, dataTestId = ''}) => {
  const [anchorEl, setAnchorEl] = useState<any | HTMLElement>(undefined)
  const [ open, setOpen ] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const  handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <>
      <IconButton dataTestId={dataTestId} onClick={handleClick} icon={<MoreHorizIcon />}/>
      <Menu data-testid={`${dataTestId}-menu`} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem data-testid={`${dataTestId}-menu-menuitem-0`} onClick={onSettingsSelect}>
          Schedule settings
        </MenuItem>
        { hasReadWrite &&
          <MenuItem data-testid={`${dataTestId}-menu-menuitem-1`} onClick={onApplyTemplateClick}>
            Apply template
          </MenuItem>
        }
      </Menu>
    </>
  )
}

const ToggleSelectButton = ({isSelected, onToggleSelect, dataTestId = ''}) => {
  return <IconButton
    dataTestId={dataTestId}
    onClick={()=> {
      onToggleSelect()
    }}
    icon={isSelected ? <RemoveIcon /> : <AddIcon /> }
  />
}

