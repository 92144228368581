import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TableList, TableListHeaderConfig } from "saga-library/src";
import { useQuery } from "@apollo/client";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { AppointmentRoomRow } from "./AppointmentRoomRow"
import { NewAppointmentRoomModal } from "./NewAppointmentRoomModal";
import { EditAppointmentRoomModal } from "./EditAppointmentRoomModal";
import { AppointmentRoom } from "../../../../types/schedule";
import { LIST_APPOINTMENT_ROOMS } from "../../../../graphql-definitions";
import { useAccountContext } from "../../../../providers/AccountContext";
import SettingsHeader from "../../components/SettingsHeader";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";

const columns: TableListHeaderConfig[] = [
  { name: 'Name' },
  { name: 'Location' },
  { name: 'Limit to practitioner' },
]

export const AppointmentRooms = () => {
  const navigate = useNavigate()
  const { userHasPermission } = useAccountContext()
  const { tenant_id } = useParams()
  const [editAppointmentRoom, setEditAppointmentRoom] = useState<AppointmentRoom | null>(null)
  const [newAppointmentRoomOpen, setNewAppointmentRoomOpen] = useState<boolean>(false)
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Schedule, Permission.READWRITE)

  const { loading, error, data } = useQuery(LIST_APPOINTMENT_ROOMS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Appointment rooms'}
        onBack={() => navigate(-1)}
        dataTestId={'appointmentRooms'}
        actions={
          <PermissionButton
            name={'newAppointmentRoom'}
            onClick={() => setNewAppointmentRoomOpen(true)}
            requiredType={PermissionType.Schedule}
            requiredPermission={Permission.READWRITE}
            dataTestId={'appointmentRooms-new-button'}
          >
            NEW ROOM
          </PermissionButton>
        }
      />
    }>
      <TableList
        size={'small'}
        loading={loading}
        columns={columns}
        dataTestId={'appointmentRooms-tableListf'}
        rows={
          data ? [...data.tenant.schedule.room.listRooms.map((appointmentRoom) => AppointmentRoomRow(appointmentRoom, setEditAppointmentRoom, hasReadWrite))] : []
        }
        sx={{ mt: 2 }}
        leftAlignColumns={true}
      />
      <NewAppointmentRoomModal
        open={newAppointmentRoomOpen}
        setOpen={setNewAppointmentRoomOpen}
      />
      <EditAppointmentRoomModal
        open={!!editAppointmentRoom}
        onClose={() => setEditAppointmentRoom(null)}
        appointmentRoom={editAppointmentRoom}
      />
    </SettingsSectionColumn>
  )
}
