import React from 'react'
import * as yup from 'yup'
import { Form, FormProvider, useForm } from 'saga-library/src/components/Form'
import { DialogV2, LoadingButton } from 'saga-library/src'
import { TaskStateSelect } from '../../../../components/TaskStateSelect'
import { useTaskContext } from '../../../../providers/TaskProvider'

const FORM_NAME = "update_task_status_form"

type UpdateStatusFormType = {
  stateId: string
}

const defaults = {
  stateId: ""
}

const schema = yup.object({
  stateId: yup.string().required("Required")
})

export const UpdateTaskStatusDialog = ({ open, onClose, onSuccess, selectedTasks }) => {
  const { updateTaskStatuses } = useTaskContext()

  const formMethods = useForm<UpdateStatusFormType>({
    defaultValues: defaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = formMethods

  const onCancel = () => {
    onClose()
    reset(defaults)
  }

  const onSave = handleSubmit( async(input: UpdateStatusFormType) => {
    await updateTaskStatuses(selectedTasks, input.stateId, () => {
      onSuccess()
      onClose()
    })
  })

  return (
    <DialogV2
      dataTestId={'update-task-status-dialog'}
      formName={FORM_NAME}
      size={'xs'}
      title={'Update status'}
      overridePrimaryComponent={
        <LoadingButton
          name={'updateTaskStatuses'}
          form={FORM_NAME}
          dataTestId={"update-task-status-dialog-update-button"}
          loading={isSubmitting}
          onClick={onSave}
          variant={'contained'}
        >
          Update
        </LoadingButton>
      }
      onClose={onCancel}
      open={open}
      preventScroll={true}
      contentSx={{ height: '208px' }}
    >
      <FormProvider {...formMethods}>
        <Form onSubmit={onSave} id={FORM_NAME}>
          <TaskStateSelect
            name={'stateId'}
            label={'State'}
            placeholder={'Select state'}
            dataTestId={"update-task-status-form-state"}
          />
        </Form>
      </FormProvider>
    </DialogV2>
  )
}