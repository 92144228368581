import { gql } from "@apollo/client";
import { DOCUMENTS_FRAGMENT } from "./LinkedDocumentQueries";
import { FILE_FRAGMENT } from '../file/FileQueries'

export const AB_LAB_CC_TERMINOLOGY_FRAGMENT = gql`
  fragment AbLabCcTerminologyFragment on AbLabCcTerminology {
    id
    code
    subset
    display
  }
`

export const AB_LAB_RESULT_OBSERVATION_RESULT_FRAGMENT = gql`
  ${AB_LAB_CC_TERMINOLOGY_FRAGMENT}
  ${FILE_FRAGMENT}
  fragment AbLabResultObservationResultFragment on AbLabResultObservationResult {
    id
    segmentOrder
    valueType {
      ...AbLabCcTerminologyFragment
    }
    observationDate
    observationDescription
    observationValue
    unitsDescription
    referenceRange
    abLabResultObservationResultAbnormals {
      id
      flag {
        ...AbLabCcTerminologyFragment
      }
    }
    file {
      ...FileDetails
    }
    performingOrganizationName
  }
`

export const AB_LAB_RESULT_OBSERVATION_REQUEST_FRAGMENT = gql`
  ${AB_LAB_CC_TERMINOLOGY_FRAGMENT}
  fragment AbLabResultObservationRequestFragment on AbLabResultObservationRequest {
    id
    segmentOrder
    resultStatus {
      ...AbLabCcTerminologyFragment
    }
    resultDate
    fillerOrderId
    placerOrderId
    fillerField1
    observationDate
    specimenSource
    specimenReceivedDate
    people {
      id
      type
      firstName
      lastName
    }
  }
`

export const AB_LAB_RESULT_SPECIMEN_FRAGMENT = gql`
  fragment AbLabResultSpecimenFragment on AbLabResultSpecimen {
    id
    segmentOrder
    collectionDate
    receivedDate
    source
    type
  }
`

export const AB_LAB_RESULT_ORDER_FRAGMENT = gql`
  fragment AbLabResultOrderFragment on AbLabResultOrder {
    id
    segmentOrder
    orderEffectiveDate
    people {
      id
      type
      firstName
      lastName
    }
  }
`

export const AB_LAB_RESULT_TRANSCRIPTION_FRAGMENT = gql`
  ${AB_LAB_CC_TERMINOLOGY_FRAGMENT}
  fragment AbLabResultTranscriptionFragment on AbLabResultTranscription {
    id
    segmentOrder
    documentCompletionStatus {
      ...AbLabCcTerminologyFragment
    }
    people {
      id
      type
      firstName
      lastName
    }
  }
`

export const AB_LAB_RESULT_NOTE_AND_COMMENT_FRAGMENT = gql`
  fragment AbLabResultNoteAndCommentFragment on AbLabResultNoteAndComment {
    id
    segmentOrder
    comment
  }
`

export const LAB_AND_INVESTIGATION_FRAGMENT = gql`
  ${AB_LAB_CC_TERMINOLOGY_FRAGMENT}
  ${AB_LAB_RESULT_OBSERVATION_RESULT_FRAGMENT}
  ${AB_LAB_RESULT_OBSERVATION_REQUEST_FRAGMENT}
  ${AB_LAB_RESULT_SPECIMEN_FRAGMENT}
  ${AB_LAB_RESULT_ORDER_FRAGMENT}
  ${AB_LAB_RESULT_TRANSCRIPTION_FRAGMENT}
  ${AB_LAB_RESULT_NOTE_AND_COMMENT_FRAGMENT}
  ${DOCUMENTS_FRAGMENT}
  fragment LabAndInvestigationFragment on AbLabResult {
    id
    isLab
    sourceType
    description
    date
    sendingApplication
    sendingFacility
    patientName {
      firstName
      middleName
      lastName
    }
    dob
    gender {
      ...AbLabCcTerminologyFragment
    }
    patientIdentifiers {
      id
      label
      type {
        ...AbLabCcTerminologyFragment
      }
      assigningAuthority {
        ...AbLabCcTerminologyFragment
      }
    }
    isAbnormal
    abLabResultObservationResults {
      ...AbLabResultObservationResultFragment
    }
    abLabResultObservationRequest {
      ...AbLabResultObservationRequestFragment
    }
    abLabResultSpecimen {
      ...AbLabResultSpecimenFragment
    }
    abLabResultOrder {
      ...AbLabResultOrderFragment
    }
    abLabResultTranscription {
      ...AbLabResultTranscriptionFragment
    }
    abLabResultNoteAndComments {
      ...AbLabResultNoteAndCommentFragment
    }
    isLinkedDocument
    linkedDocument { 
      ...DocumentsFragment
    }
    notes
    audit {
      crePit
    }
    version
  }
`

export const GET_LAB_AND_INVESTIGATION = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  query GetLabAndInvestigation($tenantId: ID!, $patientId: ID!, $id: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        labAndInvestigation {
          get(id: $id) {
            ...LabAndInvestigationFragment
          }
        }
      }
    }
  }
`

export const GET_PATIENT_LAB_AND_INVESTIGATIONS = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  query GetPatientLabAndInvestigations($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        labAndInvestigation {
          list {
            ...LabAndInvestigationFragment
          }
        }
      }
    }
  }
`

export const GET_HISTORICAL_LAB_VALUES = gql`
  ${AB_LAB_RESULT_OBSERVATION_RESULT_FRAGMENT}
  query GetHistoricalLabValues($tenantId: ID!, $patientId: ID!, $resultId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        labAndInvestigation {
          historicalLabValues(resultId: $resultId) {
            ...AbLabResultObservationResultFragment
          }
        }
      }
    }
  }
`

export const UPDATE_LAB_AND_INVESTIGATION = gql`
  ${LAB_AND_INVESTIGATION_FRAGMENT}
  mutation UpdateLabAndInvestigation($tenantId: ID!, $id: ID!, $input: AbLabResultInput!) {
    tenant(id: $tenantId) {
      patient {
        labAndInvestigation {
          update(id: $id, input: $input) {
            ...LabAndInvestigationFragment
          }
        }
      }
    }
  }
`
