import React from "react";
import { Autocomplete } from "saga-library/src";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface ControlledConditionSelectProps {
  name: string
  label: string
  disabled?: boolean
  sx?: SxProps<Theme>
  map: Map<any, {label: string}>
  dataTestId: string
}

export const ControlledConditionSelect = ({
  name,
  label,
  disabled=false,
  sx,
  map,
  dataTestId
}: ControlledConditionSelectProps) => {
  const { control } = useFormContext();
  const options = Array.from(map.keys())

  return <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange : controlledOnChange}, fieldState: {error} }) => (
      <Autocomplete
        dataTestId={dataTestId}
        sx={sx}
        value={value}
        name={name}
        label={label}
        options={options}
        getOptionLabel={ (option) => map.get(option)?.label || ''}
        onChange={(e, v)=>{
          controlledOnChange(v)
        }}
        disabled={disabled}
        error={error}
        helperText={error?.message}
      />
    )
    }
  />
}