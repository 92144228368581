export const letterDefaults = {
  practitionerId: "",
  title: "",
  content: "",
  letterDate: new Date(),
  letterTemplateId: "",
  notes: "",
  recipientIds: [],
  recipients:[],
  version: "0"
}