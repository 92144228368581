import { Section, Form } from "saga-library/src";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { IdentifierDetails } from "./components/IdentifierDetails";
import { ContactDetails } from "./components/ContactDetails";
import { AddressDetails } from "./components/AddressDetails";
import { AdministrationDetails } from "./components/AdministrationDetails";
import React, { useEffect } from "react";
import { schema } from "../../util/validation";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { usePatientProfileContext } from "../../providers/PatientProfileProvider";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { PersonalDetails } from "./components/PersonalDetails";
import { PatientDetailsColumn } from "./components/PatientDetailsColumn";
import { PatientDetailsContainer } from "./components/PatientDetailsContainer";
import { PatientProfileType } from "../../PatientTypes";

const FormName = "patient-profile-form"
const SectionTitle = "Profile"

export const PatientProfileDefaults = {
  version: "0",
  ...PersonalDetails.defaults,
  ...ContactDetails.defaults,
  ...AddressDetails.defaults,
  ...AdministrationDetails.defaults,
  ...IdentifierDetails.defaults,
}

export function subsetData(patientDetails, defaults): PatientProfileType {
  let patientProfile = defaults;
  for (let key in patientDetails) {
    if (key in patientProfile) {
      patientProfile[key] = patientDetails[key];
    }
  }
  return patientProfile;
}

export const PatientProfile = ChartPanelHOC(SectionTitle, (props) => {
  const { profileData } = usePatientProfileContext()
  return profileData && <PatientProfilePanel profileData={subsetData(profileData, PatientProfileDefaults)} {...props} />
})


const PatientProfilePanel = ({ profileData, ...props }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { updatePatientProfile } = usePatientProfileContext()

  const formMethods = useForm<PatientProfileType>({
    values: profileData,
    schema: schema,
  })

  const {
    reset,
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FormName)
    return () => enableNavigationPrompt(false, FormName)
  }, [Object.keys(dirtyFields).length])

  useEffect( () => {
    reset(profileData)
  }, [JSON.stringify(profileData)])

  const onSubmit = handleSubmit(async (data, event) => {
    if (event && event.target.name !== FormName) {
      return
    }
    await updatePatientProfile(data, () => {
      reset({}, { keepValues: true })
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Form
        autoComplete={'false'}
        name={FormName}
        id={FormName}
        onSubmit={onSubmit}
        style={{
          height:'100%',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}
      >
        <Section.Column
          sx={{ flex: "1 1 100%" }}
          header={<PatientSectionHeader formName={FormName} dataTestId={"patient-header"} sectionLabel={SectionTitle} submitting={isSubmitting} />}
        >
          <PatientDetailsContainer sx={{ pr: 1 }}>
            <PatientDetailsColumn>
              <PersonalDetails.Form dataTestId={"patient-personalDetails"} />
              <IdentifierDetails.Form dataTestId={"patient-identifierDetails"}/>
            </PatientDetailsColumn>
            <PatientDetailsColumn>
              <ContactDetails.Form dataTestId={"patient-contactDetails"} />
            </PatientDetailsColumn>
            <PatientDetailsColumn>
              <AddressDetails.Form dataTestId={"patient-addressDetails"}/>
              <AdministrationDetails.Form dataTestId={"patient-administrationDetails"}/>
            </PatientDetailsColumn>
          </PatientDetailsContainer>
        </Section.Column>
      </Form>
    </FormProvider>
  )
}




