import React from "react";
import { Box, useTheme } from "@mui/material";
import { TableListCellConfig, TableListRowConfig, Typography } from "saga-library/src";
import moment from "moment-timezone";
import { PrescriptionStatus, PrescriptionStatusMap, PrescriptionType } from "../../../../types/Prescription";
import { useFormattedDate } from "../../../../hooks/FormattedDate";
import { usePrescriptionUtils } from "./components/PrescriptionUtil";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";

function FormatRowData(prescription: PrescriptionType): TableListCellConfig[] {
  const theme = useTheme()
  const { buildSummary } = usePrescriptionUtils()
  const today = moment().startOf('day')
  const startDate = moment(prescription.startDate)
  const formattedDate = useFormattedDate(startDate)
  const displayDate = startDate.clone().startOf('day').isSame((today)) ? "Today" : formattedDate

  const dosageSummary = buildSummary(prescription)

  let backgroundColour = "white"
  if (prescription.status === PrescriptionStatus.DRAFT) {
    backgroundColour = theme.palette.chart.yellowTag
  }
  if (prescription.status === PrescriptionStatus.ACTIVE) {
    backgroundColour = theme.palette.chart.greenTag
  }

  return [
    {
      data: PrescriptionStatusMap.get(prescription?.status!)!.order.toString(),
      children:
        <Box
          sx={{
            borderRadius: 4,
            width: 'fit-content',
            px: 1,
            backgroundColor: backgroundColour,
            color: theme.palette.primary.contrastText,
            display:'flex',
            flexDirection:'column',
            paddingTop: "6px",
            paddingBottom: "6px",
            outline: prescription.status === PrescriptionStatus.INACTIVE ? `1px solid ${theme.palette.greys.light}` : undefined
          }}
        >
          <Typography variant={'body2'} fontWeight={'500'} color={prescription.status === PrescriptionStatus.INACTIVE ? theme.palette.greys.light : theme.palette.greys.dark}>
            {PrescriptionStatusMap.get(prescription?.status!)!.label}
          </Typography>
        </Box>
    },
    {
      data: startDate.unix().toString(),
      children:
        <Typography variant={'body2'}>
          {displayDate}
        </Typography>
    },
    {
      children:
        <Box display={'flex'} sx={{ flexDirection:"column" }} gap={0}>
          <Typography lineclamp={1} variant={'body2'} >
            {prescription.drug.name}
          </Typography>
          <Typography lineclamp={1} variant={'p3'} >
            {dosageSummary}
          </Typography>
        </Box>
    },
    {
      children:
        <Typography variant={'body2'}>
          {prescription.practitionerName}
        </Typography>
    },
  ]
}

export const PatientPrescriptionRow = (prescription: PrescriptionType, navigate, onClick): TableListRowConfig => {
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id, patient_id } = useParams()
  
  const onRowClick = () => {
    onClick()
    navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions/p/${prescription.id}`, tenant_id))
  }
  return ({
    onClick: onRowClick,
    rowData: FormatRowData(prescription),
    key: prescription.id!
  })
}