import React, { useCallback } from 'react'
import { Autocomplete } from "saga-library/src";
import { ExtendedFamilyRelationship, formatEnumToSentenceCase } from '../../../../types/FamilyHistory'
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'

export interface ExtendedFamilyRelationshipSearchProps {
  name: string
  dataTestId: string
  placeholderText?: string
  label?: string
  sx?: SxProps<Theme>
  params: GridRenderEditCellParams
}

export const ExtendedFamilyRelationshipSearch = ({
  name,
  dataTestId,
  placeholderText='',
  label = '',
  params,
  sx
}: ExtendedFamilyRelationshipSearchProps) => {

  const apiRef = useGridApiContext();
  const handleChange = useCallback((event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    event.stopPropagation();
    apiRef.current.setEditCellValue({ id: params.id, field: params.field, value: newValue });
  }, [params.id, params.field]);

  const getValue = useCallback(() => {
    if (params.value){
      if (!params.value.label) {
        return {label: formatEnumToSentenceCase(params.value), value: params.value}
      }
      return params.value;
    }
    return null
  }, [params.value]);

  const relationshipOptions = Object.values(ExtendedFamilyRelationship).map(value => (
    {label: formatEnumToSentenceCase(value), value: value})
  )

  return <Autocomplete
    dataTestId={dataTestId}
    name={name}
    label={label}
    options={relationshipOptions}
    onChange={handleChange}
    getOptionLabel={(option) => option?.label || option || ""}
    isOptionEqualToValue={(option, value) => {
      if (value.label) return option.label === value.label
      return option.label === value
    }}
    value={getValue()}
    placeholder={placeholderText}
    sx={sx}
  />
}