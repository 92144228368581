import React, { useEffect, useState } from "react";
import { Autocomplete, MultiSelect, Spinner, Typography } from "saga-library/src";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { Controller, FieldError, useFormContext } from "saga-library/src/components/Form";
import { transform } from "../utils/identifierTransform";
import { Box, InputAdornment, SxProps, useTheme } from "@mui/material";
import { ColorBox } from "./AppointmentStateSelect";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { Theme } from "@mui/system";
import { LIST_APPOINTMENT_TYPES } from "../graphql-definitions";
import { AppointmentType } from "../types/schedule";

interface AppointmentTypeSelectProps {
  name?: string
  label?:string
  value?: string,
  onSelect?: (value) => void,
  limitToScheduleId?: string
  preferredTypes?: string[]
  disabled?:boolean
  sx?: SxProps<Theme>
  dataTestId?: string
}

const AppointmentTypeSelect = ({
  name="appointment_type_select",
  label="Appointment type",
  value,
  onSelect,
  limitToScheduleId,
  preferredTypes,
  disabled=false,
  sx,
  dataTestId
}:AppointmentTypeSelectProps) => {
  const { tenant_id } = useParams()
  const [ appointmentTypeList, setAppointmentTypeList ] = useState<AppointmentType[]>([])
  const [ showMore, setShowMore ] = useState<boolean>(false)
  const [ defaultValue, setDefaultValue ] = useState<string | undefined>(preferredTypes && preferredTypes.length === 1 ? preferredTypes[0] : undefined)
  const { showErrorAlert } = useAlerts()
  const theme = useTheme()

  useEffect(()=>{
    filterAppointmentTypes()
  }, [limitToScheduleId, preferredTypes, showMore])

  const { loading, data } = useQuery(LIST_APPOINTMENT_TYPES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve appointment types.')
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      filterAppointmentTypes()
    }
  })

  const filterAppointmentTypes = ()=> {
    const allTypes = _get(data, "tenant.schedule.type.listTypes", [])
    let scheduleTypes:any[] = []
    if (limitToScheduleId) {
      scheduleTypes = allTypes.filter(t => t.allSchedules || (t.schedules.findIndex(s => s.id === limitToScheduleId) !== -1))
    } else {
      scheduleTypes = allTypes
    }

    let tempTypes:any[] = []
    if(preferredTypes && preferredTypes.length > 0){
        const preferredTypeList = scheduleTypes.filter(t => preferredTypes?.includes(t.id)).sort((a, b) => a.name.localeCompare(b.name))
        const remainderTypes = scheduleTypes.filter(t => !preferredTypes?.includes(t.id)).sort((a, b) => a.name.localeCompare(b.name))

        tempTypes = preferredTypeList

        if (showMore) {
          tempTypes.push(...remainderTypes)
        } else if(tempTypes.length < allTypes.length) {
          tempTypes.push({ id: "showMore" })
        }
    } else {
      tempTypes = scheduleTypes
    }

    setAppointmentTypeList(tempTypes)
  }

  const AppointmentTypeSelectOption = ({props, data, dataTestId = ''}) => {
    if(data.id === "showMore" && !showMore){
      return <Box data-testid={dataTestId} {...props} onClick={()=>{
        setShowMore(true)
      }}>
        <Typography color={theme.palette.greys.light}> show more... </Typography>
      </Box>
    }

    return (
      <Box display={"flex"} data-testid={dataTestId} {...props} >
        <ColorBox color={data.color}/>
        <Typography> {data.name} </Typography>
        <Box flex={"1 1 auto"} sx={{ display: "flex", alignItems:"center", justifyContent:"flex-end" }}>
          {data.inPerson && <PersonIcon sx={{ fontSize: '16px', color:theme.palette.greys.dark}}/>}
          {data.byPhone && <CallOutlinedIcon sx={{ fontSize: '16px', color:theme.palette.greys.dark}}/>}
          {data.videoCall && <VideocamOutlinedIcon sx={{ fontSize: '18px', color:theme.palette.greys.dark }}/>}
        </Box>
      </Box>
    )
  }
  const transformedValue = transform.output(value === undefined ? defaultValue : value, appointmentTypeList)
  return (
    <Autocomplete
      dataTestId={dataTestId}
      sx={{ width: "100%", ...sx }}
      value={transformedValue}
      name={name}
      label={label}
      options={appointmentTypeList}
      getOptionLabel={ (option) => `${option.name}`}
      onChange={(e, v)=>{
        if(onSelect) {
          onSelect(v)
        }
      }}
      loading={loading}
      OptionComponent={AppointmentTypeSelectOption}
      isOptionEqualToValue={(o, v) => {
        return (
          o.id === v.id
        )
      }}
      InputProps={{
        startAdornment: transformedValue && (
          <InputAdornment position="start" sx={{ mr: 0, pl: 1 }}>
            <ColorBox color={transformedValue.color} />
          </InputAdornment>
        )
      }}
      inputSx={{
        "& .MuiAutocomplete-input.MuiInputBase-input.MuiInputBase-inputAdornedStart": {
          pl: 0
        }
      }}
      disabled={disabled}
    />)
}

interface ControlledAppointmentTypeSelectProps {
  name: string
  label?: string
  onSelect?: (v) => void
  onSelectObject?: (v) => void
  limitToScheduleId?: string
  disabled?: boolean
  sx?: SxProps<Theme>
  preferredTypes?: string[]
  dataTestId?: string
}

export const ControlledAppointmentTypeSelect = ({
  name,
  label='Appointment type',
  onSelect,
  onSelectObject,
  limitToScheduleId,
  disabled=false,
  preferredTypes,
  sx,
  dataTestId
}: ControlledAppointmentTypeSelectProps) => {
  const { control } = useFormContext();

  return <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange : controlledOnChange}, fieldState: {error} }) => (
        <AppointmentTypeSelect
          dataTestId={dataTestId}
          onSelect={(v)=>{
            controlledOnChange(transform.input(v))
            if(onSelect) {
              onSelect(transform.input(v))
            }
            if(onSelectObject) {
              onSelectObject(v)
            }
          }}
          value={value}
          label={label}
          limitToScheduleId={limitToScheduleId}
          disabled={disabled}
          preferredTypes = {preferredTypes}
          sx={sx}
        />
      )
    }
  />
}

export default AppointmentTypeSelect

type AppointmentTypeOption = {
  id: string,
  name: string
}

interface AppointmentTypeMultiSelectProps {
  value?: Array<AppointmentTypeOption> | null
  onChange: (string) => void
  sx?: SxProps<Theme>
  errors?: FieldError
  helperText?: string
  limitToScheduleId?: string
  dataTestId?: string
}
export const AppointmentTypeMultiSelect = ({
  value,
  onChange,
  sx,
  errors,
  helperText,
  limitToScheduleId,
  dataTestId
}: AppointmentTypeMultiSelectProps) => {
  const { tenant_id } = useParams()
  const [selectedIds, setSelectedIds] = useState<Array<AppointmentTypeOption>>([])

  const { loading, data } = useQuery(LIST_APPOINTMENT_TYPES, {
    fetchPolicy: 'cache-first',
    variables: {tenantId: tenant_id}
  })

  const appointmentTypes = _get(data, 'tenant.schedule.type.listTypes', [])

  const options = () => {
    let scheduleTypes = appointmentTypes
    if(limitToScheduleId){
      scheduleTypes = scheduleTypes.filter(t => t.allSchedules || (t.schedules.findIndex(s => s.id === limitToScheduleId) !== -1))
    }
    return[
      ...scheduleTypes.map((appointmentType) => {
        return {
          key: appointmentType.id,
          name: appointmentType.name,
          id: appointmentType.id
        }
      })
    ]
  }

  useEffect(() => {
    if (value) {
      const tempSelectedIds : Array<AppointmentTypeOption> = []
      value.forEach((appointmentType) => {
          tempSelectedIds.push(appointmentType)
        })
      setSelectedIds(tempSelectedIds)
    }
  }, [value])

  if (loading) {
    return <Spinner size={'xs'}/>
  }

  return (
    <Box
      sx={sx}
      >
      <MultiSelect
        dataTestId={dataTestId}
        placeholder={'Select appointment types'}
        options={options()}
        onChange={onChange}
        value={selectedIds}
        error={errors}
        helperText={helperText}
        />
    </Box>
  )
}

export const ControlledAppointmentTypeMultiSelect = ({ name, limitToScheduleId, dataTestId }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({field: { value, onChange }, fieldState: {error}}) => {
        return (
          <AppointmentTypeMultiSelect
            dataTestId={dataTestId}
            onChange={onChange}
            value={value}
            errors={error}
            helperText={error?.message}
            limitToScheduleId={limitToScheduleId}
          />
        )
      }}
    />
  )
}