import React from 'react'
import {
  ResultOptionsWrapper
} from "saga-library/src";
import Box from "@mui/material/Box";
import { EntryDescription, EntryTitle } from "saga-library/src";
import { ReferralPractitioner } from "../../../types/settings";
import { formatPractitionerId } from "../../PractitionerMaskedTextField";
import { practitionerDisplayName } from "saga-library/src/util/formatting";

interface ReferralPractitionerResultProps {
  referralPractitioner: ReferralPractitioner
  searchText: string
  dataTestId: string
}

export const ReferralPractitionerResult = ({
  referralPractitioner,
  searchText,
  dataTestId
}: ReferralPractitionerResultProps) => {
  return (
    <ResultOptionsWrapper
      dataTestId={dataTestId}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box>
          <EntryTitle
            title={practitionerDisplayName(referralPractitioner.firstName, referralPractitioner.lastName)}
            searchText={searchText}
            dataTestId={`${dataTestId}-name`}
          />
          <EntryDescription
            text={formatPractitionerId(referralPractitioner.practitionerId)}
            searchText={searchText}
            dataTestId={`${dataTestId}-identifier`}
          />
          <EntryDescription
            text={referralPractitioner.disciplineDescription}
            searchText={''}
            dataTestId={`${dataTestId}-discipline`}
          />
        </Box>
        <Box>
          <EntryDescription
            text={referralPractitioner.label}
            searchText={''}
            dataTestId={`${dataTestId}-label`}
          />
          <EntryDescription
            text={referralPractitioner.street1}
            searchText={''}
            dataTestId={`${dataTestId}-street`}
          />
          <EntryDescription
            text={(referralPractitioner.city ? referralPractitioner.city +', ' : "") + referralPractitioner.province}
            searchText={''}
            dataTestId={`${dataTestId}-city-province`}
          />
        </Box>
      </Box>
    </ResultOptionsWrapper>
  )
}
