import React from "react";
import { Select } from "saga-library/src";
import { LocationCode } from "../../utils/ABClaimConstants";
import { MenuItem, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface LocationCodeSelectProps {
  name?: string
  label?: string
  sx?: SxProps<Theme>
  onChange?: () => void
  warning?: boolean
  autoFocus?: boolean
  inputLabelStyle?: object
  disabled?: boolean
  dataTestId?: string
}

export const LocationCodeSelect = ({
  name = 'locationCode',
  label = 'Location',
  sx={},
  onChange,
  autoFocus,
  inputLabelStyle,
  disabled = false,
  dataTestId
}: LocationCodeSelectProps) => {
  return (
    <Select
      dataTestId={dataTestId}
      label={label}
      defaultValue={''}
      name={name}
      sx={sx}
      inputLabelStyle={inputLabelStyle}
      onChange={() => {
        if (onChange) onChange()
      }}
      autoFocus={autoFocus}
      disabled={disabled}
    >
      <MenuItem data-testid={`${dataTestId}-menuItem-0`} value={''}>None</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-1`} value={LocationCode.HOME}>Home</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-2`} value={LocationCode.SCHL}>School</MenuItem>
      <MenuItem data-testid={`${dataTestId}-menuItem-3`} value={LocationCode.OTHR}>Other</MenuItem>
    </Select>
  )
}

export default LocationCodeSelect

