import React, { useState } from 'react'
import {
  AbClaimAcceptCode,
  ABClaimActionCode,
  ABClaimAssessmentOutcome,
  AbClaimStatus
} from '../../../../utils/ABClaimConstants'
import { Box, Chip, Tooltip, useTheme } from '@mui/material'
import { Button, ButtonDropdown, Section, Typography } from 'saga-library/src'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { useAssessedClaimsContext } from '../../providers/AssessedClaimsProvider'
import {
  CancelResubmissionDialog,
  CancelWriteOffDialog,
  DeletionConfirmationDialog,
  ReassessmentConfirmationDialog,
  WriteOffConfirmationDialog
} from './AssessedClaimDialogs'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { ReadOnlyFormActionsMask } from "../../../../components/ReadOnlyFormActionsMask";
import { getActionCodeDescription } from '../../util/getActionCodeDescription'




export const AssessedClaimHeader = ({
  getValues,
  resubmitWithCode
}) => {
  const theme = useTheme()
  const claim = getValues()
  const assessmentDate = useFormattedDate(claim.assessmentDate)
  const showResubmissionRefusedChip = !claim.acceptCode && claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED && claim.actionCode === ABClaimActionCode.CHANGE

  const [openReassessmentDialog, setOpenReassessmentDialog] = useState(false)
  const [openWriteOffDialog, setOpenWriteOffDialog] = useState(false)
  const [openCancelWriteOffDialog, setOpenCancelWriteOffDialog] = useState(false)
  const [openCancelResubmissionDialog, setOpenCancelResubmissionDialog] = useState(false)
  const [openDeletionDialog, setOpenDeletionDialog] = useState(false)

  const { writeOffClaim, cancelWriteOff, acceptAdjustment, cancelResubmission } = useAssessedClaimsContext()


  const ResubmitOptionDropdown = () => (
    <ButtonDropdown
      title={'RESUBMIT'}
      variant={'outlined'}
      options={[
        {
          name: 'FOR REASSESSMENT',
          onClick: () => setOpenReassessmentDialog(true),
          tooltip: 'Select this option when text has been added but no other changes have been made a claim.'
        },
        {
          name: 'AS CHANGED CLAIM',
          onClick: () => resubmitWithCode(ABClaimActionCode.CHANGE),
          tooltip: 'Select this option when fields other than Text have been changed for a claim.'
        },
        {
          name: 'FOR DELETION',
          onClick: () => setOpenDeletionDialog(true),
          tooltip: 'Select this option to request that a claim is reversed by AHCIP.'
        }
      ]}
      sx={{ marginRight: '16px' }}
    />
  )


  const AssessedClaimStateHeader = () => {
    if (claim.acceptCode === AbClaimAcceptCode.ACCEPT) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
            <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{ m: 'auto' }}>
              ACCEPTED ON {assessmentDate}
            </Typography>
          </Box>
          <ResubmitOptionDropdown />
        </Box>
      )
    }
    if (!!claim.acceptCode) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
            <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{ m: 'auto' }}>
              {claim.acceptCode === AbClaimAcceptCode.WRITE_OFF_OR_UNCOLLECTABLE ? 'UNCOLLECTABLE' : claim.acceptCode.replaceAll('_', ' ')}
            </Typography>
          </Box>
          <Button name="cancelWriteoff" variant={'outlined'} onClick={() => setOpenCancelWriteOffDialog(true)}>
            CANCEL WRITE OFF
          </Button>
        </Box>
      )
    }

    if (claim.claimStatus === AbClaimStatus.RESUBMITTED) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {claim.assessmentOutcome === 'APPLIED' && claim.adjusted &&
            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', mr: 1 }}>
              <Typography variant={'body1'} fontWeight={'bold'} color={theme.palette.greys.medium} sx={{ m: 'auto' }}>
                {getActionCodeDescription(claim.actionCode)}
              </Typography>
            </Box>
          }
          <Button
            name="cancelResubmission"
            variant={'outlined'}
            onClick={() => setOpenCancelResubmissionDialog(true)}
          >
            Cancel resubmission
          </Button>
        </Box>
      )
    }
    if (claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED && claim.actionCode !== ABClaimActionCode.CHANGE) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            name="writeOff"
            variant={'outlined'}
            onClick={() => setOpenWriteOffDialog(true)}
            sx={{
              mr: 1
            }}
          >
            WRITE OFF
          </Button>
          <Tooltip title="This claim will be sent back to AHCIP as a new claim with a new claim number."
                   placement="bottom">
            <Button name="resubmit" onClick={() => resubmitWithCode(ABClaimActionCode.ADD)}>
              RESUBMIT
            </Button>
          </Tooltip>
        </Box>
      )
    }

    if (claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Button name="accept" variant={'outlined'} onClick={() => acceptAdjustment(claim.id)} sx={{ m: 'auto', mr: 1 }}>
            ACCEPT
          </Button>
          <ResubmitOptionDropdown />
        </Box>
      )
    }

    if (claim.assessmentOutcome === ABClaimAssessmentOutcome.APPLIED) {
      if (claim.adjusted) {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button name="accept" variant={'outlined'} onClick={() => acceptAdjustment(claim.id)} sx={{ m: 'auto', mr: 1 }}>
              ACCEPT
            </Button>
            <ResubmitOptionDropdown />
          </Box>
        )
      } else {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <ResubmitOptionDropdown />
          </Box>
        )
      }
    }

    return null
  }


  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Section.Header>
        {getValues('claimIdentifier')}
      </Section.Header>

      <Box display={'flex'} flexDirection={'column'}>
        {
          showResubmissionRefusedChip &&
          <Chip
            label="RESUBMISSION REFUSED"
            sx={theme => ({
              backgroundColor: theme.palette.error.main,
              color: 'white'
            })}
          />
        }
      </Box>

      <ReadOnlyFormActionsMask requiredType={PermissionType.Billing} requiredPermission={Permission.READWRITE}>
        <AssessedClaimStateHeader/>
      </ReadOnlyFormActionsMask>

      <ReassessmentConfirmationDialog
        open={openReassessmentDialog}
        setOpen={setOpenReassessmentDialog}
        onAccept={() => resubmitWithCode(ABClaimActionCode.REASSESS)}
      />

      <WriteOffConfirmationDialog
        open={openWriteOffDialog}
        setOpen={setOpenWriteOffDialog}
        onAccept={(action) => writeOffClaim(claim.id, action)}
      />

      <DeletionConfirmationDialog
        open={openDeletionDialog}
        setOpen={setOpenDeletionDialog}
        onAccept={() => resubmitWithCode(ABClaimActionCode.DELETE)}
      />

      <CancelWriteOffDialog
        open={openCancelWriteOffDialog}
        setOpen={setOpenCancelWriteOffDialog}
        onAccept={() => cancelWriteOff(claim.id)}
      />

      <CancelResubmissionDialog
        open={openCancelResubmissionDialog}
        setOpen={setOpenCancelResubmissionDialog}
        onAccept={() => cancelResubmission(claim.id)}
      />
    </Box>
  )
}