import { PractitionerDetailsType } from "../../apps/settings/Practitioners/components/PractitionerDetails";
import { AppointmentType } from "./AppointmentType";
import { Location } from "../Location";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { BillingProfile } from "../settings/BillingProfile";
import { AppointmentRoom } from "./AppointmentRoom";

export enum ScheduleLength {
  DAY = 'day',
  WEEK = 'week'
}

export enum BlockType {
  BOOKING_PREFERENCE = 'BOOKING_PREFERENCE',
  EVENT = 'EVENT',
  CLOSED = 'CLOSED'
}

export type ScheduleType = {
  id: string
  practitionerLastName: string,
  practitionerFirstName: string,
  practitionerId: string,
  location: Location
  locationId: string
  hasFutureAppointments: boolean
}

export type ScheduleSettingsType = {
  id: string | null
  practitionerId: string | null
  locationId:string | null
  defaultBillingProfileId: string | null
  defaultBillingProfile: BillingProfile | null
  defaultAppointmentTypeId: string | null
  defaultAppointmentType: AppointmentType | null
  timeScale: number
  endDate?: string | null,
  sundayOpen: boolean
  sundayStart?: string | null,
  sundayEnd?: string | null,
  mondayOpen: boolean
  mondayStart?: string | null,
  mondayEnd?: string | null,
  tuesdayOpen: boolean | null,
  tuesdayStart?: string | null,
  tuesdayEnd?: string | null,
  wednesdayOpen: boolean | null,
  wednesdayStart?: string | null,
  wednesdayEnd?: string | null,
  thursdayOpen: boolean | null,
  thursdayStart?: string | null,
  thursdayEnd?: string | null,
  fridayOpen: boolean | null,
  fridayStart?: string | null,
  fridayEnd?: string | null,
  saturdayOpen: boolean | null,
  saturdayStart?: string | null,
  saturdayEnd?: string | null,
  active: boolean
  location: Location
  practitioner:PractitionerDetailsType
}

export interface ScheduleViewInterface {
  schedules: string[]
  date: string
  view: ScheduleLength,
}

export interface resourceType {
  id: string
  name: string
  location: string
}

export interface ScheduleLayoutInterface {
  resources?: resourceType[],
  defaultClosedHours:MbscCalendarEvent[],
  scheduleEndEvents:MbscCalendarEvent[],
  rooms?: AppointmentRoom[]
}

export interface ScheduleDataInterface {
  practitionerName: string | undefined
  practitionerId: string,
  defaultBillingProfileId?: string,
  defaultAppointmentTypeId?: string,
  appointmentTypes: AppointmentType[],
  id: string,
  locationId:string,
  endDate: string,
  version: string
}

export interface  AppointmentSlotSearchInput {
  startDate: Date,
  scheduleId: string,
  slotLength: number,
  appointmentLength: number,

  appointmentTypeId?: string
  appointmentPractitionerId: string
  showPreferredTimes?: boolean
}