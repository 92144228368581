import { Box } from "@mui/material";
import { AddButton, Typography, RemoveButton } from "saga-library/src";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "saga-library/src/components/Form";
import { PharmacySearch } from "./PharmacySearch";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined';
import CallIcon from '@mui/icons-material/Call';
import { Pharmacy } from "../../../types";
import { fullAddress } from "saga-library/src/util";

export type PharmacyArrayProps = {
  name: string
  dataTestId: string
}

export const PharmacyArray = ({name, dataTestId}: PharmacyArrayProps) => {
  const { control, setValue, getValues, watch } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: name,
    control,
  })

  const [autoFocusOnOpen, setAutoFocusOnOpen] = useState<boolean>(false)
  const [displayPharmacies, setDisplayPharmacies] = useState<JSX.Element[]>()
  const watchFieldArray = watch(name)

  useEffect(() => {
    if (fields.length === 0) {
      setValue(name, [''], { shouldDirty: false });
    }
  }, [fields])

  useEffect(() => {
    setDisplayPharmacies(fields.map((item, index) => {
      let pharmacy = getValues(`${name}[${index}]`)
      if (pharmacy && pharmacy.id) {
        return (
          <Box
            key={`${item.id}-box`}
            sx={{
              p: 1,
              borderRadius: 2,
              border: '1px solid rgba(32, 41, 49, 0.32)',
              flexShrink: 0,
              alignSelf: 'stretch',
              my: 1
            }}
          >
            <PharmacyBlock
              dataTestId={`${dataTestId}-${index}-box`}
              pharmacy={pharmacy}
              removePharmacy={() => remove(index)}
            />
          </Box>
        )
      }
      return (
        <Box key={`${item.id}-box`}>
          <PharmacySearch
            dataTestId={`${dataTestId}-${index}-search`}
            key={item.id}
            label={'Pharmacy'}
            name={`${name}.${index}`}
            selectedOptions={watchFieldArray}
            autoFocusOnOpen={autoFocusOnOpen}
            append={append}
          />
        </Box>
      )
    }))
  }, [JSON.stringify(watchFieldArray), JSON.stringify(fields)])

  return (
    <>
      {displayPharmacies}
      <AddButton
        dataTestId={dataTestId}
        label={'Add pharmacy'}
        onClick={() => {
          append("")
          setAutoFocusOnOpen(true)
        }}
      />
    </>
  )
}

const PharmacyTag = ({ children }) => {
  return (
    <Typography variant={'p2'} sx={{ color: (theme) => theme.palette.chart.greyTag }}>
      {children}
    </Typography>
  )
}

type PharmacyBlockProps = {
  pharmacy: Pharmacy
  removePharmacy?: () => void
  dataTestId?: string
}

export const PharmacyBlock = ({pharmacy, removePharmacy, dataTestId}: PharmacyBlockProps) => {
  const address = fullAddress(pharmacy)
  const { faxNumber, name, phoneNumber } = pharmacy

  const iconStyle = { fontSize: "20px", verticalAlign: "middle", paddingRight: "4px" }

  return (
    <Box data-testid={dataTestId} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
      <Box>
        <Typography variant={'body1'} lineclamp={1}>{name}</Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'2px'}
        >
          {address && <PharmacyTag>{fullAddress(pharmacy)}</PharmacyTag>}
          {(faxNumber || phoneNumber) && (
            <Box display={'flex'} flexDirection={'row'} gap={1}>
              {faxNumber && <PharmacyTag><FaxOutlinedIcon sx={iconStyle} />{phoneNumberMask(faxNumber)}</PharmacyTag>}
              {phoneNumber && <PharmacyTag><CallIcon sx={iconStyle} />{phoneNumberMask(phoneNumber)}</PharmacyTag>}
            </Box>
          )}
        </Box>
      </Box>
      {removePharmacy &&
        <RemoveButton
          dataTestId={dataTestId}
          onClick={removePharmacy}
        />
      }
    </Box>
  )
}