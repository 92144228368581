import { gql } from "@apollo/client";
import { DOCUMENTS_FRAGMENT } from "./LinkedDocumentQueries";

export const FORM_FRAGMENT = gql`
    ${DOCUMENTS_FRAGMENT}
    fragment FormFragment on Form {
        id
        name
        description        
        formDate
        practitionerId
        practitioner{
            id
            firstName
            lastName
        }
        locationId
        sharedFormId
        csvData
        version
        isLinkedDocument
        linkedDocument {
            ...DocumentsFragment
        }
        audit {
          crePit
        }
    }
`


export const GET_PATIENT_FORMS = gql`
    ${FORM_FRAGMENT}
    query GetPatientForms($patientId: ID!, $tenantId: ID!) {
        tenant(id: $tenantId) {
            patient(id: $patientId) {
                form {
                    list {
                        ...FormFragment
                    }
                }
            }
        }
    }
`


export const CREATE_FORM = gql`
    ${FORM_FRAGMENT}
    mutation CreateForm($tenantId: ID!, $input: FormInput!) {
        tenant(id: $tenantId) {
            patient {
                form {
                    create(input: $input) {
                        ...FormFragment
                    }
                }
            }
        }
    }
`

export const UPDATE_FORM = gql`
    ${FORM_FRAGMENT}
    mutation UpdateForm($tenantId: ID!, $formId: ID!, $input: FormInput!) {
        tenant(id: $tenantId) {
            patient {
                form {
                    update(formId: $formId, input: $input) {
                        ...FormFragment
                    }
                }
            }
        }
    }
`

export const DELETE_FORM = gql`
    mutation DeleteForm($tenantId: ID!, $formId: ID!, $version: String!) {
        tenant(id: $tenantId) {
            patient {
                form {
                    delete(formId: $formId, version: $version)
                }
            }
        }
    }
`
