import { AllergySeverityMap, AllergyType, ReactionTypeMap } from '../../../../../types/Allergy'
import { nullOrUndefinedComparison } from 'saga-library/src/components/TableList/TableSortUtils'
import moment from 'moment-timezone'

export const AllergySortPredicate = (a: AllergyType, b: AllergyType) : number => {
  const getReactionOrder = (allergy: AllergyType) => {
    return allergy.reactionType ? ReactionTypeMap.get(allergy.reactionType)!.order : null
  }

  const getSeverityOrder = (allergy: AllergyType) => {
    return allergy.severity ? AllergySeverityMap.get(allergy.severity)!.order : null
  }

  const aReactionOrder = getReactionOrder(a)
  const bReactionOrder = getReactionOrder(b)
  const aSeverityOrder = getSeverityOrder(a)
  const bSeverityOrder = getSeverityOrder(b)

  return (nullOrUndefinedComparison(aReactionOrder, bReactionOrder) ?? aReactionOrder! - bReactionOrder!)
    || (nullOrUndefinedComparison(aSeverityOrder, bSeverityOrder) ?? aSeverityOrder! - bSeverityOrder!)
    || (nullOrUndefinedComparison(a.audit?.crePit, b.audit?.crePit) ?? moment(b.audit?.crePit).unix() - moment(a.audit?.crePit).unix())
    || 0
}