import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { AppointmentRoom, AppointmentRoomInput } from "../../../../types/schedule";
import { AppointmentRoomForm } from "./AppointmentRoomForm";
import { DeleteButton, DialogV2 } from "saga-library/src";
import { schema } from "../util/appointmentRoomValidation";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useParams } from "react-router-dom";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { LIST_APPOINTMENT_ROOMS, DELETE_APPOINTMENT_ROOM, UPDATE_APPOINTMENT_ROOM } from "../../../../graphql-definitions";

const FORM_NAME = "edit_appointment_room_form"

interface EditAppointmentRoomModalProps {
  open: boolean
  onClose: () => void
  appointmentRoom: AppointmentRoom | null
}

export const EditAppointmentRoomModal = ({
  open,
  onClose,
  appointmentRoom
}: EditAppointmentRoomModalProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [updateAppointmentRoom] = useMutation(UPDATE_APPOINTMENT_ROOM, {
    onCompleted: () => {
      showSuccessAlert('Appointment room has been saved.')
      reset(originalDefault)
      onClose()
    },
    onError: (error) => {
      showErrorAlert('This room can’t be edited because it contains a patient.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const [ deleteAppointmentRoom ] = useMutation(DELETE_APPOINTMENT_ROOM, {
    onCompleted: () => {
      showSuccessAlert('Appointment room deleted.')
      reset(originalDefault)
      onClose()
    },
    onError: (error) => {
      showErrorAlert('This room can’t be delete because it contains a patient.')
    }
  })

  const originalDefault = {
    name: appointmentRoom?.name,
    locationId: appointmentRoom?.location.id,
    practitionerId: appointmentRoom?.practitioner?.id,
    version: appointmentRoom?.version,
  }

  const formMethods = useForm<AppointmentRoomInput>({
    defaultValues: originalDefault,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    if (appointmentRoom) {
      reset(originalDefault)
    }
  }, [appointmentRoom])

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
      reset()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    await updateAppointmentRoom({
      variables: {
        tenantId: tenant_id,
        roomId: appointmentRoom?.id,
        roomInput: data
      },
    })
  })

  const onDelete = () => {
    deleteAppointmentRoom({
      variables: {
        tenantId: tenant_id,
        roomId: appointmentRoom?.id,
        version: appointmentRoom?.version
      },
      update: (cache) => {
        cache.updateQuery({
          query: LIST_APPOINTMENT_ROOMS,
          variables: { tenantId: tenant_id },
        }, (data) => ({
          tenant: {
            schedule: {
              room: {
                listRooms: [...data.tenant.schedule.room.listRooms.filter(r => r.id !== appointmentRoom?.id)]
              }
            }
          }
        })
      )}
    })
  }

  return (
    <DialogV2
      title={`Edit room (${appointmentRoom?.name})`}
      open={open}
      onClose={handleClose}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={'saveAppointmentRoom'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={'editAppointmentRoomModal-save-button'}
          loading={isSubmitting}
          type={'submit'}
        >
          Save
        </PermissionButton>
      }
      headerActions={[
        <DeleteButton
          onClick={onDelete}
          dataTestId={'editAppointmentRoomModal-delete-button'}
        />
      ]}
      data-testid={'editAppointmentRoomModal-dialog'}
    >
      <FormProvider {...formMethods}>
        <AppointmentRoomForm
          onSubmit={onSubmit}
        />
      </FormProvider>
    </DialogV2>
  )
}
