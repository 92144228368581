import React from 'react'
import { PersonalDetails, PersonalDetailsType } from './PersonalDetails'
import { ContactDetails, ContactDetailsType } from './ContactDetails'
import { AddressDetails, AddressDetailsType } from "./AddressDetails";
import { IdentifierDetails, IdentifierDetailsType } from './IdentifierDetails'
import { AdministrationDetails, AdministrationDetailsType } from "./AdministrationDetails";
import { SignatureDetails, SignatureDetailsType } from "./SignatureDetails";
import { PermissionType } from "../../../../types/settings/Permission";
import { Box } from "@mui/material";

const defaults = {
  version: "0",
  ...PersonalDetails.defaults,
  ...ContactDetails.defaults,
  ...AddressDetails.defaults,
  ...IdentifierDetails.defaults,
  ...AdministrationDetails.defaults,
  ...SignatureDetails.defaults
}

const Form = () => {
  const PERMISSION_REQUIRED = PermissionType.Practitioner

  return (
    <Box display={'flex'}>
      <PractitionerSubSection>
        <PersonalDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
        <ContactDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>
      <PractitionerSubSection>
        <AddressDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>
      <PractitionerSubSection>
        <IdentifierDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
        <AdministrationDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
        <SignatureDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>
    </Box>
  )
}

export type PractitionerDetailsType =
  PersonalDetailsType &
  ContactDetailsType &
  AddressDetailsType &
  IdentifierDetailsType &
  AdministrationDetailsType &
  SignatureDetailsType

export const PractitionerDetails = {
  Form: Form,
  defaults: defaults,
}

export const PractitionerSubSection = ({children}) => {
  return <Box  sx={theme => ({
    mr: 1,
    flex: '1 1 100%',
    padding: theme.spacing(1 ,2),
    display: 'flex',
    flexDirection: 'column',
  })}>
    {children}
  </Box>
}
