import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { AppointmentInput } from "../../../../types/schedule";
import { AppointmentForm } from "./AppointmentForm";
import moment from "moment-timezone";
import { schema } from "../../util/appointmentValidation";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useParams } from "react-router-dom";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { ADD_NEW_APPOINTMENT } from '../../../../graphql-definitions'
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime, getScheduleViewDates, updateScheduleItemsCache } from '../../util/scheduleFunctions'
import { TempEventActionType } from "../../../../utils/ScheduleProviderUtils";
import { ScheduleEventType } from "../../../../types/schedule/ScheduleEvent";
import _get from 'lodash/get'
import shuttleBay from '../SidePanel/ShuttleBay'

const DATA_TEST_ID = "newAppointment"
const FORM_NAME = "new_appointment_form"

export const appointmentDefaults = (eventData, scheduleId, defaultAppointmentStateId, timezone, defaultAppointmentTypeId = '' ) => {
  if(!eventData){
    return {}
  }

  let localBookingPreference = ''
  if (eventData.bookingPreferenceTypeIds) {
    localBookingPreference = eventData.bookingPreferenceTypeIds[0]
  }

  return {
    version: eventData.version || "0",
    scheduleId: scheduleId,
    patientId: eventData.patient || '',
    appointmentTypeId: eventData.type?.id || localBookingPreference || defaultAppointmentTypeId,
    method: eventData.method,
    start: moment(eventData.start).tz(timezone),
    end: moment(eventData.end).tz(timezone),
    appointmentStateId: eventData.state?.id || defaultAppointmentStateId,
    reasonForVisit: eventData.reasonForVisit || '',
    notes: eventData.notes || '',
    allDay: eventData.allDay,
    itemDate: moment(eventData?.start).tz(timezone).format("YYYY-MM-DD"),
  }
}

type NewAppointmentDialogProps = {
  scheduleId: string
  onClose: () => void
  onFormChange: (formName: string, submitting: boolean, dirtyFields: any) => void
  eventData: ScheduleEventType
}

export const NewAppointment = ({scheduleId, onClose, onFormChange, eventData} : NewAppointmentDialogProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const { getScheduleData, appointmentSlot, scheduleView, tempEventHandler, displayScheduleError, shuttleBay } = useScheduleContext()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()

  const { tempEvent, clearTempEvent } = tempEventHandler
  const [ preferredAppointmentTypes, setPreferredAppointmentTypes] = useState(eventData?.bookingPreferenceTypeIds)

  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE, tenantSettingsKeys.DEFAULT_APPOINTMENT_STATE_ID])

  const scheduleData = getScheduleData(scheduleId)

  const defaultAppointmentTypeId = scheduleData?.defaultAppointmentTypeId || ''
  if (preferredAppointmentTypes && preferredAppointmentTypes.length > 0 && !preferredAppointmentTypes.includes(defaultAppointmentTypeId)) {
    setPreferredAppointmentTypes(oldPreferences => [...oldPreferences, defaultAppointmentTypeId])
  }

  const formMethods = useForm<AppointmentInput>({
    defaultValues: appointmentDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.DEFAULT_APPOINTMENT_STATE_ID.name], tenantSettings[tenantSettingsKeys.TIMEZONE.name], defaultAppointmentTypeId),
    schema: schema
  })

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  useEffect(() => {
    onFormChange(FORM_NAME, isSubmitting, dirtyFields)
  }, [isSubmitting, Object.keys(dirtyFields).length])

  const [ addNewAppointment] = useMutation(ADD_NEW_APPOINTMENT, {
    onCompleted:(data) => {
      appointmentSlot.clearSlot()
      showSuccessAlert('Appointment has been created')
      if(eventData.shuttleBay){
        shuttleBay.setUnoccupied();
      }
      if(tempEvent?.action === TempEventActionType.QUICKBOOK) {
        clearTempEvent()
      }
    },
    onError: (error) => {
      displayScheduleError(error, "Appointment")
    },
    update: (cache, { data }) => {
      const newAppointment = _get(data, 'tenant.schedule.appointment.create', null)
      const variables = {
        tenantId: tenant_id,
        scheduleId: scheduleId,
        scheduleIdList: scheduleView?.schedules,
        start: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).start).utc().toISOString(),
        end: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).end).add(1, 'day').utc().toISOString()
      }
      updateScheduleItemsCache(cache, newAppointment, variables)
    }
  })

  const onSubmit = handleSubmit(async(data, event) => {
    if (event && event.target.name !== FORM_NAME) {
      return
    }
    data.start = moment(data.itemDate + ' ' + formatTime(data.start), 'YYYY-MM-DD h:mm A')
    data.end =  moment(data.itemDate + ' ' + formatTime(data.end), 'YYYY-MM-DD h:mm A')

    await addNewAppointment({
      variables: {
        tenantId: tenant_id,
        appointmentInput: data
      }
    })
    reset()
    onClose()
  })

  return (
    <FormProvider {...formMethods}>
      <AppointmentForm
        formName={FORM_NAME}
        dataTestId={DATA_TEST_ID}
        onSubmit={onSubmit}
        preferredAppointmentTypes={preferredAppointmentTypes}
      />
    </FormProvider>
  )
}
