import { SimpleColorPicker, TableListRowConfig, Typography } from "saga-library/src";
import React from "react";
import { CheckIcon } from "../../../../components/Icons/CheckIcon";
import { CloseIcon } from "../../../../components/Icons/CloseIcon";
import SettingsRow from "../../components/SettingsRow";
import { AppointmentState, AppointmentStateCategoryMap } from '../../../../types/schedule/AppointmentState'

export const AppointmentStateRow = (appointmentState: AppointmentState, setEditAppointmentState, hasReadWrite): TableListRowConfig => {
  return ({
    onClick: hasReadWrite ? () => setEditAppointmentState(appointmentState) : null,
    key: appointmentState.id,
    rowData: [
      {
        children: [
          <SettingsRow sx={{ p: '8px 0px' }} key={'name'+appointmentState.id}>
            <Typography variant={'body1'}>{appointmentState.name}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow key={'color'+appointmentState.id}>
            <SimpleColorPicker
              color={appointmentState.color}
              disabled={true}
            />
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow sx={{ p: '8px 0px' }} key={'name'+appointmentState.category}>
            <Typography variant={'body1'}>{AppointmentStateCategoryMap.get(appointmentState.category)!.label}</Typography>
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow key={'canEnterExamRoom'+appointmentState.id}>
            {appointmentState.canEnterExamRoom ? <CheckIcon /> : <CloseIcon />}
          </SettingsRow>
        ]
      },
      {
        children: [
          <SettingsRow key={'blocksSchedule'+appointmentState.id}>
            {appointmentState.blocksSchedule ? <CheckIcon /> : <CloseIcon />}
          </SettingsRow>
        ]
      }
    ]
  })
}
