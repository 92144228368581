import { PrintIconButton } from 'saga-library/src'
import React, { useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { PrintPdfModal } from './PrintPdfModal'
import { PagesToInclude } from '../../../../types/Document'

interface LinkedDocumentsPrintButtonProps {
  documentId: string | undefined
  patientId: string | undefined
  onPrintModalOpen?: () => void,
  onPrintClicked?: (printType: number, pageRange: string, pagesToInclude: PagesToInclude) => void,
  dataTestId: string
}

export const LinkedDocumentsPrintButton = ({
  documentId,
  patientId,
  onPrintModalOpen,
  onPrintClicked,
  dataTestId,
}: LinkedDocumentsPrintButtonProps) => {
  const [printModalOpen, setPrintModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const activityTracking = useActivityTracking()

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!documentId) return
    activityTracking.logPrintActivity(UserActivityObjectType.LINKED_DOCUMENT, documentId, patientId)
    setLoading(false)
  }

  const printPdfViewer = async () => {
    setLoading(true)
    setPrintModalOpen(true)
    onPrintModalOpen?.()
  }

  const onModalClose = () => {
    setLoading(false)
    setPrintModalOpen(false)
  }

  return (
    <>
      <PrintPdfModal
        open={printModalOpen}
        onModalClose={onModalClose}
        printPdfCallback={printPdfCallback}
        onPrintClicked={onPrintClicked}
      />
      <PrintIconButton
        disabled={loading}
        onClick={printPdfViewer}
        dataTestId={`${dataTestId}-linked-documents`}
      />
    </>
  )
}