import React from 'react'
import { TextField, Section } from 'saga-library/src'
import { rules } from '../util/validation'
import { FormFragment } from "../../../../components/PermissionForm";
import { PractitionerMaskedTextField } from "../../../../components/PractitionerMaskedTextField";

export type IdentifierDetailsType = {
  id?: string
  typeId?: string
  value?: string
  index?: number
}

const defaults = {
  practitionerId: '',
  connectCareProviderId: '',
}

const Form = ({requiredPermissionType}) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Identifiers</Section.Header>
        <PractitionerMaskedTextField
          dataTestId={"identifierDetails-practitionerId"}
        />
        <TextField
          label={'Connect Care provider ID'}
          name={'connectCareProviderId'}
          inputProps={{ maxLength: rules.connectCareProviderId.max }}
          dataTestId={"identifierDetails-connectCareProviderId"}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const IdentifierDetails = {
  Form: Form,
  defaults: defaults,
}
