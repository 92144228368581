import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { DialogV2, LoadingButton } from 'saga-library/src'
import { Box } from '@mui/material'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { HistoryFrequency, SocialHistoryType } from '../../../../types/SocialHistory'
import { useHistoryContext } from '../../providers/HistoryProvider'
import * as yup from 'yup'
import { useReadQuery } from '@apollo/client'
import { SocialHistoryForm } from './SocialHistoryForm';
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import moment from 'moment-timezone'
import { useAccountContext } from '../../../../providers/AccountContext'

const FORM_NAME = "social_history_form"

const socialHistoryDefaults: SocialHistoryType = {
  exerciseFrequency: HistoryFrequency.UNKNOWN,
  exerciseNote: '',
  alcoholFrequency: HistoryFrequency.UNKNOWN,
  alcoholNote: '',
  smokingFrequency: HistoryFrequency.UNKNOWN,
  smokingNote: '',
  vapingFrequency: HistoryFrequency.UNKNOWN,
  vapingNote: '',
  caffeineFrequency: HistoryFrequency.UNKNOWN,
  caffeineNote: '',
  cannabisFrequency: HistoryFrequency.UNKNOWN,
  cannabisNote: '',
  otherDrugsFrequency: HistoryFrequency.UNKNOWN,
  otherDrugsNote: '',
  version: "0"
}

const schema = yup.object({
  exerciseFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  exerciseNote: yup.string().nullable(),
  alcoholFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  alcoholNote: yup.string().nullable(),
  smokingFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  smokingNote: yup.string().nullable(),
  vapingFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  vapingNote: yup.string().nullable(),
  caffeineFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  caffeineNote: yup.string().nullable(),
  cannabisFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  cannabisNote: yup.string().nullable(),
  otherDrugsFrequency: yup.mixed<HistoryFrequency>().oneOf(Object.values(HistoryFrequency)).required('Required'),
  otherDrugsNote: yup.string().nullable()
})

const SocialHistoryDialog = ({ open, setOpen }) => {
  const { getUserSetting } = useAccountContext()
  const { parseSocialHistoryQueryResults, socialHistoryQueryRef, updateSocialHistory } = useHistoryContext()
  const { enableNavigationPrompt } = usePrompt()
  const { data } = useReadQuery(socialHistoryQueryRef!)
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const socialHistoryData = parseSocialHistoryQueryResults(data)
  const dateFormat = getUserSetting(DATE_FORMAT) as string
  const lastUpdatedDate = socialHistoryData ? moment(socialHistoryData.audit?.luPit).format(dateFormat) : 'N/A'

  const formMethods = useForm<SocialHistoryType>({
    defaultValues: socialHistoryDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if (open) {
      if (!socialHistoryData) {
        reset(socialHistoryDefaults)
      } else {
        reset(socialHistoryData)
      }
    }
  }, [open, reset, socialHistoryData])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(null)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = handleSubmit((data) => {
    updateSocialHistory(data, () => {
      enableNavigationPrompt(false, FORM_NAME)
      setOpen(null)
    })
  })

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(null)
    }
  }

  return (
    <DialogV2
      dataTestId={'edit-social-history-dialog'}
      title={"Social history"}
      subtitle={
        <Box>
          <Box component={"span"} color={(theme) => theme.palette.greys.medium}>Last updated on: </Box>
          <Box component={"span"} color={(theme) => theme.palette.greys.dark}>{lastUpdatedDate}</Box>
        </Box>
      }
      open={!!open}
      onClose={onCancel}
      primaryAction={()=> null}
      overridePrimaryComponent={
        <LoadingButton
          name={'saveSocialHistory'}
          form={FORM_NAME}
          dataTestId={"edit-social-history-dialog-primary-button"}
          loading={isSubmitting}
          onClick={onSave}
          variant={'contained'}
        >
          Save and update
        </LoadingButton>
      }
    >
      <FormProvider {...formMethods}>
        <SocialHistoryForm
          onSubmit={onSave}
        />
      </FormProvider>
    </DialogV2>
  )
}

export default SocialHistoryDialog