import React, { useEffect, useState } from 'react'
import { DialogV2, Tabs } from "saga-library/src";
import { NewTemplateBookingPreference } from "./BookingPreference/NewTemplateBookingPreference";
import { TemplateMbscCalendarColor, TemplateMbscCalendarEvent } from "../../../types/schedule/Template";
import { TemplateData } from "./TemplateForm";
import { NewTemplateEvent } from "./Event/NewTemplateEvent";
import { getAppointmentTitle } from '../util/appointmentDialogFunctions'
import { usePrompt } from '../../../providers/NavigationPrompt'

const DATA_TEST_ID = "newTemplateEvent"

export interface NewTemplateEventDialogProps {
  open: boolean
  onClose: () => void
  onBookingPreferenceCreate: (bookingPreference: TemplateMbscCalendarColor) => void
  onEventCreate: (event: TemplateMbscCalendarEvent) => void
  templateData: TemplateData | null
  scheduleLength?: string
}

export const NewTemplateEventDialog = ({
  open,
  onClose,
  onBookingPreferenceCreate,
  onEventCreate,
  templateData,
  scheduleLength,
}: NewTemplateEventDialogProps) => {
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()
  const [formName, setFormName] = useState<string>("")
  const [dirtyFields, setDirtyFields] = useState<any>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, formName)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onFormChange = (name: string, submitting: boolean, changedFields: any) => {
    setFormName(name)
    setIsSubmitting(submitting)
    setDirtyFields(changedFields)
  }

  const eventOption = [
    {
      label: 'EVENT',
      content: (
        <NewTemplateEvent
          onClose={onClose}
          onFormChange={onFormChange}
          onEventCreate={(event) => {
            onEventCreate(event)
            enableNavigationPrompt(false, formName)
          }}
          templateData={templateData}
        />
      ),
    },
  ]

  const allOptions = [
    ...eventOption,
    {
      label: 'BOOKING PREFERENCE',
      content: (
        <NewTemplateBookingPreference
          templateData={templateData}
          onClose={onClose}
          onFormChange={onFormChange}
          onBookingPreferenceCreate={(bookingPreference) => {
            onBookingPreferenceCreate(bookingPreference)
            enableNavigationPrompt(false, formName)
          }}
        />
      ),
    },
  ]

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: templateData?.eventData?.start,
        end: templateData?.eventData?.end,
        scheduleLength: scheduleLength
      })}
      formName={formName}
      submitting={isSubmitting}
      contentSx={{ mt: 1 }}
      dataTestId={DATA_TEST_ID}
    >
      <Tabs dataTestId={`${DATA_TEST_ID}-type`} options={templateData?.showBookingPreference ? allOptions : eventOption } variant={'contained'} centered={true} />
    </DialogV2>
  )
}