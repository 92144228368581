import React, { useEffect } from "react";
import { BookingPreferenceForm } from "../../components/BookingPreference/BookingPreferenceForm";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { TemplateMbscCalendarColor } from "../../../../types/schedule/Template";
import moment from "moment-timezone";
import { schema } from "../../util/bookingPreferenceValidation";
import { TemplateData } from "../TemplateForm";
import { diffDates } from "saga-library/src/util";

const DATA_TEST_ID = "newTemplateBookingPreference"
const FORM_NAME = "new_template_booking_preference_form"

export const bookingPreferenceTemplateDefaults = (eventData) => {
  return {
    version: "0",
    start: moment(eventData?.start),
    end: moment(eventData?.end),
    title: '',
    appointmentTypeIds: [],
  }
}

interface NewTemplateBookingPreferenceProps {
  onClose: () => void
  onFormChange: (formName: string, submitting: boolean, dirtyFields: any) => void
  onBookingPreferenceCreate: (bookingPreference: TemplateMbscCalendarColor) => void
  templateData: TemplateData | null
}

export const NewTemplateBookingPreference = ({
  templateData,
  onClose,
  onFormChange,
  onBookingPreferenceCreate
}: NewTemplateBookingPreferenceProps) => {
  const formMethods = useForm<TemplateMbscCalendarColor>({
    defaultValues: bookingPreferenceTemplateDefaults(templateData?.eventData),
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    onFormChange(FORM_NAME, isSubmitting, dirtyFields)
  }, [isSubmitting, Object.keys(dirtyFields).length])

  const onSubmit = handleSubmit(async (data) => {
    const startOfDay = moment(data.start).startOf('day')
    const start = moment.duration(diffDates(startOfDay, moment(data.start))).toISOString()
    const length = diffDates(data.start, moment(data.end), 'minutes')

    let newBookingPreferenceInput = {
      ...data,
      appointmentTypeIds: data.appointmentTypes?.map((at) => at.id) || [],
      data: data.appointmentTypes || [],
      start: start,
      length: length,
      dbTitle: data.title,
      day: templateData?.day ?? 0,
      localId: crypto.randomUUID()
    }

    onBookingPreferenceCreate(newBookingPreferenceInput)

    reset()
    onClose()
  })

  return (
    <FormProvider {...formMethods}>
      <BookingPreferenceForm
        formName={FORM_NAME}
        onSubmit={onSubmit}
        dataTestId={DATA_TEST_ID}
      />
    </FormProvider>
  )
}


