import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import React from "react";
import { DraggableCellProps, DraggableTableListCell } from "./DraggableTableListCell";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";


interface RowProps {
  cells: DraggableCellProps[]
  onClick?: (() => void) | null
  draggableProvided?: DraggableProvided
  draggableSnapshot?: DraggableStateSnapshot
  dataTestId?: string
}
export const DraggableTableListRow = ({ cells, onClick, draggableProvided, draggableSnapshot, dataTestId }: RowProps) => {
  const isDragging = draggableSnapshot.isDragging
  const innerRef = draggableProvided.innerRef
  const draggableProps = draggableProvided.draggableProps
  const dragHandleProps = draggableProvided.dragHandleProps

  return (
    <TableRow
      ref={innerRef}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': {
          backgroundColor: (theme) => onClick ? theme.palette.backgrounds.hover : 'none'
        }
      }}
      data-testid={`draggableTableListRow-${dataTestId}`}
      {...draggableProps}
    >
      {draggableProvided &&
        <DraggableTableListCell isDragOccurring={isDragging}>
          <Box sx={{display: "flex", flexDirection: "row-reverse"}}>
            <div {...dragHandleProps}>
              <DragHandleIcon
                sx={{display: "flex", boxSizing: "content-box", padding: "8px"}}
                data-testid={`draggableTableListRow-${dataTestId}-dragHandleIcon`}
              />
            </div>
          </Box>
        </DraggableTableListCell>
      }
      {cells.map((cellProps, i) => (
        <DraggableTableListCell
          {...cellProps}
          key={'cell-' + i}
          onClick={() => onClick?.()}
          isDragOccurring={isDragging}
          lastCol={cells.length - 1 === i}
        />
      ))}
    </TableRow>
  )
}