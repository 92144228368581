import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { Typography } from "saga-library/src";
import _get from "lodash/get";
import { ReferralPractitioner } from "../../../../../types/settings";
import { LoadingSpinner } from "../../../../../components/LoadingScreen";
import { useLocationContext } from "../../../../../providers/LocationContextProvider";
import { GET_LOCATION } from "../../../../../graphql-definitions";
import { formatLocationAddress } from "../../../../settings/Locations/Locations";

type LetterFormLocationSelectionProps = {
  onSelect: (referralPractitioner: ReferralPractitioner) => void
  dataTestId: string
}

export const LetterFormLocationSelection = ({
  onSelect,
  dataTestId
}: LetterFormLocationSelectionProps) => {
  const { tenant_id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const { locations } = useLocationContext()

  const [getLocationDetails] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      onSelect(_get(data, 'tenant.location.get', null))
      setLoading(false)
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
      setLoading(false)
    }
  })

  if (loading) {
    return <LoadingSpinner size={"md"} label={"Loading location..."} />
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1} height={'100%'} sx={{ overflowY: 'auto' }}>
      {locations.map((location) => (
        <Box
          key={location.id}
          border={(theme) => `1px solid ${theme.palette.greys.medium}`}
          borderRadius={'8px'}
          lineHeight={'16px'}
          p={1}
          onClick={() => {
            setLoading(true)
            getLocationDetails({ variables: { tenantId: tenant_id, locationId: location.id }})
          }}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.backgrounds.hover
            },
          }}
          data-testid={`${dataTestId}-${location.id}`}
        >
          <Typography>{location.name}</Typography>
          <Typography variant={"p2"} color={(theme) => theme.palette.greys.medium}>{formatLocationAddress(location)}</Typography>
        </Box>
      ))}
    </Box>
  )
}