import { Box, Popover, useTheme } from "@mui/material";
import { FilterSelect, IconButton, SimpleToggleButtonGroup } from "saga-library/src";
import moment from "moment-timezone";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CalendarNext, CalendarPrev, CalendarToday } from "@mobiscroll/react";
import { ScheduleLength } from "../../../../types/schedule/Schedule";
import { ScheduleOptionsMenu } from "../ScheduleOptionsMenu";
import React, { useState } from "react";
import Search from "@mui/icons-material/Search";
import {
  staticDatePickerSlotDataTestId,
  staticDatePickerSlotPropsDataTestId
} from "saga-library/src/components/DatePicker/DatePicker";

export const ScheduleHeader = ({
  date,
  dateChanged,
  showViewSelector,
  instance,
  setScheduleType,
  toggleSearchOpen,
  scheduleType,
}) => {
  const theme = useTheme()

  const [ anchorEl, setAnchorEl ] = useState<any | HTMLElement>(undefined)
  const [ openDatePicker, setOpenDatePicker ] = useState(false)


  return (
    <Box
      display={'flex'}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"100%"}
    >
      <Box
        sx={{
          position:"absolute",
          left:0,
          display:"flex",
          "& .mbsc-calendar-button-today":{
            color: `${theme.palette.primary.main} !important`,
            fontWeight: "400",
          },
          "& .mbsc-calendar-button": {
            color: `${theme.palette.primary.main} !important`,
            fontFamily: "Inter, sans-serif !important",
            textTransform: "capitalize",
            fontSize: "16px",
            fontStyle: "normal",
            lineHeight: "20px",
          },
          "& .mbsc-calendar-button:hover": {
            backgroundColor: "#f6f9f8 !important",
          },
        }}
      >
        <FilterSelect
          dataTestId={"schedule-selectDate"}
          className={"mbsc-calendar-button"}
          color={`${theme.palette.primary.main} !important`}
          sx={{width: '190px'}}
          options={[{label: moment(date).format("MMMM YYYY"), value: moment(date).format("MMMM YYYY")}]}
          size={"large"}
          onOpen={(e) => {
            setAnchorEl(e.currentTarget)
            setOpenDatePicker(true)
          }}
          onClose={() => {
            setAnchorEl(null)
            setOpenDatePicker(false)
          }}
          dropDownOpen={openDatePicker}
          menuOverride={
            <Popover
              data-testid={"schedule-selectDate-popOver"}
              open={openDatePicker}
              onClose={() => {
                setAnchorEl(null)
                setOpenDatePicker(false)
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <StaticDatePicker
                  data-testid={"schedule-selectDate-datePicker"}
                  value={moment(date)}
                  onAccept={(day) => {
                    if (day) {
                      setOpenDatePicker(false)
                      if (dateChanged) {
                        const newDate = moment(day).set({hour: 12, minute: 0, second: 0, millisecond: 0}).toDate()
                        dateChanged(newDate)
                        instance?.navigate(newDate, false)
                      }
                    }
                  }}
                  views={['year', 'month', 'day']}
                  displayStaticWrapperAs={"desktop"}
                  slots={staticDatePickerSlotDataTestId}
                  slotProps={staticDatePickerSlotPropsDataTestId("schedule-selectDate-datePicker")}
                />
              </LocalizationProvider>
            </Popover>
          }
        />
        <Box display={"flex"}>
          <Box display={"flex"} data-testid={"schedule-calendarPrev"}><CalendarPrev className={"mbsc-calendar-button"}  /></Box>
          <Box display={"flex"} data-testid={"schedule-calendarToday"}><CalendarToday className={"mbsc-calendar-button-today"} /></Box>
          <Box display={"flex"} data-testid={"schedule-calendarNext"}><CalendarNext className={"mbsc-calendar-button"} /></Box>
        </Box>
      </Box>
      <Box
        sx={{
          "& .MuiButtonBase-root":{
            borderRadius: 1,
            padding: "2px 8px"
          }
        }}
        display={"flex"}
      >
        {!showViewSelector &&
          <SimpleToggleButtonGroup
            dataTestId={"schedule-length"}
            value={scheduleType}
            onChange={(e,v) => {
              if (v) {
                setScheduleType(v)
              }
            }}
            size={"small"}
            buttons={[
              { value: ScheduleLength.DAY, children: "Day" },
              { value: ScheduleLength.WEEK, children: "Week" },
            ]}
          />
        }
      </Box>
      <Box
        display={'flex'}
        sx={{
          position: 'absolute',
          right: 0
        }}
      >
        <IconButton
          dataTestId={"schedule-search"}
          onClick={toggleSearchOpen}
          icon={<Search />}
        />
        <ScheduleOptionsMenu />
      </Box>
    </Box>
  )
}

