import React, { useState } from "react";
import {
  EmptyList,
  ButtonDropdown,
  NavigationHeader,
  Section,
  Spinner,
  SubsectionMenuItem,
} from "saga-library/src";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { useQuery } from "@apollo/client";
import { LIST_TEMPLATES } from "../../../graphql-definitions";
import _get from "lodash/get";
import { useAccountContext } from "../../../providers/AccountContext";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { HasPermissionTemplate } from "../../../components/HasPermissionTemplate";
import { TemplateType } from "../../../types/schedule";
import SubsectionMenu from "saga-library/src/components/SubsectionMenu/SubsectionMenu";

export const TemplatesDashboard = () => {
  const { tenant_id, template_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const [templates, setTemplates] = useState<TemplateType[]>([])
  const location = useLocation()

  const { loading } = useQuery(LIST_TEMPLATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      const templates = _get(data, 'tenant.schedule.listTemplates')
      setTemplates(templates)
      if(templates.length && !template_id) {
        navigate(buildTenantRoute(`schedule/templates/t/${templates[0].id}`, tenant_id))
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const TemplateInformation = () => {
    if (loading) return <LoadingSpinner />

    return (
      <EmptyList
        message={'Template details appear here once a template has been created.'}
      />
    )
  }

  return (
    <Section.Container>
      <HasPermissionTemplate requiredType={PermissionType.Schedule} requiredPermission={Permission.READWRITE} showAccessDenied={true}>
        <TemplateListSelect />
        <Section.Column sx={{flex:"1 1 auto", overflow:"hidden"}}>
          {templates.length > 0 || location.pathname.includes("new") ? <Outlet /> : <TemplateInformation />}
        </Section.Column>
      </HasPermissionTemplate>
    </Section.Container>
  )
}

export const TemplateListSelect = () => {
  const navigate = useNavigate()
  const { tenant_id, template_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const { loading, data } = useQuery(LIST_TEMPLATES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const templates = _get(data, 'tenant.schedule.listTemplates')

  const onSelect = (templateId) => {
    navigate(buildTenantRoute(`schedule/templates/t/${templateId}`, tenant_id))
  }

  if (loading) return <Spinner />
  if (!templates) return <></>

  const menuItems = () => {
    return templates.map((template, index) => {
      return (
        <SubsectionMenuItem
          dataTestId={`templateMenu-menuItem-${index}`}
          key={`template_${template.id}`}
          onClick={() => onSelect(template.id)}
          primaryText={template.name}
          isActive={template_id === template.id}
        />
      )
    })
  }

  return (
    <SubsectionMenu
      emptyListComponent={{ message: 'Templates appear here once created.' , size: 'md'}}
      header={
        <NavigationHeader
          dataTestId={'template-navigation'}
          title={'Templates'}
          titleVariant={'h3'}
          onBack={() => navigate(buildTenantRoute(`schedule`, tenant_id))}
          actions={
            <ButtonDropdown
              dataTestId={'template-new'}
              title={'New'}
              options={[{name: 'DAY', onClick:() => navigate(buildTenantRoute("schedule/templates/new/?length=day", tenant_id))},
                {name: 'WEEK', onClick:() => navigate(buildTenantRoute('schedule/templates/new/?length=week', tenant_id))}]
              }
            />
          }
        />
      }
      routes={menuItems()}
      overflowY={"auto"}
    />
  )
}