import React, { useState } from 'react'
import { Box, Collapse } from '@mui/material'
import { ExpandButton, Typography } from 'saga-library/src'
import { EncounterNoteTemplateFieldTree } from '../../../../../types/patients'

interface EncounterNoteContainerProps {
  templateField: EncounterNoteTemplateFieldTree
  collapsible?: boolean
  collapsed?: boolean
  defaultStyle?: 'collapsed' | 'expanded' | 'controlled'
  actions?: React.ReactNode
  dataTestId: string
  children?: React.ReactNode
}

export const EncounterNoteContainer = ({
  templateField,
  collapsible = true,
  collapsed,
  defaultStyle = 'expanded',
  actions,
  dataTestId,
  children
}: EncounterNoteContainerProps) => {
  const [expandedField, setExpandedField] = useState<boolean>(() => {
    if (defaultStyle === 'controlled') {
      return !collapsed
    } else if (defaultStyle === 'collapsed') {
      return false
    }
    return true
  })

  return (
    <Box
      key={templateField.id}
      display={'flex'}
      flexDirection={'column'}
      border={'1px solid rgba(32, 41, 49, 0.32)'}
      borderRadius={'4px'}
      overflow={'hidden'}
    >
      <Box
        key={`${templateField.id}-field-name-box`}
        data-testid={`${dataTestId}-field-name-box`}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        height={'40px'}
        bgcolor={'tertiary.main'}
        px={1}
        onClick={() => setExpandedField(!expandedField)}
        sx={{
          cursor: 'pointer',
          "&:hover": {
            backgroundColor: 'backgrounds.hover'
          }
        }}
      >
        <Typography
          key={`${templateField.id}-field-name`}
          variant={'h5'}
          dataTestId={`${dataTestId}-field-name`}
        >
          {templateField.name}
        </Typography>
        {(actions || collapsible) && (
          <Box>
            {actions}
            {collapsible && (
              <ExpandButton expand={expandedField} setExpand={setExpandedField} dataTestId={`${dataTestId}-expand-button`} />
            )}
          </Box>
        )}
      </Box>
      <Collapse in={expandedField}>
        <Box p={1}>
          {children}
        </Box>
      </Collapse>
    </Box>
  )
}