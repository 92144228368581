import { gql } from "@apollo/client";

export const FAMILY_HISTORY_FRAGMENT = gql`
  fragment FamilyHistoryFragment on FamilyHistory {
    id
    condition   
    relationship
    notes
    diagnosticCodeId
    version
    audit {
      crePit
    }
  }
`

export const GET_PATIENT_FAMILY_HISTORY = gql`
  ${FAMILY_HISTORY_FRAGMENT}
  query GetPatientFamilyHistory($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        familyHistory {
          list {
            ...FamilyHistoryFragment
          }
        }
      }
    }
  }
`

export const UPDATE_FAMILY_HISTORY = gql`
  ${FAMILY_HISTORY_FRAGMENT}
  mutation UpdateFamilyHistory($tenantId: ID!, $patientId: ID!, $input: FamilyHistoryInput!) {
    tenant(id: $tenantId) {
      patient {
        familyHistory {
          update(patientId: $patientId, input: $input) {
            ...FamilyHistoryFragment
          }
        }
      }
    }
  }
`
