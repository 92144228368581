import * as yup from "yup";
import moment from "moment-timezone";

const stringDescriptionSchema = yup.string().required('Required');
const diagnosticCodeSchema = yup.object({
    description: yup.string(),
    diagnosticCode: yup.string(),
    id: yup.string()
});

export const schema = yup.object().shape({
    description: yup.lazy((value) =>
      typeof value === 'object' ? diagnosticCodeSchema.required('Required') : stringDescriptionSchema
    ),
    status: yup.string().required("Required"),
    diagnosticCode: diagnosticCodeSchema.nullable(),
    severity: yup.string().nullable(),
    startDate: yup.date().typeError("Invalid date").nullable(),
    endDate: yup.date().typeError("Invalid date").nullable().test('end_time_is_greater', 'End time should be greater than start time', function(value) {
        const { startDate } = this.parent;
        if (startDate === null) return true;
        if (value === null) return true;
        return moment(value).isSameOrAfter(moment(startDate));
    }),
    notes: yup.string().nullable()
})