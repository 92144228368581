export interface AuthContext {
  performSignIn: (credentials: any) => Promise<boolean>,
  performSignOut: () => Promise<void>,
  performResetPassword: (postData: any) => Promise<boolean>,
  performForgotPassword: (postData: any) => Promise<boolean>,
  beginChangeEmail: (postData: any) => Promise<boolean>,
  performChangeEmail: (postData: any) => Promise<boolean>,
  clearErrors: () => void,
  isAuthenticated: boolean,
  friendlyErrorMessage: string | null,
  friendlyErrorDetails: string | null,
  friendlyEmailErrorMessage: string | null,
}

export enum AuthActions {
  INIT,
  UPDATE_ERROR_MESSAGE,
  SIGN_IN,
  SIGN_OUT,
  REFRESH,
  ANOTHER_TAB_SIGNED_IN,
  ANOTHER_TAB_SIGNED_OUT
}

export interface AuthInternalState {
  isAuthenticated: boolean,
  loading: boolean,
  refreshDelayMs: number,
  friendlyErrorMessage: string | null,
  friendlyErrorDetails: string | null,
  friendlyEmailErrorMessage: string | null
}

export interface AuthStatusResponse {
  status: number
  isAuthenticated: boolean,
  expiresInSeconds: number
}

export interface SignInResponse {
  status: number,
  lockoutEndsInSeconds: number,
  lockoutAttemptsRemaining: number
}

export type ResetPasswordParams = {
  e?: string | undefined
  t?: string | undefined
}