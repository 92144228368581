import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../providers/AccountContext";
import { Permission, PermissionType } from "../types/settings/Permission";
import { AccessDeniedPage } from "./AccessDeniedPage";


interface HasPermissionProps {
  requiredType: PermissionType | PermissionType[]
  requiredPermission: Permission
  children: ReactElement | ReactElement[]
  showAccessDenied?: boolean
  overridePermission?: boolean
}

export const HasPermissionTemplate = ({ children, requiredPermission, requiredType, showAccessDenied = false, overridePermission = false }: HasPermissionProps) => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()

  let shouldRender = overridePermission
  if (!shouldRender && tenant_id) {
    if (Array.isArray(requiredType)) {
      shouldRender = requiredType.some(type => userHasPermission(tenant_id, type, requiredPermission));
    } else {
      shouldRender = userHasPermission(tenant_id, requiredType, requiredPermission);
    }
  }

  if(shouldRender) {
    return <> {children} </>
  }

  if(showAccessDenied) {
    return <AccessDeniedPage />
  }

  return null
}