import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { BookingPreferenceForm } from "./BookingPreferenceForm";
import { Button, ConfirmationDialog, DialogV2, RemoveButton } from "saga-library/src";
import { schema } from "../../util/bookingPreferenceValidation";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import _get from "lodash/get";
import { BookingPreferenceInput } from "../../../../types/schedule";
import { DELETE_BOOKING_PREFERENCE, GET_SCHEDULE_ITEMS, UPDATE_BOOKING_PREFERENCE } from "../../../../graphql-definitions";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime, getScheduleViewDates } from "../../util/scheduleFunctions";
import { getAppointmentTitle } from '../../util/appointmentDialogFunctions'

const DATA_TEST_ID = "editBookingPreference"
const FORM_NAME = "edit_booking_preference_form"

interface EditBookingPreferenceProps {
  open: boolean
  eventData: any
  onClose: () => void
  scheduleId: string
}

const bookingPreferenceDefaults = (eventData, scheduleId, timezone): BookingPreferenceInput => {
  return {
    version: eventData.version,
    scheduleId: scheduleId,
    start: moment(eventData.start).tz(timezone),
    end: moment(eventData.end).tz(timezone),
    title: eventData.title,
    itemDate: moment(eventData?.start).format('YYYY-MM-DD'),
    appointmentTypeIds: eventData.appointmentTypes.map((at) => at.id),
    appointmentTypes: eventData.appointmentTypes
  }
}

export const EditBookingPreference = ({
  open,
  eventData,
  onClose,
  scheduleId,
}: EditBookingPreferenceProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()
  const { selectedScheduleIds, scheduleView, displayScheduleError } = useScheduleContext()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

  const formMethods = useForm<BookingPreferenceInput>({
    defaultValues: bookingPreferenceDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]),
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [updateBookingPreference] = useMutation(UPDATE_BOOKING_PREFERENCE, {
    onCompleted: () => {
      reset()
      onClose()
      showSuccessAlert('Booking preference has been saved.')
    },
    onError: (error: any) => {
      displayScheduleError(error, "Booking preference", "saved")
    }
  })

  const [deleteBookingPreference] = useMutation(DELETE_BOOKING_PREFERENCE, {
    onCompleted: () => {
      reset()
      onClose()
      showSuccessAlert('Booking preference has been deleted.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Booking preference couldn't be deleted.")
    }
  })

  const handleDeleteBookingPreference = () => {
    deleteBookingPreference({
      variables: {
        tenantId: tenant_id,
        scheduleBlockId: eventData.id,
        version: eventData.version
      },
      update(cache) {
        const normalizedId = cache.identify({
          id:eventData.id,
          __typename: 'BookingPreference'
        })
        cache.evict({id:normalizedId})
        cache.gc()
      }
    })
  }

  useEffect(()=>{
    if(eventData){
      reset(bookingPreferenceDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]))
    }
  },[eventData])

  const onSubmit = handleSubmit( async (data) => {
    let appointmentTypeIds = data.appointmentTypes?.map((at) => at.id)
    delete data.appointmentTypes

    let updatedBookingPreferenceInput = {
      ...data,
      appointmentTypeIds: appointmentTypeIds,
      start: moment(data.itemDate + ' ' + formatTime(data.start), 'YYYY-MM-DD h:mm A'),
      end: moment(data.itemDate + ' ' + formatTime(data.end), 'YYYY-MM-DD h:mm A')
    }

    await updateBookingPreference({
      variables: {
        tenantId: tenant_id,
        scheduleBlockId: eventData.id,
        scheduleBlockInput: updatedBookingPreferenceInput
      },
      update(cache, returnedData) {
        let updatedBookingPreference = _get(returnedData, 'data.tenant.schedule.updateBookingPreference')

        const scheduleDateRange = getScheduleViewDates(scheduleView.date, scheduleView.view)

        try {
          cache.writeQuery({
            query: GET_SCHEDULE_ITEMS,
            data: {
              tenant: {
                schedule: {
                  scheduleItems: [
                    {
                      key: scheduleId,
                      value: [updatedBookingPreference],
                    }
                  ],
                  scheduleTemplateDays: [],
                }
              }
            },
            variables: {
              tenantId: tenant_id,
              scheduleIdList: selectedScheduleIds,
              start: moment(scheduleDateRange.start).utc().toISOString(),
              end: moment(scheduleDateRange.end).utc().toISOString
            }
          })
        } catch (e) {
          console.error("Error updating cache", e)
        }
      }
    })
  })

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: eventData?.start,
        end: eventData?.end
      })}
      headerActions={[
        <RemoveButton onClick={() => setOpenDeleteDialog(true)} dataTestId={DATA_TEST_ID} />
      ]}
      formName={FORM_NAME}
      submitting={isSubmitting}
      dataTestId={DATA_TEST_ID}
    >
      <FormProvider {...formMethods}>
        <BookingPreferenceForm
          formName={FORM_NAME}
          onSubmit={onSubmit}
          limitToScheduleId={scheduleId}
          dataTestId={DATA_TEST_ID}
        />
      </FormProvider>
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={'Delete booking preference?'}
        message={'This action cannot be undone.'}
        primaryAction={handleDeleteBookingPreference}
        primaryLabel={'Delete'}
        dataTestId={`${DATA_TEST_ID}-deleteDialog`}
      />
    </DialogV2>
  )
}
