import React from 'react'
import { TextField, Section } from 'saga-library/src'
import { rules } from '../../util/validation'
import { FormFragment } from "../../../../../components/PermissionForm";

export type ExternalIdentifiersDetailsType = {
  dndNumber: string
  wcbBillingNumber: string
}

const defaults = {
  dndNumber: '',
  wcbBillingNumber: '',
}

const Form = ({ profileIndex, requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>External identifiers</Section.Header>
        <TextField
          label={'DND number'}
          name={`billingProfileInputs.${profileIndex}.dndNumber`}
          inputProps={{ maxLength: rules.billingProfileInputs.dndNumber.max }}
        />
        <TextField
          label={'WCB billing number'}
          name={`billingProfileInputs.${profileIndex}.wcbBillingNumber`}
          inputProps={{
            maxLength: rules.billingProfileInputs.wcbBillingNumber.max,
          }}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const ExternalIdentifiersDetails = {
  Form: Form,
  defaults: defaults,
}
