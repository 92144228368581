import React, { useMemo, useState } from 'react'
import { useLazyQuery, useReadQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { Typography } from 'saga-library/src'
import { LoadingSpinner } from '../../../../../components/LoadingScreen'
import { useAppointmentContext } from '../../../providers/AppointmentProvider'
import moment from 'moment-timezone'
import { PatientAppointment } from '../../../../../types/patients'
import { useFormattedDate } from '../../../../../hooks/FormattedDate'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import _get from 'lodash/get'
import {
  GET_LOCATION,
  GET_PRACTITIONER_ADDRESS_AND_PHONE
} from '../../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import { PatientAppointmentWithPractitionerDetailsAndClinicLocation } from '../../../../../types/patients/Patient'

type LetterFormAppointmentSelectionProps = {
  onSelect: (appointment: PatientAppointmentWithPractitionerDetailsAndClinicLocation) => void
  dataTestId: string
}

export const LetterFormAppointmentSelection = ({
  onSelect,
  dataTestId
}: LetterFormAppointmentSelectionProps) => {
  const { tenant_id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)

  const { appointmentQueryRef, getAppointmentQueryResults } = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef!)
  const patientAppointments = useMemo(() => getAppointmentQueryResults(appointmentData), [appointmentData, getAppointmentQueryResults])

  const [appointment, setAppointment] = useState<PatientAppointment | PatientAppointmentWithPractitionerDetailsAndClinicLocation | null>(null)

  const [getPractitionerDetails] = useLazyQuery(GET_PRACTITIONER_ADDRESS_AND_PHONE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      let practitionerData = _get(data, 'tenant.practitioner.get', null)

      let appointmentWithPractitioner: PatientAppointmentWithPractitionerDetailsAndClinicLocation | null = null
      if(!!appointment && !!practitionerData){
        appointmentWithPractitioner = {
          ...appointment,
          practitioner: practitionerData,
          location: appointment.location,
        }
      }

      setAppointment(appointmentWithPractitioner)

      if(!!appointmentWithPractitioner){
        getLocationDetails({
          variables: {
            tenantId: tenant_id,
            locationId: appointmentWithPractitioner.location.id
          }
        })
      }
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
      setLoading(false)
    }
  })

  const [getLocationDetails] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      let locationData = _get(data, 'tenant.location.get', null)
      let appointmentWithPractitionerAndLocation: PatientAppointmentWithPractitionerDetailsAndClinicLocation | null = null
      if(!!appointment && !!locationData){
        appointmentWithPractitionerAndLocation = {
          ...appointment,
          practitioner: appointment.practitioner,
          location: locationData
        }
      }

      if(!!appointmentWithPractitionerAndLocation){
        onSelect(appointmentWithPractitionerAndLocation)
      }
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
      setLoading(false)
    }
  })


  const filteredAppointments = () => {
    const dateNow = moment()

    if (!patientAppointments || patientAppointments.length === 0) return []
    let filtered = [...patientAppointments.filter(appointment => dateNow.isSameOrAfter(appointment.start, 'day'))]

    const futureAppointments = patientAppointments.filter(appointment => dateNow.isBefore(appointment.start, 'day'))
    if (futureAppointments.length > 0) {
      filtered = [futureAppointments[0], ...filtered]
    }

    return filtered.slice(0, 5)
  }

  if (loading) {
    return <LoadingSpinner size={'md'} label={'Loading location...'} />
  }

  const FormattedAppointment = ({appointment}: {appointment: PatientAppointment}) => {
    let appointmentTimeDisplay = `${useFormattedDate(appointment.start, true)} - ${moment(appointment.end).format('h:mm A')}`

    let practitionerDisplay = ''
    if (appointment.practitioner !== undefined) {
      practitionerDisplay = practitionerDisplayName(appointment.practitioner.firstName, appointment.practitioner.lastName)
    }

    return (
      <>
        <Typography variant={'h5'}>{appointmentTimeDisplay}</Typography>
        <Typography variant={'body1'}>{practitionerDisplay}</Typography>
        <Typography variant={'p3'}>{appointment?.type?.name}</Typography>
      </>
    )
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      {
        filteredAppointments().map((appointment) => (
          <Box
            key={appointment.id}
            border={(theme) => `1px solid ${theme.palette.greys.medium}`}
            borderRadius={'8px'}
            lineHeight={'16px'}
            p={1}
            onClick={() => {
              setAppointment(appointment)
              setLoading(true)
              getPractitionerDetails({
                variables: {
                  tenantId: tenant_id,
                  practitionerId: appointment.practitioner.id
                }
              })
            }}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: (theme) => theme.palette.backgrounds.hover
              }
            }}
            data-testid={`${dataTestId}-${appointment.id}`}
          >
            <FormattedAppointment appointment={appointment} />
          </Box>
        ))
      }
    </Box>
  )
}