import React, { Suspense, useState } from 'react'
import { Section } from 'saga-library/src'
import { Outlet } from 'react-router-dom'
import AssessedClaimsList from "./components/AssessedClaims/AssessedClaimsList";
import { AbClaim } from "../../types/billing";
import { Box, useTheme } from '@mui/material'



export const AssessedClaimsLayout = () => {
  const theme = useTheme()
  const [selectedClaims, setSelectedClaims] = useState<AbClaim[]>([])


  return (
    <Section.Container
      sx={{
        width: '100%',
        mr: theme.spacing(2)
      }}
    >
      <Section.Column
        width={'40%'}
        sx={{
          flex: "0 0 40%",
          minWidth: "650px",
          maxWidth: "730px",
          mt: 0
        }}
        rightPadding={1}
      >
        <Suspense fallback={<h1>LOADING...</h1>}>
          <Outlet />
        </Suspense>
      </Section.Column>

      <Box
        sx={{
          height: 'auto',
          flex: '1 1 auto',
          minWidth: '880px',
          overflow: "hidden",
          flexDirection: "column",
          mr: 1,
        }}
      >
        <Section.Column
          width={'auto'}
          sx={{
            flex:"1 1 auto",
            m: theme.spacing(0,0,1)
          }}
          rightPadding={1}
        >
          <Suspense fallback={<Box>LOADING...</Box>}>
            <AssessedClaimsList
              selectedClaims={selectedClaims}
              setSelectedClaims={setSelectedClaims}
            />
          </Suspense>
        </Section.Column>
      </Box>
    </Section.Container>
  )
}


export default AssessedClaimsLayout
