import React, { useEffect, useState } from "react";
import { useQuery } from '@apollo/client'
import { Autocomplete } from 'saga-library/src'
import { Box, SxProps, useTheme } from "@mui/material";
import FormControl from '@mui/material/FormControl'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { Typography } from 'saga-library/src';
import { useParams } from 'react-router-dom'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import _get from 'lodash/get'
import { Theme } from "@mui/system";
import { transform } from "../../utils/identifierTransform";
import { GET_BILLINGPROFILES } from "../../graphql-definitions";
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'

interface BillingProfileSelectProps {
  name?: string
  label?: string
  onSelect?: (billingProfile) => void
  autoFocus?: boolean
  setValueAsProfile?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
  dataTestId?: string
}

export interface BillingProfileOption {
  label: string
  id: string
  firstName: string
  lastName: string
  practitionerId: string
  facilityId: string
  functionalCenterId: string
  locationCode: string
  functionalCenter: object
  facility: object
}

export const ControlledBillingProfileSelect = ({
  name = 'practitionerName',
  label = 'Practitioner',
  onSelect,
  autoFocus,
  setValueAsProfile = true,
  sx,
  disabled = false,
  dataTestId
}: BillingProfileSelectProps) => {

  const { control } = useFormContext();
  const [practitionerId, setPractitionerId] = useState(null)

  useEffect(() => {
    if (control._formValues?.practitionerId) {
      setPractitionerId(control._formValues.practitionerId)
    }
  },[control._formValues?.practitionerId])

  return (
    <Box sx={{ minWidth: 120, width: "100%" }}>
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            onChange : controlledOnChange,
            value ,
            ref,
          },
          fieldState: { error }
        }) => {
          return (
            <BillingProfileSelect
              name={name}
              dataTestId={dataTestId}
              value={value}
              helperText={error?.message}
              error={error}
              label={label}
              onSelect={(e, billingProfile) => {
                controlledOnChange(setValueAsProfile ? billingProfile : transform.input(billingProfile))
                if (onSelect) {
                  onSelect(setValueAsProfile ? billingProfile : transform.input(billingProfile))
                }
              }}
              autoFocus={autoFocus}
              sx={sx}
              inputRef={ref}
              disabled={disabled}
              limitToPractitionerId={practitionerId}
            />
          )
        }}
      />
    </Box>
  )
}

export const BillingProfileSelect = ({
  value: incomingValue,
  helperText,
  error,
  name = 'practitionerName',
  label = 'Practitioner',
  onSelect,
  autoFocus,
  sx,
  inputRef,
  disabled = false,
  limitToPractitionerId = null,
  setValueAsProfile = true,
  dataTestId = ''
}) => {
  const { tenant_id } = useParams()
  const theme = useTheme()
  const [ options, setOptions ] = useState<BillingProfileOption[]>([])
  const [ value, setValue ]= useState<any>(null)

  const { loading } = useQuery(GET_BILLINGPROFILES, {
    fetchPolicy: 'cache-and-network',
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      let billingProfiles = _get(
        data,
        'tenant.practitioner.listAbBillingProfiles',
        null
      )
      let loadedOptions: BillingProfileOption[] = billingProfiles.map(
        (item) => {
          return {
            ...item,
            label: item.name,
            firstName: item.practitionerFirstName,
            lastName: item.practitionerLastName,
          }
        }
      )
      if (limitToPractitionerId) {
        loadedOptions = loadedOptions.filter((item) => item.practitionerId === limitToPractitionerId)
      }
      setOptions(loadedOptions)
    },
  })

  useEffect(()=>{
    if (options?.length > 0) {
      if (typeof incomingValue === 'string') {
        const tempValue = transform.output(incomingValue, options)
        setValue(tempValue)
        onSelect(null, tempValue)
      } else if(incomingValue?.id !== value?.id){
        const tempValue = transform.output(incomingValue && incomingValue.id, options)
        setValue(tempValue)
        onSelect(null, tempValue)
      }
    }
  }, [incomingValue, options])

  const BillingProfileRenderOption = ({ props, data }) => {
    const billingProfile = options.find((s) => {
      return s.id === (setValueAsProfile ? data.id : data)
    })

    return <Box component="li" {...props}>
      <Box>
        <Typography>{billingProfile ? billingProfile.label : ''}</Typography>
        <Typography variant={'p2'} color={theme.palette.greys.medium}>
          {billingProfile
            ? `${practitionerDisplayName(
              billingProfile.firstName,
              billingProfile.lastName
            )}`
            : ''}
        </Typography>
      </Box>
    </Box>
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        name={name}
        inputRef={inputRef}
        label={label}
        options={options}
        onChange={onSelect}
        OptionComponent={BillingProfileRenderOption}
        getOptionLabel={(value) => {
          const billingProfile = options.find((bp) => bp.id === (setValueAsProfile ? value.id : value))
          if (billingProfile) {
            return `${billingProfile.label} (${practitionerDisplayName(
              billingProfile.firstName,
              billingProfile.lastName
            )})`
          } else if (value) {
            return `${value.name} (${practitionerDisplayName(
              value.practitionerLastName,
              value.practitionerFirstName
            )})`
          }
          return ''
        }}
        isOptionEqualToValue={setValueAsProfile ? IdEqual : (option, value) => {
          return option.id === value
        }}
        value={value}
        sx={sx}
        autoFocus={autoFocus}
        loading={loading}
        disabled={disabled}
        error={error}
        helperText={helperText}
        dataTestId={dataTestId}
      />
    </FormControl>
  )
}

export default BillingProfileSelect
