import * as React from 'react'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { FormHelperText, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { Typography } from "../Typography";

export type CheckBoxArrayValue = {
  label: string
  id: string
  disabled?: boolean
}

interface CheckBoxArrayProps {
  title?: string
  name: string
  helperText?: string
  items: CheckBoxArrayValue[]
  sx?: SxProps<Theme>,
  checkboxSx?: SxProps<Theme>,
  size?: 'small' | 'medium'
  row: any
  rules?: object,
  dataTestId?: string
}

export default function CheckBoxArray({
  title,
  name,
  items,
  rules,
  row = undefined,
  sx,
  checkboxSx,
  helperText,
  size='medium',
  dataTestId
}: CheckBoxArrayProps) {
  const { control } = useFormContext();
  return (
    <Box sx={{ display: 'flex' }}>
      <Controller
        defaultValue={[]}
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value , ref}, fieldState: {error} }) => {
          const itemChecked = (e) => {
            if (e.target.checked) {
              value.push(e.target.name)
            } else {
              value.splice(value.indexOf(e.target.name), 1)
            }
            onChange(value)
          }
          return (
            <FormControl error={!!error} component="fieldset" sx={{ m: 3, ...sx }}>
              {title && <FormLabel sx={{ ...sx }}>{title}</FormLabel>}
              <FormGroup data-testid={`${dataTestId}-checkboxgroup`} row={row}>
                {items.map((item, index) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <MuiCheckbox
                        data-testid={`${dataTestId}-checkbox-${index}`}
                        name={item.id}
                        sx={{ color: 'primary', ...checkboxSx }}
                        checked={value.includes(item.id)}
                        onChange={itemChecked}
                        size={size}
                        disabled={item.disabled}
                      />
                    }
                    label={size === 'small'?
                      <Typography>
                        {item.label}
                      </Typography>
                      : item.label
                    }
                    sx={(theme) => ({mb: row? 0 : 1, color: theme.palette.greys.dark})}
                  />
                ))}
            </FormGroup>
            {error && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        )}}
      />
    </Box>
  )
}
