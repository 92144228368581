import * as yup from 'yup'
import _get from 'lodash/get'
import moment from "moment-timezone";

export const rules = {
  notes: { max: 65535},
  reasonForVisit: { max: 65535 }
}

const maxLengthMessage = (value) => `Max length of ${value}`

const nullIfEmpty = (value) => (!value ? null : value)
export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    notes: yup
      .string()
      .max(rules.notes.max, maxLengthMessage(rules.notes.max)),
    reasonForVisit: yup
      .string()
      .max(rules.reasonForVisit.max, maxLengthMessage(rules.reasonForVisit.max)),
    patientId: yup
      .string()
      .transform((val) => val.id)
      .required('Required'),
    appointmentTypeId: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    appointmentStateId: yup
      .string()
      .required('Required'),
    method: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    start: yup
      .string()
      .required('Required'),
    end: yup
      .string()
      .required('Required')
      .test('end_time_is_greater', 'End time should be greater than start time', function(value) {
        const { start } = this.parent;
        return moment(value).isSameOrAfter(moment(start));
      })
  })
  .required()
