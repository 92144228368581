import { PrintIconButton } from 'saga-library/src'
import React, { useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { useImportedDocumentsStateContext } from './ImportedDocumentsStateProvider'
import { getPageRange, PrintPdfModal } from '../../../patients/components/print/PrintPdfModal'
import { PagesToInclude } from '../../../../types/Document'

export const ImportedDocumentsPrintButton = () => {
  const [printModalOpen, setPrintModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { selectedFile, pdfDocRef, pdfViewerRef } = useImportedDocumentsStateContext()
  const activityTracking = useActivityTracking()

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!selectedFile?.id) return
    activityTracking.logPrintActivity(UserActivityObjectType.FILE, selectedFile.id)
    setLoading(false)
  }

  const printPdfViewer = async () => {
    setLoading(true)
    setPrintModalOpen(true)
  }

  const onPrintClicked = async (printType: number, pageRange: string, pagesToInclude: PagesToInclude) => {
    if (!pdfDocRef?.current || !selectedFile || !pdfViewerRef?.current) return
    let totalPageCount = pdfDocRef.current.getPageCount()
    await pdfViewerRef?.current?.printEx({ type: printType, pageRange: getPageRange(pagesToInclude, totalPageCount, pageRange), progress: true }, (data) => {
      printPdfCallback(data)
    })
  }

  const onModalClose = () => {
    setLoading(false)
    setPrintModalOpen(false)
  }

  return (
    <>
      <PrintPdfModal
        open={printModalOpen}
        onModalClose={onModalClose}
        printPdfCallback={printPdfCallback}
        onPrintClicked={onPrintClicked}
      />
      <PrintIconButton
        disabled={loading}
        onClick={printPdfViewer}
        dataTestId={'imported-documents'}
      />
    </>
  )
}
