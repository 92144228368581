import { EntryDescription, EntryTitle, ResultOptionsWrapper } from "saga-library/src"
import React from "react"
import { capitalizedWord } from "saga-client/src/utils/StringHelpers"
import { Drug } from "../../../../../types/Prescription"
import { Chip } from "saga-library/src/components/Chip/Chip"

interface DrugResultProps {
  drug: Drug
  searchText: string
}

export const DrugResult = ({drug, searchText}: DrugResultProps) => {
  return (
    <ResultOptionsWrapper>
      <EntryTitle
        title={drug?.brand ? drug?.name : capitalizedWord(drug?.name || "")}
        searchText={searchText}
        endAdornment={drug?.brand ? undefined : <Chip label={"GENERIC"} size={'small'} sx={{m:0}}/>}
      />
      <EntryDescription
        text={capitalizedWord(drug?.ingredient || "")}
        searchText={searchText}
      />
    </ResultOptionsWrapper>
  )
}