import { EditAppointmentDialog } from "../Appointment/EditAppointmentDialog";
import React from "react";
import { EditEvent } from "./EditEvent";
import { EditBookingPreference } from "../BookingPreference/EditBookingPreference";
import { DialogV2 } from "saga-library/src";
import { ApplyTemplateDialog } from "../ApplyTemplateDialog";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";

export interface EditEventDialogProps {
  open: boolean
  onClose: () => void
  scheduleId: string
  practitionerName?: string
  eventData: any
}

export const EditEventDialog = ({
  open,
  onClose,
  scheduleId,
  practitionerName,
  eventData
}: EditEventDialogProps) => {
  const { scheduleItemActions:{refetchScheduleItems} } = useScheduleContext()
  if(!open) return <></>

  if (eventData?.__typename === 'Appointment') {
    return (
      <EditAppointmentDialog
        open={open}
        onClose={onClose}
        scheduleId={scheduleId}
        practitionerName={practitionerName}
        eventData={eventData}
      />
    )
  }

  if (eventData?.__typename === 'ScheduleEvent') {
    return (
      <EditEvent
        open={open}
        eventData={eventData}
        scheduleId={scheduleId}
        onClose={onClose}
        practitionerName={practitionerName}
      />
    )
  }

  if (eventData?.__typename === 'BookingPreference') {
    // TODO: SAGA-1501
    let defaultAppointmentTypeId = eventData.appointmentTypes.length > 0 ? eventData.appointmentTypes[0].id : null

    return (
      <EditBookingPreference
        open={open}
        eventData={eventData}
        scheduleId={scheduleId}
        onClose={onClose}
      />
    )
  }

  if (eventData?.__typename === 'TemplateEvent' || eventData?.__typename === 'TemplateBookingPreference') {
    return (
      <DialogV2
        open={open}
        onClose={onClose}
        title={"NYI (Editing template event/booking preferences)"}
        primaryAction={() => console.log('NYI')}
      >
      </DialogV2>
    )
  }

  if (eventData?.__typename === 'TemplateAppliedEvent') {
    return (
      <ApplyTemplateDialog
        open={open}
        handleClose={onClose}
        scheduleDate={eventData.end}
        onComplete={refetchScheduleItems}
        selectedTemplateId={eventData.templateId}
      />
    )
  }

  return <></>
}
