import { SearchBar, SimpleTextField, Typography } from "saga-library/src";
import _get from "lodash/get";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PatientSearchResult } from "../../types/patients";
import { PractitionerSearchResult } from "../../types/settings";
import { GlobalSearchResult, ResultTypes } from "./GlobalSearchResult";
import { SearchControlProps } from "saga-library/src/components/SearchControl/SearchControl";
import { useAccountContext } from "../../providers/AccountContext";
import { useFocus } from "../../hooks/useFocus";
import { AutocompleteRenderGroupParams, Box, useTheme } from "@mui/material";
import { SearchIconAdornment } from "saga-library/src";
import { GET_SEARCH_GLOBAL, GET_RECENT_GLOBAL } from "../../graphql-definitions";
import { PatientModal } from "../SearchControls/Patient/PatientModal";

type ResultType = {
  resultType: string
}

export type GlobalResult = (PatientSearchResult | PractitionerSearchResult) & ResultType

export interface GlobalSearchBarProps<
  T = GlobalResult,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<SearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'error'|'onChange'|'value'|'inputRef'|'label'|'queries'|'render'> {
  dataTestId?: string
}

const INPUT = 'INPUT'
const TEXT = 'TEXTAREA'
const SLASH = '/'

export const GlobalSearchBar = ({
  dataTestId = 'GlobalSearchBar',
  ...props
}: GlobalSearchBarProps) => {

  const theme = useTheme()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const [inputRef, setInputFocus] = useFocus()
  const [ openPatientModal, setOpenPatientModal ] = useState<boolean>(false)

  const handleSearchHotkey = useCallback(
    (event) => {
      if (event.key === SLASH) {
        const nodeName = document.activeElement?.nodeName
        if (event.ctrlKey || !(nodeName === INPUT || nodeName === TEXT)) {
          event.preventDefault()
          setInputFocus()
        }
      }
    },
    [setInputFocus]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleSearchHotkey)
    return () => {
      document.removeEventListener('keydown', handleSearchHotkey)
    }
  }, [handleSearchHotkey])

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id,
    includeInactive: true
  }), [tenant_id])

  const resultOnClick = (event, result: GlobalResult | null) => {
    if(result) {
      let typeRoute = ""
      switch (result.resultType) {
        case ResultTypes.patient:
          typeRoute = "patients/p/"
          break
        case ResultTypes.practitioner:
          typeRoute = "settings/practitioners/p/"
          break
      }
      navigate(buildTenantRoute(
        `${typeRoute}${result.id}`,
        tenant_id
      ))
    }
  }

  const RenderGlobalResultGroup = (params: AutocompleteRenderGroupParams) => {
    return (
      <Box key={params.key} m={1} ml={2}>
        <Typography variant={'body1'} color={theme.palette.greys.light}>
          {params.group}
        </Typography>
        {params.children}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        width: '415px',
      }}
    >
      <PatientModal
        open={openPatientModal}
        onModalClose={() => setOpenPatientModal(false)}
      />
      <SearchBar<GlobalResult, false, false, false>
        {...props}
        dataTestId={dataTestId}
        label={'Search'}
        value={null}
        onChange={resultOnClick}
        inputRef={inputRef}
        groupBy={(result) => result.resultType}
        renderGroup={RenderGlobalResultGroup}
        addNewName={'patient'}
        addNewOnClick={() => {
          setOpenPatientModal(true)
        }}
        queries={{
          search: {
            gql: GET_SEARCH_GLOBAL,
            get: (data) => {
              let patients = _get<any, string, PatientSearchResult[]>(data, 'tenant.search.patients.searchResults', []) as PatientSearchResult[]
              patients = patients.map(obj => ({ ...obj, resultType: ResultTypes.patient }))
              let practitioners = _get<any, string, PractitionerSearchResult[]>(data, 'tenant.search.practitioner.searchResults', []) as PractitionerSearchResult[]
              practitioners = practitioners.map(obj => ({ ...obj, resultType: ResultTypes.practitioner }))
              return [...patients, ...practitioners]
            },
            variables: queryVariables,
            fetchPolicy: 'network-only'
          },
          initial: {
            gql: GET_RECENT_GLOBAL,
            get: (data) => {
              let patients = _get<any, string, PatientSearchResult[]>(data, 'tenant.search.patients.recent', []) as PatientSearchResult[]
              return patients.map(obj => ({ ...obj, resultType: ResultTypes.patient }))
            },
            variables: queryVariables,
            fetchPolicy: 'cache-first'
          }
        }}
        render={{
          value: () => '',
          option: (result, state) => (
            <GlobalSearchResult
              key={result.id}
              result={result}
              searchText={state.inputValue}
              dataTestId={`${dataTestId}-result-${state.index}`}
            />
          ),
          startAdornment: (result ) => (
            <SearchIconAdornment dataTestId={`${dataTestId}-searchIcon`}/>
          )
        }}
        renderInput={(params) => (
          <SimpleTextField
            {...params}
            dataTestId={`${dataTestId}-textfield`}
            fullWidth
            placeholder={"Search"}
            variant={'standard'}
            inputRef={inputRef}
            InputProps={{
              style: {height: '24px'},
              ...params.InputProps,
              startAdornment: <SearchIconAdornment dataTestId={`${dataTestId}-searchIcon`}/>,
              disableUnderline: true
            }}
            sx={{
              margin: '8px 0',
              p: 1,
              borderRadius: '8px',
              backgroundColor: '#fff'
            }}
            hideHelperText={true}
          />
        )}
      />
    </Box>
  )
}


