import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { DialogV2 } from 'saga-library/src'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useMutation } from '@apollo/client'
import { LIST_TASK_TEMPLATES, UPDATE_TASK_TEMPLATE } from '../../../../graphql-definitions'
import { TaskTemplate, TaskTemplateInput } from '../../../../types/tasks/TaskTemplate'
import { TaskTemplateForm } from './TaskTemplateForm'
import { taskTemplateDefaults } from './TaskTemplateDefaultValues'
import { schema } from './TaskTemplateValidationsSchema'
import _get from 'lodash/get'
import { convertTemplateInputToTemplateData } from './TaskTemplateUtil'
import { userDisplayName } from 'saga-library/src/util/formatting'

const FORM_NAME = "edit_task_template_form"

interface EditTaskTemplateDialogProps {
  open: TaskTemplate | null
  setOpen: (open: TaskTemplate | null) => void
}

export const EditTaskTemplateDialog = ({
  open: template,
  setOpen
}:EditTaskTemplateDialogProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const [updateTaskTemplate] = useMutation(UPDATE_TASK_TEMPLATE)

  const formMethods = useForm<TaskTemplateInput>({
    defaultValues: taskTemplateDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const onClose = () => {
    setOpen(null)
    reset(taskTemplateDefaults)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (template) {
      const taskTemplate: TaskTemplateInput = {
        ...template,
        typeId: template.type.id,
        practitionerId: template.practitioner?.id || '',
        endOffset: template.startOffset + template.duration,
        assignedTo: {
          value: template?.assignedRole?.id || template?.assignedUser?.id || '',
          label: template.assignedRole?.name || userDisplayName(template?.assignedUser?.firstName || null, template?.assignedUser?.lastName || null) || '',
          type: template.assignedRole?.id ? 'role' : 'user',
        },
      };

      reset(taskTemplate)
    }
  }, [template])

  useEffect(() => {
    if (template) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [template, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = handleSubmit( async(input: TaskTemplateInput) => {
    const data = convertTemplateInputToTemplateData(input)
    delete data.id
    await updateTaskTemplate({
      variables: {
        input: data,
        id: template?.id,
        tenantId: tenant_id,
      },
      onCompleted: () => {
        showSuccessAlert("Task template has been saved.")
        onClose()
      },
      onError: (error: any) => {
        const errors = error.networkError?.result?.errors || []
        if (errors.length > 0 && errors[0].extensions?.userError === true) {
          showErrorAlert(errors[0].message)
        } else {
          showErrorAlert("Task template couldn't be updated.")
        }
      }
    })
  })

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'edit-task-template-dialog'}
      formName={FORM_NAME}
      size={'sm'}
      title={'Edit task template'}
      submitting={isSubmitting}
      onClose={onCancel}
      open={!!template}
    >
      <FormProvider {...formMethods}>
        <TaskTemplateForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"edit-task-template-form"}
        />
      </FormProvider>
    </DialogV2>
  )
}