import React, { useState } from 'react'
import { Section, StyledTooltip, Typography } from 'saga-library/src'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { ReportQueryBuilder } from '../../../../components/ReportQueryBuilder/ReportQueryBuilder'
import { Box } from '@mui/material'
import InfoFilledIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const CriteriaTooltip = () => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      title={
        <Typography variant={'body1'}>Add criteria based on the columns across all related categories.</Typography>
      }
    >
      <Box
        color={(theme) => theme.palette.greys.medium}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {open
          ? <InfoFilledIcon fontSize={'medium'} />
          : <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}

export const ReportFormCriteria = ({ name, isLoading, disabled }: { name: string, isLoading: boolean, disabled?: boolean }) => {
  return (
    <Section.Container
      sx={{
        border: "1px solid rgba(32, 41, 49, 0.32)",
        borderRadius: "8px",
        flex: "1 1 auto",
        height: "100%"
      }}
    >
      <Section.ColumnNoElevation>
        <Box display={"flex"} alignItems={"center"} gap={1} py={1}>
          <Section.SubHeader variant={"h5"} sx={{ mb: 0 }}>
            Criteria
          </Section.SubHeader>
          <CriteriaTooltip />
        </Box>
        {isLoading ? <LoadingSpinner label={null} /> : <ReportQueryBuilder name={name} disabled={disabled} />}
      </Section.ColumnNoElevation>
    </Section.Container>
  )
}