import React, { useCallback, useMemo, useState } from 'react'
import Widget from '../Widget'
import { LetterIcon } from '../ChartIcons'
import { WidgetDateTableList } from "./components/WidgetDateTableList";
import { LetterModal } from "../letters/LetterModal";
import { LetterDocumentModal } from "../letters/LetterDocumentModal";
import { LetterType } from "../../../../types/patients";
import { useLetterContext } from "../../providers/LetterProvider";
import { useReadQuery } from "@apollo/client";
import { EditorImageProvider } from '../../../../providers/EditorImageContextProvider'

export const LetterWidget = () => {
  const [ openDialog, setOpenDialog]  = useState(false)
  const [ selectedLetter, setSelectedLetter ] = useState<LetterType|null>(null)

  const handleModalClose = useCallback(() => {
    setSelectedLetter(null)
    setOpenDialog(false)
  },[setSelectedLetter, setOpenDialog])

  return <Widget
    route={'letters'}
    title={'Letters'}
    WidgetIcon={LetterIcon}
    onAddItem={() => {setOpenDialog(true)}}
  >
    <LetterWidgetBody setSelectedLetter={setSelectedLetter} />
    {!selectedLetter?.isLinkedDocument
      ? (
        <EditorImageProvider>
          <LetterModal open={openDialog||!!selectedLetter} onModalClose={handleModalClose} letter={selectedLetter}/>
        </EditorImageProvider>
      )
      : <LetterDocumentModal letter={selectedLetter} onModalClose={handleModalClose} />
    }
  </Widget>
}


const LetterWidgetBody = ({ setSelectedLetter }) => {
  const { letterQueryRef, parseLetterQueryResults } = useLetterContext()
  const { data } = useReadQuery(letterQueryRef!)
  const patientLetters = useMemo(()=> parseLetterQueryResults(data), [data, parseLetterQueryResults])

  return (
    <>
      <WidgetDateTableList
        dataTestId={"letterWidget"}
        rows={patientLetters.map((letter:LetterType) => {
          return {
            displayDate: letter.letterDate,
            label: letter.title,
            subLabel: letter.notes,
            keyName: `letter_${letter.id}_${letter.isLinkedDocument?'d':'l'}`,
            onRowClick: () => { setSelectedLetter(letter)}
          }
        })}
        emptyListMessage={"Click the add button to add a letter."}
      />
    </>
  )
}




