import React from 'react'
import { Spinner } from 'saga-library/src'
import Box from '@mui/material/Box'
import { Backdrop, Paper, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export const LoadingScreen = ({ message }) => {
  return (
    <Box width={'100%'} paddingTop={'25%'}>
      <Spinner label={message} />
    </Box>
  )
}

export const LoadingOverlay = ({ message, loading, wholeScreen = true }) => {

  if(loading) {
    if (wholeScreen) {
      return (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!!loading}
        >
          <Paper sx={{ padding: 4 }}>
            <Spinner label={message} />
          </Paper>
        </Backdrop>
      )
    }
    return (
      <div style={{ position: 'relative', zIndex: 1}} >
        <Backdrop
          sx={{ color: '#fff', position: 'absolute', width: '45vw', height: '75vh' }}
          open={!!loading}
        >
          <Paper sx={{ padding: 4 }}>
            <Spinner label={message} />
          </Paper>
        </Backdrop>
      </div>
    )
  }

  return null
}

interface LoadingSpinnerProps {
  label?: string | null
  overlay?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  sx?: SxProps<Theme>
}

export const LoadingSpinner = ({label="Loading", overlay=false, size='xl', sx = {}}: LoadingSpinnerProps) => {
  const style = {
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: (theme) => theme.zIndex.drawer + 1
  }

  const overlayStyle = {
    ...style,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }

  const spinnerStyle = overlay ? overlayStyle : style

  return (
    <Box sx={{
      ...spinnerStyle,
      ...sx
    }}>
      <Spinner label={label} size={size} />
    </Box>
  )
}