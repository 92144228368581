import React from 'react'
import { Box } from '@mui/material'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { Typography } from 'saga-library/src'
import { SignedIcon } from '../ChartIcons'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import { getEncounterNoteSummary } from './EncounterNoteUtil'
import { EncounterNote } from '../../../../types/patients'


interface EncounterNotesRowInterface {
  encounterNote: EncounterNote,
  onRowClicked: () => void,
  dataTestId?: string
}

export const EncounterNotesRow = ({
  encounterNote,
  onRowClicked,
  dataTestId
}: EncounterNotesRowInterface) => {
  const formattedDate = useFormattedDate(encounterNote.encounterNoteDisplayDate)
  const [isMouseOver, setIsMouseOver] = React.useState(false)

  if (encounterNote) {
    const summary = getEncounterNoteSummary(encounterNote)

    return (
      <Box
        data-testid={dataTestId}
        key={`encounterNote_${encounterNote.isLinkedDocument ? 'l' : 'd'}_${encounterNote.id}`}
        onClick={() => onRowClicked()}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        sx={theme => ({
          borderRadius: '8px',
          border: `thin ${theme.palette.greys.light} solid`,
          overflow: 'hidden',
          '&:hover': {
            cursor: 'pointer'
          }
        })
        }
      >
        <Box
          sx={theme => ({
            background: isMouseOver ? theme.palette.backgrounds.hover : theme.palette.greys.extraLight,
            p: 1,
            display: 'flex'
          })}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            flex={'1 1 50%'}
          >
            <Typography
              dataTestId={dataTestId + '-title'}
              variant={'h5'}> {encounterNote.template?.name || encounterNote.description || 'Encounter Note'} </Typography>
            <Box display={'flex'} alignItems={'center'} gap={'4px'}>
              {
                encounterNote.appointment &&
                <CalendarMonthOutlinedIcon
                  data-testid={dataTestId + '-calendar-icon'}
                  style={{ height: '16px', width: '16px' }}
                />
              }
              <Typography
                variant={'body2'}
                dataTestId={dataTestId + '-date'}
              >
                {formattedDate}
              </Typography>
            </Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={1}
          >
            {encounterNote.signOffPit &&
              <SignedIcon
                style={{ height: '24px', width: '24px' }}
                data-testid={dataTestId + '-signed'}
              />
            }
            <Typography dataTestId={dataTestId + '-practitioner'}>
              {encounterNote.practitioner && practitionerDisplayName(encounterNote.practitioner.firstName, encounterNote.practitioner.lastName)}
            </Typography>
          </Box>
        </Box>
        {summary && <Box
          data-testid={dataTestId + '-summary'}
          sx={theme => ({
            borderTop: `thin ${theme.palette.greys.light} solid`,
            p: 1
          })}
        >
          <Typography>
            {summary}
          </Typography>
        </Box>}
      </Box>
    )
  }

  return null
}