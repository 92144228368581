import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReferralPractitionerForm } from './components/ReferralPractitionerForm'
import ReferralPractitionerHeader from "./components/ReferralPractitionerHeader";
import { ReferralPractitionerDetails, ReferralPractitionerDetailsType } from "./components/ReferralPractitionerDetails";
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { schema } from './util/referralPractitionerValidation'
import { useMutation } from '@apollo/client'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import {  SkillValue } from "components/SkillSelect/SkillSelect"
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ADD_NEW_REFERRAL_PRACTITIONER } from "../../../../graphql-definitions";
import { Province } from 'saga-library/src'
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";
import { flushSync } from "react-dom";

const FORM_NAME = 'new_referral_practitioner_form'

export const ReferralPractitionerNew = () => {
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id } = useParams()
  const { getTenantProvince } = useAccountContext()
  const provinceTenant: Province = getTenantProvince(tenant_id)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [name, setName] = useState('')
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const [addNewReferralPractitioner] = useMutation(
    ADD_NEW_REFERRAL_PRACTITIONER,
    {
      onCompleted: (data) => {
        if (!!data.tenant.referralPractitioner.create) {
          flushSync(() => {
            clearNavigationPrompt(FORM_NAME)
          })
          showSuccessAlert(`${name} has been saved.`)
          navigate(
            buildTenantRoute(
              `settings/practitioners/referral/r/${data.tenant.referralPractitioner.create}`,
              tenant_id
            )
          )
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Referral practitioner couldn\'t be created.')
      },
    }
  )

  const formMethods = useForm<ReferralPractitionerDetailsType>({
    defaultValues: {...ReferralPractitionerDetails.defaults, province: provinceTenant.code ?? ""},
    schema: schema,
  })
  const {
    handleSubmit,
    formState: { dirtyFields },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit(async (data) => {
    setName(data.lastName + ', ' + data.firstName)
    let {skills, ...rest} = data
    let skillIds = skills.map((skill:SkillValue) => skill.id)
    let refPracInput = {
      ...rest,
      skillIds
    }
    await addNewReferralPractitioner({
      variables: {
        tenantId: tenant_id,
        input: refPracInput,
      },
    })
  })

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn header={<ReferralPractitionerHeader />}>
        <ReferralPractitionerForm
          onSubmit={onSubmit}
          prevProvince={''}
        />
      </SettingsSectionColumn>
    </FormProvider>
  )
}

export default ReferralPractitionerNew
