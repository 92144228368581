import React, { useEffect } from "react";
import SettingsHeader from "./components/SettingsHeader";
import { Controller } from "saga-library/src/components/Form";
import { Typography, useForm, Form, SaveButton } from "saga-library/src";
import { useQuery } from "@apollo/client";
import { TimezoneSelect } from "../../components/Timezone";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../providers/AccountContext";
import { useTenantContext } from "../../providers/TenantContextProvider";
import { Permission, PermissionType } from "../../types/settings/Permission";
import { TIMEZONES } from "../../graphql-definitions";
import { ReadOnlyFormActionsMask } from "../../components/ReadOnlyFormActionsMask";
import { AllUsersAtClinicSetting } from "../../components/SettingScopeIcons"
import _get from "lodash/get";
import { tenantSettings } from "../../utils/TenantSettings";
import { LoadingOverlay } from "../../components/LoadingScreen";
import { Timezone } from "../../types";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";
import { FormProvider } from "react-hook-form";
import { usePrompt } from "../../providers/NavigationPrompt";

export const General = () => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const { getTenantSettings, updateTenantSetting } = useTenantContext()
  const { enableNavigationPrompt } = usePrompt()

  const { TIMEZONE } = tenantSettings
  const hasAdminReadWrite = tenant_id != null && userHasPermission(tenant_id, PermissionType.Admin, Permission.READWRITE)

  const FORM_NAME = 'general_settings'
  const formMethods = useForm<{timezone:Timezone}>({})
  const {
    handleSubmit,
    formState: { dirtyFields,isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const { loading, data: timezoneData } = useQuery(
    TIMEZONES, {
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      },
      onCompleted:  (data) => {
        const timezoneList = _get(timezoneData, 'static.timezones', [])
        const { timezone } = getTenantSettings([TIMEZONE])
        const tz = timezoneList.find((tz => tz.name === timezone))
        reset({timezone:tz})
      },
      fetchPolicy: 'cache-first'
    })

  const saveSetting = handleSubmit(async(data) => {
    const { timezone } = data
    if (timezone != null) {
      await updateTenantSetting(TIMEZONE, timezone.name)
        .then(() => {
          reset({},{keepValues: true})
        })
    }
  })

  if(loading){
    return <LoadingOverlay
      data-testid={'general-loading-overlay'}
      loading={loading}
      message={'Loading...'}
    />
  }

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'General'}
        actions={
          <ReadOnlyFormActionsMask requiredType={PermissionType.Admin} requiredPermission={Permission.READWRITE}>
            <SaveButton
              form={FORM_NAME}
              submitting={isSubmitting}
              dataTestId={'general'}
            />
          </ReadOnlyFormActionsMask>
        }
        dataTestId={'general'}
      />
    }>

      <FormProvider {...formMethods}>
        <Form onSubmit={saveSetting} id={FORM_NAME}>
          <Typography fontWeight={'bold'} gutterBottom>Clinic time zone</Typography>
          <AllUsersAtClinicSetting>
            <Controller
              name={'timezone'}
              control={formMethods.control}
              render={({field:{ onChange, value}}) => (
                <TimezoneSelect
                  dataTestId={'general-timezone'}
                  label={'Time zone'}
                  disabled={!hasAdminReadWrite}
                  value={value}
                  onChange={(e,v)=> {
                    onChange(v)
                  }}
                  sx={{
                    width: '380px'
                  }}
                />
              )}
            />
          </AllUsersAtClinicSetting>
        </Form>
      </FormProvider>
    </SettingsSectionColumn>
  )
}

