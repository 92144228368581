import React from 'react'
import { Box, Divider } from '@mui/material'
import { Form, TextField } from 'saga-library/src'
import { FormRow } from '../../../../components/FormRow'
import { AssignedTo } from '../../../../components/AssignedTo'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { TaskTemplateSelect } from '../../../../components/TaskTemplateSelect'
import { TaskStateSelect } from '../../../../components/TaskStateSelect'
import { PatientSearch } from '../../../../components/SearchControls/Patient/PatientSearch'
import { TaskTypeSelect } from '../../../../components/TaskTypeSelect'
import { TaskPrioritySelect } from '../../../../components/TaskPrioritySelect'
import { useFormContext } from 'react-hook-form'
import { rules } from './TaskValidationsSchema'

interface TaskFormProps {
  formName: string
  showTemplateSelect?: boolean
  onSubmit: () => void
  dataTestId: string
}

export const TaskForm = ({
  formName,
  showTemplateSelect = false,
  onSubmit,
  dataTestId
}: TaskFormProps) => {
  const { watch } = useFormContext()
  const id = watch('id')
  const version = watch('version')

  return (
    <Form onSubmit={onSubmit} id={formName}>
      {!showTemplateSelect && (
        <TaskTemplateSelect
          name={'template'}
          label={'Template'}
          dataTestId={`${dataTestId}-template`}
        />
      )}
      <FormRow>
        <TextField
          name={'name'}
          label={'Name'}
          inputProps={{ maxLength: rules.name.max }}
          fullWidth={true}
          dataTestId={`${dataTestId}-name`}
        />
        <TaskTypeSelect
          name={'typeId'}
          label={'Type'}
          sx={{ flexBasis: '206px' }}
          dataTestId={`${dataTestId}-type`}
        />
      </FormRow>
      <Divider />
      <FormRow>
        <Box flex={'1 1 50%'}>
          <AssignedTo
            name={'assignedTo'}
            label={'Assigned to'}
            includeRoles={true}
            dataTestId={`${dataTestId}-assignedTo`}
          />
          <FormRow>
            <TaskStateSelect
              name={'stateId'}
              label={'State'}
              dataTestId={`${dataTestId}-state`}
            />
            <TaskPrioritySelect
              name={'priority'}
              label={'Priority'}
              dataTestId={`${dataTestId}-priority`}
            />
          </FormRow>
          <FormRow>
            <FormattedDatePicker
              name={'startDate'}
              label={'Start date'}
              dataTestId={`${dataTestId}-startDate`}
            />
            <FormattedDatePicker
              name={'dueDate'}
              label={'Due date'}
              dataTestId={`${dataTestId}-dueDate`}
            />
          </FormRow>
          <PractitionerSelect
            name={'practitionerId'}
            label={'Practitioner'}
            dataTestId={`${dataTestId}-practitioner`}
          />
          <PatientSearch
            key={id && `${id}-${version}`}
            name={'patientId'}
            label={'Patient'}
            defaultStyle={'expanded'}
            fetchDetails={!!id}
            dataTestId={`${dataTestId}-patient`}
          />
          {/* TODO: SAGA-3143 */}
        </Box>
        <Box flex={'1 1 50%'}>
          <TextField
            name={'description'}
            label={'Description'}
            multiline={true}
            rows={3}
            fullWidth={true}
            dataTestId={`${dataTestId}-description`}
          />
        </Box>
      </FormRow>
    </Form>
  )
}