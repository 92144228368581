export type BillingProfile = {
  id: string | null
  name: string
  practitionerFirstName: string
  practitionerLastName: string
}

export enum BillingSettings {
  today = "today",
  last = "last"
}