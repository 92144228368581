import { useTheme } from '@mui/material';
import React from 'react'
import { PrescriptionStatus } from "../../../../../types/Prescription";
import { Tag } from "../../Tag";

interface PrescriptionStatusTagProps{
  prescriptionStatus?: PrescriptionStatus
}
export const PrescriptionStatusTag = ({prescriptionStatus}:PrescriptionStatusTagProps) => {
  const theme = useTheme()

  let tagColor = theme.palette.chart.greyTag
  if (prescriptionStatus === PrescriptionStatus.ACTIVE) {
    tagColor = theme.palette.chart.greenTag
  } else if (prescriptionStatus === PrescriptionStatus.DRAFT) {
    tagColor = theme.palette.chart.yellowTag
  }

  if(!!prescriptionStatus){
    return <Tag label={prescriptionStatus} color={tagColor} />
  }

  return null
}