import React, { useState } from "react";
import { TableList, TableListHeaderConfig } from "saga-library/src";
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useAlerts } from "saga-library/src/providers/Alerts";
import SettingsHeader from "../components/SettingsHeader";
import { LIST_LOCATIONS } from "../../../graphql-definitions";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import PermissionButton from "../../../components/PermissionButton";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { LOCATIONS_SCROLL_KEY } from '../../../providers/UserInteractionContext'

export const Locations = () =>{
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const [locations, setLocations] = useState([])

  const {loading }= useQuery(LIST_LOCATIONS, {
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setLocations(data.tenant.location.list)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Location list couldn\'t be retrieved.')
    },
    fetchPolicy: 'cache-and-network',
  })

  const NewLocationButton = () => {
    return <PermissionButton
      dataTestId={"locations-new-button"}
      name={"newLocation"}
      onClick={()=>navigate('new')}
      requiredType={PermissionType.Admin}
      requiredPermission={Permission.READONLY}
    >
      New Location
    </PermissionButton>
  }

  return <SettingsSectionColumn header={
    <SettingsHeader title={'Locations'} actions={<NewLocationButton />} dataTestId={'locations'} />
  }>
    <TableList
      scrollKey={LOCATIONS_SCROLL_KEY}
      columns={LocationColumns}
      rows={locations.map(location => LocationRow(location, navigate))}
      loading={loading}
      dataTestId={"locations-list"}
      leftAlignColumns={true}
    />
  </SettingsSectionColumn>
}

const LocationColumns: TableListHeaderConfig[] = [
  {name: 'Name'},
  {name: 'Address'}
]

export const formatLocationAddress = (location) => {
  const { street1, province, city, postalCode } = location

  return `${street1 ? street1 + ',' : ''} ${city ? city + ',' : ''} ${province ? province : ''} ${postalCode  ? postalCode : ''}`
}

const LocationRow = (location, navigate) => {
  if(location) {
    const { name, id } = location

    return ({
      onClick: () => navigate(`l/${id}`),
      key: id,
      rowData:[
        {
          data: name
        },
        {
          data: formatLocationAddress(location)
        }
      ]
    })
  }
  return {rowData: [], key: ""}
}

