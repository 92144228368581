import { DialogV2, RemoveButton } from 'saga-library/src'
import { FormProvider } from 'saga-library/src/components/Form'
import { TaskStateForm } from './TaskStateForm'
import React, { useEffect, useState } from 'react'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useFormContext } from 'react-hook-form'

interface TaskStateModalProps {
  title: string
  open: boolean
  onClose: () => void
  formName: string
  onSubmit: () => void
  onDelete?: () => void
}

export const TaskStateModal = ({
  title,
  open,
  onClose,
  formName,
  onSubmit,
  onDelete
}: TaskStateModalProps) => {
  const { enableNavigationPrompt } = usePrompt()
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)

  const formMethods = useFormContext()

  const {
    formState: {dirtyFields, isSubmitting},
  } = formMethods

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, formName)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);


  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'new-task-state-dialog'}
      title={title}
      open={open}
      onClose={handleClose}
      size={'xs'}
      formName={formName}
      submitting={isSubmitting}
      headerActions={ onDelete ? [<RemoveButton
        key={'editTaskStateModal-delete'}
        onClick={() => onDelete()}
        dataTestId={'editTaskStateModal'}
      />] : undefined }
    >
      <TaskStateForm
        formName={formName}
        onSubmit={onSubmit}
        dataTestId={'new-task-state-form'}
      />
    </DialogV2>
  )
}