import { gql } from "@apollo/client";

const APPOINTMENT_STATE_FRAGMENT = gql`
  fragment AppointmentStateFragment on AppointmentState {
    id
    name
    color
    category
    canEnterExamRoom
    blocksSchedule
    isDefault
    isDeleted
    version
  }
`

export const ADD_NEW_APPOINTMENT_STATE = gql`
  ${APPOINTMENT_STATE_FRAGMENT}
  mutation CreateAppointmentState($tenantId: ID!, $appointmentStateInput: AppointmentStateInput!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          create(newAppointmentState: $appointmentStateInput) {
            ...AppointmentStateFragment
          }
        }
      }
    }
  }
  `


export const LIST_APPOINTMENT_STATES = gql`
  ${APPOINTMENT_STATE_FRAGMENT}
  query ListAppointmentStates($tenantId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          listStates {
            ...AppointmentStateFragment
          }
        }
      }
    }
  }
 `

export const UPDATE_APPOINTMENT_STATE_SORT = gql`
  ${APPOINTMENT_STATE_FRAGMENT}
  mutation UpdateAppointmentStateSort($tenantId: ID!, $appointmentStateId: ID!, $version: String!, $index: Short!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          updateSort(id: $appointmentStateId, version: $version, index: $index) {
            ...AppointmentStateFragment
          }
        }
      }
    }
  }
`


export const GET_APPOINTMENT_STATE = gql`
  ${APPOINTMENT_STATE_FRAGMENT}
  query GetAppointmentState($tenantId: ID!, $appointmentStateId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          state(id: $appointmentStateId) {
            ...AppointmentStateFragment
          }
        }
      }
    }
  }
`

export const UPDATE_APPOINTMENT_STATE = gql`
  ${APPOINTMENT_STATE_FRAGMENT}
  mutation UpdateAppointmentState($tenantId: ID!, $appointmentStateId: ID!, $appointmentStateInput: AppointmentStateInput!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          update(id: $appointmentStateId, updatedAppointmentState: $appointmentStateInput) {
            ...AppointmentStateFragment
          }
        }
      }
    }
  }
`

export const DELETE_APPOINTMENT_STATE = gql`
  mutation DeleteAppointmentState($tenantId: ID!, $appointmentStateId: ID!, $version: String!) {
    tenant(id: $tenantId) {
      schedule {
        state {
          delete(id: $appointmentStateId, version: $version)
        }
      }
    }
  }
`