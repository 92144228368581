import React, { useEffect, useState } from "react";
import Chip from "./Chip";
import { ChipProps, Grid, Popover } from "@mui/material";
import Box from "@mui/material/Box";

type VariantType = "filled" | "outlined";

interface ChipBagProps<T extends ChipProps> {
  chips?: T[];
  chipVariant?: VariantType;
  sx?: any;
  onChipClick?: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  onDelete?: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  deleteIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  verticalAnchorOrigin?: "top" | "center" | "bottom";
  horizontalAnchorOrigin?: "left" | "center" | "right";
  allChips?: any;
  allChipsText?: string;
  dataTestId?: string;
}

export default function ChipBag<T extends {
  id: string;
  label: string;
  chips?: T[];
  chipVariant?: VariantType;
  sx?: any;
  onChipClick?: () => void;
  onDelete?: () => void;
  deleteIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  verticalAnchorOrigin?: "top" | "center" | "bottom";
  horizontalAnchorOrigin?: "left" | "center" | "right";
  allChips?: any;
  allChipsText?: string;
}>({
  chips = [],
  chipVariant,
  sx,
  onChipClick,
  onDelete,
  deleteIcon,
  verticalAnchorOrigin,
  horizontalAnchorOrigin,
  allChips,
  allChipsText = "All",
  dataTestId
}: ChipBagProps<T>) {
  const SHOW_ALL_BUTTON_NAME = "showAll";
  const CHIP_BASE_COUNT = 1;
  // The higher the padding number, the fewer chips will be shown.
  const CHIP_PADDING = 10;
  const DELETE_ICON_PADDING = onDelete ? 25 : 0;

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [visibleCount, setVisibleCount] = useState(CHIP_BASE_COUNT);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const visibleChips = chips.slice(0, visibleCount);
  const hiddenChips = chips.slice(visibleChips.length);

  let showAllButtonText = "+" + (chips.length - visibleCount);

  const containerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const parentWidth = containerRef?.current?.offsetWidth ?? 0;
      let chipsVisibleCount = CHIP_BASE_COUNT;

      let totalCharacterWidth = 0;
      for (let i = 0; i < chips.length; i++) {
        const currentChipWidth = getTextWidth(chips[i].label, chips[i].sx?.typography?.fontFamily);
        if ((totalCharacterWidth + currentChipWidth + CHIP_PADDING + DELETE_ICON_PADDING) < parentWidth) {
          // Padding is added to each chip.
          totalCharacterWidth += currentChipWidth + CHIP_PADDING + DELETE_ICON_PADDING;
          chipsVisibleCount += 1;
        }
      }

      setVisibleCount(chipsVisibleCount);
    };

    handleResize()
  },[])

  function getTextWidth(text, font) {
    // https://stackoverflow.com/questions/58704990/calculate-pixel-width-of-text-without-knowing-font-in-react-javascript
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      return 0;
    }

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  }


  // A 'dorito' is a Chip that can be reused and customized for the purpose of this ChipBag class.
  const dorito = (chip, dataTestId) => {
    return (
      <Chip
        key={chip.id}
        data-testid={dataTestId}
        label={chip.label}
        // This icon is show on the end of the Chip - doesn't necessarily delete anything, unless specified.
        // If no onDelete method is passed in, no icon is shown.
        // If no Icon is passed in, the default X is used.
        deleteIcon={deleteIcon}
        onDelete={onDelete ? (event) => { onDelete(event, chip.id) } : undefined}
        // chipVariant is the variant of the ChipBag.
        // chip.variant is the individual chip variant.
        variant={chipVariant ?? (chip.variant || "outlined")}
        sx={sx || chip.sx}
        onClick={(event) => {
          onChipClick?.(event, chip.id)
        }}
      />
    );
  };

  const onShowAllClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // This event.StopPropagation() is to prevent the parent from receiving the click event.
    // for example, if the ChipBag is in a table row, clicking the button will also trigger the row click without this.
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const entireBag = () => {
    return {
      id: "showAll",
      label: allChipsText
    };
  };

  return (
    <Box ref={containerRef} sx={{overflowX: "none"}}>
      <Grid container display={'flex'} flexWrap={'nowrap'}>
        { allChips
          ? (
              <Grid item>
                {
                  // Show a special chip that represents All Chips in one.
                  dorito(entireBag(), `${dataTestId}-all`)
                }
              </Grid>
          )
          : (
            <>
              <Grid item>
                {
                  // Here we show the limited visible chips if the popover is still closed.
                  visibleChips.map((chip, index) => (
                    dorito(chip, `${dataTestId}-limited-${index}`)
                  ))
                }
              </Grid>
              <Grid item>
                {
                  chips.length > visibleCount &&
                  (
                    <Box>
                      <Chip
                        key={SHOW_ALL_BUTTON_NAME}
                        label={showAllButtonText}
                        variant={chipVariant ?? "outlined"}
                        onClick={onShowAllClick}
                        data-testid={`${dataTestId}-showAll`}
                      />
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: verticalAnchorOrigin ?? "bottom",
                          horizontal: horizontalAnchorOrigin ?? "left"
                        }}
                      >
                        <Box sx={{
                          paddingTop: '8px',
                          paddingLeft: '8px',
                          paddingRight: '8px',
                        }}>
                          {
                            // Here we show the hidden chips if the popover is opened.
                            hiddenChips.map((chip, index) => (
                              <Box sx={{
                                paddingBottom: '8px',
                              }}>
                                {dorito(chip, `${dataTestId}-hidden-${index}`)}
                              </Box>
                            ))
                          }
                        </Box>
                      </Popover>
                    </Box>
                  )
                }
              </Grid>
            </>
          )
        }
      </Grid>
    </Box>
  );
}