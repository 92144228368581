import _get from "lodash/get";
import { ControlledSearch } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PharmacyResult } from "./PharmacyResult";
import { GET_SEARCH_PHARMACIES } from "../../../graphql-definitions";
import { Pharmacy } from "../../../types";
import { PharmacyModal } from "./PharmacyModal";

export interface PharmacySearchProps<
  T = Pharmacy,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
  selectedOptions: any[]
  autoFocusOnOpen: boolean
  append: (newPharmacy?: Pharmacy) => void
}

export const PharmacySearch = ({
  name = 'pharmacyIds',
  label = 'Pharmacies',
  selectedOptions,
  autoFocusOnOpen,
  append,
  ...props
} :PharmacySearchProps) => {

  const { tenant_id } = useParams()
  const [openPharmacyModal, setOpenPharmacyModal] = useState<boolean>(false)

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  return (
    <>
      <ControlledSearch<Pharmacy, false, false, false>
        {...props}
        autoFocus={autoFocusOnOpen}
        name={name}
        label={label}
        queries={{
          search: {
            gql: GET_SEARCH_PHARMACIES,
            get: (data) => _get(data, 'tenant.search.pharmacies.searchResults', []) as Pharmacy[],
            variables: queryVariables,
            fetchPolicy: 'cache-and-network'
          }
        }}
        render={{
          value: (pharmacy) => pharmacy.name,
          option: (pharmacy, state) => (
            <PharmacyResult
              pharmacy={pharmacy}
              searchText={state.inputValue}
            />
          )
        }}
        getOptionDisabled={ (option) =>
          selectedOptions.some((selectedOption) => option.id === selectedOption.id)
        }
        addNewName={'pharmacy'}
        addNewOnClick={() => {
          setOpenPharmacyModal(true)
        }}
      />
      <PharmacyModal
        open={openPharmacyModal}
        onModalClose={(newPharmacy) => {
          if(newPharmacy) {
            append(newPharmacy)
          }
          setOpenPharmacyModal(false)
        }}
      />
    </>

  )
}
