import React from 'react'
import { Box, MenuItem } from "@mui/material";
import {
  Checkbox,
  Section,
  Select,
  TextField
} from "saga-library/src";
import { InterceptReason, PayToCode, PayToOptions } from "../../../../../utils/ABClaimConstants";
import { InterceptReasonSelect } from "../../../../../components/InterceptReasonSelect/InterceptReasonSelect";
import { useWatch } from "saga-library/src/components/Form";
import { MultipleValuesField } from "../components/MultipleValuesField";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import { BatchControlledNewbornCodeSelect } from "../components/BatchControlledNewbornCodeSelect";
import { BatchControlledTextFieldMask } from "../components/BatchControlledTextFieldMask";
import {
  batchUpdateStyleSearch,
  batchUpdateStyleSelect,
  batchUpdateStyleSelectLabel
} from "../components/BatchUpdateStyle";
import { BatchControlledSkillSelect } from "../components/BatchUpdateSkillSelect";


const Form = ({ control, errors, resetField, dirtyFields, batchUpdateMultiple, setValue, register }) => {
  const newBornCode = useWatch({
    control,
    name: 'newBornCode',
  })

  return (
    <Box display={'flex'} flexDirection={'row'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          m: 1,
          p: 2,
          width: '100%',
        }}
      >
        <Section.Header>Options</Section.Header>
        <Checkbox
          name={'paperDocumentation'}
          dataTestId={'BatchUpdateClaimOptions-paperDocumentation'}
          label={'Paper documentation to follow'}
        />
        <Checkbox
          name={'confidential'}
          dataTestId={'BatchUpdateClaimOptions-confidential'}
          label={'Confidential'}
        />
        <Section.Header>Original encounter information</Section.Header>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-originalEncounterDate'}
          label={'Original encounter date'}
          multipleValuesState={batchUpdateMultiple.originalEncounterDate}
          setUpdatedFields={() => setValue('originalEncounterDate', '', { shouldDirty: true })}
        >
          <BatchControlledDatePicker
            label={'Original encounter date'}
            name={'originalEncounterDate'}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-originalFacility'}
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.originalFacilityId}
          setUpdatedFields={() => {
            setValue('originalFacility', '', { shouldDirty: true })
          }}
        >
          <BatchControlledFacilityInput
            name={'originalFacility'}
            label={'Facility'}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-originalLocationCode'}
          label={'Original location'}
          multipleValuesState={batchUpdateMultiple.originalLocationCode}
          setUpdatedFields={() => {
            setValue('originalLocationCode', '', { shouldDirty: true })
          }}
        >
          <BatchControlledLocationCodeSelect
            label={'Original location'}
            name={'originalLocationCode'}
          />
        </MultipleValuesField>
        <Section.Header>Patient</Section.Header>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-newBornCode'}
          label={'Newborn'}
          multipleValuesState={batchUpdateMultiple.newBornCode}
          setUpdatedFields={() => {
            setValue('newBornCode', '', { shouldDirty: true })
          }}
        >
          <BatchControlledNewbornCodeSelect
            label={'Newborn'}
            name={'newBornCode'}
          />
        </MultipleValuesField>
        {
          newBornCode !== null && (
            <>
              <MultipleValuesField
                dataTestId={'BatchUpdateClaimOptions-guardianUli'}
                label={'Guardian/parent ULI'}
                multipleValuesState={batchUpdateMultiple.guardianUli}
                setUpdatedFields={() => {
                  setValue('guardianUli', '', { shouldDirty: true })
                }}
              >
                <BatchControlledTextFieldMask
                  label={'Guardian/parent ULI'}
                  name={'guardianUli'}
                />
              </MultipleValuesField>
              <MultipleValuesField
                dataTestId={'BatchUpdateClaimOptions-guardianRegNumber'}
                label={'Guardian/parent registration number'}
                multipleValuesState={batchUpdateMultiple.guardianRegNumber}
                setUpdatedFields={() => {
                  setValue('guardianRegNumber', '', { shouldDirty: true })
                }}
              >
                <TextField
                  name={'guardianRegNumber'}
                  label={'Guardian/parent registration number'}
                  type={"number"}
                  sx={batchUpdateStyleSearch(dirtyFields.guardianRegNumber)}
                  autoFocus={true}
                />
              </MultipleValuesField>
            </>
          )
        }
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          m: 1,
          p: 2,
          width: '100%',
          overflowX: 'hidden'
        }}
      >
        <Section.Header>Practitioner</Section.Header>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-skill'}
          label={'Skill'}
          multipleValuesState={batchUpdateMultiple.skillId}
          setUpdatedFields={() => {
            setValue('skillId', '', { shouldDirty: true })
          }}
        >
          <BatchControlledSkillSelect />
        </MultipleValuesField>
        <Section.Header>Payment</Section.Header>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-payToCode'}
          label={'Pay to'}
          multipleValuesState={batchUpdateMultiple.payToCode}
          setUpdatedFields={() => {
            setValue('payToCode', PayToCode.BAPY, { shouldDirty: true })
          }}
        >
          <Select
            label={'Pay to'}
            name={'payToCode'}
            sx={batchUpdateStyleSelect(dirtyFields.payToCode)}
            inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields.payToCode)}
          >
            {PayToOptions.map(({label, value}) =>
              <MenuItem value={value}>{label}</MenuItem>
            )}
          </Select>
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-payToUli'}
          label={'Pay to ULI'}
          multipleValuesState={batchUpdateMultiple.payToUli}
          setUpdatedFields={() => {
            setValue('payToUli', '', { shouldDirty: true })
          }}
        >
          <TextField
            name={'payToUli'}
            label={'Pay to ULI'}
            type={'number'}
            sx={batchUpdateStyleSearch(dirtyFields.payToUli)}
            InputProps={register('payToUli', { valueAsNumber: true })}
          />
        </MultipleValuesField>
        <MultipleValuesField
          dataTestId={'BatchUpdateClaimOptions-interceptReason'}
          label={'Intercept reason'}
          multipleValuesState={batchUpdateMultiple.interceptReason}
          setUpdatedFields={() => {
            setValue('interceptReason', InterceptReason.HOLD, { shouldDirty: true })
          }}
          >
        <InterceptReasonSelect
          name={'interceptReason'}
          label={'Intercept reason'}
          sx={batchUpdateStyleSelect(dirtyFields.interceptReason)}
          inputLabelStyle={batchUpdateStyleSelectLabel(dirtyFields.interceptReason)}
        />
        </MultipleValuesField>
      </Box>
    </Box>
  )
}

export const BatchUpdateClaimOptions = {
  Form: Form,
}
