import React from "react"
import { ChipInput, Chip, Typography } from "saga-library/src/"
import { ReferralPractitioner } from "../types/settings";
import { useLazyQuery } from "@apollo/client";
import { REFERRAL_PRACTITIONER_SEARCH } from "../graphql-definitions";
import { useParams } from "react-router-dom";
import { debounce } from "@mui/material/utils";
import { DEBOUNCE_WAIT_TIME } from "saga-library/src/components/SearchControl/types";
import _get from "lodash/get";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { NoOptions } from "saga-library/src/components/SearchControl/SearchControl";
import { useAccountContext } from "../providers/AccountContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";


interface ReferralPractitionerChipSelectProps {
  label?: string
  name: string
  sx?: SxProps<Theme>
  onChange?: (event: React.SyntheticEvent, value: any) => void
  disabled?: boolean
  dataTestId?: string
}
export const ReferralPractitionerChipSelect = ({
  label="Referral practitioners",
  name,
  sx,
  onChange,
  disabled,
  dataTestId
}: ReferralPractitionerChipSelectProps) => {
  const { tenant_id } = useParams()
  const { getValues } = useFormContext()
  const { buildTenantRoute } = useAccountContext()

  const [options, setOptions] = React.useState<{label: string, value:string}[]>([])
  const [inputValue, setInputValue] = React.useState<string|null>(null)

  const [runSearch] = useLazyQuery(
    REFERRAL_PRACTITIONER_SEARCH,
    {
      fetchPolicy: 'network-only',
    }
  )

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: ReferralPractitioner[]) => void,
        ) => {
          setOptions([])
          runSearch({
            variables: {
              searchParam: request.input,
              tenantId: tenant_id
            },
            onCompleted: (data) => {
              callback(_get(data, 'tenant.search.referralPractitioner.searchResults', []) as ReferralPractitioner[])
            },
            onError: (err) => {
              console.error(err)
            },
          })
        },
        DEBOUNCE_WAIT_TIME,
      ),
    [],
  )


  React.useEffect(() => {
    let active = true
    if(inputValue) {
      fetch({ input: inputValue }, (results?: ReferralPractitioner[]) => {
        if (active) {
          let filteredResults: ReferralPractitioner[] = results || []
          setOptions(filteredResults.map( r => {
            return {
              label: `${r.lastName}, ${r.firstName}`,
              value: r.id
            }
          }))
        }
      })
    }
    return () => {
      active = false
    }
  }, [inputValue])

  const onInputChange = (event, value) => {
    setInputValue(value)
  }

  return <ChipInput
    name={name}
    label={label}
    dataTestId={`${dataTestId}-chip-input`}
    options={options}
    sx={sx}
    freeSolo={false}
    disableClearable={true}
    popupIcon={null}
    noOptionsText={<NoOptions inputValue={inputValue} />}
    getOptionDisabled={(option) => getValues(name).some((selectedOption) => option.value === selectedOption.value)}
    renderTags={(value, getTagProps) => {
      return value.map((option, index) => {
        const chipDataTestId = `${dataTestId}-chip-${index}`
        return <Chip
          data-testid={chipDataTestId}
          {...getTagProps({ index })}
          label={
            <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={'center'}>
              <Typography variant={"p2"}>
                {option.label}
              </Typography>
              <Box
                onClick={() =>window.open(buildTenantRoute(`settings/practitioners/referral/r/${option.value}`, tenant_id))}
                alignItems={'center'}
                display={'flex'}
                sx={{
                  borderRadius: '4px',
                  "&:hover": {
                    cursor: 'pointer',
                    backgroundColor: 'backgrounds.hover'
                  }
                }}
                data-testid={`${chipDataTestId}-openInNew-button`}
              >
                <OpenInNewIcon sx={{fontSize:16, color:'greys.medium'}} data-testid={`${chipDataTestId}-openInNew-icon`} />
              </Box>
            </Box>
          }
          variant={'outlined'}
          size={'small'}
        />
      })
    }}
    onInputChange={onInputChange}
    onChange={(e, v)=>{
      setOptions([])
      if(onChange){
        onChange(e,v)
      }
    }}
    disabled={disabled}
  />
}