import { gql } from "@apollo/client";

export const CREATE_NEW_TEMPLATE = gql`
  mutation CreateTemplate($tenantId: ID!, $templateInput: TemplateInput!) {
    tenant(id: $tenantId) {
      schedule {
        createTemplate(input: $templateInput)
      }
    }
  }
`

export const LIST_TEMPLATES = gql`
  query ListTemplates($tenantId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        listTemplates {
          id
          name
          templateDays {
           day 
          }
        }
      }
    }
  }
  `

export const GET_TEMPLATE = gql`
  query GetTemplate($tenantId: ID!, $templateId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        template(templateId: $templateId) {
          id
          name
          version
          templateDays {
            id
            startTime
            endTime
            day
            templateBlocks {
              id
              version
              day
              startTimeSpan 
              length
              title
              blockType
              allDay
              appointmentTypes {
                id
                name
              }
            }
          }
        }
      }
    }
  }
  `

export const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      schedule {
        deleteTemplate(id: $id, version: $version)
      }
    }
  }
`

export const ADD_NEW_TEMPLATE_BOOKING_PREFERENCE = gql`
  mutation addNewTemplateBookingPreference($tenantId: ID!, $input: TemplateBlockInput!) {
    tenant(id: $tenantId) {
      schedule {
        createTemplateBookingPreference(input: $input)
      }
    }
  }
`

export const APPLY_TEMPLATE = gql`
  mutation ApplyTemplate($tenantId: ID!, $scheduleId: ID!, $version: String!, $templateId: ID!, $scheduleDate: Date!) {
    tenant(id: $tenantId) {
      schedule {
        applyTemplateToSchedule(scheduleId: $scheduleId, version: $version, templateId: $templateId, scheduleDate: $scheduleDate) 
      }
    }
  }
`

export const REMOVE_TEMPLATE = gql`
  mutation RemoveTemplate($tenantId: ID!, $scheduleId: ID!, $version: String!, $startDate: Date!, $endDate: Date!) {
    tenant(id: $tenantId) {
      schedule {
        removeTemplateFromSchedule(scheduleId: $scheduleId, version: $version, startDate: $startDate, endDate: $endDate) 
      }
    }
  }
`

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate($tenantId: ID!, $id: ID!, $templateInput: TemplateInput!) {
    tenant(id: $tenantId) {
      schedule {
        updateTemplate(id: $id, input: $templateInput) {
          id
          name
          templateDays {
            startTime
            endTime
            day
            templateBlocks {
              id
              version
              day
              startTimeSpan 
              length
              title
              blockType
              allDay
              appointmentTypes {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`