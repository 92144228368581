import React from 'react'
import { TextField } from '../TextField'
import Box from '@mui/material/Box'

export interface AddressLinesProps {
  inputProps?: object
  labels: string[]
  names: string[]
  showAddressLabelField?: boolean
  addressLabel?: string
  disabled?: boolean
  dataTestId?: string
  firstFieldRef?: any
}

export const AddressLines = ({
  inputProps,
  labels,
  names,
  showAddressLabelField = false,
  addressLabel = '',
  disabled = false,
  dataTestId,
  firstFieldRef,
}: AddressLinesProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      { showAddressLabelField &&
        <TextField
          dataTestId={`${dataTestId}-labelfield`}
          name={'label'}
          label={addressLabel}
          disabled={disabled}
        />
      }
      <TextField
        dataTestId={`${dataTestId}-street`}
        label={labels[0]}
        name={names[0]}
        inputProps={inputProps}
        disabled={disabled}
      />
      <TextField
        dataTestId={`${dataTestId}-street2`}
        label={labels[1]}
        name={names[1]}
        inputProps={inputProps}
        disabled={disabled}
        inputRef={firstFieldRef}
      />
      <TextField
        dataTestId={`${dataTestId}-street3`}
        label={labels[2]}
        name={names[2]}
        inputProps={inputProps}
        disabled={disabled}
      />
    </Box>
  )
}

export default AddressLines
