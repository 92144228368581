//https://github.com/foxitsoftware/foxit-pdf-sdk-web-demo/blob/main/examples/overlay-comparison/src/foxit-sdk.ts

import * as PDFViewCtrl from "@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/PDFViewCtrl.full";
import { PDFUI as PDFUIType } from "@foxitsoftware/foxit-pdf-sdk-for-web-library"
import { PDF } from "@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/PDFViewCtrl.full";

export { PDFViewCtrl };

export type PDFUI = PDFUIType;
export type PDFDoc = PDFViewCtrl.PDF.PDFDoc;
export type PDFViewer = PDFViewCtrl.PDFViewer;
export const ViewerEvents = PDFViewCtrl.constants.ViewerEvents;
export type ViewerEventsType = PDFViewCtrl.constants.ViewerEvents;
export const DataEvents = PDF.constant.DataEvents;
export type DataEventsType = PDF.constant.DataEvents;
export type PDFForm = PDF.form.PDFForm;
export type PDFField = PDF.form.PDFField;
export const FieldTypes = PDF.form.constant.Field_Type;
export const PDFAnnotType = PDFViewCtrl.PDF.annots.constant.Annot_Type;
export const PDFAnnotFlag = PDFViewCtrl.PDF.annots.constant.Annot_Flags;