import React, { useEffect } from "react";
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { LinkedDocumentEditor } from "../LinkedDocumentForm/LinkedDocumentEditor";
import { useParams } from "react-router-dom";
import { useLetterContext } from "../../providers/LetterProvider";
import { useReadQuery } from "@apollo/client";
import _get from "lodash/get";
import { LetterType } from "../../../../types/patients";


const FORM_NAME = "letter_document_form"

export const LetterDocumentEditor = () => {
  const { document_id } = useParams()
  const { letterQueryRef } = useLetterContext()
  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)
  const { data } = useReadQuery(letterQueryRef!)
  useEffect(() => {
    if(document_id){
      const letter = (_get(data, 'tenant.patient.letter.list', []) as Array<LetterType>).find(document => document.id === document_id && document.isLinkedDocument)
      if(letter) {
        setDocument(letter.linkedDocument)
      }
    }
  }, [document_id, data]);

  return (
    <LinkedDocumentEditor
      pathName={'letters'}
      formName={FORM_NAME}
      document={document}
      category={DocumentCategory.LETTER}
      sectionLabel={document?.description || "Letter"}
      dataTestId={"letterDocument-editor"}
    />
  )
}