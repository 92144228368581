import * as yup from 'yup'
import { PostalCodeValidation } from 'saga-library/src/components/PostalCodeField'
import { PhoneValidation } from 'saga-library/src/components/PhoneField'
import _get from 'lodash/get'

export const rules = {
  firstName: { max: 35 },
  lastName: { max: 35 },
  middleName: { max: 35 },
  email: { max: 320 },
  phone: {
    number: { max: 15 },
    notes: { max: 48 },
  },
  address: {
    street: { max: 100 },
    city: { max: 100 },
    province: { max: 2 },
    postalCode: { max: 10 },
    country: { max: 100 }
  },
  practitionerId: { max: 10 },
  connectCareProviderId: { max: 35 },
  connectCareSubmitterId: { max: 35 },
  connectCareDepartmentId: { max: 35 },
  billingProfileInputs: {
    name: { max: 255 },
    functionalCenter: { max: 4 },
    wcbBillingNumber: { max: 12 },
    dndNumber: { max: 30 },
  },
}

const maxLengthMessage = (value) => `Max length of ${value}`

const nullIfEmpty = (value) => (!value ? null : value)

const nullIfEmptyPhoneNumber = (object) => (!object?.number ? null : object)

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    lastName: yup
      .string()
      .max(rules.lastName.max, maxLengthMessage(rules.lastName.max))
      .required('Required'),
    firstName: yup
      .string()
      .max(rules.firstName.max, maxLengthMessage(rules.firstName.max))
      .required('Required'),
    middleName: yup
      .string()
      .max(rules.middleName.max, maxLengthMessage(rules.middleName.max))
      .nullable(),
    email: yup
      .string()
      .max(rules.email.max, maxLengthMessage(rules.email.max))
      .email('Invalid email')
      .nullable(),
    phone: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        type: yup.string().required('Required'),
        number: yup
          .string()
          .max(rules.phone.number.max, maxLengthMessage(rules.phone.number.max))
          .matches(PhoneValidation.regex, 'Invalid phone number')
          .required('Required'),
        notes: yup
          .string()
          .max(rules.phone.notes.max, maxLengthMessage(rules.phone.notes.max))
          .nullable(),
      })
      .nullable(),
    street: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    street2: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    street3: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    city: yup
      .string()
      .max(rules.address.city.max, maxLengthMessage(rules.address.city.max))
      .nullable(),
    province: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable()
      .length(rules.address.province.max, 'Invalid province code'),
    postalCode: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable()
      .matches(PostalCodeValidation.regex, 'Invalid postal code'),
    country: yup
      .string()
      .max(rules.address.country.max, maxLengthMessage(rules.address.country.max))
      .nullable(),
    practitionerId: yup
      .string()
      .max(rules.practitionerId.max, maxLengthMessage(rules.practitionerId.max))
      .nullable(),
    billingProfileInputs: yup.array().of(
      yup.object().shape({
        baNumber: yup.number().nullable(),
        facilityId: yup.string().nullable(),
        locumBaNumber: yup
          .number()
          .transform((value) => {
            return isNaN(value) ? null : value
          })
          .nullable(),
        skill: yup
          .object()
          .nullable(),
        name: yup
          .string()
          .max(
            rules.billingProfileInputs.name.max,
            maxLengthMessage(rules.billingProfileInputs.name.max)
          )
          .nullable(),
        functionalCenterId: yup
          .string()
          .max(
            rules.billingProfileInputs.functionalCenter.max,
            maxLengthMessage(rules.billingProfileInputs.functionalCenter.max)
          )
          .nullable(),
        locationCode: yup
          .string()
          .nullable(),
        wcbBillingNumber: yup
          .string()
          .max(
            rules.billingProfileInputs.wcbBillingNumber.max,
            maxLengthMessage(rules.billingProfileInputs.wcbBillingNumber.max)
          )
          .nullable(),
        dndNumber: yup
          .string()
          .max(
            rules.billingProfileInputs.dndNumber.max,
            maxLengthMessage(rules.billingProfileInputs.dndNumber.max)
          )
          .nullable(),
      })
    ),
  })
  .required()
