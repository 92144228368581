import { TableList, TableListHeaderConfig } from 'saga-library/src'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import _get from 'lodash/get'
import Box from '@mui/material/Box'
import { StaticPage } from '../../../components/Layouts'
import { GET_TENANT_IDENTIFIERS } from '../../../graphql-definitions'
import SettingsSubHeader from './SettingsSubHeader'
import { IdentifierRow } from './IdentifierRow'
import { useAccountContext } from '../../../providers/AccountContext'
import { EditIdentifierModal } from "./EditIdentifierModal";

const columns: TableListHeaderConfig[] = [
  { name: 'Identifier' },
  { name: 'Searchable' },
  { name: 'Add to all patients' },
  { name: 'Auto-generate identifier' }
]

export const DefaultIdentifierList = () => {
  const { tenant_id } = useParams()
  const { getTenantProvince } = useAccountContext()
  const [identifiers, setIdentifiers] = useState<any[]>([])
  const [editIdentifier, setEditIdentifier] = useState(null)

  const { loading, error, data: identifierData } = useQuery(GET_TENANT_IDENTIFIERS, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  useEffect(() => {
    const tenantIdentifiers = _get(identifierData, 'tenant.identifier.list', null)
    setIdentifiers(tenantIdentifiers)
  }, [identifierData])

  const provinceIdentifiers = identifiers.filter((i) => i.province === getTenantProvince(tenant_id).code)
  const oopIdentifiers = identifiers.filter((i) => i.province !== getTenantProvince(tenant_id).code)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SettingsSubHeader
        title={'Default identifiers'}
        description={'Identifiers are unique numeric strings used to identify users in Saga Health.'}
      />
      <TableList
        size={'small'}
        loading={loading}
        enableSorting={false}
        columns={columns}
        rows={[...provinceIdentifiers.map((identifier) => IdentifierRow(identifier, setEditIdentifier))]}
        leftAlignColumns={true}
      />
      <SettingsSubHeader
        title={'Out of province identifiers'}
        description={'Primary identifiers from other provinces come built-in to Saga, but do not appear on patient profiles by default.'}
        sx={{ mt: 4 }}
      />
      <TableList
        size={'small'}
        loading={loading}
        enableSorting={false}
        columns={columns}
        rows={[...oopIdentifiers.map((identifier) => IdentifierRow(identifier, setEditIdentifier))]}
        leftAlignColumns={true}
      />
      {editIdentifier && <EditIdentifierModal
        identifier={editIdentifier}
        onClose={()=>setEditIdentifier(null)}
      /> }
      {error && (
        <StaticPage>
          Sorry, an error occurred loading the identifiers list.
        </StaticPage>
      )}
    </Box>
  )
}
