import { RuleType } from 'react-querybuilder'
import {
  REPORT_COLUMNS,
  REPORT_COMBINATORS,
  REPORT_OPERATORS,
  ReportColumnType,
  ReportCombinatorType,
  ReportOperatorType,
  ReportQueryInput
} from '../../types/Report'
import { REPORT_COLUMN_GROUPS } from '../../apps/reports/components/ReportUtil'

export const validator = (r: RuleType) => !!r.value

export const defaultReportQueryInput: ReportQueryInput = { column: null, operator: ReportOperatorType.EQUAL, value1: "", value2: "" }

export const defaultReportGroupQueryInput: ReportQueryInput = { column: null, operator: ReportOperatorType.GROUP, rules: [defaultReportQueryInput], value1: "", value2: ""}

export const combinators = [
  ReportCombinatorType.AND,
  ReportCombinatorType.OR
].map(combinator => ({ name: combinator, value: combinator, label: REPORT_COMBINATORS[combinator] } as const))

export const fields = (
  REPORT_COLUMN_GROUPS.flatMap((group) =>
    group.columns.map((column) => {
      const columnData = REPORT_COLUMNS[column]
      return {
        name: column,
        label: columnData.selectedLabel || columnData.label,
        inputType: columnData.inputType,
        Component: columnData.Component,
        validator
      }
    })
  )
)

export const operators = [
  ReportOperatorType.EQUAL,
  ReportOperatorType.NOT_EQUAL,
  ReportOperatorType.LESS_THAN,
  ReportOperatorType.GREATER_THAN,
  ReportOperatorType.LESS_THAN_OR_EQUALS,
  ReportOperatorType.GREATER_THAN_OR_EQUALS,
  ReportOperatorType.CONTAINS,
  ReportOperatorType.BEGINS_WITH,
  ReportOperatorType.ENDS_WITH,
  ReportOperatorType.DOES_NOT_CONTAIN,
  ReportOperatorType.IS_NULL,
  ReportOperatorType.IS_NOT_NULL,
  ReportOperatorType.IS_EMPTY,
  ReportOperatorType.IS_NOT_EMPTY,
  ReportOperatorType.IN,
  ReportOperatorType.NOT_IN,
  ReportOperatorType.BETWEEN,
  ReportOperatorType.NOT_BETWEEN
].map(operator => ({ name: operator, value: operator, label: REPORT_OPERATORS[operator] } as const))

export const getOperators = (field: string) => {
  const defaultOperators = [ReportOperatorType.IS_NULL, ReportOperatorType.IS_NOT_NULL, ReportOperatorType.IS_EMPTY, ReportOperatorType.IS_NOT_EMPTY]

  switch (field) {
    case ReportColumnType.LINKED_DOCUMENT_CATEGORY:
    case ReportColumnType.OUTCOME:
    case ReportColumnType.PATIENT_GENDER:
    case ReportColumnType.PATIENT_IDENTIFIER_TYPE:
    case ReportColumnType.PRACTITIONER:
    case ReportColumnType.PRACTITIONER_BILLING_PROFILE:
    case ReportColumnType.PRESCRIPTION_STATUS:
    case ReportColumnType.STATE:
    case ReportColumnType.TYPE:
      return getOperatorOptions([
        ReportOperatorType.EQUAL,
        ReportOperatorType.NOT_EQUAL,
        ...defaultOperators
      ])
    case ReportColumnType.DIAGNOSES:
    case ReportColumnType.FACILITY:
    case ReportColumnType.PATIENT_IDENTIFIER:
    case ReportColumnType.LAB_RESULT_DESCRIPTION:
    case ReportColumnType.LINKED_DOCUMENT_DESCRIPTION:
    case ReportColumnType.NOTES:
    case ReportColumnType.PATIENT:
    case ReportColumnType.PATIENT_NAME:
    case ReportColumnType.PATIENT_POSTAL_CODE:
    case ReportColumnType.PRESCRIPTION:
    case ReportColumnType.SERVICE_CODE:
      return getOperatorOptions([
        ReportOperatorType.EQUAL,
        ReportOperatorType.NOT_EQUAL,
        ReportOperatorType.CONTAINS,
        ReportOperatorType.BEGINS_WITH,
        ReportOperatorType.ENDS_WITH,
        ReportOperatorType.DOES_NOT_CONTAIN,
        ...defaultOperators
      ])
    case ReportColumnType.CALLS:
    case ReportColumnType.CLAIMED:
    case ReportColumnType.DATE:
    case ReportColumnType.LAB_RESULT_DATE:
    case ReportColumnType.PAID:
    case ReportColumnType.PATIENT_AGE:
    case ReportColumnType.PATIENT_DATE_OF_BIRTH:
    case ReportColumnType.PATIENT_LAST_APPOINTMENT_DATE:
    case ReportColumnType.PRESCRIPTION_DATE:
    case ReportColumnType.SERVICE_DATE:
      return getOperatorOptions([
        ReportOperatorType.EQUAL,
        ReportOperatorType.NOT_EQUAL,
        ReportOperatorType.LESS_THAN,
        ReportOperatorType.GREATER_THAN,
        ReportOperatorType.LESS_THAN_OR_EQUALS,
        ReportOperatorType.GREATER_THAN_OR_EQUALS,
        ReportOperatorType.BETWEEN,
        ReportOperatorType.NOT_BETWEEN,
        ...defaultOperators
      ])
  }
  return null
}

const getOperatorOptions = (operatorTypes: ReportOperatorType[]) => {
  return operators.filter(operator => !!operatorTypes.find(type => type === operator.name))
}