import React from "react";
import { Box, SvgIcon } from "@mui/material";
import { TimelineContent, TimelineItem, TimelineSeparator } from "@mui/lab";
import ScheduleIcon from "@mui/icons-material/CalendarMonthOutlined";
import TimelineConnector from "./TimelineConnector";
import {
  AllergiesIcon,
  ChartNotesIcon,
  ConditionAndProcedureIcon,
  FormIcon,
  LetterIcon,
  LabAndInvestigationsIcon,
  OtherDocumentIcon,
  PrescriptionIcon
} from "../../ChartIcons";
import {
  TIMELINE_EVENT_TYPES,
  TimelineEventGroupType,
  TimelineEventType
} from "../../../../../types/patients/Timeline";
import TimelineEventDate from "./TimelineEventDate";
import TimelineEventDetails from "./TimelineEventDetails";
import TimelineEventDot from "./TimelineEventDot";
import TimelineEventName from "./TimelineEventName";

const TIMELINE_EVENT_TYPE_MAP: { [type in TIMELINE_EVENT_TYPES]: typeof SvgIcon } = {
  "ALLERGY": AllergiesIcon,
  "APPOINTMENT": ScheduleIcon,
  "CONDITION_AND_PROCEDURE": ConditionAndProcedureIcon,
  "ENCOUNTER_NOTE": ChartNotesIcon,
  "FORM": FormIcon,
  "LAB_AND_INVESTIGATION": LabAndInvestigationsIcon,
  "LETTER": LetterIcon,
  "OTHER_DOCUMENT": OtherDocumentIcon,
  "PRESCRIPTION": PrescriptionIcon
}

type TimelineEventProps = {
  events: TimelineEventGroupType
  onEventClick: (event: TimelineEventType) => void
  dataTestId?: string
}

const TimelineEvent = ({ events, onEventClick, dataTestId }: TimelineEventProps) => {
  return (
    <>
      <TimelineEventDate date={events.date} dateFromNow={events.dateFromNow} />
      <Box
        position={"relative"}
        sx={{
          border: "1px solid rgba(32, 41, 49, 0.32)",
          borderRadius: "8px"
        }}
      >
        {events.events.map((event, eventIndex) => {
          return (
            <TimelineItem
              key={`${event.type}-${event.id}`}
              data-testid={`${dataTestId}-item-${eventIndex}`}
              onClick={() => onEventClick(event)}
              sx={{
                alignItems: "center",
                borderTopLeftRadius: eventIndex === 0 ? "8px": 0,
                borderTopRightRadius: eventIndex === 0 ? "8px" : 0,
                borderBottomLeftRadius: eventIndex === events.events.length - 1 ? "8px" : 0,
                borderBottomRightRadius: eventIndex === events.events.length - 1 ? "8px" : 0,
                color: (theme) => event.abnormal ? theme.palette.error.main : theme.palette.greys.dark,
                cursor: "pointer",
                minHeight: 0,
                p: 1,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.backgrounds.hover
                }
              }}
            >
              <TimelineSeparator sx={{ alignSelf: "flex-start", py: "2px" }}>
                {eventIndex === 0 && (
                  <TimelineConnector
                    onClick={(e) => e.stopPropagation()}
                    sx={{ position: "absolute", bottom: "calc(100% + 1px)", cursor: "default", height: "24px", userSelect: "none" }}
                  />
                )}
                <TimelineEventDot Icon={TIMELINE_EVENT_TYPE_MAP[event.type]} />
              </TimelineSeparator>
              <TimelineContent gap={"2px"} sx={{ px: 1, py: 0 }}>
                <TimelineEventName event={event} />
                <TimelineEventDetails event={event} />
              </TimelineContent>
            </TimelineItem>
          )}
        )}
      </Box>
    </>
  )
}

export default TimelineEvent