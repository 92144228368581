import { Box } from "@mui/material";
import { DialogV2, Typography } from "saga-library/src";
import React from "react";
import { PatientSearchResult } from "../../../types/patients/Patient";

interface PatientNotesDialogProps {
  patient: PatientSearchResult
  open: boolean,
  setOpen: (o: boolean) => void
}

export const PatientNotesDialog = ({
  patient,
  open,
  setOpen
}: PatientNotesDialogProps) => {

  return (
    <DialogV2
      open={open}
      onClose={() => setOpen(false)}
      title={'Patient notes'}
      showActions={false}
      PaperProps={{ sx: { pb: 1 } }}
      dataTestId={'patientNotesDialog'}
    >
      {
        patient?.patientNotes?.map(pn =>
          <Box
            key={pn.id}
            sx={{
              whiteSpace: 'normal',
              backgroundColor: 'rgba(196, 196, 196, 0.22)',
              borderRadius: 8,
              margin: '8px'
            }}
          >
            <Typography
              variant={'body1'}
              style={{ padding: '16px' }}
            >
              {pn.noteText}
            </Typography>
          </Box>
        )
      }
    </DialogV2>
  )
}