import React, { useEffect, useState } from 'react'
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { rules } from '../../../util/baseClaimValidation'
import { Refreshable, SimpleTextField } from "saga-library/src";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { BatchControlledDatePicker } from "../components/BatchControlledDatePicker";
import { BatchControlledServiceCodeSearch } from "../components/BatchControlledServiceCodeSearch";
import { MultipleValuesBatchMessage, serviceCodeHospitalAdmission } from "../../../../../utils/ABClaimConstants";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { BatchControlledDiagnosticCodeInput } from "../components/BatchControlledDiagnosticCodeInput";
import { BatchControlledServiceFeeModifierSelect } from "../components/BatchControlledServiceFeeModifierSelect";
import BatchControlledOverflowTextField from "../components/BatchControlledOverflowTextField";
import { SimpleTooltip } from "saga-library/src/components/Tooltip";
import { BatchControlledFacilityInput } from "../components/BatchControlledFacilityInput";
import { BatchControlledFunctionalCenter } from "../components/BatchControlledFunctionalCenterInput";
import { BatchControlledReferralPractitionerSearch } from "../components/BatchControlledReferralPractitionerSearch";
import { BatchControlledLocationCodeSelect } from "../components/BatchControlledLocationCodeSelect";
import PatientRefreshable from "../../../../../components/SearchControls/Patient/PatientRefreshable";
import FormattedDatePicker from "../../../../../components/FormattedDatePicker";
import { useAssessedClaimsContext } from '../../../providers/AssessedClaimsProvider'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'


export const AssessedMultipleValuesField = ({ label, multipleValuesState, children, sx = {} }) => {
  const theme = useTheme()
  return (
    <>
      {
        multipleValuesState ?
          <SimpleTextField
            variant={'standard'}
            label={label}
            disabled={true}
            value={'Multiple values entered'}
            InputProps={{
              disableUnderline: true
            }}
            InputLabelProps={{
              sx: {
                color: theme.palette.greys.medium,
                fontSize: '14px',
                ml: '14px',
                ...sx
              },
            }}
            sx={{
              '& .MuiInputBase-input': {
                color: theme.palette.greys.light,
                fontStyle: 'italic',
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginLeft: '14px'
              },
              marginBottom: '16px',
              ...sx,
            }}
          />
          :
          children
      }
    </>
  )
}

const BatchControlledAssessedClaimAmount = ({ multipleValuesState, children }) => {
  const theme = useTheme()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <>
    {
      multipleValuesState ?
        <SimpleTooltip title={MultipleValuesBatchMessage} open={tooltipOpen}>
        <Box
          sx={{
            width: '100%',
            marginLeft: '16px',
            marginBottom: '8px',
            marginTop: '8px'
          }}
        >
          <span>Estimated total: </span>
          <Typography
            onMouseOver={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
            sx={{
              fontWeight: 400,
              color: theme.palette.greys.light,
              display: 'inline-block',
              fontStyle: 'italic',
            }}
          >
           [...]
          </Typography>
        </Box>
        </SimpleTooltip>
        :
        children
    }
    </>
  )

}
const Form = ({ batchUpdateMultiple }) => {
  const { control, resetField, register, getValues } = useFormContext();
  const [practitionerId, setPractitionerId] = useState(getValues('practitionerId'))
  const { showSuccessAlert } = useAlerts()
  const { selectedClaimIds, setSelectedClaimIds, getSelectedClaims } = useAssessedClaimsContext()
  const [practitionerName, setPractitionerName] = useState(getValues('practitionerId'))


  useEffect(() => {
    const selectedClaims = getSelectedClaims()
    if (selectedClaims.length <= 0) {
      throw new Error('No claims selected.')
    }
    let name = selectedClaims[0].billingProfile.name + ' (' + practitionerDisplayName(selectedClaims[0].billingProfile.practitionerFirstName, selectedClaims[0].billingProfile.practitionerLastName) + ')'
    setPractitionerName(name)
  }, [selectedClaimIds])


  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  const serviceCode = useWatch({
    control,
    name: 'serviceCode',
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '48%'
        }}
      >
        <AssessedMultipleValuesField
          label={'Practitioner'}
          multipleValuesState={batchUpdateMultiple.billingProfile}
        >
          <Refreshable
            label={'Practitioner'}
            name={'practitioner'}
            textValue={practitionerName}
            onRefreshDisplaySnackbar={(value) => {
              if (!value) {
                showSuccessAlert('Practitioner information will be updated when this claim is resubmitted.')
              }
              else {
                showSuccessAlert('Practitioner information will not be updated when this claim is resubmitted.')
              }
            }}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Patient'}
          multipleValuesState={batchUpdateMultiple.patient}
        >
          <PatientRefreshable
            name={'patient'}
            patient={batchUpdateMultiple.patient}
            onRefreshDisplaySnackbar={(value) => {
              if (!value) {
                showSuccessAlert('Patient information will be updated when this claim is resubmitted.')
              }
              else {
                showSuccessAlert('Patient information will not be updated when this claim is resubmitted.')
              }
            }}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Service date'}
          multipleValuesState={batchUpdateMultiple.serviceDate}
        >
          <BatchControlledDatePicker
            label={'Service date'}
            name={'serviceDate'}
          />
        </AssessedMultipleValuesField>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            border: 1,
            borderRadius: '8px',
            color: 'greys.light',
            marginTop: '16px',
          }}
          >
          <AssessedMultipleValuesField
            label={'Service code'}
            multipleValuesState={batchUpdateMultiple.serviceCode}
            sx={{
              pl: 2
            }}
          >
            <BatchControlledServiceCodeSearch
              label={'Service code'}
              name={'serviceCode'}
              practitionerId={practitionerId}
              serviceDate={serviceDate}
              sx={{
                width: '80%',
                mr: '7px',
                pl: 2
              }}
            />
          </AssessedMultipleValuesField>
          {serviceCode &&
            serviceCodeHospitalAdmission.includes(
              serviceCode['serviceCode']
            ) && (
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignContent={'center'}
                justifyContent={'space-evenly'}
                sx={{
                  width: '80%',
                  paddingLeft: '16px',
                }}
              >
                <FormattedDatePicker
                  label={'Hospital admission date'}
                  name={'hospitalAdmissionDate'}
                />
              </Box>
            )}
            <AssessedMultipleValuesField
              label={'Diagnostic code'}
              multipleValuesState={batchUpdateMultiple.diagnosticCodes}
              sx={{
                pl: 2
              }}
              >
              <BatchControlledDiagnosticCodeInput
                label={'Diagnostic code'}
                name={'diagnosticCodes'}
                practitionerId={practitionerId}
                serviceDate={serviceDate}
                sx={{
                  width: '80%',
                  mr: '7px',
                  pl: 2
                }}
              />
            </AssessedMultipleValuesField>
          <AssessedMultipleValuesField
            label={'Fee modifiers'}
            multipleValuesState={batchUpdateMultiple.serviceCodeFeeModifiers}
            sx={{
              pl: 2
            }}
          >
            <BatchControlledServiceFeeModifierSelect
              serviceCodeId={serviceCode?.id}
              sx={{
                m: '8px 0',
                width: '80%',
                mr: '7px',
                pl: 2,
              }}
              inputProps={{ maxLength: rules.feeModifiers.max }}
              batchUpdateMultiple={batchUpdateMultiple}
            />
          </AssessedMultipleValuesField>
          <Box
            display={'flex'}
            flexDirection={'row'}
            paddingLeft={'16px'}
          >
            <BatchControlledOverflowTextField
              label={'Calls'}
              type={'number'}
              name={'calls'}
              field={'calls'}
              inputProps={{ maxLength: rules.calls.max }}
              sx={{
                width: '30%',
              }}
              multipleValuesState={batchUpdateMultiple.calls}
              InputProps={register('calls', { valueAsNumber: true })}
            />
            <BatchControlledOverflowTextField
              label={'Encounter'}
              type={'number'}
              name={'encounter'}
              field={'encounter'}
              inputProps={{ maxLength: rules.encounter.max }}
              sx={{
                width: '30%',
                ml: 2
              }}
              multipleValuesState={batchUpdateMultiple.encounter}
              InputProps={register('encounter', { valueAsNumber: true })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '48%',
        }}
      >
        <AssessedMultipleValuesField
          label={'Facility'}
          multipleValuesState={batchUpdateMultiple.facility}
          >
          <BatchControlledFacilityInput
            name={'facility'}
            label={'Facility'}
            practitionerId={practitionerId}
            serviceDate={serviceDate}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Functional centre'}
          multipleValuesState={batchUpdateMultiple.functionalCenter}
          >
          <BatchControlledFunctionalCenter
            name={'functionalCenter'}
            label={'Functional centre'}
            onClear={() => resetField('functionalCenter')}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Location'}
          multipleValuesState={batchUpdateMultiple.locationCode}
        >
          <BatchControlledLocationCodeSelect
            label={'Location'}
            name={'locationCode'}
          />
        </AssessedMultipleValuesField>
        <AssessedMultipleValuesField
          label={'Referral practitioner'}
          multipleValuesState={batchUpdateMultiple.referralPractitioner}
        >
          <BatchControlledReferralPractitionerSearch
            label={'Referral practitioner'}
            name={'referralPractitioner'}
          />
        </AssessedMultipleValuesField>
        <BatchControlledOverflowTextField
          label={'Text'}
          type={'text'}
          name={'text'}
          field={'text'}
          multiline={true}
          rows={7}
          multipleValuesState={batchUpdateMultiple.text}
          fullWidth={true}
        />
      </Box>
    </Box>
  )
}

export const BatchUpdateAssessedClaimBaseDetails = {
  Form: Form,
}
