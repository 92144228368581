import * as yup from "yup";

export const schema = yup
  .object({
      allergen: yup
        .string()
        .transform((val) => {
            if(typeof val === 'object') {
                return val?.display
            }
            return val
        })
        .required("Required"),
  })