import { Box } from "@mui/material";
import { useAccountContext } from "../../../../../providers/AccountContext";
import {
  TableList,
  TableListCellConfig,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from "saga-library/src";
import { DATE_FORMAT } from "../../../../../utils/SettingsConstants";
import moment from "moment-timezone";
import React from "react";


export interface WidgetDateTableListRow {
  displayDate:string|Date|moment.Moment
  label: string
  subLabel?: string
  keyName: string,
  onRowClick?: () => void
}
interface WidgetDateTableListProps {
  rows: WidgetDateTableListRow[]
  onRowClick?: () => void
  emptyListMessage: string
  dataTestId: string
}

export const WidgetDateTableList = ({rows, onRowClick:onRowClickTable, emptyListMessage, dataTestId}:WidgetDateTableListProps) => {
  const { getUserSetting } = useAccountContext()

  const columns: TableListHeaderConfig[] = [
    { name: '', sx:{width:80} },
    { name: '', sx:{width:200} }
  ]

  const DateRow = (displayDate, label, subLabel, onRowClick, keyName): TableListRowConfig => {
    const dateFormat = getUserSetting(DATE_FORMAT) as string
    const formattedDate = moment(displayDate).format(dateFormat)

    const rowData = [
      {
        sx:{width:80},
        children: <Box>
          <Typography variant={'body2'}>{formattedDate}</Typography>
        </Box>
      },
      {
        sx:{width:200},
        children:
          <Box display={'flex'} sx={{ flexDirection:"column", lineHeight: "16px" }} gap={0}>
            <Typography lineclamp={1} variant={'body2'} >
              {label}
            </Typography>
            <Typography lineclamp={1} variant={'p3'} >
              {subLabel}
            </Typography>
          </Box>
      }
    ] as TableListCellConfig[]

    return {
      onClick: onRowClick,
      rowData: rowData,
      key: keyName
    }
  }

  return (
    <TableList
      columns={columns}
      isWidget={true}
      showHeader={false}
      rows={rows.map((row) => DateRow(row.displayDate, row.label, row.subLabel, row.onRowClick || onRowClickTable, row.keyName))}
      sx={{height: '100%', "&.MuiTableCell-root": { borderBottom: null }}}
      emptyListComponent={{ message: emptyListMessage, size: 'sm', sx: {paddingTop: '1vh', textAlign: 'left'} }}
      dataTestId={`${dataTestId}-widgetDateTableList`}
    />
  )
}