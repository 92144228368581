import { DocumentCategory } from '../../../../../types/Document'
import { LinkedDocumentModal } from '../../LinkedDocumentForm/LinkedDocumentModal'
import React from 'react'

export const FormDocumentModal = ({ form, onModalClose }) => {
  return (
    <LinkedDocumentModal
      formName={"form_document_modal"}
      document={form.linkedDocument}
      title={form.linkedDocument?.description || "Form"}
      category={DocumentCategory.FORM}
      onModalClose={onModalClose}
      dataTestId={'formDocument-modal'}
    />
  )
}