import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { ReferralPractitioner } from "../../../../../types/settings";
import {
  ReferralPractitionerSearch
} from "../../../../../components/SearchControls/Practitioner/ReferralPractitionerSearch";
import { useFormContext } from "react-hook-form";
import { GET_REFERRAL_PRACTITIONER_PROFILE } from "../../../../../graphql-definitions";
import { LoadingSpinner } from "../../../../../components/LoadingScreen";

type LetterFormFamilyPractitionerSelectionProps = {
  name: string
  onSelect: (familyPractitioner: ReferralPractitioner) => void
  dataTestId: string
}

export const LetterFormFamilyPractitionerSelection = ({
  name,
  onSelect,
  dataTestId
}: LetterFormFamilyPractitionerSelectionProps) => {
  const { tenant_id } = useParams()
  const { watch } = useFormContext()

  const selectedFamilyPractitioner = watch(name)

  const [getFamilyPractitioner, { loading }] = useLazyQuery(GET_REFERRAL_PRACTITIONER_PROFILE, {
    onCompleted: (data) => {
      const familyPractitioner: ReferralPractitioner = data.tenant.referralPractitioner.get
      onSelect(familyPractitioner)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    if (selectedFamilyPractitioner) {
      getFamilyPractitioner({
        variables: {
          referralPracId: selectedFamilyPractitioner.id,
          tenantId: tenant_id,
        }
      })
    }
  }, [selectedFamilyPractitioner])

  if (loading) {
    return <LoadingSpinner size={"md"} label={"Loading family practitioner..."} />
  }

  return (
    <ReferralPractitionerSearch
      dataTestId={dataTestId}
      label={"Find a family practitioner"}
      name={name}
      autoFocusOnOpen
    />
  )
}