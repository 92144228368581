import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { schema } from "./util/validation";
import { LocationForm } from "./LocationForm";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";
import { useAccountContext } from "../../../providers/AccountContext";
import { Location } from "../../../types";
import { CREATE_LOCATION, LIST_LOCATIONS } from "../../../graphql-definitions";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import PermissionButton from "../../../components/PermissionButton";
import SettingsHeader from "../components/SettingsHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

const FORM_NAME = 'new_location_form'

export type LocationDetailsType = {
  name: string
  street: string
  street2: string
  street3: string
  city: string
  province: string
  postalCode: string
  phoneNumber: string
  faxNumber: string
}

export const locationDetailsDefaults = {
  name: '',
  street: '',
  street2: '',
  street3: '',
  city: '',
  province: '',
  postalCode: '',
  phoneNumber: '',
  faxNumber: '',
  version: "0"
}

export const LocationNew = () => {
  const navigate = useNavigate()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const [createLocation] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted: (data) => {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        const newLocation = _get(data, 'tenant.location.create')
        const newLocationName = newLocation?.name
        const newLocationId = newLocation?.id
        showSuccessAlert(`${newLocationName} has been saved.`)
        navigate( buildTenantRoute(
            `settings/locations/l/${newLocationId}`,
            tenant_id
          )
        )
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Location couldn\'t be created.')
      },
    }
  )

  const formMethods = useForm<LocationDetailsType>({
    defaultValues: locationDetailsDefaults,
    schema: schema,
  })
  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit( async (data) => {
    const {street, ...rest} = data
    const newLocation: Location = {
      ...rest,
      street1: street
    }
    await createLocation(({
      variables: {
        tenantId: tenant_id,
        input: newLocation
      },
      update(cache, returnedData) {
        cache.modify({
          fields: {
            list(existingLocations = []) {
              const newLocation = _get(returnedData, 'data.tenant.location.create')
              cache.writeQuery({
                query: LIST_LOCATIONS,
                data: {
                  tenant: {
                    pharmacy: {
                      list: [ ...existingLocations, newLocation]
                    }
                  }
                }
              })
            }
          }
        })
      }
    }))
  })

  const SaveButton = () => {
    return <PermissionButton
      type={'submit'}
      loading={isSubmitting}
      name={"saveNewLocation"}
      onClick={onSubmit}
      requiredType={PermissionType.Admin}
      requiredPermission={Permission.READONLY}
      dataTestId={'locationNew-save-button'}
    >
      Save
    </PermissionButton>
  }

  return (
    <FormProvider {...formMethods}>
      <SettingsSectionColumn
        header={
        <SettingsHeader
          title={'New Location'}
          dataTestId={'locationNew-navigationHeader'}
          onBack={() => navigate(-1)}
          actions={ <SaveButton /> }
        />
      }>
        <LocationForm onSubmit={onSubmit} dataTestId={'locationNew'} formName={FORM_NAME}/>
      </SettingsSectionColumn>
    </FormProvider>
  )
}
