import React from 'react'
import { Chip as MuiChip, Typography } from '@mui/material'
import type { TableListRowConfig } from "saga-library/src";
import { CheckIcon } from "../../../components/Icons/CheckIcon";
import { styled } from "@mui/system";
import { CloseIcon } from "../../../components/Icons/CloseIcon";
import SettingsRow from "./SettingsRow";


const Chip = styled(MuiChip)(({ theme, variant }) => ({
  borderColor: theme.palette.greys.dark,
  marginLeft: 5,
}))

export const IdentifierRow = (identifier, setEditIdentifier): TableListRowConfig => {
  return ({
    onClick: ()=>setEditIdentifier(identifier),
    key: identifier.id,
    rowData: [
      {
        children: [
          <SettingsRow key={'name'+identifier.id}>
            <Typography>{identifier.name}</Typography>
            <Chip label={identifier.label} />
          </SettingsRow>,
        ],
      },
      {
        children: [
          <SettingsRow key={'check'+identifier.id}>
            <CheckIcon />
          </SettingsRow>,
        ],
      },
      {
        children: [
          <SettingsRow key={'display'+identifier.id}>
            {identifier.alwaysDisplayField ? <CheckIcon /> : <CloseIcon />}
          </SettingsRow>,
        ]
      },
      {
        children: [
          <SettingsRow key={'close'+identifier.id}>
            <CloseIcon />
          </SettingsRow>,
        ],
      }
    ]
  })
}

