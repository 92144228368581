export const heightWeight2to19 = (gender: 'boys' | 'girls') => {
  if (gender === 'boys') {
    // height in y: 193.2 to 700.9 (76 cm to 207 cm)
    // weight in y: 80.8 to 433.4 (2 kg to 93 kg)
    // age in x: 102.3 to 524.2 (24 to 228 months)
    return {
      height: {
        min: 76,
        max: 207,
        coord_min: 193.2,
        coord_max: 700.9
      },
      weight: {
        min: 2,
        max: 93,
        coord_min: 80.8,
        coord_max: 433.4
      },
      age: {
        min: 24,
        max: 228,
        coord_min: 102.3,
        coord_max: 524.2
      },
      table: {
        top: 656,
        bottom: 587.8,
        start: 103,
        shortIncrement: 25,
        increment: 50,
        rows: 8
      },
      header: {
        name: { left: 395, bottom: 737, right: 570, top: 750 },
        dob: { left: 385, bottom: 725, right: 475, top: 736 }
      }
    }
  }
  // height in y: 183.3 to 690.9 (71 cm to 202 cm)
  // weight in y: 70.9 to 423.5 (2 kg to 93 kg)
  // age in x: 112.3 to 534.2 (24 to 228 months)
  return {
    height: {
      min: 71,
      max: 202,
      coord_min: 183.3,
      coord_max: 690.9
    },
    weight: {
      min: 2,
      max: 93,
      coord_min: 70.9,
      coord_max: 423.5
    },
    age: {
      min: 24,
      max: 228,
      coord_min: 112.3,
      coord_max: 534.2
    },
    table: {
      top: 646,
      bottom: 577.8,
      start: 113,
      shortIncrement: 25,
      increment: 49.7,
      rows: 8
    },
    header: {
      name: { left: 400, bottom: 727, right: 570, top: 740 },
      dob: { left: 395, bottom: 714, right: 480, top: 727 }
    }
  }
}

export const bmi2to19 = (gender: 'boys' | 'girls') => {
  if (gender === 'boys') {
    //y: 93.3 to 684 (10 to 41 kg/m^2)
    //x: 111.9 to 530.4 (24 to 228 months)
    return {
      bmi: {
        min: 10,
        max: 41,
        coord_min: 93.3,
        coord_max: 684
      },
      age: {
        min: 24,
        max: 228,
        coord_min: 111.9,
        coord_max: 530.4
      },
      table: {
        top: 685,
        bottom: 561.7,
        start: 73,
        increment: 46.84,
        rows: 15
      }
    }
  }
  //y: 93.3 to 684 (11 to 42 kg/m^2)
  //x: 111.9 to 530.4 (24 to 228 months)
  return {
    bmi: {
      min: 11,
      max: 42,
      coord_min: 93.3,
      coord_max: 684
    },
    age: {
      min: 24,
      max: 228,
      coord_min: 111.9,
      coord_max: 530.4
    },
    table: {
      top: 685,
      bottom: 577,
      start: 73,
      increment: 46.84,
      rows: 15
    }
  }
}


export const headcircumferenceWeight0to2 = (gender: 'boys' | 'girls') => {
  if (gender === 'boys') {
    // hc in y: 424 to 686 (28.4 cm to 56.8 cm)
    // age in x: 125 to 518 (0 to 24 months)
    // weight in y: 94.3 to 544 (1.4 kg to 24.6 kg)
    // length in x: 125 to 518 (45 cm to 110 cm)
    return {
      headCircumference: {
        min: 28.4,
        max: 56.8,
        coord_min: 424,
        coord_max: 686
      },
      length: {
        min: 45,
        max: 110,
        coord_min: 125,
        coord_max: 518
      },
      weight: {
        min: 1.4,
        max: 24.6,
        coord_min: 94.3,
        coord_max: 544
      },
      age: {
        min: 0,
        max: 24,
        coord_min: 125,
        coord_max: 518
      },
      table: {
        top: 134,
        bottom: 64,
        start: 266,
        increment: 51.166667,
        rows: 8
      }
    }
  }
  // age in x: 125 to 518 (0 to 24 months)
  // hc in y: 428.3 to 690.5 (26.4 cm to 54.8 cm)
  // length in x: 125 to 518 (45 cm to 110 cm)
  // weight in y: 91.5 to 552.9 (1.4 kg to 25.2 kg)
  return {
    headCircumference: {
      min: 26.4,
      max: 54.8,
      coord_min: 428.3,
      coord_max: 690.5
    },
    length: {
      min: 45,
      max: 110,
      coord_min: 125,
      coord_max: 518
    },
    weight: {
      min: 1.4,
      max: 25.2,
      coord_min: 91.5,
      coord_max: 552.9
    },
    age: {
      min: 0,
      max: 24,
      coord_min: 125,
      coord_max: 518
    },
    table: {
      top: 131.5,
      bottom: 64,
      start: 266,
      increment: 51.166667,
      rows: 8
    }
  }
}

export const lengthWeight0to2 = () => {
  // length in y: 251.7 to 689 (17 cm to 102 cm)
  // weight in y: 72 to 523.7 (0.4 kg to 18 kg)
  // age in x: 125.2 to 516.7 (0 to 24 months)
  return {
    length: {
      min: 17,
      max: 102,
      coord_min: 251.7,
      coord_max: 689
    },
    weight: {
      min: 0.4,
      max: 18,
      coord_min: 72,
      coord_max: 523.7
    },
    age: {
      min: 0,
      max: 24,
      coord_min: 125.2,
      coord_max: 516.7
    },
    table: {
      top: 158,
      bottom: 72,
      start: 256,
      increment: 58.25,
      rows: 10
    }
  }
}

