import React, { useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import moment from "moment-timezone";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { EventForm } from "./EventForm";
import { ADD_NEW_EVENT } from "../../../../graphql-definitions";
import { schema } from "../../util/eventValidation";
import { ScheduleEventInput } from "../../../../types/schedule";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime, getScheduleViewDates, updateScheduleItemsCache } from '../../util/scheduleFunctions'
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import _get from 'lodash/get'

const FORM_NAME = 'new_event_form'

const eventDefaults = (eventData, scheduleId, timezone): ScheduleEventInput | {} => {
  if(!eventData) return {}

  return {
    version: "0",
    scheduleId: scheduleId,
    start: moment(eventData.start).tz(timezone),
    end: moment(eventData.end).tz(timezone),
    title: '',
    itemDate: moment(eventData?.start).format('YYYY-MM-DD'),
    allDay: false
  }
}

interface NewEventProps {
  eventData: any
  scheduleId?: string
  onClose: () => void
  onFormChange: (formName: string, submitting: boolean, dirtyFields: any) => void
}

export const NewEvent = ({
  eventData,
  scheduleId,
  onClose,
  onFormChange
}: NewEventProps) => {

  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const { displayScheduleError, scheduleView } = useScheduleContext()

  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const formMethods = useForm<ScheduleEventInput>({
    defaultValues: eventDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]),
    schema: schema
  })

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  useEffect(() => {
    onFormChange(FORM_NAME, isSubmitting, dirtyFields)
  }, [isSubmitting, Object.keys(dirtyFields).length])

  const [addNewEvent] = useMutation(ADD_NEW_EVENT, {
    onCompleted: (res) => {
      reset()
      onClose()
      showSuccessAlert('Event created.')
    },
    onError: (error) => {
      displayScheduleError(error, "Event")
    }
  })

  const onSubmit = handleSubmit(async (eventData) => {
    eventData.start = moment(eventData.itemDate + ' ' + formatTime(eventData.start), 'YYYY-MM-DD h:mm A')
    eventData.end =  moment(eventData.itemDate + ' ' + formatTime(eventData.end), 'YYYY-MM-DD h:mm A')

    const newEvent = {
      __typename: "ScheduleEvent",
      resource: eventData.scheduleId,
      ...eventData
    }

    await addNewEvent({
      variables: {
        tenantId: tenant_id,
        input: eventData
      },
      update: (cache, { data }) => {
        newEvent["id"] = _get(data, 'tenant.schedule.createEvent', null)
        const variables = {
          tenantId: tenant_id,
          scheduleId: scheduleId,
          scheduleIdList: scheduleView?.schedules,
          start: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).start).utc().toISOString(),
          end: moment(getScheduleViewDates(scheduleView?.date, scheduleView?.view).end).add(1, 'day').utc().toISOString()
        }
        updateScheduleItemsCache(cache, newEvent, variables)
      }
    })
  })

  return (
    <FormProvider {...formMethods}>
      <EventForm
        onSubmit={onSubmit}
        formName={FORM_NAME}
        dataTestId={"newEvent"}
      />
    </FormProvider>

  )
}
