import { IdentifierType, TenantIdentifierType } from "../PatientTypes";

export const filterIdentifiers = (identifiers, tenantIdentifiers) => {
  // Remove "always display" identifiers if they have no value.
  const filteredIdentifiers = identifiers.filter((identifier: IdentifierType) => {
    let tenantIdentifier = tenantIdentifiers.find(
      (tenantIdentifier: TenantIdentifierType) =>
        tenantIdentifier.id === identifier.typeId
    )
    if (tenantIdentifier) {
      const ti = tenantIdentifier as TenantIdentifierType
      // Keep if display is optional or if there is a value.
      return (
        (!ti.alwaysDisplayField && !ti.isPrimary) ||
        (identifier.value != null && identifier.value.trim() !== '')
      )
    } else {
      return false
    }
  })
  return filteredIdentifiers.map((identifier: IdentifierType) => {
    return {
      typeId: identifier.typeId,
      value: identifier.value,
    }
  })
}