import React from 'react'
import { StaticPage, TosPrivacyPolicyLayout } from "../../components/Layouts";

export const TermsOfService = () => {
  return <StaticPage>
    <TosPrivacyPolicyLayout>
      <p className={ "MsoTitle" }>Microquest Terms and Conditions Agreement (the "Agreement")</p>

      <p className={ "MsoNormal" }><b>Effective Date: July 28, 2022</b></p>

      <p className={ "MsoNormal" }>This Agreement is between Microquest Inc., a corporation
        incorporated pursuant to the laws of the Province of Alberta, Canada ("Microquest",
        "us" or "our" as the context requires) and you (the "Primary User". "you",
        "your" or "yourself" as the context requires).</p>

      <p className={ "MsoNormal" }>You, as the Primary User, shall bear specific obligations
        under these Terms and Conditions and this Agreement. A Product Subscription
        shall be in the name of a Primary User. The Primary User may permit use of the
        Product by other Authorized Users, however, the Primary User shall maintain
        responsibility for the Product Subscription and the compliance of all
        Authorized Users with this Agreement at all times.</p>

      <p className={ "MsoNormal" }>If you are agreeing to this Agreement as a duly authorized
        representative of an incorporated entity or partnership (rather than as a
        natural person on behalf of himself or herself), then "Primary User", or "you",
        "your" and "yourself" means an incorporated entity or partnership, and you are
        binding that incorporated entity or partnership to this Agreement. Microquest
        may modify this Agreement from time to time, subject to the terms in Section 25
        (Changes to this Agreement) below.</p>

      <p className={ "MsoNormal" }>For clarity, this Agreement sets out circumstances in which
        you may use this platform for yourself or on behalf of another individual, and
        where you are doing so (as permitted), references to "you" and "your" in this
        Agreement include the child or other individual listed in your account for whom
        you have the legal authority to seek healthcare services as defined below as a
        Registered Dependent.</p>

      <p className={ "MsoNormal" }>By clicking on the "I agree" (or similar button) that is
        presented to you at the time of your Subscription, or by using or accessing the
        Product, you indicate your assent to be bound by this Agreement, as of the date
        you do so (the "Effective Date").</p>

      <p className={ "MsoNormal" }>IF YOU DO NOT AGREE TO THESE TERMS, MICROQUEST WILL NOT AND
        DOES NOT PROVIDE YOU WITH THE RIGHT TO USE THE MICROQUEST PLATFORM IN ANY
        CAPACITY. IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT USE, OR IMMEDIATELY
        CEASE USE OF THE PLATFORM.</p>

      <p className={ "MsoNormal" }>WE RESERVE THE RIGHT, IN OUR SOLE DISCRETION, TO MODIFY,
        ALTER OR OTHERWISE UPDATE THESE TERMS AT ANY TIME. THE DATE ON WHICH THESE
        TERMS WERE LAST AMENDED WILL APPEAR AT THE TOP.</p>

      <p className={ "MsoNormal" }>ON NOTICE TO YOU, WE MAY IMMEDIATELY TERMINATE THESE TERMS,
        AND/OR ACCESS TO YOUR ACCOUNT, OR GENERALLY CEASE OFFERING OR DENY ACCESS TO
        THE PLATFORM OR ANY PORTION THEREOF AT ANY TIME, FOR ANY REASON.</p>

      <h1>1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Interpretation</h1>

      <h2>1.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Definitions</h2>

      <p className={ "MsoNormal" }>In this Agreement, unless otherwise stated the following
        terms shall have the meaning prescribed for each:</p>

      <h3>1.1.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Agreement" means the terms and conditions described herein and its
        Recitals and Schedules incorporated by reference;</h3>

      <h3>1.1.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Authorized Users" means the Primary User, and the specific individuals
        for whom you have paid the required fees and whom you designate through the
        Product Subscription and who are Eligible Users as defined herein;</h3>

      <h3>1.1.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Business Day" means any day other than Saturday or Sunday or a
        statutory holiday so recognized by the Province of Alberta;</h3>

      <h3>1.1.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Effective Date" has the meaning given to it in the first page of this
        Agreement;</h3>

      <h3>1.1.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Eligible Users" means any persons determined by mutual agreement of the
        Parties following the Effective Date of this Agreement evidenced in writing;</h3>

      <h3>1.1.6<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Health Information" has the meaning ascribed to it in Alberta"s <i>Personal
        Information Protection Act</i>, as amended;</h3>

      <h3>1.1.7<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Microquest Office Hours" means the regular hours for Microquest on
        Business Days, being 07:30 to 18:00 Mountain Time;</h3>

      <h3>1.1.8<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Parties" means Microquest and the Primary User and "Party" means one of
        them as the context provides;</h3>

      <h3>1.1.9<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Personal Information" has the meaning ascribed to it in Alberta"s <i>Personal
        Information Protection Act</i>, as amended;</h3>

      <h3>1.1.10<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Primary User" means the Authorized User in whose name the Product
        Subscription is made and the Product Account is maintained. A Primary User may
        be a natural person or a corporate entity;</h3>

      <h3>1.1.11<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Product" or "Products" means the solution developed by Microquest,
        together with all associated services, and related documentation, enhancements,
        upgrades and updates that may be provided by Microquest ("Hosted Services"),
        and any related support or maintenance and associated services provided by
        Microquest to support the solution and its Authorized Users; </h3>

      <h3>1.1.12<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Product Subscription" means a duly written agreement between Microquest
        and a Primary User that provides the terms and conditions of access to and use
        of the Product on a monthly basis in exchange for payment of a Subscription
        Fee; </h3>

      <h3>1.1.13<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Proprietary and Confidential Information" includes Health Information,
        Personal Information, and any confidential or proprietary information of the
        Eligible Users disclosed to, or accessed by, Microquest;</h3>

      <h3>1.1.14<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Regular Clinic Office Hours" means the generally accepted hours of
        operation for medical clinics in Alberta on Business Days;</h3>

      <h3>1.1.15<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>"Authorized User Data" means any data, content, code, video, images or
        other materials of any type that is uploaded, submitted or otherwise
        transmitted to or through the Product and/or includes Personal Information and
        Proprietary and Confidential Information but does not include Address Book
        Data.</h3>

      <h1>2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Scope of the Agreement</h1>

      <p className={ "MsoNormal" }>This Agreement governs your initial Product Subscription as
        well as any future subscriptions or purchases made by you that reference this
        Agreement. This Agreement includes our Privacy Policy, our Acceptable Use
        Policy, our Product Support Policy any Product Subscriptions; any Service
        Specific Terms; and any other referenced policies and attachments.</p>

      <h1>3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Product</h1>

      <h2>3.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Governance</h2>

      <p className={ "MsoNormal" }>This Agreement governs the Product and its permitted use.</p>

      <h2>3.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Availability</h2>

      <p className={ "MsoNormal" }>Microquest shall make the Product available to you to use
        strictly in accordance with the terms and conditions of this Agreement.
        Ownership of the Product shall at all times remain with Microquest. Access to
        the Product is provided to you only to allow you to exercise your rights under
        this Agreement. You must provide and are responsible for all equipment and
        Internet connectivity necessary to access the Product.</p>

      <h1>4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Account Registration</h1>

      <h2>4.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Product Access</h2>

      <p className={ "MsoNormal" }>You, the Primary User, will need to sign up for a Microquest
        account in order to subscribe for access or receive any Products. Any
        registration information that you provide to us must be accurate, current and
        complete. You must also update your information so that we may send notices,
        statements and other information to you by email or through your account. You
        are responsible for all actions taken through your accounts.</p>

      <h2>4.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Legal Age</h2>

      <p className={ "MsoNormal" }>In consideration of your access to and use of the Product ,
        you represent that you are of legal age to form a binding contract and are not
        a person barred from receiving the Product under the laws of Canada or other
        applicable jurisdiction. </p>

      <h2>4.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Registration Process</h2>

      <p className={ "MsoNormal" }>In order to access the Product, you, the Primary User, will
        be required to provide information about yourself (such as identification or
        contact details) as part of the log-in registration process (the
        &quot;Registration&quot;). In connection with the Registration, you will select
        (or Microquest will assign to you) a user name that will be identified with
        your account (your &quot;Username&quot;). You will also be able to select a
        password which, together with your Username, will compose an account (your
        &quot; Account&quot;). Microquest may reject any Username that Microquest
        determines in its discretion is unacceptable for use in connection with the
        Product.</p>

      <h2>4.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Personal Account</h2>

      <p className={ "MsoNormal" }>If you are an individual proposing to use the Product
        Subscription, including for obtaining healthcare services or general
        information, you must register for and maintain an active personal account as
        an Authorized User. You must be at least 16 years of age to be an Authorized
        User. You are only permitted to request healthcare services for you or for
        Registered Dependents. You must create a separate &quot;profile&quot; for each
        Registered Dependent, which will include the person"s name and other personal
        information. Again, you are only permitted to request healthcare services for
        yourself or Registered Dependents. </p>

      <p className={ "MsoNormal" }>You are responsible for all activity that occurs under your
        Product Subscription, and you agree to maintain the security and secrecy of
        your Individual Account username and password at all times. Unless otherwise
        permitted by Microquest in writing, you may only possess one Individual
        Account.</p>

      <h2>4.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Representation and Warrants</h2>

      <p className={ "MsoNormal" }>As consideration in exchange for access to and use of the
        Product, an Authorized User represents and warrants the following:</p>

      <p className={ "MsoNormal indent" }>a)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>the Authorized User is of legal age to enter into contractual
        obligations under the laws of the Province of Alberta;</p>

      <p className={ "MsoNormal indent" }>b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>the
        Authorized User accepts this Agreement without reservation or exception;</p>

      <p className={ "MsoNormal indent" }>c)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>the
        Authorized User shall comply with this Agreement at all times;</p>

      <p className={ "MsoNormal indent" }>d)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>the Authorized User is not a person barred from subscribing to or using
        the Product under the law of any jurisdiction;</p>

      <h1>5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Product Subscriptions</h1>

      <p className={ "MsoNormal" }>Microquest"s Product ordering documentation or subscription
        flow ("Product Subscription") will specify your authorized scope of use for the
        Product, which may include: (a) number and type of Authorized Users or
        Registered Dependents, (b) storage or capacity for Hosted Services, or (c)
        other restrictions or billable units (as applicable, the "Scope of Use"). The
        term "Subscription" also includes any applicable Product or Support renewal, or
        purchases you make to increase or upgrade your Scope of Use.</p>

      <h1>6<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Authorized Users</h1>

      <p className={ "MsoNormal" }>Subject to 4.4, only Authorized Users may access and use the
        Product. A Primary User may increase the number of Authorized Users permitted
        to access the Product by placing a new Product Subscription or, in some cases,
        directly through the Product. In all cases, a Primary User must pay the
        applicable fee for the increased number of Authorized Users. A Primary User is
        responsible for compliance with this Agreement by all Authorized Users or
        Registered Dependents. All use of Products by Authorized Users must be within
        the Scope of Use and solely for the benefit of you.</p>

      <h1>7<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Hosted Services Terms</h1>

      <h2>7.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Access to Hosted Services</h2>

      <p className={ "MsoNormal" }>Subject to the terms and conditions of this Agreement,
        Microquest grants you a non-exclusive right to access and use the Product and
        Hosted Services during the applicable Subscription Term (as defined below) in
        accordance with this Agreement, your applicable Scope of Use and any relevant
        documentation. If Microquest offers client software (e.g., a desktop or mobile
        application) for any Hosted Service, you may use such software solely with the
        Hosted Service, subject to the terms and conditions of this Agreement. You
        acknowledge that the Hosted Services are on-line, subscription-based products
        and that Microquest may make changes to the Hosted Services from time to time.</p>

      <h2>7.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Product Subscription Terms and Renewals</h2>

      <p className={ "MsoNormal" }>Hosted Services are provided on a subscription basis for a
        set term specified in your Product Subscription ("Subscription Term"). Except
        as otherwise specified in your Product Subscription, all subscriptions will
        automatically renew for periods equal to your initial Subscription Term (and
        you will be charged at the then-current rates) unless you cancel your Product
        Subscription. If you cancel, your subscription will terminate at the end of
        then-current billing cycle. You will not be entitled to any credits or refunds
        for amounts accrued or paid prior to such termination except in accordance with
        the Return Policy in Section 9.1.</p>

      <h2>7.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Credentials</h2>

      <p className={ "MsoNormal" }>You must ensure that all Authorized Users keep their
        Username and passwords for the Hosted Services strictly confidential and not
        share such information with any unauthorized person. Usernames are granted to
        individual, named persons and may not be shared. You are responsible for any and
        all actions taken using your Accounts, Usernames and passwords, and you agree
        to immediately notify Microquest of any unauthorized use of which you become
        aware.</p>

      <h2>7.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Authorized User Data</h2>

      <p className={ "MsoNormal" }>Authorized Users retain all right, title and interest in and
        to Authorized User Data in the form provided to Microquest. Subject to the
        terms of this Agreement, you hereby grant to Microquest a non-exclusive,
        worldwide, royalty-free right to (a) collect, use, copy, store, transmit,
        modify and create derivative works of Authorized User Data, in each case solely
        to the extent necessary to provide the applicable Hosted Services to you and
        (b) for Hosted Services that enable you to share Authorized User Data or
        interact with other people, to distribute and publicly perform and display
        Authorized User Data as you (or your Authorized Users) direct or enable through
        the Hosted Services. Microquest may also access your Account(s) or instance in
        order to respond to your support requests.</p>

      <h2>7.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Security</h2>

      <p className={ "MsoNormal" }>Microquest implements security procedures to help protect
        Authorized User Data from security attacks. However, you understand that use of
        the Hosted Services necessarily involves transmission of Authorized User Data
        over networks that are not owned, operated or controlled by us, and we are not
        responsible for any of Authorized User Data that is lost, altered, intercepted
        or stored across such networks. Microquest cannot guarantee that its security
        procedures will be error-free, that transmissions of Authorized User Data will
        always be secure or that unauthorized third parties will never be able to
        defeat our security measures or those of our third party service providers.</p>

      <h2>7.6<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Storage Limits</h2>

      <p className={ "MsoNormal" }>There are storage limits associated with the Hosted
        Services. These limits are described in the product descriptions on the product
        website. Microquest reserves the right to charge for additional storage or
        overage fees at the rates specified on the product website. Microquest may
        impose new, or may modify existing, storage limits for the Hosted Services at
        any time in its discretion, with notice to you.</p>

      <h2>7.7<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Responsibility for Authorized User Data.</h2>

      <h3>7.7.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>General. You must ensure that your use of the Product and Hosted
        Services and all Your Data is at all times compliant with Microquest"s Acceptable
        Use Policy and all applicable local, Provincial, state, federal and
        international laws and regulations ("Laws"). You represent and warrant that:
        (i) you have obtained all necessary rights, releases and permissions to provide
        all Authorized User Data to Microquest and to grant the rights granted to
        Microquest in this Agreement and (ii) Authorized User Data and its transfer to
        and use by Microquest as authorized by you under this Agreement do not violate
        any Laws (including without limitation those relating to export control and
        electronic communications) or rights of any third party, including without
        limitation any intellectual property rights, rights of privacy, or rights of
        publicity, and any use, collection and disclosure authorized herein is not inconsistent
        with the terms of any applicable privacy policies. Other than its security
        obligations under Section 7.6 (Security), Microquest assumes no responsibility
        or liability for Authorized User Data, and you shall be solely responsible for
        Authorized User Data and the consequences of using, disclosing, storing, or
        transmitting it.</h3>

      <h3>7.7.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Sensitive Data. You will not submit to the Hosted Services (or use the
        Hosted Services to collect): (i) any personally identifiable information,
        except as necessary for the establishment of your Microquest account; (ii) any
        patient, medical or other protected health information regulated by HIA or any
        similar federal or provincial or state laws, rules or regulations; or (iii) any
        other information subject to regulation or protection under any specific laws
        or related rules or regulations ((i) through (iii), collectively, "Sensitive
        Data") unless and until you have complied with any relevant laws or related
        rules or regulations to the satisfaction of Microquest.</h3>

      <h3>7.7.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>IMA. You, and your Authorized Users through you, also acknowledge that,
        pursuant to the Alberta Health Information Act, as amended from time to time,
        ("HIA") an" Information Manager Agreement ("IMA") meeting the requirements of
        the Alberta HIA may have to be entered into, signed and registered before the
        use of the Product can begin.</h3>

      <h3>7.7.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Subject to the terms of the IMA and the terms of this Agreement,
        Microquest has no liability for Authorized User Data including Sensitive Data.</h3>

      <h3>7.7.5<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Indemnity for Authorized User Data including Sensitive Data. You will
        defend, indemnify and hold harmless Microquest from and against any loss, cost,
        liability or damage, including lawyers" fees, costs and charges on a lawyer and
        client basis, for which Microquest becomes liable arising from or relating to any
        claim relating to Authorized User Data including Sensitive Data, such claims to
        include, but shall not be limited to, any claim brought by a third party
        alleging that Authorized User Data including Sensitive Data, or your use of the
        Hosted Services in breach of this Agreement, infringes or misappropriates the
        intellectual property rights of a third party or violates applicable law. This
        indemnification obligation is subject to your receiving (i) prompt written
        notice of such claim (but in any event notice in sufficient time for you to
        respond without prejudice); (ii) the exclusive right to control and direct the
        investigation, defense, or settlement of such claim; and (iii) all reasonable
        necessary cooperation of Microquest at your expense.</h3>

      <h2>7.8<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Removals and Suspension</h2>

      <p className={ "MsoNormal" }>Microquest has no obligation to monitor any content uploaded
        to the Hosted Services. Nonetheless, if Microquest deems such action necessary
        based on your violation of this Agreement or in response to takedown requests
        that Microquest receives, Microquest may (1) remove Authorized User Data from
        the Hosted Services or (2) suspend your access to the Hosted Services.
        Microquest will generally alert you when it takes such action and give you a
        reasonable opportunity to cure your breach, but if Microquest determines that
        your actions endanger the operation of the Hosted Services or other users,
        Microquest may suspend your access immediately without notice. You will
        continue to be charged for the Hosted Services during any suspension period.
        Microquest has no liability to you for removing or deleting Authorized User
        Data from or suspending your access to any Hosted Services as described in this
        section.</p>

      <p className={ "MsoNormal" }>You and your Authorized Users agree to refrain from abusive
        language or inappropriate conduct when communicating with anyone through the
        Microquest platform, including contact with physicians. If inappropriate
        conduct is reported to Microquest, Microquest reserves the right to remove or
        suspend the Hosted Services immediately. </p>

      <h2>7.9<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Cancellation or Termination of Product Subscriptions</h2>

      <h3>7.9.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Data Export. Microquest shall allow the Primary User to export their
        Authorized User Data stored in the Product after the Product Subscription has
        been cancelled or terminated. The data export may incur a cost to the Primary
        User. The format for the data export will be Portable Document Format (PDF). </h3>

      <h3>7.9.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Data Access. Microquest shall allow the Primary User to maintain
        read-only access to their Authorized User Data stored in the Product at a
        minimal cost after the services have been cancelled or terminated. </h3>

      <h2>7.10<span>&nbsp;&nbsp; </span>Service-Specific
        Terms</h2>

      <p className={ "MsoNormal" }>Some of our Hosted Services may be subject to additional
        terms specific to that service as set forth in our Service-Specific Terms.</p>

      <h1>8<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Product Support</h1>

      <p className={ "MsoNormal" }>Microquest will provide the support services for the
        Products described in the Product Support Policy during the period for which
        you have paid the applicable fee. Support is subject to the terms of the
        Product Support Policy and will be provided at the support level specified and
        during the support term specified in your Product Subscription. The Product
        Support Policy may be modified by Microquest from time to time to reflect
        process improvements or changing practices.</p>

      <h1>9<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Returns and Financial Terms</h1>

      <h2>9.1<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Return Policy</h2>

      <p className={ "MsoNormal" }>As part of our commitment to customer satisfaction, it is
        our customary business practice to allow customers to return a Product within
        30 days of initial payment for any reason or no reason and to receive a refund
        of the amount paid for the returned Product. In the context of Hosted Services,
        a return means that we will disable access to the Hosted Services. We will not
        accept returns after the initial 30-day return period. You understand that
        Microquest may change this practice in the future in accordance with Section 23
        (Changes to this Agreement).</p>

      <h2>9.2<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Delivery</h2>

      <p className={ "MsoNormal" }>We will deliver the applicable login instructions (in the
        case of Hosted Services) to the email addresses specified in your Product
        Subscription when we have received payment of the applicable fees. All
        deliveries under this Agreement will be electronic.</p>

      <h2>9.3<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Payment</h2>

      <p className={ "MsoNormal" }>You agree to pay all fees in accordance with each Product
        Subscription. Unless otherwise specified in your Product Subscription, you will
        pay all amounts in Canadian (CDN) dollars at the time you place your Product
        Subscription. Other than as expressly set forth in Section 17 (IP
        Indemnification by Microquest), all amounts are non-refundable, non-cancelable
        and non-creditable. In making payments, you acknowledge that you are not
        relying on future availability of any Product beyond the current Subscription
        Term or any Product upgrades or feature enhancements. If you add Authorized
        Users during your Subscription Term, we will charge you for the increased
        number of Authorized Users pursuant to the then-currently applicable rates in
        your next billing cycle. You agree that we may bill your credit card for
        renewals, additional users, and unpaid fees, as applicable. </p>

      <h2>9.4<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Taxes</h2>

      <p className={ "MsoNormal" }>Your payments under this Agreement exclude any taxes or
        duties payable in respect of the Products in the jurisdiction where the payment
        is either made or received. To the extent that any such taxes or duties are
        payable by Microquest, you must pay to Microquest the amount of such taxes or
        duties in addition to any fees owed under this Agreement. Notwithstanding the
        foregoing, you may have obtained an exemption from relevant taxes or duties as
        of the time such taxes or duties are levied or assessed. In that case, you will
        have the right to provide to Microquest any such exemption information, and
        Microquest will use reasonable efforts to provide such invoicing documents as
        may enable you to obtain a refund or credit for the amount so paid from any
        relevant revenue authority if such a refund or credit is available.</p>

      <h1>10<span>&nbsp;&nbsp;&nbsp; </span>No-Charge
        Products</h1>

      <p className={ "MsoNormal" }>We may offer certain Products to you at no charge, including
        free accounts, trial use, and access to Beta Versions as defined below
        ("No-Charge Products"). Your use of No-Charge Products is subject to any
        additional terms that we specify and is only permitted for the period
        designated by us. You may not use No-Charge Products for competitive analysis
        or similar purposes. We may terminate your right to use No-Charge Products at
        any time and for any reason in our sole discretion, without liability to you.
        You understand that any pre-release and beta products we make available ("Beta
        Versions") are still under development, may be inoperable or incomplete and are
        likely to contain more errors and bugs than generally available Products. We
        make no promises that any Beta Versions will ever be made generally available.
        In some circumstances, we may charge a fee in order to allow you to access Beta
        Versions, but the Beta Versions will still remain subject to this Section 10
        (No-Charge Products). All information regarding the characteristics, features
        or performance of Beta Versions constitutes Microquest"s Confidential
        Information. To the maximum extent permitted by applicable law, we disclaim all
        obligations or liabilities with respect to No-Charge Products, including any
        Support and Maintenance, warranty, and indemnity obligations.</p>

      <h1>11<span>&nbsp;&nbsp;&nbsp; </span>Restrictions</h1>

      <p className={ "MsoNormal" }>Except as otherwise expressly permitted in this Agreement,
        you will not: (a) rent, lease, reproduce, modify, adapt, create derivative
        works of, distribute, sell, sublicense, transfer, or provide access to the
        Product to a third party, (b) use the Product for the benefit of any third
        party, (c) incorporate any Product into a product or service you provide to a
        third party, (d) interfere with any license key mechanism in the Product or
        otherwise circumvent mechanisms in the Product intended to limit your use, (e)
        reverse engineer, disassemble, decompile, translate, or otherwise seek to
        obtain or derive the source code, underlying ideas, algorithms, file formats or
        non-public APIs to any Product, except as permitted by law, (f) remove or
        obscure any proprietary or other notices contained in any Product, or (g)
        publicly disseminate information regarding the performance of the Product.</p>

      <h1>12<span>&nbsp;&nbsp;&nbsp; </span>Feedback</h1>

      <p className={ "MsoNormal" }>From time to time, you may choose to submit comments,
        information, questions, data, ideas, description of processes, or other
        information to Microquest, including sharing your modifications or in the
        course of receiving Support and Maintenance ("Feedback"). Microquest may in
        connection with any of its products or services freely use, copy, disclose,
        license, distribute and exploit any Feedback in any manner without any
        obligation, royalty or restriction based on intellectual property rights or
        otherwise. No Feedback will be considered your Confidential Information, and
        nothing in this Agreement limits Microquest's right to independently use,
        develop, evaluate, or market products, whether incorporating Feedback or
        otherwise.</p>

      <h1>13<span>&nbsp;&nbsp;&nbsp; </span>Confidentiality</h1>

      <p className={ "MsoNormal" }>Except as otherwise set forth in this Agreement, each party
        agrees that all code, inventions, know-how, business, technical and financial
        information disclosed to such party ("Receiving Party") by the disclosing party
        (&quot;Disclosing Party&quot;) constitute the confidential property of the
        Disclosing Party (&quot;Confidential Information&quot;), provided that it is
        identified as confidential at the time of disclosure. Any Microquest technology
        and any performance information relating to the Products shall be deemed
        Confidential Information of Microquest without any marking or further
        designation. Except as expressly authorized herein, the Receiving Party will
        hold in confidence and not use or disclose any Confidential Information. The
        Receiving Party's nondisclosure obligation shall not apply to information which
        the Receiving Party can document: (i) was rightfully in its possession or known
        to it prior to receipt of the Confidential Information; (ii) is or has become
        public knowledge through no fault of the Receiving Party; (iii) is rightfully
        obtained by the Receiving Party from a third party without breach of any
        confidentiality obligation; or (iv) is independently developed by employees of
        the Receiving Party who had no access to such information. The Receiving Party
        may also disclose Confidential Information if so required pursuant to a
        regulation, law or court order (but only to the minimum extent required to
        comply with such regulation or order and with advance notice to the Disclosing
        Party). The Receiving Party acknowledges that disclosure of Confidential
        Information would cause substantial harm for which damages alone would not be a
        sufficient remedy, and therefore that upon any such disclosure by the Receiving
        Party the Disclosing Party shall be entitled to appropriate equitable relief in
        addition to whatever other remedies it might have at law. For the avoidance of
        doubt, this Section shall not operate as a separate warranty with respect to
        the operation of any Product.</p>

      <h1>14<span>&nbsp;&nbsp;&nbsp; </span>Term
        and Termination.</h1>

      <p className={ "MsoNormal" }>This Agreement is in effect for as long as you have a valid
        Product Subscription unless sooner terminated as permitted in this Agreement.
        Either party may terminate this Agreement before the expiration of the Term if
        the other party materially breaches any of the terms of this Agreement and does
        not cure the breach within thirty (30) days after written notice of the breach.
        Either party may also terminate the Agreement before the expiration of the Term
        if the other party ceases to operate, declares bankruptcy, or becomes insolvent
        or otherwise unable to meet its financial obligations. You may terminate this
        Agreement at any time with notice to Microquest, but you will not be entitled
        to any credits or refunds as a result of convenience termination for prepaid
        but unused Hosted Services subscriptions, or Support and Maintenance prior to
        the end of the month in which the termination was effective. Except where an
        exclusive remedy may be specified in this Agreement, the exercise by either
        party of any remedy, including termination, will be without prejudice to any
        other remedies it may have under this Agreement, by law, or otherwise. Once the
        Agreement terminates, you (and your Authorized Users) will no longer have any
        right to use or access any Products, or any information or materials that we
        make available to you under this Agreement, including Microquest Confidential
        Information. You are required to delete any of the foregoing from your systems
        as applicable (including any third party systems operated on your behalf) and
        provide written certification to us that you have done so at our request. The
        following provisions will survive any termination or expiration of this
        Agreement: Sections 7.8.5 (Indemnity for Your Data), 9.3 (Payment), 9.4
        (Taxes), 10 (No-Charge Products) (disclaimers and use restrictions only), 11
        (Restrictions) 12 (Feedback), 13 (Confidentiality),14 (Term and Termination),
        15.2 (Warranty Disclaimer), 16 (Limitation of Liability), 18 (Third Party
        Vendor Products), and 20 (Dispute Resolution).</p>

      <h1>15<span>&nbsp;&nbsp;&nbsp; </span>Warranty
        and Disclaimer</h1>

      <h2>15.1<span>&nbsp;&nbsp; </span>Due
        Authority</h2>

      <p className={ "MsoNormal" }>Each party represents and warrants that it has the legal
        power and authority to enter into this Agreement, and that, if you are an
        entity, this Agreement and each Subscription is entered into by an employee or
        agent of such party with all necessary authority to bind such party to the
        terms and conditions of this Agreement.</p>

      <h2>15.2<span>&nbsp;&nbsp; </span>WARRANTY
        DISCLAIMER</h2>

      <p className={ "MsoNormal" }>ALL PRODUCTS ARE PROVIDED "AS IS," AND MICROQUEST AND ITS
        SUPPLIERS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY
        KIND, INCLUDING ANY WARRANTY OF NON-INFRINGEMENT, TITLE, FITNESS FOR A
        PARTICULAR PURPOSE, FUNCTIONALITY, OR MERCHANTABILITY, WHETHER EXPRESS,
        IMPLIED, OR STATUTORY. YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT THE DURATION OF
        STATUTORILY REQUIRED WARRANTIES, IF ANY, SHALL BE LIMITED TO THE SHORTEST
        PERIOD PERMITTED BY LAW. MICROQUEST SHALL NOT BE LIABLE FOR DELAYS,
        INTERRUPTIONS, SERVICE FAILURES AND OTHER PROBLEMS INHERENT IN USE OF THE
        INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE
        CONTROL OF MICROQUEST. TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER
        MICROQUEST NOR ANY OF ITS THIRD PARTY SUPPLIERS MAKES ANY REPRESENTATION,
        WARRANTY OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY,
        TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF ANY PRODUCTS OR ANY CONTENT
        THEREIN OR GENERATED THEREWITH, OR THAT: (A) THE USE OF ANY PRODUCTS WILL BE
        SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE; (B) THE PRODUCTS WILL OPERATE IN
        COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA; (C) THE
        PRODUCTS (OR ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED
        OR OBTAINED BY YOU THROUGH THE PRODUCTS) WILL MEET YOUR REQUIREMENTS OR
        EXPECTATIONS); (D) ANY STORED DATA WILL BE ACCURATE OR RELIABLE OR THAT ANY STORED
        DATA WILL NOT BE LOST OR CORRUPTED; (E) ERRORS OR DEFECTS WILL BE CORRECTED; OR
        (F) THE PRODUCTS (OR ANY SERVER(S) THAT MAKE A HOSTED SERVICE AVAILABLE) ARE
        FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>

      <h1>16<span>&nbsp;&nbsp;&nbsp; </span>Limitation
        of Liability</h1>

      <p className={ "MsoNormal" }>NEITHER PARTY (NOR ITS SUPPLIERS) SHALL BE LIABLE FOR ANY
        LOSS OF USE, LOST OR INACCURATE DATA, FAILURE OF SECURITY MECHANISMS,
        INTERRUPTION OF BUSINESS, COSTS OF DELAY OR ANY INDIRECT, SPECIAL, INCIDENTAL,
        RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS),
        REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
        NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EVEN IF INFORMED OF THE POSSIBILITY
        OF SUCH DAMAGES IN ADVANCE. NEITHER PARTY"S AGGREGATE LIABILITY TO THE OTHER
        SHALL EXCEED THE AMOUNT ACTUALLY PAID BY YOU TO US FOR PRODUCTS AND SUPPORT AND
        MAINTENANCE IN THE 12 MONTHS IMMEDIATELY PRECEDING THE CLAIM. NOTWITHSTANDING
        ANYTHING ELSE IN THIS AGREEMENT, OUR AGGREGATE LIABILITY TO YOU IN RESPECT OF
        NO-CHARGE PRODUCTS SHALL BE CDN$20. THIS SECTION 16 (LIMITATION OF LIABILITY)
        SHALL NOT APPLY TO (1) AMOUNTS OWED BY YOU UNDER ANY SUBSCRIPTIONS, (2) EITHER
        PARTY"S EXPRESS INDEMNIFICATION OBLIGATIONS IN THIS AGREEMENT, OR (3) YOUR
        BREACH OF SECTION 11 (RESTRICTIONS) OR COMBINING THE PRODUCTS WITH OPEN SOURCE
        SOFTWARE OF THIRD PARTY CODE IN MICROQUEST PRODUCTS. TO THE MAXIMUM EXTENT
        PERMITTED BY LAW, NO SUPPLIERS OF ANY THIRD PARTY COMPONENTS INCLUDED IN THE
        PRODUCTS WILL BE LIABLE TO YOU FOR ANY DAMAGES WHATSOEVER. The parties agree
        that the limitations specified in this Section 16 (Limitation of Liability)
        will survive and apply even if any limited remedy specified in this Agreement
        is found to have failed of its essential purpose.</p>

      <p className={ "MsoNormal" }>Microquest is not liable for any information stored or
        recorded by any computer, tablet, mobile device or any network, whether public
        or private, that you may use to access your Product Subscription or any other
        product or service offered by use of this platform.</p>

      <h1>17<span>&nbsp;&nbsp;&nbsp; </span>IP
        Indemnification by Microquest</h1>

      <h2>17.1<span>&nbsp;&nbsp; </span>Claim Defense</h2>

      <p className={ "MsoNormal" }>We will defend you against any claim brought against you by
        a third party alleging that a Product, when used as authorized under this
        Agreement, infringes a Canadian, United States or European Union patent or
        registered copyright (a "Claim"), and we will indemnify you and hold you
        harmless against any damages and costs finally awarded by a court of competent
        jurisdiction or agreed to settlement by Microquest (including reasonable
        lawyers" fees) arising out of a Claim, provided that we have received from you:
        (a) prompt written notice of the claim (but in any event notice in sufficient
        time for us to respond without prejudice); (b) reasonable assistance in the
        defense and investigation of the claim, including providing us a copy of the
        claim and all relevant evidence in your possession, custody or control; and (c)
        the exclusive right to control and direct the investigation, defense, and
        settlement (if applicable) of the claim. If your use of a Product is (or in our
        opinion is likely to be) enjoined, if required by settlement, or if we
        determine such actions are reasonably necessary to avoid material liability, we
        may, at our option and in our discretion: (i) procure a license for your
        continued use of the Product in accordance with this Agreement; (ii) substitute
        a substantially functionally similar Product; or (iii) terminate your right to
        continue using the Product and refund, in the case of Software, the license fee
        paid by you as reduced to reflect a three year straight-line depreciation from
        the license purchase date, and in the case of a Hosted Service, any prepaid
        amounts for the terminated portion of the Subscription Term. </p>

      <p className={ "MsoNormal" }>&nbsp;</p>

      <h2>17.2<span>&nbsp;&nbsp; </span>Indemnification
        Obligations</h2>

      <p className={ "MsoNormal" }>Microquest"s indemnification obligations above do not apply:
        (1) if the Product is modified by any party other than Microquest, but solely
        to the extent the alleged infringement is caused by such modification; (2) if
        the Product is used in combination with any non-Microquest product, software or
        equipment, but solely to the extent the alleged infringement is caused by such
        combination; (3) to unauthorized use of Products; (4) to any Claim arising as a
        result of Your Data (or circumstances covered by your indemnification
        obligations in Section 7.8.5 (Indemnity for Your Data) or any third-party
        deliverables or components contained with the Products; (5) to any unsupported
        release of the Software; or (6) if you settle or make any admissions with
        respect to a claim without Microquest"s prior written consent. THIS SECTION 17
        (IP INDEMNIFICATION BY MICROQUEST) STATES OUR SOLE LIABILITY AND YOUR EXCLUSIVE
        REMEDY FOR ANY INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS IN CONNECTION WITH
        ANY PRODUCT OR OTHER ITEMS PROVIDED BY MICROQUEST UNDER THIS AGREEMENT.</p>

      <h1>18<span>&nbsp;&nbsp;&nbsp; </span>Third
        Party Vendor Products</h1>

      <p className={ "MsoNormal" }>Microquest or third parties may from time to time make
        available to you (e.g., through the Microquest Marketplace) third-party
        products or services, including but not limited to add-ons and plugins as well
        as implementation, customization, training, and other consulting services. If you
        procure any of these third party products or services, you do so under a
        separate agreement (and exchange of data) solely between you and the third
        party vendor. Microquest does not warrant or support non-Microquest products or
        services, whether or not they are designated by Microquest as "verified" or
        otherwise, and disclaims all liability for such products or services. If you
        install or enable any third party products or services for use with Microquest
        products, you acknowledge that Microquest may allow the vendors of those
        products and services to access Your Data as required for the interoperation
        and support of such add-ons with the Microquest products. Microquest shall not
        be responsible for any disclosure, modification or deletion of Your Data resulting
        from any such access by third party add-on vendors. The intellectual property
        of Foxit is protected, and end users shall not redistribute, resell, modify, reverse engineer, or
        reuse any part of the SDK in any application except the Product</p>

      <h1>19<span>&nbsp;&nbsp;&nbsp; </span>Improving
        Our Products</h1>

      <p className={ "MsoNormal" }>We are always striving to improve our Products. In order to
        do so, we need to measure, analyze, and aggregate how users interact with our
        Products, such as usage patterns and characteristics of our user base. We
        collect and use analytics data regarding the use of our Products as described
        in our Privacy Policy.</p>

      <h1>20<span>&nbsp;&nbsp;&nbsp; </span>Dispute
        Resolution</h1>

      <h2>20.1<span>&nbsp;&nbsp; </span>Dispute
        Resolution; Arbitration</h2>

      <p className={ "MsoNormal" }>In the event of any controversy or claim arising out of or
        relating to this Agreement, the parties hereto shall consult and negotiate with
        each other and, recognizing their mutual interests, attempt to reach a solution
        satisfactory to both parties. If the parties do not reach settlement within a
        period of 60 days, any unresolved controversy or claim arising out of or
        relating to this Agreement shall proceed to binding arbitration under the
        Alberta Arbitration Act, R.S.A.. The language of the arbitration shall be
        English.</p>

      <h2>20.2<span>&nbsp;&nbsp; </span>Governing
        Law; Jurisdiction</h2>

      <p className={ "MsoNormal" }>This Agreement will be governed by and construed in
        accordance with the applicable laws of the Province of Alberta, Canada, without
        giving effect to the principles of that Province relating to conflicts of laws
        and each party irrevocably submits to the sole and exclusive personal jurisdiction
        of the courts of the Province of Alberta, Canada, generally and
        unconditionally, with respect to any action, suit or proceeding brought by it
        or against it by the other party. Notwithstanding the foregoing, Microquest may
        bring a claim for equitable relief in any court with proper jurisdiction.</p>

      <h2>20.3<span>&nbsp;&nbsp; </span>Injunctive
        Relief; Enforcement</h2>

      <p className={ "MsoNormal" }>Notwithstanding the provisions of Section 21.1 (Dispute
        Resolution; Arbitration), nothing in this Agreement shall prevent either party
        from seeking injunctive relief with respect to a violation of intellectual
        property rights, confidentiality obligations or enforcement or recognition of
        any award or order in any appropriate jurisdiction.</p>

      <h2>20.4<span>&nbsp;&nbsp; </span>Exclusion
        of UN Convention and UCITA</h2>

      <p className={ "MsoNormal" }>The terms of the United Nations Convention on Contracts for
        the Sale of Goods do not apply to this Agreement. The Uniform Computer
        Information Transactions Act (UCITA) shall not apply to this Agreement
        regardless of when or where adopted.</p>

      <h1>21<span>&nbsp;&nbsp;&nbsp; </span>Notices</h1>

      <p className={ "MsoNormal" }>Any notice under this Agreement must be given in writing. We
        may provide notice to you via email or through your account. Our notices to you
        will be deemed given upon the first business day after we send it. You may
        provide notice to us electronically to support@microquest.ca . Your notices to
        us will be deemed given upon our receipt. Neither party shall be liable to the
        other for any delay or failure to perform any obligation under this Agreement
        (except for a failure to pay fees) if the delay or failure is due to unforeseen
        events which are beyond the reasonable control of such party, such as a strike,
        blockade, war, act of terrorism, riot, natural disaster, failure or
        diminishment of power or telecommunications or data networks or services, or
        refusal of a license by a government agency. You may not assign this Agreement
        without our prior written consent. The parties are independent contractors.
        This Agreement shall not be construed as constituting either party as a partner
        of the other or to create any other form of legal association that would give
        on party the express or implied right, power or authority to create any duty or
        obligation of the other party.</p>

      <h1>22<span>&nbsp;&nbsp;&nbsp; </span>Changes
        to this Agreement</h1>

      <p className={ "MsoNormal" }>We may update or modify this Agreement from time to time,
        including any referenced policies and other documents. If a revision
        meaningfully reduces your rights, we will use reasonable efforts to notify you
        (by, for example, sending an email to the billing or technical contact you
        designate in the applicable Subscription, posting on our blog, through your
        Microquest account, or in the Product itself). If we modify the Agreement
        during your Subscription Term, the modified version will be effective upon your
        next renewal of a Subscription Term. In this case, if you object to the updated
        Agreement, as your exclusive remedy, you may choose not to renew, including
        cancelling any terms set to auto-renew. With respect to No-Charge Products,
        accepting the updated Agreement is required for you to continue using the
        No-Charge Products. You may be required to click through the updated Agreement
        to show your acceptance. If you do not agree to the updated Agreement after it
        becomes effective, you will no longer have a right to use No-Charge Products.
        For the avoidance of doubt, any Subscription is subject to the version of the
        Agreement in effect at the time of the Subscription.</p>

    </TosPrivacyPolicyLayout>
  </StaticPage>
}
