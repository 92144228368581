import { Route, Routes } from "react-router-dom";
import React from "react";
import { ResourceNotFound } from '../../routes/static/ErrorPages'
import { PatientLayout } from "./PatientLayout";
import { PatientNotes } from "./components/profile/PatientNotes";
import { PatientAppointments } from "./components/profile/PatientAppointments";
import { PatientPrescriptions } from "./components/prescriptions/PatientPrescriptions";
import { FormsList } from "./components/forms/FormsList";
import { PatientOtherDocuments } from "./components/other-documents/PatientOtherDocuments";
import { NewPrescription } from "./components/prescriptions/NewPrescription";
import { EditPrescription } from "./components/prescriptions/EditPrescription";
import { PatientBase } from "./PatientBase";
import { PatientLetters } from "./components/letters/PatientLetters";
import { LetterFormMain } from "./components/letters/LetterFormMain";
import { LetterDocumentEditor } from "./components/letters/LetterDocumentEditor";
import { FormsEditor } from "./components/forms/FormsEditor";
import { EditOtherDocument } from "./components/other-documents/EditOtherDocument";
import { EncounterNotes } from "./components/encounterNotes/EncounterNotesList";
import { EncounterNotesDocumentEditor} from "./components/encounterNotes/EncounterNotesDocumentEditor";
import { PrescriptionProvider } from "./providers/PrescriptionProvider";
import { LetterProvider } from "./providers/LetterProvider";
import { AllergyProvider } from "./providers/AllergyProvider";
import { PatientFormProvider } from "./providers/PatientFormProvider";
import { ConditionAndProcedureProvider } from "./providers/ConditionAndProcedureProvider"
import { AppointmentProvider } from "./providers/AppointmentProvider"
import { LabAndInvestigations } from "./components/labAndInvestigations/LabAndInvestigationsList";
import { LabAndInvestigationDocumentEditor } from "./components/labAndInvestigations/LabAndInvestigationDocumentEditor";
import { PatientProfileProvider } from "./providers/PatientProfileProvider";
import { PatientDocumentProvider } from "./providers/PatientDocumentProvider";
import { PatientTimeline } from "./components/profile/PatientTimeline";
import { PatientProfile } from "./components/profile/PatientProfile";
import { PatientRelationships } from "./components/profile/PatientRelationships";
import { PatientBilling } from "./components/profile/PatientBilling";
import { LabAndInvestigationProvider } from "./providers/LabAndInvestigationProvider";
import { LabAndInvestigationEditor } from './components/labAndInvestigations/LabAndInvestigationEditor'
import { FormsDocumentEditor } from "./components/forms/FormsDocumentEditor";
import { EncounterNoteProvider } from './providers/EncounterNoteProvider'
import { EncounterNotesEditor } from './components/encounterNotes/EncounterNotesEditor'
import { PatientDocumentStateProvider } from './providers/PatientDocumentStateProvider'
import { HistoryProvider } from './providers/HistoryProvider'
import { EditorImageProvider } from '../../providers/EditorImageContextProvider'
import { PatientTaskProvider } from './providers/PatientTaskProvider'


const PatientRoutesProviders = (Component) => {
  return (
    <PatientProfileProvider>
      <AppointmentProvider>
        <PrescriptionProvider>
          <HistoryProvider>
            <PatientDocumentStateProvider>
              <PatientDocumentProvider>
                <LetterProvider>
                  <AllergyProvider>
                    <PatientFormProvider>
                      <ConditionAndProcedureProvider>
                        <LabAndInvestigationProvider>
                          <EncounterNoteProvider>
                            <PatientTaskProvider>
                              <EditorImageProvider>
                                <Component />
                              </EditorImageProvider>
                            </PatientTaskProvider>
                          </EncounterNoteProvider>
                        </LabAndInvestigationProvider>
                      </ConditionAndProcedureProvider>
                    </PatientFormProvider>
                  </AllergyProvider>
                </LetterProvider>
              </PatientDocumentProvider>
            </PatientDocumentStateProvider>
          </HistoryProvider>
        </PrescriptionProvider>
      </AppointmentProvider>
    </PatientProfileProvider>
  )
}



export const Patients = () => {
  return (
    <Routes>
      <Route element={<PatientBase />}>
        <Route path={'p/:patient_id'} element={PatientRoutesProviders(PatientLayout)}>

          <Route index element={<PatientTimeline />} />
          <Route path={'details'} element={<PatientProfile autoFocus={false}/>} />
          <Route path={'notes'} element={<PatientNotes />} />
          <Route path={'relationships'} element={<PatientRelationships />} />
          <Route path={'billing'} element={<PatientBilling />} />
          <Route path={'appointments'} element={<PatientAppointments />} />

          <Route path={'prescriptions'}>
            <Route index element={<PatientPrescriptions />} />
            <Route path={'new'} element={<NewPrescription />} />
            <Route path={'p/:prescription_id'} element={<EditPrescription />} />
          </Route>

          <Route path={'forms'}>
            <Route index element={<FormsList/>} />
            <Route path={'new'} element={<FormsEditor/>} />
            <Route path={'f/:form_id'} element={<FormsEditor/>} />
            <Route path={'d/:document_id'} element={<FormsDocumentEditor/>} />
          </Route>

          <Route path={'letters'} >
            <Route index element={<PatientLetters />} />
            <Route path={'new'} element={<LetterFormMain />} />
            <Route path={'l/:letter_id'} element={<LetterFormMain />} />
            <Route path={'d/:document_id'} element={<LetterDocumentEditor />} />
          </Route>

          <Route path={'other-documents'}>
            <Route index element={<PatientOtherDocuments />} />
            <Route path={'d/:document_id'} element={<EditOtherDocument />} />
          </Route>

          <Route path={'labs-investigations'}>
            <Route index element={<LabAndInvestigations />} />
            <Route path={'l/:lab_id'} element={<LabAndInvestigationEditor />} />
            <Route path={'d/:document_id'} element={<LabAndInvestigationDocumentEditor />} />
          </Route>

          <Route path={'encounter-notes'}>
            <Route index element={<EncounterNotes />} />
            <Route path={'new'} element={<EncounterNotesEditor/>} />
            <Route path={'e/:encounterNote_id'} element={<EncounterNotesEditor />} />
            <Route path={'d/:document_id'} element={<EncounterNotesDocumentEditor />} />
          </Route>

        </Route>
        <Route path="*" element={<ResourceNotFound />} />
      </Route>
    </Routes>
  )
}
