import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TableList, TableListHeaderConfig } from "saga-library/src";
import { useQuery } from "@apollo/client";
import { AppointmentTypeRow } from "./AppointmentTypeRow";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { AppointmentType } from "../../../../types/schedule";
import { NewAppointmentTypeModal } from "./NewAppointmentTypeModal";
import { EditAppointmentTypeModal } from "./EditAppointmentTypeModal";
import { LIST_APPOINTMENT_TYPES, LIST_SCHEDULES } from "../../../../graphql-definitions";
import { useAccountContext } from "../../../../providers/AccountContext";
import SettingsHeader from "../../components/SettingsHeader";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";

const columns: TableListHeaderConfig[] = [
  { name: 'Name' },
  { name: 'Colour' },
  { name: 'Length' },
  { name: 'Appointments can take place... '},
  { name: 'Available to '}
]

export const AppointmentTypes = () => {
  const navigate = useNavigate()
  const { userHasPermission } = useAccountContext()
  const { tenant_id } = useParams()
  const [newAppointmentTypeOpen, setNewAppointmentTypeOpen] = useState(false)
  const [editAppointmentType, setEditAppointmentType] = useState<AppointmentType | null>(null)
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Schedule, Permission.READWRITE)

  const { loading, error, data } = useQuery(LIST_APPOINTMENT_TYPES, {
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  const { loading: scheduleLoading, data: scheduleData} = useQuery(LIST_SCHEDULES, {
    variables: {tenantId: tenant_id },
    onError:  (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Appointment types'}
        onBack={() => navigate(-1)}
        dataTestId={"appointmentTypes"}
        actions={
        <PermissionButton
          name={'newAppointmentType'}
          onClick={() => setNewAppointmentTypeOpen(true)}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={"appointmentTypes-new-button"}
          >
          NEW TYPE
        </PermissionButton>
        }
      />
    }>
      <TableList
        size={'small'}
        loading={loading || scheduleLoading}
        columns={columns}
        rows={
          data ? [...data.tenant.schedule.type.listTypes.map((appointmentType) => AppointmentTypeRow(appointmentType, setEditAppointmentType, hasReadWrite))] : []
        }
        sx={{ mt: 2 }}
        dataTestId={"appointmentTypes-list"}
        leftAlignColumns={true}
      />
      {newAppointmentTypeOpen &&
        <NewAppointmentTypeModal
          open={newAppointmentTypeOpen}
          setOpen={setNewAppointmentTypeOpen}
        />
      }
      {editAppointmentType &&
        <EditAppointmentTypeModal
          open={!!editAppointmentType}
          onClose={() => setEditAppointmentType(null)}
          appointmentType={editAppointmentType}
        />
      }
    </SettingsSectionColumn>
  )
}
