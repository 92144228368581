import React, { useCallback, useMemo, useState } from "react";
import { ConfirmationDialog } from "saga-library/src";

type ConcurrencyProviderType = {
  showConcurrencyError: (user: string, version: string) => Promise<boolean>
}

type ConcurrencyError = {
  user: string,
  version: string
  callback: (value: (boolean | PromiseLike<boolean>)) => void
}

const ConcurrencyContext = React.createContext<ConcurrencyProviderType>({
  showConcurrencyError: (user, version) => Promise.resolve(false)
})

export const ConcurrencysProvider = ({ children }) => {

  const [open, setOpen] = useState<boolean>(false)
  const [concurrencyError, setConcurrencyError] = useState<ConcurrencyError>({
    user: '',
    version: "0",
    callback: (value) => {}
  })

  const showConcurrencyError = useCallback((user, version): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      openDialog({actionCallback: resolve, user, version})
    })
  }, [])

  const openDialog = useCallback(({ user, version, actionCallback }) => {
    setOpen(true);
    setConcurrencyError({
      user: user,
      version: version,
      callback: actionCallback
    })
  },[])

  const providerValues = useMemo(
    () => ({ showConcurrencyError }),
    [showConcurrencyError]
  )

  return (
    <ConcurrencyContext.Provider value={providerValues}>
      {children}
      <ConcurrencyErrorDialog
        open={open}
        setOpen={setOpen}
        onResult={concurrencyError.callback}
        user={concurrencyError.user}
        version={concurrencyError.version}
      />
    </ConcurrencyContext.Provider>
  )
}

export const useConcurrencyHandler = () => {
  return React.useContext(ConcurrencyContext)
}

const ConcurrencyErrorDialog = ({open, setOpen, onResult, user, version}) => {

  const ticksToMicrotime = (ticks : string) => {
    const ticksToMicrotime = Number(BigInt(ticks) / BigInt(10000))
    const epochMicrotimeDiff = 2208988800000
    return new Date(ticksToMicrotime - epochMicrotimeDiff)
  }

  const cancel = () => {
    onResult(false)
    setOpen(false)
  }

  const overwrite = () => {
    onResult(true)
    setOpen(false)
  }

  const reload = () => {
    window.location.reload()
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={cancel}
      title={"Another user has updated this record"}
      message={
        <>
          Changes have been made by <b>{user} at {ticksToMicrotime(version).toLocaleTimeString()}</b> since you loaded this page.
          Do you want to reload this page to see those changes?
          Reloading will clear your changes. Saving your changes will overwrite {user}'s changes.
        </>
      }
      primaryAction={reload}
      primaryLabel={"Reload page"}
      primaryVariant={"outlined"}
      secondaryAction={overwrite}
      secondaryLabel={"Save my changes"}
    />
  )
}
