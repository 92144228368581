import { Controller, useFormContext } from "saga-library/src/components/Form";
import { useTenantContext } from "../providers/TenantContextProvider";
import React, { useEffect } from "react";
import { Autocomplete } from "saga-library/src";
import { SxProps, Theme } from "@mui/material";

interface PractitionerSelectProps {
  label: string,
  name: string,
  getOptionDisabled?: (option) => boolean
  disabled?: boolean,
  setValueAsPractitioner?: boolean
  onChange?: (value) => void,
  dataTestId: string,
  sx?: SxProps<Theme>
}

export const PractitionerSelect = ({
  label='Practitioner',
  name,
  getOptionDisabled,
  disabled=false,
  setValueAsPractitioner=false,
  onChange,
  dataTestId,
  sx
}:PractitionerSelectProps) => {
  const { control } = useFormContext()
  const { practitioners } = useTenantContext()

  const [practitionerOptions, setPractitionerOptions] = React.useState<{value: string, label: string}[]>([])

  useEffect(()=>{
    const tempPrac = practitioners.map(practitioner => {
      return {
        value: practitioner.id,
        label: `${practitioner.lastName}, ${practitioner.firstName}`
      }
    })

    setPractitionerOptions(tempPrac)
  }, [practitioners])

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange: controlledOnChange, value = null }, fieldState: { error } }) => {
      if (value === '') {
        value = null
      }
      return <Autocomplete
        name={name}
        label={label}
        options={practitionerOptions}
        onChange={(e, newValue) => {
          if (setValueAsPractitioner) {
            const newPractitioner = practitioners.find((p) => p.id === newValue?.value)
            controlledOnChange(newPractitioner)
            if (onChange) {
              onChange(newPractitioner)
            }
          } else {
            controlledOnChange(newValue?.value ?? null)
            if (onChange) {
              onChange(newValue?.value ?? null)
            }
          }
        }}
        getOptionLabel={(option) => {
          if(typeof option === 'string') {
            return practitionerOptions.find(o => o.value === option)?.label || ""
          }
          return option?.label || ""
        }}
        isOptionEqualToValue={(option, value) => option?.value === (setValueAsPractitioner ? value.id : value)}
        getOptionDisabled={getOptionDisabled}
        value={value}
        sx={{flex: '1 1 100%', ...sx}}
        disabled={disabled}
        onInputChange={(e, value, reason) => {
          if(reason === 'clear'){
            controlledOnChange('')
            if (onChange) {
              onChange('')
            }
          }
        }}
        error={error}
        helperText={error?.message}
        dataTestId={`practitionersSelect-${dataTestId}`}
      />
    }}
  />
}
