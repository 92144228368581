import React, { ReactElement, Suspense, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { IconButton, Section, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { formatNextAppointmentDate, patientDisplayName, practitionerDisplayName } from 'saga-library/src/util/formatting'
import { AllergyWidget } from './components/widgets/AllergyWidget'
import { PrescriptionWidget } from './components/widgets/PrescriptionWidget'
import { ConditionAndProceduresWidget } from './components/widgets/ConditionAndProceduresWidget'
import { LetterWidget } from './components/widgets/LetterWidget'
import { OtherDocumentsWidget } from './components/widgets/OtherDocumentsWidget'
import { FormWidget } from './components/widgets/FormWidget'
import { LabsAndInvestigationsWidget } from './components/widgets/LabsAndInvestigationsWidget'
import { EncounterNotesWidget } from './components/widgets/EncounterNotesWidget'
import { TaskWidget } from './components/widgets/TaskWidget'
import { useAccountContext } from '../../providers/AccountContext'
import { useFormattedDate } from '../../hooks/FormattedDate'
import { IdentifierGroup } from 'saga-library/src/components/SearchControl/blocks'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLongOutlined'
import ScheduleIcon from '@mui/icons-material/CalendarMonthOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { PractitionerIcon, TimelineIcon } from './components/ChartIcons'
import { PrintPatientModal } from './components/print/PrintPatientModal'
import { usePageTitleContext } from '../../providers/PageTitleContextProvider'
import { usePatientProfileContext } from './providers/PatientProfileProvider'
import { useTenantContext } from '../../providers/TenantContextProvider'
import { FormattedAgeString } from '../../components/FormattedAge'
import { postalCodeMask } from 'saga-library/src/components/PostalCodeField/PostalCodeField'
import { phoneNumberMask } from 'saga-library/src/components/PhoneField/PhoneField'
import { useReadQuery } from '@apollo/client'
import { PatientRelationshipsType } from './components/profile/PatientRelationships'
import { omit } from 'lodash'
import _get from 'lodash/get'
import { LoadingSpinner } from '../../components/LoadingScreen'
import { OpenAppointmentOnSchedule } from './components/PatientAppointmentRow'
import { useAppointmentContext } from './providers/AppointmentProvider'
import moment from 'moment-timezone'
import { calculateBMI, convertMeasurementUnits, formatDecimal } from './components/vitals/unitConversionUtil'
import { LengthHeightUnits, PatientVitalDetailsType, VitalsType, WeightUnits } from './components/vitals/VitalTypes'
import VitalsModal from './components/VitalsModal'
import { HistoryWidget } from './components/widgets/HistoryWidget'
import { useUserInteraction } from '../../providers/UserInteractionContext'

const SIDE_PANEL_WIDTH = '376px'

export const PatientLayout = () => {
  const { patient_id, tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { setAreaTitle } = usePageTitleContext()
  const navigate = useNavigate()
  const { patientPrimaryIdentifier } = useTenantContext()
  const { clearScrollPositions } = useUserInteraction()

  const { profileData, patientRelationshipRef, patientCurrentVitalsRef  } = usePatientProfileContext()
  const { data: relationshipData } = useReadQuery(patientRelationshipRef)
  const patientRelationships: PatientRelationshipsType = omit(_get(relationshipData, 'tenant.patient.relationships', null), 'id')
  const { data: currentVitalsData } = useReadQuery(patientCurrentVitalsRef)
  const patientCurrentVitals: PatientVitalDetailsType = omit(_get(currentVitalsData, 'tenant.patient.currentVitals', null), 'id')

  const { appointmentQueryRef, getAppointmentQueryResults } = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef)
  const futureAppointments = useMemo(() =>
      getAppointmentQueryResults(appointmentData).filter(appointment => moment(appointment.itemDate).isAfter(moment()))
    , [appointmentData, getAppointmentQueryResults])

  useEffect(() => {
    clearScrollPositions()
    if (profileData) {
      setAreaTitle(patientDisplayName(profileData.firstName, profileData.lastName))
    }
  }, [profileData])

  const navigateInMain = (route) => {
    navigate(
      buildTenantRoute(`patients/p/${patient_id}/${route}`, tenant_id)
    )
  }

  const PatientDetails = ({patient}) => {
    const patientIdentifier = patientPrimaryIdentifier(patient)
    const [ openVitalType, setOpenVitalType ] = useState<VitalsType | null>(null)

    const DisplayDateOfBirthAndGender = ({gender, dob}: {gender: string | undefined, dob: string }) => {
      const formattedDob = useFormattedDate(dob)
      if (formattedDob) {
        let formattedAgeAndGender = `${FormattedAgeString(dob, false)} old`
        if (gender) {
          formattedAgeAndGender += ` ${gender.toLowerCase()}`
        }
        return <Typography variant={'p2'}>{formattedDob} ({formattedAgeAndGender})</Typography>
      }
      if (gender) {
        return <Typography variant={'p2'}>{gender}</Typography>
      }
      return <NoInformationEntered label={'birthdate'}/>
    }

    const DisplayIdentifier = ({ primaryIdentifier }) => {
      if (primaryIdentifier?.formatted) {
        return <IdentifierGroup dataTestId={"patientLayoutDobLabel"} variant={'p2'} height={"16px"} label={primaryIdentifier.label} identifier={primaryIdentifier.formatted} />
      }
      return <NoInformationEntered label={'identifier'}/>
    }

    const DisplayPractitioner = ({ clinicPractitioners }) => {
      let primaryPractitioner = clinicPractitioners?.find(practitioner => practitioner.isPrimaryPractitioner)
      if (!primaryPractitioner && clinicPractitioners?.length > 0) {
        primaryPractitioner = clinicPractitioners[0]
      }
      if (primaryPractitioner) {
        return <Typography variant={'p2'}>{practitionerDisplayName(primaryPractitioner.firstName, primaryPractitioner.lastName)}</Typography>
      }
      return <NoInformationEntered label={'practitioner'} ending={'selected'}/>
    }

    const DisplayPrimaryPhoneNumber = ({ phones }) => {
      const primaryPhoneNumber = phones?.find(phone => phone.isPrimary)
      if (primaryPhoneNumber) {
        return <Typography variant={'p2'}>{phoneNumberMask(primaryPhoneNumber.number)} {primaryPhoneNumber.extension}</Typography>
      }
      return <NoInformationEntered label={'phone number'}/>
    }

    const DisplayVitals = ({ vitals }) => {
      const VitalBox = ({label, value, units, onClick}) => {
        if (value) {
          return (
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"4px"}
              alignItems={"center"}
              onClick={onClick}
              sx={(theme) => ({
                cursor: onClick ? 'pointer' : 'default',
                borderRadius: 2,
                "&:hover": {
                  backgroundColor: onClick ? theme.palette.backgrounds.hover : 'inherit'
                }
              })}
            >
              <Typography variant={'p3'} dataTestId={`vital-${label}-label`}>{label}</Typography>
              <Typography variant={'p2'} dataTestId={`vital-${label}-value`}>{value} {units}</Typography>
            </Box>
          )
        }
        return null
      }

      const formatBloodPressure = (systolic, diastolic) => {
        if (systolic && diastolic) {
          return `${systolic}/${diastolic}`
        }
        return null
      }


      const isMaleOrFemale = patient.gender && (patient.gender.name === "Male" || patient.gender.name === "Female")
      const getAge = () => {
        if(patient.dob) {
          const today = moment();
          const momentDob = moment(patient.dob);

          return today.diff(momentDob, 'years')
        }
        return null
      }

      const getHeadCircumferenceValue = () => {
        let currentAge = getAge()
        if(currentAge === null) return null
        return currentAge > 2 ? null : formatDecimal(convertMeasurementUnits(vitals[0].headCircumference, vitals[0].headCircumferenceUnit,LengthHeightUnits.CM))
      }

      const onHeightOrWeightClick = (vitalsType) => {
        const age = getAge()
        let targetVital = vitalsType
        if (age !== null && age <= 19 && isMaleOrFemale) {
          if (age < 2) {
            if (patient.gender.name === "Male") {
              targetVital = VitalsType.LENGTH_BOYS
            } else {
              targetVital = VitalsType.LENGTH_GIRLS
            }
          } else {
            if (patient.gender.name === "Male") {
              targetVital = VitalsType.HW_BOYS
            } else {
              targetVital = VitalsType.HW_GIRLS
            }
          }
        }
        setOpenVitalType(targetVital)
      }

      const onHCClick = () => {
        let targetVital = VitalsType.WEIGHT
        if(patient.gender && patient.gender.name === "Male") {
          targetVital = VitalsType.HC_BOYS
        } else if(patient.gender && patient.gender.name === "Female") {
          targetVital = VitalsType.HC_GIRLS
        }

        setOpenVitalType(targetVital)
      }

      const onBMIClick = () => {
        const age = getAge()
        let targetVital = VitalsType.BMI

        if (age !== null && age >= 2 && age <= 19 && isMaleOrFemale) {
          if (patient.gender.name === "Male") {
            targetVital = VitalsType.BMI_BOYS
          } else {
            targetVital = VitalsType.BMI_GIRLS
          }
        }

        setOpenVitalType(targetVital)
      }

      if (vitals && vitals.length === 1) {
        const currentVitals = vitals[0]
        return (
          <Box display={"flex"} flexDirection={"column"} gap={"2px"}>
            <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
                <VitalBox
                  label={"HT"}
                  units={"cm"}
                  value={formatDecimal(convertMeasurementUnits(currentVitals.lengthHeight,currentVitals.lengthHeightUnit,LengthHeightUnits.CM))}
                  onClick={ ()=> onHeightOrWeightClick(VitalsType.HEIGHT) }
                />
                <VitalBox
                  label={"WT"}
                  units={"kg"}
                  value={formatDecimal(convertMeasurementUnits(currentVitals.weight,currentVitals.weightUnit,WeightUnits.KG))}
                  onClick={ ()=> onHeightOrWeightClick(VitalsType.WEIGHT) }
                />
                <VitalBox
                  label={"BMI"}
                  units={""}
                  value={calculateBMI(currentVitals.weight, currentVitals.weightUnit, currentVitals.lengthHeight, currentVitals.lengthHeightUnit)}
                  onClick={ onBMIClick }
                />
              </Box>
              <VitalBox
                label={"BP"}
                units={"mm Hg"}
                value={formatBloodPressure(currentVitals.bloodPressureSystolic,currentVitals.bloodPressureDiastolic)}
                onClick={ ()=> setOpenVitalType(VitalsType.BLOOD_PRESSURE)}
              />            <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
              <VitalBox
                label={"HR"}
                units={"bpm"}
                value={currentVitals.heartRate}
                onClick={ ()=> setOpenVitalType(VitalsType.HEART_RATE)}
              />
              <VitalBox
                label={"HC"}
                units={"cm"}
                value={getHeadCircumferenceValue()}
                onClick={ onHCClick }
              />
            </Box>
            <VitalsModal
              vitalType={openVitalType}
              onClose={() => setOpenVitalType(null)}
              patientName = {patientDisplayName(patient.firstName, patient.lastName)}
            />
          </Box>
        )
      }
      return <NoInformationEntered label={'vitals'} ending={'recorded'}/>
    }

    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={1}
      >
        <PatientDetailsBox>
          <DisplayDateOfBirthAndGender gender={patient.gender?.name} dob={patient.dob}/>
          <DisplayIdentifier primaryIdentifier={patientIdentifier}/>
          <PatientDetailsRow Icon={PractitionerIcon}>
            <Suspense fallback={<LoadingSpinner size={"xs"} label={"Loading practitioner..."} />}>
              <DisplayPractitioner clinicPractitioners={patientRelationships.clinicPractitioners} />
            </Suspense>
          </PatientDetailsRow>
        </PatientDetailsBox>
        <PatientDetailsBox>
          <PatientDetailsRow Icon={HomeOutlinedIcon}>
            <PatientAddressRow variant={'p2'} displayNoneEntered={true} street={patient.street} street2={''} street3={''} city={patient.city} province={patient.province} postalCode={patient.postalCode}/>
          </PatientDetailsRow>
          <PatientDetailsRow Icon={PhoneOutlinedIcon}>
            <DisplayPrimaryPhoneNumber phones={patient.phones} />
          </PatientDetailsRow>
        </PatientDetailsBox>
        <PatientDetailsBox>
          <PatientDetailsRow Icon={MonitorHeartOutlinedIcon}>
            <Suspense fallback={<LoadingSpinner size={"xs"} label={"Loading current vitals..."} />}>
              <DisplayVitals vitals={patientCurrentVitals.vitals} />
            </Suspense>
          </PatientDetailsRow>
        </PatientDetailsBox>
      </Box>
    )
  }

  const PatientLabel = () => {
    return (
      <Section.Column sx={{height: "114px", pt: 0, pr: 1}}>
        <PatientHeader patient={profileData} navigateInMain={navigateInMain} futureAppointments={futureAppointments}/>
        <PatientDetails patient={profileData} />
      </Section.Column>
    )
  }

  return (
    <Section.Container key={patient_id}>
      <PatientSideColumn>
        <ConditionAndProceduresWidget />
        <HistoryWidget />
        <AllergyWidget />
        <TaskWidget />
      </PatientSideColumn>
      <PatientMainColumn>
        <PatientLabel />
        <Box sx={{overflow:'hidden', height:"100%"}}>
          <Outlet />
        </Box>
      </PatientMainColumn>
      <PatientSideColumn>
        <PrescriptionWidget />
        <LabsAndInvestigationsWidget />
        <EncounterNotesWidget />
        <FormWidget />
        <LetterWidget />
        <OtherDocumentsWidget />
      </PatientSideColumn>
    </Section.Container>
  )
}

type PatientSectionColumnType = {
  children?: ReactElement[] | ReactElement
}
const PatientSideColumn = ({children}: PatientSectionColumnType) => {
  return <Section.Column
    width={SIDE_PANEL_WIDTH}
    sx={{ flex:`0 0 ${SIDE_PANEL_WIDTH}` }}
    gap={1}
    preventScroll={true}
  >
    { children }
  </Section.Column>
}

const PatientMainColumn = ({children}: PatientSectionColumnType) => {
  return (
    <Section.Container
      sx={{
        flexDirection: 'column',
        gap: 1,
      }}
    >
      { children }
    </Section.Container>
  )
}

const PatientHeader = ({patient, navigateInMain, futureAppointments}) => {
  const [openPrintModal, setOpenPrintModal] = useState(false)
  const PatientNavigation = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={1}
        alignItems={"center"}
      >
        <Typography variant={'h3'}>{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
        <Box
          display={"flex"}
          flexDirection={"row"}
        >
          <IconButton
            name={"timeline"}
            tooltip={"Timeline"}
            dataTestId={"patient-timeline-button"}
            icon={<TimelineIcon />}
            onClick={() => navigateInMain('')}
          />
          <IconButton
            name={"details"}
            tooltip={"Profile"}
            dataTestId={"patient-details-button"}
            icon={<AccountCircleIcon />}
            onClick={() => navigateInMain('details')}
          />
          <IconButton
            name={"relationships"}
            tooltip={"Relationships"}
            dataTestId={"patient-relationships-button"}
            icon={<GroupsOutlinedIcon />}
            onClick={() => navigateInMain('relationships')}
          />
          <IconButton
            name={"billing"}
            tooltip={"Billing"}
            dataTestId={"patient-billing-button"}
            icon={<ReceiptLongIcon />}
            onClick={() => navigateInMain('billing')}
          />
          <IconButton
            name={"notes"}
            tooltip={"Notes"}
            dataTestId={"patient-notes-button"}
            icon={<DescriptionOutlinedIcon />}
            onClick={() => navigateInMain('notes')}
          />
          <IconButton
            name={"appointments"}
            tooltip={"Appointments"}
            dataTestId={"patient-appointments-button"}
            icon={<ScheduleIcon />}
            onClick={() => navigateInMain('appointments')}
          />
          <IconButton
            name={"print"}
            tooltip={"Print label"}
            dataTestId={"patient-print-button"}
            icon={<PrintOutlinedIcon />}
            onClick={() => setOpenPrintModal(true)}
          />
        </Box>
      </Box>
    )
  }
  
  const NextAppointment = () => {
    const futureAppointment = futureAppointments.length > 0 ? futureAppointments[futureAppointments.length - 1] : null
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Typography variant={'body1'} sx={{color: 'greys.medium', pr: "2px"}}>Next visit:</Typography>
        { futureAppointment ?
            <>
              <Typography variant={'body1'} dataTestId={"patient-next-appointment-date"}>
                {formatNextAppointmentDate(futureAppointment.itemDate)}
              </Typography>
              <IconButton
                dataTestId={'patient-open-next-appointment-button'}
                onClick={OpenAppointmentOnSchedule(futureAppointment)}
                icon={<OpenInNewIcon />}
              />
            </>
          :
          <Typography variant={'body1'} dataTestId={"patient-next-appointment-date"}>
            N/A
          </Typography>
        }
      </Box>
    )
  }
  
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      overflow={"hidden"}
    >
      <PatientNavigation />
      <Suspense fallback={<LoadingSpinner size={"xs"} label={"Loading next appointment..."} />}>
        <NextAppointment />
      </Suspense>
      <PrintPatientModal patientId={patient.id} open={openPrintModal} onModalClose={() => setOpenPrintModal(false)} />
    </Box>
  )
}

const PatientDetailsBox = ({children}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"2px"}
    >
      { children }
    </Box>
  )
}

const PatientDetailsRow = ({children, Icon}) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={"4px"}
    >
      <Icon sx={{width:"16px", height:"16px", color: (theme) => theme.palette.greys.medium}}/>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"2px"}
        pt={"1px"}
      >
        { children }
      </Box>
    </Box>
  )
}

const NoInformationEntered = ({ label, ending = 'entered' }) => {
  return <Typography variant={'p2'} sx={theme => ({ color: theme.palette.greys.medium })}>No {label} {ending}</Typography>
}

export const PatientAddressRow = ({street, street2, street3, city, province, postalCode, variant, displayNoneEntered = false}) => {
  if (!street && !street2 && !street3 && !city && !province && !postalCode) {
    if (displayNoneEntered) {
      return <NoInformationEntered label={'address'} />
    }
    return <></>
  }
  return (
    <>
      <Section.FormSection sx={{mb: 0}}>
        <Typography variant={variant}> {street} </Typography>
        <Typography variant={variant}> {street2} </Typography>
        <Typography variant={variant}> {street3} </Typography>
        <Typography variant={variant}>
          {city && `${city}${province ? ', ' : ''}`}
          {province}
        </Typography>
        <Typography variant={variant}> {postalCodeMask(postalCode)} </Typography>
      </Section.FormSection>
    </>
  )
}