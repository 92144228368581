import React, { useState } from 'react'
import { ConfirmationDialog, Radio, SimpleRadioGroup, DialogV2 } from "saga-library/src";
import { AbClaimAcceptCode } from 'utils/ABClaimConstants';


export const WriteOffConfirmationDialog = ({open, setOpen, onAccept, batchUpdate=false}) => {
  const [writeoff, setWriteoff] = useState(AbClaimAcceptCode.WRONG_DOCTOR_OR_PATIENT)
  const writeoffStyling = {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      lineHeight: '24px',
    }
  }
  return (
    <DialogV2
      open={open}
      title={batchUpdate ? 'Write off claims' : 'Write off claim'}
      onClose={() => setOpen(false)}
      primaryAction={() => onAccept(writeoff)}
      primaryLabel={'Write off'}
    >
      <SimpleRadioGroup
        row={false}
        label={batchUpdate ? 'Select a reason for writing off these claims:' : 'Select a reason for writing off this claim:'}
        name={''}
        value={writeoff}
        onChange={(e) => setWriteoff(e.currentTarget.value)}
      >
        <Radio label={'Wrong practitioner or patient'} value={AbClaimAcceptCode.WRONG_DOCTOR_OR_PATIENT} sx={writeoffStyling}/>
        <Radio label={'Previously paid or included in another province'} value={AbClaimAcceptCode.PREVIOUSLY_PAID_OR_INCLUDED_IN_ANOTHER_SERVICE} sx={writeoffStyling}/>
        <Radio label={'Bill directly to patient or third party'} value={AbClaimAcceptCode.BILLED_TO_PATIENT_OR_THIRD_PARTY} sx={writeoffStyling}/>
        <Radio label={'Billed to alternate BA number'} value={AbClaimAcceptCode.BILLED_TO_ALTERNATE_BA_NUMBER} sx={writeoffStyling}/>
        <Radio label={'Write-off: uncollectable'} value={AbClaimAcceptCode.WRITE_OFF_OR_UNCOLLECTABLE} sx={writeoffStyling}/>
      </SimpleRadioGroup>
    </DialogV2>
  )
}


export const ReassessmentConfirmationDialog = ({open, setOpen, onAccept}) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      title={'Resubmitting a claim for reassessment'}
      message={'When resubmitting a claim for reassessment, only text is updated. All other claim fields that have been modified will not be updated.'}
      primaryAction={() => {
        setOpen(false)
        onAccept()
      }}
      primaryLabel={'Resubmit'}
    />
  )
}


export const DeletionConfirmationDialog = ({open, setOpen, onAccept}) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      title={'Resubmitting a claim for deletion'}
      message={'When resubmitting a claim for deletion, no fields are updated. All claim fields that have been modified will not be  updated.'}
      primaryAction={() => {
        setOpen(false)
        onAccept()
      }}
      primaryLabel={'Resubmit'}
    />
  )
}


export const CancelWriteOffDialog = ({open, setOpen, onAccept}) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      closeLabel={'No'}
      title={'Cancel write off?'}
      message={'You will be able to resubmit this claim to AHCIP again.'}
      primaryAction={() => {
        setOpen(false)
        onAccept()
      }}
      primaryLabel={'Cancel write off'}
    />
  )
}


export const CancelResubmissionDialog = ({open, setOpen, onAccept}) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={() => setOpen(false)}
      closeLabel={'No'}
      title={'Cancel resubmission?'}
      message={'This claim will not be resubmitted to AHCIP and will revert back to its original assessed state, including all fields that have been modified.'}
      primaryAction={() => {
        setOpen(false)
        onAccept()
      }}
      primaryLabel={'Cancel resubmission'}
    />
  )
}
