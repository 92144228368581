import React, {useEffect} from 'react'
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { useNavigate, useParams } from 'react-router-dom'
import { PractitionerDetails } from "./components/PractitionerDetails";
import { useMutation } from '@apollo/client'
import { useAccountContext } from 'providers/AccountContext'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { schema } from './util/validation'
import { LoadingOverlay } from '../../../components/LoadingScreen'
import { PractitionerBillingDetailsList } from './components/BillingProfiles/PractitionerBillingDetailsList'
import PractitionerForm from './components/PractitionerForm'
import { ProfileFormType } from './PractitionerProfile'
import { PractitionerInput } from "../../../types/settings";
import { PractitionerBillingDetailsType } from "./components/BillingProfiles/PractitionerBillingDetails";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { HasPermissionTemplate } from "../../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { PractitionerSchedulesDetails } from "./components/Schedules/PractitionerSchedulesDetails";
import {
  ADD_NEW_PRACTITIONER,
  LIST_PRACTITIONERS
} from "../../../graphql-definitions";
import { Province } from 'saga-library/src'
import PractitionerHeader from "./components/PractitionerHeader";
import { SettingsSectionColumn } from "../components/SettingsSectionColumn";
import _get from "lodash/get";
import { flushSync } from "react-dom";

const FORM_NAME = 'new_practitioner_form'

export const PractitionerNew = () => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { getTenantProvince } = useAccountContext()
  const provinceTenant: Province = getTenantProvince(tenant_id)
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const [addNewPractitioner, { loading }] = useMutation(ADD_NEW_PRACTITIONER, {
    onCompleted: (data) => {
      const practitionerId = _get(data,'tenant.practitioner.create', null)
      if (!!practitionerId) {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        showSuccessAlert(`${getValues('lastName')}, ${getValues('firstName')} has been saved.`)
        navigate(
          buildTenantRoute(
            `settings/practitioners/p/${practitionerId}`,
            tenant_id
          )
        )
      }
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Practitioner couldn\'t be created.')
    },
  })

  const formMethods = useForm<ProfileFormType>({
    defaultValues: {
      ...PractitionerDetails.defaults, province: provinceTenant.code ?? "",
      ...PractitionerBillingDetailsList.defaults,
      ...PractitionerSchedulesDetails.defaults
    },
    schema: schema,
  })
  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    getValues,
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `settings/practitioners/new`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const onSubmit = handleSubmit(async (data) => {
    const {street, billingProfileInputs, schedules, user, signature, ...rest} = data

    const newDoc:PractitionerInput = {
      ...rest,
      street1: street
    }
    newDoc.userId = user?.id
    newDoc.signatureFileId = signature?.id

    newDoc.scheduleLocationIds = schedules?.map(s => s.locationId) || []

    if (billingProfileInputs) {
      newDoc.billingProfileInputs = billingProfileInputs.map(
        (billingProfile) => {
          const{id, skill, abClaimFacility, abClaimFunctionalCenter, ...rest} = billingProfile
          const updatedBillingProfile: PractitionerBillingDetailsType = {
            ...rest,
            skillId: skill?.id!,
            facilityId: abClaimFacility?.id!,
            functionalCenterId: abClaimFunctionalCenter?.id!
          }

          return updatedBillingProfile
        }
      )
    }

    await addNewPractitioner({
      variables: {
        tenantId: tenant_id,
        input: newDoc,
      },
      update(cache, returnedData) {
        try {
          const practitionerId = _get(returnedData, 'data.tenant.practitioner.create')
          const newPractitioner = {
            id: practitionerId,
            ...newDoc,
          }
          cache.updateQuery({
              query: LIST_PRACTITIONERS,
              variables: { tenantId: tenant_id }
            },
            (listData) => {
              let list = [...listData.tenant.practitioner.list, newPractitioner]
              return {
                tenant: {
                  practitioner: {
                    list: list
                  }
                }
              }
            })
        } catch (e) {
          console.error("Error updating cache", e)
        }
      }
    })
  })

  return (
    <SettingsSectionColumn
    header={ <PractitionerHeader
      loading={isSubmitting}
      formName={FORM_NAME}
      onSubmit={onSubmit}
    /> }>
      <HasPermissionTemplate requiredType={PermissionType.Practitioner} requiredPermission={Permission.READWRITE} showAccessDenied={true}>
        <FormProvider {...formMethods}>
          <LoadingOverlay loading={loading} message={'Creating practitioner'} />
          <PractitionerForm
            formName={FORM_NAME}
            onSubmit={onSubmit}
          />
        </FormProvider>
      </HasPermissionTemplate>
    </SettingsSectionColumn>
  )
}
