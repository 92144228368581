import { defaultReportQueryInput } from '../../../../components/ReportQueryBuilder/ReportQueryBuilderFields'

export const reportFormDefaultValues = {
  name: "",
  description: "",
  category: null,
  columns: [],
  parameters: [],
  queries: [defaultReportQueryInput],
  version: "0"
}