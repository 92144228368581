import React from "react";
import Box from "@mui/material/Box";
import { TextField, Form } from "saga-library/src";
import { LocationSelect } from "../../../../components/LocationSelect";
import { PractitionersForLocationSelect } from "../../../../components/PractitionersForLocationSelect";
import { useFormContext, useWatch } from "saga-library/src/components/Form";

interface AppointmentRoomFormProps {
  onSubmit: () => void
}

export const AppointmentRoomForm = ({
  onSubmit
}: AppointmentRoomFormProps) => {
  const { control, setValue } = useFormContext()
  const locationId = useWatch({
    control,
    name: 'locationId'
  })

  return (
      <Form onSubmit={onSubmit}>
        <Box
          display={'flex'}
          flexDirection={'column'}
        >
          <TextField
            dataTestId={'appointmentRoomForm-name'}
            name={'name'}
            label={'Name'}
          />
          <LocationSelect
            label={'Location'}
            name={'locationId'}
            onChange={() => setValue('practitionerId', null)}
          />
          <PractitionersForLocationSelect
            label={'Limit to practitioner (optional)'}
            name={'practitionerId'}
            locationId={locationId}
            disabled={!locationId}
          />
        </Box>
      </Form>
  )
}