import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { useDocumentContext } from "../../../../../providers/DocumentProvider";
import { useAccountContext } from "../../../../../providers/AccountContext";
import {
  FieldTypes,
  PDFField,
  PDFForm,
  PDFUI,
  PDFViewer,
  PDFAnnotType,
  ViewerEvents
} from "../../../../../components/FoxitPDFViewer/FoxitTypes";
import { FoxitPDFViewer } from "../../../../../components/FoxitPDFViewer";
import { usePDFViewerEvent } from "../../../../../components/FoxitPDFViewer/UsePDFViewerEvent";
import { usePatientFormStateContext } from "./FormsStateProvider";
import { FileType } from "../../../../../types/tasks";
import moment from "moment-timezone";
import { useFormContext } from "react-hook-form";
import { useWatch } from "saga-library/src/components/Form";
import { useLazyQuery } from "@apollo/client";
import {
  GET_FILE_DETAILS,
  GET_LOCATION,
  GET_PRACTITIONER_LABEL
} from "../../../../../graphql-definitions";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { formatPractitionerId } from "../../../../../components/PractitionerMaskedTextField";
import { Location } from "../../../../../types";
import { omit } from "lodash";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import { postalCodeMask } from "saga-library/src/components/PostalCodeField/PostalCodeField";
import { FormattedAgeString } from "../../../../../components/FormattedAge";
import { usePatientProfileContext } from "../../../providers/PatientProfileProvider";
import { useTenantContext } from "../../../../../providers/TenantContextProvider";

interface PatientFormViewProps {
  prefillFields?: boolean;
  sx?: SxProps<Theme>;
}

const practitionerLabelDefaults = {
  userId: "",
  signatureFileId: "",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  practitionerId: "",
  connectCareProviderId: "",
  phone: {
    number: "",
  },
}

const locationDetailsDefaults : Location = {
  name: "",
  street1: "",
  street2: "",
  street3: "",
  city: "",
  province: "",
  postalCode: "",
  phoneNumber: "",
  faxNumber: "",
}

export const PatientFormViewer = ({ sx, prefillFields = false }: PatientFormViewProps) => {
  const pdfuiRef = useRef<PDFUI | null>(null);
  const { selectedFile, loadingFile, setLoadingFile, setFileModified, pdfDocRef, pdfViewerRef } = usePatientFormStateContext();
  const { getSharedFileTypeUriWithToken, getFileTypeUriWithToken } = useDocumentContext();
  const { userId, userFirstName, userLastName } = useAccountContext();
  const { patientPrimaryIdentifier } = useTenantContext()
  const { control } = useFormContext();
  const { tenant_id } = useParams();
  const [ practitionerLabel, setPractitionerLabel ] = useState(practitionerLabelDefaults);
  const [ locationDetails, setLocationDetails ] = useState<Location>(locationDetailsDefaults);

  const setFormField = (field, value, widget) => {
    requestAnimationFrame(() => {
      field.setValue(value, widget)
    })
  }

  const { profileData } = usePatientProfileContext()

  const practitionerId = useWatch({
    control,
    name: "practitionerId"
  });

  const locationId = useWatch({
    control,
    name: "locationId"
  });

  const [getFileDetails] = useLazyQuery(GET_FILE_DETAILS, {
    onError: (error) => {
      console.error("Error occurred retrieving signature file: " + error);
    },
    fetchPolicy: "cache-and-network"
  });

  const [practitionerProfile] = useLazyQuery(GET_PRACTITIONER_LABEL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const tempPractitionerLabel = _get(data, 'tenant.practitioner.get', null)
      setPractitionerLabel({
        ...tempPractitionerLabel,
        practitionerId: formatPractitionerId(tempPractitionerLabel.practitionerId)
      })
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const [getLocationDetails ] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setLocationDetails(_get(data, 'tenant.location.get', null))
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const getSignatureUriAndToken = async (file) => {
    return await getFileTypeUriWithToken(file);
  };

  function customFormatName(format: string, firstName?: string, lastName?: string, title?: string) {
    let formattedName = format;
    formattedName = formattedName.replace("firstName", firstName ?? "");
    formattedName = formattedName.replace("lastName", lastName ?? "");
    if (format.includes("title")) {
      if (title === "dr") {
        formattedName = formattedName.replace("title", "Dr.");
      } else {
        formattedName = formattedName.replace("title", "");
      }
    }

    return formattedName;
  }

  function formatStreets(street1?: string | null, street2?: string | null, street3?: string | null) {
    let streets = street1 ?? ""

    if ((street2 ?? "") !== "") {
      streets += streets !== "" ? " " + street2 : street2
    }

    if ((street3 ?? "") !== "") {
      streets += streets !== "" ? " " + street3 : street3
    }

    return streets
  }

  async function autofillFullName(pdfForm: PDFForm, fieldName: string, firstName?: string, lastName?: string, title?: string) {
    if(!pdfForm) return
    const fullNameField = pdfForm.getField(`Autofill:${fieldName}.fullName`) as unknown as PDFField
    if (fullNameField && fullNameField.getControlsCount() > 0) {
      const fieldWidget = await fullNameField.getControlByIndex(0).getWidgetAnnot()
      const fieldFormat = fullNameField.getAlternateName()
      setFormField(fullNameField, customFormatName(fieldFormat, firstName, lastName, title), fieldWidget)
    }
  }

  const fileOpenedCallback = useCallback((pdfViewer: PDFViewer, ...args: any[]) => {
    setFileModified(false);
  }, [setFileModified]);

  const openFileFailedCallback = useCallback((pdfViewer: PDFViewer, ...args: any[]) => {
    setLoadingFile(false);
  }, [setLoadingFile]);

  const renderFileCallback = useCallback((pdfViewer: PDFViewer, ...args: any[]) => {
    if(!pdfViewerRef) return
    pdfViewerRef.current = pdfViewer
  }, [pdfDocRef?.current]);

  usePDFViewerEvent(ViewerEvents.beforeOpenFile, fileOpenedCallback, pdfViewerRef);
  usePDFViewerEvent(ViewerEvents.openFileFailed, openFileFailedCallback, pdfViewerRef);
  usePDFViewerEvent(ViewerEvents.renderPageSuccess, renderFileCallback, pdfViewerRef);

  useEffect(() => {
    if (!selectedFile) return;

    async function getUriAndToken() {
      return await getSharedFileTypeUriWithToken(selectedFile as FileType);
    }

    setLoadingFile(true);

    getUriAndToken()
      .then(async (uri) => {
        if (!uri) return;
        if (!pdfuiRef || !pdfuiRef.current) return;

        pdfuiRef.current?.openPDFByHttpRangeRequest({
          range: {
            url: uri
          }
        }).then((doc) => {
          if (!doc) {
            console.error("error opening pdf", uri);
            return;
          }

          if (pdfDocRef) pdfDocRef.current = doc;

          Promise.all([autofillLocation(doc), autofillPractitioner(doc), autofillPatient(doc), autofillDateAndTimeToday(doc)]).then(async () => {
            await pdfViewerRef?.current?.redraw();
          })
        })
      })
      .catch((error) => {
        console.error("error getting file uri and token", error);
      })
      .finally(() => {
        setLoadingFile(false);
      });
  }, [selectedFile]);

  const autofillDateAndTimeToday = async (doc) => {
    if(!doc) return
    const pdfForm = doc.getPDFForm()
    const numOfFields = pdfForm.getFieldCount()
    if (!numOfFields) return

    for (let i = 0; i < numOfFields; i++) {
      const pdfFormField = pdfForm.getFieldByIndex(i)
      if (pdfFormField && pdfFormField.getControlsCount() > 0) {
        pdfFormField.getControlByIndex(0).getWidgetAnnot().then((fieldWidget) => {
          const fieldName = pdfFormField.getName()
          if (fieldName.startsWith('Autofill:General.todaysDate')) {
            const fieldFormat = pdfFormField.getAlternateName()
            setFormField(pdfFormField, moment().format(fieldFormat), fieldWidget)
          }
          if (fieldName.startsWith('Autofill:General.currentTime')) {
            setFormField(pdfFormField, moment().format("HH:mm"), fieldWidget)
          }
        })
      }
    }
  }

  async function getImageArrayBuffer(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.arrayBuffer();
  }

  function calculateAspectRatioFit(dimensions, rect) {
    const imageAspectRatio = dimensions.width / dimensions.height;
    const rectAspectRatio = (rect.right - rect.left) / (rect.top - rect.bottom);
    let newWidth, newHeight;

    if (imageAspectRatio > rectAspectRatio) {
      // If the aspect ratio of the image is greater than the aspect ratio of the rectangle,
      // set the width of the image to the width of the rectangle and adjust the height of the image
      newWidth = rect.right - rect.left;
      newHeight = newWidth / imageAspectRatio;
    } else {
      // If the aspect ratio of the image is less than the aspect ratio of the rectangle,
      // set the height of the image to the height of the rectangle and adjust the width of the image
      newHeight = rect.top - rect.bottom;
      newWidth = newHeight * imageAspectRatio;
    }

    rect.right = rect.left + newWidth;
    rect.top = rect.bottom + newHeight;
    return rect;
  }

  function getImageSizeFromUrl(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        resolve({
          height: img.height,
          width: img.width
        });
      };
      img.onerror = function() {
        reject(new Error('Could not load image'));
      };
      img.src = url;
    });
  }

  const autofillPractitioner = async (doc) => {
    if(!doc) return
    const pdfForm = doc.getPDFForm()
    const numOfFields = pdfForm.getFieldCount()
    if (!numOfFields || !prefillFields || !practitionerLabel) return

    // Practitioner details will be autofilled only on a new form
    if (prefillFields) {
      await Promise.all((Object.keys(practitionerLabel).map(async (fieldName) => {
        const formField = pdfForm.getField(`Autofill:Practitioner.${fieldName}`) as unknown as PDFField
        if (formField && formField.getControlsCount() > 0) {
          formField.getControlByIndex(0).getWidgetAnnot().then((widget) => {
            setFormField(formField, practitionerLabel[fieldName] ?? "", widget)
          })
        }
      })))

      await autofillFullName(pdfForm, "Practitioner", practitionerLabel.firstName, practitionerLabel.lastName, practitionerLabel.title)

      const phoneField = pdfForm.getField("Autofill:Practitioner.primaryPhoneNumber") as unknown as PDFField
      if (phoneField && phoneField.getControlsCount() > 0) {
        const fieldWidget = await phoneField.getControlByIndex(0).getWidgetAnnot()
        setFormField(phoneField, phoneNumberMask(practitionerLabel?.phone?.number) ?? "", fieldWidget)
      }

      // A practitioner signature will be autofilled on new or editing of forms, only if the userId matches
      const sigField = pdfForm.getField("Autofill:Practitioner.signature") as unknown as PDFField
      if (sigField && sigField.getControlsCount() > 0) {
        for (let i = 0; i < sigField.getControlsCount(); i++) {
          sigField.getControlByIndex(i).getWidgetAnnot().then(async (widget) => {
            const page = widget.getPage()
            if (practitionerLabel.userId === userId && practitionerLabel.signatureFileId) {
              const { data: signatureData } = await getFileDetails({
                variables: {
                  fileId: practitionerLabel.signatureFileId,
                  tenantId: tenant_id,
                },
              })
              const signature = _get(signatureData, 'tenant.file.get', null)
              let fullURI = ''
              await getSignatureUriAndToken(signature)
                .then((fileDetails) => {
                  if (fileDetails) {
                    fullURI = fileDetails
                  }
                })
              if (fullURI) {
                let rect = widget.getRect()
                const image = await getImageArrayBuffer(fullURI) // kept to trigger and maintain the aspect ratio of the image
                const dimensions = await getImageSizeFromUrl(fullURI) as unknown as {
                  width: number,
                  height: number
                }
                rect = calculateAspectRatioFit(dimensions, rect)
                const tempSignatureStamp = {
                  type: PDFAnnotType.stamp,
                  rect: rect,
                  iconInfo: {
                    annotType: PDFAnnotType.stamp,
                    category: "signature",
                    name: "signature",
                    fileType: "png",
                    url: fullURI
                  }
                }
                await page.addAnnot(tempSignatureStamp);
              }
            } else {
              page.getAnnots()
                .then((annotations: any[]) => {
                  annotations.forEach(async (annotation) => {
                    if (annotation.getType() === PDFAnnotType.stamp && annotation.getTitle() === practitionerDisplayName(userFirstName, userLastName)) {
                      await page.removeAnnotByObjectNumber(annotation.getObjectNumber())
                    }
                  })
                })
            }
          })
        }
      }
    }
  }

  const autofillLocation = async (doc) => {
    if(!doc) return
    const pdfForm = doc.getPDFForm()
    const numOfFields = pdfForm.getFieldCount()
    if (!numOfFields || !prefillFields || !locationDetails) return

    const locationFields = [...Object.keys(omit(locationDetails, ['street1', 'street2', 'street3'])), 'addressLines']

    await Promise.all(locationFields.map(async (fieldName) => {
      const formField = pdfForm.getField(`Autofill:Location.${fieldName}`) as unknown as PDFField
      if (formField && formField.getControlsCount() > 0) {
        formField.getControlByIndex(0).getWidgetAnnot().then(async (fieldWidget) => {
          // Intercept the addressLines field and combine address lines 1,2,3
          if(fieldName === 'addressLines') {
            let locationAddress = formatStreets(locationDetails.street1, locationDetails.street2, locationDetails.street3)
            setFormField(formField, locationAddress, fieldWidget)
          } else if (fieldName === 'postalCode') {
            setFormField(formField, postalCodeMask(locationDetails[fieldName]) ?? "", fieldWidget)
          } else if (fieldName === 'phoneNumber' || fieldName === 'faxNumber') {
            setFormField(formField, phoneNumberMask(locationDetails[fieldName]) ?? "", fieldWidget)
          } else {
            setFormField(formField, locationDetails[fieldName] ?? "", fieldWidget)
          }
        })
      }
    }))
  }

  const autofillPatient = async (doc) => {
    if(!doc) return
    const pdfForm = doc.getPDFForm()
    const numOfFields = pdfForm.getFieldCount()
    if (!numOfFields || !prefillFields || !profileData) return

    let patientFields = Object.keys(omit(profileData, ["__typename",'id','version','phones','street', 'street2', 'street3', 'genderId']))
    patientFields = [...patientFields, "age", "genderMale", "genderFemale", "genderNon-binary", "genderOther", "genderUnknown", "genderX", "identifier", "primaryPhoneNumber", "addressLines"]

    await Promise.all(patientFields.map(async (fieldName) => {
      const formField = pdfForm.getField(`Autofill:Patient.${fieldName}`) as unknown as PDFField
      if (formField && formField.getControlsCount() > 0) {
        const fieldWidget = await formField.getControlByIndex(0).getWidgetAnnot()

        if (formField.getType() === FieldTypes.CheckBox) {
          if (`Autofill:Patient.gender${profileData?.gender?.name}` === `Autofill:Patient.${fieldName}`) {
            setFormField(formField, "Yes", fieldWidget)
          }
        }
        else if(formField.getType() === FieldTypes.RadioButton) {
          if (`Autofill:Patient.gender${profileData?.gender?.name}` === `Autofill:Patient.${fieldName}`) {
            setFormField(formField, "Yes", fieldWidget)
          }
        }
        else
        {
          // Intercept the street field and combine address lines 1,2,3
          if(fieldName === 'addressLines') {
            let patientAddress = formatStreets(profileData.street, profileData.street2, profileData.street3)
            setFormField(formField, patientAddress, fieldWidget)
            return
          }

          // Fill in gender as a text field
          if (fieldName === 'gender') {
            setFormField(formField, profileData.gender.name, fieldWidget)
            return
          }

          // Set all other text fields
          const fieldValue = profileData[fieldName]
          if (fieldValue) {
            if (fieldName === 'postalCode') {
              setFormField(formField, postalCodeMask(fieldValue), fieldWidget)
            } else {
              setFormField(formField, fieldValue, fieldWidget)
            }
          }

          if (fieldName === 'age') {
            setFormField(formField, FormattedAgeString(moment(profileData.dob).format()), fieldWidget)
          }

          if(fieldName === 'identifier') {
            const primaryIdentifier = patientPrimaryIdentifier(profileData)?.formatted
            if (primaryIdentifier) {
              setFormField(formField, primaryIdentifier, fieldWidget)
            }
          }

          if(fieldName === 'primaryPhoneNumber') {
            const primaryPhone = profileData.phones.find((phone) => phone.isPrimary)
            if (primaryPhone) {
              setFormField(formField, phoneNumberMask(primaryPhone.number), fieldWidget)
            }
          }
        }
      }
    }))

    await autofillFullName(pdfForm, "Patient", profileData.firstName, profileData.lastName)
  }

  useEffect(() => {
    if (practitionerId != null && practitionerId !== "") {
      practitionerProfile({ variables: { tenantId: tenant_id, practitionerId: practitionerId } })
    } else {
      setPractitionerLabel(practitionerLabelDefaults)
    }
  }, [practitionerId])

  useEffect(() => {
    if (locationId !== null && locationId !== "") {
      getLocationDetails({ variables: { tenantId: tenant_id, locationId: locationId } })
    } else {
      setLocationDetails(locationDetailsDefaults)
    }
  }, [getLocationDetails, locationId, tenant_id])

  useEffect(() => {
    if(!pdfViewerRef) return
    const doc = pdfViewerRef?.current?.getCurrentPDFDoc()
    autofillLocation(doc)
      .then(async () => {
        await pdfViewerRef?.current?.redraw();
      })
      .catch((error) => {
        console.error(JSON.stringify(error, null, 2))
      })
  }, [locationDetails]);


  useEffect(() => {
    if (!pdfViewerRef) return
    const doc = pdfViewerRef?.current?.getCurrentPDFDoc()
    autofillPractitioner(doc)
      .then(async () => {
        await pdfViewerRef?.current?.redraw();
      })
      .catch((error) => {
        console.error(JSON.stringify(error, null, 2))
      })
  }, [practitionerLabel]);

  useEffect(() => {
    if (!pdfViewerRef) return
    const doc = pdfViewerRef?.current?.getCurrentPDFDoc()
    autofillPatient(doc)
      .then(async () => {
        await pdfViewerRef?.current?.redraw();
      })
      .catch((error) => {
        console.error(JSON.stringify(error, null, 2))
      })
  }, [profileData])

  return (
    <Box
      sx={{p:0, pr:0, mr:0, ...sx
      }}
    >
      <FoxitPDFViewer
        pdfuiRef={pdfuiRef}
        pdfViewerRef={pdfViewerRef}
        loading={loadingFile}
        selectedFile={selectedFile as FileType}
        variant={"forms"}
      />
    </Box>
  );
};