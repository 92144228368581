import React, { useEffect } from "react";
import { TemplateForm, defaultSingleDay, defaultFullWeek } from "./TemplateForm";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { TemplateInput } from "../../../types/schedule";
import _get from "lodash/get";
import { schema } from "../util/templateValidation";
import { useAccountContext } from "../../../providers/AccountContext";
import { LIST_TEMPLATES, CREATE_NEW_TEMPLATE } from "../../../graphql-definitions";
import { ScheduleLength } from "../../../types/schedule/Schedule";
import { cleanTemplateDayForInput } from "../util/templateFunctions";
import { Form } from 'saga-library/src'
import { usePrompt } from "../../../providers/NavigationPrompt";
import { flushSync } from "react-dom";

let newTemplateDefaults = {
  name: '',
  version: "0",
  isDeleted: false,
  templateDays: [defaultSingleDay(0)],
}

const FORM_NAME = 'new_schedule_template_form'

export const NewTemplate = () => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const location = useLocation()
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const queryParams = new URLSearchParams(location.search)

  const [createNewTemplate, { loading, error }]  =
    useMutation(CREATE_NEW_TEMPLATE, {
      onCompleted: (data) => {
        flushSync(() => {
          clearNavigationPrompt(FORM_NAME)
        })
        const createdTemplateId = _get(data, 'tenant.schedule.createTemplate', null)
        showSuccessAlert('Template has been saved.')
        navigate(buildTenantRoute(`schedule/templates/t/${createdTemplateId}`, tenant_id))
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    })

  if (location.search && queryParams.get("length") === ScheduleLength.WEEK) {
    newTemplateDefaults.templateDays = defaultFullWeek
  }

  const formMethods = useForm<TemplateInput>({
    defaultValues: newTemplateDefaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: {isSubmitSuccessful, dirtyFields},
    reset,
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true, keepDefaultValues: true })
    }
  }, [isSubmitSuccessful])

  const onSubmit = handleSubmit(async (data, event) => {
    if (event && event.target.name !== FORM_NAME) {
      return
    }

    data.isDeleted = false
    data.templateDays.forEach(templateDay => {
      cleanTemplateDayForInput(templateDay)
    })

    await createNewTemplate({
      variables: {
        tenantId: tenant_id,
        templateInput: data
      },
      update(cache, returnedData) {
        const templateId = _get(returnedData, 'data.tenant.schedule.createTemplate')
        const newTemplate =
          {
            id: templateId,
            ...data
          }
          cache.updateQuery({
            query: LIST_TEMPLATES,
            variables: { tenantId: tenant_id },
          },
            (data) => {
              let list = [...data.tenant.schedule.listTemplates, newTemplate]
              return {
                tenant: {
                  schedule: {
                    listTemplates: list
                  }
                }
              }
            }
          )
      }
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Form
        id={FORM_NAME}
        name={FORM_NAME}
        style={{
          height: '100%'
        }}
        onSubmit={onSubmit}
      >
        <TemplateForm
          formName={FORM_NAME}
          title={'New template'}
          showDelete={false}
        />
      </Form>
    </FormProvider>
  )
}