import React from 'react'
import { gql, useApolloClient, useLoadableQuery } from '@apollo/client'
import {
  GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
} from '../../../graphql-definitions'
import { ReviewLab } from "types/inbox/ReviewLab";
import _get from 'lodash/get'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../providers/AccountContext'

const getPractitionerLabQueryResults = (data) => {
  return [..._get(data, 'tenant.review.lab.list', []) as Array<ReviewLab>]
}

const parsePractitionerLabQueryResults = (data) => {
  return getPractitionerLabQueryResults(data)
}

interface ReviewLabsContextInterface {
  practitionerLabs: any,
  loadPractitionerLabs: any,
  refetchPractitionerLabs: any,
  parsePractitionerLabQueryResults: (any) => ReviewLab[],
  selectNextRow: (currentLabId: string, userId: string | undefined) => void,
}

const defaultReviewLabsContext: ReviewLabsContextInterface = {
  practitionerLabs: [],
  loadPractitionerLabs: () => {},
  refetchPractitionerLabs: () => {},
  parsePractitionerLabQueryResults: parsePractitionerLabQueryResults,
  selectNextRow: (currentLabId: string, userId: string | undefined) => {},
}

const ReviewLabsContext = React.createContext(defaultReviewLabsContext)

export const ReviewLabsProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const client = useApolloClient()

  const [loadPractitionerLabs, practitionerLabs,  { refetch: refetchPractitionerLabs, }] = useLoadableQuery(GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
    {fetchPolicy: 'cache-and-network'}
  )

  const selectNextRow = (currentLabId:  string, userId: string | undefined) => {
    if(!currentLabId || !userId) return
    const labsResult = client.cache.readQuery({
      query: GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
      variables: {
        tenantId: tenant_id,
        userId: userId,
      },
    })

    const labs = getPractitionerLabQueryResults(labsResult)

    if(!labs) {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
      return
    }

    const currentLabIndex = labs.findIndex((lab: ReviewLab) => lab.id === currentLabId)
    if(!labs[currentLabIndex + 1]) {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
      return
    }

    const nextLab =  labs[currentLabIndex + 1]
    const filteredLabs = labs.filter((lab: ReviewLab) => !lab.isReviewed)

    if(filteredLabs.includes(nextLab))
    {
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/l/${nextLab.id}`, tenant_id))
      return
    }

    if(filteredLabs.length === 1) {
      const firstLab = filteredLabs[0]
      navigate(buildTenantRoute(`inbox/u/${userId}/labs/l/${firstLab.id}`, tenant_id))
      return
    }

    navigate(buildTenantRoute(`inbox/u/${userId}/labs/`, tenant_id))
    return
  }

  const providerValues = {
    practitionerLabs,
    loadPractitionerLabs,
    refetchPractitionerLabs,
    parsePractitionerLabQueryResults,
    selectNextRow,
  }

  return (
    <ReviewLabsContext.Provider value={providerValues}>
      { children }
    </ReviewLabsContext.Provider>
  )
}

export const useReviewLabsContext = () => {
  return React.useContext(ReviewLabsContext)
}