import { Controller, useFormContext } from 'saga-library/src/components/Form'
import React, { useEffect, useState } from 'react'
import { Autocomplete } from 'saga-library/src'
import { Box, SxProps, Theme } from '@mui/material'
import { useQuery } from '@apollo/client'
import { LIST_TASK_TYPES } from '../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import { sortTaskTypeList } from '../apps/settings/Tasks/Types/TaskTypes'
import { TaskType } from '../types/tasks/TaskType'

interface TaskTypeSelectProps {
  label: string,
  name: string,
  disabled?: boolean,
  onChange?: (value) => void,
  dataTestId: string,
  sx?: SxProps<Theme>
}

export const TaskTypeSelect = ({
  label = 'Type',
  name,
  disabled = false,
  onChange,
  dataTestId,
  sx
}: TaskTypeSelectProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const { control } = useFormContext()
  const [ taskTypes, setTaskTypes ] = useState<TaskType[]>([])

  const { loading, data } = useQuery(LIST_TASK_TYPES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve task types.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    const list = _get(data, "tenant.task.type.list", [])
    setTaskTypes(sortTaskTypeList(list))
  }, [data])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: controlledOnChange, value = null }, fieldState: { error } }) => {
        if (value === '') {
          value = null
        }
        return (
          <Autocomplete
            name={name}
            label={label}
            options={taskTypes}
            loading={loading}
            onChange={(e, newValue) => {
              controlledOnChange(newValue?.id ?? null)
              if (onChange) {
                onChange(newValue?.id ?? null)
              }
            }}
            getOptionLabel={(option) => {
              if(typeof option === 'string') {
                return taskTypes.find(t => t.id === option)?.type || ""
              }
              return option?.type || ""
            }}
            OptionComponent={TypeOption}
            isOptionEqualToValue={(option, value) => option?.id === value}
            value={value}
            sx={{ flex: '1 1 100%', ...sx }}
            disabled={disabled}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                controlledOnChange('')
                if (onChange) {
                  onChange('')
                }
              }
            }}
            error={error}
            helperText={error?.message}
            dataTestId={dataTestId}
          />
        )
      }}
    />
  )
}

const TypeOption = ({props, data}) => {
  return(
    <Box
      component={"li"}
      sx={{
        display:!data.active ? "none !important" : "",
      }}
      {...props}
      key={data.id}
      data-testid={`state-option-${data.id}`}
    >
      {data.type}
    </Box>
  )
}