import React from "react";
import Box from "@mui/material/Box";
import { TextField, Section, Typography, Form } from 'saga-library/src';
import { AppointmentDatePicker } from "../../../../components/AppointmentDatePicker";
import {
  ControlledAppointmentTypeMultiSelect
} from "../../../../components/AppointmentTypeSelect";

interface BookingPreferenceFormProps {
  onSubmit: () => void
  limitToScheduleId?: string
  dataTestId?: string
  formName: string
}

export const BookingPreferenceForm = ({
  onSubmit,
  limitToScheduleId,
  dataTestId,
  formName
}: BookingPreferenceFormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      autoComplete={'false'}
      name={formName}
      id={formName}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
        <Section.FormSection sx={{ maxWidth: '50%', mt: 2, mb: 0, gap: '4px' }}>
          <TextField
            dataTestId={`${dataTestId}-title`}
            name={'title'}
            label={'Title (optional)'}
          />

          <AppointmentDatePicker dataTestId={dataTestId} />

          <Typography variant={'body1'} fontWeight={'bold'}>Preferred appointment types for this booking preference (optional)</Typography>
          <ControlledAppointmentTypeMultiSelect
            dataTestId={`${dataTestId}-appointmentType`}
            name={'appointmentTypes'}
            limitToScheduleId={limitToScheduleId}
          />
        </Section.FormSection>
      </Box>
    </Form>
  )

}