import React, { useEffect } from "react";
import { TemplateData } from "../TemplateForm";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { schema } from "../../util/bookingPreferenceValidation";
import moment from "moment-timezone";
import { EventInput } from "../../../../types/schedule";
import { TemplateMbscCalendarEvent } from "../../../../types/schedule/Template";
import { EventForm } from "../../components/Event/EventForm";
import { diffDates } from "saga-library/src/util";

const FORM_NAME = "new_template_event_form"

const eventTemplateDefaults = (eventData) => {
  return {
    version: "0",
    start: moment(eventData?.start),
    end: moment(eventData?.end),
    title: '',
    allDay: eventData?.allDay ?? false,
  }
}

interface NewTemplateEventProps {
  onClose: () => void
  onFormChange: (formName: string, submitting: boolean, dirtyFields: any) => void
  onEventCreate: (event: TemplateMbscCalendarEvent) => void
  templateData: TemplateData | null
}

export const NewTemplateEvent = ({
  templateData,
  onClose,
  onFormChange,
  onEventCreate
}: NewTemplateEventProps) => {
  const formMethods = useForm<EventInput>({
    defaultValues: eventTemplateDefaults(templateData?.eventData),
    schema: schema
  })

  const {
    handleSubmit,
    formState: {  dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    onFormChange(FORM_NAME, isSubmitting, dirtyFields)
  }, [isSubmitting, Object.keys(dirtyFields).length])

  const onSubmit = handleSubmit(async (data) => {
    const startOfDay = moment(data.start).startOf('day')
    const start = moment.duration(diffDates(startOfDay, moment(data.start))).toISOString()
    const length = diffDates(data.start, moment(data.end), 'minutes')

    let newEventInput = {
      ...data,
      start: start,
      length: length,
      day: templateData?.day ?? 0,
      localId: crypto.randomUUID()
    }
    await onEventCreate(newEventInput)
    reset()
    onClose()
  })

  return (
    <FormProvider {...formMethods}>
      <EventForm
        formName={FORM_NAME}
        onSubmit={onSubmit}
        dataTestId={"newTemplateEvent"}
      />
    </FormProvider>
  )
}