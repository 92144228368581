import React from 'react'
import { NavigationButton } from "saga-library/src";
import SettingsHeader from "./components/SettingsHeader";
import { useNavigate } from "react-router-dom";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";

export const Schedule = () => {
  const navigate = useNavigate()

  return (
    <SettingsSectionColumn header={
      <SettingsHeader title={'Schedule'} dataTestId={'schedule'} />
    }>
      <NavigationButton to={'types'} navigate={navigate}> Appointment types </NavigationButton>
      <NavigationButton to={'states'} navigate={navigate}> Appointment states </NavigationButton>
      <NavigationButton to={'rooms'} navigate={navigate}> Appointment rooms </NavigationButton>
    </SettingsSectionColumn>
  )
}

