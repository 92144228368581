import React from 'react'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { Button } from 'saga-library/src'
import { useNavigate } from 'react-router-dom'

export const InvitationAccepted = () => {
  const navigate = useNavigate()

  const navigateToDashboard = () => {
    navigate('/')
  }

  return (
    <div>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        m={3}
      >
        <Typography>Invitation Accepted!</Typography>
      </Box>
      <Button name={'okButton'} onClick={navigateToDashboard}>
        Ok
      </Button>
    </div>
  )
}
