import { DocumentCategory } from "../../../types/Document";
import { moveDocumentToLetter } from "../providers/LetterProvider";
import { moveDocumentToOtherDocument } from "../providers/PatientDocumentProvider";
import { moveDocumentToForm } from "../providers/PatientFormProvider";
import { moveDocumentToLabAndInvestigation } from '../providers/LabAndInvestigationProvider'
import { moveDocumentToEncounterNote } from '../providers/EncounterNoteProvider'

export const updateCacheByCategory = async (cache, document, tenantId, patientId) => {
  switch (document.category) {
    case DocumentCategory.OTHER_DOCUMENT:
      await moveDocumentToOtherDocument(cache, document, tenantId, patientId)
      break
    case DocumentCategory.LETTER:
      await moveDocumentToLetter(cache,document, tenantId, patientId)
      break
    case DocumentCategory.FORM:
      await moveDocumentToForm(cache, document, tenantId, patientId)
      break
    case DocumentCategory.LAB_RESULT:
    case DocumentCategory.INVESTIGATION:
      await moveDocumentToLabAndInvestigation(cache, document, tenantId, patientId)
      break
    case DocumentCategory.CHART_NOTE:
    default:
      await moveDocumentToEncounterNote(cache, document, tenantId, patientId)
  }
}