import React, { useEffect } from "react";
import { AbClaimDiagnosticCode } from "../../../../types/billing"
import { AbClaimServiceCode } from "../../../../types/billing"
import { ServiceCodeSearch } from "../../../../components/SearchControls/Claims/ServiceCodeSearch";
import { DiagnosticCodeSearch } from "../../../../components/SearchControls/Claims/DiagnosticCodeSearch";
import { Box } from "@mui/material";
import { Section, useForm, Form } from "saga-library/src";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { FormProvider } from "react-hook-form";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { usePatientProfileContext } from "../../providers/PatientProfileProvider";
import { usePrompt } from "../../../../providers/NavigationPrompt";

export type PatientBillingType = {
  serviceCode?: AbClaimServiceCode | null
  serviceCodeId?: string | null
  diagnosticCodes?: Array<AbClaimDiagnosticCode> | null
  diagnosticCodeIds?: Array<string | null | undefined> | null
  version: string
}

export const PatientBillingDefaults = {
  serviceCode: null,
  diagnosticCodes: [],
}

const FormName = "patient-billng-form"
const SectionTitle = "Billing"


export const PatientBilling = ChartPanelHOC(SectionTitle, (props) => {
  const { profileData } = usePatientProfileContext()
  const simplifiedData = {
    serviceCode: profileData.serviceCode,
    diagnosticCodes: profileData.diagnosticCodes,
    version: profileData.version
  }
  return profileData && <PatientBillingPanel profileData={simplifiedData} {...props} />
})


const PatientBillingPanel = ({ profileData, ...props }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { updatePatientBilling } = usePatientProfileContext()

  const formMethods = useForm<PatientBillingType>({
    values: profileData,
  })

  const {
    handleSubmit,
    setFocus,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    setFocus('serviceCode')
  }, [setFocus]);

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FormName)
    return () => enableNavigationPrompt(false, FormName)
  }, [Object.keys(dirtyFields).length])

  const onSubmit = handleSubmit( async(data, event) => {
    if (event && event.target.name !== FormName) {
      return
    }
    await updatePatientBilling(data)
  })

  return (
    <FormProvider {...formMethods}>
      <Form
        autoComplete={'false'}
        name={FormName}
        id={FormName}
        onSubmit={onSubmit}
        style={{
          height:'100%',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}
      >
        <Section.Column
          sx={{ flex: "1 1 100%" }}
          header={
            <PatientSectionHeader
              dataTestId={'billing-defaults'}
              sectionLabel={'Billing defaults'}
              submitting={isSubmitting}
              formName={FormName}
            />
          }
        >
          <Box width={360}>
            <ServiceCodeSearch
              name={'serviceCode'}
              label={'Service code'}
              dataTestId={'serviceCode-search'}
            />
            <DiagnosticCodeSearch
              name={'diagnosticCodes'}
              label={'Diagnostic codes'}
              dataTestId={'diagnosticCodes-search'}
            />
          </Box>
        </Section.Column>
      </Form>
    </FormProvider>
  )
}


