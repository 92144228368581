import React from "react";
import { Box } from "@mui/material";
import { Address } from "saga-client/src/components/Address";
import { Section, TextField, TextFieldMask } from "saga-library/src";
import { faxNumberMask, phoneNumberMask } from "./util/mask";
import { PermissionForm } from "../../../components/PermissionForm";
import { PermissionType } from "../../../types/settings/Permission";
import { rules } from "../Practitioners/util/validation";

export const LocationForm = ({
  onSubmit,
  formName,
  dataTestId
}) => {
  return (
    <Box sx={{ width: '60%' }}>
      <PermissionForm
        onSubmit={onSubmit}
        name={formName}
        id={formName}
        requiredPermissionType={PermissionType.Admin}
        readOnlyOverride={true}
      >
        <Section.Container>
          <Section.ColumnNoElevation>
            <Section.FormSection>
              <Section.Header>Address and contact info</Section.Header>
                <TextField
                  name={'name'}
                  label={'Name'}
                  dataTestId={`${dataTestId}-locationForm-name`}
                />
                <Address
                  showCountry={false}
                  dataTestId={`${dataTestId}-locationForm-address`}
                />
                <TextFieldMask
                  name={'phoneNumber'}
                  label={'Phone number'}
                  formatMask={phoneNumberMask}
                  maskChar={''}
                  dataTestId={`${dataTestId}-locationForm-phoneNumber`}
                />
                <TextFieldMask
                  name={'faxNumber'}
                  label={'Fax number'}
                  formatMask={faxNumberMask}
                  maskChar={''}
                  dataTestId={`${dataTestId}-locationForm-faxNumber`}
                />
            </Section.FormSection>
          </Section.ColumnNoElevation>
          <Section.ColumnNoElevation>
            <Section.FormSection>
              <Section.Header>Connect Care identifiers</Section.Header>
              <TextField
                label={'Connect Care submitter ID'}
                name={'connectCareSubmitterId'}
                inputProps={{ maxLength: rules.connectCareSubmitterId.max }}
                dataTestId={`${dataTestId}-locationForm-connectCareSubmitterId`}
              />
              <TextField
                label={'Connect Care department ID'}
                name={'connectCareDepartmentId'}
                inputProps={{ maxLength: rules.connectCareDepartmentId.max }}
                dataTestId={`${dataTestId}-locationForm-connectCareDepartmentId`}
              />
            </Section.FormSection>
          </Section.ColumnNoElevation>
        </Section.Container>
      </PermissionForm>
    </Box>
  )
}