import React from "react";
import { Box } from "@mui/material";
import { TableListRowConfig, Typography, SimpleTooltip, TableListCellConfig, IconButton } from "saga-library/src";
import { practitionerDisplayName, toHoursAndMinutes } from "saga-library/src/util/formatting";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ColorBox } from "../../../components/AppointmentStateSelect";
import { useAccountContext } from "../../../providers/AccountContext";
import moment from 'moment-timezone'
import { DATE_FORMAT } from "../../../utils/SettingsConstants";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PRACTITIONER_PROFILE } from "../../../graphql-definitions";
import { useAlerts } from "saga-library/src/providers/Alerts";
import _get from "lodash/get";

function FormatRowData(appointment, getUserSetting, isHistory = false): TableListCellConfig[] {
  const appointmentDate = moment(appointment.itemDate).format(getUserSetting(DATE_FORMAT) as string)
  const startTime = moment(appointment.start).format(' h:mm A')
  const lastUpdated = { date: moment(appointment.audit.luPit).format(getUserSetting(DATE_FORMAT) as string),
                        time :  moment(appointment.audit.luPit).format('h:mm A')}
  const isDeleted = appointment.isDeleted
  const widths = {xsmall: '40px', small: '55px', medium: '74px', large: '84px', xlarge: '122px', xxlarge:'170px'}

  let practitionerDisplay: string
  if (appointment.practitioner === undefined) {
    practitionerDisplay = practitionerDisplayName(appointment.practitionerFirstName, appointment.practitionerLastName)
  } else {
    practitionerDisplay = practitionerDisplayName(appointment.practitioner.firstName, appointment.practitioner.lastName)
  }
  const lastUpdatedDisplay = practitionerDisplayName(appointment.audit.luUserFirstName, appointment.audit.luUserLastName)

  return [
    {
      children: (isDeleted || isHistory )
        ? <Box height={widths.xsmall} width={widths.xsmall}/>
        : <OpenAppointmentButton appointment={appointment} />,
      align: 'center'
    },
    {
      children: <Typography variant={'body2'} sx={{ minWidth: widths.medium }}>{appointmentDate}</Typography>
    },
    {
      children: <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ minWidth: widths.small }}>
        <Typography variant={'body2'} >{startTime}</Typography>
        <Typography variant={'p3'} sx={theme => ({ color: theme.palette.greys.light  })}>{toHoursAndMinutes(appointment.length)}</Typography>
      </Box>
    },
    {
      children:
        <Box key={'name-' + appointment.id} display={'flex'} flexDirection={'column'} justifyContent={'center'} sx={{ minWidth: widths.xlarge }}>
          <SimpleTooltip title={practitionerDisplay} >
            <Typography variant={'body2'} lineclamp={1} >{practitionerDisplay}</Typography>
          </SimpleTooltip>
          <Typography variant={'p3'} lineclamp={1} sx={theme => ({ color: theme.palette.greys.light })}>{appointment.location.name}</Typography>
        </Box>
    },
    {
      children:
        <Box key={'appointmentType-' + appointment.id} display={'flex'} sx={{ alignItems: 'center', minWidth: widths.medium }} gap={1}>
          <SimpleTooltip title={appointment.type?.name} >
            <Typography variant={'body2'} lineclamp={1} >{appointment.type?.name}</Typography>
          </SimpleTooltip>
        </Box>
    },
    {
      children:
        <Box display={'flex'} sx={{ minWidth: widths.xlarge}} alignItems="center">
          {isDeleted ? <Typography variant={'body2'}>Deleted</Typography> :
            <>
              <ColorBox color={appointment.state.color} />
              <SimpleTooltip title={appointment.state.name} >
                <Typography variant={'body2'} lineclamp={1} >{appointment.state.name}</Typography>
              </SimpleTooltip>
            </>
          }
        </Box>
    },
    {
      children:
        <SimpleTooltip title={appointment.reasonForVisit} >
          <Typography variant={'body2'} lineclamp={1} sx={{ minWidth: widths.xlarge }}>{appointment.reasonForVisit}</Typography>
        </SimpleTooltip>
    },
    {
      children:
        <SimpleTooltip title={appointment.notes} >
          <Typography variant={'body2'} lineclamp={1} sx={{ minWidth: widths.xxlarge }}>{appointment.notes}</Typography>
        </SimpleTooltip>
    },
    {
      children:
        <Box display={'flex'} sx={{ minWidth: widths.xlarge }} justifyContent={'space-between'}>
          <Typography variant={'body2'} align={'left'} sx={{mr: 0.5}}>{lastUpdated.date}</Typography>
          <Typography variant={'body2'} align={'right'}>{lastUpdated.time}</Typography>
        </Box>
    },
    {
      children:
        <SimpleTooltip title={lastUpdatedDisplay} >
          <Typography variant={'body2'} lineclamp={1} sx={{ minWidth: widths.medium }}>{lastUpdatedDisplay}</Typography>
        </SimpleTooltip>
    }
  ];
}

export const OpenAppointmentOnSchedule = (appointment, onComplete?) => {
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { showWarningAlert, showErrorAlert } = useAlerts()

  const [ verifyPractitionerLocation ] = useLazyQuery(GET_PRACTITIONER_PROFILE, {
    variables: { practitionerId: appointment.practitioner.id, tenantId: tenant_id },
    onError: error => {
      showErrorAlert('Practitioner couldn\'t be retrieved.')
      if (onComplete) {
        onComplete()
      }
    },
    onCompleted: data => {
      const practitionerSchedules = _get( data, "tenant.practitioner.get.schedules",[])
      if (practitionerSchedules.length > 0) {
        if (practitionerSchedules.some((sc) => sc.locationId === appointment.location.id)) {
          window.open(buildTenantRoute(`schedule/?aId=${appointment.id}`, tenant_id))
        } else {
          showWarningAlert('This practitioner does not have this schedule location active')
        }
      } else {
        showWarningAlert('This practitioner does not have any active schedules')
      }
      if (onComplete) {
        onComplete()
      }
    },
  })

  return () => {
    verifyPractitionerLocation()
  }
}

export const OpenAppointmentButton = ({appointment}) => {
  return (
    <IconButton
      aria-label="open-appointment"
      onClick={ OpenAppointmentOnSchedule(appointment) }
      icon={<OpenInNewIcon sx={{verticalAlign: 'middle'}}/>}
      dataTestId={"openAppointment-button"}
    />
  )
}

export const PatientAppointmentRow = (appointment, getUserSetting, showErrorAlert, getHistoryQuery, loading): TableListRowConfig => {
  const hasHistory = !moment(appointment.audit.luPit).isSame(moment(appointment.audit.crePit))

  async function getHistoryTable() {
    const result = await getHistoryQuery({ variables: { id: appointment.id }})
    if (result.error) {
      showErrorAlert("Appointment history couldn't be loaded.")
      return []
    }
    const historyTable: any[] = []
    const appointmentHistory = _get( result.data, "tenant.schedule.appointment.appointmentHistory",[])
    appointmentHistory.forEach((appointmentH) => {
      historyTable.push(FormatRowData(appointmentH, getUserSetting, true));
    });
    return historyTable
  }

  return ({
    rowData: FormatRowData(appointment, getUserSetting),
    hasExpandableData: hasHistory,
    getExpandedTable: getHistoryTable,
    loadingExpansion: loading,
    key: appointment.id
  })
}