import React from "react";
import { Box } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Typography } from "saga-library/src";
import { TimelineEventType } from "../../../../../types/patients/Timeline";
import TextSeparator from "./TextSeparator";

const TimelineEventDetails = ({ event }: { event: TimelineEventType }) => {
  return (
    <Typography
      variant={"p2"}
      color={(theme) => event.abnormal ? "inherit" : theme.palette.greys.medium}
      sx={{
        display: event.abnormal ? "inline-flex" : "inline",
        alignItems: "center"
      }}
    >
      {event.abnormal && <WarningAmberIcon sx={{ mr: "2px" }} />}
      <Summary event={event} />
    </Typography>
  )
}

const Summary = ({ event }: { event: TimelineEventType }) => {
  const { details } = event

  if (!details || details.length === 0) {
    return <></>
  }

  return (
    <>
      {details.map((detail, detailIndex) => {
        if (detailIndex === 0) {
          return <Box key={detailIndex} component={"span"}>{detail}</Box>
        }
        return (
          <React.Fragment key={detailIndex}>
            <TextSeparator />
            <Box component={"span"}>{detail}</Box>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default TimelineEventDetails