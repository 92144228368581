import { SettingDictionaryType, SettingsDictEntryType } from "../types/settings/Setting";

export interface TenantUserSettingDictionaryType extends SettingDictionaryType {
  TIME_SCALE_SETTING: SettingsDictEntryType
  SHOW_ROOM_SETTING: SettingsDictEntryType
  BILLING_RESET_DATE_SETTING: SettingsDictEntryType
}

export const tenantUserSettings:TenantUserSettingDictionaryType = {
  'TIME_SCALE_SETTING': {
    name: 'time_scale',
    type: "intSetting",
    defaultValue: 60
  },
  'SHOW_ROOM_SETTING': {
    name: 'show_rooms',
    type: "boolSetting",
    defaultValue: true
  },
  'BILLING_RESET_DATE_SETTING': {
    name: 'billing_reset_service_date',
    type: "stringSetting",
    defaultValue: '1'
  }
}
