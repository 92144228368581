import React, { useState } from 'react'
import { SimpleSelect, Spinner } from 'saga-library/src'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { GET_TENANT_ROLES } from '../../../../graphql-definitions'
import { MenuItem } from "@mui/material";
import { Role } from "../../../../types/account";

const TenantRoleSelector = ({ onChange, name, currentRoleId, dataTestId }) => {
  const { tenant_id } = useParams()
  const [roles, setRoles] = useState([])
  const [roleId, setRoleId] = useState(-1)
  const { loading, error, data } = useQuery(GET_TENANT_ROLES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setRoles(_get(data, 'tenant.role.tenantRoles', null))
    },
    onError: (error) => {
      console.error('Unable to retrieve roles: ' + error)
    },
  })

  const localOnChange = (e) => {
    onChange(roles.find((r: Role) => r.id === e.target.value))
  }

  if (loading) {
    return <Spinner size={'sm'} />
  }

  return (
    <SimpleSelect
      dataTestId={dataTestId}
      onChange={(e) => {
        setRoleId(e.target.value)
        localOnChange(e)
      }}
      label={'Copy permissions from'}
      value={roleId}
      name={name}
    >
      {roles.filter(({id}) => id !== currentRoleId).map(({ name, id='' }, index) =>
        <MenuItem data-testid={`${dataTestId}-menuItem-${index}`} value={id}>{name}</MenuItem>
      )}
    </SimpleSelect>
  )
}

export default TenantRoleSelector
