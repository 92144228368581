import React, { useState, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { QueryRef, useApolloClient, useLoadableQuery, useMutation } from '@apollo/client'
import {
  ACCEPT_ADJUSTMENT, CANCEL_RESUBMISSION, CANCEL_WRITEOFF,
  GET_ASSESSEDCLAIM,
  LIST_ASSESSEDCLAIMS, RESUBMIT_CLAIM,
  RESUBMIT_CLAIMS, WRITEOFF_CLAIM
} from '../../../graphql-definitions'
import { ABClaimActionCode, AssessedAbClaimSortColumn } from '../../../utils/ABClaimConstants'
import { SortOrder } from '../../../utils/GeneralEnums'
import { AbClaim, AssessedAbClaimFilterInput, AssessedAbClaimSorterInput } from '../../../types/billing'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'


interface AssessedClaimsParamsProps {
  tenantId: string
  filters: AssessedAbClaimFilterInput
  sorter: AssessedAbClaimSorterInput
  first: number
  after: string | null
}

const DEFAULT_PAGE_SIZE = 50



interface AssessedClaimsContextInterface {
  assessedClaimsListQueryRef: QueryRef | null,
  changeFilters: (filters) => void,
  selectedClaimIds: string[],
  setSelectedClaimIds: ([]) => void,
  batchResubmitClaims: (claimData) => void,
  resubmitClaim: (claim_id, claimData, actionCode) => void,
  getClaim: (variables) => void,
  getClaimQueryRef: QueryRef | null,
  cancelWriteOff: (claim_id) => void,
  writeOffClaim: (claim_id, code) => void,
  acceptAdjustment: (claim_id) => void,
  cancelResubmission: (claim_id) => void,
  getSelectedClaims: () => AbClaim[]
}

const defaultAssessedClaimsContext: AssessedClaimsContextInterface = {
  assessedClaimsListQueryRef: null,
  changeFilters: (filters) => null,
  selectedClaimIds: [],
  setSelectedClaimIds: (arg) => null,
  batchResubmitClaims: () => null,
  resubmitClaim: (claim_id, claimData, actionCode) => null,
  getClaim: (variables) => null,
  getClaimQueryRef: null,
  cancelWriteOff: (claim_id) => null,
  writeOffClaim: (claim_id, code) => null,
  acceptAdjustment: (claim_id) => null,
  cancelResubmission: (claim_id) => null,
  getSelectedClaims: () => []
}

const AssessedClaimsContext = React.createContext(defaultAssessedClaimsContext)

export const AssessedClaimsProvider = () => {
  const { tenant_id } = useParams()
  const [ selectedClaimIds, setSelectedClaimIds ] = useState<string[]>([])
  const { showWarningAlert, showErrorAlert, showSuccessAlert } = useAlerts()
  const client = useApolloClient()

  const [getClaim, getClaimQueryRef] = useLoadableQuery(GET_ASSESSEDCLAIM, {fetchPolicy: 'cache-and-network'})

  const [batchResubmitClaimsMutation, { error: batchResubmitError }] = useMutation(RESUBMIT_CLAIMS)
  const [resubmitClaimMutation, { error: resubmitError }] = useMutation(RESUBMIT_CLAIM)
  const [acceptAdjustmentMutation] = useMutation(ACCEPT_ADJUSTMENT)
  const [cancelResubmissionMutation] = useMutation(CANCEL_RESUBMISSION)
  const [writeOffClaimMutation] = useMutation(WRITEOFF_CLAIM)
  const [cancelWriteOffMutation] = useMutation(CANCEL_WRITEOFF)


  const defaultParams: AssessedClaimsParamsProps = {
    tenantId: tenant_id!,
    filters:  {
      assessmentOutcomeFilter: null,
      practitionerId: null,
      searchTerm: null,
      adjusted: null,
      resubmittedOnly: null,
    },
    sorter:{
      sortColumn: AssessedAbClaimSortColumn.CLAIM_IDENTIFIER,
      sortOrder: SortOrder.ASCENDING
    },
    first: DEFAULT_PAGE_SIZE,
    after: null
  }

  const assessedClaimsParams: AssessedClaimsParamsProps = defaultParams

  const [loadAssessedClaimsList, assessedClaimsListQueryRef] = useLoadableQuery(LIST_ASSESSEDCLAIMS)

  useEffect(() => {
    loadAssessedClaimsList(assessedClaimsParams)
  }, [])


  const getSelectedClaims = () => {
    const claimsList = client.readQuery({
      query: LIST_ASSESSEDCLAIMS,
      variables: assessedClaimsParams
    })
    return claimsList.filter(c => selectedClaimIds.includes(c.id))
  }


  const changeFilters = (filters) => {
    assessedClaimsParams.filters = filters
    loadAssessedClaimsList(assessedClaimsParams)
  }
  const batchResubmitClaims = async(claimData) => {
    await batchResubmitClaimsMutation({
      variables: {
        tenantId: tenant_id,
        claimIds: selectedClaimIds,
        claimData: claimData,
        actionCode: ABClaimActionCode.REASSESS
      },
      onCompleted: () => {
        setSelectedClaimIds([])
        showSuccessAlert(`${selectedClaimIds.length} ${selectedClaimIds.length === 1? 'claim has' : 'claims have'} been queued for resubmission.`)
      },
      onError: () => {
        console.error(JSON.stringify(batchResubmitError, null, 2))
        showErrorAlert('Claims couldn\'t be resubmitted.')
      }
    })
  }

  const resubmitClaim = async(claim_id, claimData, actionCode) => {
    await resubmitClaimMutation({
      variables: {
        tenantId: tenant_id,
        claimId: claim_id,
        claimData: claimData,
        actionCode: actionCode
      },
      onCompleted: (data) => {
        const problems = _get(data, 'tenant.assessedAbClaim.resubmitClaim.problems', null)
        if (problems?.length) {
          let containsError = problems.some(problem => problem.severity === 'ERROR')
          if (!containsError) {
            showWarningAlert("Claim has been resubmitted but contains warnings. The claim may be be rejected by AHCIP.")
          }
          else {
            showErrorAlert("Claim has been saved but contains errors. It cannot be submitted until all errors are resolved.")
          }
        }
        else {
          showSuccessAlert("Claim has been resubmitted.")
        }
      },
      onError: () => {
        console.error(JSON.stringify(resubmitError, null, 2))
        showErrorAlert('Claim couldn\'t be resubmitted.')
      }
    })
  }

  const acceptAdjustment = async(claim_id) => {
    await acceptAdjustmentMutation({
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        showSuccessAlert("Claim has been accepted.")
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Claim couldn't be accepted.")
      },
    })
  }

  const cancelResubmission = async(claim_id) => {
    await cancelResubmissionMutation({
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        showSuccessAlert("Resubmission has been cancelled.")
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Resubmission couldn't be cancelled.")
      },
    })
  }

  const cancelWriteOff = async(claim_id) => {
    console.log(claim_id)
    await cancelWriteOffMutation({
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        console.log(data)
        showSuccessAlert("Claim can now be resubmitted to AHCIP.")
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Write off couldn't be cancelled.")
      },
    })
  }

  const writeOffClaim = async(claim_id, code) => {
    console.log(claim_id)
    await writeOffClaimMutation({
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
        code: code
      },
      onCompleted: (data) => {
        console.log(data)
        showSuccessAlert('Claim has been written off.')
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Claim couldn\'t be written off.')
      },
    })
  }

  const providerValues = {
    assessedClaimsListQueryRef,
    changeFilters,
    setSelectedClaimIds,
    selectedClaimIds,
    batchResubmitClaims,
    resubmitClaim,
    getClaim,
    getClaimQueryRef,
    cancelWriteOff,
    writeOffClaim,
    acceptAdjustment,
    cancelResubmission,
    getSelectedClaims
  }

  return (
    <AssessedClaimsContext.Provider value={providerValues}>
      { assessedClaimsListQueryRef && <Outlet /> }
    </AssessedClaimsContext.Provider>
  )
}

export const useAssessedClaimsContext = () => {
  return React.useContext(AssessedClaimsContext)
}