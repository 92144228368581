import { FormProvider, useForm } from "saga-library/src/components/Form";
import FormattedDatePicker from "../../../../components/FormattedDatePicker";
import { ScheduleIdSelect } from "../../../../components/ScheduleIdSelect";
import { ControlledAppointmentTypeSelect } from "../../../../components/AppointmentTypeSelect";
import { Checkbox, IconButton, TextField, Typography, Form, LoadingButton } from "saga-library/src";
import { Box, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { schema } from "../../util/searchPanelValidation";
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { tenantUserSettings } from "../../../../utils/TenantUserSettings";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment-timezone";


export type ApptSearchFormValues = {
  startDate: Date,
  scheduleId: string,
  appointmentTypeId?: string,
  appointmentLength: number
}

export const ApptSearchForm = ({onSubmit, onClose, searchData, loading}) => {
  const { getUserTenantSettings } = useTenantContext()
  const { TIME_SCALE_SETTING } = tenantUserSettings
  const userTenantSettings = getUserTenantSettings([TIME_SCALE_SETTING])
  const { selectedScheduleIds, listSchedules, getScheduleData } = useScheduleContext()
  const [ selectedScheduleId, setSelectedScheduleId ] = React.useState<string | undefined>(undefined)
  const [ filteredSchedules, setFilteredSchedules ] = React.useState<any[]>([])

  const formMethods = useForm<ApptSearchFormValues>({
    defaultValues: {
      startDate: moment().toDate(),
      appointmentLength: Number(userTenantSettings[TIME_SCALE_SETTING.name]),
      scheduleId: ""
    },
    schema: schema,
  })

  const {
    setValue,
    watch,
    resetField,
    reset
  } = formMethods

  const watchAppointmentType = watch('appointmentTypeId')

  useEffect(() => {
    reset(searchData)
  }, [reset, searchData]);

  const updateSelectedScheduleId = useCallback((scheduleId) => {
    if (scheduleId) {
      if (watchAppointmentType && selectedScheduleId) {
        const availableAppointmentTypeIds = getScheduleData(selectedScheduleId)?.appointmentTypes.map(({ id }) => id)
        if (availableAppointmentTypeIds && !availableAppointmentTypeIds.includes(watchAppointmentType)) {
          resetField('appointmentTypeId')
          resetField('appointmentLength')
        }
      }
      const schedule = listSchedules.find((schedule) => schedule.id === scheduleId)
      setSelectedScheduleId(schedule.id)
    } else {
      resetField('appointmentTypeId')
      resetField('appointmentLength')
      setSelectedScheduleId("")
    }
  }, [getScheduleData, listSchedules, resetField, selectedScheduleId, watchAppointmentType])

  useEffect(() => {
    if(selectedScheduleId && !selectedScheduleIds.includes(selectedScheduleId)){
      if(selectedScheduleIds.length === 1){
        setValue('scheduleId', selectedScheduleIds[0])
        updateSelectedScheduleId(selectedScheduleIds[0])
      } else {
        setValue('scheduleId', "")
        updateSelectedScheduleId("")
      }
    } else if (!selectedScheduleId && selectedScheduleIds.length === 1) {
      setValue('scheduleId', selectedScheduleIds[0])
      updateSelectedScheduleId(selectedScheduleIds[0])
    } 
  }, [selectedScheduleIds, setValue, selectedScheduleId, updateSelectedScheduleId])
  
  useEffect(() => {
    setFilteredSchedules(listSchedules.filter(({ id }) => selectedScheduleIds.includes(id)))
  }, [listSchedules, selectedScheduleIds])

  const updateAppointmentLength = (appointmentType) => {
    if (appointmentType) {
      setValue('appointmentLength', appointmentType?.duration)
    } else {
      resetField('appointmentLength')
    }
  }

  return<>
    <Box display={'flex'} flex-direction={'row'} alignItems={'center'} justifyContent={'space-between'} >
      <Typography variant={'h3'}>Find an open time slot</Typography>
      <IconButton dataTestId={"scheduler-appointmentSearch-close"} onClick={onClose} icon={<CloseIcon/>}/>
    </Box>
    <Box display={'flex'} flex-direction={'column'} justifyContent={'center'}>
      <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit(onSubmit)} style={{width:"100%"}}>
        <FormattedDatePicker dataTestId={"scheduler-appointmentSearch-selectDate"} label={'Starting on'} name={'startDate'} sx={{width:'100%'}}/>
        <ScheduleIdSelect
          dataTestId={"scheduler-appointmentSearch-selectPractitioner"}
          name={'scheduleId'}
          label={'Practitioner'}
          schedules={filteredSchedules}
          showLocation={true}
          onChange={(e) => updateSelectedScheduleId(e)}
        />
        <ControlledAppointmentTypeSelect
          dataTestId={"scheduler-appointmentSearch-type"}
          label={'Appointment type (optional)'}
          name={'appointmentTypeId'}
          onSelectObject={(e) => updateAppointmentLength(e)}
          limitToScheduleId={selectedScheduleId}
        />
        {watchAppointmentType &&
          <Checkbox
            dataTestId={"scheduler-appointmentSearch-showPreferredTimes"}
            name={'showPreferredTimes'}
            label={'Only show preferred times'}
          />
        }
        <TextField
          dataTestId={"scheduler-appointmentSearch-length"}
          name={'appointmentLength'}
          label={'Length'}
          type={'number'}
          sx={{width:'100%'}}
          InputProps={{
            endAdornment: <InputAdornment position={'end'}>minutes</InputAdornment>
          }}
        />
        <Box display={'flex'} flex-direction={'row'} justifyContent={'center'} sx={{marginTop:2}}>
          <LoadingButton
            dataTestId={"scheduler-appointmentSearch-find"}
            sx={{
              width: '50%',
              minWidth: '5px',
              maxWidth: '100px',
            }}
            name={'appointmentSearch'}
            variant={'contained'}
            type={'submit'}
            loading={loading}
          >
            Find
          </LoadingButton>
        </Box>
      </Form>
    </FormProvider>
    </Box>
  </>
}