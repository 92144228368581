import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";
import { FacilitySearch } from "../../../../../components/SearchControls/Claims/FacilitySearch";

interface BatchControlledFacilityInputProps {
  name: string
  label: string
  serviceDate?: Date,
  practitionerId?: string
  dataTestId?: string
}

export const BatchControlledFacilityInput = ({
  name,
  label,
  serviceDate,
  practitionerId,
  dataTestId
}: BatchControlledFacilityInputProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <FacilitySearch
      dataTestId={dataTestId}
      label={label}
      name={name}
      practitionerId={practitionerId}
      serviceDate={serviceDate}
      sx={
        batchUpdateStyleSearch(dirtyFields[name]) as any
      }
    />
  )
}
