import React from 'react'
import { PatientSearchResult } from "../../../types/patients";
import { EntryTitle, IdentifierRow, ResultOptionsWrapper } from "saga-library/src";
import { patientDisplayName } from "saga-library/src/util";
import { useTenantContext } from "../../../providers/TenantContextProvider";
import Box from "@mui/material/Box";
import { Typography, useTheme } from '@mui/material'
import { useFormattedDate } from '../../../hooks/FormattedDate'
import { Tag } from '../../../apps/patients/components/Tag'
import { NO_DATE_OF_BIRTH } from '../../FormattedAge'
import { useAccountContext } from '../../../providers/AccountContext'
import { useParams } from 'react-router-dom'

interface PatientResultProps {
  patient: PatientSearchResult
  searchText: string
  dataTestId?: string
}

export const PatientResult = ({
  patient,
  searchText,
  dataTestId
}: PatientResultProps) => {
  const { formatIdentifier } = useTenantContext()
  const theme = useTheme()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const {
    firstName,
    lastName,
    identifiers,
    dob,
    inactiveDate
  } = patient

  const formattedDob = useFormattedDate(dob)

  const openPatientInNewTab = () => {
    window.open(buildTenantRoute(
      `patients/p/${patient.id}`, tenant_id
    ))
  }

  function formatIdentifierRows() {
    if (identifiers && identifiers.length > 0) {
      return identifiers.map((identifier) => {
        return (
          <IdentifierRow
            key={identifier.typeId}
            dataTestId={`${dataTestId}-id`}
            label={identifier.label}
            identifier={formatIdentifier(identifier.value, identifier.typeId)}
            searchText={searchText}
            disabled={!!inactiveDate}
          />
        )
      })
    }

    return <IdentifierRow
      dataTestId={`${dataTestId}-id`}
      label={"ID"}
      identifier={"None"}
      searchText={searchText}
      disabled={!!inactiveDate}
    />
  }

  return (
    <ResultOptionsWrapper
      dataTestId={dataTestId}
      key={dataTestId}
      options={{
        onOpenInNewTab: openPatientInNewTab
      }}
    >
      <EntryTitle
        dataTestId={`${dataTestId}-name`}
        title={patientDisplayName(firstName, lastName)}
        searchText={searchText}
        endAdornment={!inactiveDate ? undefined : <Tag label={'End dated'} color={theme.palette.greys.medium} fontWeight={400}/>}
        sx={{
          mb: 0,
          color: !inactiveDate ? "default" : theme.palette.greys.medium
        }}
      />
      <Typography
        variant={'p2'}
        sx={{ mb: '2px' }}
        color={!inactiveDate ? undefined : theme.palette.greys.medium}
      >
        {formattedDob || NO_DATE_OF_BIRTH}
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
      >
        {formatIdentifierRows()}
      </Box>
    </ResultOptionsWrapper>
  )
}

export default PatientResult
