import { Box, Theme, useTheme } from "@mui/material";
import { Typography } from "saga-library/src";
import React from "react";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { patientDisplayName } from "saga-library/src/util";
import { styled, SxProps } from "@mui/system";
import { AppointmentMethod } from "../../../../types/schedule/Appointment";
import { GhostInspectorNewEvent } from "./GhostInspectorNewEvent";

export const AppointmentEvent = ({
  eventData,
  height='100%',
  room
}) => {
  const state = eventData?.state
  const theme = useTheme()
  return <>
    <Box
      className={"md-custom-event-cont"}
      sx={theme => ({
        height: `calc(${height} - 1px)`,
        overflow: "hidden",
        color: theme.palette.greys.dark,
        my: "1px",
        container: 'my-container / size',
        display: 'flex',
        flexDirection: 'row',
        background: state.color,
        borderRadius:"0 4px 4px 0",
      })}
    >
      <Box sx={{
        height: '100%',
        width: '8px',
        background: eventData?.type ? eventData?.type?.color : "rgba(255,255,255,0)",
        border: eventData?.type ? "none" : `1px solid ${theme.palette.greys.extraLight}`,
        flex: '0 0 auto',
        boxSizing: "border-box"
      }}/>
      <Box
        className={"external-drop-task"}
        display={"flex"}
        sx={{
          flex:'1 1 100%',
          height: "100%",
          px: "4px",
          flexDirection: "column",
          position: "relative",
          "#appointment-state":{
            position:"absolute",
            bottom:'4px',
            right:'4px'
          },
          ['@container (min-height: 55px)']:{
            ".appointment-row:first-of-type":{
              height: 'auto'
            }
          },
          ['@container (max-height: 54px)']:{
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            alignContent:"flex-start",
            ".appointment-row:first-of-type":{
              height: '18px',
              width:"100%"
            },
            ".appointment-row:not(:first-of-type)":{
              marginRight:'4px'
            }
          },
          ['@container (max-height: 35px)']:{
            flexWrap:"nowrap",
            gap: '4px',
            ".appointment-row:first-of-type":{
              width:"auto"
            },
            ".appointment-row:not(:first-of-type)":{
              marginRight:0
            },
            "#appointment-state":{
              flex: '1 1 100%',
              textAlign: 'right',
              position:"static",
              display: "flex",
              flexDirection: "column"
            }
          },
          ['@container (max-height: 54px) and (max-width: 185px)']:{
            "#appointment-state":{
              display: "none"
            }
          },
          ['@container (max-width: 75px)']:{
            "#appointment-state":{
              display: "none"
            },
            ".appointment-row:not(:first-of-type)":{
             display: "none"
            }
          }
        }}
      >
        <AppointmentRow>
          <AppointmentTypography variant={"p3"} fontWeight={"bold"}> {patientDisplayName(eventData?.patient.firstName, eventData?.patient.lastName)}</AppointmentTypography>
        </AppointmentRow>
        <AppointmentRow className={"row2"}>
          <AppointmentTypography variant={"p3"} lineclamp={1}> {eventData?.type?.name} </AppointmentTypography>
          {eventData?.method === AppointmentMethod.PHONE && <CallOutlinedIcon sx={{ fontSize: '16px' }} />}
          {eventData?.method === AppointmentMethod.VIDEO && <VideocamOutlinedIcon sx={{ fontSize: '18px' }} />}
        </AppointmentRow>
        <AppointmentRow className={"row3"}>
          <AppointmentTypography variant={"p3"}> {eventData?.reasonForVisit} </AppointmentTypography>
        </AppointmentRow>
        <Box id={"appointment-state"} >
          { room ?
            <AppointmentTypography variant={"p3"}> IN {room.name.toUpperCase()} </AppointmentTypography> :
            <AppointmentTypography variant={"p3"}> {state.name.toUpperCase()} </AppointmentTypography>
          }
        </Box>
      </Box>
    </Box>
    <GhostInspectorNewEvent />
  </>
}

type AppointmentRowProps = {
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  className?: string
}
const AppointmentRow = ({children, sx, className=""}:AppointmentRowProps) => {
  return <Box className={`appointment-row${className ? " "+className : ""}`} flex={"0 0 auto"} gap={"2px"} sx={{ display:'flex', maxHeight: '16px', alignItems:'center', flexDirection:'row', ...sx }}>
    { children }
  </Box>
}

const AppointmentTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary
}))