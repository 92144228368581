import React from 'react'
import { Select } from 'saga-library/src'
import { NewbornCode } from '../../utils/ABClaimConstants'
import { MenuItem, SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface NewbornCodeSelectProps {
  name?: string
  label?: string
  sx?: SxProps<Theme>
  autoFocus?: boolean
  disabled?:boolean
  inputLabelStyle?: object
  dataTestId?: string
}

export const NewbornCodeSelect = ({
  name = 'newBornCode',
  label = 'Newborn',
  sx = {},
  inputLabelStyle = {},
  autoFocus,
  disabled = false,
  dataTestId
}: NewbornCodeSelectProps) => {
  const menuItemId = `${dataTestId}-menuitem`
  return (
    <Select
      dataTestId={dataTestId}
      inputLabelStyle={inputLabelStyle}
      label={label}
      name={name}
      sx={sx}
      autoFocus={autoFocus}
      disabled={disabled}
    >
      <MenuItem data-testid={`${menuItemId}-0`} value={''}>None</MenuItem>
      <MenuItem data-testid={`${menuItemId}-1`} value={NewbornCode.LIVEBIRTH}>Live birth (LVBR)</MenuItem>
      <MenuItem data-testid={`${menuItemId}-2`} value={NewbornCode.ADOPTION}>Adoption (ADOP)</MenuItem>
      <MenuItem data-testid={`${menuItemId}-3`} value={NewbornCode.MULTIPLEBIRTH}>Multiple births (MULTI)</MenuItem>
      <MenuItem data-testid={`${menuItemId}-4`} value={NewbornCode.STILLBORN}>Stillborn (STBN)</MenuItem>
    </Select>
  )
}

export default NewbornCodeSelect
