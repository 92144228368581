import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { AppointmentTypeInput } from "../../../../types/schedule";
import _get from "lodash/get";
import { DialogV2 } from "saga-library/src";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { schema } from "../util/appointmentTypeValidation";
import { AppointmentTypeForm } from "./AppointmentTypeForm";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ADD_NEW_APPOINTMENT_TYPE, LIST_APPOINTMENT_TYPES } from "../../../../graphql-definitions";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";

const FORM_NAME = 'new_appointment_type_form'

interface NewAppointmentTypeModalTempProps {
  open: boolean
  setOpen: (a: boolean) => void
}

const newAppointmentTypeDefaultValues = {
  name: '',
  duration: '',
  color: '#FFFFFF',
  inPerson: false,
  byPhone: false,
  videoCall: false,
  schedules: [],
  scheduleIds: [],
  isDeleted: false,
  allSchedules: false,
  version: "0"
}

export const NewAppointmentTypeModal = ({ open, setOpen }: NewAppointmentTypeModalTempProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const [addNewAppointmentType, {loading, error}] =
    useMutation(ADD_NEW_APPOINTMENT_TYPE, {
      onCompleted: (data) => {
        showSuccessAlert('Appointment type has been added.')
        reset()
        setOpen(false)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    })

  const formMethods = useForm<AppointmentTypeInput>({
    defaultValues: newAppointmentTypeDefaultValues,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setOpen(false)
    }
  }
  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {

    const allSchedulesCheck = data.schedules && data.schedules.find(s => s.id === 'allSchedules')
    if (allSchedulesCheck) {
      data.allSchedules = true
      data.scheduleIds = []
    } else {
      data.allSchedules = false
      data.scheduleIds = data.schedules ? data.schedules.map(s => s.id) : []
    }
    delete data.schedules

    data.duration = +(data.duration ?? '0')

    await addNewAppointmentType({
      variables: {
        tenantId: tenant_id,
        appointmentTypeInput: data
      },
      update(cache, returnedData) {
        const appointmentTypeId = _get(returnedData, 'data.tenant.schedule.type.create')
        const newAppointmentType =
          {
            id: appointmentTypeId,
            ...data,
          }
        cache.updateQuery({
            query: LIST_APPOINTMENT_TYPES,
            variables: { tenantId: tenant_id },
          },
          (data) => {
            let list = [...data.tenant.schedule.type.listTypes, newAppointmentType]
            return {
              tenant: {
                schedule: {
                  type:  {
                    listTypes: list
                  }
                }
              }
            }
          }
        )
      },
    })
  })

  return (
    <DialogV2
      title={"Add a new appointment type"}
      open={open}
      onClose={handleClose}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={'saveAppointmentType'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={"newAppointmentTypeModal-save-button"}
          loading={isSubmitting}
          type={'submit'}
        >
          Save
        </PermissionButton>
      }
      data-testid={"newAppointmentTypeModal-dialog"}
    >
      <FormProvider {...formMethods}>
        <AppointmentTypeForm
          onSubmit={onSubmit}
        />
      </FormProvider>
    </DialogV2>
  )
}
