import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface PageTitleContextInterface {
  setAreaTitle: (title: string) => void
}

const defaultPageTitleContext : PageTitleContextInterface = {
  setAreaTitle: () => {}
}

const DEFAULT_TITLE = 'Saga Health'

const PageTitleContext = React.createContext(defaultPageTitleContext)

export const PageTitleContextProvider = ({ children }) => {
  const [title, setTitle] = useState<string>(DEFAULT_TITLE)
  const [previousArea, setPreviousArea] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    const splitPathname = location.pathname.split('/')
    if (splitPathname.length >= 4) {
      const currentSection = splitPathname[3]
      if (currentSection !== previousArea) {
        setDefaultTitle()
        setPreviousArea(currentSection)
      }
    } else {
      setDefaultTitle()
      setPreviousArea('')
    }
  }, [location])

  const setDefaultTitle = () => {
    setTitle(DEFAULT_TITLE)
  }

  const setAreaTitle = (sectionTitle: string) => {
    setTitle(`${sectionTitle} - ${DEFAULT_TITLE}`)
  }

  const providerValues = {
    setAreaTitle
  }

  return (
    <PageTitleContext.Provider value={providerValues}>
      {children}
    </PageTitleContext.Provider>
  )
}

export const usePageTitleContext = () => {
  return React.useContext(PageTitleContext)
}
