export type AppointmentState = {
  id: string
  name: string
  color?: string
  category: AppointmentStateCategory
  canEnterExamRoom: boolean
  blocksSchedule: boolean
  isDefault: boolean
  sort?: number
  version: string
}

export type AppointmentStateInput = {
  name: string
  color?: string
  category: AppointmentStateCategory
  canEnterExamRoom: boolean
  blocksSchedule: boolean
  sort: number
  version: string
}

export enum AppointmentStateCategory {
  PREVISIT = 'PRE_VISIT',
  INPROGRESS = 'IN_PROGRESS',
  POSTVISIT = 'POST_VISIT',
  DONE = 'DONE'
}

export const AppointmentStateCategoryMap: Map<AppointmentStateCategory, {label: string, order: number}> = new Map ([
  [AppointmentStateCategory.PREVISIT, {label: "Pre-visit", order: 0}],
  [AppointmentStateCategory.INPROGRESS, {label: "In progress", order: 1}],
  [AppointmentStateCategory.POSTVISIT, {label: "Post-visit", order: 2}],
  [AppointmentStateCategory.DONE, {label: "Done", order: 3}]
])