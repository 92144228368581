import React from "react";
import { Tabs } from "saga-library/src";
import { ReferralPractitionerDetails } from "./ReferralPractitionerDetails";
import { PermissionType } from "../../../../../types/settings/Permission";
import { PermissionForm } from "../../../../../components/PermissionForm";

export const ReferralPractitionerForm = ({
  onSubmit,
  prevProvince,
}) => {
  const options = [
    {
      label: 'GENERAL',
      content: (
        <ReferralPractitionerDetails.Form
          prevProvince={prevProvince}
        />
      ),
    },
  ]

  return (
    <PermissionForm
      onSubmit={onSubmit}
      sx={{
        height: '100%',
        flex: "1 1 auto",
        display:"flex",
        flexDirection: "column",
        overflow: "auto"
      }}
      requiredPermissionType={PermissionType.Admin}
      readOnlyOverride={true}
      id={'referralPractitionerForm'}
    >
      <Tabs options={options} />
    </PermissionForm>
  )
}

export default ReferralPractitionerForm
