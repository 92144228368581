import React, { useState } from 'react'
import { Box } from '@mui/material'
import InfoFilledIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { DraggableList, IconButton, Section, StyledTooltip, Typography } from 'saga-library/src'
import { useFormContext } from 'saga-library/src/components/Form'
import { PenIcon } from '../../../patients/components/ChartIcons'
import { ReportFormParametersModal } from './ReportFormParametersModal'
import { ReportParameterInput } from '../../../../types/Report'

const ParametersTooltip = () => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <StyledTooltip
      arrow
      open={open}
      placement={'right'}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      color={'white'}
      title={
        <Typography variant={'body1'}>Select the parameters available to users in this report.</Typography>
      }
    >
      <Box
        color={(theme) => theme.palette.greys.medium}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {open
          ? <InfoFilledIcon fontSize={'medium'} />
          : <InfoOutlinedIcon fontSize={'medium'} />
        }
      </Box>
    </StyledTooltip>
  )
}

export const ReportFormParameters = ({ name, disabled }: { name: string, disabled?: boolean }) => {
  const [open, setOpen] = useState<boolean>(false)
  const { setValue, watch } = useFormContext()

  const category = watch("category")
  const parameters = watch(name)

  return (
    <Section.Container
      sx={{
        flex: "0 0 auto",
        height: "100%",
        mr: "-8px",
        width: "288px"
      }}
    >
      <Section.ColumnNoElevation
        sx={{
          border: "1px solid rgba(32, 41, 49, 0.32)",
          borderRadius: "8px"
        }}
      >
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Box display={"inline-flex"} gap={"4px"} minHeight={"40px"}>
            <Section.SubHeader variant={"h5"} sx={{ mb: 0 }}>
              Parameters
            </Section.SubHeader>
            <ParametersTooltip />
          </Box>
          {!disabled && <IconButton icon={<PenIcon />} onClick={() => setOpen(true)} />}
        </Box>
        <DraggableList<ReportParameterInput>
          emptyListMessage={"Parameters appear here once selected"}
          listItems={parameters}
          isDraggable={false}
          updateListItems={(items) => setValue(name, items, { shouldDirty: true })}
          disabled={disabled}
          sx={{ pr: "8px" }}
          dataTestId={"report-parameters"}
        />
      </Section.ColumnNoElevation>
      <ReportFormParametersModal
        open={open}
        category={category}
        parameters={parameters}
        onChange={(items) => setValue(name, items, { shouldDirty: true })}
        onClose={() => setOpen(false)}
      />
    </Section.Container>
  )
}