import { DocumentNode, WatchQueryFetchPolicy } from "@apollo/client";
import { AutocompleteRenderOptionState, AutocompleteValue } from "@mui/material";

export const DEBOUNCE_WAIT_TIME = 200
export const MAX_INPUT_LENGTH = 140

export type QueryDef<T> = {
  gql: DocumentNode
  get: (data: object) => T[]
  variables: object
  fetchPolicy?: WatchQueryFetchPolicy | undefined
}

export type SearchQueries<T> = {
  initial?: QueryDef<T>
  search: QueryDef<T>
}

export type ValueTransform<T, Multiple, DisableClearable, FreeSolo> = (value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>) => string | string[] | null | undefined
export type InputTransform<T, Multiple, DisableClearable, FreeSolo> = (input: string | null | undefined) => AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>

export type RenderPackage<T> = {
  option: (input: T, state: AutocompleteRenderOptionState) => JSX.Element[] | JSX.Element | null
  value: (input: T) => string
  key?: (input: T) => string
  endAdornment?: (input: T | T[] | null | undefined) => JSX.Element[] | JSX.Element | null
  startAdornment?: (input: T | T[] | null | undefined) => JSX.Element[] | JSX.Element | null
}
