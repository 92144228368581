import React from 'react'
import { Section } from 'saga-library/src'
import { PersonalDetails, PersonalDetailsType } from './PersonalDetails'
import { ContactDetails, ContactDetailsType } from './ContactDetails'
import {
  AddressDetails,
  AddressDetailsType,
} from './AddressDetails'
import {
  AdministrationDetails,
  AdministrationDetailsType,
} from './AdministrationDetails'
import { IdentifierDetails, IdentifierDetailsType } from './IdentifierDetails'
import { PermissionType } from "../../../../../types/settings/Permission";
import { PractitionerSubSection } from "../../components/PractitionerDetails";

const defaults = {
  version: "0",
  ...PersonalDetails.defaults,
  ...ContactDetails.defaults,
  ...AddressDetails.defaults,
  ...AdministrationDetails.defaults,
  ...IdentifierDetails.defaults,
}

const Form = ({ prevProvince }) => {
  const PERMISSION_REQUIRED = PermissionType.Practitioner

  return (
    <Section.Container sx={{overflowY: "auto"}}>
      <PractitionerSubSection>
        <PersonalDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
        <ContactDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>

      <PractitionerSubSection>
        <AddressDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
      </PractitionerSubSection>

      <PractitionerSubSection>
        <IdentifierDetails.Form requiredPermissionType={PERMISSION_REQUIRED}/>
        <AdministrationDetails.Form
          prevProvince={prevProvince}
          requiredPermissionType={PERMISSION_REQUIRED}
        />
      </PractitionerSubSection>
    </Section.Container>
  )
}

export type ReferralPractitionerDetailsType = PersonalDetailsType &
  ContactDetailsType &
  AddressDetailsType &
  AdministrationDetailsType &
  IdentifierDetailsType

export const ReferralPractitionerDetails = {
  Form: Form,
  defaults: defaults,
}
