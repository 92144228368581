import { Box, SvgIcon } from "@mui/material";
import { Typography, IconButton } from "saga-library/src";
import AddCircleIcon from '@mui/icons-material/AddCircle'
import React, { Suspense } from "react";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { LoadingSpinner } from "../../../components/LoadingScreen";

interface WidgetHeaderProps {
  title: string
  WidgetIcon: typeof SvgIcon
  onAddItem?: () => void
  children: React.ReactNode
  route?: string
}

const WidgetHeader = ({title, WidgetIcon, onAddItem: parentOnAddItem, route}) => {
  const { tenant_id, patient_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE)

  const navigateInMain = () => {
    if (route) {
      navigate(
        buildTenantRoute(`patients/p/${patient_id}/${route}`, tenant_id)
      )
    }
    return {}
  }

  const onAddItem = (event) => {
    if (parentOnAddItem) {
      event.stopPropagation()
      parentOnAddItem()
    }
  }

  return <Box
    data-testid={`${title}-widget-header`}
    onClick={navigateInMain}
    sx={ (theme) => ({
      background: theme.palette.greys.extraLight,
      display: "flex",
      borderRadius: 2,
      alignItems: "center",
      px: 1,
      mb: 0,
      gap: 1,
      minHeight: '32px',
      '&:hover': {
        backgroundColor: route ? theme.palette.backgrounds.hover : theme.palette.greys.extraLight,
        cursor: route ? 'pointer' : 'default'
      },
    })}
  >
    <WidgetIcon sx={{ fontSize: "16px" }} />
    <Typography sx={{ flex: "1 1 100%" }} variant={'p4'} fontWeight={'bold'}>  {title.toUpperCase() } </Typography>
    {hasReadWrite && parentOnAddItem && <IconButton dataTestId={`${title}-add-item`}  sx={{ p: '3px'}} onClick={onAddItem} size={'small'} icon={<AddCircleIcon color={'primary'}/>}/>}
  </Box>
}

const Widget = ({title, WidgetIcon, onAddItem, children, route}: WidgetHeaderProps) => {
  return (
    <Box display="flex" flexDirection="column" sx={{flex: 1, overflow: 'hidden'}}>
      <Box paddingRight={1}>
        <WidgetHeader title={title} WidgetIcon={WidgetIcon} onAddItem={onAddItem} route={route}/>
      </Box>
      <Box sx={{pl:1, pr:0, height:'100%', overflow:'hidden'}}>
        <WidgetErrorBoundary>
          <Suspense fallback={<LoadingSpinner size={"md"} label={null} />}>
            { children }
          </Suspense>
        </WidgetErrorBoundary>
      </Box>
    </Box>
  )
}


class WidgetErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.error(error)
    console.error(info.componentStack)
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // @ts-ignore
      return <Box color={"red"}>Failed to load</Box>
    }

    // @ts-ignore
    return this.props.children
  }
}


export default Widget