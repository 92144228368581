import { gql } from "@apollo/client";
import { CONDITION_AND_PROCEDURE_FRAGMENT } from "./patient/ConditionAndProceduresQueries";

export const LIST_RECENT_CONDITION_AND_PROCEDURES = gql`
    ${CONDITION_AND_PROCEDURE_FRAGMENT}
    query ListRecentConditionAndProcedures($tenantId: ID!){
        tenant(id: $tenantId){
            recentConditionAndProcedures {
               ...ConditionAndProcedureFragment
            }
        }
    }
`