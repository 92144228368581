import { PrintIconButton } from 'saga-library/src'
import React, { useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { usePatientFormStateContext } from '../forms/components/FormsStateProvider'

interface FormsPrintButtonProps {
  formId: string | undefined
  patientId: string | undefined
  dataTestId: string
}

export const FormsPrintButton = ({
  formId,
  patientId,
  dataTestId,
}: FormsPrintButtonProps) => {
  const { pdfDocRef, pdfViewerRef, selectedFile } = usePatientFormStateContext()
  const [loading, setLoading] = useState(false)

  const activityTracking = useActivityTracking()

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!formId) return
    activityTracking.logPrintActivity(UserActivityObjectType.FORM, formId, patientId)
    setLoading(false)
  }

  const printPdfViewer = async () => {
    if (!pdfDocRef?.current || !selectedFile || !pdfViewerRef?.current) return

    setLoading(true)
    let totalPageCount = pdfDocRef.current.getPageCount()

    await pdfViewerRef.current?.printEx({ type: 0, pageRange: `1-${totalPageCount}`, progress: true }, (data) => {
      printPdfCallback(data)
    })
  }

  return (
    <PrintIconButton
      disabled={loading}
      onClick={printPdfViewer}
      dataTestId={`${dataTestId}-forms`}
    />
  )
}
