import { gql } from "@apollo/client";

export const LIST_APPOINTMENT_ROOMS = gql`
  query ListAppointmentRooms($tenantId: ID!) {
    tenant(id: $tenantId) {
      schedule {
        room {
          listRooms {
            id
            version
            name
            practitioner {
              id
              firstName
              lastName
            }
            location {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const SCHEDULE_ROOMS_FRAGMENT = gql`
  fragment ScheduleRooms on AppointmentRoom {
    id
    name
    practitioner {
      id
      firstName
      lastName
    }
    location {
      id
      name
    }
    appointment {
      id
      version
      resource: scheduleId
      allDay
      start
      end
      notes
      reasonForVisit
      method
      allDay
      type {
        byPhone
        color
        duration
        id
        inPerson
        isDeleted
        name
        videoCall
      }
      state {
        blocksSchedule
        canEnterExamRoom
        color
        id
        isDefault
        isDeleted
        name
      }
      patient {
        id
        firstName
        lastName
        primaryIdentifierLabel
        primaryIdentifier
        dob
      }
      location {
        id
      }
      practitioner {
        id
      }
    }
    version
  }`

export const GET_ROOMS = gql`
  query GetRooms ($tenantId: ID!, $scheduleIdList: [ID!]!) {
    tenant(id: $tenantId) {
      schedule {
        room {
          rooms(scheduleIds: $scheduleIdList) {
             id
             name
             practitioner {
              id
              firstName
              lastName
             }
             location {
              id
              name
             }
             appointment {
              id
              version
              resource: scheduleId
              allDay
              start
              end
              notes
              reasonForVisit
              method
              allDay
              type {
                  byPhone
                  color
                  duration
                  id
                  inPerson
                  isDeleted
                  name
                  videoCall
              }
              state {
                  blocksSchedule
                  canEnterExamRoom
                  color
                  id
                  isDefault
                  isDeleted
                  name
              }
              patient {
                  id
                  firstName
                  lastName
                  primaryIdentifierLabel
                  primaryIdentifier
                  dob
              }
              location {
                id
              }
              practitioner {
                id
              }
              version
             }
             version
          }
        }
      }
    }
  }
`

export const ADD_NEW_APPOINTMENT_ROOM = gql`
  mutation CreateAppointmentRoom($tenantId: ID!, $input: AppointmentRoomInput!) {
    tenant(id: $tenantId) {
      schedule {
        room {
          create(input: $input) {
            id
            name
            practitioner {
                id
                firstName
                lastName
            }
            location {
                id
                name
            }
            version
          }
        }
      }
    }
  }
  `

export const UPDATE_APPOINTMENT_ROOM = gql`
  mutation UpdateAppointmentRoom($tenantId: ID!, $roomId: ID!, $roomInput: AppointmentRoomInput!) {
    tenant(id: $tenantId) {
      schedule {
        room {
          update(id: $roomId, input: $roomInput) {
            id
            name
            practitioner {
              id
              firstName
              lastName
            }
            location {
              id
              name
            }
            version
          }
        }
      }
    }
  }
`

export const DELETE_APPOINTMENT_ROOM = gql`
    mutation DeleteAppointmentRoom($tenantId: ID!, $roomId: ID!, $version: String!) {
        tenant(id: $tenantId) {
            schedule {
                room {
                    delete(id: $roomId, version: $version)
                }
            }
        }
    }
`


export const ASSIGN_APPOINTMENT_TO_ROOM = gql`
  mutation AssignAppointmentToRoom (
      $tenantId: ID!,
      $roomId: ID!,
      $roomVersion: String!,
      $appointmentId: ID!,
      $appointmentVersion: String!,
      $previousRoomVersion: String
  ) {
    tenant(id: $tenantId) {
      schedule {
        room {
          assignAppointmentToRoom(id: $roomId, roomVersion: $roomVersion, appointmentId: $appointmentId, appointmentVersion: $appointmentVersion, previousRoomVersion: $previousRoomVersion) {
             id
             name
             practitioner {
              id
              firstName
              lastName
             }
             location {
              id
              name
             }
             appointment {
              id
              version
              resource: scheduleId
              allDay
              start
              end
              notes
              reasonForVisit
              method
              allDay
              type {
                  byPhone
                  color
                  duration
                  id
                  inPerson
                  isDeleted
                  name
                  videoCall
              }
              state {
                  blocksSchedule
                  canEnterExamRoom
                  color
                  id
                  isDefault
                  isDeleted
                  name
              }
              patient {
                  id
                  firstName
                  lastName
                  primaryIdentifierLabel
                  primaryIdentifier
                  dob
              }
              location {
                id
              }
              practitioner {
                id
              }
             }
             version
          }
        }
      }
    }
  }
`


export const REMOVE_PATIENT_FROM_ROOM = gql`
  mutation RemovePatientFromRoom($tenantId: ID!, $roomId: ID!, $roomVersion: String!, $appointmentVersion: String!) {
    tenant(id: $tenantId) {
      schedule {
        room {
          removePatientFromRoom(id: $roomId, roomVersion: $roomVersion, appointmentVersion: $appointmentVersion) {
            id
             name
             practitioner {
              id
              firstName
              lastName
             }
             location {
              id
              name
             }
             appointment {
              id
              version
              resource: scheduleId
              allDay
              start
              end
              notes
              reasonForVisit
              method
              allDay
              type {
                  byPhone
                  color
                  duration
                  id
                  inPerson
                  isDeleted
                  name
                  videoCall
              }
              state {
                  blocksSchedule
                  canEnterExamRoom
                  color
                  id
                  isDefault
                  isDeleted
                  name
              }
              patient {
                  id
                  firstName
                  lastName
                  primaryIdentifierLabel
                  primaryIdentifier
                  dob
              }
              location {
                id
              }
              practitioner {
                id
              }
             }
             version
          }
        }
      }
    }
  }
 `
