import React, { useEffect, useRef, useState } from 'react'
import { DialogV2, PrintButton, SimpleCheckbox, Typography } from 'saga-library/src'
import { Box } from '@mui/material'
import { patientDisplayName } from 'saga-library/src/util'
import moment from 'moment-timezone'
import { phoneNumberMask } from 'saga-library/src/components/PhoneField/PhoneField'
import { postalCodeMask } from 'saga-library/src/components/PostalCodeField/PostalCodeField'
import { useLazyQuery } from '@apollo/client'
import { GET_PATIENT_LABEL } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { UserActivityObjectType } from '../../../../hooks/useActivityTracking'

export const PrintPatientModal = ({ patientId, onModalClose, open }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const [ patientLabel, setPatientLabel ] = useState<any>(null)
  const [ addressOnly, setAddressOnly ] = useState(false)
  const componentRef = useRef(null);
  const printWidthCM = 8
  const printHeightCM = 2.5

  const [runGetPatientQuery, {loading}] = useLazyQuery(GET_PATIENT_LABEL, {
    variables: { tenantId: tenant_id, patientId: patientId },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setPatientLabel(_get(data, 'tenant.patient.label', null))
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert('Patient couldn\'t be found.')
    }
  })

  useEffect(() => {
    if (patientId && open) {
      runGetPatientQuery()
    }
  }, [patientId, open, runGetPatientQuery])

  const PatientLabelPreview = () => {
    if (loading) return <LoadingSpinner />
    if (!patientId || !patientLabel) return <></>

    return <Box
      sx={{
        border: '1px solid',
        borderColor: 'greys.dark',
        boxShadow: '5px 5px 5px 0px rgba(0, 0, 0, 0.25)'
      }}
    >
      <Box
        ref={componentRef}
        display={"flex"}
        justifyContent={"space-between"}
        padding={1}
        width={`${printWidthCM}cm`}
        height={`${printHeightCM-0.3}cm`}
      >
        <Box
          data-testid={'name-and-address'}
          display={"flex"}
          flexDirection={"column"}
          flexShrink={1}
        >
          <Typography variant={'p2'} lineclamp={2} sx={{fontWeight: 700}}>{patientDisplayName(patientLabel.firstName, patientLabel.lastName)}</Typography>
          <Typography variant={'p2'} lineclamp={1}>{patientLabel.street}</Typography>
          <Typography variant={'p2'} lineclamp={1}>{patientLabel.street2}</Typography>
          <Typography variant={'p2'} lineclamp={1}>{`${patientLabel.city || ""}, ${patientLabel.province || ""} ${postalCodeMask(patientLabel.postalCode) || ""}`}</Typography>
        </Box>
        {!addressOnly &&
          <Box
            data-testid={'other-details'}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-end"}
            flexGrow={1}
            sx={{minWidth:'40%'}}
          >
            <Typography variant={'p2'}>{`${patientLabel.primaryIdentifierLabel || "ID"}: ${patientLabel.primaryIdentifier || "None"}`}</Typography>
            <Typography variant={'p2'}>{patientLabel.gender?.name}</Typography>
            <Typography variant={'p2'}>{patientLabel.dob && moment(patientLabel.dob).format("YYYY/MM/DD")}</Typography>
            <Typography variant={'p2'}>{phoneNumberMask(patientLabel.primaryPhoneNumber)}</Typography>
          </Box>
        }
      </Box>
    </Box>
  }

  return <DialogV2
    size={'sm'}
    open={open}
    onClose={onModalClose}
    title={"Print patient label"}
    primaryAction={()=>null}
    overridePrimaryComponent={
      <PrintButton
        documentTitle={`Patient Label - ${patientDisplayName(patientLabel?.firstName, patientLabel?.lastName)}`}
        dataTestId={'patient-label'}
        name={'patient_label'}
        printWidth={`${printWidthCM}cm`}
        printHeight={`${printHeightCM}cm`}
        componentRef={componentRef}
        onComplete={onModalClose}
        objectType={UserActivityObjectType.PATIENT}
        objectId={patientId}
      />
    }
  >
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={1}
      alignItems={"center"}
    >
      <SimpleCheckbox
        label={'Address only'}
        dataTestId={'print-patient-address-only'}
        value={addressOnly}
        onChange={() => setAddressOnly(!addressOnly)}
      />
      <PatientLabelPreview />
    </Box>
  </DialogV2>
}