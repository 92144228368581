import React from "react";
import { TextFieldMask } from "saga-library/src";
import { useFormContext } from "saga-library/src/components/Form";
import { useWatch } from "saga-library/src/components/Form"
import { rules } from '../apps/settings/Practitioners/util/validation'

const PRAC_ID_MASK = '99999-9999'
const NO_MASK = '***********************************'
export const PractitionerMaskedTextField = ({dataTestId, name='practitionerId', label='Practitioner ID', disabled = false}) => {
  const {control} = useFormContext()

  const value = useWatch({control, name})

  return <TextFieldMask
    name={name}
    label={label}
    maxLength={rules.practitionerId.max}
    formatMask={isInputPotentialPractionerId(value)?PRAC_ID_MASK:NO_MASK}
    maskChar={""}
    dataTestId={dataTestId}
    disabled={disabled}
  />
}

export const formatPractitionerId = (pracId) => {
  if (isStringPotentialPractitionerId(pracId)) {
    return `${pracId.slice(0, 5)}-${pracId.slice(5)}`
  }

  return pracId
}

export const isStringPotentialPractitionerId = (inputString) => {
  if(inputString?.length > 9) {
    return false
  }
  const pattern = /^\d{9}$/

  return pattern.test(inputString)
}

export const isInputPotentialPractionerId = (inputString) => {
  const pattern = /^(\d{0,5})-?(\d{0,4})$/;

  return pattern.test(inputString);
}