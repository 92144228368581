

export const capitalizedWord = (word: string|undefined|null): string => {
  if (!word) return "";
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export const capitalizedWords = (words: string|undefined|null): string => {
  if (!words) return ""
  const segments = (new Intl.Segmenter("en-CA", { granularity: "word"})).segment(words)
  return Array.from(segments)
    .map(word => capitalizedWord(word.segment))
    .reduce((a, b) => a + b, "")
}

export const lowerCaseWord = (word: string|undefined|null): string => {
  if (!word) return "";
  return word.toLowerCase();
}