import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { DialogV2 } from 'saga-library/src'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { useMutation } from '@apollo/client'
import { CREATE_TASK_TEMPLATE, LIST_TASK_TEMPLATES } from '../../../../graphql-definitions'
import { TaskTemplateInput } from '../../../../types/tasks/TaskTemplate'
import { TaskTemplateForm } from './TaskTemplateForm'
import { taskTemplateDefaults } from './TaskTemplateDefaultValues'
import { schema } from './TaskTemplateValidationsSchema'
import _get from 'lodash/get'
import { convertTemplateInputToTemplateData } from './TaskTemplateUtil'

const FORM_NAME = "new_task_template_form"

export const NewTaskTemplateDialog = ({ open, setOpen }) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert, showErrorAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const [createTaskTemplate] = useMutation(CREATE_TASK_TEMPLATE)

  const formMethods = useForm<TaskTemplateInput>({
    defaultValues: taskTemplateDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const onClose = () => {
    setOpen(false)
    reset(taskTemplateDefaults)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = handleSubmit( async(input: TaskTemplateInput) => {
    const data = convertTemplateInputToTemplateData(input)
    await createTaskTemplate({
      variables: {
        tenantId: tenant_id,
        input: data
      },
      onCompleted: () => {
        showSuccessAlert("Task template has been saved.")
        onClose()
      },
      onError: (error: any) => {
        const errors = error.networkError?.result?.errors || []
        if (errors.length > 0 && errors[0].extensions?.userError === true) {
          showErrorAlert(errors[0].message)
        } else {
          showErrorAlert("Task template couldn't be created.")
        }
      },
      update: async (cache, { data }) => {
        const newTaskTemplate = _get(data, 'tenant.task.template.create', null)
        await cache.updateQuery({
          query: LIST_TASK_TEMPLATES,
          variables: {
            tenantId: tenant_id
          }
        }, (data) => {
          const existingTaskTemplates = _get(data, 'tenant.task.template.list', [])
          return {
            tenant: {
              task: {
                listTaskTypes: [...existingTaskTemplates, newTaskTemplate]
              }
            }
          }
        })
      }
    })
  })

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'new-task-template-dialog'}
      formName={FORM_NAME}
      size={'sm'}
      title={'New task template'}
      submitting={isSubmitting}
      onClose={onCancel}
      open={open}
    >
      <FormProvider {...formMethods}>
        <TaskTemplateForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"new-task-template-form"}
        />
      </FormProvider>
    </DialogV2>
  )
}