import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { TextField, Typography, RadioGroup, Section, Form } from "saga-library/src";
import { AppointmentDatePicker } from "../../../../components/AppointmentDatePicker";
import { ControlledAppointmentTypeSelect } from "../../../../components/AppointmentTypeSelect";
import { AppointmentMethod } from "../../../../types/schedule/Appointment";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { AppointmentStateSelect } from "../../../../components/AppointmentStateSelect";
import _get from "lodash/get";
import { PatientSearch } from "../../../../components/SearchControls/Patient/PatientSearch";
import { LIST_APPOINTMENT_TYPES } from "../../../../graphql-definitions";
import moment from "moment-timezone";
import { addDurationToDate } from "saga-library/src/util";

type AppointmentFormProps = {
  onSubmit: () => void
  preferredAppointmentTypes?: string[]
  newAppointment?: boolean
  dataTestId?: string
  formName: string
}

export const AppointmentForm = ({onSubmit, formName, preferredAppointmentTypes, newAppointment = true, dataTestId} : AppointmentFormProps) => {
  const { tenant_id } = useParams()
  const { control, setValue, getValues } = useFormContext()
  const [methodOptions, setMethodOptions] = useState<{label: string, value: AppointmentMethod}[]>([])
  const [originalAppointmentTypeId, setOriginalAppointmentTypeId] = useState<string>(getValues('appointmentTypeId'))

  const appointmentTypeId = useWatch({
    control,
    name: 'appointmentTypeId',
  })

  const {data: appointmentTypeList} = useQuery(LIST_APPOINTMENT_TYPES, {
    variables: { tenantId: tenant_id},
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  useEffect(() => {
    if (newAppointment && appointmentTypeId) {
      const appointmentType = _get(appointmentTypeList, 'tenant.schedule.type.listTypes', []).find(type => type.id === appointmentTypeId)
      if(appointmentType) {
        setValue('end', addDurationToDate(moment.duration(appointmentType.duration, 'minutes'), moment(getValues('start'))))
      } else {
        console.error('Appointment type id supplied, but unable to find appointment type')
      }
    }
  }, [newAppointment]);

  useEffect(() => {
    if (appointmentTypeList) {
      const appointmentType = _get(appointmentTypeList, 'tenant.schedule.type.listTypes', []).find(type => type.id === appointmentTypeId)
      updateMethodOptions(appointmentType)

      if (originalAppointmentTypeId !== appointmentTypeId) {
        setOriginalAppointmentTypeId(appointmentTypeId)
        if (appointmentType?.duration) {
          setValue('end', addDurationToDate(moment.duration(appointmentType.duration, 'minutes'), moment(getValues('start'))))
        }
      }
    }
  }, [appointmentTypeId, appointmentTypeList])

  const updateMethodOptions = (appointmentType) => {
    let options : {label: string, value: AppointmentMethod}[] = []

    if (!appointmentType || appointmentType.inPerson) {
      options.push({
        label: 'In person',
        value: AppointmentMethod.INPERSON
      })
    }
    if (!appointmentType || appointmentType.byPhone) {
      options.push({
        label: 'By phone',
        value: AppointmentMethod.PHONE
      })
    }
    if (!appointmentType || appointmentType.videoCall) {
      options.push({
        label: 'By video call',
        value: AppointmentMethod.VIDEO
      })
    }

    setMethodOptions(options)

    if (options.length !== 0 && (newAppointment || !options.some((option) => option.value === getValues('method')))) {
      setValue('method', options[0].value)
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      autoComplete={'false'}
      name={formName}
      id={formName}
    >
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Section.FormSection sx={{ maxWidth: "49%", mt: 2, mb: 0, overflowX: 'hidden', gap: '4px'}}>
          <PatientSearch
            dataTestId={`${dataTestId}-patient`}
            name={'patientId'}
            label={"Patient"}
            disabled={!newAppointment}
            defaultStyle={'expanded'}
            fetchDetails={true}
          />
        </Section.FormSection>
        <Section.FormSection sx={{ maxWidth: "49%", mt: 2, mb: 0 }}>
          <ControlledAppointmentTypeSelect
            dataTestId={`${dataTestId}-type`}
            label={'Type'}
            name={'appointmentTypeId'}
            limitToScheduleId={getValues('scheduleId')}
            preferredTypes={ preferredAppointmentTypes }
          />
          <Typography variant={'body1'} fontWeight={'bold'} sx={{mt: 2}}>
            Appointment will take place...
          </Typography>
          <RadioGroup dataTestId={`${dataTestId}-method`} name={'method'} row={true} options={methodOptions} />
          <AppointmentDatePicker dataTestId={dataTestId} />
          <AppointmentStateSelect
            dataTestId={`${dataTestId}-state`}
            label={'State'}
            name={'appointmentStateId'}
          />
          <TextField dataTestId={`${dataTestId}-reasonForVisit`} label={'Reason for visit'} name={'reasonForVisit'} />
          <TextField dataTestId={`${dataTestId}-notes`} label={'Notes'} name={'notes'} multiline={true} rows={5} />
        </Section.FormSection>
      </Box>
    </Form>
  )
}
