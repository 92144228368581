import React, { useEffect, useRef, useState } from 'react'
import { AbLabResult, AbLabResultUpdate } from '../../../../types/patients'
import { useForm } from 'saga-library/src/components/Form'
import { DialogV2 } from 'saga-library/src'
import { schema } from './LabAndInvestigationFormValidationSchema'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { LabAndInvestigationForm } from './LabAndInvestigationForm'
import { FormProvider } from 'react-hook-form'
import PermissionButton from '../../../../components/PermissionButton'
import { LabAndInvestigationLabel, LabAndInvestigationPatientLabel } from './components/LabAndInvestigationLabel'
import { useQuery } from '@apollo/client'
import { GET_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { FileType } from '../../../../types/tasks'
import { useParams } from 'react-router-dom'
import { LabAndInvestigationFileNavigation } from './components/LabAndInvestigationFileNavigation'
import { Box } from '@mui/material'
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { LabAndInvestigationPrintButton } from './LabAndInvestigationsPrintButton'

const FORM_NAME = "labs_and_investigations_modal_form"

export interface LabAndInvestigationModalProps {
  labId: string | null,
  open: boolean,
  onModalClose: () => void
}

export const LabAndInvestigationModal = ({
  labId,
  open,
  onModalClose
}:LabAndInvestigationModalProps) => {
  const { tenant_id, patient_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)
  const [ labAndInvestigation, setLabAndInvestigation ] = useState<AbLabResult | null>(null)
  const [ files, setFiles ] = useState<FileType[]>([])
  const [ file, setFile ] = useState<FileType | null>(null)

  const printComponentRef = useRef<any>(null)

  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema,
  })

  const {
    reset,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  const { loading } = useQuery(GET_LAB_AND_INVESTIGATION, {
    variables: {
      tenantId: tenant_id,
      patientId: patient_id,
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: async (data) => {
      const lab:AbLabResult = _get(data, 'tenant.patient.labAndInvestigation.get', null)

      if (lab) {
        const resultsWithEmbeddedFile = lab?.abLabResultObservationResults
          ?.filter(r => r.file)
          ?.map(r => r.file!)

        setFiles(resultsWithEmbeddedFile)
        resultsWithEmbeddedFile.length > 0 ? setFile(resultsWithEmbeddedFile[0]) : setFile(null)

        reset({
          id: lab.id,
          notes: lab.notes,
          version: lab.version
        })
      }

      setLabAndInvestigation(lab)
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onModalClose()
        reset(undefined)
      }
    }
  }

  useEffect(() => {
    if(open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onModalClose()
    }
  }


  return (
    <DialogV2
      className={"labsAndInvestigationModal"}
      printComponentRef={printComponentRef}
      title={!labAndInvestigation ? ""
        : <>
            <LabAndInvestigationLabel labAndInvestigation={labAndInvestigation}/>
            <LabAndInvestigationPatientLabel labAndInvestigation={labAndInvestigation}/>
          </>
      }
      open={open}
      size={"lg"}
      onClose={onCancel}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveLabAndInvestigation"}
          type={"submit"}
          form={FORM_NAME}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
          dataTestId={"editLabAndInvestigationModal-saveButton"}
        >
          Save
        </PermissionButton>
      }
      preventScroll={true}
      contentSx={{
        display: "flex",
        flexDirection: "column",
        paddingRight: 0,
        flex: "1 1 672px"
      }}
      headerActions={[
        <Box
          pr={"32px"}
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          {files.length > 1 ?
            <LabAndInvestigationFileNavigation
              files={files}
              selectedFile={file}
              setSelectedFile={setFile}
              dataTestId={"editLabAndInvestigationModal"}
            />
            : undefined}
        </Box>,
        <LabAndInvestigationPrintButton
          labAndInvestigation={labAndInvestigation}
          printComponentRef={printComponentRef}
          patientId={patient_id}
        />
      ]}
      dataTestId={"editLabAndInvestigationModal"}
    >
      <FormProvider {...formMethods}>
        {loading
          ? <LoadingSpinner />
          : <LabAndInvestigationForm
              dataTestId={"editLabAndInvestigationModal"}
              formName={FORM_NAME}
              file={file}
              labAndInvestigation={labAndInvestigation}
              onSuccess={onModalClose}
            />
        }
      </FormProvider>
    </DialogV2>
  )
}