import React from "react";
import { Box } from "@mui/material";
import { FormProvider, useForm, useFormContext, useWatch } from "saga-library/src/components/Form";
import PermissionButton from "../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../types/settings/Permission";
import { Checkbox, DialogV2, Typography } from "saga-library/src";
import FormattedDatePicker from "../../../components/FormattedDatePicker";
import { ScheduleTimeSelect } from "../../../components/ScheduleTimeSelect";
import { ControlledAppointmentTypeSelect } from "../../../components/AppointmentTypeSelect";
import { ScheduleSettingsType } from "../../../types/schedule";
import { useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../../components/LoadingScreen";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { forEach, omit } from "lodash";
import { GET_SCHEDULE, UPDATE_SCHEDULE } from "../../../graphql-definitions";
import { ControlledBillingProfileSelect } from "../../../components/BillingProfileSelect/BillingProfileSelect";
import { WEEKDAYSMAP } from "../../../utils/ScheduleConstants";
import * as yup from 'yup'
import _cloneDeep from "lodash/cloneDeep";
import { useScheduleContext } from "../../../providers/ScheduleContextProvider";

interface ScheduleSettingsDialogProps {
  onClose: () => void
  scheduleId?: string | null
}

const defaultValues = {
  practitionerId: '',
  endDate: '',
  sundayOpen: false,
  sundayStart: 'PT8H',
  sundayEnd: 'PT17H',
  mondayOpen: false,
  mondayStart: 'PT8H',
  mondayEnd: 'PT17H',
  tuesdayOpen: false,
  tuesdayStart: 'PT8H',
  tuesdayEnd: 'PT17H',
  wednesdayOpen: false,
  wednesdayStart: 'PT8H',
  wednesdayEnd: 'PT17H',
  thursdayOpen: false,
  thursdayStart: 'PT8H',
  thursdayEnd: 'PT17H',
  fridayOpen: false,
  fridayStart: 'PT8H',
  fridayEnd: 'PT17H',
  saturdayOpen: false,
  saturdayStart: 'PT8H',
  saturdayEnd: 'PT17H',
  defaultAppointmentTypeId: '',
  defaultAppointmentType: null,
  defaultBillingProfile: null,
  defaultBillingProfileId: ''
}

const daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const ScheduleSettingsDialog = ({
  onClose,
  scheduleId
}: ScheduleSettingsDialogProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { scheduleItemActions:{refetchScheduleItems} } = useScheduleContext()

  const { loading } = useQuery(GET_SCHEDULE, {
    variables: {
      tenantId: tenant_id,
      scheduleId: scheduleId
    },
    skip: !scheduleId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      let schedule = _cloneDeep(_get(data, 'tenant.schedule.schedule', []))

      forEach(daysOfTheWeek, (day) => {
        if (!schedule[day+'Open']) {
          schedule[day+'Start'] = defaultValues[day+'Start']
          schedule[day+'End'] = defaultValues[day+'End']
        }
      })
      reset(schedule)
    }
  })

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    onError: (error) => {
      showErrorAlert("Schedule couldn't be updated.")
      console.error('Unable to update schedule: ' + JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      showSuccessAlert(`Schedule updated.`)
      refetchScheduleItems()
      onClose()
    },
  })

  const formMethods = useForm<ScheduleSettingsType>({
    defaultValues,
    schema: yup.object({
      defaultBillingProfileId: yup
        .string()
        .transform((val) => val?.id)
        .nullable()
    })
  })

  const { reset, handleSubmit, formState: {isSubmitting}} = formMethods

  const onSubmit = handleSubmit(async (data) => {
    const input = omit(data, ['__typename', 'practitionerId'])
    await updateSchedule({
      variables: {
        tenantId: tenant_id,
        scheduleInput: input,
        scheduleId: scheduleId
      },
    })
  })

  const renderDialogContent = () => {
    if(loading) {
      return <LoadingSpinner/>
    }

    return (
      <FormProvider {...formMethods}>
        <ScheduleSettingsForm scheduleId={scheduleId}/>
      </FormProvider>
    )
  }

  return (
    <DialogV2
      open={!!scheduleId}
      onClose={onClose}
      title={'Schedule settings'}
      size={'lg'}
      overridePrimaryComponent={
        <PermissionButton
          dataTestId={"scheduleSettings-save"}
          name={'saveScheduleSettings'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
        >
          Save
        </PermissionButton>
      }
      showActions={!loading}
      showCancelButton={false}
    >
      {renderDialogContent()}
    </DialogV2>
  )
}

const ScheduleSettingsForm = ({scheduleId}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography variant={'h3'}>Schedule availability</Typography>
      <Typography variant={'p4'}>See how far into the future appointments can be booked on this schedule.</Typography>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          my: 2,
        }}
      >
        <Typography variant={'p4'}>Appointments can be booked on this schedule until </Typography>
        <FormattedDatePicker
          dataTestId={"scheduleSettings-endDate"}
          label={''}
          name={'endDate'}
          sx={{
            ml: 1,
          }}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography variant={'h3'}>Default work days and hours</Typography>
        <Typography variant={'p4'}>Set default available days and hours for this schedule when opening more availability. Templates will override these settings.</Typography>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          alignItems: 'flex-start',
          mb: 2,
          gap: '16px'
        }}
      >
        {WEEKDAYSMAP.map((day, index) => (
          <DayAvailability dataTestId={`scheduleSettings-day-${index}`} name={day[1]} key={day[1]+`_availability`}/>
        ))}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography variant={'h3'}>Default appointment type (optional)</Typography>
        <Typography variant={'p4'}>Select an appointment type to automatically assign to new appointments on this schedule.</Typography>
        <Box
          sx={{
            mt: 1,
          }}
        >
          <ControlledAppointmentTypeSelect
            dataTestId={"scheduleSettings-appointmentType"}
            name={'defaultAppointmentTypeId'}
            label={'Appointment type'}
            sx={{width: '30%'}}
            limitToScheduleId={scheduleId}
          />
        </Box>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        <Typography variant={'h3'}>Default billing profile (optional)</Typography>
        <Typography variant={'p4'}>Select a billing profile to automatically assign to new claims created from appointment on this schedule.</Typography>
        <Box
          sx={{
            mt: 1,
          }}
        >
          <ControlledBillingProfileSelect
            dataTestId={"scheduleSettings-billingProfile"}
            name={'defaultBillingProfileId'}
            label={'Billing Profile'}
            sx={{width: '30%'}}
          />
        </Box>
      </Box>
    </Box>
  )
}

interface DayAvailabilityProps {
  name: string,
  dataTestId?: string
}

export const DayAvailability = ({
  name,
  dataTestId
}: DayAvailabilityProps) => {
  const { getValues, setValue, resetField } = useFormContext()
  const dayIsOpen = useWatch({ name: `${name}Open` })

  const StartEndTime = () => {
    if (dayIsOpen) {
      return(
        <>
          <ScheduleTimeSelect dataTestId={`${dataTestId}-startTime`} name={`${name}Start`} label={'Start time'} />
          <ScheduleTimeSelect dataTestId={`${dataTestId}-endTime`} name={`${name}End`} label={'End time'} />
        </>
      )
    }
    return (
      <Typography  dataTestId={`${dataTestId}-noAvailableHours`} sx={{mt: 1, textAlign: 'center'}} variant={'p4'}>No available hours</Typography>
    )
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Checkbox
        dataTestId={`${dataTestId}-checkbox`}
        name={name+'Open'}
        label={<Typography variant={'p4'} fontWeight={'700'} sx={{ textTransform: 'capitalize' }}>{name}</Typography>}
        labelPlacement={'bottom'}
        onSelect={() => {
          if (getValues(`${name}Open`)) {
            resetField(`${name}`)
          }
        }}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ width: '100%' }}
      >
        <StartEndTime />
      </Box>
    </Box>
  )
}
