import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { SimpleTextField } from '../TextField'
import { Box } from '@mui/system'
import { SimpleTextFieldProps } from "../TextField/TextField";

export const SearchField = (props: SimpleTextFieldProps) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <SimpleTextField
        {...props}
        placeholder={props.placeholder ?? 'Search...'}
        InputProps={{
          startAdornment: <SearchIcon sx={{ color: 'gray' }} />,
          style: {height: '37px'}
        }}
        onChange={props.onChange}
        value={props.value}
        hideHelperText={true}
        sx={{
          margin: '8px 0',
          ...props.sx
      }}
      />
    </Box>
  )
}

export default SearchField
