import React, { useEffect, useState } from 'react'
import SettingsHeader from "../../components/SettingsHeader";
import { SettingsSectionColumn } from "../../components/SettingsSectionColumn";
import PermissionButton from '../../../../components/PermissionButton'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import {
  DraggableTableList,
  TableListHeaderConfig,
  TableListRowConfig,
  Typography
} from 'saga-library/src'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { LIST_TASK_STATES, UPDATE_TASK_STATE_SORT } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { TaskState } from '../../../../types/tasks/TaskState'
import { capitalizedWord } from '../../../../utils/StringHelpers'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { NewTaskStateModal } from './NewTaskStateModal'
import { EditTaskStateModal } from './EditTaskStateModal'
import { TaskProvider, useTaskContext } from '../../../../providers/TaskProvider'

const columns: TableListHeaderConfig[] = [
  { name: 'Name' },
  { name: 'Category' },
]

const TaskStateRow = (taskState: TaskState, onClick: ()=>void): TableListRowConfig => {
  return ({
    onClick: onClick,
    key: taskState.id,
    rowData: [
      {
        children:
          <Typography
            key={`${taskState.id}-name`}
            variant={'body2'}
            minWidth={200}
          >
            {taskState.name}
          </Typography>

      },
      {
        children:
          <Typography
            key={`${taskState.id}-category`}
            variant={'body2'}
          >
            {capitalizedWord(taskState.category)}
          </Typography>

      }
    ]
  })
}

export const TaskStates = () => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [taskStates, setTaskStates] = useState<TaskState[] | null>(null)
  const [newTaskStateOpen, setNewTaskStateOpen] = useState<boolean>(false)
  const [selectedState, setSelectedState] = useState<TaskState | null>(null)
  const { taskStates:states } = useTaskContext()

  useEffect(() => {
    console.log("tasks updated")
    setTaskStates(states.filter(s=>s.active))
  }, [states])

  const [updateTaskState] = useMutation(UPDATE_TASK_STATE_SORT, {
    onCompleted: (data) => {
      showSuccessAlert('Task state order has been updated.')
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Task state order couldn't be updated.")
    }
  })

  const updateSort = async(taskState, index, newList) => {
    await updateTaskState({
      variables: {
        tenantId: tenant_id,
        taskStateId: taskState.id,
        version: taskState.version,
        index: index
      },
      update(cache) {
        cache.writeQuery({
          query: LIST_TASK_STATES,
          data: {
            tenant: {
              task: {
                state: {
                  list: newList
                }
              }
            }
          },
          variables: {
            tenantId: tenant_id,
          },
        })
      }
    })
  }

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Task states'}
        dataTestId={'task-states'}
        actions={
          <PermissionButton
            name={'taskStates-newState'}
            onClick={() => setNewTaskStateOpen(true)}
            dataTestId={'taskStates-newState-button'}
            requiredType={PermissionType.Admin}
            requiredPermission={Permission.READONLY}
          >
            NEW STATE
          </PermissionButton>
        }
      />
    }>
      <DraggableTableList<TaskState>
        size={'small'}
        columns={columns}
        updateListItems={setTaskStates}
        onDragEnd={updateSort}
        sx={{ mt: 2, height: "calc(100vh - 216px)" }}
        listItems={taskStates}
        configureRow = {(taskState) => {
          return TaskStateRow(taskState, ()=>setSelectedState(taskState))
        }}
        dataTestId={'taskStates-draggable-list'}
      />
      <NewTaskStateModal
        open={newTaskStateOpen}
        setOpen={setNewTaskStateOpen}
      />
      <EditTaskStateModal
        taskState={selectedState}
        onClose={() => setSelectedState(null)}
      />

    </SettingsSectionColumn>
  )
}