import React from 'react'

export const checkDiagnosticCodeArrays = (selectedClaims) => {
  if (selectedClaims.length !== 0) {
    let diagnosticCodeObjectArray = selectedClaims.map(sc => sc.diagnosticCodes)
    let firstClaim = (diagnosticCodeObjectArray[0].map(dc => dc.id))
    let diagnosticCodeArray = diagnosticCodeObjectArray.map(a => a.map(dc => dc.id))
    return diagnosticCodeArray.every(x => containsAll(x, firstClaim) && containsAll(firstClaim, x))
  }
  return false
}

export const checkServiceCodeFeeModifiers = (selectedClaims) => {
  if (selectedClaims.length !== 0) {
    let feeModifierObjectArray = selectedClaims.map(sc => sc.serviceCodeFeeModifiers)
    let firstClaim = (feeModifierObjectArray[0].map(sc => sc.id))
    let feeModifierArray = feeModifierObjectArray.map(a => a.map(fm => fm.id))
    return feeModifierArray.every(x => containsAll(x, firstClaim) && containsAll(firstClaim, x))
  }
  return false
}

const containsAll = (arr1, arr2) => {
  return  arr2.every(i => arr1.includes(i))
}

export const setAbClaimMultiple = (selectedClaims) => {
  const firstClaim = selectedClaims[0]
  return (
    {
      billingProfile: !selectedClaims.every(c => c.billingProfile.id === firstClaim.billingProfile.id),
      patient: !selectedClaims.every(c => c.patient.id === firstClaim.patient.id),
      serviceDate: !selectedClaims.every(c => c.serviceDate === firstClaim.serviceDate),
      serviceCode: !selectedClaims.every(c => c.serviceCode.serviceCode === firstClaim.serviceCode.serviceCode),
      diagnosticCodes: !checkDiagnosticCodeArrays(selectedClaims),
      serviceCodeFeeModifiers: !checkServiceCodeFeeModifiers(selectedClaims),
      calls: !selectedClaims.every(c => c.calls === firstClaim.calls),
      encounter: !selectedClaims.every(c => c.encounter === firstClaim.encounter),
      claimAmount: !selectedClaims.every(c => c.claimAmount === firstClaim.claimAmount),
      facility: !selectedClaims.every(c => c.facility?.id === firstClaim.facility?.id),
      functionalCenter: !selectedClaims.every(c => c.functionalCenter?.id === firstClaim.functionalCenter?.id),
      locationCode: !selectedClaims.every(c => c.locationCode !== null && c.locationCode === firstClaim.locationCode),
      referralPractitioner: !selectedClaims.every(c => c.referralPractitioner?.id === firstClaim.referralPractitioner?.id),
      text: !selectedClaims.every(c => c.text === firstClaim.text),
      paperDocumentation: !selectedClaims.every(c => c.paperDocumentation === firstClaim.paperDocumentation),
      confidential: !selectedClaims.every(c => c.confidential === firstClaim.confidential),
      originalEncounterDate: !selectedClaims.every(c => c.originalEncounterDate === firstClaim.originalEncounterDate),
      originalFacilityId: !selectedClaims.every(c => c.originalFacility?.id === firstClaim.originalFacility?.id),
      originalLocationCode: !selectedClaims.every(c => c.originalLocationCode !== null && c.originalLocationCode === firstClaim.originalLocationCode),
      newBornCode: !selectedClaims.every(c => c.newBornCode === firstClaim.newBornCode),
      guardianUli: !selectedClaims.every(c => c.guardianUli === firstClaim.guardianUli),
      guardianRegNumber: !selectedClaims.every(c => c.guardianRegNumber === firstClaim.guardianRegNumber),
      skillId: !selectedClaims.every(c => c.skill?.id === firstClaim.skill?.id),
      payToCode: !selectedClaims.every(c => c.payToCode === firstClaim.payToCode),
      payToUli: !selectedClaims.every(c => c.payToUli === firstClaim.payToUli),
      interceptReason: !selectedClaims.every(c => c.interceptReason === firstClaim.interceptReason),
      feeMod1Units: !selectedClaims.every(c => c.feeMod1Units === firstClaim.feeMod1Units),
      feeMod2Units: !selectedClaims.every(c => c.feeMod2Units === firstClaim.feeMod2Units),
      feeMod3Units: !selectedClaims.every(c => c.feeMod3Units === firstClaim.feeMod3Units),
    }
  )
}

export const setBatchAbClaimValues = (abClaimMultiple, selectedClaims, setValue) => {
  const firstClaim = selectedClaims[0]

  if (!abClaimMultiple.billingProfile) {
    setValue('billingProfile', firstClaim.billingProfile)
  }
  if (!abClaimMultiple.patient) {
    setValue('patient', firstClaim.patient)
  }
  if (!abClaimMultiple.serviceDate) {
    setValue('serviceDate', firstClaim.serviceDate)
  }
  if (!abClaimMultiple.serviceCode) {
    setValue('serviceCode', firstClaim.serviceCode)
  }
  if (!abClaimMultiple.diagnosticCodes) {
    let diagnosticCodes = firstClaim.diagnosticCodes
    if (diagnosticCodes.length !== 0) {
      setValue('diagnosticCodes', diagnosticCodes)
    }
  }
  if (!abClaimMultiple.serviceCodeFeeModifiers) {
    let serviceCodes = firstClaim.serviceCodeFeeModifiers
    if (serviceCodes.length !== 0) {
      setValue('serviceCodeFeeModifiers', serviceCodes)
    }
  }
  if (!abClaimMultiple.calls) {
    setValue('calls', firstClaim.calls)
  }
  if (!abClaimMultiple.encounter) {
    setValue('encounter', firstClaim.encounter)
  }
  if (!abClaimMultiple.claimAmount) {
    setValue('claimAmount', firstClaim.claimAmount)
  }
  if (!abClaimMultiple.facility) {
    setValue('facility', firstClaim.facility)
  }
  if (!abClaimMultiple.functionalCenter) {
    setValue('functionalCenter', firstClaim.functionalCenter)
  }
  if (!abClaimMultiple.locationCode) {
    setValue('locationCode', firstClaim.locationCode)
  }
  if (!abClaimMultiple.referralPractitioner) {
    setValue('referralPractitioner', firstClaim.referralPractitioner)
  }
  if (!abClaimMultiple.text) {
    setValue('text', firstClaim.text)
  }
  if (!abClaimMultiple.paperDocumentation) {
    setValue('paperDocumentation', firstClaim.paperDocumentation)
  }
  if (!abClaimMultiple.confidential) {
    setValue('confidential', firstClaim.confidential)
  }
  if (!abClaimMultiple.originalEncounterDate) {
    setValue('originalEncounterDate', firstClaim.originalEncounterDate)
  }
  if (!abClaimMultiple.originalFacilityId) {
    setValue('originalFacility', firstClaim.originalFacility)
  }
  if (!abClaimMultiple.originalLocationCode) {
    setValue('originalLocationCode', firstClaim.originalLocationCode)
  }
  if (!abClaimMultiple.newBornCode) {
    setValue('newBornCode', firstClaim.newBornCode)
  }
  if (!abClaimMultiple.guardianUli) {
    setValue('guardianUli', firstClaim.guardianUli)
  }
  if (!abClaimMultiple.guardianRegNumber) {
    setValue('guardianRegNumber', firstClaim.guardianRegNumber)
  }
  if (!abClaimMultiple.skillId) {
    setValue('skillId', firstClaim.skill || null)
  }
  if (!abClaimMultiple.payToCode) {
    setValue('payToCode', firstClaim.payToCode)
  }
  if (!abClaimMultiple.payToUli) {
    setValue('payToUli', firstClaim.payToUli)
  }
  if (!abClaimMultiple.interceptReason) {
    setValue('interceptReason', firstClaim.interceptReason)
  }
}