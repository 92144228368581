import { FormProvider, useForm } from "saga-library/src/components/Form";
import React, { useState, useEffect } from "react";
import { DialogV2 } from "saga-library/src";
import { PharmacyForm } from "../../../apps/settings/Pharmacies/PharmacyForm";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { CREATE_PHARMACY, LIST_PHARMACIES } from "../../../graphql-definitions";
import _get from "lodash/get";
import { schema } from "../../../apps/settings/Pharmacies/util/validation";
import { PharmacyInput } from "../../../types";
import { pharmacyDetailsDefaults, PharmacyDetailsType } from "../../../apps/settings/Pharmacies/PharmacyNew";

const FORM_NAME = 'pharmacy_modal_form'

export const PharmacyModal = ({ open, onModalClose }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset(pharmacyDetailsDefaults)
      onModalClose()
    }
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset(pharmacyDetailsDefaults)
        onModalClose()
      }
    }
  }

  const formMethods = useForm<PharmacyDetailsType>({
    defaultValues: pharmacyDetailsDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [createPharmacy] = useMutation(
    CREATE_PHARMACY,
    {
      onCompleted: (data) => {
        const newPharmacy = _get(data, 'tenant.pharmacy.create')
        showSuccessAlert(`Pharmacy has been added.`)
        reset(pharmacyDetailsDefaults)
        onModalClose(newPharmacy)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert('Pharmacy couldn\'t be created.')
      },
    }
  )

  const onSubmit = handleSubmit(async (data) => {
    const {street, ...rest} = data
    const newPharmacy: PharmacyInput = {
      ...rest,
      street1:street
    }

    await createPharmacy(({
      variables: {
        tenantId: tenant_id,
        input: newPharmacy
      },
      update(cache, returnedData) {
        cache.modify({
          fields: {
            list(existingPharmacies = []) {
              const newPharmacy = _get(returnedData, 'tenant.pharmacy.create')
              cache.writeQuery({
                query: LIST_PHARMACIES,
                data: {
                  tenant: {
                    pharmacy: {
                      list: [ ...existingPharmacies, newPharmacy ]
                    }
                  }
                }
              })
            }
          }
        })
      }
    }))
  })

  return (
    <DialogV2
      open={open}
      onClose={handleClose}
      title={"New pharmacy"}
      formName={FORM_NAME}
      submitting={isSubmitting}
      dataTestId={"pharmacyNew"}
    >
      <FormProvider {...formMethods}>
        <PharmacyForm formName={FORM_NAME} onSubmit={onSubmit} width={'416px'}/>
      </FormProvider>
    </DialogV2>
  )
}