import { Box } from "@mui/material";
import { Typography } from "saga-library/src";
import React from "react";
import { GhostInspectorNewEvent } from "./GhostInspectorNewEvent";
import { styled } from "@mui/system";

export const ScheduleBlockEvent = ({eventData}) => {
  const type = eventData?.__typename
  if (type === "BookingPreference") {
    return (
    <BookingPreferencesEvent eventData={eventData}/>
    )
  }
  else if (type === "ScheduleEvent" || type === 'TemplateEvent'){
    return (
      <Event eventData={eventData}/>
    )
  } else if (type === 'TemplateAppliedEvent') {
    return (
      <TemplateAppliedEvent eventData={eventData}/>
    )
  }
  return (
    <Box className={"md-custom-event-cont"}>Unknown event</Box>
  )
}

export const TemplateAppliedEvent = ({eventData}) => {
  return (
    <Box
      className={"md-custom-event-cont"}
      key={eventData.id}
      sx={{
        my: "1px",
        height: `calc(100% - 1px)`,
        overflow: 'hidden'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'start',
          border: 1,
          borderRadius: '0 4px 4px 0',
        }}
      >
        <EventTypography variant={'p3'} fontWeight={'bold'} sx={{ml: '4px'}}>{eventData.title}</EventTypography>
      </Box>
      <GhostInspectorNewEvent />
    </Box>
  )
}

export const Event =({eventData}) => {
  return (
    <Box
      className={"md-custom-event-cont"}
      key={eventData.id}
      sx={{
        my: "1px",
        height: `calc(100% - 1px)`,
        overflow: 'hidden'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          height: '100%',
          justifyContent: 'center',
          background: 'repeating-linear-gradient(-45deg, #f3f3f3, #f3f3f3 11px, #e5e5e5 11px, #e5e5e5 22px)',
          borderRadius: '0 4px 4px 0',
          textAlign: eventData.allDay ? "left" : "center",
          whiteSpace: "nowrap",
          px: 1
        }}
      >
        <EventTypography variant={'p3'} fontWeight={'bold'} lineclamp={1} sx={{ml: eventData.allDay ? '4px' : 0}}>{eventData.title}</EventTypography>
      </Box>
      <GhostInspectorNewEvent />
    </Box>
  )
}

const EventTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.text.secondary
}))

export const BookingPreferencesEvent =({eventData}) => {
  const appointmentTypes = eventData.appointmentTypes.map(at => at.name)

  return (
    <Box
      className={"md-custom-event-cont"}
      sx={{
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          backgroundColor: 'rgba(220, 223, 229, 0.38)',
          height: '100%',
          px: '4px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant={'p3'} fontWeight={'bold'}>{eventData.title}</Typography>
        <Typography variant={'p3'}>
          {appointmentTypes.join(', ')}
        </Typography>
      </Box>
      <GhostInspectorNewEvent />
    </Box>
  )
}
