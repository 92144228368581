import * as yup from 'yup'
import { PostalCodeValidation } from 'saga-library/src/components/PostalCodeField'
import { PhoneValidation } from 'saga-library/src/components/PhoneField'
import _get from 'lodash/get'

export const rules = {
  firstName: { max: 100 },
  lastName: { max: 100 },
  middleName: { max: 100 },
  email: { max: 320 },
  phones: {
    number: { max: 15 },
    notes: { max: 48 },
    ext: { max: 15 },
  },
  address: {
    street: { max: 100 },
    city: { max: 100 },
    province: { max: 2 },
    postalCode: { max: 10 },
    country: { max: 100 }
  },
  inactiveReason: { max: 48 }
}

export const maxLengthMessage = (value) => `Max length of ${value}`

export const nullIfEmpty = (value) => (!value ? null : value)

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    lastName: yup
      .string()
      .max(rules.lastName.max, maxLengthMessage(rules.lastName.max))
      .required('Required'),
    firstName: yup
      .string()
      .max(rules.firstName.max, maxLengthMessage(rules.firstName.max))
      .required('Required'),
    middleName: yup
      .string()
      .max(rules.middleName.max, maxLengthMessage(rules.middleName.max))
      .nullable(),
    dob: yup
      .date()
      .typeError("Invalid date")
      .nullable(),
    email: yup
      .string()
      .max(rules.email.max, maxLengthMessage(rules.email.max))
      .email('Invalid email')
      .nullable(),
    phones: yup.array().of(
      yup
        .object()
        .shape({
          id: yup.string().nullable(),
          type: yup.string().required('Required'),
          number: yup
            .string()
            .max(
              rules.phones.number.max,
              maxLengthMessage(rules.phones.number.max)
            )
            .matches(PhoneValidation.regex, 'Invalid phone number')
            .required('Required'),
          notes: yup
            .string()
            .max(
              rules.phones.notes.max,
              maxLengthMessage(rules.phones.notes.max)
            ),
          extension: yup
            .string()
            .max(rules.phones.ext.max, maxLengthMessage(rules.phones.ext.max)),
        })
        .nullable()
    ),
    street: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    street2: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    street3: yup
      .string()
      .max(rules.address.street.max, maxLengthMessage(rules.address.street.max))
      .nullable(),
    city: yup
      .string()
      .max(rules.address.city.max, maxLengthMessage(rules.address.city.max))
      .nullable(),
    province: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    postalCode: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable()
      .matches(PostalCodeValidation.regex, 'Invalid postal code'),
    country: yup
      .string()
      .max(rules.address.country.max, maxLengthMessage(rules.address.country.max)),
    inactiveDate: yup
      .date()
      .typeError("Invalid date")
      .nullable(),
    inactiveReason: yup
      .string()
      .max(rules.inactiveReason.max, maxLengthMessage(rules.inactiveReason.max))
      .nullable(),
  })
  .required()
