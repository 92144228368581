import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { TaskForm } from './TaskForm'
import { taskDefaults } from './TaskDefaultValues'
import { schema } from './TaskValidationsSchema'
import { LoadingButton, Section } from 'saga-library/src'
import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useUpdateCount } from '../../../../hooks/useUpdateCount'
import { flushSync } from 'react-dom'

const FORM_NAME = "new_task_form"

export const NewTask = ({}) => {
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const { buildTenantRoute } = useAccountContext()
  const { createTask } = useTaskContext()
  const { updateTaskCount } = useUpdateCount()
  const navigate = useNavigate()
  const { user_id, role_id, tenant_id } = useParams()

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `tasks/t/new`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  const onCreateComplete = (data) => {
    if((role_id && data.assignedRoleId === role_id) || (user_id && data.assignedUserId === user_id)){
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
      updateTaskCount(1)
      navigate(buildTenantRoute(`inbox/${role_id? `r/${role_id}`: `u/${user_id}`}/tasks/t/${data.id}`, tenant_id))
    } else {
      reset( undefined, {keepDefaultValues: true})
    }
  }

  const onSave = handleSubmit(async (data) => {
    let inputData: TaskAssignmentInput = {...data}
    delete inputData.assignedTo
    inputData.assignedRoleId = data.assignedTo?.type === 'role'? data.assignedTo?.value : null
    inputData.assignedUserId = data.assignedTo?.type === 'user'? data.assignedTo?.value : null

    await createTask(inputData, onCreateComplete, { tenant_id, user_id, role_id })
  })

  return (
    <Section.Column
      rightPadding={1}
      sx={{ flex: "1 1 100%" }}
      headerLabel={"New Task"}
      headerProps={{
        primaryAction: <LoadingButton
          name={'new-task-save'}
          dataTestId={'new-task-save'}
          variant={'contained'}
          type={"submit"}
          form={FORM_NAME}
          loading={isSubmitting}
        >
          Save
        </LoadingButton>
      }}>
      <FormProvider {...formMethods}>
        <TaskForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"task-editor-form"}
        />
      </FormProvider>
    </Section.Column>
  )
}