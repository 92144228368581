import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EmptyList, Section } from 'saga-library/src'
import { PatientSectionHeader } from "../PatientSectionHeader";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import PermissionButton from "../../../../components/PermissionButton";
import { EncounterNotesRow } from "./EncounterNotesRow";
import { useNavigate, useParams } from 'react-router-dom'
import { useEncounterNoteContext } from '../../providers/EncounterNoteProvider'
import { useReadQuery } from '@apollo/client'
import { ChartPanelHOC } from '../../util/ChartPanelHOC'
import { useAccountContext } from '../../../../providers/AccountContext'
import { Box } from '@mui/material'
import { useAppointmentContext } from '../../providers/AppointmentProvider'
import moment from 'moment-timezone'
import { PatientAppointmentDialog } from './PatientAppointmentDialog'
import { EncounterNote, PatientAppointment } from '../../../../types/patients'
import { ENCOUNTER_NOTES_SCROLL_KEY, useUserInteraction } from '../../../../providers/UserInteractionContext'
import { StyledTableContainer } from 'saga-library/src/components/TableList/TableContainer'

const sectionTitle = "Encounter notes"

export const EncounterNotes = ChartPanelHOC(sectionTitle, () => {
  const { encounterNoteQueryRef } = useEncounterNoteContext()
  return encounterNoteQueryRef && <EncounterNotesPanel/>
})

export const EncounterNotesPanel = () => {
  const { tenant_id, patient_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { getScrollPosition, saveScrollPosition } = useUserInteraction()

  let url = buildTenantRoute(`patients/p/${patient_id}/encounter-notes/new?`, tenant_id);

  const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false)

  const { encounterNoteQueryRef, parseEncounterNoteQueryResults } = useEncounterNoteContext()
  const { data: encounterNoteData } = useReadQuery(encounterNoteQueryRef!)
  const encounterNotes = useMemo(() => parseEncounterNoteQueryResults(encounterNoteData), [encounterNoteData, parseEncounterNoteQueryResults])

  const { appointmentQueryRef, getAppointmentQueryResults} = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef!)
  const patientAppointments = useMemo(() => getAppointmentQueryResults(appointmentData), [appointmentData, getAppointmentQueryResults])

  const listRef = useRef<HTMLDivElement>(null)

  const buildUrl = (appointment: PatientAppointment) => {
    url += `appointment_id=${appointment.id}`
    if(!!appointment.practitioner){
      url += `&practitioner_id=${appointment.practitioner?.id}`
    }

    return url
  }

  const onNew = () => {
    const dateNow = moment();
    let appointments = patientAppointments.filter(appointment => dateNow.isSame(appointment.start, 'day'));
    if (appointments.length === 1) {
      let appointment = appointments[0];
      navigate(buildUrl(appointment))
      return
    }

    setAppointmentDialogOpen(true)
  }

  const onPatientAppointmentModalClosed = useCallback(() => {
    setAppointmentDialogOpen(false)
  }, [setAppointmentDialogOpen])

  const onPatientAppointmentSelected = (appointment: PatientAppointment|null) => {
    setAppointmentDialogOpen(false)
    if(!!appointment)
    {
      navigate(buildUrl(appointment))
      return
    }

    navigate(url)
  }

  useEffect(() => {
    if (listRef?.current) {
      listRef.current.scrollTop = getScrollPosition(ENCOUNTER_NOTES_SCROLL_KEY);
    }
  }, [ENCOUNTER_NOTES_SCROLL_KEY, getScrollPosition]);

  const onRowClicked = (document: EncounterNote) => {
    if (listRef?.current) {
      saveScrollPosition(ENCOUNTER_NOTES_SCROLL_KEY, listRef.current.scrollTop);
    }

    navigate(buildTenantRoute(`patients/p/${patient_id}/encounter-notes/${document.isLinkedDocument ? "d" : "e"}/${document.id}`, tenant_id))
  }

  return <Section.Column
    header={
      <PatientSectionHeader
        dataTestId={'encounter-notes-list'}
        sectionLabel={'Encounter notes'}
        showSave={false}
        actions={
          <PermissionButton
            name={"new"}
            requiredType={PermissionType.Chart}
            requiredPermission={Permission.READWRITE}
            onClick={onNew}
            dataTestId={"encounter-notes-new-button"}
          >
            New
          </PermissionButton>
        }
      />
    } >
    {encounterNotes.length > 0 ? (
      <StyledTableContainer
        tableContainerRef={listRef}
        data-testid={'encounter-notes-list-container'}
      >
        <Box
          pr={1}
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          boxSizing={"border-box"}
          width={"100%"}
        >
          {encounterNotes
            .map((document, index) =>
              <EncounterNotesRow
                onRowClicked={() => onRowClicked(document)}
                encounterNote={document}
                key={`encounter_note_${document.isLinkedDocument?"l":"e"}_${document.id}`}
                dataTestId={`encounter-notes-row-${index}`}
              />
            )}
        </Box>
      </StyledTableContainer>
    ):(
      <EmptyList message={"Encounter notes appear here once added"} />
    )}
    <PatientAppointmentDialog
      openDialog={appointmentDialogOpen}
      patientAppointments={patientAppointments}
      onSelected={onPatientAppointmentSelected}
      onModalClose={onPatientAppointmentModalClosed}
    />
  </Section.Column>
}