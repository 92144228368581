import { TaskPriority } from '../../../../types/tasks/TaskPriority'
import { TaskTemplateInput } from '../../../../types/tasks/TaskTemplate'

export const taskTemplateDefaults: TaskTemplateInput = {
  name: "",
  description: "",
  taskName: "",
  typeId: "",
  assignedTo: undefined,
  priority: TaskPriority.MEDIUM,
  practitionerId: undefined,
  startOffset: 0,
  endOffset: 0,
  version: "0"
}