import React, { useEffect, useState, Suspense } from "react";
import { LoadingOverlay } from "../../components/LoadingScreen";
import { Outlet, useParams } from 'react-router-dom'
import { useApolloClientContext, Apollo } from '../../providers/Apollo'


export const PatientBase = () => {
  const { patientClient } = useApolloClientContext()
  const { patient_id } = useParams()
  const [currentPatientId, setCurrentPatientId] = useState<string|undefined>(undefined)

  useEffect(() => {
    patientClient.clearStore()
    setCurrentPatientId(patient_id)
    return () => {
      setCurrentPatientId(undefined)
      patientClient.clearStore()
    }
  }, [patient_id])


  if (currentPatientId == null || currentPatientId !== patient_id){
    return null
  }

  return (
    <Apollo client={patientClient}>
      <Suspense fallback={<LoadingOverlay loading={true} message={"Loading..."}/>}>
        <Outlet/>
      </Suspense>
    </Apollo>
  )
}