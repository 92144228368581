import { Section, Typography } from 'saga-library/src'
import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ReportSelect } from './components/ReportSelect'
import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined'
import { Box } from '@mui/material'

export const Reports = () => {
  const { report_id, category_type } = useParams()

  return (
    <Section.Container>
      <ReportSelect />
      {!!report_id || !!category_type
        ? <Outlet />
        : <Section.Column sx={{ flex: "1 1 auto" }}>
            <Box sx={{height:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
              <AnalyticsIcon sx={{fontSize: 100, color:"greys.medium"}}/>
              <Typography variant={"body1"} color={"greys.medium"}>Select a report to view</Typography>
            </Box>
          </Section.Column>
      }
    </Section.Container>
  )
}