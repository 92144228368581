import moment from "moment-timezone";
import {
  WEEKDAYSMAP,
  CLOSED_HOURS_COLOUR,
  CLOSED_SCHEDULE_COLOUR
} from "../../../utils/ScheduleConstants";
import { MbscCalendarEvent } from "@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types";
import { ScheduleLength } from "../../../types/schedule/Schedule";
import { addDurationToDate, diffDates } from "saga-library/src/util";
import ReactDOM from "react-dom";
import React, { MutableRefObject } from "react";
import { GET_SCHEDULE_ITEMS } from '../../../graphql-definitions'
import _cloneDeep from 'lodash/cloneDeep'

export const getTemplateLength = (template) => {
  if (template.templateDays.length === 1) {
    return ScheduleLength.DAY
  } else if (template.templateDays.length === 7) {
    return ScheduleLength.WEEK
  } else {
    throw new Error('Unsupported template length')
  }
}

export const fixMobiscrollDateOutput = (datetime) => {
  const year = datetime.getFullYear().toString()
  const month = (datetime.getMonth() + 1).toString().padStart(2, '0')
  const day = datetime.getDate().toString().padStart(2, '0')
  const hours = datetime.getHours().toString().padStart(2, '0')
  const minutes = datetime.getMinutes().toString().padStart(2, '0')
  return moment(`${year}-${month}-${day} ${hours}:${minutes}:00`, "YYYY-MM-DD HH:mm:SS").toISOString()
}

export const parseTime = (time, date) => {
  return overwriteTimeOnMoment(time, date).utc().toISOString()
}

export const convertToDateTimeStringIgnoreTZ = (duration, date) => {
  return addDurationToDate(moment.duration(duration), moment(date)).utc().toISOString()
}

export const overwriteTimeOnMoment = (time, date) => {
  return addDurationToDate(moment.duration(time), moment(date).startOf("day"))
}

export const getFormattedDate = (date) => {
  let year = date.getFullYear()
  let month = (date.getMonth() + 1).toString().padStart(2, '0')
  let day = date.getDate().toString().padStart(2, '0')

  return year + '-' + month + '-' + day
}

export const formatTime = (time, timezone=null) => {
  if (timezone) {
    return moment(time).tz(timezone).format('h:mm A')
  } else {
    return moment(time).format('h:mm A')
  }
}

export const formatMomentDateTime = (datetime) => {
  return moment(datetime).toISOString()
}

export const formatMomentDateTimeToUTC = (datetime) => {
  return moment(datetime).utc().toISOString()
}

export const filterBookingPreferences = (unfilteredBookingPreferences, start, end) => {
  return unfilteredBookingPreferences.filter((bp) =>
    (bp.start >= start && bp.end <= end)
    || (start >= bp.start && end <= bp.end)
    || (start >= bp.start && bp.end <= end && start < bp.end)
    || (bp.start >= start && bp.end >= end && bp.start < end)
  )
}

export const checkExistingBookingPreference = (event, bookingPreferences) => {
  const { start: eventStart, end: eventEnd } = event
  const bookingPreferenceScheduleItems = filterBookingPreferences(
    bookingPreferences.filter(si => event.resource == null || si.resource === event.resource),
    formatMomentDateTime(eventStart), formatMomentDateTime(eventEnd))

  if (bookingPreferenceScheduleItems) {
    return bookingPreferenceScheduleItems
  }

  return null
}

export const eventHasInvalidType = async(event, appointmentTypes):Promise<string|null> => {
  if(event.type) {
    return !appointmentTypes.some(at => at.id === event.type.id) ? "invalidType" : null
  }
  return null
}

const overlapsAllDayEvent = async(event, events): Promise<string | null> => {
  return events.some((e) => {
    const eDateOnly = moment(e.start, moment.ISO_8601).format('YYYY-MM-DD')
    return (eDateOnly === event.date) && e.allDay
  }) ? "allDayOverlap" : null
}

const overlapsEvent = async(event, events): Promise<string | null> => {
  return events.some((e) => {
    const eDateOnly = moment(e.start, moment.ISO_8601).format('YYYY-MM-DD')
    return (eDateOnly === event.date) && ((new Date(event.start) < new Date(e.end) && new Date(event.end) > new Date(e.start)))
  }) ? "eventOverlap" : null
}

const eventIsInPast = async(event): Promise<string|null> => moment(event.start).isBefore(moment().startOf('day')) ? "inPast" : null

const eventIsOffSchedule = async(event, closedHours):Promise<string | null> => {
  let scheduleClosedHours = closedHours
  if(event.resource) {
    scheduleClosedHours = closedHours.filter( hours => hours.resource === event.resource)
  }

  const isOffSchedule = scheduleClosedHours.some( hours => {
    return doTimesIntersect( formatMomentDateTime(hours.start), formatMomentDateTime(hours.end), formatMomentDateTimeToUTC(event.start), formatMomentDateTimeToUTC(event.end))
  })

  return isOffSchedule ? "offSchedule" : null
}

const doTimesIntersect = (startTimeA, endTimeA, startTimeB, endTimeB) => {
  const moment_startA = moment(startTimeA)
  const moment_endA = moment(endTimeA)
  const moment_startB = moment(startTimeB)
  const moment_endB = moment(endTimeB)

 return moment_startB.isBetween(moment_startA, moment_endA) ||  moment_endB.isBetween(moment_startA, moment_endA)
}

export const validateEvent = async(event, events, closedHours, appointmentTypes) => {
  const eventDateTime = {
    start: moment(event.start, moment.ISO_8601).toISOString(),
    end: moment(event.end, moment.ISO_8601).toISOString(),
    date: moment(event.start, moment.ISO_8601).format('YYYY-MM-DD')
  }

  const checks = [
    overlapsAllDayEvent(eventDateTime, events),
    overlapsEvent(eventDateTime, events),
    eventIsOffSchedule(event, closedHours),
    eventIsInPast(event),
    eventHasInvalidType(event, appointmentTypes)
  ]

  let checkResults = await Promise.all(checks)
  return checkResults.filter( Boolean )
}

export const separateIntoIntervals = (event: any, timeScale: number) => {
  let intervals: any[] = []

  const startTime = moment.utc(event.start)
  const endTime = moment.utc(event.end)

  const label = () => {
    if (event.title && event.appointmentTypes && event.appointmentTypes.length > 0) {
      return `${event.title}: ${event.appointmentTypes.map((at) => at.name).join(', ')}`
    }
    else if (event.title) {
      return event.title
    }
    else if (event.appointmentTypes && event.appointmentTypes.length > 0) {
      return `${event.appointmentTypes.map((at) => at.name).join(', ')}`
    }
    return ''
  }

  let currentTime = startTime
  while (currentTime.isBefore(endTime)) {
    const start = moment(currentTime)
    let cssClass = 'booking-preference'

    let intervalRate = 0
    if (start.minutes() % timeScale !== 0) {
      intervalRate = timeScale - (start.minutes() % timeScale)
      cssClass += ' small'
    } else {
      intervalRate = moment.duration(diffDates(start, endTime)).asMinutes()
      if (timeScale <= intervalRate) {
        intervalRate = timeScale
      } else if (timeScale > intervalRate*2) {
        cssClass += ' small'
      }
    }

    currentTime = addDurationToDate(moment.duration(intervalRate, 'minutes'), moment(currentTime))
    const end = moment(currentTime)

    intervals.push({
      start: formatMomentDateTime(start),
      end: formatMomentDateTime(end),
      background: CLOSED_HOURS_COLOUR,
      title: label(),
      cssClass: cssClass,
      resource: event.resource
    })
  }

  return intervals
}

export const getScheduleViewDates = (date, view) => {
  const scheduleDate = date ?? moment().utc().toDate()
  return {
    start: view === 'day' ? moment(scheduleDate) : moment(scheduleDate).startOf('week'),
    end: view === 'day' ? moment(scheduleDate) : moment(scheduleDate).endOf('week')
  }
}

export const parseScheduleSettings = (settings, displayStartDate, displayEndDate, timezone) =>{
  const parsedHours = settings.map( s => {
    const BASE_DATE = moment(displayStartDate)
      let settings = {
        id: s.id,
        endDate: s.endDate
      }

    if (s.sundayOpen) {
      settings["sunday"] = {
        start: overwriteTimeOnMoment(s.sundayStart, BASE_DATE.day(0)),
        end: overwriteTimeOnMoment(s.sundayEnd, BASE_DATE.day(0))
      }
    }

    if (s.mondayOpen) {
      settings["monday"] = {
        start: overwriteTimeOnMoment(s.mondayStart, BASE_DATE.day(1)),
        end: overwriteTimeOnMoment(s.mondayEnd, BASE_DATE.day(1))
      }
    }

    if (s.tuesdayOpen) {
      settings["tuesday"] = {
        start: overwriteTimeOnMoment(s.tuesdayStart, BASE_DATE.day(2)),
        end: overwriteTimeOnMoment(s.tuesdayEnd, BASE_DATE.day(2))
      }
    }

    if (s.wednesdayOpen) {
      settings["wednesday"] = {
        start: overwriteTimeOnMoment(s.wednesdayStart, BASE_DATE.day(3)),
        end: overwriteTimeOnMoment(s.wednesdayEnd, BASE_DATE.day(3))
      }
    }

    if (s.thursdayOpen) {
      settings["thursday"] = {
        start: overwriteTimeOnMoment(s.thursdayStart, BASE_DATE.day(4)),
        end: overwriteTimeOnMoment(s.thursdayEnd, BASE_DATE.day(4))
      }
    }

    if (s.fridayOpen) {
      settings["friday"] = {
        start: overwriteTimeOnMoment(s.fridayStart, BASE_DATE.day(5)),
        end: overwriteTimeOnMoment(s.fridayEnd, BASE_DATE.day(5))
      }
    }

    if (s.saturdayOpen) {
      settings["saturday"] = {
        start: overwriteTimeOnMoment(s.saturdayStart, BASE_DATE.day(6)),
        end: overwriteTimeOnMoment(s.saturdayEnd, BASE_DATE.day(6))
      }
    }


    return settings
  })

  return processHoursForSchedule(parsedHours, displayEndDate, timezone)
}

export const processHoursForSchedule = (scheduleHours, displayEndDate, timezone) => {
  let closedHours: MbscCalendarEvent[] = []
  let scheduleEndEvents: MbscCalendarEvent[] = []

  scheduleHours.forEach( settings => {
    const scheduleEndStart = addDurationToDate(moment.duration(24, 'hours'), moment(settings.endDate)).tz(timezone, true)
    const displayEndDay = addDurationToDate(moment.duration(24, 'hours'), moment.min(moment(scheduleEndStart), moment(displayEndDate)))

    WEEKDAYSMAP.forEach( (day, i) => {
      const daySettings = settings[day[1]]

      const dayStartRaw = moment(displayEndDate).startOf("week").day(i).startOf('day')
      const dayStart = moment(dayStartRaw)
      const dayEnd = addDurationToDate(moment.duration(24, 'hours'), dayStartRaw)

      if(daySettings){
        closedHours.push({
          resource: settings.id,
          recurring:{
            repeat: 'weekly',
            until: displayEndDay.startOf('day')
          },
          start: dayStart,
          end: daySettings.start,
          timezone: timezone as string,
          background: CLOSED_HOURS_COLOUR,
        })

        closedHours.push({
          resource: settings.id,
          recurring:{
            repeat: 'weekly',
            until: displayEndDay.endOf('day')
          },
          start: daySettings.end,
          end: dayEnd,
          timezone: timezone as string,
          background: CLOSED_HOURS_COLOUR,
        })
      } else {
        closedHours.push({
          resource: settings.id,
          recurring:{
            repeat: 'weekly',
            until: displayEndDay.startOf('day')
          },
          start: dayStart,
          end: dayEnd,
          timezone: timezone as string,
          background: CLOSED_HOURS_COLOUR,
        })
      }
    })

    // This fills in the red on all hours of the day.
    const scheduleEnd: MbscCalendarEvent = {
      resource: settings.id,
      recurring: {
        repeat: "daily",
        weekDays: "SU, MO, TU, WE, TH, FR, SA"
      },
      background: CLOSED_SCHEDULE_COLOUR,
      start: scheduleEndStart,
      end: addDurationToDate(moment.duration(24, 'hours'), scheduleEndStart),
      timezone: timezone as string
    }

    const end = addDurationToDate(moment.duration(24, 'hours'), moment(settings.endDate)).toISOString()

    // This is the event in the "all day" bar.
    const scheduleEndEvent: MbscCalendarEvent = {
      resource: settings.id,
      recurring: {
        repeat: "daily",
        weekDays: "SU, MO, TU, WE, TH, FR, SA"
      },
      background: CLOSED_SCHEDULE_COLOUR,
      allDay: true,
      editable: false,
      start: end,
      timezone: timezone as string,
      __typename: "ScheduleEnd"
    }

    closedHours.push(scheduleEnd)
    scheduleEndEvents.push(scheduleEndEvent)
  })

  return {
    closedHours: closedHours,
    scheduleEndEvents: scheduleEndEvents
  }
}

const mergeWithStructure = (source, structure) => {
  const merged = { ...structure };

  for (const key in structure) {
    if (typeof structure[key] === 'object') {
      if (Array.isArray(structure[key])) {
        merged[key] = Array.isArray(source[key]) ? source[key] : structure[key];
      } else {
        merged[key] = mergeWithStructure(source[key], structure[key]);
      }
    } else {
      merged[key] = source.hasOwnProperty(key) ? source[key] : structure[key];
    }
  }

  return merged;
}

export const matchGetScheduleItemsStructure = (prev) => {
  let defaultStructure = {
    tenant: {
      schedule: {
        scheduleItems: [],
        scheduleTemplateDays: []
      }
    }
  }

  let newObject = mergeWithStructure(prev, defaultStructure);

  return newObject
}

function orderEvents(a, b) {
  // Order events by state, with cancelled and missed events on the right
  // Needs to be sorted here in order to deal with live updates from the appts being updated
  const aIsCancelledOrMissed = ['Cancelled', 'Missed'].includes(a.state?.name);
  const bIsCancelledOrMissed = ['Cancelled', 'Missed'].includes(b.state?.name);

  if (aIsCancelledOrMissed && !bIsCancelledOrMissed) {
    return -1
  } else if (!aIsCancelledOrMissed && bIsCancelledOrMissed) {
    return 1
  }
  return 0
}

export const processEvents = (events) => {
  return events.sort(orderEvents).map((event) => {
    const processedEvent = event ? removeAllDayEventTimes(event) : undefined
    return Object.assign({}, processedEvent)
  })
}

export const removeAllDayEventTimes = (event) => {
  if(event?.allDay){
    const updatedEvent = Object.assign({}, event)
    updatedEvent.start = moment(event.start).format('YYYY-MM-DD')
    updatedEvent.end = event.start

    return updatedEvent
  }
  return event
}

export const insertDataTestIds = (
  ref: React.Ref<any>,
  date: string | null,
  dataTestIdPrefix: string = 'scheduler',
  type?: ScheduleLength,
  cellStep?: number
) => {
  // add data-testids to events and calendar cells within the mobiscroll Eventcalendar component
  if (!ref || !type || !cellStep) {
    return
  }
  const refObject = (ref as MutableRefObject<any>).current
  if (!refObject) {
    return
  }

  const refElement = ReactDOM.findDOMNode(refObject) as Element
  if (!refElement) {
    return
  }

  const slotElements = refElement.getElementsByClassName("mbsc-schedule-column-inner")
  const cellDuration = moment.duration(cellStep, 'minutes')
  let cellStart = getScheduleViewDates(date, type).start.startOf("day")
  let column = -1

  for (const element of Array.from(slotElements)) {
    column += 1
    let row = -1
    let exit = false
    for (const child of Array.from(element.children)) {
      if (!child.classList.contains("mbsc-schedule-item")) {
        continue
      }
      row += 1

      const cellEnd = addDurationToDate(cellDuration, cellStart)
      const dataTestId = `${dataTestIdPrefix}-column-${column}-row-${row}`
      if (child.getAttribute("data-testid") === dataTestId) {
        exit = true // ids still exist
        break
      }

      child.setAttribute("data-testid", dataTestId)
      cellStart = cellEnd
    }
    if (exit) {
      break
    }
  }

  const eventElements = refElement.getElementsByClassName("mbsc-schedule-events")

  column = -1
  for (const element of Array.from(eventElements)) {
    column += 1
    let row = -1
    let exit = false
    for (const child of Array.from(element.children)) {
      if (!child.classList.contains("mbsc-schedule-event")) {
        continue
      }
      row += 1

      const dataTestId = `${dataTestIdPrefix}-column-${column}-event-${row}`
      if (child.getAttribute("data-testid") !== dataTestId) {
        child.setAttribute("data-testid", dataTestId)
      }

      const newEventElements = child.getElementsByClassName("newEvent")
      Array.from(newEventElements).map((event) => event.setAttribute("data-testid", `${dataTestId}-newEvent`))
    }
    if (exit) {
      break
    }
  }

  const allDayEventElements = refElement.getElementsByClassName("mbsc-schedule-all-day-item")

  column = -1
  for (const element of Array.from(allDayEventElements)) {
    column += 1
    let row = -1
    let exit = false
    for (const child of Array.from(element.children)) {
      if (!child.classList.contains("mbsc-schedule-event")) {
        continue
      }
      row += 1

      const dataTestId = `${dataTestIdPrefix}-column-${column}-allDayEvent-${row}`
      if (child.getAttribute("data-testid") === dataTestId) {
        exit = true // ids still exist
        break
      }

      child.setAttribute("data-testid", dataTestId)
    }
    if (exit) {
      break
    }
  }
}

export const convertTemplateItemTimes = (templateItems)=>{
  return templateItems.map( ti => {
    ti.start = convertToDateTimeStringIgnoreTZ(ti.startTimeSpan, ti.scheduleDate).toString()
    ti.end = convertToDateTimeStringIgnoreTZ(ti.endTimeSpan, ti.scheduleDate).toString()
  })
}

export const updateClosedHours = (defaultClosedHours, scheduleDateEnd, templateDays, timezone) => {
  let newClosedHours = defaultClosedHours.filter((closedHour) => {
    if (closedHour.recurring?.repeat === 'daily') {
      return true
    }
    return !templateDays.some((templateDay) => {
      return moment(closedHour.start).isSame(templateDay.scheduleDate, 'day') && closedHour.resource === templateDay.resource;
    });
  })
  const displayEndDay = scheduleDateEnd.endOf('day')

  templateDays.forEach((templateDay) => {
    const dayStartRaw = moment(templateDay.scheduleDate).startOf('day')
    const dayStart = moment(dayStartRaw)
    const dayEnd = addDurationToDate(moment.duration(24, 'hours'), dayStartRaw)

    if (templateDay.startTime === null || templateDay.endTime === null) {
      newClosedHours.push({
        resource: templateDay.resource,
        recurring:{
          repeat: 'weekly',
          until: displayEndDay
        },
        background: CLOSED_HOURS_COLOUR,
        start: dayStart,
        end: dayEnd,
        timezone: timezone as string
      })
    } else {
      newClosedHours.push({
        resource: templateDay.resource,
        recurring:{
          repeat: 'weekly',
          until: displayEndDay
        },
        background: CLOSED_HOURS_COLOUR,
        start: dayStart,
        end: overwriteTimeOnMoment(templateDay.startTime, templateDay.scheduleDate),
        timezone: timezone as string
      })
      newClosedHours.push({
        resource: templateDay.resource,
        recurring:{
          repeat: 'weekly',
          until: displayEndDay
        },
        background: CLOSED_HOURS_COLOUR,
        start: overwriteTimeOnMoment(templateDay.endTime, templateDay.scheduleDate),
        end: dayEnd,
        timezone: timezone as string
      })
    }
  })

  return newClosedHours
}

export const getTemplateDayHeaderEvents = (templateDays) => {
  const templateAppliedEvents:any[] = []

  templateDays.forEach((t) => {
    let start = moment(t.scheduleDate).utc().toISOString()
    let end = moment(t.scheduleDate).endOf('day').utc().toISOString()
    let templateAppliedEvent = {
      __typename: 'TemplateAppliedEvent',
      start: start, end: end, allDay: true, title: t.templateName, templateId: t.templateId, resource: t.resource
    }
    templateAppliedEvents.push(templateAppliedEvent)
  })

  return templateAppliedEvents
}

export const updateScheduleItemsCache = (cache, eventData, variables) => {
  cache.updateQuery({
    query: GET_SCHEDULE_ITEMS,
    variables: variables
  },(prevData) => {
    var deep = _cloneDeep(prevData)

    const schedule = deep.tenant.schedule.scheduleItems.find(s => s.key === eventData.resource)

    if(!schedule) {
      console.error('Schedule not found')
      return prevData
    }

    const existingEvent = schedule.value.find(e => e.id === eventData.id)
    if(!existingEvent) {
      schedule.value = [...schedule.value, eventData]
    }

    return deep
  })
}