import { useQuery } from "@apollo/client";
import { Autocomplete, Typography } from "saga-library/src";
import React, { useState } from "react";
import { Timezone as TimezoneType } from "../types/Timezone";
import { Box, SxProps, useTheme } from "@mui/material";
import { TIMEZONES } from "../graphql-definitions";
import moment from "moment-timezone";

interface TimezoneProps {
  label?: string
  value: TimezoneType | null
  onChange: (e, o) => void
  disabled?: boolean
  dataTestId?: string
  sx: SxProps
}

export const TimezoneSelect = ({
  label,
  value,
  onChange,
  disabled = false,
  dataTestId,
  sx
}: TimezoneProps) => {
  const theme = useTheme()
  const [timezoneOptions, setTimezoneOptions] = useState([])
  const [now, setTime] = React.useState("")

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (value) {
        setTime(moment().tz(value?.name || "").format("h:mm A"))
      }
    }, 5 * 1000);
    return () => {
      clearInterval(timer)
    }
  }, [value])

  React.useEffect(() => {
    if (value) {
      setTime(moment().tz(value?.name || "").format("h:mm A"))
    }

  }, [value])

  const { loading } = useQuery(TIMEZONES, {
    onCompleted: (data) => {
      const timezoneData = data?.static.timezones
        .map(t =>({
          ...t,
          name: t.name.replace('_', ' '),
        }))
        .filter(tz => moment.tz.zone(tz.name))
        .filter(tz => moment.tz.zonesForCountry('CA').includes(tz.name))

      timezoneData.forEach((tz) => {
        tz.currentTime = moment().tz(tz.name).format("H.MM")
      })

      timezoneData.sort((a, b) => {
        return a.currentTime - b.currentTime || a.name.localeCompare(b.name)
      })
      setTimezoneOptions(timezoneData)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-first'
  })

  const TimezoneOption = ({data, props, inputValue}) => {
    const time = moment().tz(data.name).format("h:mm A")
    return (
      <Box display={"flex"} sx={{width: "100%"}} {...props} >
        <Typography
          sx={{flex: '1'}}
          dataTestId={`${dataTestId}-timezone-name`}
        >
          {data?.name?.replace('_', ' ') || ""}
        </Typography>
        <Typography
          sx={{marginLeft: '1rem', flex: '1', textAlign: 'right', color: theme.palette.greys.light}}
          dataTestId={`${dataTestId}-timezone-time`}
        >
          {time}
        </Typography>
      </Box>
    )
  }

  return (
    <Autocomplete
      dataTestId={`${dataTestId}-timezone-select`}
      label={label || 'Time zone'}
      value={value || null}
      disabled={disabled}
      disableClearable={true}
      onChange={(e, o) => {
        onChange(e, {
          ...o,
          name: o?.name?.replace(' ', '_')
        })
      }}
      options={timezoneOptions}
      isOptionEqualToValue={(o, v) => o.name === v.name}
      getOptionLabel={o => o?.name?.replace('_', ' ') || ""}
      OptionComponent={TimezoneOption}
      loading={loading}
      sx={sx}
      endAdornment={<Typography sx={{marginLeft: '1rem', color: theme.palette.greys.light}}> {now} </Typography>}
    />
  )
}








