import React from "react";
import { PDFUIInstanceContext, PDFUIRenderToElementContext } from './FoxitWebPDFContexts';
import * as UIExtension from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/UIExtension.full.js';
import * as Addons from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/uix-addons/allInOne.js';
import * as mobileAddons from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/uix-addons/allInOne.mobile.js';
import { licenseKey, licenseSN } from '../../foxitLicenseKey';
import { PDFViewCtrl } from "@foxitsoftware/foxit-pdf-sdk-for-web-library";
import DefaultTemplate, { NoUiTemplate } from "./DefaultTemplate";

if (module.hot) {
    module.hot.dispose(() => {
        window.location.reload();
    });
}

const formsFragment = [
    {
        target: 'group-edit',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
        target:'group-text',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
        target: 'group-drawing',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    }
]

const labsFragment =  formsFragment.concat([
    {
        target: 'fv--thumbnail-contextmenu',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
        target: 'fv--contextmenu-item-rotate-right',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    },
    {
        target: 'fv--contextmenu-item-rotate-left',
        action: UIExtension.UIConsts.FRAGMENT_ACTION.REMOVE
    }

])


class FoxitWebPDFAppComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerRef: React.createRef()
        };
    }


    componentDidMount() {
        const getCustomAppearance = () => {
            switch(this.props.variant){
                case 'document':
                    return DefaultTemplate();
                case 'labs':
                    return DefaultTemplate(labsFragment);
                case 'forms':
                    return DefaultTemplate(formsFragment);
                case 'no_ui':
                    return NoUiTemplate
                default:
                    return undefined;
            }
        }

        const libPath = "/foxit-lib-10-00-00/";
        const renderToElement = document.createElement('div');
        renderToElement.id = 'pdf-ui';
        renderToElement.dataset.testid = 'pdf-viewer';
        const pdfui = new UIExtension.PDFUI({
            viewerOptions: {
                libPath,
                jr: {
                    workerPath: libPath,
                    enginePath: libPath + '/jr-engine/gsdk',
                    fontPath: 'https://webpdf.foxitsoftware.com/webfonts/',
                    licenseSN,
                    licenseKey,
                },
                messageSyncServiceWorker: {
                    registration: () => {}
                },
            },
            renderTo: renderToElement,
            appearance:  getCustomAppearance(),
            addons: UIExtension.PDFViewCtrl.DeviceInfo.isMobile ? mobileAddons : Addons,
            customs: {
                getDocPermissions: () => {
                    return this.props.variant === 'forms' ? 0 : 1;
                }
            }
        });

        if (this.props.viewChanged) {
            pdfui.eventEmitter.on(PDFViewCtrl.constants.ViewerEvents.zoomToSuccess, (newScale, oldScale)  => {
                this.props.viewChanged({
                    zoom: newScale,
                    sidebarWidth: this.props.sidebarWidth
                })
            })
        }

        this.setState({
            pdfui
        });

        window.addEventListener('resize', this.resize = () => {
            pdfui.redraw();
        });
    }
    render() {
        require("@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/UIExtension.css")
        return (
            <PDFUIRenderToElementContext.Provider value={this.state.containerRef}>
                {
                    this.state.pdfui
                        ? (
                            <PDFUIInstanceContext.Provider value={this.state.pdfui}>
                                {this.props.children}
                            </PDFUIInstanceContext.Provider>
                        )
                        : this.props.children
                }
            </PDFUIRenderToElementContext.Provider>
        );
    }
    componentDidUpdate(){
        const pdfui = this.state.pdfui;
        const container = this.state.containerRef.current;
        if(pdfui && container) {
            pdfui.renderTo.remove();
            container.appendChild(pdfui.renderTo);
        }
    }
    componentWillUnmount() {
        if(this.state.pdfui) {
            this.state.pdfui.destroy();

            window.removeEventListener('resize', this.resize);
        }
    }
}
export const FoxitWebPDFApp = React.memo(FoxitWebPDFAppComponent);
