import React from 'react'
import { Address, AddressType, AddressDefaults } from "saga-client/src/components/Address";
import { Section } from 'saga-library/src'
import { rules } from '../util/referralPractitionerValidation'
import { FormFragment } from "../../../../../components/PermissionForm";

export type AddressDetailsType = AddressType

const Form = ({ requiredPermissionType }) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection>
        <Section.Header>Address</Section.Header>
        <Address
          rules={rules.address}
          showAddressLineLabelField={true}
          addressLineLabel={"Clinic"}
        />
      </Section.FormSection>
    </FormFragment>
  )
}

export const AddressDetails = {
  Form: Form,
  defaults: AddressDefaults
}
