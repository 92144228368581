import React from "react";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { SimpleTextField } from "../TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Controller, useFormContext } from "saga-library/src/components/Form";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import { IconButton } from "../Button";

export interface RefreshableProps {
  name: string
  label: string
  sx?: SxProps<Theme>
  textValue?: string
  onRefreshDisplaySnackbar: (v) => void
  dataTestId?: string
}

export const Refreshable = ({
  name,
  label,
  sx,
  textValue = '',
  onRefreshDisplaySnackbar,
  dataTestId
} : RefreshableProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref}, fieldState: {error} }) => (
        <SimpleTextField
          name={name}
          dataTestId={dataTestId}
          label={label}
          error={!!error}
          helperText={error?.message}
          sx={{
            ...sx,
            '& .MuiOutlinedInput-notchedOutline': {borderColor: value? '#0F6558 !important' : ''}
          }}
          value={textValue}
          inputRef={ref}
          InputProps={{
            readOnly: true,
            endAdornment:
              <InputAdornment position={"end"}>
                <IconButton
                  onClick={() => {
                    onRefreshDisplaySnackbar(value)
                    value = !value
                    onChange()
                  }}
                  sx={{ color:'greys.medium' }}
                  icon={<AutorenewIcon />}
                />
              </InputAdornment>
          }}
        />
      )}
    />
  )
}

export default Refreshable