import { MenuAndOutletLayout } from '../../components/Layouts'
import React, { useEffect } from 'react'
import { InboxMenu } from './components/InboxMenu'
import { Role, User } from '../../types/account'
import { useNavigate } from 'react-router-dom'

export enum UserRoleType {
  USER,
  ROLE
}

export type UserRole = {
  type: UserRoleType,
  data: User | Role
}

export const InboxSection = () => {
  const navigate = useNavigate()

  const onUserRoleChange = (userRole:UserRole) => {
    navigate(`${userRole.type === UserRoleType.USER ? "u" : "r"}/${userRole.data.id}/tasks`)
  }

  return (
    <MenuAndOutletLayout menu={<InboxMenu onChange={onUserRoleChange}/>} />
  )
}