import moment from "moment-timezone";
import { formatMomentDateTime } from "./scheduleFunctions";
import {
  TemplateBookingPreferenceInput,
  TemplateDay,
  TemplateEventInput,
  TemplateMbscCalendarColor,
  TemplateMbscCalendarEvent
} from "../../../types/schedule/Template";
import { BlockType, ScheduleLength } from "../../../types/schedule/Schedule";
import { MbscCalendarColor, MbscCalendarEvent } from "@mobiscroll/react";
import { TemplateDayInput } from "../../../types/schedule/Template";
import { addDurationToDate, diffDates } from "saga-library/src/util";

interface UpdateTemplateBookingPreferenceData {
  bookingPreferences: TemplateMbscCalendarColor[],
  templateDayIndex?: number,
  templateBlocks?: any[],
  errorMessage?: string
}

interface UpdateTemplateEventData {
  events: TemplateMbscCalendarEvent[],
  templateDayIndex?: number,
  templateBlocks?: any[]
}

const transformToTemplateBookingPreferenceInput = (bookingPreference: any): TemplateBookingPreferenceInput => {
  return {
    startTimeSpan: bookingPreference.start,
    length: bookingPreference.length,
    appointmentTypeIds: bookingPreference.appointmentTypeIds,
    appointmentTypes: bookingPreference.appointmentTypes,
    day: bookingPreference.day,
    version: bookingPreference.version,
    allDay: false,
    blockType: BlockType.BOOKING_PREFERENCE,
    title: bookingPreference.dbTitle,
    localId: bookingPreference.localId
  }
}

export const transformTemplateMbscCalendarColor = (bookingPreference, start, end): TemplateMbscCalendarColor => {
  return {
    start: formatMomentDateTime(start),
    end: formatMomentDateTime(end),
    background: '#DCDFE562',
    title: getBookingPreferenceLabel(bookingPreference),
    cssClass: 'booking-preference',
    appointmentTypeIds: !!bookingPreference.appointmentTypeIds ? bookingPreference.appointmentTypeIds : bookingPreference.appointmentTypes?.map(at => at.id),
    appointmentTypes: bookingPreference.appointmentTypes || [],
    version: bookingPreference.version,
    dbTitle: bookingPreference.title,
    localId: bookingPreference.localId,
    day: bookingPreference.day,
  }
}

export const transformToMbscCalendarColor = (bookingPreference, start, end): MbscCalendarColor => {
  return {
    start: formatMomentDateTime(start),
    end: formatMomentDateTime(end),
    background: '#DCDFE562',
    appointmentTypeIds: !!bookingPreference.appointmentTypeIds ? bookingPreference.appointmentTypeIds : bookingPreference.appointmentTypes?.map(at => at.id),
    version: bookingPreference.version,
    title: getBookingPreferenceLabel(bookingPreference),
    cssClass: 'booking-preference'
  }
}

const bookingPreferenceHasCollisions = (bookingPreferences: TemplateMbscCalendarColor[], bookingPreference: TemplateMbscCalendarColor, localId?: string) => {
  const bookingPreferenceCollisions = bookingPreferences
    .filter(bp => {
      if (bp.localId === localId || bp.day !== bookingPreference.day) {
        return false
      }
      if (!bp.start || !bp.end || !bookingPreference.start || !bookingPreference.end) {
        return false
      }
      return (bp.start >= bookingPreference.start && bp.start < bookingPreference.end) ||
        (bp.end > bookingPreference.start && bp.end <= bookingPreference.end)
    })

  return bookingPreferenceCollisions.length >= 1;
}

export const createBookingPreference = (templateDays: any[], bookingPreferences: TemplateMbscCalendarColor[], bookingPreference: TemplateMbscCalendarColor): UpdateTemplateBookingPreferenceData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === bookingPreference.day)
  if (templateDayIndex === -1) {
    console.error('createBookingPreference: templateDay not found', bookingPreference)
    return { bookingPreferences }
  }

  const templateDay = templateDays[templateDayIndex]
  const templateBlockBookingPreference = transformToTemplateBookingPreferenceInput(bookingPreference)
  const templateBlocks = [...templateDay.templateBlocks, templateBlockBookingPreference]
    
  const templateLength = (templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY
  const [start, end] = getStartEndTimes(bookingPreference, templateLength, templateDay.day)
  const newBookingPreference = transformTemplateMbscCalendarColor(bookingPreference, start, end)

  if (bookingPreferenceHasCollisions(bookingPreferences, newBookingPreference)) {
    console.error('createBookingPreference: bookingPreference overlaps with existing bookingPreference', bookingPreference)
    return {
      bookingPreferences,
      errorMessage: 'New booking preference overlaps with existing booking preference.'
    }
  }

  const updatedBookingPreferences = [...bookingPreferences, newBookingPreference]

  return { bookingPreferences: updatedBookingPreferences, templateDayIndex, templateBlocks }
}

export const updateBookingPreference = (templateDays: any[], bookingPreferences: TemplateMbscCalendarColor[], bookingPreference: TemplateMbscCalendarColor, originalBookingPreference: any): UpdateTemplateBookingPreferenceData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === bookingPreference.day)
  if (templateDayIndex === -1) {
    console.error('updateBookingPreference: templateDay not found', bookingPreference)
    return { bookingPreferences }
  }

  const templateDay = templateDays[templateDayIndex]
  const templateBlockBookingPreference = transformToTemplateBookingPreferenceInput(bookingPreference)
  const indexTemplateBlock = templateDay.templateBlocks.findIndex(bp => (bp.localId === originalBookingPreference.localId))
  if (indexTemplateBlock === -1) {
    console.error('updateBookingPreference: templateBlock not found', originalBookingPreference)
    return { bookingPreferences }
  }

  const templateBlocks = [...templateDay.templateBlocks]
  templateBlocks[indexTemplateBlock] = templateBlockBookingPreference

  const indexBookingPreference = bookingPreferences.findIndex(bp => (bp.localId === originalBookingPreference.localId))
  if (indexBookingPreference === -1) {
    console.error('updateBookingPreference: bookingPreference not found', originalBookingPreference)
    return { bookingPreferences }
  }

  const templateLength = (templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY
  const [start, end] = getStartEndTimes(bookingPreference, templateLength, templateDay.day)
  const newBookingPreference = transformTemplateMbscCalendarColor(bookingPreference, start, end)

  if (bookingPreferenceHasCollisions(bookingPreferences, newBookingPreference, newBookingPreference.localId)) {
    console.error('updateBookingPreference: bookingPreference overlaps with existing bookingPreference', bookingPreference)
    return {
      bookingPreferences,
      errorMessage: 'Updated booking preference overlaps with existing booking preference.'
    }
  }
  const updatedBookingPreferences = [...bookingPreferences.filter((bp) => bp.localId !== originalBookingPreference.localId), newBookingPreference]

  return { bookingPreferences: updatedBookingPreferences, templateDayIndex, templateBlocks }
}

export const deleteBookingPreference = (templateDays: any[], bookingPreferences: TemplateMbscCalendarColor[], bookingPreference: TemplateMbscCalendarColor): UpdateTemplateBookingPreferenceData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === bookingPreference.day)
  if (templateDayIndex === -1) {
    console.error('deleteBookingPreference: templateDay not found', bookingPreference)
    return { bookingPreferences }
  }

  const templateDay = templateDays[templateDayIndex]
  const templateBlocks = [...templateDay.templateBlocks].filter(templateBlock =>  templateBlock.localId !== bookingPreference.localId)
  const updatedBookingPreferences = [...bookingPreferences].filter(bp => bp.localId !== bookingPreference.localId)

  return { bookingPreferences: updatedBookingPreferences, templateDayIndex, templateBlocks }
}

const transformToTemplateEventInput = (event: any): TemplateEventInput => {
  const eventInput: TemplateEventInput = {
    startTimeSpan: event.startTimeSpan || event.start,
    length: event.length,
    day: event.day,
    title: event.title,
    allDay: !!event.allDay,
    version: event.version,
    blockType: BlockType.EVENT,
    appointmentTypeIds: event.appointmentTypeIds || [],
    localId: event.localId
  }

  return eventInput
}

export const transformTemplateMbscCalendarEvent = (event, start, end): TemplateMbscCalendarEvent => {
  return {
    ...event,
    start: formatMomentDateTime(start),
    end: formatMomentDateTime(end),
    title: event.title,
    appointmentTypeIds: [],
    version: event.version,
    localId: event.localId,
    blockType: BlockType.EVENT,
    day: event.day,
  }
}

export const createEvent = (templateDays: any[], events: TemplateMbscCalendarEvent[], event: TemplateMbscCalendarEvent): UpdateTemplateEventData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === event.day)
  if (templateDayIndex === -1) {
    console.error('createEvent: templateDay not found', event)
    return { events }
  }

  const eventTemplateBlock = transformToTemplateEventInput(event)
  const templateDay = templateDays[templateDayIndex]
  const templateBlocks = [...templateDay.templateBlocks, eventTemplateBlock]

  const templateLength = (templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY
  const [start, end] = getEventStartEndTimes(event, templateLength, event.day)
  const newEvent = transformTemplateMbscCalendarEvent(event, start, end)
  const updatedEvents = [...events, newEvent]

  return { events: updatedEvents, templateDayIndex, templateBlocks }
}

export const updateEvent = (templateDays: any[], events: TemplateMbscCalendarEvent[], event: TemplateMbscCalendarEvent, originalEvent: TemplateMbscCalendarEvent): UpdateTemplateEventData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === event.day)
  if (templateDayIndex === -1) {
    console.error('updateEvent: templateDay not found', event)
    return { events }
  }

  const templateDay = templateDays[templateDayIndex]
  const templateBlockEvent = transformToTemplateEventInput(event)
  const indexTemplateBlock = templateDay.templateBlocks.findIndex(bp => (bp.localId === originalEvent.localId))
  if (indexTemplateBlock === -1) {
    console.error('updateEvent: templateBlock not found', originalEvent)
    return { events }
  }

  const templateBlocks = [...templateDay.templateBlocks]
  templateBlocks[indexTemplateBlock] = templateBlockEvent

  const indexEvent = events.findIndex(bp => (bp.localId === originalEvent.localId))
  if (indexEvent === -1) {
    console.error('updateEvent: event not found', originalEvent)
    return { events }
  }

  const templateLength = (templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY
  const [start, end] = getEventStartEndTimes(event, templateLength, templateDay.day)
  const updatedEvents = [...events]
  updatedEvents[indexEvent] = transformTemplateMbscCalendarEvent(event, start, end)

  return { events: updatedEvents, templateDayIndex, templateBlocks }
}

export const convertToTimeSpan = (start: string | number | object | Date | undefined, timeFormat:string ='YYYY-MM-DD h:mm A', addOffset: boolean = false) => {
  let dayStart = moment(start,  timeFormat).startOf('day').utc()

  if(addOffset){
    const offsetInHours = Math.floor(new Date().getTimezoneOffset() / 60)
    dayStart = addDurationToDate(moment.duration(offsetInHours, 'hours'), dayStart)
  }
  const eventStart = moment(start, timeFormat).utc()

 return moment.duration(diffDates(dayStart, eventStart)).toISOString()
}

export const updateDragEvent = (
  templateDays: any[],
  events: TemplateMbscCalendarEvent[],
  event: TemplateMbscCalendarEvent)
  : UpdateTemplateEventData & {
    newTemplateDayIndex?: number
    newTemplateBlocks?: TemplateEventInput[]
} => {

  const templateLength = (templateDays.length > 1) ? ScheduleLength.WEEK : ScheduleLength.DAY

  const newDay = moment(event.start).weekday()
  const startTime = convertToTimeSpan(event.start, 'YYYY-MM-DD h:mm A', true)
  let templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === event.day)
  if (templateDayIndex === -1) {
    console.error('updateEvent: templateDay not found', event)
    return { events }
  }
  event.startTimeSpan = startTime

  const templateDay = templateDays[templateDayIndex]
  const templateBlockEvent = transformToTemplateEventInput(event)
  const indexTemplateBlock = templateDay.templateBlocks.findIndex(bp => (bp.localId === event.localId))
  if (indexTemplateBlock === -1) {
    console.error('updateEvent: templateBlock not found', event)
    return { events }
  }

  const templateBlocks = [...templateDay.templateBlocks]
  let newTemplateDayIndex = -1
  let newTemplateBlocks: TemplateEventInput[] = []
  if(templateLength === ScheduleLength.WEEK && event.day !== newDay){
    event.day = newDay
    templateBlocks.splice(indexTemplateBlock, 1)
    newTemplateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === newDay)
    newTemplateBlocks = [...templateDays[newTemplateDayIndex].templateBlocks] || []
    newTemplateBlocks.push(templateBlockEvent)
  } else {
    templateBlocks[indexTemplateBlock] = templateBlockEvent
  }

  const indexEvent = events.findIndex(e => (e.localId === event.localId))
  if (indexEvent === -1) {
    console.error('updateEvent: event not found', event)
    return { events, templateDayIndex, templateBlocks, newTemplateDayIndex, newTemplateBlocks }
  }

  const updatedEvents = [...events]
  updatedEvents[indexEvent] = event

  return { events: updatedEvents, templateDayIndex, templateBlocks, newTemplateDayIndex, newTemplateBlocks }
}

export const appendEvent = (events, event, start, end, day) => {
  events.push({
    ...event,
    start: formatMomentDateTime(start),
    end: formatMomentDateTime(end),
    blockType: BlockType.EVENT,
    day: day
  })
}

export const deleteEvent = (templateDays: any[], events: TemplateMbscCalendarEvent[], event: TemplateMbscCalendarEvent): UpdateTemplateEventData => {
  const templateDayIndex = templateDays?.findIndex((templateDay) => templateDay.day === event.day)
  if (templateDayIndex === -1) {
    console.error('deleteEvent: templateDay not found', event)
    return { events }
  }

  const templateDay = templateDays[templateDayIndex]
  const templateBlocks = [...templateDay.templateBlocks].filter(templateBlock => templateBlock.localId !== event.localId)
  events = events?.filter(ev => ev.localId !== event.localId)

  return { events, templateDayIndex, templateBlocks }
}

export const getTemplateEvent = (eventData, templateLength: ScheduleLength): TemplateMbscCalendarEvent => {
  const startMoment = getDateTimeMomentFromTimeSpan(eventData.startTimeSpan, eventData.day, templateLength)

  return   {
    ...eventData,
    start: startMoment.toISOString(),
    end: addDurationToDate(moment.duration(eventData.length, "minutes"), startMoment).utc().toISOString(),
    blockType: BlockType.EVENT,
    day: eventData.day
  }
}

export const getTemplateBookingPreference = (eventData, templateLength: ScheduleLength): TemplateMbscCalendarColor => {
  const startMoment = getDateTimeMomentFromTimeSpan(eventData.startTimeSpan, eventData.day, templateLength)

  const start = startMoment.toISOString()
  const end = addDurationToDate(moment.duration(eventData.length, "minutes"), startMoment).utc().toISOString()
  eventData.dbTitle = eventData.title

  return transformTemplateMbscCalendarColor(eventData, start, end)
}

export const getMomentTime = (time) => {
  return moment(time, 'HH:mm:ss')
}

export const getDateTimeMomentFromTimeSpan = (timeSpan: string, weekDayNum: number, templateLength: ScheduleLength) => {
  let date = moment().startOf("day")
  if(templateLength === ScheduleLength.WEEK){
    date = date.day(weekDayNum).startOf("day")
  }
  return addDurationToDate(timeSpan, date).utc()
}

export const separateIntoIntervals = (bookingPreference: TemplateMbscCalendarColor, timeScale: number): TemplateMbscCalendarColor[] => {
  let intervals: TemplateMbscCalendarColor[] = []

  const startTime = moment.utc(bookingPreference.start)
  const endTime = moment.utc(bookingPreference.end)

  let currentTime = startTime
  while (currentTime.isBefore(endTime)) {
    const start = moment(currentTime)
    let cssClass = 'booking-preference'

    let intervalRate = 0
    if (start.minutes() % timeScale !== 0) {
      intervalRate = timeScale - (start.minutes() % timeScale)
      cssClass += ' small'
    } else {
      intervalRate = moment.duration(diffDates(start, endTime)).asMinutes()
      if (timeScale <= intervalRate) {
        intervalRate = timeScale
      } else if (timeScale > intervalRate * 2) {
        cssClass += ' small'
      }
    }

    currentTime = addDurationToDate(moment.duration(intervalRate, 'minutes'), moment(currentTime))
    const end = moment(currentTime)

    intervals.push({
      ...bookingPreference,
      start: formatMomentDateTime(start),
      end: formatMomentDateTime(end),
      cssClass: cssClass,
    })
  }

  return intervals
}
export const combineFromIntervals = (bookingPreferences: TemplateMbscCalendarColor[], bookingPreference: TemplateMbscCalendarColor): TemplateMbscCalendarColor => {
  const selectedBookingPreferences = bookingPreferences.filter((bp) => bp.localId === bookingPreference.localId)
  const startTime = moment.min(selectedBookingPreferences.map((bp) => moment(bp.start!)))
  const endTime = moment.max(selectedBookingPreferences.map((bp) => moment(bp.end!)))
  return {
    ...bookingPreference,
    start: formatMomentDateTime(startTime),
    end: formatMomentDateTime(endTime)
  }
}

const getBookingPreferenceLabel = (bookingPreference: TemplateMbscCalendarColor): string => {
  if (bookingPreference.title && bookingPreference.appointmentTypes && bookingPreference.appointmentTypes.length > 0) {
    return `${bookingPreference.title}: ${bookingPreference.appointmentTypes.map((at) => at.name).join(', ')}`
  }
  else if (bookingPreference.title) {
    return bookingPreference.title
  }
  else if (bookingPreference.appointmentTypes && bookingPreference.appointmentTypes.length > 0) {
    return `${bookingPreference.appointmentTypes.map((at) => at.name).join(', ')}`
  }
  return ''
}

const getStartEndTimes = (bookingPreference: TemplateMbscCalendarColor, scheduleLength: ScheduleLength, day: number) : [Date, Date] => {
  const startDuration = moment.duration(diffDates(moment().startOf('day'), moment(bookingPreference.start, 'h:mm A')))
  let today = moment().startOf('day')
  let startTime = addDurationToDate(startDuration, today)

  if (scheduleLength === ScheduleLength.WEEK) {
    let date = moment().day(day)
    let localDate = date.startOf('day')
    let duration = moment.duration(diffDates(moment().startOf('day'), moment(bookingPreference.start, 'h:mm A')))
    startTime = addDurationToDate(duration, moment(localDate))
  }

  let start = startTime.toDate()
  const eventDuration = moment.duration(bookingPreference.length, 'minutes')
  let end = addDurationToDate(eventDuration, startTime).toDate()

  return [start, end]
}

const getEventStartEndTimes = (event: TemplateMbscCalendarEvent, scheduleLength: ScheduleLength, day: number): [Date, Date] => {
  const startDuration = moment.duration(diffDates(moment().startOf('day'), moment(event.start, 'h:mm A')))
  let today = moment().startOf('day')
  let startTime = addDurationToDate(startDuration, today)

  if (scheduleLength === ScheduleLength.WEEK) {
    let date = moment().day(day)
    let localDate = date.startOf('day')
    let duration = moment.duration(diffDates(moment().startOf('day'), moment(event.start, 'h:mm A')))
    startTime = addDurationToDate(duration, moment(localDate))
  }

  let start = startTime.toDate()
  const eventDuration = moment.duration(event.length, 'minutes')
  let end = addDurationToDate(eventDuration, startTime).toDate()

  return [start, end]
}

export const getDayBlockedTimes = (templateDay: TemplateDay, templateLength: ScheduleLength, timezone: string): MbscCalendarEvent[] => {
  if (!templateDay) {
    return []
  }
  const weekday = (templateLength === ScheduleLength.DAY) ? 0 : templateDay.day
  if (templateDay.startTime && templateDay.endTime && (templateDay.isOpen || templateDay.isOpen === undefined)) {
    return [{
      start: moment().day(weekday).startOf("day"),
      end: addDurationToDate(moment.duration(templateDay.startTime), moment().day(weekday).startOf("day")),
      timezone: timezone,
      background: "rgba(220, 223, 229, 0.38)",
      recurring: {
        repeat: templateLength === ScheduleLength.DAY ? "daily" : "weekly"
      }
    },
    {
      start: addDurationToDate(moment.duration(templateDay.endTime), moment().day(weekday).startOf("day")),
      timezone: timezone,
      end: moment().day(weekday).endOf("day"),
      background: "rgba(220, 223, 229, 0.38)",
      recurring: {
        repeat: templateLength === ScheduleLength.DAY ? "daily" : "weekly"
      }
    }]
  } else {
    return [{
      start: moment().day(weekday).startOf("day"),
      end: moment().day(templateDay.day).endOf("day"),
      timezone: timezone,
      background: "rgba(220, 223, 229, 0.38)",
      recurring: {
        repeat: (templateLength === ScheduleLength.DAY) ? "daily" : "weekly"
      }
    }]
  }
}

export const cleanTemplateDayForInput = (templateDay: TemplateDay): TemplateDayInput => {
  if (!templateDay.isOpen) {
    templateDay.startTime = null
    templateDay.endTime = null
  }
  if (templateDay.hasOwnProperty('isOpen')) delete templateDay.isOpen
  if (templateDay.hasOwnProperty('id')) delete templateDay.id
  templateDay.templateBlocks.forEach(templateBlock => {
    if (templateBlock.hasOwnProperty('id')) delete templateBlock.id
    if (templateBlock.hasOwnProperty('localId')) delete templateBlock.localId
    if (templateBlock.hasOwnProperty('appointmentTypes')) {
      templateBlock.appointmentTypeIds = templateBlock.appointmentTypes?.map(at => at.id) || []
      delete templateBlock.appointmentTypes
    }
  })
  return templateDay
}