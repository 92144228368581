import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccountContext } from "providers/AccountContext";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import SettingsHeader from "../../../components/SettingsHeader";
import { useFormContext } from 'react-hook-form'
import PermissionButton from "components/PermissionButton";

export const ReferralPractitionerHeader = ({ title = 'New referral practitioner' }) => {
  const navigate = useNavigate()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()

  const { formState: { isSubmitting } } = useFormContext()

  return (
    <SettingsHeader
      title={title}
      onBack={() =>
        navigate(
          buildTenantRoute('settings/practitioners/referral', tenant_id)
        )
      }
      actions={
        <PermissionButton
          name="referral-practitioner-save"
          type="submit"
          requiredType={PermissionType.Admin}
          requiredPermission={Permission.READWRITE}
          form={'referralPractitionerForm'}
          dataTestId={"referralPractitionerForm-save-button"}
          loading={isSubmitting}
        >
          Save
        </PermissionButton>
      }
      dataTestId={'referralPractitioner'}
    />
  )
}

export default ReferralPractitionerHeader
