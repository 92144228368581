import { gql } from "@apollo/client";

const IDENTIFIER_DETAILS_FRAGMENT = gql`
  fragment IdentifierDetails on PatientProfile {
    identifiers {
      typeId
      value
    }
  }
`

const BILLING_DETAILS_FRAGMENT = gql`
  fragment BillingDetails on PatientProfile {
    serviceCode {
      id
      serviceCode
      description
    }
    diagnosticCodes {
      id
      diagnosticCode
      description
    }
  }
`

const PERSONAL_DETAILS_FRAGMENT = gql`
  fragment PersonalDetails on PatientProfile {
    lastName
    firstName
    middleName
    genderId
    gender {
      name
    }
    dob
  }
`

const CONTACT_DETAILS_FRAGMENT = gql`
  fragment ContactDetails on PatientProfile {
    email
    phones {
      id
      number
      extension
      type
      isPrimary
      notes
    }
  }
`

const ADDRESS_DETAILS_FRAGMENT = gql`
  fragment AddressDetails on PatientProfile {
    street
    street2
    street3
    city
    province
    postalCode
    country
  }
`

const ADMINISTRATION_DETAILS_FRAGMENT = gql`
  fragment AdministrationDetails on PatientProfile {
    inactiveDate
    inactiveReason
  }
`

const VITAL_DETAILS_FRAGMENT = gql`
  fragment VitalDetails on PatientVitals {
    vitals {
      bloodPressureSystolic
      bloodPressureDiastolic
      heartRate
      weight
      weightUnit
      lengthHeight
      lengthHeightUnit
      lengthHeightType
      headCircumference
      headCircumferenceUnit
    }
  }
`

const RELATIONSHIP_DETAILS_FRAGMENT = gql`
  fragment RelationshipDetails on PatientRelationships {
    referralPractitioners {
      id
      firstName
      lastName
      practitionerId
      locationId
    }
    familyPractitioners {
      id
      firstName
      lastName
      practitionerId
      locationId
    }
    clinicPractitioners {
      firstName
      lastName
      practitionerId
      isPrimaryPractitioner
    }
    pharmacies {
      id
      name
      street1
      street2
      street3
      city
      province
      postalCode
      phoneNumber
      faxNumber
    }
    familyRelationships {
      relatedPatientId
      relationshipType
      isEmergencyContact
      firstName
      lastName
      email
      primaryPhoneNumber
    }
  }
`

const PATIENT_NOTES_FRAGMENT = gql`
  fragment NoteDetails on PatientProfile {
    notes {
      id
      noteText
    }
  }
`

export const UPDATE_PATIENT_PROFILE = gql`
    ${PERSONAL_DETAILS_FRAGMENT}
    ${CONTACT_DETAILS_FRAGMENT}
    ${ADDRESS_DETAILS_FRAGMENT}
    ${ADMINISTRATION_DETAILS_FRAGMENT}
    ${IDENTIFIER_DETAILS_FRAGMENT}
    ${BILLING_DETAILS_FRAGMENT}
    ${PATIENT_NOTES_FRAGMENT}
    mutation UpdatePatientDetails(
        $tenantId: ID!
        $patientId: ID!
        $patientData: PatientProfileInput!
    ) {
        tenant(id: $tenantId) {
            patient {
                updatePatientProfile(id: $patientId, patientData: $patientData) {
                    id
                    version
                    ...PersonalDetails
                    ...ContactDetails
                    ...AddressDetails
                    ...AdministrationDetails
                    ...IdentifierDetails
                    ...BillingDetails
                    ...NoteDetails
                }
            }
        }
    }
`

export const UPDATE_PATIENT_NOTES = gql`
    ${PERSONAL_DETAILS_FRAGMENT}
    ${CONTACT_DETAILS_FRAGMENT}
    ${ADDRESS_DETAILS_FRAGMENT}
    ${ADMINISTRATION_DETAILS_FRAGMENT}
    ${IDENTIFIER_DETAILS_FRAGMENT}
    ${BILLING_DETAILS_FRAGMENT}
    ${PATIENT_NOTES_FRAGMENT}
    mutation UpdatePatientNotes(
        $tenantId: ID!
        $patientId: ID!
        $patientData: PatientNotesInput!
    ) {
        tenant(id: $tenantId) {
            patient {
                updatePatientNotes(id: $patientId, patientData: $patientData) {
                    id
                    version
                    ...PersonalDetails
                    ...ContactDetails
                    ...AddressDetails
                    ...AdministrationDetails
                    ...IdentifierDetails
                    ...BillingDetails
                    ...NoteDetails
                }
            }
        }
    }
`

export const UPDATE_PATIENT_BILLING = gql`
    ${PERSONAL_DETAILS_FRAGMENT}
    ${CONTACT_DETAILS_FRAGMENT}
    ${ADDRESS_DETAILS_FRAGMENT}
    ${ADMINISTRATION_DETAILS_FRAGMENT}
    ${IDENTIFIER_DETAILS_FRAGMENT}
    ${BILLING_DETAILS_FRAGMENT}
    ${PATIENT_NOTES_FRAGMENT}
    mutation UpdatePatientBilling(
        $tenantId: ID!
        $patientId: ID!
        $patientData: PatientBillingInput!
    ) {
        tenant(id: $tenantId) {
            patient {
                updatePatientBilling(id: $patientId, patientData: $patientData) {
                    id
                    version
                    ...PersonalDetails
                    ...ContactDetails
                    ...AddressDetails
                    ...AdministrationDetails
                    ...IdentifierDetails
                    ...BillingDetails
                    ...NoteDetails
                }
            }
        }
    }
`

export const UPDATE_PATIENT_RELATIONSHIPS = gql`
    ${RELATIONSHIP_DETAILS_FRAGMENT}
    mutation UpdatePatientRelationships(
        $tenantId: ID!
        $patientId: ID!
        $patientData: PatientRelationshipsInput!
    ) {
        tenant(id: $tenantId) {
            patient {
                updatePatientRelationships(id: $patientId, patientData: $patientData) {
                    id
                    version
                    ...RelationshipDetails
                }
            }
        }
    }
`

export const GET_PATIENT_PROFILE_ONLY = gql`
  ${PERSONAL_DETAILS_FRAGMENT}
  ${CONTACT_DETAILS_FRAGMENT}
  ${ADDRESS_DETAILS_FRAGMENT}
  ${ADMINISTRATION_DETAILS_FRAGMENT}
  ${IDENTIFIER_DETAILS_FRAGMENT}
  ${BILLING_DETAILS_FRAGMENT}
  ${PATIENT_NOTES_FRAGMENT}
  query GetPatientProfile($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        profile {
          id
          version
          ...PersonalDetails
          ...ContactDetails
          ...AddressDetails
          ...AdministrationDetails
          ...IdentifierDetails
          ...BillingDetails
          ...NoteDetails
        }
      }
    }
  }
`

export const GET_PATIENT_RELATIONSHIPS = gql`
  ${RELATIONSHIP_DETAILS_FRAGMENT}
  query GetPatientRelationships($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        relationships {
          id
          version
          ...RelationshipDetails
        }
      }
    }
  }
`

export const GET_PATIENT_CURRENT_VITALS = gql`
  ${VITAL_DETAILS_FRAGMENT}
  query GetPatientCurrentVitals($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        currentVitals {
          id
          ...VitalDetails
        }
      }
    }
  }
`

export const GET_PATIENT_ALL_VITALS_DATA = gql`
  query GetPatientAllVitalsData($tenantId: ID!, $patientId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        vitalsData {
          bloodPressureSystolic
          bloodPressureDiastolic
          heartRate
          weight
          weightUnit
          lengthHeight
          lengthHeightUnit
          lengthHeightType
          headCircumference
          headCircumferenceUnit
          crePit
        }
      }
    }
  }
`