import React from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'saga-library/src'
import { CircularProgress, MenuItem } from "@mui/material";
import { GET_DISCIPLINES } from "../../graphql-definitions";

interface DisciplineSelectProps {
  provinceCode: string | null | undefined
  disabled?:boolean
}

export const DisciplineSelect = ({
  provinceCode,
  disabled = false
}: DisciplineSelectProps) => {
  const { data, loading } = useQuery(GET_DISCIPLINES, {
    fetchPolicy: 'cache-first',
    variables: { provinceCode: provinceCode },
  })
  return (
    <Select
      label={'Discipline'}
      name={'disciplineId'}
      disabled={disabled}
    >
      {loading && (
        <MenuItem>
          <CircularProgress />
        </MenuItem>
      )}
      {data && data.static.disciplinesByProvince.map(({ description, id }) =>
        <MenuItem value={id}>{description}</MenuItem>
      )}
    </Select>
  )
}

export default DisciplineSelect
