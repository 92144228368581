import React, { Suspense, useCallback, useEffect, useState } from 'react'
import {
  DialogV2,
  LineChart,
  Radio,
  SimpleRadioGroup,
  SimpleSelect,
  SimpleStartEndDatePicker,
  TableList,
  TableListCellConfig,
  Typography
} from 'saga-library/src'
import { Box, ListSubheader, MenuItem } from '@mui/material'
import { DATE_FORMAT } from '../../../utils/SettingsConstants'
import { useAccountContext } from '../../../providers/AccountContext'
import { ChartDataIcon } from './ChartIcons'
import { LoadingSpinner } from '../../../components/LoadingScreen'
import { usePatientProfileContext } from '../providers/PatientProfileProvider'
import { useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { GrowthChart } from './vitals/GrowthChart'
import { LengthHeightUnits, Vital, VitalsType, WeightUnits } from './vitals/VitalTypes'
import moment from 'moment-timezone'
import { calculateBMI, convertMeasurementUnits } from './vitals/unitConversionUtil'
import { LineChartData } from 'saga-library/src/components/LineChart/LineChart'

function VitalsDataRow(date, value, unit) {
  const rowData = [
    {
      children: <Typography variant={'body1'}>{moment(date).format('YYYY/MM/DD • hh:mm A')}</Typography>
    },
    {
      children: <Typography variant={'body1'} align={'right'}><b>{value}</b> {unit}</Typography>
    }
  ] as TableListCellConfig[];

  return {
    rowData: rowData,
    key: date
  }
}

const removeGenderTag = (vitalType: string): string => {
  if(vitalType) {
    return vitalType.replace(/_GIRLS|_BOYS/g, '')
  }

  return ""
}

const isLineChart = (vitalType: VitalsType): boolean => {
  return vitalType === VitalsType.BMI ||
    vitalType === VitalsType.HEIGHT ||
    vitalType === VitalsType.WEIGHT ||
    vitalType === VitalsType.BLOOD_PRESSURE ||
    vitalType === VitalsType.HEART_RATE
}

const VitalsModal = ({
  vitalType,
  onClose,
  patientName
}) => {
  const [selectedVitalType, setSelectedVitalType] = useState<VitalsType>(vitalType)
  return (
    <DialogV2
      title={removeGenderTag(selectedVitalType)}
      subtitle={patientName}
      onClose={onClose}
      open={!!selectedVitalType}
      showActions={false}
      contentSx={{

      }}
      preventScroll={true}
    >
      <Suspense fallback={<LoadingSpinner size={"md"} label={null} />}>
        <VitalsGraphModalBody selectedVitalType={selectedVitalType} setSelectedVitalType={setSelectedVitalType} />
      </Suspense>
    </DialogV2>
  )
}

const VitalsGraphModalBody = ({selectedVitalType, setSelectedVitalType}) => {
  const { patientAllVitalsDataRef } = usePatientProfileContext()
  const { data } = useReadQuery(patientAllVitalsDataRef!)
  const allVitalsData: Vital[] = _get(data, "tenant.patient.vitalsData", [])
  const { profileData } = usePatientProfileContext()

  const [selectedMeasurement, setSelectedMeasurement] = useState<"Imperial" | "Metric">("Metric")
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [vitalTableData, setVitalTableData] = useState<any[]>([])
  const [vitalGraphData, setVitalGraphData] = useState<any[]>([])

  const { getUserSetting } = useAccountContext()
  const dateFormat = getUserSetting(DATE_FORMAT).toString()

  const showMeasurement = selectedVitalType === VitalsType.HEIGHT || selectedVitalType === VitalsType.WEIGHT
  const isLineData = isLineChart(selectedVitalType)

  const filterChildAge = (values, type: 'months' | 'years', start: number, end: number) => {
    const age = moment(values.crePit).diff(moment(profileData.dob), type);
    return age >= start && age <= end
  }

  const calculateVitalsListBMI = (vitals) => {
    let lastKnownHeight: {value: any, unit: any} = { value: null, unit: null }
    const reorderedBMI = vitals.reverse().map(v => {
      if (v.lengthHeight) {
        lastKnownHeight = { value: v.lengthHeight, unit: v.lengthHeightUnit }
      }
      if (v.weight && lastKnownHeight.value) {
        return {
          date: v.crePit,
          value: calculateBMI(v.weight, v.weightUnit, lastKnownHeight.value, lastKnownHeight.unit),
          unit: ""
        }
      }
      return null
    })
    return reorderedBMI.filter(v => v).reverse()
  }

  useEffect(() => {
    const filteredDateVitalsData = allVitalsData.filter(v => {
      let date = moment(v.crePit).startOf('day')
      if (!startDate && !endDate) return true
      if (!endDate) return date.isAfter(moment(startDate.format()))
      if (!startDate) return date.isBefore(moment(endDate.format()))
      return date.isBetween(moment(startDate.format()), moment(endDate.format()))
    })

    let tempVitalData: any[]
    switch (selectedVitalType) {
      case VitalsType.HEART_RATE:
        tempVitalData = filteredDateVitalsData.filter(v => v.heartRate).map(v => {
          return {
            date: v.crePit,
            value: v.heartRate,
            unit: "bpm"
          }
        })
        break
      case VitalsType.WEIGHT:
        const weightUnits = selectedMeasurement === "Metric" ? WeightUnits.KG : WeightUnits.LBS
        tempVitalData = filteredDateVitalsData.filter(v => v.weight).map(v => {
          return {
            date: v.crePit,
            value: convertMeasurementUnits(v.weight!, v.weightUnit!, weightUnits).toFixed(1),
            unit: weightUnits
          }
        })
        break
      case VitalsType.HEIGHT:
        const heightUnits = selectedMeasurement === "Metric" ? LengthHeightUnits.CM : LengthHeightUnits.INCH
        tempVitalData = filteredDateVitalsData.filter(v => v.lengthHeight).map(v => {
          return {
            date: v.crePit,
            value: convertMeasurementUnits(v.lengthHeight!, v.lengthHeightUnit!, heightUnits).toFixed(1),
            unit: selectedMeasurement === "Metric" ? heightUnits : "inches"
          }
        })
        break
      case VitalsType.BMI:
        tempVitalData = calculateVitalsListBMI(filteredDateVitalsData)
        break
      case VitalsType.BLOOD_PRESSURE:
        tempVitalData = filteredDateVitalsData.filter(v => v.bloodPressureSystolic && v.bloodPressureDiastolic).map(v => {
          return {
            date: v.crePit,
            value: `${v.bloodPressureSystolic}/${v.bloodPressureDiastolic}`,
            unit: "mm Hg",
            data: {systolic: v.bloodPressureSystolic, diastolic: v.bloodPressureDiastolic}
          }
        })
        break
      case VitalsType.HC_GIRLS:
      case VitalsType.HC_BOYS:
        tempVitalData = filteredDateVitalsData
          .filter(v => filterChildAge(v, 'months', 0, 24))
          .filter(v => v.headCircumference || v.weight || v.lengthHeight)
          .map(v => {
            return {
              date: v.crePit,
              lengthHeight: v.lengthHeight ? convertMeasurementUnits(v.lengthHeight!, v.lengthHeightUnit!, LengthHeightUnits.CM).toFixed(1) : null,
              lengthHeightUnit: LengthHeightUnits.CM,
              headCircumference: v.headCircumference ? convertMeasurementUnits(v.headCircumference!, v.headCircumferenceUnit!, LengthHeightUnits.CM).toFixed(1) : null,
              headCircumferenceUnit: LengthHeightUnits.CM,
              weight: v.weight ? convertMeasurementUnits(v.weight!, v.weightUnit!, WeightUnits.KG).toFixed(1) : null,
              weightUnit: WeightUnits.KG
            }
          })
        break
      case VitalsType.LENGTH_GIRLS:
      case VitalsType.LENGTH_BOYS:
        tempVitalData = filteredDateVitalsData
          .filter(v => filterChildAge(v, 'months', 0, 24))
          .filter(v => v.lengthHeight || v.weight)
          .map(v => {
            return {
              date: v.crePit,
              lengthHeight: v.lengthHeight ? convertMeasurementUnits(v.lengthHeight!, v.lengthHeightUnit!, LengthHeightUnits.CM).toFixed(1) : null,
              lengthHeightUnit: LengthHeightUnits.CM,
              weight: v.weight ? convertMeasurementUnits(v.weight!, v.weightUnit!, WeightUnits.KG).toFixed(1) : null,
              weightUnit: WeightUnits.KG
            }
          })
        break
      case VitalsType.HW_GIRLS:
      case VitalsType.HW_BOYS:
      case VitalsType.BMI_GIRLS:
      case VitalsType.BMI_BOYS:
        tempVitalData = filteredDateVitalsData
          .filter(v => filterChildAge(v, 'years', 2, 19))
          .filter(v => v.lengthHeight || v.weight)
          .map(v => {
            return {
              date: v.crePit,
              lengthHeight: v.lengthHeight ? convertMeasurementUnits(v.lengthHeight!, v.lengthHeightUnit!, LengthHeightUnits.CM).toFixed(1) : null,
              lengthHeightUnit: LengthHeightUnits.CM,
              weight: v.weight ? convertMeasurementUnits(v.weight!, v.weightUnit!, WeightUnits.KG).toFixed(1) : null,
              weightUnit: WeightUnits.KG
            }
          })

        if (selectedVitalType === VitalsType.BMI_GIRLS || selectedVitalType === VitalsType.BMI_BOYS) {
          const tempBMIData = calculateVitalsListBMI(filteredDateVitalsData.filter(v => filterChildAge(v, 'years', 2, 19)))
          tempVitalData = tempVitalData.map((v) => {
            const bmi = tempBMIData.find(b => b.date === v.date)
            if (bmi) {
              return {
                ...v,
                bmi: bmi.value
              }
            }
            return v
          }).filter(v => v)
        }
        break
      default:
        tempVitalData = []
        break
    }

    setVitalTableData(tempVitalData)
    if (isLineData) {
      setVitalGraphData(convertVitalDataToLineChartData(tempVitalData, selectedVitalType))
    }
  }, [selectedVitalType, startDate, endDate, allVitalsData, selectedMeasurement])

  const DateAndMeasurements = () => {
    if (!isLineData) return null

    const datePicker = <SimpleStartEndDatePicker
      startDate={startDate}
      setStartDate={setStartDate}
      endDate={endDate}
      setEndDate={setEndDate}
      dateFormat={dateFormat}
      dataTestId={'vitals'}
    />

    if (showMeasurement) {
      return (
        <>
          <SimpleRadioGroup
            label={""}
            row={true}
            name={"vital_measurement_select"}
            value={selectedMeasurement}
            onChange={(e) => setSelectedMeasurement(e.currentTarget.value)}
          >
            <Radio dataTestId={"metric_radio"} label={"Metric"} value={"Metric"} />
            <Radio dataTestId={"imperial_radio"} label={"Imperial"} value={"Imperial"} />
          </SimpleRadioGroup>
          {datePicker}
        </>
      )
    }
    return datePicker
  }

  const convertVitalDataToLineChartData = (tempVitalData: any[], selectedVitalType: VitalsType) => {
    const groupedData: Record<string, LineChartData[]> = {};

    tempVitalData.flatMap(v => {
      if (VitalsType.BLOOD_PRESSURE === selectedVitalType) {
        return [
          {label: "Systolic", value: parseFloat(v.data.systolic), date: new Date(v.date.valueOf())},
          {label: "Diastolic", value: parseFloat(v.data.diastolic), date: new Date(v.date.valueOf())}
        ];
      }
      return [{label: selectedVitalType, value: parseFloat(v.value), date: new Date(v.date.valueOf())}];
    }).forEach(item => {
      if (!groupedData[item.label]) {
        groupedData[item.label] = [];
      }
      groupedData[item.label].push({value: item.value, date: item.date});
    })

    return Object.keys(groupedData).map(label => ({
      label,
      values: groupedData[label]
    }))
  }

  const ViewChart = useCallback(() => {
    if (vitalTableData.length === 0) {
      return (
        <Box
          textAlign={"center"}
          flex={"1 1 100%"}
          display={"flex"}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          gap={"25px"}
          sx={{ color: 'greys.medium' }}
        >
          <ChartDataIcon style={{height:"75px", width:"75px"}}/>
          <Typography variant={'h4'}> No recorded data </Typography>
        </Box>
      )
    }
    if (isLineData) {
      return (
        <Box flex={"1 1 100%"} >
          <LineChart
            data={vitalGraphData}
            min={startDate?.toDate()}
            max={endDate?.toDate()}
          />
        </Box>
      )
    }
    return (
      <GrowthChart
        vitalType={selectedVitalType}
        vitalTableData={vitalTableData}
      />
    )
  }, [vitalTableData, vitalGraphData, startDate, endDate])

  return (
    <Box
      display={"flex"}
      gap={2}
      pb={1}
      flexDirection={isLineData ? "row" : "column"}
      overflow={"hidden"}
      sx={{
        height: !isLineData && vitalTableData.length > 0 ? "calc(100vh - 200px)" : "auto",
        minHeight: "280px"
      }}
    >
      <Box
        flex={" 0 0 auto"}
        width={"395px"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
      >
        <VitalSelect
          value={selectedVitalType}
          onChange={setSelectedVitalType}
        />
        <DateAndMeasurements />
        { isLineData && vitalTableData.length > 0 &&
          <TableList
            dataTestId={"vitals_table"}
            showHeader={false}
            columns={[{name:""}, {name:""}]}
            rows={vitalTableData.map(v => VitalsDataRow(v.date, v.value, v.unit))}
            emptyListComponent={{message: `No recorded data`}}
          />
        }
      </Box>
      <ViewChart />
    </Box>
  )
}

const VitalSelect = ({ value, onChange}) => {
  const options = [
    {label: "Height", value: VitalsType.HEIGHT},
    {label: "Weight", value: VitalsType.WEIGHT},
    {label: "BMI", value: VitalsType.BMI},
    {label: "Blood pressure", value: VitalsType.BLOOD_PRESSURE},
    {label: "Heart rate", value: VitalsType.HEART_RATE},
  ]
  const boyOptions = [
    { label: removeGenderTag(VitalsType.HC_BOYS), value: VitalsType.HC_BOYS},
    { label: removeGenderTag(VitalsType.LENGTH_BOYS), value: VitalsType.LENGTH_BOYS},
    { label: removeGenderTag(VitalsType.HW_BOYS), value: VitalsType.HW_BOYS},
    { label: removeGenderTag(VitalsType.BMI_BOYS), value: VitalsType.BMI_BOYS},
  ]
  const girlOptions = [
    { label: removeGenderTag(VitalsType.HC_GIRLS), value: VitalsType.HC_GIRLS},
    { label: removeGenderTag(VitalsType.LENGTH_GIRLS), value: VitalsType.LENGTH_GIRLS},
    { label: removeGenderTag(VitalsType.HW_GIRLS), value: VitalsType.HW_GIRLS},
    { label: removeGenderTag(VitalsType.BMI_GIRLS), value: VitalsType.BMI_GIRLS},
  ]

  return(
    <SimpleSelect
      label={"Graph"}
      name={"vital_graph_select"}
      onChange={(e) => onChange(e.target.value as VitalsType)}
      value={value}
    >
      {options.map( option => (
        <MenuItem key={`graph-option-${option.label}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
      <ListSubheader> Boys </ListSubheader>
      {boyOptions.map( option =>
        <MenuItem key={`graph-option-${option.label}`} value={option.value}>
          {option.label}
        </MenuItem>
      )}

      <ListSubheader> Girls </ListSubheader>
      {girlOptions.map( option =>
        <MenuItem key={`graph-option-${option.label}`} value={option.value}>
          {option.label}
        </MenuItem>
      )}

    </SimpleSelect>
  )
}

export default VitalsModal