import { useReportContext } from '../../../providers/ReportProvider'
import { Section, Typography } from 'saga-library/src'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import PermissionButton from '../../../components/PermissionButton'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import { useAccountContext } from '../../../providers/AccountContext'
import { ReportCategoryType } from '../../../types/Report'
import {ReportResults} from './ReportResults'

export const ReportDetails = () => {
  const { tenant_id, report_id } = useParams()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()

  const { reports } = useReportContext()

  const report = reports.find((r) => r.id === report_id)

  if (!report) {
    return <></>
  }

  return (
    <Section.Column
      header={
        <Box display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} gap={1} mb={1}>
          <Box display={"flex"} flexDirection={"column"}>
            <Section.Header lineclamp={1} sx={{ mb: 0 }}>
              {report.name}
            </Section.Header>
            {!!report.description && <Typography variant={"p2"} lineclamp={1}>{report.description}</Typography>}
          </Box>
          {!report.isReference && (
            <PermissionButton
              variant={"outlined"}
              requiredType={report.category === ReportCategoryType.BILLING
                ? PermissionType.ReportingBilling
                : PermissionType.ReportingPatientAndAppointment
              }
              requiredPermission={Permission.READWRITE}
              name={"editReport"}
              onClick={() => navigate(buildTenantRoute(`reports/r/${report_id}/edit`, tenant_id))}
              dataTestId={"report-edit-button"}
            >
              Edit
            </PermissionButton>
          )}
        </Box>
      }
      rightPadding={1}
      sx={{ flex: "1 1 auto" }}
    >
      <ReportResults report={report}/>
    </Section.Column>
  )
}