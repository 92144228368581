import { gql } from "@apollo/client";

export const EXTERNAL_IDENTIFIERS_DETAILS_FRAGMENT = gql`
  fragment ExternalIdentifiersDetails on BillingProfile {
    dndNumber
    wcbBillingNumber
  }
`

export const AHCIP_BILLING_DETAILS_FRAGMENT = gql`
  fragment AhcipBillingDetails on BillingProfile {
    baNumber
    abClaimFacility {
      id
      description
    }
    abClaimFunctionalCenter {
        id
        description
        functionalCenterCode
    }
    skill{
      id
      description
      skillCode
    }
    locumBaNumber
    locationCode
  }
`
export const PRACTITIONER_BILLING_ADMINISTRATION_DETAILS_FRAGMENT = gql`
  fragment PractitionerBillingAdministrationDetails on BillingProfile {
      name
      active
  }
`
export const PRACTITIONER_BILLING_DETAILS_FRAGMENT = gql`
    ${PRACTITIONER_BILLING_ADMINISTRATION_DETAILS_FRAGMENT}
    ${AHCIP_BILLING_DETAILS_FRAGMENT}
    ${EXTERNAL_IDENTIFIERS_DETAILS_FRAGMENT}
    fragment PractitionerBillingDetails on BillingProfile {
        ...PractitionerBillingAdministrationDetails
        ...AhcipBillingDetails
        ...ExternalIdentifiersDetails
    }
`


export const PRACTITIONER_SCHEDULES_DETAILS_FRAGMENT = gql `
  fragment PractitionerSchedulesDetails on Practitioner {
    schedules {
      id
      locationId
      hasFutureAppointments
    }
  }   
`

export const PRACTITIONER_ADMINISTRATIVE_DETAILS_FRAGMENT = gql`
    fragment PractitionerAdministrativeDetails on Practitioner {
        active
        userId
        userFirstName
        userLastName
    }
`

export const PRACTITIONER_SIGNATURE_DETAILS_FRAGMENT = gql`
    fragment PractitionerSignatureDetails on Practitioner {
        signatureFileId
    }
`

export const PRACTITIONER_BILLING_DETAILS_LIST_FRAGMENTS = gql `
    ${PRACTITIONER_BILLING_DETAILS_FRAGMENT}
    fragment PractitionerBillingDetailsList on Practitioner {
        billingProfiles {
            id
            ...PractitionerBillingDetails
        }
    }
`


export const PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT = gql`
  fragment PractitionerIdentifierDetails on Practitioner {
    practitionerId
    connectCareProviderId
  }
`

export const PRACTITIONER_ADDRESS_DETAILS_FRAGMENT = gql`
  fragment PractitionerAddressDetails on Practitioner {
    street1
    street2
    street3
    city
    province
    postalCode
    country
  }
`


export const PRACTITIONER_CONTACT_DETAILS_FRAGMENT = gql`
  fragment PractitionerContactDetails on Practitioner {
    email
    phone {
      id
      number
      extension
      type
      isPrimary
      notes
    }
  }
`

export const PRACTITIONER_PERSONAL_DETAILS_FRAGMENT = gql`
  fragment PractitionerPersonalDetails on Practitioner {
    lastName
    firstName
    middleName
    title
  }
`


export const ADD_NEW_PRACTITIONER = gql`
  mutation AddNewPractitioner($tenantId: ID!, $input: PractitionerInput!) {
    tenant(id: $tenantId) {
      practitioner {
        create(input: $input)
      }
    }
  }
`


export const GET_PRACTITIONER_PROFILE = gql`
    ${PRACTITIONER_PERSONAL_DETAILS_FRAGMENT}
    ${PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
    ${PRACTITIONER_ADDRESS_DETAILS_FRAGMENT}
    ${PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
    ${PRACTITIONER_BILLING_DETAILS_LIST_FRAGMENTS}
    ${PRACTITIONER_ADMINISTRATIVE_DETAILS_FRAGMENT}
    ${PRACTITIONER_SIGNATURE_DETAILS_FRAGMENT}
    ${PRACTITIONER_SCHEDULES_DETAILS_FRAGMENT}
    query GetPractitionerDetails($tenantId: ID!, $practitionerId: ID!) {
        tenant(id: $tenantId) {
            practitioner {
                get(id: $practitionerId) {
                    id
                    version
                    ...PractitionerPersonalDetails
                    ...PractitionerContactDetails
                    ...PractitionerAddressDetails
                    ...PractitionerIdentifierDetails
                    ...PractitionerBillingDetailsList
                    ...PractitionerAdministrativeDetails
                    ...PractitionerSignatureDetails
                    ...PractitionerSchedulesDetails
                }
            }
        }
    }
`

export const UPDATE_PRACTITIONER_PROFILE = gql`
    ${PRACTITIONER_PERSONAL_DETAILS_FRAGMENT}
    ${PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
    ${PRACTITIONER_ADDRESS_DETAILS_FRAGMENT}
    ${PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
    ${PRACTITIONER_BILLING_DETAILS_LIST_FRAGMENTS}
    ${PRACTITIONER_ADMINISTRATIVE_DETAILS_FRAGMENT}
    ${PRACTITIONER_SIGNATURE_DETAILS_FRAGMENT}
    ${PRACTITIONER_SCHEDULES_DETAILS_FRAGMENT}
    mutation UpdatePractitionerDetails(
        $tenantId: ID!
        $practitionerId: ID!
        $input: PractitionerInput!
    ) {
        tenant(id: $tenantId) {
            practitioner {
                update(id: $practitionerId, input: $input) {
                    id
                    version
                    ...PractitionerPersonalDetails
                    ...PractitionerContactDetails
                    ...PractitionerAddressDetails
                    ...PractitionerIdentifierDetails
                    ...PractitionerBillingDetailsList
                    ...PractitionerAdministrativeDetails
                    ...PractitionerSignatureDetails
                    ...PractitionerSchedulesDetails
                }
            }
        }
    }
`


export const LIST_PRACTITIONERS = gql`
  query ListPractitioners($tenantId: ID!) {
    tenant(id: $tenantId) {
      practitioner {
        list {
          id
          active
          lastName
          firstName
          practitionerId
          userId
          userFirstName
          userLastName
        }
      }
    }
  }
`



export const GET_PRACTITIONER_LABEL = gql`
  ${PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
  ${PRACTITIONER_SIGNATURE_DETAILS_FRAGMENT}
  ${PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
  query GetPractitionerPersonalDetails($tenantId: ID!, $practitionerId: ID!) {
    tenant(id: $tenantId) {
      practitioner {
        get(id: $practitionerId) {
          userId
          title
          lastName
          middleName
          firstName
          ...PractitionerContactDetails
          ...PractitionerSignatureDetails
          ...PractitionerIdentifierDetails
        }
      }
    }
  }
`

export const GET_PRACTITIONER_ADDRESS_AND_PHONE = gql`
    ${PRACTITIONER_PERSONAL_DETAILS_FRAGMENT}
    ${PRACTITIONER_CONTACT_DETAILS_FRAGMENT}
    ${PRACTITIONER_ADDRESS_DETAILS_FRAGMENT}
    ${PRACTITIONER_IDENTIFIER_DETAILS_FRAGMENT}
    query GetPractitionerAddressAndPhone($tenantId: ID!, $practitionerId: ID!) {
        tenant(id: $tenantId) {
            practitioner {
                get(id: $practitionerId) {
                    id
                    version
                    ...PractitionerPersonalDetails
                    ...PractitionerContactDetails
                    ...PractitionerAddressDetails
                    ...PractitionerIdentifierDetails
                }
            }
        }
    }
`

export const GET_BILLINGPROFILES = gql`
  query GetBillingProfiles($tenantId: ID!) {
    tenant(id: $tenantId) {
      practitioner {
        listAbBillingProfiles {
          id
          practitionerLastName
          practitionerFirstName
          name
          locationCode
          practitionerId
          facility {
            id
            description
            facilityType
          }
          functionalCenter {
            id
            description
            functionalCenterCode
          }
          skill {
            id
            skillCode
            description
          }
        }
      }
    }
  }
`

