import React, { useState } from "react";
import { IconButton } from "saga-library/src";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu, MenuItem } from "@mui/material";
import { HasPermissionTemplate } from "../../../../components/HasPermissionTemplate";
import { Permission, PermissionType } from "../../../../types/settings/Permission";

export const MoreDocumentOptions = ({ onChangePatient }) => {
  const ViewMore = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return <>
      <IconButton onClick={handleClick} icon={<MoreHorizIcon />}/>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem key={"change-linked-patient"} onClick={onChangePatient} data-testid={"change-linked-patient-menu-item"}>
          Change linked patient
        </MenuItem>
      </Menu>
    </>
  }

  return <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE} >
    <ViewMore />
  </HasPermissionTemplate>
}