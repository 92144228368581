import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { FoxitPDFViewer } from '../../../../components/FoxitPDFViewer'
import {
  PDFAnnotFlag,
  PDFAnnotType,
  PDFUI,
  PDFViewer,
  ViewerEvents
} from '../../../../components/FoxitPDFViewer/FoxitTypes'
import { VitalGraphingMap, VitalsType } from './VitalTypes'
import { patientDisplayName } from 'saga-library/src/util'
import { usePatientProfileContext } from '../../providers/PatientProfileProvider'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import moment from 'moment-timezone'
import { useAccountContext } from '../../../../providers/AccountContext'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import { usePDFViewerEvent } from '../../../../components/FoxitPDFViewer/UsePDFViewerEvent'

const FIRST_CIRCLE_COLOR = 0x479385
const SECOND_CIRCLE_COLOR = 0x014361

const calculateAgeInMonths = (birthDate: moment.Moment | null, currentDate: string): number => {
  if (!birthDate) return 0
  const birthMoment = moment(birthDate)
  const currentMoment = moment(currentDate)
  const wholeMonths = currentMoment.diff(birthMoment, 'months');
  birthMoment.add(wholeMonths, 'months');
  const remainingDays = currentMoment.diff(birthMoment, 'days');
  const fractionalMonths = remainingDays / 30.44; // Average days in a month
  return wholeMonths + fractionalMonths;
};

interface GrowthCartProps {
  vitalType: VitalsType
  vitalTableData: any[]
}

export const GrowthChart = ({
  vitalType,
  vitalTableData
}: GrowthCartProps) => {
  const { profileData } = usePatientProfileContext()
  const formattedBirthDate = useFormattedDate(profileData.dob)
  const { getUserSetting } = useAccountContext()
  let dateFormat = getUserSetting(DATE_FORMAT) as string

  const [loading, setLoading] = useState(true)
  const pdfuiRef = useRef<PDFUI | null>(null)
  const pdfViewerRef = useRef<PDFViewer | null>(null)
  const pdfFunctionRef = useRef<(pdfui: PDFUI) => void>(() => null)

  useEffect(() => {
    setLoading(true)
  }, [vitalType])

  const textBoxAnnotation = (rect, text, fontSize = 10) => {
    return {
      type: PDFAnnotType.freeText,
      rect: rect,
      contents: text,
      defaultAppearance: {
        textColor: 0,
        textSize: fontSize
      },
      borderInfo: {
        style: 6
      },
      flags: PDFAnnotFlag.readOnly
    }
  }

  const circleAnnotation = (x, y, color) => {
    return {
      type: PDFAnnotType.circle,
      rect: { left: x - 3, bottom: y - 3, right: x + 3, top: y + 3 },
      color: color,
      fillColor: color,
      flags: PDFAnnotFlag.readOnly
    }
  }

  const drawNameAndDOB = async (page) => {
    let nameRect = { left: 400, bottom: 727, right: 570, top: 740 }
    let dobRect = { left: 392, bottom: 714, right: 480, top: 727 }
    const { header } = VitalGraphingMap.get(vitalType)?.coordinates
    if (header) {
      nameRect = header.name
      dobRect = header.dob
    }
    await page.addAnnot(textBoxAnnotation(nameRect, patientDisplayName(profileData.firstName, profileData.lastName)));
    await page.addAnnot(textBoxAnnotation(dobRect, formattedBirthDate));
  }

  const drawTable = async (page, tableStrings, tableParameters, fontSize) => {
    let currentStartPoint = tableParameters.start;
    Object.keys(tableStrings).forEach((key, index) => {
      let increment = (tableParameters.shortIncrement && index === 1) ? tableParameters.shortIncrement : tableParameters.increment
      const startPoint = currentStartPoint
      const endPoint = startPoint + increment
      currentStartPoint = endPoint
      page.addAnnot(
        textBoxAnnotation(
          { left: startPoint, bottom: tableParameters.bottom, right: endPoint, top: tableParameters.top },
          tableStrings[key],
          fontSize
        )
      )
    })
  }

  const mappingPoints = (valueParameters1, valueParameters2, value, value2) => {
    const y = valueParameters1.coord_min + (value - valueParameters1.min) * (valueParameters1.coord_max - valueParameters1.coord_min) / (valueParameters1.max - valueParameters1.min)
    const x = valueParameters2.coord_min + (value2 - valueParameters2.min) * (valueParameters2.coord_max - valueParameters2.coord_min) / (valueParameters2.max - valueParameters2.min)
    return { x, y }
  }

  const drawHeadCircumferenceWeight0to24 = async (page, data) => {
    const { headCircumference, age, weight, length, table } = VitalGraphingMap.get(vitalType)?.coordinates
    const tableStrings = {
      date: '\n',
      age: '          (months)\n',
      lengthHeight: '                (cm)\n',
      weight: '               (kg)\n',
      headCircumference: '                   (cm)\n'
    }

    data.forEach((vital, index) => {
      if (vital.headCircumference && vital.headCircumference >= headCircumference.min && vital.headCircumference <= headCircumference.max) {
        const { x, y } = mappingPoints(headCircumference, age, vital.headCircumference, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, FIRST_CIRCLE_COLOR));
      }
      if (vital.weight && vital.weight >= weight.min && vital.weight <= weight.max &&
          vital.lengthHeight && vital.lengthHeight >= length.min && vital.lengthHeight <= length.max) {
        const { x, y } =  mappingPoints(weight, length, vital.weight, vital.lengthHeight);
        page.addAnnot(circleAnnotation(x, y, SECOND_CIRCLE_COLOR));
      }

      if (index < table.rows) {
        tableStrings.date += moment(vital.date).format(dateFormat) + '\n' 
        tableStrings.age += moment(vital.date).diff(moment(profileData.dob), 'months') + '\n'
        tableStrings.lengthHeight += (vital.lengthHeight ?? "") + '\n'
        tableStrings.weight += (vital.weight ?? "") + '\n'
        tableStrings.headCircumference += (vital.headCircumference ?? "") + '\n'
      }
    })
    await drawTable(page, tableStrings, table, 6.4)
  }

  const drawLengthWeight0to24 = async (page, data) => {
    const { age, weight, length, table } = VitalGraphingMap.get(vitalType)?.coordinates
    const tableStrings = {
      date: '\n',
      age: '          (months)\n',
      lengthHeight: '                (cm)\n',
      weight: '               (kg)\n',
    }

    data.forEach((vital, index) => {
      if (vital.lengthHeight && vital.lengthHeight >= length.min && vital.lengthHeight <= length.max) {
        const { x, y } = mappingPoints(length, age, vital.lengthHeight, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, FIRST_CIRCLE_COLOR));
      }
      if (vital.weight && vital.weight >= weight.min && vital.weight <= weight.max) {
        const { x, y } = mappingPoints(weight, age, vital.weight, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, SECOND_CIRCLE_COLOR));
      }

      if (index < table.rows) {
        tableStrings.date += moment(vital.date).format(dateFormat) + '\n' 
        tableStrings.age += moment(vital.date).diff(moment(profileData.dob), 'months') + '\n'
        tableStrings.lengthHeight += (vital.lengthHeight ?? "") + '\n'
        tableStrings.weight += (vital.weight ?? "") + '\n'
      }
    })
    await drawTable(page, tableStrings, table, 6.6)
  }

  const drawHeightWeight2to19 = async (page, data) => {
    const { age, weight, height, table } = VitalGraphingMap.get(vitalType)?.coordinates
    const tableStrings = {
      date: '\n',
      age:  '\n',
      lengthHeight: '                (cm)\n',
      weight: '               (kg)\n',
    }

    data.forEach((vital, index) => {
      if (vital.lengthHeight && vital.lengthHeight >= height.min && vital.lengthHeight <= height.max) {
        const { x, y } = mappingPoints(height, age, vital.lengthHeight, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, FIRST_CIRCLE_COLOR));
      }
      if (vital.weight && vital.weight >= weight.min && vital.weight <= weight.max) {
        const { x, y } = mappingPoints(weight, age, vital.weight, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, SECOND_CIRCLE_COLOR));
      }

      if (index < table.rows) {
        tableStrings.date += moment(vital.date).format(dateFormat) + '\n' 
        tableStrings.age += moment(vital.date).diff(moment(profileData.dob), 'years') + '\n'
        tableStrings.lengthHeight += (vital.lengthHeight ?? "") + '\n'
        tableStrings.weight += (vital.weight ?? "") + '\n'
      }
    })
    await drawTable(page, tableStrings, table, 6.6)
  }

  const drawBMI2to19 = async (page, data) => {
    const { age, bmi, table } = VitalGraphingMap.get(vitalType)?.coordinates
    const tableStrings = {
      date: '\n',
      age:  '\n',
      weight: '               (kg)\n',
      lengthHeight: '                (cm)\n',
      bmi: '\n',
    }

    data.forEach((vital, index) => {
      if (vital.bmi && vital.bmi >= bmi.min && vital.bmi <= bmi.max) {
        const { x, y } = mappingPoints(bmi, age, vital.bmi, calculateAgeInMonths(profileData.dob, vital.date));
        page.addAnnot(circleAnnotation(x, y, FIRST_CIRCLE_COLOR));
      }

      if (index < table.rows) {
        tableStrings.date += moment(vital.date).format(dateFormat) + '\n' 
        tableStrings.age += moment(vital.date).diff(moment(profileData.dob), 'years') + '\n'
        tableStrings.weight += (vital.weight ?? "") + '\n'
        tableStrings.lengthHeight += (vital.lengthHeight ?? "") + '\n'
        tableStrings.bmi += (vital.bmi ?? "") + '\n'
      }
    })
    await drawTable(page, tableStrings, table, 6.6)
  }

  const openFileFailedCallback = useCallback((pdfViewer: PDFViewer, ...args: any[]) => {
    setLoading(false);
  }, [setLoading]);

  usePDFViewerEvent(ViewerEvents.openFileFailed, openFileFailedCallback, pdfViewerRef);

  useEffect( () => {
    setLoading(true)
    pdfFunctionRef.current = async (pdfui) => {
      const url = "/" + VitalGraphingMap.get(vitalType)?.fileName
      const document = await pdfui.openPDFByHttpRangeRequest({
        range: {
          url: url,
        },
      })

      if (!document) {
        console.error('error opening pdf')
        setLoading(false)
        return
      }

      await drawPage(document).then(() => setLoading(false))
    }
  }, [vitalTableData, vitalType, pdfFunctionRef, pdfViewerRef])

  const drawPage = async(document)=> {
    if (vitalTableData.length > 0) {
      const page = await document.getPageByIndex(0)
      if (!page) return
      await drawNameAndDOB(page)
      if (vitalType === VitalsType.HC_BOYS || vitalType === VitalsType.HC_GIRLS) {
        await drawHeadCircumferenceWeight0to24(page, vitalTableData)
      } else if (vitalType === VitalsType.LENGTH_BOYS || vitalType === VitalsType.LENGTH_GIRLS) {
        await drawLengthWeight0to24(page, vitalTableData)
      } else if (vitalType === VitalsType.HW_BOYS || vitalType === VitalsType.HW_GIRLS) {
        await drawHeightWeight2to19(page, vitalTableData)
      } else if (vitalType === VitalsType.BMI_BOYS || vitalType === VitalsType.BMI_GIRLS) {
        await drawBMI2to19(page, vitalTableData)
      }
    }
  }

  return (
    <Box
      flex={"1 1 auto"}
      overflow={"hidden"}
    >
      <FoxitPDFViewer
        loading={loading}
        pdfuiRef={pdfuiRef}
        pdfFunctionRef={pdfFunctionRef}
        pdfViewerRef={pdfViewerRef}
        variant={"no_ui"}
        selectedFile={true}
      />
    </Box>
  )
}