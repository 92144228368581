import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, List, ListItem } from "@mui/material";
import { Typography } from "saga-library/src";
import { useImportedDocumentsStateContext } from "./ImportedDocumentsStateProvider";
import { FileType } from "../../../../types/tasks";
import { useAccountContext } from "../../../../providers/AccountContext";
import { DATE_FORMAT } from "../../../../utils/SettingsConstants";
import moment from "moment-timezone";

type UnlinkedDocumentListProps = {
  items: FileType[]
  dataTestId?: string
}

export const UnlinkedDocumentList = ({items, dataTestId} : UnlinkedDocumentListProps) => {
  const [ listItems, setListItems ] = useState<any[]>([])
  const { getUserSetting } = useAccountContext()
  const { selectedFile, setSelectedFile, loadingFile } = useImportedDocumentsStateContext()

  const formattedDate = useCallback((date) => {
    if(!date){
      return ""
    }

    if(moment(date).isSame(moment(), 'day')){
      return "Today"
    }

    let dateFormat = getUserSetting(DATE_FORMAT) as string
    dateFormat += ' · h:mm A'

    return moment(date).format(dateFormat)
  }, [getUserSetting])

  useEffect(() => {
    setListItems(items.map( (document : FileType, index : number) => {
      return <UnlinkedDocumentListItem
        dataTestId={`${dataTestId}-item-${index}`}
        key={document.id}
        itemId={document.id}
        primaryText={document.originalFileName}
        secondaryText={formattedDate(document.audit.crePit)}
        isActive={!!selectedFile && (document.id === selectedFile?.id)}
        onClick={() => {
          if (loadingFile) return

          setSelectedFile(document)
        }}
      />
    }))
  }, [items, selectedFile, formattedDate, dataTestId, setSelectedFile, loadingFile])

  useEffect(() => {
    if (listItems.length === 0) return
    if (selectedFile) return

    setSelectedFile(items[0])
  }, [items, listItems.length, selectedFile, setSelectedFile])

  return (
    <List data-testid={dataTestId} sx={{ overflowY: 'auto', flex: '1 1 auto', pt: 0}}>
      {listItems}
    </List>
  )
}

interface UnlinkedDocumentListItemProps {
  isActive: boolean
  primaryText: string
  secondaryText?: string | null
  onClick?: () => void
  itemId: string
  dataTestId?: string
}

const UnlinkedDocumentListItem = ({
  isActive, onClick, secondaryText, primaryText, itemId, dataTestId
} : UnlinkedDocumentListItemProps) => {
  const primaryTextFontColor = isActive ? 'primary.main' : 'greys.dark'
  const secondaryTextFontColor = isActive ? 'primary.main' : 'greys.light'

  return (
    <ListItem
      data-testid={dataTestId}
      key={itemId}
      sx={{
        display: 'block',
        padding: 0,
        paddingRight: 1,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        sx={{
          py: 1,
          px: 1,
          alignItems: 'flex-start',
          color: primaryTextFontColor,
          backgroundColor: isActive ? 'backgrounds.selected' : 'backgrounds.none',
          '&:hover': {
            backgroundColor: isActive ? 'backgrounds.selectedHover' : 'backgrounds.hover',
            cursor: 'pointer',
          },
        }}
        onClick={onClick}
      >
          <Typography data-testid={`${dataTestId}-primary-text`} sx={{color: primaryTextFontColor}} lineclamp={1} >{primaryText}</Typography>
          { secondaryText && <Typography data-testid={`${dataTestId}-secondary-text`} variant={'body2'} lineclamp={1} sx={{color: secondaryTextFontColor}} >{secondaryText}</Typography> }
      </Box>
      <Divider
        sx={{
          borderWidth: '1px',
        }}/>
    </ListItem>
  )
}
