import { Box } from '@mui/material'
import { OpenInNewTab, RemoveButton, Typography, IconButton, Chip, SimpleTooltip } from 'saga-library/src'
import React from "react";
import { patientDisplayName } from 'saga-library/src/util/formatting'
import { useAccountContext } from "../../../providers/AccountContext";
import { useParams } from "react-router-dom";
import { FamilyRelationship } from '../../../types/patients/Patient'
import { usePatientProfileContext } from '../../../apps/patients/providers/PatientProfileProvider'
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import { displayRelationship } from '../../../apps/patients/util/displayEnum'
import { useFormContext, useWatch } from 'saga-library/src/components/Form'

type FamilyRelationshipBlockProps = {
  blockKey: string
  relationship: FamilyRelationship
  onDelete: () => void
  setAsEmergencyContact: () => void
  removeEmergencyContact: () => void
  dataTestId: string
}

export const FamilyRelationshipBlock = ({blockKey, relationship, dataTestId, onDelete, setAsEmergencyContact, removeEmergencyContact}: FamilyRelationshipBlockProps) => {
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const { profileData } = usePatientProfileContext()
  const { control } = useFormContext();

  const newPatientFirstName = useWatch({
    control,
    name: 'firstName',
  })

  const openPatientInNewTab = (relationship) => {
    window.open(buildTenantRoute(`patients/p/${relationship.relatedPatientId}`, tenant_id))
  }

  const IconAndText = ({Icon, text}) => {
    if (!text) return null
    return (
      <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
        <Icon sx={{ fontSize:"20px" }}/>
        <Typography variant={'p2'} lineclamp={1}>{text}</Typography>
      </Box>
    )
  }

  const EmergencyContactButton = ({isEmergencyContact, dataTestId}) => {
    if (isEmergencyContact) {
      return (
        <SimpleTooltip title='Remove emergency contact'>
          <Box>
            <IconButton
              data-testid={dataTestId}
              onClick={removeEmergencyContact}
              icon={<ContactEmergencyIcon />}
            />
          </Box>
        </SimpleTooltip>
      )
    }
    return (
      <SimpleTooltip title='Set emergency contact'>
        <Box>
          <IconButton
            data-testid={dataTestId}
            onClick={setAsEmergencyContact}
            icon={<ContactEmergencyOutlinedIcon />}
          />
        </Box>
      </SimpleTooltip>
    )
  }

  return (
    <Box
      key={blockKey}
      data-testid={dataTestId}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        p: 1,
        borderRadius: 2,
        border: '1px solid rgba(32, 41, 49, 0.32)',
        flexShrink: 0,
        alignSelf: 'stretch',
        my: 1
      }}
    >
      <Box display={"flex"} flexDirection={"row"} gap={"4px"} alignItems={'center'}>
        <Typography variant={'body1'} lineclamp={1}>{patientDisplayName(relationship.firstName, relationship.lastName)}</Typography>
        {relationship.isEmergencyContact && <Chip label={"EMERGENCY CONTACT"} size={'small'} />}
      </Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"column"} gap={"2px"} sx={{color: 'greys.medium'}}>
          <Typography variant={'p2'} lineclamp={1}>{displayRelationship(relationship.relationshipType)} to {newPatientFirstName || profileData.firstName}</Typography>
          <IconAndText Icon={PhoneOutlinedIcon} text={phoneNumberMask(relationship.primaryPhoneNumber)} />
          <IconAndText Icon={MailOutlineIcon} text={relationship.email} />
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={'center'} gap={0}>
          <EmergencyContactButton isEmergencyContact={relationship.isEmergencyContact} dataTestId={`${dataTestId}-emergencyContact`} />
          <OpenInNewTab onOpenInNewTab={() => openPatientInNewTab(relationship)} dataTestId={`${dataTestId}-openInNew`} sx={{m:0}} />
          <RemoveButton onClick={onDelete} dataTestId={dataTestId}/>
        </Box>
      </Box>
    </Box>
  )
}
