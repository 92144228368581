import React from 'react'
import { Box, MenuItem } from "@mui/material";
import {
  Checkbox,
  Section,
  Select,
  TextField, TextFieldMask
} from "saga-library/src";
import { SkillSelect } from '../../../components/SkillSelect'
import NewbornCodeSelect from "../../../components/NewbornCodeSelect/NewbornCodeSelect";
import { LocationCodeSelect } from "../../../components/LocationCodeSelect";
import { PayToOptions } from "../../../utils/ABClaimConstants";
import { InterceptReasonSelect } from "../../../components/InterceptReasonSelect/InterceptReasonSelect";
import { AlbertaPHNFormatMask } from "../../../utils/validateIdentifiers";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import FormattedDatePicker from "../../../components/FormattedDatePicker";
import { FormFragment } from "../../../components/PermissionForm";
import { PermissionType } from "../../../types/settings/Permission";
import { FacilitySearch } from "../../../components/SearchControls/Claims/FacilitySearch";

const Form = () => {

  const { control } = useFormContext()
  const newBornCode = useWatch({
    control,
    name: 'newBornCode',
  })

  const serviceDate = useWatch({
    control,
    name: 'serviceDate',
  })

  return (
    <FormFragment requiredPermissionType={PermissionType.Billing}>
      <Box display={'flex'} flexDirection={'row'} gap={2}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            width: '100%',
            gap: '4px'
          }}
        >
          <Section.FormSection label={'Options'}>
            <Checkbox
              name={'paperDocumentation'}
              label={'Paper documentation to follow'}
            />
            <Checkbox
              name={'confidential'}
              label={'Confidential'}
            />
          </Section.FormSection>

          <Section.FormSection label={'Original encounter information'}>
            <FormattedDatePicker
              label={'Original encounter date'}
              name={'originalEncounterDate'}
            />
            <FacilitySearch
              name={'originalFacility'}
              label={'Original facility'}
              serviceDate={serviceDate}
            />
            <LocationCodeSelect
              label={'Original location'}
              name={'originalLocationCode'}
            />
          </Section.FormSection>

          <Section.FormSection label={'Patient'}>
            <NewbornCodeSelect
              name={'newBornCode'}
              label={'Newborn'}
            />
            {
              newBornCode !== null && (
                <>
                  <TextFieldMask
                    formatMask={AlbertaPHNFormatMask}
                    maskChar={''}
                    name={'guardianUli'}
                    label={'Guardian/parent ULI'}
                    sx={{
                      flex: '1 1 auto',
                    }}
                  />
                  <TextField
                    name={'guardianRegNumber'}
                    label={'Guardian/parent registration number'}
                    type={"number"}
                  />
                </>
              )
            }
          </Section.FormSection>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            width: '100%',
            overflowX: 'hidden',
            gap: '4px'
          }}
        >
          <Section.FormSection label={'Practitioner'}>
            <SkillSelect provinceCode={'AB'} />
          </Section.FormSection>

          <Section.FormSection label={'Payment'}>
            <Select
              label={'Pay to'}
              name={'payToCode'}
            >
              {PayToOptions.map(({label, value}) =>
                <MenuItem value={value}>{label}</MenuItem>
              )}
            </Select>
            <TextField name={'payToUli'} label={'Pay to ULI'} />
            <InterceptReasonSelect
              name={'interceptReason'}
              label={'Intercept reason'}
            />
          </Section.FormSection>
        </Box>
      </Box>
    </FormFragment>
  )
}

export const ClaimOptions = {
  Form: Form,
}
