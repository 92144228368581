import React, { useEffect, useState } from "react";
import { Radio, RadioGroup, Form, SaveButton, useForm } from "saga-library/src";
import { useParams } from "react-router-dom";
import { LoadingSpinner } from "../../components/LoadingScreen";
import { BillingSettings } from "../../types/settings/BillingProfile";
import SettingsHeader from "./components/SettingsHeader";
import { Permission, PermissionType } from "../../types/settings/Permission";
import { useAccountContext } from "../../providers/AccountContext";
import PermissionButton from "../../components/PermissionButton";
import { ReadOnlyFormActionsMask } from "../../components/ReadOnlyFormActionsMask";
import { useTenantContext } from "../../providers/TenantContextProvider";
import { tenantUserSettings } from "../../utils/TenantUserSettings";
import { usePrompt } from "../../providers/NavigationPrompt";
import { SettingsSectionColumn } from "./components/SettingsSectionColumn";
import { FormProvider } from "react-hook-form";

const FORM_NAME = "billing_form"

export const Billing = () => {
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const { getUserTenantSettings, updateUserTenantSetting } = useTenantContext()
  const hasReadWrite = tenant_id && userHasPermission(tenant_id, PermissionType.Admin, Permission.READWRITE)
  const { BILLING_RESET_DATE_SETTING } = tenantUserSettings
  const billingSetting = getUserTenantSettings([BILLING_RESET_DATE_SETTING])[BILLING_RESET_DATE_SETTING.name]
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<{billing_reset_date:string}>({})
  const {
    handleSubmit,
    formState: { dirtyFields,isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    reset({billing_reset_date: billingSetting as string})
  }, [billingSetting])

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  const saveSetting = handleSubmit(
    (data) => {
      console.log(data.billing_reset_date)
      updateUserTenantSetting(BILLING_RESET_DATE_SETTING, data.billing_reset_date)
    }
  )

  const billingOptions = [
    {label: 'Today', value: BillingSettings.today},
    {label: 'Last claim', value: BillingSettings.last}
  ]

  return (
    <>
      <SettingsSectionColumn header={
        <SettingsHeader
          title={'Billing settings'}
          actions={
            <ReadOnlyFormActionsMask requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}>
              <SaveButton
                form={FORM_NAME}
                submitting={isSubmitting}
                dataTestId={"billing"}
              />
            </ReadOnlyFormActionsMask>
          }
          dataTestId={'billingSettings'}
        />
      }>
        {billingSetting ? (
          <FormProvider {...formMethods}>
            <Form onSubmit={saveSetting} id={FORM_NAME}>
              <RadioGroup
                label={'Reset service date for new claims to'}
                name={"billing_reset_date"}
                disabled={!hasReadWrite}
                options={billingOptions}
                dataTestId={"billing-radioGroup"}
              />
          </Form>
        </FormProvider>
        ): <LoadingSpinner />}
      </SettingsSectionColumn>
    </>
  )
}
