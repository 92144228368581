import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, ConfirmationDialog } from 'saga-library/src'
import { PractitionerSelect } from 'components/PractitionersSelect'
import { PermissionForm } from '../../../../components/PermissionForm'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { FormRow } from '../../../../components/FormRow'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { LinkIcon } from '../../../../components/Icons/LinkIcon'
import { EncounterNoteTemplateSelect } from '../../../../components/EncounterNoteTemplateSelect'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useTenantContext } from '../../../../providers/TenantContextProvider'
import { useSearchParams } from 'react-router-dom'
import { PenIcon } from '../ChartIcons'
import { IconButton } from 'saga-library/src'
import { Box, SxProps, Typography } from '@mui/material'
import { useAppointmentContext } from '../../providers/AppointmentProvider'
import { useReadQuery } from '@apollo/client'
import { PatientAppointmentDialog } from './PatientAppointmentDialog'
import { EncounterNote, EncounterNoteTemplate, PatientAppointment } from '../../../../types/patients'
import { useEncounterNoteTemplateContext } from '../../../../providers/EncounterNoteTemplateProvider'
import { EncounterNoteTemplateFields } from "./EncounterNoteTemplateFields";
import { LoadingSpinner } from '../../../../components/LoadingScreen'
import { useFormattedDate } from '../../../../hooks/FormattedDate'
import { Theme } from '@mui/system'
import { practitionerDisplayName } from 'saga-library/src/util/formatting'
import { HasPermissionTemplate } from '../../../../components/HasPermissionTemplate'

interface EncounterNotesFormProps {
  onSubmit: (event: React.BaseSyntheticEvent) => void,
  formName: string,
  formDisabled?: boolean,
  encounterNote?: EncounterNote | null,
  dataTestId?: string,
  appointment?: PatientAppointment,
  sx?: SxProps<Theme>
}

export const EncounterNotesForm = ({
  onSubmit,
  formName,
  formDisabled = false,
  encounterNote,
  dataTestId,
  appointment,
  sx
}: EncounterNotesFormProps) => {
  const { userId } = useAccountContext()
  const { practitioners } = useTenantContext()
  const {
    control,
    setValue,
    getValues,
    watch,
    reset
  } = useFormContext()
  const [searchParams] = useSearchParams()

  const { appointment_id, practitioner_id } = Object.fromEntries(new URLSearchParams(searchParams))

  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false)

  const { appointmentQueryRef, getAppointmentQueryResults } = useAppointmentContext()
  const { data: appointmentData } = useReadQuery(appointmentQueryRef!)
  const patientAppointments = useMemo(() => getAppointmentQueryResults(appointmentData), [appointmentData, getAppointmentQueryResults])

  const { loading: isLoading, getEncounterNoteTemplate } = useEncounterNoteTemplateContext()

  const [ currentEncounterNoteTemplateId, setCurrentEncounterNoteTemplateId] = useState<string>('')
  const [ encounterNoteTemplate, setEncounterNoteTemplate ] = useState<EncounterNoteTemplate>()
  const [ templateChangedMessage, setTemplateChangedMessage ] = useState<string>('')

  useEffect(() => {
    setEncounterNoteTemplate(getEncounterNoteTemplate(currentEncounterNoteTemplateId))
  }, [currentEncounterNoteTemplateId])

  const isSigned = !!encounterNote?.signOffUser?.id

  const watchedTemplateFields = currentEncounterNoteTemplateId ? watch(getEncounterNoteTemplate(currentEncounterNoteTemplateId)?.fields?.map(field => `textbox-${field.id}`) || []): []

  const encounterNoteTemplateId = useWatch({
    control,
    name: 'templateId'
  })

  const appointmentId = useWatch({
    control,
    name: 'appointmentId'
  })

  useEffect(() => {
    if (appointment_id) {
      setValue('appointmentId', appointment_id, { shouldDirty: false })
    }
  }, [appointment_id])

  useEffect(() => {
    const userPractitionerId = practitioners.find(p => p.userId === userId)?.id
    if (!!userPractitionerId) {
      setValue('practitionerId', userPractitionerId, { shouldDirty: false })
    }
  }, [userId, practitioners])

  useEffect(() => {
    if (practitioner_id) {
      let practitionerId = practitioners.find(practitioner => practitioner.id === practitioner_id)?.id
      setValue('practitionerId', practitionerId, { shouldDirty: false })
    }
  }, [practitioner_id, practitioners])

  useEffect(() => {
    if (appointment) {
      setValue('appointmentId', appointment.id, { shouldDirty: false })
    }
    if (appointment?.practitioner) {
      let practitionerId = practitioners.find(practitioner => practitioner.id === appointment.practitioner.id)?.id
      setValue('practitionerId', practitionerId, { shouldDirty: false })
    }
  }, [appointment, practitioners])

  const hasValueInWatchedFields = () => {
    return Object.keys(watchedTemplateFields).some(key => {
      return !!watchedTemplateFields[key]
    })
  }

  useEffect(() => {
    if (currentEncounterNoteTemplateId === encounterNoteTemplateId) {
      return
    }

    if (hasValueInWatchedFields()) {
      setTemplateChangedMessage('This encounter note has been modified.')
    } else {
      setCurrentEncounterNoteTemplateId(encounterNoteTemplateId)
    }
  }, [encounterNoteTemplateId])

  const onPatientAppointmentModalClosed = useCallback(() => {
    setOpenAppointmentDialog(false)
  }, [setOpenAppointmentDialog])

  const onPatientAppointmentSelected = (appointment: PatientAppointment | null) => {
    if (appointment) {
      setValue('appointmentId', appointment.id, { shouldDirty: true })
      if (!!appointment.practitioner) {
        let practitionerId = practitioners.find(practitioner => practitioner.id === appointment.practitioner.id)?.id
        setValue('practitionerId', practitionerId, { shouldDirty: true })
      }
    } else {
      setValue('appointmentId', null, { shouldDirty: true })
    }

    setOpenAppointmentDialog(false)
  }

  const changeTemplateConfirmationDialog = () => {
    if (!encounterNoteTemplateId) {
      setEncounterNoteTemplate(undefined)
    } else {
      reset({
        "templateId": encounterNoteTemplateId,
        "appointmentId": getValues().appointmentId,
        "practitionerId": getValues().practitionerId
      })
      setCurrentEncounterNoteTemplateId(encounterNoteTemplateId)
    }

    confirmationDialogClose(true)
  }

  const confirmationDialogClose = (discard: boolean) => {
    setTemplateChangedMessage('')

    if (!discard) {
      setValue('templateId', currentEncounterNoteTemplateId, { shouldDirty: false })
    }
  }

  const GetAppointmentText = () => {
    let appointment = patientAppointments.find(appointment => appointment.id === appointmentId)
    let appointmentTime = useFormattedDate(appointment?.start, true, true)

    if (!appointmentId) {
      return (
        <Button
          size={'small'}
          variant={'text'}
          startIcon={<LinkIcon />}
          name={'encounter-note-link'}
          dataTestId={'encounter-note-link'}
          onClick={() => setOpenAppointmentDialog(true)}
          sx={{
            minWidth: '187px',
            alignSelf: 'center'
          }}
        >
          Link an appointment
        </Button>
      )
    }

    return (
      <MetadataItem
        label={'Appointment'}
        text={appointmentTime}
        sx={{ minWidth: '300px' }}
      >
        {!isSigned && (
          <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
            <IconButton
              dataTestId={'edit-appointment-icon-button'}
              icon={<PenIcon />}
              onClick={() => setOpenAppointmentDialog(true)}
            />
          </HasPermissionTemplate>
        )}
      </MetadataItem>
    )
  }

  const Form = (
    <>
      <GetAppointmentText />
      {!appointmentId &&
        <FormattedDatePicker
          name={'encounterNoteDate'}
          label={'Date'}
          dataTestId={`${dataTestId}-encounter-date`}
        />
      }
      {!encounterNote && (
        <EncounterNoteTemplateSelect
          name={'templateId'}
          label={'Template'}
          dataTestId={`${dataTestId}-template`}
        />
      )}
      <PractitionerSelect
        name={'practitionerId'}
        label={'Practitioner'}
        dataTestId={`${dataTestId}-practitioner`}
      />
    </>
  )

  return (
    <>
      <PermissionForm
        name={`${formName}_encounter_notes_form`}
        onSubmit={onSubmit}
        id={formName}
        requiredPermissionType={PermissionType.Chart}
        readOnlyOverride={true}
        formDisabled={formDisabled}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ...sx
        }}
      >
        <FormRow>
          {!isSigned
            ? Form
            : <MetadataRow encounterNote={encounterNote} patientAppointments={patientAppointments} appointmentId={appointmentId} />
          }
        </FormRow>
        {isLoading
          ? <LoadingSpinner />
          : <EncounterNoteTemplateFields template={encounterNoteTemplate} isSigned={isSigned} />
        }
      </PermissionForm>
      <PatientAppointmentDialog
        openDialog={openAppointmentDialog}
        patientAppointments={patientAppointments}
        onSelected={onPatientAppointmentSelected}
        onModalClose={onPatientAppointmentModalClosed}
      />
      <ConfirmationDialog
        open={!!templateChangedMessage}
        title={'Change encounter note template?'}
        message={templateChangedMessage}
        primaryAction={changeTemplateConfirmationDialog}
        primaryLabel={'discard'}
        onClose={() => confirmationDialogClose(false)}
        dataTestId={'changedSharedForm-dialog'}
      />
    </>
  )
}

const MetadataRow = ({ encounterNote, patientAppointments, appointmentId }: {
  encounterNote: EncounterNote,
  patientAppointments: PatientAppointment[],
  appointmentId?: string
}) => {
  const appointment = patientAppointments.find(appointment => appointment.id === appointmentId)
  const appointmentTime = useFormattedDate(appointment?.start, true, true)

  const encounterNoteDate = useFormattedDate(encounterNote.encounterNoteDate)

  return (
    <Box display={"flex"} alignItems={"center"} gap={4} py={2}>
      {appointmentId
        ? <MetadataItem
            label={'Appointment'}
            text={appointmentTime}
          />
        : <MetadataItem
            label={'Date'}
            text={encounterNoteDate}
          />
      }
      <MetadataItem
        label={'Practitioner'}
        text={practitionerDisplayName(encounterNote!.practitioner!.firstName, encounterNote!.practitioner!.lastName)}
      />
    </Box>
  )
}

const MetadataItem = ({ label, text, sx, children }: {
  label: string,
  text: string,
  sx?: SxProps<Theme>,
  children?: React.ReactNode
}) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      flexShrink={0}
      alignItems={'center'}
      alignSelf={'center'}
      mb={1}
      sx={sx}
    >
      <Typography key={`${label}-title`} variant={'body1'} sx={{ alignSelf: 'center' }}>
        {label}:&nbsp;
      </Typography>
      <Typography key={`${label}-text`} variant={'h5'} sx={{ alignSelf: 'center' }}>
        {text}
      </Typography>
      {children}
    </Box>
  )
}