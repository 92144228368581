import React from 'react'
import { MenuItem } from "@mui/material";
import { TextField, Section, Select } from 'saga-library/src'

import { rules } from '../util/validation'
import { FormFragment } from "../../../../components/PermissionForm";

export type PersonalDetailsType = {
  lastName: string
  firstName: string
  middleName: string
  title: string
}

const defaults = {
  lastName: '',
  firstName: '',
  middleName: '',
  title: '',
}

const Form = ({requiredPermissionType}) => {
  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Personal</Section.Header>
        <TextField
          label={'Last name'}
          name={'lastName'}
          inputProps={{ maxLength: rules.lastName.max }}
          dataTestId={"personalDetails-lastName"}
        />
        <TextField
          label={'First name'}
          name={'firstName'}
          inputProps={{ maxLength: rules.firstName.max }}
          dataTestId={"personalDetails-firstName"}
        />
        <TextField
          label={'Middle name'}
          name={'middleName'}
          inputProps={{ maxLength: rules.middleName.max }}
          dataTestId={"personalDetails-middleName"}
        />
        <Select
          label={'Title'}
          name={'title'}
          dataTestId={"personalDetails-title-select"}
          allowNullOption={true}
        >
          <MenuItem value={'mr'} data-testid={"personalDetails-menuItem-mr"}>Mr.</MenuItem>
          <MenuItem value={'mrs'} data-testid={"personalDetails-menuItem-mrs"}>Mrs.</MenuItem>
          <MenuItem value={'miss'} data-testid={"personalDetails-menuItem-miss"}>Miss.</MenuItem>
          <MenuItem value={'ms'} data-testid={"personalDetails-menuItem-ms"}>Ms.</MenuItem>
          <MenuItem value={'dr'} data-testid={"personalDetails-menuItem-dr"}>Dr.</MenuItem>
        </Select>
      </Section.FormSection>
    </FormFragment>
  )
}

export const PersonalDetails = {
  Form: Form,
  defaults: defaults,
}
