import { TableListCellConfig, TableListRowConfig, Typography } from "saga-library/src";
import React from "react";
import { FormType } from "../../../../../types/patients";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { useFormattedDate } from "../../../../../hooks/FormattedDate";

function FormatRowData(patientForm: any): TableListCellConfig[] {
  const { formDate, name, practitioner, description } = patientForm
  const formDateFormatted = useFormattedDate(formDate)

  return [
    {
      children:
        <Typography variant={"body2"}>
          {formDateFormatted ?? ''}
        </Typography>
    },
    {
      children:
        <Typography lineclamp={1} variant={"body2"} style={{ wordBreak: 'break-all' }}>
          {name ?? ''}
        </Typography>
    },
    {
      children:
        <Typography variant={"body2"}>
          {practitioner && practitionerDisplayName(practitioner.firstName, practitioner.lastName)}
        </Typography>
    },
    {
      children:
        <Typography lineclamp={1} variant={"body2"} style={{ wordBreak: 'break-all' }}>
          {description ?? ''}
        </Typography>
    },
  ]
}


export const FormsRow = (form: FormType, rowClicked: (form:FormType) => void): TableListRowConfig => {
  if(form) {
    return ({
      onClick: ()=>rowClicked(form),
      rowData: FormatRowData(form),
      key: `${form.id!}_${form.isLinkedDocument?'d':'l'}`
    })
  }
  return {rowData: [], key: ""}
}