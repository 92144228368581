import { PrintButton, PrintIconButton } from 'saga-library/src'
import React, { useRef, useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { AbLabResult } from '../../../../types/patients'
import { FileType } from '../../../../types/tasks'
import { useDocumentContext } from '../../../../providers/DocumentProvider'
import { PDFUI, PDFViewer } from '../../../../components/FoxitPDFViewer/FoxitTypes'
import { FoxitPDFViewer } from '../../../../components/FoxitPDFViewer'
import { PrintOnly } from 'components/PrintOnly'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import _get from 'lodash/get'
import { LinkedDocumentsPrintButton } from '../print/LinkedDocumentsPrintButton'
import { PagesToInclude } from '../../../../types/Document'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { getPageRange } from '../print/PrintPdfModal'

interface LabAndInvestigationPrintButtonProps {
  file?: FileType | null,
  labAndInvestigation?: AbLabResult | null,
  printComponentRef: React.RefObject<any>,
  patientId: string | undefined,
  onComplete?: () => void,
  onBeforeGetContent?: () => void,
}

export const LabAndInvestigationPrintButton = ({
  labAndInvestigation,
  printComponentRef,
  patientId,
  onComplete,
  onBeforeGetContent,
}: LabAndInvestigationPrintButtonProps) => {
  const { showErrorAlert } = useAlerts()
  const { tenant_id } = useParams()
  const [loading, setLoading] = useState(false)
  const { getFileTypeUriWithToken } = useDocumentContext()
  const labPdfUiRef = useRef<PDFUI | null>(null)
  const labPdfViewerRef = useRef<PDFViewer | null>(null)
  const activityTracking = useActivityTracking()

  let hasResultsWithEmbeddedFiles = !!labAndInvestigation ? labAndInvestigation.abLabResultObservationResults.filter(result => result.observationValue === "Embedded PDF").length > 0 : false
  const firstFile = useRef<FileType | undefined>(labAndInvestigation?.abLabResultObservationResults.filter(result => !!result.file)[0]?.file)
  let otherLabs = labAndInvestigation?.abLabResultObservationResults
    ? [...labAndInvestigation.abLabResultObservationResults.filter(result => !!result.file).slice(1)]
    : []

  const [getLabAndInvestigation] = useLazyQuery(GET_LAB_AND_INVESTIGATION, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async data => {
      labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.get')
      firstFile.current = labAndInvestigation?.abLabResultObservationResults.filter(result => !!result.file)[0]?.file
      otherLabs = labAndInvestigation?.abLabResultObservationResults
        ? [...labAndInvestigation.abLabResultObservationResults.filter(result => !!result.file).slice(1)]
        : []
    },
    onError: error => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!patientId || !labAndInvestigation) return
    activityTracking.logPrintActivity(UserActivityObjectType.LINKED_DOCUMENT, labAndInvestigation.id, patientId)
    setLoading(false)
  }

  const printPdfViewers = async (printType: number, pageRange: string, pagesToInclude: PagesToInclude) => {
    if (!labPdfUiRef.current || !firstFile || !firstFile.current) return

    setLoading(true)
    try {
      let fileUri = await getFileTypeUriWithToken(firstFile.current)
      if (!fileUri) return
      if (!labPdfUiRef.current) return

      const printPdfDoc = await labPdfUiRef.current.loadPDFDocByHttpRangeRequest({
        range: {
          url: fileUri
        }
      }, {})

      if(!printPdfDoc) return
      let totalPageCount = printPdfDoc.getPageCount()

      await Promise.all(otherLabs.map(async (result) => {
        if (!labPdfUiRef.current || !result.file) return
        let fileUri = await getFileTypeUriWithToken(result.file)
        if(!fileUri) return
        if (result.file?.originalFileName.split('.').pop() === 'jpg') {
          const response = await fetch(fileUri)
          let blob = await response.blob()
          let imageDoc = await labPdfUiRef.current.convertImageToPDFDoc(blob, fileUri, '', '')
          await labPdfUiRef.current.renderDoc(imageDoc, 1);
          if(!imageDoc) return
          await printPdfDoc.mergePDFDoc({doc: imageDoc})
          totalPageCount += imageDoc.getPageCount()
        }
        else {
          let tempDoc = await labPdfUiRef.current.loadPDFDocByHttpRangeRequest({
            range: {
              url: fileUri
            }
          }, {})
          if(!tempDoc) return
          await printPdfDoc.mergePDFDoc({doc: tempDoc})
          totalPageCount += tempDoc.getPageCount()
        }
      }))

      let baseFile = await printPdfDoc.getFile()
      await labPdfUiRef.current.openPDFByFile(baseFile);
      totalPageCount = printPdfDoc.getPageCount()

      await labPdfViewerRef?.current?.printEx({ type: printType, pageRange: getPageRange(pagesToInclude, totalPageCount, pageRange), progress: true }, (data) => {
        printPdfCallback(data)
      })
    }
    catch (error) {
      console.error(error)
      showErrorAlert('There was an issue printing.')
      setLoading(false)
    }
  }

  const handlePrint = async (printType: number, pageRange: string, pagesToInclude: PagesToInclude) => {
    try {
      getLabAndInvestigation({
        variables: {
          tenantId: tenant_id,
          patientId: patientId,
          id: labAndInvestigation?.id
        }
      }).then(() => {
        printPdfViewers(printType, pageRange, pagesToInclude).then(() => {})
      }).catch((error) => {
        showErrorAlert('There was an issue printing.')
      })
    } catch (error) {
      console.log(error)
      return
    }
  }

  const printDocumentPdfViewer = () => {
    firstFile.current = labAndInvestigation?.linkedDocument?.file
  }

  if(hasResultsWithEmbeddedFiles || !!labAndInvestigation?.isLinkedDocument) {
    return (
      <>
      {
        labAndInvestigation?.isLinkedDocument
          ? (
            <LinkedDocumentsPrintButton
              patientId={patientId}
              onPrintClicked={handlePrint}
              onPrintModalOpen={printDocumentPdfViewer}
              documentId={labAndInvestigation?.linkedDocument?.id}
              dataTestId={'lab-and-investigation-document'}
            />
          )
          : (
            <PrintIconButton
              disabled={loading}
              onClick={() => handlePrint(0, '1', PagesToInclude.ALL)}
              dataTestId={'lab-and-investigation'}
            />
          )
      }
      <PrintOnly componentKey={`${labAndInvestigation?.id}-print-only-area`}>
          <FoxitPDFViewer
            key={'print-pdf-viewer'}
            pdfuiRef={labPdfUiRef}
            pdfViewerRef={labPdfViewerRef}
            loading={loading}
            selectedFile={undefined}
            variant={'labs'}
          />
        </PrintOnly>
      </>
    )
  }
  else {
    return (
      <PrintButton
        type={'icon'}
        componentRef={printComponentRef}
        documentTitle={'Labs and investigations'}
        dataTestId={'labs-and-investigations'}
        name={'labs-and-investigations'}
        objectType={UserActivityObjectType.PATIENT}
        objectId={patientId}
        onComplete={onComplete}
        onBeforeGetContent={onBeforeGetContent}
      />
    )
  }
}
