import React, { useEffect, useMemo, useState } from 'react'
import {
  ButtonDropdown,
  ConfirmationDialog,
  Section,
  TableList,
  TableListHeaderConfig,
  FilterSelect
} from "saga-library/src";
import { PatientPrescriptionRow } from "./PatientPrescriptionRow";
import { Box } from "@mui/material";
import { PatientSectionHeader } from "../PatientSectionHeader";
import {
  PrescriptionStatus,
  PrescriptionStatusMap,
  PrescriptionType,
  PrescriptionUsage
} from "../../../../types/Prescription";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { useAccountContext } from "../../../../providers/AccountContext";
import { useNavigate, useParams } from "react-router-dom";
import { HasPermissionTemplate } from "../../../../components/HasPermissionTemplate";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { schema } from "./components/PrescriptionFormValidationsSchema";
import moment from "moment-timezone";
import { usePrescriptionContext } from "../../providers/PrescriptionProvider";
import { useReadQuery } from "@apollo/client";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import {
  PRESCRIPTIONS_SCROLL_KEY,
  PRESCRIPTIONS_FILTER_KEY,
  useUserInteraction
} from '../../../../providers/UserInteractionContext'

const columns: TableListHeaderConfig[] = [
  { name: 'Status', sortable: true },
  { name: 'Last prescribed', sortable: true },
  { name: 'Prescription' },
  { name: 'Prescriber' },
]

const PrescriptionStatusOptions = [
  {label: "All", value: "*"},
  ...Array.from( PrescriptionStatusMap ).map(([key, value]) => {
    return {
      label: value.label,
      value: key
    }
  })
]

const SectionTitle = 'Prescriptions'


export const PatientPrescriptions = ChartPanelHOC(SectionTitle, () => {
  return  <PatientPrescriptionsPanel />
})


const PatientPrescriptionsPanel = () => {
  const { buildTenantRoute } = useAccountContext()
  const navigate = useNavigate()
  const { tenant_id, patient_id } = useParams()
  const { showWarningAlert } = useAlerts()
  const { getFilter, saveFilter } = useUserInteraction()

  const { prescriptionsQueryRef, parsePrescriptionQueryResults } = usePrescriptionContext()
  const { data } = useReadQuery(prescriptionsQueryRef!)
  const patientPrescriptions = useMemo(()=> parsePrescriptionQueryResults(data), [data, parsePrescriptionQueryResults])

  const { renewAllActiveAndContinuousPrescriptions, renewSelectedPrescriptions, activateSelectedPrescriptions } = usePrescriptionContext()

  const [status, setStatus] = useState(getFilter(PRESCRIPTIONS_FILTER_KEY) || "*")
  const [openRenewAllDialog, setOpenRenewAllDialog] = useState(false)
  const [openRenewSelectedDialog, setOpenRenewSelectedDialog] = useState('')
  const [openPrintSelectedDialog, setOpenPrintSelectedDialog] = useState('')
  const [openPrintDialog, setOpenPrintDialog] = useState(false)
  const [selectedPrescriptionIds, setSelectedPrescriptionIds] = useState<string[]>([])
  const [selectedPrescriptions, setSelectedPrescriptions] = useState<PrescriptionType[]>([])
  const [clearSelectedRows, setClearSelectedRows] = useState(false)
  const [ updatedSelectedList, setUpdatedSelectedList ] = useState<string[] | null>(null)

  const prescriptions = patientPrescriptions.slice()
    .filter((prescription) => status === '*' || prescription.status === status)

  const [totalActiveAndContinuous, setTotalActiveAndContinuous] = useState(0)

  useEffect(() => {
    if (patientPrescriptions) {
      setTotalActiveAndContinuous(patientPrescriptions.filter
        ((prescription) =>
          prescription.status === PrescriptionStatus.ACTIVE &&
          prescription.usage === PrescriptionUsage.CONTINUOUS &&
          prescription.renewedOn === null
        ).length
      )
    }
  }, [patientPrescriptions]);

  useEffect(() => {
    setSelectedPrescriptions(selectedPrescriptionIds.map((id) => prescriptions.find((p) => p.id === id)) as PrescriptionType[])
  }, [selectedPrescriptionIds]);

  useEffect(() => {
    setClearSelectedRows(true)
  }, [status])

  const removeInactivePrescriptions = (prescriptions: PrescriptionType[]): PrescriptionType[] => {
    return prescriptions.filter((prescription) => prescription?.status !== PrescriptionStatus.INACTIVE)
  }

  const removeIncompletePrescriptions = (prescriptions: PrescriptionType[]): PrescriptionType[] => {
    return prescriptions.filter((prescription) => {
      return prescription?.status === PrescriptionStatus.ACTIVE || schema.isValidSync({...prescription, status: PrescriptionStatus.ACTIVE})
    })
  }

  const checkRenewOnSelected = () => {
    if (selectedPrescriptions.length > 0) {
      const nonDraftPrescriptions = selectedPrescriptions.filter((prescription) => prescription?.status !== PrescriptionStatus.DRAFT)
      if (nonDraftPrescriptions.length !== selectedPrescriptions.length && nonDraftPrescriptions.some((p) => p.renewedOn !== null)) {
        setOpenRenewSelectedDialog('One or more draft and renewed prescriptions are selected but won’t be renewed.')
      } else if (nonDraftPrescriptions.length !== selectedPrescriptions.length) {
        setOpenRenewSelectedDialog('One or more draft prescriptions are selected but won’t be renewed.')
      } else if (nonDraftPrescriptions.some((p) => p.renewedOn !== null)) {
        setOpenRenewSelectedDialog('One or more renewed prescriptions are selected but won’t be renewed.')
      } else {
        renewSelectedPrescriptions(selectedPrescriptions, selectPrescriptions)
      }
    } else {
      showWarningAlert('Select one or more prescriptions first.')
    }
  }

  const selectPrescriptions = prescriptions => {
    const renewedPrescriptionIds:string[] = prescriptions.map(p => p.id)
    setUpdatedSelectedList(renewedPrescriptionIds)
  }

  const checkPrintSelected = () => {
    if (selectedPrescriptions.length > 0) {
      processPrescriptionsToBePrinted(selectedPrescriptions)
    } else {
      showWarningAlert('Select one or more prescriptions first.')
    }
  }

  const processPrescriptionsToBePrinted = (prescriptions: PrescriptionType[]) => {
    const nonInactivePrescriptions = removeInactivePrescriptions(prescriptions)
    const completePrescriptions = removeIncompletePrescriptions(nonInactivePrescriptions)
    const inactivePrescriptionsSelected = nonInactivePrescriptions.length !== prescriptions.length
    const incompletePrescriptionsSelected = completePrescriptions.length !== nonInactivePrescriptions.length

    if (inactivePrescriptionsSelected && incompletePrescriptionsSelected) {
      setOpenPrintSelectedDialog('One or more draft prescriptions with missing required fields and inactive prescriptions are selected but won’t be printed.')
    } else if (incompletePrescriptionsSelected) {
      setOpenPrintSelectedDialog('One or more draft prescriptions with missing required fields are selected but won’t be printed.')
    } else if (inactivePrescriptionsSelected) {
      setOpenPrintSelectedDialog('One or more inactive prescriptions are selected but won’t be printed.')
    } else {
      printPrescriptions(completePrescriptions)
    }
  }

  const printTodayPrescriptions = () => {
    const todayPrescriptions = prescriptions.filter((prescription) => moment(prescription.startDate).isSame(new Date(), "day"))
    if (todayPrescriptions.length > 0) {
      setSelectedPrescriptions(todayPrescriptions)
      processPrescriptionsToBePrinted(todayPrescriptions)
    } else {
      showWarningAlert("There are no prescriptions to print.")
    }
  }

  const printPrescriptions = (prescriptions: PrescriptionType[]) => {
    const prescriptionsToPrint = removeIncompletePrescriptions(removeInactivePrescriptions(prescriptions))
    const prescriptionsToActivate = prescriptionsToPrint.filter((prescription) => prescription.status === PrescriptionStatus.DRAFT)
    if (prescriptionsToPrint.length > 0) {
      prescriptionsToActivate.length > 0
        ? activateSelectedPrescriptions(prescriptionsToActivate, () => setOpenPrintDialog(true))
        : setOpenPrintDialog(true)
    } else {
      showWarningAlert("Select at least one draft or active prescription to print.")
    }
  }

  const checkRenewActiveAndContinuous = () => {
    if (totalActiveAndContinuous > 0) {
      setOpenRenewAllDialog(true)
    } else {
      showWarningAlert('There are no active and continuous prescriptions to renew.')
    }
  }

  const onPrescriptionRowClick = () => {
    saveFilter(PRESCRIPTIONS_FILTER_KEY, status)
  }

  return (
    <Section.Column
      header={
        <PatientSectionHeader
          dataTestId={'prescriptions-list'}
          sectionLabel={SectionTitle}
          showSave={false}
          actions={
            <HasPermissionTemplate requiredType={PermissionType.Chart} requiredPermission={Permission.READWRITE}>
              <Box display={'flex'}>
                <ButtonDropdown
                  dataTestId={'prescription-actions-print'}
                  title={'PRINT'}
                  variant={'outlined'}
                  options={[
                    {name: 'SELECTED', onClick: checkPrintSelected},
                    {name: "TODAY’S", onClick: printTodayPrescriptions}
                  ]}
                  sx={{ marginRight:'16px' }}
                />
                <ButtonDropdown
                  dataTestId={'prescription-actions-renew'}
                  title={'RENEW'}
                  variant={'outlined'}
                  options={[
                    {name: 'SELECTED', onClick: checkRenewOnSelected},
                    {name: 'ACTIVE AND CONTINUOUS', onClick: checkRenewActiveAndContinuous}
                  ]}
                  sx={{ marginRight:'16px' }}
                />
                <PermissionButton
                  dataTestId={'prescription-actions-new'}
                  name="newPrescription"
                  type={'button'}
                  onClick={ () => {
                      navigate(buildTenantRoute(`patients/p/${patient_id}/prescriptions/new`, tenant_id))
                    }
                  }
                  requiredType={PermissionType.Chart}
                  requiredPermission={Permission.READWRITE}
                >
                  New
                </PermissionButton>
              </Box>
            </HasPermissionTemplate>
          }
        />
      }
    >
      <Box display={'flex'} justifyContent={'stretch'} alignItems={'center'}>
        <FilterSelect
          dataTestId={'prescription-status-filter'}
          label="Status"
          options={PrescriptionStatusOptions}
          onSelect={(event) => setStatus(event.value)}
          defaultSelectedValue={status}
        />
      </Box>
      <TableList
        dataTestId={'prescriptions-table'}
        scrollKey={PRESCRIPTIONS_SCROLL_KEY}
        showCheckbox={true}
        enableSorting={true}
        defaultSortColumn={-1}
        columns={columns}
        rows={[
          ...prescriptions.map((prescription) => PatientPrescriptionRow(prescription, navigate, onPrescriptionRowClick))
        ]}
        onCheckboxSelectionChanged={setSelectedPrescriptionIds}
        clearSelectedRows={clearSelectedRows}
        setClearSelectedRows={setClearSelectedRows}
        sx={{height: '100%'}}
        emptyListComponent={ { message: "Prescriptions appear here once added." } }
        checkedRows={updatedSelectedList}
      />

      <ConfirmationDialog
        dataTestId={'prescription-renewAll-dialog'}
        open={openRenewAllDialog}
        title={'Renew all active and continuous prescriptions?'}
        primaryAction={() => {
          setOpenRenewAllDialog(false)
          renewAllActiveAndContinuousPrescriptions(totalActiveAndContinuous, selectPrescriptions)
        }}
        primaryLabel={"Renew"}
        onClose={() => {
          setOpenRenewAllDialog(false)
        }}
        message={`${totalActiveAndContinuous} active and continuous prescriptions will be renewed.`}
      />
      <ConfirmationDialog
        dataTestId={'prescription-renewSelected-confirmationDialog'}
        open={!!openRenewSelectedDialog}
        title={'One or more prescriptions won’t be renewed'}
        primaryAction={() => {
          setOpenRenewSelectedDialog('')
          renewSelectedPrescriptions(selectedPrescriptions, selectPrescriptions)
        }}
        onClose={() => {
          setOpenRenewSelectedDialog('')
        }}
        message={openRenewSelectedDialog + " All other selected prescriptions will be renewed."}
      />
      <ConfirmationDialog
        dataTestId={'prescription-printSelected-confirmationDialog'}
        open={!!openPrintSelectedDialog}
        title={"One or more prescriptions won't be printed"}
        primaryAction={() => {
          setOpenPrintSelectedDialog('')
          printPrescriptions(selectedPrescriptions)
        }}
        onClose={() => {
          setOpenPrintSelectedDialog('')
        }}
        message={openPrintSelectedDialog + " All other selected draft and active prescriptions will be printed."}
      />
      <ConfirmationDialog
        dataTestId={'prescription-print-dialog'}
        open={openPrintDialog}
        title={'Prescriptions printed'}
        message={"Prescriptions printed."}
        primaryAction={() => {
          setOpenPrintDialog(false)
        }}
      />
    </Section.Column>
  )
}

