import React, { useCallback, useState } from "react";
import { Draggable } from "@mobiscroll/react";

export const DraggableEvent = ({dragData, dataTestId, children}) => {
  const [draggable, setDraggable] = useState<any>()
  const setDrag = useCallback((event) => setDraggable(event), [])

  return (
    <div ref={setDrag} data-testid={`${dataTestId}-draggable`}>
      {children}
      <Draggable dragData={dragData} element={draggable} />
    </div>
  )
}
