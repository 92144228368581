import React from 'react'
import {
  EntryTitle,
  ResultOptionsWrapper
} from "saga-library/src";
import { Facility } from "../../../types/billing";

interface FacilityResultProps {
  facility: Facility
  searchText: string,
  dataTestId?: string
}

export const FacilityResult = ({
  facility,
  searchText,
  dataTestId
}: FacilityResultProps) => {
  return (
    <ResultOptionsWrapper>
      <EntryTitle
        title={facility.description}
        searchText={searchText}
        dataTestId={`facilityResult-description-${dataTestId}`}
      />
    </ResultOptionsWrapper>
  )
}
