
const DateFormats = [
  'YYYY/MM/DD',
  'MM/DD/YYYY',
  'DD/MM/YYYY',
  'DD/MMM/YYYY',
  'YY/MM/DD',
  'MM/DD/YY',
  'DD/MM/YY',
]

export default DateFormats