import React from "react";
import { LinkedDocumentModal } from "../LinkedDocumentForm/LinkedDocumentModal";
import { DocumentCategory } from "../../../../types/Document";
import { getLabAndInvestigationLabel } from "./LabAndInvestigationsUtil";

const FORM_NAME = "labs_and_investigations_document_modal_form"
export const LabAndInvestigationDocumentModal = ({
  document,
  onModalClose
}) => {
  return (
    <LinkedDocumentModal
      formName={FORM_NAME}
      document={document}
      category={document?.category || DocumentCategory.LAB_RESULT}
      title={getLabAndInvestigationLabel(document)}
      onModalClose={onModalClose}
    />
  )
}