import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ResourceNotFound } from './static/ErrorPages'
import { InvitationValidation } from '../apps/invitations/Invitation'

export const InviteRedemption = () => {
  return (
    <Routes>
      <Route path={'i/:invite_key'} element={<InvitationValidation />} />
      <Route path="*" element={<ResourceNotFound />} />
    </Routes>
  )
}
