import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { AppointmentRoomInput } from "../../../../types/schedule";
import { AppointmentRoomForm } from "./AppointmentRoomForm";
import { DialogV2 } from "saga-library/src";
import { schema } from "../util/appointmentRoomValidation";
import { useMutation } from "@apollo/client";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ADD_NEW_APPOINTMENT_ROOM, LIST_APPOINTMENT_ROOMS } from "../../../../graphql-definitions";
import PermissionButton from "../../../../components/PermissionButton";
import { Permission, PermissionType } from "../../../../types/settings/Permission";

const FORM_NAME = 'new_appointment_room_form'

interface NewAppointmentRoomModalProps {
  open: boolean
  setOpen: (v: boolean) => void
}

const NewAppointmentRoomDefaults = {
  name: '',
  locationId: '',
  practitionerId: '',
  version: "0"
}

export const NewAppointmentRoomModal = ({
  open,
  setOpen
}: NewAppointmentRoomModalProps) => {
  const { showSuccessAlert } = useAlerts()
  const { tenant_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<AppointmentRoomInput>({
    defaultValues: NewAppointmentRoomDefaults,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods


  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      setOpen(false)
    }
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const [addNewAppointmentRoom, { loading, error }] =
  useMutation(ADD_NEW_APPOINTMENT_ROOM, {
    onCompleted: (data) => {
      showSuccessAlert('Room has been added.')
      reset()
      setOpen(false)
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    }
  })

  const onSubmit = handleSubmit(async (data) => {
    await addNewAppointmentRoom({
      variables: {
        tenantId: tenant_id,
        input: data
      },
      update(cache,returnedData) {
        const appointmentRoomId = _get(returnedData, 'data.tenant.schedule.room.create')
        const newAppointmentRoom = {
          id: appointmentRoomId,
          ...data,
        }
        cache.updateQuery({
            query: LIST_APPOINTMENT_ROOMS,
            variables: {tenantId: tenant_id}},
          (listData) => {
            let list = [...listData.tenant.schedule.room.listRooms, newAppointmentRoom]
            return {
              tenant: {
                schedule: {
                  room: {
                    listRooms: list
                  }
                }
              }
            }
          })
      }
    })
  })

  return (
    <DialogV2
      title={"Add a new room"}
      open={open}
      onClose={handleClose}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={'saveAppointmentRoom'}
          onClick={onSubmit}
          requiredType={PermissionType.Schedule}
          requiredPermission={Permission.READWRITE}
          dataTestId={"newAppointmentRoomModal-save-button"}
          loading={isSubmitting}
          type={'submit'}
        >
          Save
        </PermissionButton>
      }
      size={'xs'}
      data-testid={'newAppointmentRoomModal-dialog'}
    >
      <FormProvider {...formMethods}>
        <AppointmentRoomForm  onSubmit={onSubmit}/>
      </FormProvider>
    </DialogV2>
  )
}
