import { BackButton, Typography } from "saga-library/src";
import Box from "@mui/material/Box";
import React from "react";

interface SettingsHeaderProps {
  title: string
  onBack?: () => void
  actions?: React.ReactNode
  children?: React.ReactNode[] | React.ReactNode
  dataTestId?: string
}

const SettingsHeader = ({title, onBack, actions, children, dataTestId}: SettingsHeaderProps) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} sx={{ pb: 1 }}>
      <Box display={'flex'} alignItems={'flex-start'}>
        {onBack && <BackButton dataTestId={`${dataTestId}-settingsHeader-back`} onClick={onBack} sx={{ mt: '-4px' }} />}
        <Typography variant={'h2'} sx={{ pr: 3 }} dataTestId={`${dataTestId}-settingsHeader-title`}>{title}</Typography>
        {children}
      </Box>
      <Box display={'inline-flex'} gap={'4px'}>
        {actions}
      </Box>
    </Box>
  )
}

export default SettingsHeader
