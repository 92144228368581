import React from 'react'
import { TextField } from 'saga-library/src'
import { EncounterNoteFieldProps } from '../../../../../types/patients/EncounterNote'

export const EncounterNoteTextField = ({
  name,
  isSigned,
  disabled,
  dataTestId
}: EncounterNoteFieldProps) => {
  return (
    <TextField
      label={''}
      name={name}
      dataTestId={`${dataTestId}-text-area`}
      variant={'standard'}
      hideHelperText={true}
      multiline
      disabled={disabled}
      InputProps={{
        readOnly: isSigned,
        disableUnderline: true,
        style: { minHeight: '24px' },
        sx: { py: '2px' }
      }}
      sx={{
        mt: 0
      }}
    />
  )
}