import _get from 'lodash/get'
import * as yup from 'yup'
import { rules as patientRules } from '../../../apps/patients/util/validation'
import { validateAlbertaPHN } from "../../../utils/validateIdentifiers";


export const rules = {
  text: { max: 300 },
  calls: { max: 999 },
  encounter: { max: 9 },
  feeModifiers: { max: 3 },
  feeModifierUnits: {
    min: 1,
    max: 99,
  }
}

const maxLengthMessage = (value) => `Max length of ${value}`

const nullIfEmpty = (value) => (!value ? null : value)

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    billingProfile: yup
      .object()
      .required('Required'),
    serviceCode: yup
      .object()
      .required('Required'),
    serviceCodeFeeModifiers: yup
      .mixed()
      .nullable(),
    patientPHN: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    patient: yup
      .object()
      .when("patientPHN", {
        is: (val) => nullIfEmpty(val) == null,
        then: (schema) => schema
          .required('Required'),
        otherwise: (schema) => schema
          .nullable()
      }),
    patientFirstName: yup
      .mixed()
      .when("patientPHN", {
        is: (val) => nullIfEmpty(val) != null,
        then: (schema) => yup.string()
          .max(patientRules.firstName.max, maxLengthMessage(patientRules.firstName.max))
          .required('Required'),
        otherwise: (schema) => schema
          .nullable()
      }),
    patientLastName: yup
      .mixed()
      .when("patientPHN", {
        is: (val) => nullIfEmpty(val) != null,
        then: (schema) => yup.string()
          .max(patientRules.lastName.max, maxLengthMessage(patientRules.lastName.max))
          .required('Required'),
        otherwise: (schema) => schema
          .nullable()
      }),
    text: yup
      .string()
      .max(
        rules.text.max,
        maxLengthMessage(rules.text.max)
      ),
    calls: yup
      .number().typeError("Required")
      .max(rules.calls.max)
      .required(),
    encounter: yup
      .number().typeError("Required")
      .max(rules.encounter.max)
      .required(),
    locationCode: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    originalLocationCode: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    interceptReason: yup
      .string()
      .transform((val) => nullIfEmpty(val))
      .nullable(),
    guardianUli: yup
      .mixed()
      .transform((val) => parseInt(val))
      .test(`validateAlbertaPHN`, 'Invalid Alberta PHN', (value) => {
        let stringValue = value ? value.toString() : ""
        return stringValue.length > 0 ? validateAlbertaPHN(stringValue) : true
      })
      .nullable(),
    serviceDate: yup
      .date()
      .typeError("Invalid date")
      .required(),
    originalEncounterDate: yup
      .date()
      .typeError("Invalid date")
      .nullable(),
    patientDOB: yup
      .date()
      .typeError("Invalid date")
      .nullable(),
    payToUli: yup
      .mixed()
      .transform((val) => parseInt(val))
      .nullable(),
    feeMod1Units: yup
      .number()
      .typeError(`Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .min(rules.feeModifierUnits.min, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .max(rules.feeModifierUnits.max, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .test('validateFeeModifier1Units', 'Required', (feeMod1Units, context) => {
        return context.parent.serviceCode == null ||
          context.parent.serviceCodeFeeModifiers == null ||
          context.parent.serviceCodeFeeModifiers.length <= 0 ||
          !context.parent.serviceCodeFeeModifiers[0].unitDescription ||
          (feeMod1Units != null && !isNaN(feeMod1Units))
      })
      .nullable(),
    feeMod2Units: yup
      .number()
      .typeError(`Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .min(rules.feeModifierUnits.min, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .max(rules.feeModifierUnits.max, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .test('validateFeeModifier2Units', 'Required', (feeMod2Units, context) => {
        return context.parent.serviceCode == null ||
          context.parent.serviceCodeFeeModifiers == null ||
          context.parent.serviceCodeFeeModifiers.length <= 1 ||
          !context.parent.serviceCodeFeeModifiers[1].unitDescription ||
          (feeMod2Units != null && !isNaN(feeMod2Units))
      })
      .nullable(),
    feeMod3Units: yup
      .number()
      .typeError(`Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .min(rules.feeModifierUnits.min, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .max(rules.feeModifierUnits.max, `Must be a number between ${rules.feeModifierUnits.min} and ${rules.feeModifierUnits.max}`)
      .test('validateFeeModifier3Units', 'Required', (feeMod3Units, context) => {
        return context.parent.serviceCode == null ||
          context.parent.serviceCodeFeeModifiers == null ||
          context.parent.serviceCodeFeeModifiers.length <= 2 ||
          !context.parent.serviceCodeFeeModifiers[2].unitDescription ||
          (feeMod3Units != null && !isNaN(feeMod3Units))
      })
      .nullable(),
    customClaimAmount: yup
      .mixed()
      .transform((val) => parseFloat(val))
      .nullable(),
  })
  .required()
