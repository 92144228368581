import React, { useEffect, useMemo } from 'react'
import { DocumentCategory, LinkedDocumentType } from "../../../../types/Document";
import { LinkedDocumentEditor } from "../LinkedDocumentForm/LinkedDocumentEditor";
import { useParams } from "react-router-dom";
import { useReadQuery } from '@apollo/client'
import { useEncounterNoteContext } from '../../providers/EncounterNoteProvider'

const FORM_NAME = "encounter_notes_document_form"

export const EncounterNotesDocumentEditor = () => {
  const { document_id } = useParams()
  const { encounterNoteQueryRef, parseEncounterNoteQueryResults } = useEncounterNoteContext()
  const { data } = useReadQuery(encounterNoteQueryRef!)
  const encounterNotes = useMemo(()=> parseEncounterNoteQueryResults(data), [data, parseEncounterNoteQueryResults])

  const [ document, setDocument] = React.useState<LinkedDocumentType|undefined>(undefined)

  useEffect(() => {
    if(document_id){
      setDocument(encounterNotes.find(encounterNote => encounterNote.isLinkedDocument && encounterNote?.linkedDocument?.id === document_id)?.linkedDocument)
    }
  }, [document_id, encounterNotes]);

  return (
    <LinkedDocumentEditor
      pathName={'encounter-notes'}
      formName={FORM_NAME}
      document={document}
      category={DocumentCategory.CHART_NOTE}
      sectionLabel={document?.description || "Encounter note"}
      dataTestId={"encounterNotesDocumentEditor"}
    />
  )
}