import React, { useEffect } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom"
import { useSagaAuthentication } from "../../providers/Auth"
import { DestinationBeforeRedirect } from "../../types/DestinationBeforeRedirect";
const unauthenticatedPaths = ["/privacy-policy", "/terms-of-service", "/forgot-password", "/invitation//*", "/reset-password"]

function isUnauthenticatedPath(path: string): boolean {
  let firstMatchIndex = unauthenticatedPaths.findIndex((unauthPath) => {
    return matchPath(unauthPath, path) !== null
  })
  return firstMatchIndex >= 0
}

export function PrivateRoutes({ children }) {
  const { isAuthenticated } = useSagaAuthentication()
  const location = useLocation()
  const navigate = useNavigate()
  const destination: DestinationBeforeRedirect = {
    from: location
  }

  useEffect(() => {
    if (!isAuthenticated && !isUnauthenticatedPath(location.pathname)) {
      navigate("/signin", { state: destination })
    }
  }, [])

  return <>{children}</>;
}

export default PrivateRoutes;