import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { ConfirmationDialog, DialogV2, LoadingButton, RemoveButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ConditionAndProcedureForm } from "./components/ConditionAndProcedureForm";
import { cleanConditionData, ConditionAndProcedureType } from "../../../../types/ConditionAndProcedure";
import { schema } from "./components/ConditionAndProcedureFormValidationsSchema";
import { conditionAndProcedureDefaults } from "./components/ConditionAndProcedureFormDefaultValues";
import { useConditionAndProcedureContext } from "../../providers/ConditionAndProcedureProvider";

const FORM_NAME = "edit_condition_and_procedure_form"

const EditConditionAndProcedureDialog = ({ conditionAndProcedure, setConditionAndProcedure }) => {
  const { updateConditionAndProcedure, deleteConditionAndProcedure } = useConditionAndProcedureContext()
  const { enableNavigationPrompt } = usePrompt()

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const formMethods = useForm<ConditionAndProcedureType>({
    defaultValues: conditionAndProcedureDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  useEffect(() => {
    if(conditionAndProcedure){
      reset(conditionAndProcedure)
    }
  }, [conditionAndProcedure]);

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setConditionAndProcedure(null)
      }
    }
  }

  const onSubmit = () => {
    setConditionAndProcedure(null)
  }

  useEffect(() => {
    if (conditionAndProcedure) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [conditionAndProcedure, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSave = () => {
    handleSubmit(async(data) => {
     await updateConditionAndProcedure(
        cleanConditionData(data),
        () => {
          setConditionAndProcedure(null)
          enableNavigationPrompt(false, FORM_NAME)
        }
      )
    })()
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setConditionAndProcedure(null)
    }
  }

  const onDelete = (conditionAndProcedure) => {
    deleteConditionAndProcedure(conditionAndProcedure, ()=>null)
    setConditionAndProcedure(null)
  }

  if(!conditionAndProcedure) return null

  return <DialogV2
    dataTestId={'edit-condition-and-procedure-dialog'}
    size={'sm'}
    title={conditionAndProcedure.description}
    open={!!conditionAndProcedure}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveConditionAndProcedure'}
        form={FORM_NAME}
        dataTestId={"edit-condition-and-procedure-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
    headerActions={[
      <DeleteConditionAndProcedureButton onDelete={()=>onDelete(conditionAndProcedure)}/>
    ]}
  >
    <FormProvider {...formMethods}>
      <ConditionAndProcedureForm
        onSubmit={onSubmit}
        autoFocus={false}
      />
    </FormProvider>
  </DialogV2>
}

const DeleteConditionAndProcedureButton = ({onDelete}) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return <>
    <RemoveButton
      dataTestId={'condition-and-procedure'}
      onClick={() => setOpenDeleteConfirmation(true)}
    />
    <ConfirmationDialog
      dataTestId={'delete-condition-and-procedure-confirmation'}
      open={openDeleteConfirmation}
      title={'Delete condition or procedure?'}
      message={"This action cannot be undone."}
      primaryAction={() => {
        setOpenDeleteConfirmation(false)
        onDelete()
      }}
      primaryLabel={'delete'}
      onClose={() => setOpenDeleteConfirmation(false)}
    />
  </>
}

export default EditConditionAndProcedureDialog