import { Box } from "@mui/material";
import { Checkbox, ColorPicker, TextField, Form, Typography, RadioGroup } from 'saga-library/src'
import React from "react";
import { ColorPickerType } from "saga-library/src/components/ColorPicker/ColorPicker";
import { SettingsFormSection } from "../../components/SettingsFormSection";
import { AppointmentStateCategory, AppointmentStateCategoryMap } from '../../../../types/schedule/AppointmentState'

interface AppointmentStateFormProps {
  isDefault: boolean
  onSubmit: () => void
}

export const AppointmentStateForm = ({
  isDefault=true,
  onSubmit,
}: AppointmentStateFormProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Form onSubmit={onSubmit}>
        {!isDefault &&
          <SettingsFormSection>
            <TextField
              name={'name'}
              label={'Name'}
              sx={{ width: '80%' }}
              dataTestId={'appointmentStateForm-name'}
            />
          </SettingsFormSection>
        }
        <SettingsFormSection label={'Colour'}>
          <ColorPicker name={'color'} type={ColorPickerType.APPOINTMENT_STATE} />
        </SettingsFormSection>
        {!isDefault &&
          <>
            <Typography variant={'h5'} dataTestId={`appointmentStateForm-category-title`}>State category</Typography>
            <Typography variant={'body1'} dataTestId={`appointmentStateForm-category-subtitle`}>Categories group appointment states for filtering purposes.</Typography>
            <RadioGroup
              name={'category'}
              row={true}
              sx={{ justifyContent: "left" }}
              options={[
                { label: AppointmentStateCategoryMap.get(AppointmentStateCategory.PREVISIT)!.label, value: AppointmentStateCategory.PREVISIT },
                { label: AppointmentStateCategoryMap.get(AppointmentStateCategory.INPROGRESS)!.label, value: AppointmentStateCategory.INPROGRESS },
                { label: AppointmentStateCategoryMap.get(AppointmentStateCategory.POSTVISIT)!.label, value: AppointmentStateCategory.POSTVISIT },
                { label: AppointmentStateCategoryMap.get(AppointmentStateCategory.DONE)!.label, value: AppointmentStateCategory.DONE },
              ]}
              dataTestId={`appointmentStateForm-category`}
            />
            <SettingsFormSection label={'Exam rooms'}>
              <Checkbox
                name={'canEnterExamRoom'}
                label={'Appointments in this state can be placed into an exam room'}
                sx={{ml:1}}
                dataTestId={'appointmentStateForm-canEnterExamRoom-checkbox'}
              />
            </SettingsFormSection>
            <SettingsFormSection label={'Online booking'} sx={{ mb: 0 }}>
              <Checkbox
                name={'blocksSchedule'}
                label={'Blocks schedule availability when an appointment is in this state'}
                dataTestId={'appointmentStateForm-blocksSchedule-checkbox'}
                sx={{ml: 1}}
              />
            </SettingsFormSection>
          </>
        }
      </Form>
    </Box>
  )
}
