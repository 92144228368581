import React, { useEffect } from "react";
import { Button, EmptyList, IconButton, Typography, LoadingButton } from "saga-library/src";
import { Box, MenuItem, MenuList, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { ApptSearchForm } from "./ApptSearchForm";
import { AppointmentSlotType } from "../../../../types/schedule/Appointment";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { CalendarAddIcon } from "../../../../components/Icons/CalendarAddIcon";
import moment from "moment-timezone";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_FOR_APPT_SLOTS } from "../../../../graphql-definitions/tenant/SearchQueries";
import { useParams } from "react-router-dom";
import { Dictionary } from "lodash";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider"
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { tenantUserSettings } from "../../../../utils/TenantUserSettings";
import { processApptResults } from './processAppointmentResults'

const SLOT_PAGE_SIZE = 12

type SearchPanelProps = {
  setSearchOpen: (searchOpenState: boolean) => void
}

type ApptResultsProps = {
  apptSlots: Dictionary<AppointmentSlotType[] | null>
  pageInfo: {
    endCursor: string
    hasNextPage: boolean
    startCursor: string
  }
} | null


export const SearchPanel = ({setSearchOpen}: SearchPanelProps) => {
  const { tenant_id } = useParams()
  const { appointmentSlot, selectedScheduleIds } = useScheduleContext()
  const { showErrorAlert } = useAlerts()

  const [apptResults, setApptResults] = React.useState<ApptResultsProps>( null)
  const [currentSearchData, setCurrentSearchData] = React.useState<any>(null)

  const { getUserTenantSettings } = useTenantContext()
  const { TIME_SCALE_SETTING } = tenantUserSettings
  const userTenantSettings = getUserTenantSettings([TIME_SCALE_SETTING])
  const slotLength = userTenantSettings[TIME_SCALE_SETTING.name]

  useEffect(() => {
    if(apptResults && !selectedScheduleIds.includes(currentSearchData.scheduleId)){
     setApptResults(null)
    }
  }, [selectedScheduleIds])

  const [ getApptSlots, {loading: loadingSlots, refetch} ] = useLazyQuery(SEARCH_FOR_APPT_SLOTS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      showErrorAlert("Available appointment slots couldn't be retrieved.")
    }
  })

  const onSubmit = (searchData) => {
    searchData.slotLength = slotLength
    setCurrentSearchData(searchData)

    getApptSlots({
      variables:{
        tenantId: tenant_id,
        first: SLOT_PAGE_SIZE,
        slotCriteria: searchData,
        after: null
      },
      onCompleted: (data) => {
        setApptResults(processApptResults(data, searchData, apptResults))
      },
    })
  }

  const onLoadMore = () => {
    refetch({
      tenantId: tenant_id,
      slotCriteria: currentSearchData,
      first: SLOT_PAGE_SIZE,
      after: apptResults?.pageInfo.endCursor
    }).then((result) => {
      const results = processApptResults(result.data, currentSearchData, apptResults)
      setApptResults({
          apptSlots: {
            ...apptResults?.apptSlots,
            ...results?.apptSlots
          },
          pageInfo: results.pageInfo
      })
    })
  }

  const clearAppointmentSlot = () => {
    appointmentSlot.clearSlot()
  }

  const closeSearchPanel = () => {
    clearAppointmentSlot()
    setSearchOpen(false)
  }

  const clearResults = () => {
    clearAppointmentSlot()
    setApptResults(null)
  }

  return (
    <>
    { apptResults ? <ApptResults apptResults={apptResults} onClose={closeSearchPanel} clearResults={clearResults} onLoadMore={onLoadMore} loading={loadingSlots}/> :
      <ApptSearchForm onSubmit={onSubmit} onClose={closeSearchPanel} searchData={currentSearchData} loading={loadingSlots}/>
    }
    </>
  )
}

const ApptResults = ({apptResults, onClose, clearResults, onLoadMore, loading}) => {
  return (
    <Box display={'flex'} flexDirection={"column"} height={'100%'} overflow={"hidden"}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1} overflow={"hidden"}>
        <Button
          dataTestId={"scheduler-appointmentResults-back"}
          name={'back_to_search_button'}
          variant={'text'}
          sx={{textTransform:'none',p:0, pr:1}}
          startIcon={<ChevronLeft sx={{height:'35px', width:'35px'}} />}
          onClick={clearResults}
        >
          Back to filters
        </Button>
        <IconButton dataTestId={"scheduler-appointmentResults-close"} onClick={onClose} icon={<CloseIcon/>}/>
      </Box>
      <Box sx={{display:"flex", flexDirection:"column", flex:"1 1 100%", pr:1, overflowY:"auto"}}>
        {apptResults && <>
          <ApptSlotList apptResults={apptResults.apptSlots} />
          {apptResults.pageInfo.hasNextPage &&
            <LoadingButton
              dataTestId={"scheduler-appointmentResults-loadMore"}
              loading={loading}
              name={"load_more_appt_slots"}

              variant={'text'}
              sx={{textTransform:'none',p:0, minHeight: '40px'}}
              onClick={onLoadMore}
            >
              Load More
            </LoadingButton>}
        </>}
      </Box>
    </Box>
  )
}

const ApptSlotList = ({apptResults}:{apptResults:Dictionary<AppointmentSlotType[]>}) => {
  const { appointmentSlot } = useScheduleContext()

  const handleClick = (apptSlot) => {
    appointmentSlot.gotoSlot(apptSlot)
  }

  if(!apptResults || Object.keys(apptResults).length === 0){
    return <Box flex={'1 1 100%'} mt={'20%'} px={4}>
      <EmptyList message={`We couldn't find any open time slots`} icon={ManageSearchIcon}/>
      <Typography sx={{textAlign:"center", mt:2}}>Try a different search</Typography>
    </Box>
  }

  return <>
    {Object.keys(apptResults).map((day) => {
      return <React.Fragment key={day}>
        <DateHeader date={day} />
        <MenuList>
          {apptResults[day].map((apptSlot) => {
            return <ApptSlot
              apptResult={apptSlot}
              selected={appointmentSlot.slotInfo?.scheduleId === apptSlot.scheduleId && appointmentSlot.slotInfo?.start === apptSlot.start}
              onClick={handleClick}
              key={`${apptSlot.scheduleId}-${apptSlot.start}`}
            />
          })}
        </MenuList>
      </React.Fragment>
    })}
  </>
}

const DateHeader = ({date}) => {
  return <Box
    sx={ theme => ({
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: 1,
      p: '4px',
      pl: 2
    })}
  >
    <Typography variant={"h5"}>{moment(date).format('MMMM D, YYYY (dddd)')}</Typography>
  </Box>
}

const ApptSlot = ({apptResult, selected, onClick}) => {
  const { tempEventHandler } = useScheduleContext()

  const { setQuickBookEvent } = tempEventHandler
  const startTime = moment(apptResult.start).format("h:mm A")
  const endTime = moment(apptResult.end).format("h:mm A")
  const theme = useTheme()
  const color = selected ? theme.palette.primary.main : undefined

  const onQuickBook = (e) => {
    setQuickBookEvent({ ...apptResult, id: 'temp_slot_id' })
    e.stopPropagation()
  }

  return (
    <MenuItem
      sx={theme => ({
        border:  `1px solid ${selected ? theme.palette.backgrounds.selected :theme.palette.greys.ui }`,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: selected ? theme.palette.backgrounds.selected : undefined,
        mb:1,
        '&:hover': {
          backgroundColor: selected ? theme.palette.backgrounds.selectedHover : theme.palette.backgrounds.hover
        },
      })}
      onClick={()=> {
        onClick(apptResult)
      }}
      data-testid={`scheduler-appointmentSlot-${startTime}-${endTime}`}
    >
      <Typography sx={{flex: "1 1 auto"}} variant={'p4'} color={color}>{`${startTime} - ${endTime}`}</Typography>
      <IconButton dataTestId={`scheduler-appointmentSlot-${startTime}-${endTime}-quickBook`} sx={{p:0}} icon={<CalendarAddIcon color={color}/>} onClick={onQuickBook} />
    </MenuItem>
  )
}




