import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { usePrompt } from "../../../providers/NavigationPrompt";
import { EventForm } from "../components/Event/EventForm";
import { DialogV2, RemoveButton } from "saga-library/src";
import { schema } from "../util/eventValidation";
import { EventInput } from "../../../types/schedule";
import moment from "moment-timezone";
import { TemplateMbscCalendarEvent } from "../../../types/schedule/Template";
import { convertToTimeSpan } from "../util/templateFunctions";
import { diffDates } from "saga-library/src/util";
import { getAppointmentTitle } from '../util/appointmentDialogFunctions'

const DATA_TEST_ID = "editTemplateEvent"
const FORM_NAME = "edit_template_event_form"

interface EditTemplateEventDialogProps {
  open: boolean
  onClose: () => void
  eventData?: any
  onEventDelete: (event: TemplateMbscCalendarEvent) => void
  onEventUpdate: (event: any) => void
  scheduleLength?: string
}

const eventTemplateDefaults = (eventData) => {
  return {
    ...eventData,
    version: eventData?.version,
    start: moment(eventData?.start),
    end: moment(eventData?.end),
    title: eventData?.title,
    allDay: eventData?.allDay,
    localId: eventData?.localId,
  }
}

export const EditTemplateEventDialog = ({
  open,
  onClose,
  eventData,
  onEventDelete,
  onEventUpdate,
  scheduleLength
}: EditTemplateEventDialogProps) => {
  const formMethods = useForm<EventInput>({
    defaultValues: eventTemplateDefaults(eventData),
    schema: schema
  })

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset
  } = formMethods

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const onDelete = () => {
    onEventDelete(eventData)
    onClose()
  }

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    const start = convertToTimeSpan(data.start, 'ddd MMM DD YYYY HH:mm:ss zzZZ')
    const length = diffDates(data.start, moment(data.end), 'minutes')

    let updatedEvent = {
      ...data,
      startTimeSpan: start,
      start: start,
      length: length,
    }

    onEventUpdate(updatedEvent)

    enableNavigationPrompt(false, FORM_NAME)
    reset()
    onClose()
  })

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: eventData?.start,
        end: eventData?.end,
        scheduleLength: scheduleLength
      })}
      primaryAction={onSubmit}
      formName={FORM_NAME}
      submitting={isSubmitting}
      headerActions={[
        <RemoveButton onClick={onDelete} dataTestId={DATA_TEST_ID} />
      ]}
      dataTestId={DATA_TEST_ID}
    >
      <FormProvider {...formMethods}>
        <EventForm
          formName={FORM_NAME}
          onSubmit={onSubmit}
          dataTestId={DATA_TEST_ID}
        />
      </FormProvider>
    </DialogV2>
  )
}