import React from 'react'
import { ClaimInputType } from "../../../types/billing/AbClaim/AbClaim";

export const setAbClaimValues = (data, reset) => {
  let claim : ClaimInputType = {
    ...data,
    patientFirstName: data.patient?.firstName,
    patientLastName: data.patient?.lastName,
    patientPHN: data.patient.primaryIdentifier,
    patientDOB: data.patient?.dob,
    patientGenderId: data.patient?.primaryIdentifier,
    billingProfileId: data.billingProfile?.id,
    facilityId: data.facility?.id,
    functionalCenterId: data.functionalCenter?.id,
    referralPractitionerId: data.referralPractitioner?.id,
    serviceCodeId: data.serviceCode?.id,
    diagnosticCodeIds: data.diagnosticCodes?.filter(dc => !!dc).map(dc => dc.id),
    serviceCodeFeeModifierIds: data.serviceCodeFeeModifiers?.filter(fm => !!fm).map(fm => fm.id),
    patientId: data.patient?.id,
    originalFacilityId: data.originalFacility?.id,
    skillId: data.skill?.id,
    text: data.text ?? "",
  }

  claim.diagnosticCodes = claim.diagnosticCodes?.filter(dc => !!dc)
  claim.serviceCodeFeeModifiers = claim.serviceCodeFeeModifiers?.filter(fm => !!fm)

  reset(claim)

  return claim
}