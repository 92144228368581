import React, { useEffect, useState } from 'react'
import { TextField } from '../TextField'
import {
  useFieldArray, useFormContext
} from "saga-library/src/components/Form";
import Box from '@mui/material/Box'
import { PhoneTypeText } from './PhoneTypeLabel'
import { RemoveButton } from '../RemoveButton/'
import { Select } from '../Select'
import { PhoneField } from '../PhoneField'
import { AddButton } from '../AddButton'
import { PHONE_TYPES, PhoneEnumType, Phones } from './PhoneType'
import { MenuItem } from "@mui/material";

export const PhoneComponentList = ({disabled=false, dataTestId = ''}) => {
  const { control } = useFormContext<Phones>();
  const { fields, append, remove } = useFieldArray<Phones>({
    name: 'phones',
    control,
  })
  const [primaryIndex, setPrimaryIndex] = useState<number>(0)

  useEffect(() => {
    const newPrimaryIndex = fields.findIndex((phone) => !!phone.isPrimary)
    if (newPrimaryIndex !== -1) {
      setPrimaryIndex(newPrimaryIndex)
    } else {
      setPrimaryIndex(0)
    }
  }, [fields])

  const addContactRow = () => {
    append({
      type: PhoneEnumType.NEW,
      number: '',
      extension: '',
      isPrimary: fields.length === 0,
      notes: '',
    })
  }

  const removeContactRow = (index) => {
    remove(index)
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Box key={item.id}>
            <PhoneComponent
              key={item.id}
              dataTestId={dataTestId}
              name={`phones.${index}`}
              index={index}
              isPrimary={index === primaryIndex}
              setPrimary={(index) => setPrimaryIndex(index)}
              removeContactRow={removeContactRow}
              type={item.type}
              disabled={disabled}
            />
            <hr/>
          </Box>
        )
      })}
      <AddButton dataTestId={`${dataTestId}-phonecomponent`} label={'Add phone number'} onClick={addContactRow} disabled={disabled}/>
    </>
  )
}

export interface PhoneComponentProps {
  dataTestId?: string
  name: string
  type: string
  index: number
  removeContactRow: (number) => void
  isPrimary: boolean
  setPrimary?: (number) => void
  notesMaxLength?: number
  disabled?: boolean
}

export const PhoneComponent = ({
  dataTestId,
  name,
  type,
  index,
  removeContactRow,
  isPrimary,
  setPrimary = () => {},
  notesMaxLength = 48,
  disabled = false
}: PhoneComponentProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <PhoneTypeText
          dataTestId={`${dataTestId}-phonecomponent-${index}-typetext`}
          type={type}
          index={index}
          isPrimary={isPrimary}
          setPrimary={(index) => setPrimary(index)}
          name={name}
        />
        <RemoveButton
          dataTestId={`${dataTestId}-phonecomponent-${index}`}
          onClick={() => removeContactRow(index)}
          disabled={disabled}
        />
      </Box>
      <Box sx={{flexGrow: 1, flexShrink: 0, minWidth: '25%'}}>
        <Select
          dataTestId={`${dataTestId}-phonecomponent-${index}-type-select`}
          name={`${name}.type`}
          label={'Type'}
          disabled={disabled}
          sx={{width: '100%'}}
        >
          <MenuItem data-testid={`${dataTestId}-phonecomponent-${index}-type-option-${PhoneEnumType.HOME}`} value={PhoneEnumType.HOME}>{PHONE_TYPES[PhoneEnumType.HOME]}</MenuItem>
          <MenuItem data-testid={`${dataTestId}-phonecomponent-${index}-type-option-${PhoneEnumType.MOBILE}`} value={PhoneEnumType.MOBILE}>{PHONE_TYPES[PhoneEnumType.MOBILE]}</MenuItem>
          <MenuItem data-testid={`${dataTestId}-phonecomponent-${index}-type-option-${PhoneEnumType.BUSINESS}`} value={PhoneEnumType.BUSINESS}>{PHONE_TYPES[PhoneEnumType.BUSINESS]}</MenuItem>
          <MenuItem data-testid={`${dataTestId}-phonecomponent-${index}-type-option-${PhoneEnumType.OTHER}`} value={PhoneEnumType.OTHER}>{PHONE_TYPES[PhoneEnumType.OTHER]}</MenuItem>
        </Select>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <PhoneField
          dataTestId={`${dataTestId}-phonecomponent-${index}-phonefield`}
          sx={{ flexGrow: 2, flexShrink: 1 }}
          label={'Phone number'}
          name={`${name}.number`}
          disabled={disabled}
        />
        <TextField
          dataTestId={`${dataTestId}-phonecomponent-${index}-extension`}
          sx={{ width: '40%', ml: 1, flexGrow: 1, flexShrink: 1 }}
          name={`${name}.extension`}
          label={'Extension'}
          disabled={disabled}
        />
      </Box>
      <TextField
        dataTestId={`${dataTestId}-phonecomponent-${index}-notes`}
        name={`${name}.notes`}
        label={'Phone number notes'}
        inputProps={{ maxLength: notesMaxLength }}
        disabled={disabled}
      />
    </Box>
  )
}

export default PhoneComponent
