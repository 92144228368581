import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { PermissionForm } from '../../../../components/PermissionForm'
import { PermissionType } from '../../../../types/settings/Permission'
import { FormRow } from '../../../../components/FormRow'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import { TemplateSelect } from '../../../../components/TemplateSelect'
import { ConfirmationDialog, TextField } from 'saga-library/src'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { ReferralPractitionerChipSelect } from '../../../../components/ReferralPractitionerChipSelect'
import { useFormContext, useWatch } from 'react-hook-form'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useTenantContext } from '../../../../providers/TenantContextProvider'
import { LetterType } from '../../../../types/patients'
import { LetterTemplateType } from '../../../../types/patients/Letters'
import { letterDefaults } from './LetterFormDefaultValues'
import TabFocusInterceptor from 'saga-library/src/hooks/tabFocusInterceptor'
import moment from 'moment-timezone'
import {
  appointmentButtons,
  locationButtons,
  patientButtons,
  patientHealthDataButtons, PRACTITIONER_TITLE_FULL_NAME, PRACTITIONER_TITLE_LAST_NAME,
  practitionerButtons, SIGNATURE_STRING
} from 'saga-library/src/components/RichTextEditor/LetterTemplateChipConstants'
import { FormattedAgeString } from '../../../../components/FormattedAge'
import { phoneNumberMask } from 'saga-library/src/components/PhoneField/PhoneField'
import { postalCodeMask } from 'saga-library/src/components/PostalCodeField/PostalCodeField'
import { usePatientProfileContext } from '../../providers/PatientProfileProvider'
import { useLazyQuery, useReadQuery } from '@apollo/client'
import { omit } from 'lodash'
import _get from 'lodash/get'
import { LengthHeightUnits, PatientVitalDetailsType, Vital, WeightUnits } from '../vitals/VitalTypes'
import { calculateBMI, convertMeasurementUnits, formatDecimal } from '../vitals/unitConversionUtil'
import { useAllergyContext } from '../../providers/AllergyProvider'
import { renderToStaticMarkup } from 'react-dom/server'
import { AllergySeverity } from '../../../../types/Allergy'
import { useConditionAndProcedureContext } from '../../providers/ConditionAndProcedureProvider'
import { ConditionStatus } from '../../../../types/ConditionAndProcedure'
import { usePrescriptionContext } from '../../providers/PrescriptionProvider'
import { PrescriptionStatus } from '../../../../types/Prescription'
import { usePrescriptionUtils } from '../prescriptions/components/PrescriptionUtil'
import {
  LetterFormChipSelectionPanel,
  SELECTION_PANEL_OPTION,
  SelectionOptionType,
  TemplateChipOption
} from './components/LetterFormChipSelectionPanel'
import {
  GET_FILE_DETAILS,
  GET_PRACTITIONER_PROFILE,
  GET_REFERRAL_PRACTITIONER_PROFILE
} from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { PatientProfileType } from '../../PatientTypes'
import { capitalizedWord, lowerCaseWord } from '../../../../utils/StringHelpers'
import { Practitioner, ReferralPractitioner } from '../../../../types/settings'
import { PatientRelationshipsType } from '../profile/PatientRelationships'
import { Location } from '../../../../types'
import { PatientAppointmentWithPractitionerDetailsAndClinicLocation } from '../../../../types/patients/Patient'
import { useDocumentContext } from '../../../../providers/DocumentProvider'
import { RichTextEditorClient } from '../../../../components/RichTextEditorClient'

const letterPractitionerSignatureRegex = `<span class="fr-chip-label">Letter practitioner - Signature</span>`
const PRACTITIONER_SIGNATURE_HEIGHT = 40

interface LetterFormProps {
  onSubmit: (event: React.BaseSyntheticEvent) => void
  formName: string
  formDisabled?: boolean
  letter?: LetterType | null
  editorRef?: React.RefObject<any>,
  shouldCallOnChange?: boolean
  dataTestId?: string
}

export const LetterForm = ({
  onSubmit,
  formName,
  formDisabled = false,
  letter,
  editorRef,
  shouldCallOnChange = false,
  dataTestId
}:LetterFormProps) => {
  const { patientPrimaryIdentifier, practitioners } = useTenantContext()
  const { userId } = useAccountContext()
  const { tenant_id} = useParams()
  const { showErrorAlert } = useAlerts()
  const { getFileTypeUriWithToken } = useDocumentContext();

  const { profileData, patientRelationshipRef, patientCurrentVitalsRef  } = usePatientProfileContext()
  const { data: relationshipData } = useReadQuery(patientRelationshipRef)
  const patientRelationships: PatientRelationshipsType = omit(_get(relationshipData, 'tenant.patient.relationships', null), 'id')
  const { data: currentVitalsData } = useReadQuery(patientCurrentVitalsRef)
  const patientCurrentVitals: PatientVitalDetailsType = omit(_get(currentVitalsData, 'tenant.patient.currentVitals', null), 'id')

  const { allergyQueryRef, parseAllergyQueryResults } = useAllergyContext()
  const { data: allergyData } = useReadQuery(allergyQueryRef!)
  const currentAllergies = useMemo(()=> parseAllergyQueryResults(allergyData), [allergyData, parseAllergyQueryResults])

  const { conditionAndProcedureQueryRef, parseConditionAndProcedureQueryResults } = useConditionAndProcedureContext()
  const { data: conditionData } = useReadQuery(conditionAndProcedureQueryRef!)
  const currentConditionsAndProcedures = useMemo(()=> parseConditionAndProcedureQueryResults(conditionData), [conditionData, parseConditionAndProcedureQueryResults])

  const { buildSummary } = usePrescriptionUtils()
  const { prescriptionsQueryRef, parsePrescriptionQueryResults } = usePrescriptionContext()
  const { data: prescriptionData } = useReadQuery(prescriptionsQueryRef!)
  const currentPrescriptions = useMemo(()=> parsePrescriptionQueryResults(prescriptionData), [prescriptionData, parsePrescriptionQueryResults])

  const [ letterTemplateChanged, setLetterTemplateChanged ] = useState<{
    message: string | null,
    selectedLetterTemplate?: LetterTemplateType | null,
  }>({ message: null, selectedLetterTemplate: null })
  const [ previousTemplate, setPreviousTemplate] = useState<LetterTemplateType | null>(null)
  const [ templateChipOptions, setTemplateChipOptions ] = useState<TemplateChipOption[]>([])
  const [ templateChipOption, setTemplateChipOption ] = useState<TemplateChipOption | null>()

  const [ familyPractitioner, setFamilyPractitioner ] = useState<ReferralPractitioner | null>(null)
  const [ letterPractitioner, setLetterPractitioner] = useState<Practitioner | null>(null)
  const [ letterPractitionerSignature, setLetterPractitionerSignature ] = useState<string|ArrayBuffer|null>(null)
  const [ keepSignatureChip, setKeepSignatureChip ] = useState<boolean>(false)

  const {
    control,
    getValues,
    setValue,
    reset,
    resetField,
    formState: { defaultValues }
  } = useFormContext()

  const letterTemplateId = useWatch({ control, name: 'letterTemplateId' })
  const practitionerId = useWatch({ control, name: 'practitionerId' })

  const [ getPractitionerDetails ] = useLazyQuery(GET_PRACTITIONER_PROFILE, {
    onCompleted: async data => {
      setLetterPractitioner(_get(data, 'tenant.practitioner.get', null));
      if (!_get(data, 'tenant.practitioner.get', null).signatureFileId) {
        setKeepSignatureChip(false)
        return
      }

      setKeepSignatureChip(true)
      await getFileDetails({
        variables: {
          fileId: _get(data, 'tenant.practitioner.get', null).signatureFileId,
          tenantId: tenant_id,
        },
      })
    },
    onError: async error => {
      showErrorAlert('Practitioner couldn\'t be retrieved.')
    }
  })

  const [ getReferralPractitionerDetails ] = useLazyQuery(GET_REFERRAL_PRACTITIONER_PROFILE, {
    onCompleted: async data => {
      setFamilyPractitioner(_get(data, 'tenant.referralPractitioner.get', null))
    },
    onError: async error => {
      showErrorAlert('Practitioner couldn\'t be retrieved.')
    }
  })

  const resizeImage = async (blob, maxHeight) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const newWidth = maxHeight * aspectRatio;

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = maxHeight;
          const ctx = canvas.getContext('2d');
          if (!ctx) return ''
          ctx.drawImage(img, 0, 0, newWidth, maxHeight);

          const resizedDataUrl = canvas.toDataURL(blob.type === 'image/png'
            ? 'image/png'
            : 'image/jpeg');
          resolve(resizedDataUrl);
        };
        if (!event.target) return ''
        img.src = event.target.result as string;
      };
      reader.readAsDataURL(blob);
    });
  };

  const [getFileDetails] = useLazyQuery(GET_FILE_DETAILS, {
    onCompleted: async (data) => {
      const signature = _get(data, 'tenant.file.get', null);
      const fullURI = await getFileTypeUriWithToken(signature);
      if (!fullURI) return;

      const response = await fetch(fullURI);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const imageBlob = await response.blob();
      const resizedImageUrl = await resizeImage(imageBlob, PRACTITIONER_SIGNATURE_HEIGHT);
      if (!resizedImageUrl) return
      setLetterPractitionerSignature(resizedImageUrl as string)
    },
    onError: (error) => {
      console.error("Error occurred retrieving signature file: " + error);
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!relationshipData || !patientRelationships || !patientRelationships.familyPractitioners || patientRelationships.familyPractitioners.length === 0) {
      return
    }

    const loadPractitionerDetails = async () => {
      await getReferralPractitionerDetails({
        variables: {
          referralPracId: patientRelationships.familyPractitioners![0].id,
          tenantId: tenant_id
        }
      })
    }
    loadPractitionerDetails()
  }, [relationshipData])

  useEffect(() => {
    if(!letterPractitioner) return
    resetField('content', {defaultValue: setPractitionerAutofill()})
  }, [letterPractitioner])

  useEffect(() => {
    if(!letterPractitionerSignature) return
    resetField('content', {defaultValue: setPractitionerAutofillSignature()})
  }, [letterPractitionerSignature])

  useEffect(() => {
    if(!practitionerId) {
      setLetterPractitioner(null)
      setLetterPractitionerSignature(null)
      return
    }

    const loadPractitionerDetails = async () => {
      await getPractitionerDetails({
        variables: {
          practitionerId: practitionerId,
          tenantId: tenant_id
        }
      });
    }

    loadPractitionerDetails()
  }, [practitionerId])

  const firstFieldRef = useRef<any>(null);
  const [hasFocus, setHasFocus] = useState(true);
  const setFocus = (focus) => {
    setHasFocus(focus)
  }
  TabFocusInterceptor(firstFieldRef, hasFocus, setFocus)

  useEffect(() => {
    if (firstFieldRef && !hasFocus) {
      setHasFocus(false)
    }
  }, [firstFieldRef]);

  useEffect(() => {
    reset(formatLetter())
  }, [letter])

  useEffect(() => {
    resetField('content', getValues('content'))
    if (!!letterPractitioner){
      setValue('content', setPractitionerAutofill())
    }

    if (!!letterPractitionerSignature){
      setValue('content', setPractitionerAutofillSignature())
    }
  }, [letterTemplateId])

  const formatLetter = () => {
    if (letter) {
      const letterClone = { ...letter }
      letterClone.recipients = letterClone.recipients?.map(r => ({
        ...r,
        value: r.id,
        label: `${r.lastName}, ${r.firstName}`
      }))
      letterClone.recipientIds = letterClone.recipients?.map(r => r.id)
      letterClone.content = fillTemplateChips(letterClone.content)
      return letterClone
    }
    return letterDefaults
  }

  const resetTemplateChipOptions = () => {
    setTemplateChipOptions([])
    setTemplateChipOption(null)
  }

  const clearLetterTemplateChanged = () => {
    setLetterTemplateChanged({ message: null, selectedLetterTemplate: null })
  }

  const getHtmlFromString = (htmlString: string) => {
    return new DOMParser().parseFromString(htmlString, 'text/html').documentElement.innerHTML
      .replace('<head></head><body>', '')
      .replace('</body>', '')
  }

  const checkPreviousTemplateHasChanges = () => {
    const contentFromForm = editorRef?.current?.editor?.el?.innerHTML
      .replaceAll(' class="fr-draggable">', '>')
    if(getHtmlFromString(defaultValues?.content) === contentFromForm) {
      return false
    }
    if (!!previousTemplate) {
      if (previousTemplate.title && getValues("title") !== previousTemplate.title) {
        return true
      }
      if (previousTemplate.content && getValues("content") !== previousTemplate.content) {
        return true
      }
    }
    return false
  }

  const letterTemplateOnSelect = (letterTemplate) => {
    const clearingTemplate = !letterTemplate
    const practitionerChanging = !!getValues("practitionerId") && !!letterTemplate?.practitionerId
      && getValues("practitionerId") !== letterTemplate?.practitionerId
    const previousTemplateChanged = checkPreviousTemplateHasChanges()
    let message = "This letter has been modified."
    if (previousTemplateChanged) {
      if(clearingTemplate) {
        message = message.concat(" Template applied fields will be cleared.")
      }
      else if (practitionerChanging) {
        message = message.concat(" The practitioner will be changed.")
      }
      else {
        message = message.concat(" The letter template will be changed.")
      }
      setLetterTemplateChanged({message: message, selectedLetterTemplate: letterTemplate})
    }
    else {
      letterTemplateOnChange(letterTemplate)
    }
  }

  const letterTemplateOnChange = (letterTemplate) => {
    resetTemplateChipOptions()

    if (!!letterTemplate) {
      if (!!letterTemplate.title) {
        setValue('title', letterTemplate.title, { shouldDirty: false })
      }
      if (!!letterTemplate.practitionerId) {
        setValue('practitionerId', letterTemplate.practitionerId, { shouldDirty: false })
      }
      if (!!letterTemplate.content) {
        let newContent = autofillTemplateChips(letterTemplate.content)
        newContent = fillTemplateChips(newContent)
        resetField('content', { defaultValue: newContent, keepDirty: false, keepTouched: false })
      }
      setPreviousTemplate(letterTemplate)
    } else {
      if (previousTemplate?.title) {
        setValue("title", "", { shouldDirty: true })
      }
      if (previousTemplate?.practitionerId) {
        setValue("practitionerId", "", { shouldDirty: true })
      }
      if (previousTemplate?.content) {
        resetField("content", {defaultValue:"", keepDirty: false, keepTouched: false})
      }
      setPreviousTemplate(null)
    }

    const letterTemplateId = !!letterTemplate ? letterTemplate.id : ""
    setValue('letterTemplateId', letterTemplateId)

    clearLetterTemplateChanged()
  }

  const getFormattedAddress = (inputData: PatientProfileType | Practitioner | ReferralPractitioner | Location) => {
    let replacementStringArray: string[] = []
    let data
    if('street1' in inputData) {
      data = inputData as Practitioner
      replacementStringArray.push(data.street1 || "")
    }
    else {
      data = inputData as PatientProfileType
      replacementStringArray.push(data.street || "")
    }
    replacementStringArray.push(data.street2 || "")
    replacementStringArray.push(data.street3 || "")
    replacementStringArray.push(data.city && `${data.city}${data.province ? ", " : ""}${data.province || ""}`)
    replacementStringArray.push(data.postalCode ? postalCodeMask(data.postalCode) : "")

    return replacementStringArray.filter(s => !!s).join("</span><br><span>")
  }

  const replaceRegexMatch = (match: string, replacementStringMethod: ((arg0: string) => string | null | undefined), newContent: string) => {
    const fullMatch = match[0];
    const replaceMatch = match[1];
    let replacementString = replacementStringMethod?.(replaceMatch);

    if(!replacementStringMethod || !replacementString) {
      newContent = newContent.replace(fullMatch, '');
    }

    if(!!replacementString) {
      newContent = newContent.replace(fullMatch, replacementString);
    }

    return newContent;
  }

  const getReplacementProfileString = (replaceMatch: string, format: string): string => {
    switch (replaceMatch) {
      case patientButtons[0]:
        return profileData.firstName || ""
      case patientButtons[1]:
        return profileData.lastName || ""
      case patientButtons[2]:
        return profileData.dob ? moment(profileData.dob).format(format) : ""
      case patientButtons[3]:
        return getFormattedAddress(profileData)
      case patientButtons[4]:
        return profileData.dob ? FormattedAgeString(moment(profileData.dob).format()) : ""
      case patientButtons[5]:
        return profileData.gender?.name || ""
      case patientButtons[6]:
        const pronounFormatArray = format.split("/")
        if (!profileData.gender?.name) {
          return ""
        } else if (profileData.gender?.name === "Male") {
          return pronounFormatArray[0]
        } else if (profileData.gender?.name === "Female") {
          return  pronounFormatArray[1]
        } else {
          return pronounFormatArray[2]
        }
      case patientButtons[7]:
        return patientPrimaryIdentifier(profileData)?.formatted || ""
      case patientButtons[8]:
        const primaryPhone = profileData.phones.find(p => p.isPrimary)
        if (!primaryPhone) {
          return ""
        } else {
          return primaryPhone.number ? phoneNumberMask(primaryPhone.number, primaryPhone.extension) : ""
        }
      case patientButtons[9]:
        return profileData.email || ""
      default:
        return ""
    }
  }

  const getReplacementVitalString = (replaceMatch: string, currentVitals: Vital|undefined) => {
    switch(replaceMatch) {
      case patientHealthDataButtons[0]:
        if(!currentAllergies || currentAllergies.length === 0) return null;
        return (
          renderToStaticMarkup(
            <ul>
              {currentAllergies.map((allergy, index) => (
                <li key={index}>
                  {allergy.allergen} {!!allergy?.severity && allergy.severity !== AllergySeverity.UNABLE_TO_ASSESS && `– ${capitalizedWord(allergy.severity)} severity `}
                  {lowerCaseWord(allergy.reactionType)}
                  {!!allergy.reactionType && !!allergy.onsetDate && ' '}
                  {!!allergy.onsetDate && `since ${allergy.onsetDate}`}
                  {!!allergy.reactionDescription && ` – Reaction: ${allergy.reactionDescription}`}
                </li>
              ))}
            </ul>
          )
        );
      case patientHealthDataButtons[1]:
        if(!currentConditionsAndProcedures || currentConditionsAndProcedures.length === 0) return null;
        const conditionStatusesToShow = [ConditionStatus.ACTIVE, ConditionStatus.RECURRENCE, ConditionStatus.RELAPSE, ConditionStatus.UNKNOWN]
        return (
          renderToStaticMarkup(
            <ul>
              {currentConditionsAndProcedures.filter(condition =>  conditionStatusesToShow.includes(condition.status)).map((condition, index) => (
                <li key={index}>
                  {condition.description} {!!condition.severity && ` – ${capitalizedWord(condition.severity)} severity `}
                </li>
              ))}
            </ul>
          )
        );
      case patientHealthDataButtons[2]:
        if(!currentPrescriptions || currentPrescriptions.length === 0) return null;
        return (
          renderToStaticMarkup(
            <ul>
              {currentPrescriptions.filter(prescription => prescription.status === PrescriptionStatus.ACTIVE).map((prescription, index) => (
                <li key={index}>
                  {prescription.drug.name} {(prescription.dosages.filter(dosage => !!dosage.dosageRange).length > 0 ? `– ${buildSummary(prescription)}` : '')}
                </li>
              ))}
            </ul>
          )
        );
      case patientHealthDataButtons[3]:
        if (!currentVitals?.lengthHeight || !currentVitals.lengthHeightUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.lengthHeight, currentVitals.lengthHeightUnit, LengthHeightUnits.CM))} cm`
      case patientHealthDataButtons[4]:
        if(!currentVitals?.lengthHeight || !currentVitals.lengthHeightUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.lengthHeight, currentVitals.lengthHeightUnit, LengthHeightUnits.INCH))} inch`
      case patientHealthDataButtons[5]:
        if(!currentVitals?.weight || !currentVitals.weightUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.weight, currentVitals.weightUnit, WeightUnits.KG))} kg`
      case patientHealthDataButtons[6]:
        if(!currentVitals?.weight || !currentVitals.weightUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.weight, currentVitals.weightUnit, WeightUnits.LBS))} lb`
      case patientHealthDataButtons[7]:
        if(!currentVitals?.lengthHeight || !currentVitals.lengthHeightUnit || !currentVitals.weight || !currentVitals.weightUnit) return null;
        return `${calculateBMI(currentVitals.weight, currentVitals.weightUnit, currentVitals.lengthHeight, currentVitals.lengthHeightUnit)}`
      case patientHealthDataButtons[8]:
        if(!currentVitals?.bloodPressureSystolic || !currentVitals.bloodPressureDiastolic) return null;
        return `${currentVitals.bloodPressureSystolic}/${currentVitals.bloodPressureDiastolic} mm Hg`
      case patientHealthDataButtons[9]:
        if(!currentVitals?.heartRate) return null;
        return `${currentVitals.heartRate} bpm`
      case patientHealthDataButtons[10]:
        if(!currentVitals?.headCircumference || !currentVitals.headCircumferenceUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.headCircumference, currentVitals.headCircumferenceUnit, LengthHeightUnits.CM))} cm`
      case patientHealthDataButtons[11]:
        if(!currentVitals?.headCircumference || !currentVitals.headCircumferenceUnit) return null;
        return `${formatDecimal(convertMeasurementUnits(currentVitals.headCircumference, currentVitals.headCircumferenceUnit, LengthHeightUnits.INCH))} inch`
      default:
        break;
    }
  }

  const getReplacementLetterPractitionerString = (replaceMatch: string) => {
    if (letterPractitioner) {
      switch(replaceMatch) {
        case practitionerButtons[0]:
          if(!letterPractitioner.lastName) return null;
          return `${letterPractitioner.title ? `${capitalizedWord(letterPractitioner.title)}.` : ""}${letterPractitioner.lastName}`
        case practitionerButtons[1]:
          if(!letterPractitioner.firstName || !letterPractitioner.lastName) return null;
          return `${letterPractitioner.title ? `${capitalizedWord(letterPractitioner.title)}.` : ""}${letterPractitioner.firstName} ${letterPractitioner.lastName}`
        case practitionerButtons[2]:
          return getFormattedAddress(letterPractitioner)
        case practitionerButtons[3]:
          if(!letterPractitioner?.phone?.number) return null;
          return phoneNumberMask(letterPractitioner?.phone?.number, letterPractitioner?.phone?.extension)
        case practitionerButtons[4]:
          if(!letterPractitioner?.practitionerId) return null;
          return letterPractitioner.practitionerId
        case SIGNATURE_STRING:
          if(keepSignatureChip){
            // Put the entire chip's string back into the letter so it can be replaced after the image is loaded
            return letterPractitionerSignatureRegex
          }
          return null
        default:
          break;
      }
    }
  }

  const getReplacementReferralPractitionerString = (replaceMatch: string, referralPractitioner: ReferralPractitioner | null = familyPractitioner) => {
    if (referralPractitioner) {
      switch(replaceMatch) {
        case practitionerButtons[0]:
          return `Dr. ${referralPractitioner.lastName}`
        case practitionerButtons[1]:
          return `Dr. ${referralPractitioner.firstName} ${referralPractitioner.lastName}`
        case practitionerButtons[2]:
          return getFormattedAddress(referralPractitioner)
        case practitionerButtons[3]:
          if(!referralPractitioner.phones || referralPractitioner.phones.length === 0) {
            return ""
          }
          const phoneNumber = referralPractitioner.phones.find(p => p.isPrimary) || referralPractitioner.phones[0]
          return phoneNumberMask(phoneNumber.number, phoneNumber.extension)
        case practitionerButtons[4]:
          return referralPractitioner.practitionerId || ""
        default:
          return ""
      }
    }
  }

  const getReplacementAppointmentString = (replaceMatch: string, appointment: PatientAppointmentWithPractitionerDetailsAndClinicLocation) => {
    const dateRegex = new RegExp(`Date (.*)`, 'g');
    let dateMatch = dateRegex.exec(replaceMatch)
    if(!!dateMatch && !!dateMatch[1]){
      return moment(appointment.start).format(dateMatch[1])
    }

    // This is the practitioner for the appointment
    const practitionerRegex = new RegExp(`Practitioner (.*)`, 'g');
    let practitionerMatch = practitionerRegex.exec(replaceMatch)
    if(!!practitionerMatch && !!practitionerMatch[1]){
      if(practitionerMatch[1].includes(PRACTITIONER_TITLE_FULL_NAME)){
        return `${appointment?.practitioner?.title ? `${capitalizedWord(appointment?.practitioner?.title)}. ` : ""}${appointment?.practitioner?.firstName} ${appointment?.practitioner?.lastName}` || ""
      }
      else if(practitionerMatch[1].includes(PRACTITIONER_TITLE_LAST_NAME)){
        return `${appointment?.practitioner?.title ? `${capitalizedWord(appointment?.practitioner?.title)}. ` : ""}${appointment?.practitioner?.lastName}` || ""
      }
      else if(practitionerMatch[1].includes('Full address')){
        return getFormattedAddress(appointment?.practitioner)
      }
      else if(practitionerMatch[1].includes('Phone Number')){
        return phoneNumberMask(appointment?.practitioner?.phone?.number, appointment?.practitioner?.phone?.extension)
      }
      else if(practitionerMatch[1].includes('Practitioner ID')){
        return appointment?.practitioner?.practitionerId || ""
      }
    }

    // This is the location of the clinic for the appointment
    const locationRegex = new RegExp(`Location (.*)`, 'g');
    let locationMatch = locationRegex.exec(replaceMatch)
    if(!!locationMatch && !!locationMatch[1]){
      if(locationMatch[1].includes('Name')){
        return appointment?.location?.name || ""
      }
      else if(locationMatch[1].includes('Full address')){
        return getFormattedAddress(appointment?.location)
      }
      else if(locationMatch[1].includes('Phone number')){
        return phoneNumberMask(appointment?.location?.phoneNumber)
      }
      else if(locationMatch[1].includes('Fax number')){
        return phoneNumberMask(appointment?.location?.faxNumber)
      }
    }

    switch(replaceMatch) {
      case appointmentButtons[1]:
        return moment(appointment.start).format('h:mm A')
      case appointmentButtons[2]:
        return appointment?.type?.name || ""
      case appointmentButtons[3]:
        return appointment?.reasonForVisit || ""
      default:
        return ""
    }
  }

  const getReplacementLocationString = (replaceMatch: string, location: Location) => {
    switch(replaceMatch) {
      case locationButtons[0]:
        return location.name
      case locationButtons[1]:
        return getFormattedAddress(location)
      case locationButtons[2]:
        return phoneNumberMask(location.phoneNumber)
      case locationButtons[3]:
        return phoneNumberMask(location.faxNumber)
      default:
        return ""
    }
  }

  const setPractitionerAutofill = () => {
    let match
    let currentContent = getValues('content')
    let newContent = currentContent
    if(!currentContent) return
    const letterPractitionerRegex = new RegExp(`<span class="fr-chip-label">Letter practitioner - \\(?(.*?)\\)?</span>`, 'g');
    while ((match = letterPractitionerRegex.exec(currentContent)) !== null) {
      newContent = replaceRegexMatch(match, getReplacementLetterPractitionerString, newContent);
    }

    return newContent
  }

  const setPractitionerAutofillSignature = () => {
    let match
    let currentContent = getValues('content')
    let newContent = currentContent
    if(!currentContent) return
    const signatureRegex = new RegExp(letterPractitionerSignatureRegex, 'g');
    while ((match = signatureRegex.exec(currentContent)) !== null) {
      const imgTag = `<img src="${letterPractitionerSignature}" alt="practitioner-signature">`;
      newContent = replaceRegexMatch(match, () => imgTag, newContent);
    }

    return newContent
  }

  const autofillTemplateChips = (letterTemplateContent: string) => {
    const dateRegex = new RegExp(`<span class="fr-chip-label">Today&#39;s date \\((.*?)\\)</span>`, 'g');
    let newContent = letterTemplateContent;
    let match;

    while ((match = dateRegex.exec(letterTemplateContent)) !== null) {
      const fullMatch = match[0];
      const dateFormat = match[1];
      const replacementString = moment().format(dateFormat);
      newContent = newContent.replace(fullMatch, replacementString);
    }

    const patientRegex = new RegExp(`<span class="fr-chip-label">Patient - (.*?)</span>`, 'g');
    let currentVitals = patientCurrentVitals?.vitals ? patientCurrentVitals.vitals[0] : undefined
    while ((match = patientRegex.exec(letterTemplateContent)) !== null) {
      const fullMatch = match[0];
      const replaceMatch = match[1];
      const replacementString = getReplacementVitalString(replaceMatch, currentVitals);
      if (replacementString === null) {
        newContent = newContent.replace(fullMatch, '')
        continue
      }
      if (replacementString !== undefined) {
        newContent = newContent.replace(fullMatch, replacementString)
      }
    }

    const regexPatientProfile = new RegExp(`<span class="fr-chip-label">Patient profile - (.*?)(?: \\((.*?)\\))?</span>`, 'g')
    while ((match = regexPatientProfile.exec(letterTemplateContent)) !== null) {
      const fullMatch = match[0]
      const replaceMatch = match[1]
      const format = match[2]
      const replacementString = getReplacementProfileString(replaceMatch, format)
      newContent = newContent.replace(fullMatch, replacementString)
    }

    if (familyPractitioner) {
      const familyPractitionerRegex = new RegExp(`<span class="fr-chip-label">Family practitioner - \\(?(.*?)\\)?</span>`, 'g')
      while ((match = familyPractitionerRegex.exec(letterTemplateContent)) !== null) {
        newContent = replaceRegexMatch(match, getReplacementReferralPractitionerString, newContent)
      }
    }

    return newContent
  }

  const highlightChip = (letterTemplateContent: string, templateChipOption: TemplateChipOption): string => {
    if(!templateChipOption) return letterTemplateContent
    const regex = new RegExp(templateChipOption.pattern, 'g')
    const match = regex.exec(letterTemplateContent)
    if (match !== null) {
      const matchedChip = match[0]
      if (!matchedChip.includes('fr-chip-label highlighted')) {
        const highlightedChip = matchedChip.replace('fr-chip-label', 'fr-chip-label highlighted')
        letterTemplateContent = letterTemplateContent.replace(matchedChip, highlightedChip)
      }
    }
    return letterTemplateContent
  }

  const fillTemplateChips = (letterTemplateContent: string): string => {
    let availableMatches: { option: SELECTION_PANEL_OPTION, match: RegExpExecArray }[] = []
    let availableChips: TemplateChipOption[] = []

    const referringPractitionerPattern = `<span class="fr-chip-label(?: highlighted)?">Referring practitioner - \\(?(.*?)\\)?</span>`
    const familyPractitionerPattern = `<span class="fr-chip-label(?: highlighted)?">Family practitioner - \\(?(.*?)\\)?</span>`
    const appointmentPattern = `<span class="fr-chip-label(?: highlighted)?">Appointment - \\(?(.*?)\\)?</span>`
    const locationPattern = `<span class="fr-chip-label(?: highlighted)?">Location - \\(?(.*?)\\)?</span>`
    const textPattern = `<span class="fr-chip-label(?: highlighted)?">Text</span>`

    const referringPractitionerRegex = new RegExp(referringPractitionerPattern, 'g')
    const familyPractitionerRegex = new RegExp(familyPractitionerPattern, 'g')
    const appointmentRegex = new RegExp(appointmentPattern, 'g')
    const locationRegex = new RegExp(locationPattern, 'g')
    const textRegex = new RegExp(textPattern, 'g')

    const referringPractitionerMatch = referringPractitionerRegex.exec(letterTemplateContent)
    const familyPractitionerMatch = familyPractitionerRegex.exec(letterTemplateContent)
    const appointmentMatch = appointmentRegex.exec(letterTemplateContent)
    const locationMatch = locationRegex.exec(letterTemplateContent)

    if (referringPractitionerMatch !== null) {
      availableMatches.push({ option: "referring practitioner", match: referringPractitionerMatch })
    }
    if (familyPractitionerMatch !== null) {
      availableMatches.push({ option: "family practitioner", match: familyPractitionerMatch })
    }
    if (appointmentMatch !== null) {
      availableMatches.push({ option: "appointment", match: appointmentMatch })
    }
    if (locationMatch !== null) {
      availableMatches.push({ option: "location", match: locationMatch })
    }

    let textMatch
    while((textMatch = textRegex.exec(letterTemplateContent)) !== null) {
      availableMatches.push({ option: "text", match: textMatch })
    }

    availableMatches.sort((a, b) => {
      if (a.match.index > b.match.index) {
        return 1
      } else if (a.match.index < b.match.index) {
        return -1
      }
      return 0
    })

    availableMatches.forEach((availableMatch) => {
      switch (availableMatch.option) {
        case "referring practitioner":
          availableChips.push({
            option: "referring practitioner",
            pattern: referringPractitionerPattern,
            onReplace: getReplacementReferralPractitionerString
          })
          break
        case "family practitioner":
          availableChips.push({
            option: "family practitioner",
            pattern: familyPractitionerPattern,
            onReplace: getReplacementReferralPractitionerString
          })
          break
        case "appointment":
          availableChips.push({
            option: "appointment",
            pattern: appointmentPattern,
            onReplace: getReplacementAppointmentString
          })
          break
        case "location":
          availableChips.push({
            option: "location",
            pattern: locationPattern,
            onReplace: getReplacementLocationString
          })
          break
        case "text":
          availableChips.push({
            option: "text",
            matchIndex: availableMatch.match.index,
            pattern: textPattern,
            onReplace: (_, text: string) => text
          })
      }
    })

    if (availableChips.length > 0) {
      setTemplateChipOption(availableChips[0])
      setTemplateChipOptions(availableChips)
      return highlightChip(letterTemplateContent, availableChips[0])
    }
    return letterTemplateContent
  }


  const onSelectTemplateChipOption = (selectedOption?: SelectionOptionType) => {
    const currentOptionIndex = templateChipOptions.findIndex(o => o.option === templateChipOption!.option)
    const getReplacementString = !!selectedOption
      ? templateChipOptions[currentOptionIndex].onReplace
      : () => ""

    const currentContent = getValues('content')
    let newContent = currentContent
    const regex = new RegExp(templateChipOption!.pattern, 'g')

    let match

    if (templateChipOption!.option === "text") {
      if ((match = regex.exec(currentContent)) !== null) {
        const fullMatch = match[0]
        const replaceMatch = match[1]
        const replacementString = getReplacementString(replaceMatch, selectedOption) || ""
        newContent = newContent.replace(fullMatch, replacementString, 1)
      }
    } else {
      while ((match = regex.exec(currentContent)) !== null) {
        const fullMatch = match[0]
        const replaceMatch = match[1]
        const replacementString = getReplacementString(replaceMatch, selectedOption) || ""
        newContent = newContent.replace(fullMatch, replacementString)
      }
    }

    if (currentOptionIndex !== templateChipOptions.length - 1) {
      let availableChipOptions = templateChipOptions.filter((_, index) => index !== currentOptionIndex)

      if (templateChipOption!.option === 'appointment' && !!selectedOption) {
        const locationOption = availableChipOptions.find((o) => o.option === "location")
        if (!!locationOption) {
          const regex = new RegExp(locationOption!.pattern, 'g')
          let locationMatch
          while ((locationMatch = regex.exec(currentContent)) !== null) {
            const fullMatch = locationMatch[0]
            const replaceMatch = locationMatch[1]
            const replacementString = getReplacementLocationString(replaceMatch, (selectedOption as PatientAppointmentWithPractitionerDetailsAndClinicLocation)?.location) || ""
            newContent = newContent.replace(fullMatch, replacementString)
          }
          availableChipOptions = availableChipOptions.filter((o) => o.option !== "location")
        }
      }

      const nextOption = availableChipOptions[0]
      setTemplateChipOption(nextOption)
      setTemplateChipOptions(availableChipOptions)
      newContent = highlightChip(newContent, nextOption)
    } else {
      resetTemplateChipOptions()
    }

    setValue('content', newContent, { shouldDirty: true })
  }

  const recipientsOnChange = (e, value) => {
    setValue('recipientIds', value.map(v => v.value))
  }

  const onChange = (newContent: string) => {
    resetField('content', {defaultValue: newContent, keepDirty: false})
  }

  useEffect(()=>{
    const userPractitionerId = practitioners.find(p => p.userId === userId)?.id
    if (!!userPractitionerId) {
      letterDefaults.practitionerId = userPractitionerId
      setValue('practitionerId', userPractitionerId, { shouldDirty: false })
    }
  },[userId, practitioners])

  return <PermissionForm
    name={`${formName}_letter_form`}
    onSubmit={onSubmit}
    id={formName}
    requiredPermissionType={PermissionType.Chart}
    readOnlyOverride={true}
    formDisabled={formDisabled}
    sx={{
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      paddingRight: "8px"
    }}
  >
    <FormRow>
      {!letter && (
        <TemplateSelect
          label={'Template'}
          name={'letterTemplateId'}
          letterTemplateId={letterTemplateId ?? ""}
          onChange={letterTemplateOnSelect}
          autoFocus={true}
          inputRef={firstFieldRef}
          dataTestId={`${dataTestId}-template`}
        />
      )}
      <TextField label={'Title'} name={'title'} sx={{width:"100%"}} dataTestId={`${dataTestId}-title`} />
      <PractitionerSelect label={'Practitioner'} name={'practitionerId'} dataTestId={`${dataTestId}-practitioner`} />
    </FormRow>

    <FormRow>
      <TextField label={'Notes'} name={'notes'} sx={{ width: 408 }} dataTestId={`${dataTestId}-notes`} />
      <ReferralPractitionerChipSelect
        label={'Letter recipient(s)'}
        name={'recipients'}
        sx={{width: 408}}
        onChange={recipientsOnChange}
        dataTestId={`${dataTestId}-recipients`}
      />
      <FormattedDatePicker label={'Date'} name={'letterDate'} dataTestId={`${dataTestId}-date`} />
    </FormRow>
    <Box display={"flex"} flexDirection={"row"} alignItems={"stretch"} gap={1} minHeight={0} mt={1}>
      <LetterFormChipSelectionPanel
        chipOption={templateChipOption}
        referralPractitioners={patientRelationships.referralPractitioners || []}
        onSelect={onSelectTemplateChipOption}
        dataTestId={dataTestId}
      />
      <RichTextEditorClient
        name={"content"}
        editorRef={editorRef}
        textEditorHeight={'1000px'}
        onChange={shouldCallOnChange ? onChange : undefined}
        disabled={!!templateChipOption}
        sx={{
          mt: 0,
          overflow: "hidden",
          width: "100%"
        }}
        dataTestId={`${dataTestId}-content`}
      />
    </Box>
    <ConfirmationDialog
      open={!!letterTemplateChanged.message}
      title={'Change letter template?'}
      message={letterTemplateChanged.message}
      primaryAction={() => {
        letterTemplateOnChange(letterTemplateChanged.selectedLetterTemplate)
      }}
      primaryLabel={'discard'}
      onClose={clearLetterTemplateChanged}
      dataTestId={'change-letter-template-confirmation'}
    />
  </PermissionForm>
}