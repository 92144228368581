import React from 'react'
import { MenuItem } from '@mui/material'
import { Select } from 'saga-library/src'
import { useTenantContext } from '../providers/TenantContextProvider'
import _sortBy from 'lodash/sortBy'

interface IdentifierTypeSelectProps {
  label: string
  name: string
  onChange?: (value) => void
  disabled?: boolean
  dataTestId: string
}

export const IdentifierTypeSelect = ({
  name = "Identifier type",
  label,
  onChange,
  disabled,
  dataTestId
}: IdentifierTypeSelectProps) => {
  const { tenantIdentifiers } = useTenantContext()

  return (
    <Select
      dataTestId={dataTestId}
      name={name}
      label={label}
      onChange={(value) => {
        if (onChange) {
          onChange(value)
        }
      }}
      disabled={disabled}
    >
      {_sortBy(tenantIdentifiers, 'name').map((identifier, index) => (
        <MenuItem
          key={identifier.id}
          value={identifier.id}
          data-testid={`${dataTestId}-identifier-type-select-${index}`}
        >
          {`${identifier.name} ${identifier.province ? "("+identifier.province+")": ''}`}
        </MenuItem>
      ))}
    </Select>
  )
}
