import { useAccountContext } from "../../../../../providers/AccountContext";
import { useParams } from "react-router-dom";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { PrescriptionType } from "../../../../../types/Prescription";
import { prescriptionFormDefaultValues } from "../../prescriptions/components/PrescriptionFormDefaultValues";
import { schema, requiredSchema } from "../../prescriptions/components/PrescriptionFormValidationsSchema";
import { usePrescriptionUtils } from "../../prescriptions/components/PrescriptionUtil";
import { DialogV2 } from "saga-library/src";
import { SavePrescriptionButton } from "../../prescriptions/components/PrescriptionActions";
import { NewPrescriptionForm } from "../../prescriptions/components/NewPrescriptionForm";
import { Permission, PermissionType } from "../../../../../types/settings/Permission";
import { usePrescriptionContext } from "../../../providers/PrescriptionProvider";

const FORM_NAME = "new_prescription_form_dialog"

const NewPrescriptionDialog = ({open, setOpen, onSaveAndNew:onSaveAndNewParent}) => {
  const { createDraftPrescription } = usePrescriptionContext()
  const { tenant_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const { setAsHistorical, saveAsDraft } = usePrescriptionUtils()
  const { userHasPermission } = useAccountContext()

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Chart, Permission.READWRITE))

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<PrescriptionType>({
    defaultValues: prescriptionFormDefaultValues(),
    schema: schema,
    requiredSchema: requiredSchema
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
    reset,
    getValues,
  } = formMethods

  useEffect(()=> {
    if(open) {
      reset(prescriptionFormDefaultValues(), {keepDirty: false})
    }
  }, [open])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = () => {
    setOpen(false)
  }

  const onSaveAsDraft = (e) => {
    saveAsDraft(
      handleSubmit,
      ()=> {
        setOpen(false)
      }
    )
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(false)
    }
  }

  const onSaveAndNew = () => {
    handleSubmit(
      async(data) => {
        createDraftPrescription(data as PrescriptionType, (prescription)=> {
          setOpen(false)
          reset(prescriptionFormDefaultValues(), {keepDirty: false})
          onSaveAndNewParent()
        })
      }
    )()
  }

  const onSetAsHistorical = () => {
    setAsHistorical(
      handleSubmit,
      () => {
        setOpen(false)
      }
    )
  }

  return <DialogV2
    dataTestId={'new-prescription'}
    title={'New prescription'}
    onClose={onCancel}
    primaryAction={()=>null}
    overridePrimaryComponent = {
      <SavePrescriptionButton
        formName={FORM_NAME}
        onSaveAsDraft={onSaveAsDraft}
        getValues={getValues}
        onSetAsHistorical={onSetAsHistorical}
      />
    }
    secondaryAction={onSaveAndNew}
    secondaryLabel={hasEditPermission?'save and new':undefined}
    open={open}
    contentSx={{paddingRight: '0px'}}
  >
    <FormProvider {...formMethods}>
      <NewPrescriptionForm
        formName={FORM_NAME}
        onSubmit={onSubmit}
      />
    </FormProvider>
  </DialogV2>
}

export default NewPrescriptionDialog