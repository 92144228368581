import React from 'react'
import { Form, DataGrid } from 'saga-library/src'
import { borderlessFieldsSx, ExtendedGridColDef } from 'saga-library/src/components/DataGrid/DataGrid'
import { usePatientProfileContext } from '../../providers/PatientProfileProvider'
import {
  formatEnumToSentenceCase,
} from '../../../../types/FamilyHistory'
import { ExtendedFamilyRelationshipSearch } from './ExtendedFamilyRelationshipSearch'
import {
  ConditionAndProcedureDescriptionGridSearch
} from '../../../../components/SearchControls/Patient/ConditionAndProcedureDescriptionGridSearch'

export const FAMILY_HISTORY_FORM_ID = 'family-history-form'

interface FamilyHistoryFormProps {
  onSubmit: () => void
  dataGridRef: React.MutableRefObject<any>
}

const familyHistoryColumns = (firstName='') => ([
  {
    field: 'condition',
    type: 'string',
    headerName: 'Condition',
    editable: true,
    required: true,
    valueGetter: (value: any) => value && value.description ? value.description : value,
    renderCell: params => (params?.value && params?.value.description) ? params?.value.description : params?.value,
    renderEditCell: (params) => (
      <ConditionAndProcedureDescriptionGridSearch
        params={params}
        dataTestId={`${FAMILY_HISTORY_FORM_ID}-condition-search`}
        name={'condition-search'}
        placeholderText={'Condition required'}
        sx={borderlessFieldsSx}
      />
    )
  },
  {
    field: 'relationship',
    headerName: `Relationship to ${firstName}`,
    editable: true,
    required: true,
    valueGetter: (value: any) => value?.value ? value.value : value,
    renderCell: params => (params?.value && params?.value.label) ? params?.value.label : formatEnumToSentenceCase(params?.value),
    renderEditCell: (params) => (
      <ExtendedFamilyRelationshipSearch
        dataTestId={`${FAMILY_HISTORY_FORM_ID}-relationship-search`}
        name={'relationship-search'}
        params={params}
        placeholderText={'Relationship required'}
        sx={borderlessFieldsSx}
      />
    )
  },
  {
    field: 'notes',
    headerName: 'Notes',
    editable: true
  }
])

export const FAMILY_HISTORY_COLUMN_KEYS = familyHistoryColumns().map(col => col.field)

export const FamilyHistoryForm = ({
  onSubmit,
  dataGridRef
} : FamilyHistoryFormProps) => {
  const { profileData } = usePatientProfileContext()

  return (
    <Form onSubmit={onSubmit} id={FAMILY_HISTORY_FORM_ID}>
      <DataGrid
        autoFocus={true}
        dataGridRef={dataGridRef}
        name={'familyHistory'}
        initialColumns={familyHistoryColumns(profileData.firstName) as ExtendedGridColDef[]}
        dataTestId={'familyHistory'}
        addLabel={'condition'}
        height={'662px'}
        emptyListMessage={'Family history appears here once added'}
      />
    </Form>
  )
}