import React, { useEffect } from 'react'
import { SkillSelect, SkillValue } from "components/SkillSelect/SkillSelect";
import { DisciplineSelect } from 'components/DisciplineSelect/DisciplineSelect'
import { Section, Switch, TextField, Typography } from "saga-library/src";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { FormFragment } from "../../../../../components/PermissionForm";

export type AdministrationDetailsType = {
  skills: SkillValue[]
  notes: string
  disciplineId: string
}

const defaults = {
  skills: [],
  notes: '',
  disciplineId: '',
  active: true,
}

const Form = ({ prevProvince, requiredPermissionType }) => {
  const { control, setValue } = useFormContext()

  const province = useWatch({
    control,
    name: 'province',
  })

  useEffect(() => {
    if (province !== prevProvince) {
      setValue('skills', [])
      setValue('disciplineId', '')
    }
  }, [province])

  return (
    <FormFragment requiredPermissionType={requiredPermissionType} readOnlyOverride={true}>
      <Section.FormSection sx={{ flex: '0 0 auto'}}>
        <Section.Header>Administration</Section.Header>
        <SkillSelect
          name={'skills'}
          provinceCode={province}
          multiple
        />
        <DisciplineSelect
          provinceCode={province}
        />
        <Section.Header>Notes</Section.Header>
        <TextField name={'notes'} label={'Notes'} />
        <Switch
          name={'active'}
          label={'Active'}
        />
        <Typography variant={'body2'}>
          Inactive referral practitioners won’t appear when searching for referral practitioners.
        </Typography>
      </Section.FormSection>
    </FormFragment>
  )
}

export const AdministrationDetails = {
  Form: Form,
  defaults: defaults,
}
