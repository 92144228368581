import { EntryTitle } from "saga-library/src";
import React from "react";
import { ResultOptionsWrapper } from "saga-library/src";

interface CityResultProps {
  city: string
  searchText: string
}

export const CityResult = ({city, searchText}: CityResultProps) => {
  return (
    <ResultOptionsWrapper>
      <EntryTitle
        title={city}
        searchText={searchText}
      />
    </ResultOptionsWrapper>
  )
}