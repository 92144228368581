import React from "react"
import { useScheduleContext } from "../../../providers/ScheduleContextProvider";
import { ConfirmationDialog, Typography } from "saga-library/src";
import { Box } from "@mui/material";
import { useLocationContext } from "../../../providers/LocationContextProvider";


export interface AppointmentCheckDialogOpenProps {
  checks: string[],
  appointmentMove?: boolean,
  eventData: any,
}

interface AppointmentCheckDialogProps {
  checks: string[],
  eventData: any,
  onCreate: (eventData) => void,
  onMove: (eventData) => void,
  onClose: () => void,
  appointmentMove?: boolean
}
export const AppointmentCheckDialog = ({
  checks,
  eventData,
  onCreate,
  onClose,
  onMove,
  appointmentMove
}: AppointmentCheckDialogProps) => {
  const { getScheduleData, selectedScheduleIds } = useScheduleContext()
  const { getLocationName } = useLocationContext()

  if(!checks.length){
    return null
  }

  const scheduleId =  eventData?.resource || selectedScheduleIds[0]
  const scheduleData = getScheduleData(scheduleId)
  const locationName = scheduleData?.locationId ? getLocationName(scheduleData.locationId) : ''
  const practitionerName = scheduleData?.practitionerName || ''

  const messageContent = () => {
    return <>
      <Typography>Please be aware of the following {checks.length>1?"concerns":"concern"} with this time slot.</Typography>
      <Box pl={2} >
        <ul>
          {checks.includes('invalidType') &&<li><Typography>This appointment type isn’t offered by <b>{practitionerName} ({locationName})</b>. If you move this appointment, the appointment type will be cleared.</Typography></li> }
          {checks.includes('allDayOverlap') && <li><Typography>An all-day event is scheduled at this time.</Typography></li> }
          {checks.includes('eventOverlap') && <li><Typography>An event is scheduled at this time.</Typography></li> }
          {checks.includes('offSchedule') && <li><Typography>Time slot is outside of scheduled hours.</Typography></li> }
          {checks.includes('inPast') && <li><Typography>Time slot is in the past.</Typography></li> }
        </ul>
      </Box>
    </>
  }


  return <ConfirmationDialog
      open={!!checks.length}
      title={appointmentMove ? "Move appointment?" : "Continue booking?"}
      message={messageContent()}
      primaryAction={()=>{
        if(appointmentMove){
          if(checks.includes('invalidType')){
            eventData.type = null
            eventData.appointmentTypeId = null
          }
          eventData.skipChecks = true
          onMove(eventData)
        } else {
          onCreate(eventData)
        }
        onClose()
      }}
      primaryLabel={appointmentMove ? 'Move' : 'Continue'}
      onClose={onClose}
    />
}