import { TextField, Form, RadioGroup, Typography } from 'saga-library/src'
import React from 'react'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { rules } from './TaskStateValidationsSchema'

interface TaskStateFormProps {
  formName: string
  onSubmit: () => void
  dataTestId: string
}

export const TaskStateForm = ({
  formName,
  onSubmit,
  dataTestId
}: TaskStateFormProps) => {
  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TextField
        name={'name'}
        label={'Name'}
        inputProps={{ maxLength: rules.name.max }}
        fullWidth={true}
        dataTestId={`${dataTestId}-name`}
      />
      <Typography variant={'h5'} dataTestId={`${dataTestId}-category-title`}>State category</Typography>
      <Typography variant={'body1'} dataTestId={`${dataTestId}-category-subtitle`}>Categories group statuses for filtering purposes.</Typography>
      <RadioGroup
        name={'category'}
        row={true}
        sx={{ justifyContent: "left" }}
        options={[
          { label: 'Incomplete', value: TaskCategory.INCOMPLETE },
          { label: 'Done', value: TaskCategory.DONE },
        ]}
        dataTestId={`${dataTestId}-category`}
      />
    </Form>
  )
}
