import _get from "lodash/get";
import { ControlledSearch } from "saga-library/src";
import {
  ControlledSearchControlProps
} from "saga-library/src/components/SearchControl/ControlledSearchControl";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { CityResult } from "./CityResult";
import { GET_CITY } from "saga-client/src/graphql-definitions";
import { useFormContext, useWatch } from "saga-library/src/components/Form";

export interface CitySearchProps<
  T = string,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = true
  > extends Omit<ControlledSearchControlProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
  >, 'queries'|'render'|'transform'|'isOptionEqualToValue'> {
}

export const CitySearch = ({
  name = 'city',
  label = 'City',
  ...props
}: CitySearchProps) => {
  const { control } = useFormContext()
  const province = useWatch({
    control,
    name: 'province'
  })

  const { tenant_id } = useParams()

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id,
    provinceCode: province ? province : null
  }), [tenant_id, province])

  return (
    <ControlledSearch<string, false, false, true>
      {...props}
      freeSolo
      name={name}
      label={label}
      queries={{
        search: {
          gql: GET_CITY,
          get: (data) => _get(data, 'tenant.search.reference.citiesByProvince', []).map(c => (c as any).name),
          variables: queryVariables,
          fetchPolicy: 'cache-first'
        }
      }}
      render={{
        value: (city) => (city || ""),
        option: (city, state) => (
          <CityResult
            city={city}
            searchText={state.inputValue}
          />
        )
      }}
    />
  )
}
