import { gql } from "@apollo/client";

export const SOCIAL_HISTORY_FRAGMENT = gql`
  fragment SocialHistoryFragment on SocialHistory {
    patientId
    exerciseFrequency
    exerciseNote
    alcoholFrequency
    alcoholNote
    smokingFrequency
    smokingNote
    vapingFrequency
    vapingNote
    caffeineFrequency
    caffeineNote
    cannabisFrequency
    cannabisNote
    otherDrugsFrequency
    otherDrugsNote
    version
    audit {
      crePit
      luPit
    }
  }
`

export const GET_PATIENT_SOCIAL_HISTORY = gql`
  ${SOCIAL_HISTORY_FRAGMENT}
  query GetPatientSocialHistory($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        socialHistory {
          get {
            ...SocialHistoryFragment
          }
        }
      }
    }
  }
`

export const UPDATE_SOCIAL_HISTORY = gql`
  ${SOCIAL_HISTORY_FRAGMENT}
  mutation UpdateSocialHistory($patientId: ID!, $tenantId: ID!, $input: SocialHistoryInput!) {
    tenant(id: $tenantId) {
      patient {
        socialHistory {
          update(patientId: $patientId, input: $input) {
            ...SocialHistoryFragment
          }
        }
      }
    }
  }
`