import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { ConfirmationDialog } from "saga-library/src";
import {useLocation, useNavigate, UNSAFE_NavigationContext} from "react-router-dom"
import type {History, Blocker, Transition} from "history"

type NavigationPromptType = {
  enableNavigationPrompt:
    (enablePrompt: boolean | (() => boolean),
    formName: string,
    rootPath?: string,
    openDialog?: boolean,
    handleNavigationPromptDiscard?: (e: boolean) => void) => void
  clearNavigationPrompt:
    (formName: string) => void
}

const NavigationPrompt = React.createContext<NavigationPromptType>({
  enableNavigationPrompt: (enablePrompt, formName, rootPath, openDialog, handleNavigationPromptDiscard) => void enablePrompt,
  clearNavigationPrompt: (formName) => void false
})

export const NavigationPromptProvider = ({children}) => {
  const [ when, setWhen ] = useState(false)
  const [ rootPath, setRootPath ] = useState<string>()
  const [ openDialog, setOpenDialog ] = useState(false)
  const [ handleNavigationPromptDiscard, setHandleNavigationPromptDiscard ] = useState<(e : boolean)=>void>(e => e)
  const [ disableOnNavigation, setDisableOnNavigation ] = useState<boolean>(false)
  const [ formName, setFormName ] = useState<string>('')
  const [ showPrompt, confirmNavigation, cancelNavigation ] = useNavigationPrompt({
    when,
    formName,
    rootPath,
    openDialog,
    handleNavigationPromptDiscard,
    disableOnNavigation,
    afterNavigation: () => {
      if (disableOnNavigation) {
        setDisableOnNavigation(false)
      }
    }
  })

  const enableNavigationPrompt = useCallback((enablePrompt: boolean | (()=>boolean), formName: string, rootPath?: string, openDialog?: boolean, handleNavigationPromptDiscard?: (e : boolean) => void) =>{
    if(rootPath){
      setRootPath(rootPath)
    }
    if(typeof enablePrompt === "function"){
      setWhen(enablePrompt())
    } else {
      setWhen(enablePrompt)
    }
    setFormName(formName)
    setOpenDialog(openDialog ?? false)
    setHandleNavigationPromptDiscard(() => handleNavigationPromptDiscard)
  },[])

  const clearNavigationPrompt = useCallback((formName: string) => {
    setDisableOnNavigation(true)
    setWhen(false)
    setFormName(formName)
  }, [])

  const value = useMemo(
    () => ({ enableNavigationPrompt, clearNavigationPrompt }),
    [enableNavigationPrompt, clearNavigationPrompt]
  )

  return <NavigationPrompt.Provider value={value}>
    {children}
    <NavigationPromptDialog
      open={showPrompt as boolean}
      onCancel={cancelNavigation as ()=>void}
      onDiscard={confirmNavigation as ()=>void}
    />
  </NavigationPrompt.Provider>
}

export const NavigationPromptDialog = ({open, onCancel, onDiscard}) => {

  return (
    <ConfirmationDialog
      open={open}
      title={"Discard unsaved changes?"}
      message={"Leaving this page without saving will discard all unsaved changes."}
      primaryLabel={'discard changes'}
      primaryAction={onDiscard}
      onClose={onCancel}
    />
  )
}

export const usePrompt = () => {
  return useContext(NavigationPrompt)
}

interface useNavigationPromptProps {
  when: boolean,
  formName: string,
  rootPath?: string,
  openDialog?: boolean,
  handleNavigationPromptDiscard?: (e:boolean) => void
  disableOnNavigation?: boolean
  afterNavigation: () => void
}
export const useNavigationPrompt = ({
  when,
  formName,
  rootPath,
  openDialog = false,
  handleNavigationPromptDiscard = (e) => (e),
  disableOnNavigation = false,
  afterNavigation
} : useNavigationPromptProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [enabled, setEnabled] = useState(when)
  const [rootLocation, setRootLocation] = useState(rootPath)
  const [showPrompt, setShowPrompt] = useState(false)
  const [targetLocation, setTargetLocation] = useState<any>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [cancelledNavigation, setCancelledNavigation] = useState(false)
  const [dirtyForms, setDirtyForms] = useState<string[]>([])

  const pushDirtyForm = (form: string) => {
    if (dirtyForms.includes(form)) {
      return
    }
    setDirtyForms(prevStack => [...prevStack, form]);
  }

  const popDirtyForm = (form?: string) => {
    if (form && dirtyForms.includes(form)) {
      setDirtyForms(prevStack => prevStack.filter(f => f !== form));
      return
    }
    setDirtyForms(prevStack => prevStack.slice(0, -1));
  }

  useEffect(()=>{
    if (when) {
      pushDirtyForm(formName)
    } else if (dirtyForms.includes(formName)) {
      popDirtyForm(formName)
    }
    setRootLocation(rootPath)
  },[when, rootPath, formName])

  useEffect(()=>{
    setEnabled(dirtyForms.length > 0)
  }, [dirtyForms])

  useEffect(()=>{
    if (openDialog) {
      setShowPrompt(true)
    }
  }, [openDialog])

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if(rootLocation && nextLocation.location.pathname.includes(rootLocation)){
        setTargetLocation(nextLocation)
        setConfirmedNavigation(true)
        return true
      }

      if (disableOnNavigation && nextLocation.location.pathname !== location.pathname) {
        setTargetLocation(nextLocation)
        setConfirmedNavigation(true)
        return true
      }

      if(!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true)
        setTargetLocation(nextLocation)
        return false
      }

      return true
    },
    [confirmedNavigation, disableOnNavigation, location, rootLocation]
  )

  const cancelNavigation = useCallback(()=>{
    setShowPrompt(false)
    setCancelledNavigation(true)
  }, [])

  const confirmNavigation = useCallback(()=>{
    popDirtyForm()
    setShowPrompt(false)
    setConfirmedNavigation(true)
  }, [])

  useEffect(()=>{
    if(confirmedNavigation){
      if (openDialog) {
        handleNavigationPromptDiscard(true)
        setConfirmedNavigation(false)
      }
      if (targetLocation && !openDialog) {
        navigate(targetLocation.location.pathname)
        setConfirmedNavigation(false)
      }
      afterNavigation()
    }
  }, [confirmedNavigation, targetLocation])

  useEffect(()=>{
    if (cancelledNavigation) {
      if (openDialog) {
        handleNavigationPromptDiscard(false)
      }
      setCancelledNavigation(false)
    }
  }, [cancelledNavigation])

  useNavigationBlock(handleBlockedNavigation, enabled)

  return[showPrompt, confirmNavigation, cancelNavigation]
}
export const useNavigationBlock = (blocker: Blocker, when = false) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History

  useEffect(() => {
    if(!when){
      return
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry(){
          unblock()
          tx.retry()
        }
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}

