import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { BookingPreferenceInput } from "../../../../types/schedule";
import { schema } from "../../util/bookingPreferenceValidation";
import moment from "moment-timezone";
import { BookingPreferenceForm } from "../../components/BookingPreference/BookingPreferenceForm";
import { DialogV2, RemoveButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_APPOINTMENT_TYPES } from "../../../../graphql-definitions";
import { addDurationToDate, diffDates } from "saga-library/src/util";
import { getAppointmentTitle } from '../../util/appointmentDialogFunctions'

const DATA_TEST_ID = "editTemplateBookingPreference"
const FORM_NAME = "edit_template_booking_preference_form"

export const EditTemplateBookingPreference = ({ open, onClose, eventData, day, onBookingPreferenceDelete, onBookingPreferenceUpdate, scheduleLength }) => {
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { enableNavigationPrompt } = usePrompt()
  const { tenant_id } = useParams()

  const { data } = useQuery(LIST_APPOINTMENT_TYPES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id }
  })
  const allAppointmentTypes = _get(data, 'tenant.schedule.type.listTypes', [])

  const defaultValues = {
    version: eventData.version,
    start: moment(eventData.start),
    end: moment(eventData.end),
    title: eventData.dbTitle,
    appointmentTypeIds: eventData.appointmentTypeIds || [],
    appointmentTypes: allAppointmentTypes.filter((at) => eventData.appointmentTypeIds?.includes(at.id))
  }

  const formMethods = useForm<BookingPreferenceInput>({
    defaultValues: defaultValues,
    schema: schema
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    setValue,
    reset
  } = formMethods

  useEffect(() => {
    const appointmentTypeIds = eventData.appointmentTypeIds || []
    let appointmentTypes = allAppointmentTypes.filter((at) => appointmentTypeIds?.includes(at.id))
    if (appointmentTypes.length === 0 && appointmentTypeIds.length > 0) {
      appointmentTypes = appointmentTypeIds
    }
    setValue('appointmentTypes', appointmentTypes, { shouldDirty: false })
  }, [allAppointmentTypes, eventData.appointmentTypeIds, setValue])

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      reset()
      onClose()
    }
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        reset()
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = handleSubmit(async (data) => {
    const startofday = moment(data.start).startOf('day')
    const start = moment.duration(diffDates(startofday, moment(data.start))).toISOString()
    const length = diffDates(data.start, moment(data.end), 'minutes')
    const end = addDurationToDate(moment.duration(length, 'minutes'), moment(start))

    let updatedBookingPreferenceInput = {
      ...data,
      appointmentTypes: data.appointmentTypes,
      appointmentTypeIds: data.appointmentTypes?.map((at) => at.id) || [],
      length: length,
      day: day,
      start: start,
      end: end,
      localId: eventData.localId,
      dbTitle: data.title,
    }

    onBookingPreferenceUpdate(updatedBookingPreferenceInput)

    enableNavigationPrompt(false, FORM_NAME)
    reset()
    onClose()
  })

  return (
    <DialogV2
      open={open}
      onClose={onCancel}
      title={getAppointmentTitle({
        start: eventData.start,
        end: eventData.end,
        scheduleLength: scheduleLength
      })}
      headerActions={[
        <RemoveButton
          onClick={() => {
            onBookingPreferenceDelete(eventData, day)
            onClose()
          }}
          dataTestId={DATA_TEST_ID}
        />
      ]}
      primaryAction={onSubmit}
      formName={FORM_NAME}
      submitting={isSubmitting}
      dataTestId={DATA_TEST_ID}
    >
      <FormProvider {...formMethods}>
        <BookingPreferenceForm
          formName={FORM_NAME}
          onSubmit={onSubmit}
          dataTestId={DATA_TEST_ID}
        />
      </FormProvider>
    </DialogV2>
  )
}