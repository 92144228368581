import React, { useEffect, useState } from "react";
import { TimePicker } from "saga-library/src";
import { useFormContext, useWatch } from "saga-library/src/components/Form";
import { Box } from "@mui/material";
import { addDurationToDate, diffDates } from "saga-library/src/util";
import moment from "moment-timezone";

interface AppointmentDatePickerProps {
  dataTestId?: string
}

export const AppointmentDatePicker = ({
  dataTestId
}: AppointmentDatePickerProps) => {
  const { control, setValue, getValues } = useFormContext()
  const [duration, setDuration] = useState<number>(diffDates(getValues('start'), getValues('end'), 'minutes'))

  const startTime = useWatch({
    control,
    name: 'start'
  })

  const endTime = useWatch({
    control,
    name: 'end'
  })

  useEffect(() => {
    setValue('end', addDurationToDate(moment.duration(duration, 'minutes'), moment(startTime)))
  }, [startTime])

  useEffect(() => {
    setDuration(diffDates(startTime, endTime, 'minutes'))
  }, [endTime])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px' }}>
      <TimePicker dataTestId={`${dataTestId}-start`} label={'Start'} name={'start'} />
      <TimePicker dataTestId={`${dataTestId}-end`} label={'End'} name={'end'} />
    </Box>
  )
}
