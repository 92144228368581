import React from "react";
import { useParams } from "react-router-dom";
import { useBackgroundQuery, QueryReference, useMutation } from '@apollo/client'
import {
  GET_PATIENT_LAB_AND_INVESTIGATIONS,
  UPDATE_LAB_AND_INVESTIGATION
} from '../../../graphql-definitions'
import { AbLabResult, AbLabResultUpdate } from '../../../types/patients'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import {
  filterResultsForList,
  LabAndInvestigationSortPredicate
} from '../components/labAndInvestigations/LabAndInvestigationsUtil'
import { DocumentCategory } from '../../../types/Document'

const getLabAndInvestigationQueryResults = (data) => {
  return [..._get(data, 'tenant.patient.labAndInvestigation.list', []) as Array<AbLabResult>]
    .map(filterResultsForList)
}

const parseLabAndInvestigationQueryResults = (data) => {
  return getLabAndInvestigationQueryResults(data).sort(LabAndInvestigationSortPredicate)
}

interface LabAndInvestigationContextInterface {
  labAndInvestigationQueryRef: QueryReference | null,
  getLabAndInvestigationQueryResults: (any) => AbLabResult[],
  parseLabAndInvestigationQueryResults: (any) => AbLabResult[],
  updateLabAndInvestigation: (labAndInvestigation: AbLabResultUpdate, onSuccess?:(labAndInvestigation: AbLabResult) => void) => Promise<void>
}

const defaultLabAndInvestigationContext: LabAndInvestigationContextInterface = {
  labAndInvestigationQueryRef: null,
  getLabAndInvestigationQueryResults: getLabAndInvestigationQueryResults,
  parseLabAndInvestigationQueryResults: parseLabAndInvestigationQueryResults,
  updateLabAndInvestigation: () => new Promise<void>(() => {})
}

const LabandInvestigationContext = React.createContext(defaultLabAndInvestigationContext)

export const LabAndInvestigationProvider = ({ children }) => {
  const { tenant_id, patient_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const [ updateLabAndInvestigationMutation ] = useMutation(UPDATE_LAB_AND_INVESTIGATION)

  const [labAndInvestigationQueryRef] = useBackgroundQuery(GET_PATIENT_LAB_AND_INVESTIGATIONS, {
    variables: {
      patientId: patient_id,
      tenantId: tenant_id
    }
  })

  const updateLabAndInvestigation = async (labAndInvestigationInput, onSuccess) => {
    const id = labAndInvestigationInput.id
    delete labAndInvestigationInput.id

    await updateLabAndInvestigationMutation({
      variables: {
        tenantId: tenant_id,
        id: id,
        input: labAndInvestigationInput
      },
      onCompleted: (data) => {
        showSuccessAlert("Lab and investigation has been saved")
        if (onSuccess) {
          const labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.update', null)
          onSuccess(labAndInvestigation)
        }
      },
      onError: () => {
        showErrorAlert("Lab and investigation couldn't be saved")
      }
    })
  }

  const providerValues = {
    labAndInvestigationQueryRef,
    getLabAndInvestigationQueryResults,
    parseLabAndInvestigationQueryResults,
    updateLabAndInvestigation
  }

  return (
    <LabandInvestigationContext.Provider value={providerValues}>
      { children }
    </LabandInvestigationContext.Provider>
  )
}

export const useLabAndInvestigationContext = () => {
  return React.useContext(LabandInvestigationContext)
}

const updateLabsAndInvestigationsCache = async(cache, newLab, tenantId, patientId) => {
  await cache.updateQuery({
    query: GET_PATIENT_LAB_AND_INVESTIGATIONS,
    variables: {
      tenantId,
      patientId
    }
  }, (data) => {
    const existingLabs = _get(data, 'tenant.patient.labAndInvestigation.list', []).filter(d => d.id !== newLab.id || d.isLinkedDocument !== newLab.isLinkedDocument)

    return {
      tenant: {
        patient: {
          labAndInvestigation: {
            list: [...existingLabs, newLab]
          }
        }
      }
    }
  })
}

const transformLinkedDocument = (document) => {
  const isLab = document.category === DocumentCategory.LAB_RESULT
  const description = !!document.description || document.description.trim().length > 0
    ? document.description
    : isLab ? "Lab result" : "Investigation"

  return {
    id: document.id,
    date: document.documentDate,
    description: description,
    isLinkedDocument: true,
    linkedDocument: document,
    __typename: 'AbLabResult',
    abLabResultObservationResults: [],
    isLab: isLab,
    notes: "",
    audit: document.audit,
    isAbnormal: null,
    version: document.file.version
  }
}

export const moveDocumentToLabAndInvestigation = async(cache, document, tenantId, patientId) => {
  const newLab = transformLinkedDocument(document)

  await updateLabsAndInvestigationsCache(cache, newLab, tenantId, patientId)
}
