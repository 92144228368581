import React, { useEffect } from "react";
import { NoteComponentList, Section } from "saga-library/src";
import { Note } from "saga-library/src/components/Note/NoteTypes";
import { PatientSectionHeader } from "../PatientSectionHeader";
import { Form } from "saga-library/src";
import { useForm, FormProvider } from "saga-library/src/components/Form"
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ChartPanelHOC } from "../../util/ChartPanelHOC";
import { usePatientProfileContext } from "../../providers/PatientProfileProvider";

export type PatientNotesType = {
  notes: Array<Note> | null
  version: string
}

export const PatientNotesDefaults = {
  notes: []
}

const FormName = "patient-notes-form"
const SectionTitle = "Notes"

export const PatientNotes = ChartPanelHOC(SectionTitle, (props) => {
  const { profileData } = usePatientProfileContext()
  const simplifiedData = {
    notes: profileData.notes && profileData.notes.length > 0 ? profileData.notes : [{ noteText: "" }],
    version: profileData.version
  }
  return profileData && <PatientNotesPanel profileData={simplifiedData} {...props} />
})

export const PatientNotesPanel = ({ profileData, ...props }) => {
  const { enableNavigationPrompt } = usePrompt()
  const { updatePatientNotes } = usePatientProfileContext()

  const formMethods = useForm<PatientNotesType>({
    values: profileData,
  })

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FormName)
    return () => enableNavigationPrompt(false, FormName)
  }, [Object.keys(dirtyFields).length])

  const onSubmit = handleSubmit(async (data, event) => {
    if (event && event.target.name !== FormName) {
      return
    }
    await updatePatientNotes(data, (updated) => {
      const simplifiedData = {
        notes: updated.notes,
        version: updated.version
      }
      reset(simplifiedData)
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Form
        autoComplete={'false'}
        name={FormName}
        id={FormName}
        onSubmit={onSubmit}
        style={{
          height:'100%',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}
      >
        <Section.Column
          sx={{ flex: "1 1 100%" }}
          header={<PatientSectionHeader formName={FormName} dataTestId={"patient-notes"} sectionLabel={SectionTitle} submitting={isSubmitting} />}
        >
          <NoteComponentList />
        </Section.Column>
      </Form>
    </FormProvider>
  )
}








