import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { Menu, MenuItem } from "@mui/material";
import { IconButton, Form, DialogV2, Select, Typography } from "saga-library/src";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTenantContext } from "../../../providers/TenantContextProvider";
import { tenantUserSettings } from "../../../utils/TenantUserSettings";
import { usePrompt } from "../../../providers/NavigationPrompt";
import * as yup from "yup";

const DATA_TEST_ID = "schedule-options"
const FORM_NAME = "schedule_options_form"

const schema = yup.object().shape({
  timeScale: yup.number().required()
}).required();

export const ScheduleOptionsMenu = () => {
  const { getUserTenantSettings } = useTenantContext()
  const { enableNavigationPrompt } = usePrompt()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openTimeScaleDialog, setOpenTimeScaleDialog] = useState<boolean>(false)
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const { updateUserTenantSetting } = useTenantContext()
  const { TIME_SCALE_SETTING, SHOW_ROOM_SETTING } = tenantUserSettings

  const userTenantSettings = getUserTenantSettings([TIME_SCALE_SETTING, SHOW_ROOM_SETTING])

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const formMethods = useForm<{timeScale: number}>({
    values: { timeScale: Number(userTenantSettings[TIME_SCALE_SETTING.name]) },
    schema: schema
  })
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, dirtyFields }
  } = formMethods

  useEffect(() => {
    if(openTimeScaleDialog) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openTimeScaleDialog, openNavigationPrompt]);

  const onFormClose = () => {
    reset({}, { keepValues: false })
    setOpenTimeScaleDialog(false)
  }

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      if (discard) {
        onFormClose()
      }
      setOpenNavigationPrompt(false)
    }
  }

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onFormClose()
    }
  }

  const saveTimeScaleSetting = handleSubmit(async (data) => {
    await updateUserTenantSetting(TIME_SCALE_SETTING, data.timeScale, false)
      .then(() => {
        onFormClose()
      })
  })

  const toggleShowRoomsSetting = () => {
    updateUserTenantSetting(SHOW_ROOM_SETTING, !userTenantSettings[SHOW_ROOM_SETTING.name], false)
    handleClose()
  }

  const showRoomsSettingLabel = userTenantSettings[SHOW_ROOM_SETTING.name] ? 'Hide rooms' : 'Show rooms'

  return (
    <>
      <IconButton dataTestId={DATA_TEST_ID} onClick={handleClick} icon={<MoreHorizIcon />}/>
      <Menu data-testid={`${DATA_TEST_ID}-menu`} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          data-testid={`${DATA_TEST_ID}-menuItem-0`}
          onClick={() => {
            setOpenTimeScaleDialog(true)
            handleClose()
          }}
        >
          Set time scale
        </MenuItem>
        <MenuItem data-testid={`${DATA_TEST_ID}-menuItem-1`} onClick={toggleShowRoomsSetting}>
          {showRoomsSettingLabel}
        </MenuItem>
      </Menu>
      <DialogV2
        title={'Time scale'}
        open={openTimeScaleDialog}
        size={'xs'}
        onClose={onCancel}
        primaryAction={()=> null}
        formName={FORM_NAME}
        submitting={isSubmitting}
        dataTestId={"schedule-options-time-scale-dialog"}
      >
        <Typography fontWeight={'bold'}>Set time scale to</Typography>
        <FormProvider {...formMethods}>
          <Form onSubmit={saveTimeScaleSetting} id={FORM_NAME}>
            <Select
              name={'timeScale'}
              label={''}
              defaultValue={String(userTenantSettings[TIME_SCALE_SETTING.name])}
              dataTestId={`${DATA_TEST_ID}-timescale-menu`}
            >
              <MenuItem data-testid={`${DATA_TEST_ID}-timescale-menu-menuItem-0`} value={5}>5 minutes</MenuItem>
              <MenuItem data-testid={`${DATA_TEST_ID}-timescale-menu-menuItem-1`} value={10}>10 minutes</MenuItem>
              <MenuItem data-testid={`${DATA_TEST_ID}-timescale-menu-menuItem-2`} value={15}>15 minutes</MenuItem>
              <MenuItem data-testid={`${DATA_TEST_ID}-timescale-menu-menuItem-3`} value={30}>30 minutes</MenuItem>
              <MenuItem data-testid={`${DATA_TEST_ID}-timescale-menu-menuItem-4`} value={60}>1 hour</MenuItem>
            </Select>
          </Form>
        </FormProvider>
      </DialogV2>
    </>
  )
}
