import React, { useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import moment from "moment-timezone";
import { BookingPreferenceInput } from "../../../../types/schedule";
import { BookingPreferenceForm } from "./BookingPreferenceForm";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import _get from "lodash/get";
import { schema } from "../../util/bookingPreferenceValidation";
import { ADD_NEW_BOOKING_PREFERENCE, GET_SCHEDULE_ITEMS } from "../../../../graphql-definitions";
import { useScheduleContext } from "../../../../providers/ScheduleContextProvider";
import omitDeep from 'omit-deep-lodash'
import { useTenantContext } from "../../../../providers/TenantContextProvider";
import { formatTime, getScheduleViewDates } from "../../util/scheduleFunctions";

const DATA_TEST_ID = "newBookingPreference"
const FORM_NAME = "new_booking_preference_form"

const bookingPreferenceDefaults = (eventData, scheduleId, timezone) => {
  return {
    version: "0",
    scheduleId: scheduleId,
    start: moment(eventData.start).tz(timezone),
    end: moment(eventData.end).tz(timezone),
    title: '',
    itemDate: moment(eventData?.start).format('YYYY-MM-DD'),
    appointmentTypeIds: [],
  }
}

interface NewBookingPreferenceProps {
  eventData: any
  scheduleId?: string
  onClose: () => void
  onFormChange: (formName: string, submitting: boolean, dirtyFields: any) => void
}

export const NewBookingPreference = ({
  eventData,
  scheduleId,
  onClose,
  onFormChange
}: NewBookingPreferenceProps) => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const { selectedScheduleIds, scheduleView, displayScheduleError } = useScheduleContext()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const tenantSettings = getTenantSettings([tenantSettingsKeys.TIMEZONE])

  const formMethods = useForm<BookingPreferenceInput>({
    defaultValues: bookingPreferenceDefaults(eventData, scheduleId, tenantSettings[tenantSettingsKeys.TIMEZONE.name]),
    schema: schema
  })

  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    reset,
  } = formMethods

  useEffect(() => {
    onFormChange(FORM_NAME, isSubmitting, dirtyFields)
  }, [isSubmitting, Object.keys(dirtyFields).length])

  const [addNewBookingPreference] = useMutation(ADD_NEW_BOOKING_PREFERENCE, {
    onCompleted: (data) => {
      reset()
      onClose()
      showSuccessAlert('Booking preference has been created.')
    },
    onError: (error: any) => {
      displayScheduleError(error, "Booking preference")
    }
  })

  const onSubmit = handleSubmit(async(data) => {
    let appointmentTypeIds = data.appointmentTypes?.map((at) => at.id)

    let newBookingPreferenceInput = {
      ...omitDeep(data, 'appointmentTypes'),
      appointmentTypeIds: appointmentTypeIds,
      start: moment(data.itemDate + ' ' + formatTime(data.start), 'YYYY-MM-DD h:mm A'),
      end: moment(data.itemDate + ' ' + formatTime(data.end), 'YYYY-MM-DD h:mm A')
    }

    await addNewBookingPreference({
      variables: {
        tenantId: tenant_id,
        input: newBookingPreferenceInput
      },
      update(cache, returnedData) {
        let newBookingPreferenceId = _get(returnedData, 'data.tenant.schedule.createBookingPreference')
        let newBookingPreference = {
          id: newBookingPreferenceId,
          ...newBookingPreferenceInput,
          __typeName: 'BookingPreference'
        }
        const scheduleDateRange = getScheduleViewDates(scheduleView.date, scheduleView.view)

        try{
          cache.writeQuery({
            query: GET_SCHEDULE_ITEMS,
            data: {
              tenant: {
                schedule: {
                  scheduleItems: [
                    {
                      key: scheduleId,
                      value: [newBookingPreference],
                    }
                  ],
                  scheduleTemplateDays: [],
                }
              }
            },
            variables: {
              tenantId: tenant_id,
              scheduleIdList: selectedScheduleIds,
              start: moment(scheduleDateRange.start).utc().toISOString(),
              end: moment(scheduleDateRange.end).utc().toISOString
            }
          })
        } catch (e) {
          console.error("Error updating cache", e)
        }
      }
    })
  })

  return (
    <FormProvider {...formMethods}>
      <BookingPreferenceForm
        formName={FORM_NAME}
        onSubmit={onSubmit}
        limitToScheduleId={scheduleId}
        dataTestId={DATA_TEST_ID}
      />
    </FormProvider>
  )
}
