import * as yup from 'yup'
import _get from 'lodash/get'

export const rules = {
  name: { max: 100 },
  description: { max: 100 },
}

const falseIfEmpty = (value) => (!value ? false : value)

const maxLengthMessage = (value) => `Exceeds max length of ${value}`

export const getErrorMessage = (errors, key) =>
  _get(errors, `${key}.message`, '')

export const schema = yup
  .object({
    name: yup
      .string()
      .max(rules.name.max, maxLengthMessage(rules.name.max))
      .required('Required'),
    description: yup
      .string()
      .max(rules.description.max, maxLengthMessage(rules.description.max))
      .nullable(),
    isAdmin: yup.boolean().transform((val) => falseIfEmpty(val)),
    billing: yup.object({
      readonly: yup.boolean(),
      readwrite: yup.boolean(),
    }),
  })
  .required()
