import React from 'react'
import { useFormContext } from "saga-library/src/components/Form";
import { batchUpdateStyleSearch } from "./BatchUpdateStyle";
import {
  ControlledFunctionalCenterInput
} from "../../../../../components/FunctionalCenterSearch/FunctionalCenterInput";
import { ClaimInputType } from "../../../../../types/billing/AbClaim/AbClaim";

interface BatchControlledFunctionalCenterInputProps {
  name: string
  label?: string
  onClear?: () => void
  autoFocus?: boolean
}

export const BatchControlledFunctionalCenter = ({
  name,
  label,
  onClear,
  autoFocus,
}: BatchControlledFunctionalCenterInputProps) => {
  const { formState: { dirtyFields } } = useFormContext<ClaimInputType>();
  return (
    <ControlledFunctionalCenterInput
      name={name}
      label={label}
      onClear={onClear}
      autoFocus={autoFocus}
      sx={batchUpdateStyleSearch(dirtyFields.functionalCenter)}
    />
  )
}
