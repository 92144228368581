import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ConditionAndProcedureForm } from "./components/ConditionAndProcedureForm";
import { cleanConditionData, ConditionAndProcedureType } from "../../../../types/ConditionAndProcedure";
import { schema } from "./components/ConditionAndProcedureFormValidationsSchema";
import { conditionAndProcedureDefaults } from "./components/ConditionAndProcedureFormDefaultValues";
import { useConditionAndProcedureContext } from "../../providers/ConditionAndProcedureProvider";

const FORM_NAME = "new_condition_and_procedure_form"

const NewConditionAndProcedureDialog = ({open, setOpen}) => {
  const { createConditionAndProcedure } = useConditionAndProcedureContext()
  const { enableNavigationPrompt } = usePrompt()
  const [ andAddAnother, setAndAddAnother ] = useState(false)

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<ConditionAndProcedureType>({
    defaultValues: conditionAndProcedureDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting, isSubmitSuccessful },
    reset,
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(false)
        reset(conditionAndProcedureDefaults)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(conditionAndProcedureDefaults)
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = () => {
    setOpen(false)
  }

  const onSave = () => {
    handleSubmit( async(data: ConditionAndProcedureType) => {
      await createConditionAndProcedure(
        cleanConditionData(data),
        () => {
          setOpen(false)
        },
      )
    })()
  }

  const onSaveAndNew = () => {
    handleSubmit(async(data: ConditionAndProcedureType) => {
      setAndAddAnother(true)
      await createConditionAndProcedure(
        cleanConditionData(data)
      )
      setAndAddAnother(false)
    })()
  }


  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(false)
      reset(conditionAndProcedureDefaults)
    }
  }

  return <DialogV2
    dataTestId={'new-condition-and-procedure-dialog'}
    size={'sm'}
    title={'New condition or procedure'}
    onClose={onCancel}
    open={open}
    primaryAction={() => null}
    overridePrimaryComponent={
      <>
        <LoadingButton
          name={'saveAndNewConditionAndProcedure'}
          form={FORM_NAME}
          dataTestId={"new-condition-and-procedure-dialog-secondary-button"}
          loading={isSubmitting && andAddAnother}
          disabled={isSubmitting && !andAddAnother}
          onClick={onSaveAndNew}
        >
          Save and add another
        </LoadingButton>
        <LoadingButton
          name={'saveConditionAndProcedure'}
          form={FORM_NAME}
          dataTestId={"new-condition-and-procedure-dialog-primary-button"}
          loading={isSubmitting && !andAddAnother}
          disabled={isSubmitting && andAddAnother}
          onClick={onSave}
          variant={'contained'}
        >
          Save
        </LoadingButton>
      </>
    }
  >
    <FormProvider {...formMethods}>
      <ConditionAndProcedureForm
        onSubmit={onSubmit}
        autoFocus={true}
      />
    </FormProvider>
  </DialogV2>
}

export default NewConditionAndProcedureDialog