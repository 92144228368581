import { FormIcon } from "../ChartIcons";
import React, { useCallback, useMemo, useState } from 'react'
import Widget from "../Widget";
import { WidgetDateTableList } from "./components/WidgetDateTableList";
import { FormType } from "../../../../types/patients";
import { FormDocumentModal } from "../forms/components/FormsDocumentModal";
import { FormModal } from "../forms/components/FormsModal";
import { useReadQuery } from "@apollo/client";
import { usePatientFormContext } from "../../providers/PatientFormProvider";

export const FormWidget = () => {
  const [ selectedForm, setSelectedForm ] = useState<FormType|null>(null)

  const [ openDialog, setOpenDialog]  = useState(false)

  const handleModalClose = useCallback(() => {
    setSelectedForm(null)
    setOpenDialog(false)
  },[setSelectedForm, setOpenDialog])

  const onRowClick = (form) => {
    setSelectedForm(form)
  }

  return <Widget
    route={'forms'}
    title={'Forms'}
    WidgetIcon={FormIcon}
    onAddItem={() => {setOpenDialog(true)}}
  >
    <FormWidgetBody onRowClick={onRowClick} />
    {selectedForm?.isLinkedDocument ?
      <FormDocumentModal
        form={selectedForm}
        onModalClose={handleModalClose}
        /> :
      <FormModal
        open={openDialog||!!selectedForm}
        onModalClose={handleModalClose}
        form={selectedForm}
      />}
  </Widget>
}


const FormWidgetBody = ({ onRowClick }) => {
  const { patientFormQueryRef, parseFormQueryResults } = usePatientFormContext()
  const { data } = useReadQuery(patientFormQueryRef!)
  const forms = useMemo(()=> parseFormQueryResults(data), [data, parseFormQueryResults])

  return (
    <WidgetDateTableList
      dataTestId={"formsWidget"}
      rows={forms.map( form => {
        return {
          displayDate: form.formDate,
          label: form.name,
          subLabel: form.description,
          keyName: `form_${form.id}_${form.isLinkedDocument?'d':'f'}`,
          onRowClick: () => onRowClick(form)
        }
      })}
      emptyListMessage={"Click the add button to add a form."}
    />
  )
}














