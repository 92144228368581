import { EntryDescription, EntryTitle } from "saga-library/src";
import React from "react";
import { ResultOptionsWrapper } from "saga-library/src";
import { Pharmacy } from "../../../types";

interface PharmacyResultProps {
  pharmacy: Pharmacy
  searchText: string
}

export const PharmacyResult = ({pharmacy, searchText}: PharmacyResultProps) => {
  const { name, street1, province, city, postalCode } = pharmacy
  const fullAddress = `${street1 ? street1 + ',' : ''} ${city ? city + ',' : ''} ${province ? province : ''} ${postalCode  ? postalCode : ''}`

  return (
    <ResultOptionsWrapper>
      <EntryTitle title={name} searchText={searchText} />
      <EntryDescription text={fullAddress} searchText={searchText}/>
    </ResultOptionsWrapper>
  )
}