import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { AllergyType } from "../../../../types/Allergy";
import { schema } from "./components/AllergyFormValidationsSchema";
import { allergyDefaults } from "./components/AllergyFormDefaultValues";
import { AllergyForm } from "./components/AllergyForm";
import { useAllergyContext } from "../../providers/AllergyProvider";

const FORM_NAME = "new_allergy_form"

const NewAllergyDialog = ({open, setOpen}) => {
  const { createAllergy } = useAllergyContext()
  const { enableNavigationPrompt } = usePrompt()
  const [ andAddAnother, setAndAddAnother ] = useState(false)

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const formMethods = useForm<AllergyType>({
    defaultValues: allergyDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(false)
        reset(allergyDefaults)
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = () => {
    setOpen(false)
  }

  const onSave = handleSubmit( async(data: AllergyType) => {
    await createAllergy(
      data,
      () => {
        setOpen(false);
        reset(allergyDefaults);
      }
    );
  })

  const onSaveAndNew = handleSubmit(async(data: AllergyType) => {
    setAndAddAnother(true)
    await createAllergy(
      data,
      () => {
        reset(allergyDefaults);
      }
    );
    setAndAddAnother(false)
  })


  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(false)
    }
  }

  return <DialogV2
    dataTestId={'new-allergy-dialog'}
    size={'sm'}
    title={'New allergy'}
    onClose={onCancel}
    open={open}
    primaryAction={() => null}
    overridePrimaryComponent={
      <>
        <LoadingButton
          name={'saveAndNewAllergy'}
          form={FORM_NAME}
          dataTestId={"new-allergy-dialog-secondary-button"}
          loading={isSubmitting && andAddAnother}
          disabled={isSubmitting && !andAddAnother}
          onClick={onSaveAndNew}
        >
          Save and add another
        </LoadingButton>
        <LoadingButton
          name={'saveAllergy'}
          form={FORM_NAME}
          dataTestId={"new-allergy-dialog-primary-button"}
          loading={isSubmitting && !andAddAnother}
          disabled={isSubmitting && andAddAnother}
          onClick={onSave}
          variant={'contained'}
        >
          Save
        </LoadingButton>
      </>
    }
  >
    <FormProvider {...formMethods}>
      <AllergyForm
        onSubmit={onSubmit}
        autoFocus={true}
      />
    </FormProvider>
  </DialogV2>
}

export default NewAllergyDialog