import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { FilterSelect, SearchField, TableList } from 'saga-library/src'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { StaticPage } from '../../../components/Layouts'
import { TenantUserRow } from './TenantUserRow'
import type { TableListHeaderConfig } from 'saga-library/src'
import { TenantInviteRow } from './TenantInviteRow'
import { useAccountContext } from "../../../providers/AccountContext";
import { PermissionType, Permission } from "../../../types/settings/Permission";
import { GET_TENANT_USERS, LIST_PRACTITIONERS } from "../../../graphql-definitions";
import { Practitioner } from "../../../types/settings";
import { User } from "../../../types/account";

const columns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Name', sortable: true },
  { name: 'Roles' },
  { name: 'Linked practitioner', sortable: true },
  { name: 'Last Active', sortable: true },
  { name: 'Account status', sortable: true },
  { name: '' },
]

export const TenantUserList = () => {
  const { tenant_id } = useParams()
  const { userHasPermission, userId} = useAccountContext()

  const [users, setUsers] = useState([])
  const [practitioners, setPractitioners] = useState([])
  const [invites, setInvites] = useState([])
  const [roles, setRoles] = useState([])
  const [filters, setFilters] = useState({
    role: '*',
    active: '*',
    status: '*',
    search: '',
  })

  const { loading, error, data } = useQuery(GET_TENANT_USERS, {
    variables: { tenantId: tenant_id },
  })

  useQuery(LIST_PRACTITIONERS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    onCompleted: (data) => {
      setPractitioners(_get(data, 'tenant.practitioner.list', null))
    }
  })

  const allowActions = tenant_id && userHasPermission(tenant_id, PermissionType.Admin, Permission.READWRITE)

  useEffect(() => {
    const tenantUsers = _get(data, 'tenant.user.allTenantUsers', null)
    const tenantInvites = _get(data, 'tenant.invite.list', null)
    const tenantRoles = _get(data, 'tenant.role.tenantRoles', null)

    if (error) {
      console.error(JSON.stringify(error, null, 2))
    } else if (data) {
      setUsers(tenantUsers)
      setInvites(tenantInvites)
      setRoles(tenantRoles)
    }
  }, [error, data])

  useEffect(() => {
    if (loading) return

    let filteredUsers = _get(data, 'tenant.user.allTenantUsers', null)
    let filteredInvites = _get(data, 'tenant.invite.list', null)
    if (filters.role !== '*') {
      filteredUsers = filteredUsers.filter((u) =>
        u.roleIds.some((r) => r === filters.role)
      )
      filteredInvites = filteredInvites.filter((i) =>
        i.roleIds.some((r) => r === filters.role)
      )
    }
    if (filters.active !== '*') {
      //TODO: Once this value exists
    }
    if (filters.status !== '*') {
      switch (filters.status) {
        case 'invitedandaccess':
          filteredUsers = filteredUsers.filter((u) => u.active)
          break
        case 'invited':
          filteredUsers = []
          break
        case 'access':
          filteredUsers = filteredUsers.filter((u) => u.active)
          filteredInvites = []
          break
        case 'none':
          filteredUsers = filteredUsers.filter((u) => !u.active)
          filteredInvites = []
          break
      }
    }
    if (filters.search) {
      filteredUsers = filteredUsers.filter((u) => {
        return (
          (u.lastName + ', ' + u.firstName)
            .toLowerCase()
            .includes(filters.search) ||
          (u.firstName + ' ' + u.lastName)
            .toLowerCase()
            .includes(filters.search) ||
          u.emailAddress?.includes(filters.search)
        )
      })
      filteredInvites = filteredInvites.filter((i) => {
        return i.emailAddress?.includes(filters.search)
      })
    }
    setUsers(filteredUsers)
    setInvites(filteredInvites)
  }, [filters, data, loading])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <>
        <TenantListFilters
          roles={roles}
          filters={filters}
          setFilters={setFilters}
        />
        <TableList
          enableSorting={true}
          columns={columns}
          rows={[
            ...users.map((user: User) => {
              const practitioner = practitioners.find((practitioner: Practitioner) => practitioner.userId === user.id)
              return TenantUserRow(user, practitioner, roles, filters.search, allowActions, users, userId);
            }),
            ...invites.map((invite) => TenantInviteRow(invite, roles, filters.search, allowActions)),
          ]}
          loading={loading}
          dataTestId={'tenantUserList-tableList'}
          leftAlignColumns={true}
        />
      </>
      {error && (
        <StaticPage>Sorry, an error occurred loading the user list.</StaticPage>
      )}
    </Box>
  )
}

const TenantListFilters = ({ roles, filters, setFilters }) => {
  const roleOptions = [
    {label: 'All', value: '*'},
    ...roles.map((role) => ({
      label: role.name,
      value: role.id,
    }))
  ]
  const statusOptions = [
    { label: 'All', value: '*'},
    { label: 'Invited and has access', value: 'invitedandaccess' },
    { label: 'Invited', value: 'invited' },
    { label: 'Has access', value: 'access' },
    { label: 'No access', value: 'none' },
  ]

  return (
    <Box display={'flex'} justifyContent={'stretch'} alignItems={'center'}>
      <FilterSelect
        label={'Role'}
        options={roleOptions}
        onSelect={(event) => setFilters({ ...filters, role: event.value })}
        defaultSelectedValue={'*'}
        dataTestId={'tenantUserList-role-filter'}
      />
      <FilterSelect
        label={'Account status'}
        options={statusOptions}
        onSelect={(event) => setFilters({ ...filters, status: event.value })}
        defaultSelectedValue={'*'}
        dataTestId={'tenantUserList-status-filter'}
      />
      <Box display={'flex'} justifyContent={'flex-end'} flexGrow={1}>
        <SearchField
          name={'search-field'}
          onChange={(e) => {
            setFilters({ ...filters, search: e.target.value.toLowerCase() })
          }}
          value={filters.search}
          dataTestId={'tenantUserList-search-field'}
        />
      </Box>
    </Box>
  )
}
