import { gql } from "@apollo/client";

export const PRESCRIPTION_FAVOURITE_FRAGMENT= gql`
    fragment PrescriptionFavouriteFragment on PrescriptionFavourite {
        id
        drug {
            name
            code
            codeType
        }
        quantityAmount
        quantityUnits
        dosageForm
        route
        site
        indication
        additionalInstructions
        link
        dosages {
            id
            dosageRange
            dosageUnits
            durationAmount
            durationUnits
            frequency
            prn
            version
            dosageOrder
        }
        version
        usage
        substitutions
    }
`

export const PRESCRIPTION_RECENT_FRAGMENT= gql`
    fragment PrescriptionRecentFragment on PrescriptionRecent {
        id
        drug {
            name
            code
            codeType
        }
        endDate
        quantityAmount
        quantityUnits
        dosageForm
        route
        site
        indication
        additionalInstructions
        link
        dosages {
            id
            dosageRange
            dosageUnits
            durationAmount
            durationUnits
            frequency
            prn
            version
            dosageOrder
        }
        version
        usage
        substitutions
    }
`

export const PRESCRIPTION_RESULT_FRAGMENT= gql`
    fragment PrescriptionResultFragment on PrescriptionResult {
        id
        drug {
            name
            code
            codeType
        }
        endDate
        quantityAmount
        quantityUnits
        dosageForm
        route
        site
        indication
        additionalInstructions
        link
        dosages {
            id
            dosageRange
            dosageUnits
            durationAmount
            durationUnits
            frequency
            prn
            version
            dosageOrder
        }
        version
        usage
        substitutions
    }
`

export const CREATE_FAVOURITE_PRESCRIPTION = gql`
  ${PRESCRIPTION_FAVOURITE_FRAGMENT}
  mutation CreateFavouritePrescription($tenantId: ID!, $prescriptionInput: PrescriptionInput!) {
    tenant(id: $tenantId) {
      user {
        favourite {
          createPrescriptionFavourite(input: $prescriptionInput) {
            ...PrescriptionFavouriteFragment
          }
        }
      }
    }
  }
`

export const LIST_FAVOURITE_AND_RECENT_PRESCRIPTIONS = gql`
    ${PRESCRIPTION_FAVOURITE_FRAGMENT}
    ${PRESCRIPTION_RECENT_FRAGMENT}
  query ListPrescriptionFavourites($tenantId: ID!){
      tenant(id: $tenantId){
          user {
              favourite {
                  list {
                      ...PrescriptionFavouriteFragment
                  }                
              }
              recent {
                  list {
                      ...PrescriptionRecentFragment
                  }
              }
          }
      }
  }
`

export const UNFAVOURITE_PRESCRIPTION = gql`
  mutation UnfavouritePrescription($tenantId: ID!, $prescriptionId: ID!) {
    tenant(id: $tenantId) {
      user {
        favourite {
          unfavouritePrescription(prescriptionId: $prescriptionId)
        }
      }
    }
  }
`

export const INCREMENT_FAVOURITE_PRESCRIPTION = gql`
  mutation IncrementFavouritePrescription($tenantId: ID!, $prescriptionId: ID!) {
    tenant(id: $tenantId) {
      user {
        favourite {
          incrementFavouritePrescriptionUsage(prescriptionId: $prescriptionId)
        }
      }
    }
  }
`