import React, { useState } from 'react'
import { Box } from '@mui/material'
import { Radio, TableList, TableListHeaderConfig } from 'saga-library/src'
import MuiRadioGroup from '@mui/material/RadioGroup'
import { UnsubmittedClaimRow } from './UnsubmittedClaimRow'
import { useNavigate, useParams } from 'react-router-dom'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'
import { useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import { AbClaimProblemSeverity } from '../../../../utils/ABClaimConstants'
import { AbClaim } from '../../../../types/billing'


export const UnsubmittedClaimList = () => {
  const [filter, setFilter] = useState('*')
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const navigate = useNavigate()

  const { unsubmittedClaimsQueryRef, setRedirectToNewlyCreatedClaim, setSelectedClaimIds, selectedClaimIds } = useUnsubmittedClaimsContext()

  const data = useReadQuery(unsubmittedClaimsQueryRef!)
  let allUnsubmittedClaims = _get(data, 'data.tenant.abClaim.listUnsubmittedAbClaim', [] as any)

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READWRITE))

  const claimStates = {
    all: '*',
    readyToSubmit: 'READY',
    held: 'HOLD',
    containsErrors: 'ERROR',
  }

  const filterClaimsByStatus = (claim) => {
    if (filter === '*') {
      return true
    } else if (filter !== 'ERROR') {
      return claim.claimStatus === filter
    } else {
      return claim.problems.some(p => p.severity === AbClaimProblemSeverity.ERROR)
    }
  }

  const onRowClick = (claim: AbClaim) => {
    setSelectedClaimIds([])
    setRedirectToNewlyCreatedClaim(false)
    navigate(`../c/${claim.id}`)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Box
        data-testid={"unsubmittedClaimList"}
        display={'flex'}
        flexDirection={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <MuiRadioGroup
          row={true}
          name={'filter'}
          data-testid={'filterTestId'}
          onChange={(event) => setFilter(claimStates[event.target.value])}
          defaultValue={'all'}
        >
          <Radio label={'All'} value={'all'} />
          <Radio label={'Ready to submit'} value={'readyToSubmit'} />
          <Radio label={'Held'} value={'held'} />
          <Radio label={'Contains errors'} value={'containsErrors'} />
        </MuiRadioGroup>
      </Box>
      <TableList
        size={'small'}
        checkedRows={selectedClaimIds}
        setCheckedRows={setSelectedClaimIds}
        showCheckbox={!!hasEditPermission}
        emptyListComponent={ { message: "Unsubmitted claims appear here once saved." } }
        columns={UnsubmittedClaimColumns}
        rows={[
          ...allUnsubmittedClaims
            .filter(filterClaimsByStatus)
            .map((claim) => {
              return (
                UnsubmittedClaimRow(claim, onRowClick)
              )
            })
        ]}
      />
    </Box>
  )
}

export default UnsubmittedClaimList
export const UnsubmittedClaimColumns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Patient' },
  { name: 'Service date' },
  { name: 'Service' },
  { name: 'Calls' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Facility' },
  { name: 'Claimed' },
]