import { gql } from "@apollo/client";

export const PHARMACY_FRAGMENT = gql`
    fragment PharmacyFragment on Pharmacy {
        id
        name
        street1
        street2
        street3
        city
        province
        postalCode
        phoneNumber
        faxNumber
        version
    }
`

export const GET_PHARMACY = gql`
  ${PHARMACY_FRAGMENT}
  query GetPharmacy($tenantId: ID!, $pharmacyId: ID!) {
    tenant(id: $tenantId) {
      pharmacy {
        get(id: $pharmacyId) {
          ...PharmacyFragment
        }
      }
    }
  }
`

export const LIST_PHARMACIES = gql`
    ${PHARMACY_FRAGMENT}
    query ListPharmacies($tenantId: ID!){
        tenant(id: $tenantId){
            pharmacy{
                list {
                    ...PharmacyFragment
                }
            }
        }
    }
`


export const UPDATE_PHARMACY = gql`
  ${PHARMACY_FRAGMENT}
  mutation UpdatePharmacy($tenantId: ID!, $id: ID!, $input: PharmacyInput!) {
    tenant(id: $tenantId) {
      pharmacy {
        update(id: $id, input: $input) {
          ...PharmacyFragment
        }
      }
    }
  }
`


export const CREATE_PHARMACY = gql`
  ${PHARMACY_FRAGMENT}
  mutation CreatePharmacy($tenantId: ID!, $input: PharmacyInput!) {
    tenant(id: $tenantId) {
      pharmacy {
        create(input: $input) {
          ...PharmacyFragment
        }
      }
    }
  }
 `

export const DELETE_PHARMACY = gql`
  mutation DeletePharmacy($tenantId: ID!, $id: ID!, $version: String!) {
    tenant(id: $tenantId) {
      pharmacy {
        delete(id: $id, version: $version)
      }
    }
  }
`
